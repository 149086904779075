/**
 * @returns true if current page is embedded in iframe
 *
 * @deprecated this utility is unused and should be removed
 * @collboard-modules-sdk
 */
export function amIEmbededInIFrame() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true; // TODO: @roseckyj This should be maybe false?
    }
}
