import { IStorage } from 'everstorage';
import { Observable } from 'rxjs';
import { forImmediate } from 'waitasecond';
import { string_translate_language, string_uriid, string_uri_part, string_url, string_version } from '../../40-utils/typeAliases';
import { ExportArt, ExportFormat } from '../../71-arts/35-ExportArt';
import { clientVersion } from '../../config';
import { ISystemsExtended } from '../00-SystemsContainer/ISystems';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { MaterialArtVersioningSystem } from '../ArtVersionSystem/0-MaterialArtVersioningSystem';
import { ITranslateMessage } from '../TranslationsSystem/interfaces/ITranslateMessage';
import { BoardApiClient } from './BoardApiClient';
import { ICreateBoardOptions } from './ICreateBoardOptions';
import { IGetMyBoardsRequest, IGetMyBoardsResponse } from './interfaces/IGetMyBoards';
import { IRequest, IResponse } from './interfaces/IRequestResponse';

/**
 * ApiClient provides API calls to the remote server.
 *
 * @collboard-system
 */
export class ApiClient extends AbstractSystem {
    public constructor(systems: ISystemsExtended, private apiUrl: URL) {
        super(systems);
    }

    private apiClientCache: IStorage<any>;

    protected async init() {
        await forImmediate(/* <- TODO: Describe why waiting forAnimationFrame OR remove */);
        this.apiClientCache = (await this.systems.storageSystem).getStorage(
            `ApiClientCache` /* TODO: Normalize names in localstorage */,
        );
    }

    public async getAbout(): Promise<{ version: string; remoteInstanceId: string_version }> {
        return this.get<any, any /* TODO: Create interfaces */>('/about');
    }

    public async connectToBoard(uriId: string_uri_part): Promise<MaterialArtVersioningSystem> {
        // consolex.info(`Connecting to board ${uriId}`);

        const { routingSystem } = await this.systems.request('routingSystem');
        const boardApiClient = new BoardApiClient(this.systems, this.apiUrl, uriId);
        await boardApiClient.atLeastOnceConnected;

        boardApiClient.commitsObservable.subscribe(async (commit) => {
            if (commit.art instanceof ExportArt && commit.art.format === ExportFormat.Native && commit.uriId) {
                routingSystem.viewUriId.next(commit.uriId);
            }
        });

        return boardApiClient /* TODO: This is a bit weird, returning child of ArtVersionSystem */;
    }

    /**
     *
     * @deprecated Use boardSystem.createNewBoard instead
     */
    public async createNewBoard(options: Omit<ICreateBoardOptions, 'redirect'>): Promise<{
        uriId: string_uriid;
        links: {
            edit: string_url;
            view: string_url;
        };
    }> {
        // TODO: !! Add to my boards

        (options as ICreateBoardOptions).redirect = false;

        const response = await this.post(`/new`, options);
        return response as {
            uriId: string_uriid;
            links: {
                edit: string_url;
                view: string_url;
            };
        };
    }

    public getMyBoards(): Observable<IGetMyBoardsResponse> {
        return new Observable((observer) => {
            (async () => {
                const cachedResponse = (await this.apiClientCache.getItem(
                    'getMyBoards',
                )) as IGetMyBoardsResponse | null;
                if (cachedResponse) {
                    observer.next(cachedResponse);
                }
                const curentResponse = await this.get<IGetMyBoardsRequest, IGetMyBoardsResponse>('/myBoards', {
                    fromDate: cachedResponse?.date,
                });

                const curentResponseUriIds = curentResponse.data.map(({ uriId }) => uriId);

                const cachedResponseData = cachedResponse ? cachedResponse.data : [];
                const cachedResponseDataFiltered = cachedResponseData.filter(
                    ({ uriId }) => !curentResponseUriIds.includes(uriId),
                );

                const data = [...cachedResponseDataFiltered, ...curentResponse.data];
                data.sort((a, b) => {
                    return new Date(b.lastEdit).getTime() - new Date(a.lastEdit).getTime();
                });

                const response = {
                    date: curentResponse.date,
                    data,
                };

                observer.next(response);
                observer.complete();
                await this.apiClientCache.setItem('getMyBoards', response);
            })();
        });
    }

    public async translateMessages(language: string_translate_language): Promise<Array<ITranslateMessage>> {
        return this.get<any, any /* TODO: Create interfaces */>('/translate/translateMessages', {
            // TODO: No cache for development version
            language,
            clientVersion,
        });
    }

    public async missingTranslateMessage(missingTranslateMessage: ITranslateMessage) {
        // consolex.warn('missingTranslateMessage', missingTranslateMessage);
        return this.post('/translate/missingTranslateMessage', { missingTranslateMessage });
    }

    private async get<TRequest extends IRequest, TResponse extends IResponse>(
        path: string,
        data?: TRequest,
    ): Promise<TResponse> {
        const url = new URL(`${this.apiUrl}${path}`);
        if (data) {
            for (const [key, value] of Object.entries(data)) {
                if (value !== null && value !== undefined) {
                    url.searchParams.set(key, value.toString());
                }
            }
        }

        const response = await fetch(url.toString(), {
            headers: {
                'X-Auth-Token': (await this.systems.identitySystem).browserId,
            },
        });
        return (await this.processResponse(response)) as TResponse;
    }

    // TODO: Create AbscractApiClient library
    // TODO: Generically typed
    private async post(path: string, data: {} /*TODO: Probably add option for query*/) {
        const response = await fetch(`${this.apiUrl}${path}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Auth-Token': (await this.systems.identitySystem).browserId,
            },
            body: JSON.stringify(data),
        });
        return this.processResponse(response);
    }

    private async processResponse(response: Response): Promise<unknown> {
        const responseData = (await response.json()) as { error?: string };
        if (responseData.error) {
            throw new Error(`Error from server: ${responseData.error}`);
        }
        return responseData;
    }
}

/**
 * TODO: !! Listen on RoutingSystem and save it into myBoards
 */
