import React from 'react';
import { IVectorData, Vector } from 'xyzt';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { Abstract2dArt } from '../../71-arts/26-Abstract2dArt';
import { TimerComponent } from './TimerComponent';
import { ITimerAndStopwatchControls } from './TimerControlsAttributeModule';

/**
 *
 * Note: In future this file will we in independent repository as external module.
 *
 */

internalModules.declareModule(() => makeArtModule(TimerArt));

export class TimerArt extends Abstract2dArt {
    public static serializeName = 'Timer';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/timer-art',
        deprecatedNames: '@collboard/timer-art',
    };

    public running: boolean;
    public remaining: number;
    public timestamp: number; // Warning: not UNIX timestamp since it is in millis

    /**
     * @deprecated [🍈] name privateSize is confusing, use something instead like size (=privateSize) vs. originalSize
     */
    private privateSize: IVectorData = new Vector(300, 75);

    public constructor(
        shift: IVectorData /* TODO: Should be shift set in the constructor or somehow else (by setShift method)? */,
        public millis: number,
    ) {
        super();
        this.shift = shift;
        this.reset();
    }

    start() {
        this.running = true;
        this.timestamp = new Date().getTime();
        // consolex.info('start', this.running, this.timestamp, this.remaining);
    }

    stop() {
        this.running = false;
        this.remaining = this.remaining - new Date().getTime() + this.timestamp;
        this.timestamp = new Date().getTime();
        // consolex.info('stop', this.running, this.timestamp, this.remaining);
    }

    reset() {
        this.running = false;
        this.remaining = this.millis;
        this.timestamp = new Date().getTime();
        // consolex.info('reset', this.running, this.timestamp, this.remaining);
    }

    // Hack to control via floating menu
    public set timerControls(value: ITimerAndStopwatchControls) {
        switch (value) {
            case 'INITIAL': // Stop
                this.stop();
                return;
            case 'RUNNING': // Start
                this.start();
                return;
            case 'STOPPED': // Reset
                this.reset();
                return;
        }
    }
    public get timerControls(): ITimerAndStopwatchControls {
        return this.running ? 'RUNNING' : 'INITIAL';
    }

    set timerTime(millis: number) {
        if (this.running) {
            return;
        }
        this.millis = millis;
        this.reset();
    }

    get timerTime(): number {
        if (this.millis !== this.remaining || this.running) {
            return -1;
        } else {
            return this.millis;
        }
    }

    public get acceptedAttributes() {
        return ['timerControls', 'timerTime'];
    }
    public get topLeft() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return this.shift;
    }
    public get bottomRight() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return Vector.add(this.shift, this.privateSize);
    }

    get warningThreshold() {
        // TIP: Use calulate util

        if (this.millis <= 60 * 1000)
            // Under minute -> 10s
            return 10 * 1000;
        if (this.millis <= 30 * 60 * 1000)
            // Under 30 minutes -> 1min
            return 60 * 1000;
        if (this.millis <= 5 * 60 * 60 * 1000)
            // Under 5 hours -> 30min
            return 30 * 60 * 1000;
        if (this.millis <= 24 * 60 * 60 * 1000)
            // Under a day -> 1hour
            return 60 * 60 * 1000;

        return 24 * 60 * 60 * 1000; // a day
    }

    render() {
        return (
            <div
                className="block"
                style={{
                    width: this.privateSize.x || 0,
                    height: this.privateSize.y || 0,
                    position: 'absolute',
                    left: this.shift.x || 0 /* <- LIB xyzt .toTopLeft() */,
                    top: this.shift.y || 0 /* <- LIB xyzt .toTopLeft() */,
                }}
            >
                {this.running ? (
                    <TimerComponent
                        warnAt={this.warningThreshold}
                        millis={10}
                        until={this.timestamp + this.remaining}
                    />
                ) : (
                    <TimerComponent millis={10} static={this.remaining} />
                )}
            </div>
        );
    }
}
