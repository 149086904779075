export function isMessageEventForCollboard(event: MessageEvent): boolean {
    if (event?.data?.source?.match(/react-devtools-.*/)) {
        return false;
    }

    if (event.data === null) {
        return false;
    }
    if (typeof event.data !== 'object') {
        return false;
    }

    if (event.data.type !== 'REQUEST') {
        return false;
    }

    if (typeof event.data.type !== 'string') {
        return false;
    }

    if (typeof event.data.actionName !== 'string') {
        return false;
    }

    return true;
}
