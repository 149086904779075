import React from 'react';
import { IVectorData, Vector } from 'xyzt';
import { classNames } from '../40-utils/classNames';
import { flipNegativeSize } from '../40-utils/flipNegativeSize';
import { pointsToPolygon } from '../40-utils/svgUtils';
import { number_positive, string_color } from '../40-utils/typeAliases';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { Abstract2dArt } from './26-Abstract2dArt';

internalModules.declareModule(() => makeArtModule(ShapeArt));

/**
 * @deprecated [🍿] Do not use enums but object as const OR 'LITERAL VALUES' instead
 *
 * @collboard-modules-sdk
 */
export enum ShapeName {
    Rectange = 'Rectange',
    Circle = 'Circle',
    Line = 'Line',
    Triangle = 'Triangle',
    TriangleRight = 'TriangleRight',
}

/**
 * @collboard-modules-sdk
 */
export class ShapeArt extends Abstract2dArt /* TODO: Probably> implements IFramable*/ {
    public static serializeName = 'Shape';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/shape-art',
        deprecatedNames: '@collboard/shape-art',
    };

    public constructor(
        public shape: ShapeName,
        public color: string_color,
        public weight: 0 | number_positive,
        shift: IVectorData /* TODO: Should be shift set in the constructor or somehow else (by setShift method)? */,
        private privateSize: /* <- [🍈] name privateSize is confusing, use something instead like size (=privateSize) vs. originalSize */ IVectorData,
    ) {
        // TODO: Shape line style
        // TODO: Shape fill
        super();
        this.shift = shift;
    }

    public get topLeft() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        const { translate } = flipNegativeSize({
            translate: this.shift,
            size: this.size,
        });

        return translate.subtract(Vector.square(this.weight));
    }
    public get bottomRight() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        const { translate, size } = flipNegativeSize({
            translate: this.shift,
            size: this.size,
        });

        return Vector.add(translate, size, Vector.square(this.weight));
    }
    public get size() {
        return this.privateSize;
    }
    public set size(newSize: IVectorData) {
        this.privateSize = newSize;
    }

    public isNear(pointToTest: IVectorData) {
        // TODO: Smarter!
        return (
            (pointToTest.x || 0) > (this.topLeft.x || 0) &&
            (pointToTest.x || 0) < (this.bottomRight.x || 0) &&
            (pointToTest.y || 0) > (this.topLeft.y || 0) &&
            (pointToTest.y || 0) < (this.bottomRight.y || 0)
        );
    }

    public get acceptedAttributes() {
        return ['color', 'weight', 'size'];
    }

    public render(isSelected: boolean) {
        // TODO: Can this be done in a more elegant way?
        const sizeNegativeOrZero = new Vector(
            (this.size.x || 0) < 0 ? this.size.x || 0 : 0,
            (this.size.y || 0) < 0 ? this.size.y || 0 : 0,
        );

        // const sizePositive = new Vector(this.size).map(Math.abs);

        const shiftBase = new Vector(this.shift).add(sizeNegativeOrZero).subtract(Vector.square(this.weight));
        const shiftRemainder = shiftBase.subtract(shiftBase.map(Math.floor));

        const origin = shiftRemainder.subtract(sizeNegativeOrZero);

        return (
            <div
                // TODO: [🍒][0]! This should became <ArtOwnShell
                className={classNames('art', isSelected && 'selected')}
                style={{
                    position: 'absolute',
                    fontSize: 0 /* <- Note: Removing fontSize to get rid of artefacts of whitespaces in deep zoom */,
                    left: Math.floor(
                        (this.shift.x || 0) + ((this.size.x || 0) < 0 ? this.size.x || 0 : 0) - this.weight,
                    ),
                    top: Math.floor(
                        (this.shift.y || 0) + ((this.size.y || 0) < 0 ? this.size.y || 0 : 0) - this.weight,
                    ),
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={Math.ceil(Math.abs(this.size.x || 0) + 2 * this.weight) + 1}
                    height={Math.ceil(Math.abs(this.size.y || 0) + 2 * this.weight) + 1}
                >
                    {this.renderShape(origin)}
                </svg>
            </div>
        );
    }

    private renderShape(origin: Vector) {
        // TODO: Some better way how to separate code and shapes

        switch (this.shape) {
            case ShapeName.Line:
                return (
                    <line
                        x1={this.weight + origin.x}
                        y1={this.weight + origin.y}
                        x2={this.weight + origin.x + (this.privateSize.x || 0)}
                        y2={this.weight + origin.y + (this.privateSize.y || 0)}
                        stroke={this.color}
                        strokeWidth={this.weight}
                        fillOpacity="null"
                        strokeOpacity="null"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        className="collisions"
                    />
                );

            case ShapeName.Rectange:
                const { translate, size } = flipNegativeSize({
                    translate: origin.add(Vector.square(this.weight)),
                    size: this.size,
                });

                return (
                    <rect
                        x={translate.x}
                        y={translate.y}
                        width={size.x}
                        height={size.y}
                        stroke={this.color}
                        strokeWidth={this.weight}
                        fillOpacity="null"
                        strokeOpacity="null"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        className="collisions"
                    />
                );

            case ShapeName.Circle:
                return (
                    <ellipse
                        cx={this.weight + (this.privateSize.x || 0) / 2 + origin.x}
                        cy={this.weight + (this.privateSize.y || 0) / 2 + origin.y}
                        rx={Math.abs(this.privateSize.x || 0) / 2}
                        ry={Math.abs(this.privateSize.y || 0) / 2}
                        stroke={this.color}
                        strokeWidth={this.weight}
                        fillOpacity="null"
                        strokeOpacity="null"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        className="collisions"
                    />
                );

            case ShapeName.TriangleRight:
                return (
                    <polygon
                        points={pointsToPolygon(
                            [
                                new Vector(this.weight, this.weight),
                                new Vector(this.weight, (this.size.y || 0) + this.weight),
                                new Vector((this.size.x || 0) + this.weight, (this.size.y || 0) + this.weight),
                            ].map((p) => p.add(origin)),
                        )}
                        stroke={this.color}
                        strokeWidth={this.weight}
                        fillOpacity="null"
                        strokeOpacity="null"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        className="collisions"
                    />
                );
            case ShapeName.Triangle:
                return (
                    <polygon
                        points={pointsToPolygon(
                            [
                                new Vector(this.weight + (this.size.x || 0) / 2, this.weight),
                                new Vector(this.weight, (this.size.y || 0) + this.weight),
                                new Vector((this.size.x || 0) + this.weight, (this.size.y || 0) + this.weight),
                            ].map((p) => p.add(origin)),
                        )}
                        stroke={this.color}
                        strokeWidth={this.weight}
                        fillOpacity="null"
                        strokeOpacity="null"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        className="collisions"
                    />
                );
        }
    }
}

/**
 * TODO: [🍎]  Use IMaterial instead of color
 * TODO: [🍎]  Use IShape instead of weight, points,...
 * TODO: [🕺] Rename weight => spotSize, edgeSize (as it is in PolygonArt and FreehandArt)
 * TODO: [🚉] There should be some rounding optimization for svg numbers (for example path)
 *       From: d="M60.00000000000006 70.4999999999999 L60.00000000000006 70.4999999999999...
 *       To:   d="M60 70.5 L60 70.5...
 * TODO: [🎚️] Implement IArt
 */
