import React from 'react';
import { ModalFooter } from '../../../../30-components/modal/ModalFooter/ModalFooter';
import { ModalScroll } from '../../../../30-components/modal/ModalScroll/ModalScroll';
import { internalModules } from '../../../ModuleStore/internalModules';
import { makeModalModule } from '../../../ModuleStore/makers/makeModalModule';
import { LicensesAddModalGroup } from './10-LicensesAddModalGroup';
import { LicensesListModalGroup } from './10-LicensesListModalGroup';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            name: '@collboard/internal/licenses',
            deprecatedNames: '@collboard/licenses',
            title: { en: 'Active licenses', cs: 'Aktivní licence' },
            requirePermissions: [],
        },
        async createModal(systems) {
            await systems.request('licenseSystem', 'translationsSystem', 'moduleStore', 'licenseSyncer');

            return (
                <>
                    <ModalScroll>
                        <LicensesListModalGroup />
                        <div className="bottom-space"> {/* <- TODO: Better way how to add more bottom space */}</div>
                    </ModalScroll>
                    <ModalFooter isSequestered={false}>
                        {/* TODO: [🚥] <ModalLine /> */}
                        <LicensesAddModalGroup />
                    </ModalFooter>
                </>
            );
        },
    }),
);

/**
 * TODO: [🥇] Allow to scan QR code with the license here
 * TODO: [🐅] Maybe some more elegant way how to create icon-window pairs of modules
 * TODO: Make clear difference between admin modules (technical, now in https://github.com/collboard/collboard-admin) and management modules (for users)
 */
