import React from 'react';
import { CopyComponent } from '../../../../30-components/utils/CopyComponent';
import { Loader } from '../../../../30-components/utils/Loader/Loader';
import { Obfuscate } from '../../../../30-components/utils/Obfuscate';
import { QRCode } from '../../../../30-components/utils/QRCode';
import { classNames } from '../../../../40-utils/classNames';
import { useObservable } from '../../../../40-utils/react-hooks/useObservable';
import { useSystems } from '../../../../40-utils/react-hooks/useSystems';
import { Translate } from '../../../../50-systems/TranslationsSystem/components/Translate';

export function ShareModalViewLink() {
    // console.log('🅰️', 'ShareModalViewLink', 'render');

    const { routingSystem } = useSystems('routingSystem');
    const { value: viewUrl } = useObservable(routingSystem.viewUrl);

    if (!viewUrl) {
        return <Loader alt="view link" />;
    }

    return (
        <>
            <hr />
            {/* TODO: [🚥] <ModalLine /> */}
            <span className="modal-input-title">
                <Translate name="ShareIcon/ display link" html>
                    Odkaz pro zobrazení:
                </Translate>
            </span>
            <Obfuscate
                buttonContent={<Translate name="ShareIcon/obfuscation button">Zobrazit</Translate>}
                content={
                    <Translate name="ShareIcon/obfuscation desc">Z bezpečnostních důvodů je odkaz skrytý</Translate>
                }
            >
                <CopyComponent className={classNames('modal-input', 'input-copy')} value={viewUrl.href} />

                <div style={{ textAlign: 'center' }}>
                    <QRCode text={viewUrl.href} width={200} />
                </div>
            </Obfuscate>
        </>
    );
}
