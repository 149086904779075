import { objectDecapitalize } from 'configchecker/lib/utils/objectDecapitalize';
import { objectDeflatten } from 'configchecker/lib/utils/objectDeflatten';
import { objectEmptyKeysAsUndefined } from 'configchecker/lib/utils/objectEmptyKeysAsUndefined';
import { objectIsNotEmpty } from 'configchecker/lib/utils/objectIsNotEmpty';
import { objectTransformDates } from 'configchecker/lib/utils/objectTransformDates';
import papaparse from 'papaparse';
import React from 'react';
import { errorMessageWithAdditional } from '../../../../40-utils/errors/errorMessageWithAdditional';
import { string_url } from '../../../../40-utils/typeAliases';

export async function fetchGoogleSpreadsheet(csvExportUrl: string_url): Promise<Array<any>> {
    const additional: any = {};

    try {
        const response = await fetch(csvExportUrl, { cache: 'reload' });
        const dataString = await response.text();
        additional.dataString = dataString;
        const { data } = papaparse.parse(dataString, {
            header: true,
        });

        additional.data = data;

        return data
            .map((object) =>
                objectEmptyKeysAsUndefined<string | undefined>(
                    object,
                    (value) => !['', '-', 'NULL'].includes((value || '').trim()),
                ),
            )
            .filter(objectIsNotEmpty)
            .map(objectDecapitalize)
            .map(objectDeflatten)
            .map(objectTransformDates)
            .map((annoucment) => {
                for (const part of ['title', 'subtitle', 'body']) {
                    for (const language of Object.keys(annoucment[part] || {})) {
                        annoucment[part][language] = (
                            <div
                                // TODO: Use here <InnerHtml component
                                dangerouslySetInnerHTML={{ __html: annoucment[part][language] }}
                            />
                        );
                    }
                }
                return annoucment;
            })
            .map((annoucment) => ({
                ...annoucment,
                businesses: (annoucment.businesses as string).split(',').map((place) => place.trim().toUpperCase()),
                places: (annoucment.places as string).split(',').map((place) => place.trim().toUpperCase()),
                repeatInDays: parseFloat(annoucment.repeatInDays || '0'),
            }));
    } catch (error) {
        throw new Error(
            errorMessageWithAdditional('Can not fetch Google spreadsheet', { csvExportUrl, error, ...additional }),
        );
    }
}
