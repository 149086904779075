import { IDestroyable, registerItemsInArray, registerItemsInSubjectOfArrays, Registration } from 'destroyable';
import React from 'react';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { forImmediate } from 'waitasecond';
import { ObservableContentComponent } from '../../30-components/utils/ObservableContentComponent';
import { consolex } from '../../consolex';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { ISkin } from './ISkin';
import { SkinContext } from './SkinContext';
import { ColorScheme, systemColorScheme, watchPreferedColorScheme } from './utils/watchPreferedColorScheme';

/**
 * StyleSystem can register and manage additional CSS styles for modules. It can scope CSS so it will do not affect others.
 *
 * Note: UserInterfaceSystem is for JSX (HTML) vs. StyleSystem is for CSS styles
 *
 * @collboard-system
 */
export class StyleSystem extends AbstractSystem {
    /*
        TODO: This class is a boilerplate of the system that we have not started working on yet.
        @see https://github.com/collboard/collboard/issues/220
    */

    protected async init() {
        /*not await*/ this.initEmbedStyle();

        const storage = (await this.systems.storageSystem).getStorage<ColorScheme>('StyleSystem');

        this.colorScheme = watchPreferedColorScheme((await storage.getItem('colorScheme')) || undefined);
        this.colorScheme.subscribe(async (value) => {
            consolex.info(`StyleSystem: Color scheme changed to ${value}.`);
            // TODO: Unify logs from systems and modules

            localStorage.setItem('Collboard_Initial_colorScheme', value);

            if (value !== systemColorScheme()) {
                await storage.setItem('colorScheme', value);
            } else {
                // Note: When user picks system color scheme, we anticipate that he want to have it in sync with the system
                await storage.removeItem('colorScheme');
            }
        });
    }

    private styleForEmbedRegistration: null | IDestroyable = null;

    private async initEmbedStyle() {
        // [🖼️]
        await forImmediate(/* <- TODO: Probbably not need to wait here */);

        (await this.systems.routingSystem).urlVariables.values
            .pipe(distinctUntilChanged((a, b) => a.extension === b.extension))
            .subscribe({
                next: async ({ extension }) => {
                    if (this.styleForEmbedRegistration) {
                        await this.styleForEmbedRegistration.destroy();
                    }
                    if (extension === 'embed') {
                        this.styleForEmbedRegistration = this.registerGlobalStyle(`
                          .menu{
                            display: none;
                          }
                        `);
                    }
                },
            });

        // TODO: [🎎] Some blocker of Clicjacking
    }

    private readonly globalStyles: BehaviorSubject<Array<string>> = new BehaviorSubject([] as Array<string>);

    /**
     *
     * TODO: Enable object-like styles + styled components in future
     * TODO: Create scoped version of this
     */
    public registerGlobalStyle(style: string): Registration {
        return registerItemsInSubjectOfArrays({
            currentValue: this.globalStyles.value /* <- [🌹] Not necessary */,
            base: this.globalStyles,
            add: [style],
        });
    }

    public renderStyles(): JSX.Element {
        return (
            <ObservableContentComponent
                alt="Global style"
                loader={<></>}
                content={this.globalStyles.pipe(
                    map((styles) => (
                        <>
                            {styles.map((style) => (
                                <style key={style}>{style}</style>
                            ))}
                        </>
                    )),
                )}
            />
        );
    }

    public /* TODO: Readonly*/ colorScheme: BehaviorSubject<ColorScheme>;

    public readonly skin = new ReplaySubject<ISkin>(1);

    /**
     * Renders content with the current skin
     */
    public readonly WithSkin = this._WithSkin.bind(this);

    /**
     * Creates context for providing skin
     *
     * You want to probbably use WithSkin
     * Use always and only for wrapping content in ReactDOM.render(<styleSystem.WithSkinContext>...</styleSystem.WithSkinContext>)
     */
    public readonly WithSkinContext = this._WithSkinContext.bind(this);

    private _WithSkin({ content }: { content: (skin: ISkin) => JSX.Element }): JSX.Element {
        return (
            <ObservableContentComponent
                alt="Skinned content to be created"
                content={this.skin.pipe(map((value) => content(value)))}
            />
        );
    }

    private _WithSkinContext({
        children,
    }: React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{}>): JSX.Element {
        return (
            <this.WithSkin content={(skin) => <SkinContext.Provider value={skin}>{children}</SkinContext.Provider>} />
        );
    }

    private skins: Array<ISkin> = [];

    // TODO: Probably rename skin to theme

    public registerSkin({ skin }: { skin: ISkin; priority?: number }): Registration {
        this.skin.next(skin);

        return registerItemsInArray({
            base: this.skins,
            add: [skin],
            // TODO: Support priority in  registerItemsInArray - observable with each new highest priority as next
        });
    }

    /* TODO: Probbably protected */
    public async destroy() {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        if (this.styleForEmbedRegistration) {
            await this.styleForEmbedRegistration.destroy();
        }
    }
}

/**
 * TODO: Probably allow to hotreload skin
 * TODO: Probably use it for core and systems.
 * TODO: Better name for renderStyles method (to be able to recgognize the difference between <GlobalStyles /> and {styleSystem.renderStyles()})
 * TODO: [🏅] Allow to override just one color in the skin via styleSystem
 */
