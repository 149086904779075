import { Promisable } from 'type-fest';
import { ISystems } from '../../00-SystemsContainer/ISystems';
import { ToolbarName } from '../../ToolbarSystem/0-ToolbarSystem';
import { IToolbarIcon } from '../../ToolbarSystem/IToolbarIcon';
import { IModuleDefinition } from '../interfaces/IModule';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
/**
 *
 * @collboard-modules-sdk
 */
export function makeIconModule(protoModule: {
    manifest?: IModuleManifest;
    toolbar: ToolbarName;
    icon: IToolbarIcon | ((systems: ISystems) => Promisable<IToolbarIcon>);
}): IModuleDefinition {
    const { manifest } = protoModule;

    return {
        manifest,
        async setup(systems: ISystems) {
            const { toolbarSystem } = await systems.request('toolbarSystem');

            const { icon: protoIcon, toolbar } = protoModule;
            let icon: IToolbarIcon;

            if (typeof protoIcon === 'function') {
                icon = await protoIcon(systems);
            } else {
                icon = protoIcon;
            }

            return toolbarSystem.getToolbar(toolbar).registerIcon(icon);
        },
    };
}
