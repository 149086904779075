import { IVectorData, Vector } from 'xyzt';
import { AbstractPlacedArt } from '../../../71-arts/25-AbstractPlacedArt';
import { joinBoundingBoxes } from './joinBoundingBoxes';

interface ICenterArtsOptions {
    arts: Array<AbstractPlacedArt>;
    center: IVectorData;
}

type ICenterArtsOptionsDeprecated = Omit<ICenterArtsOptions, 'center'> & {
    /**
     * @deprecated
     */
    boardPosition: IVectorData;
};

/**
 * Moves given arts to be in center
 *
 * @sideeffect This will mutate passed arts
 *
 * @collboard-modules-sdk
 */
export function centerArts(options: ICenterArtsOptions | ICenterArtsOptionsDeprecated): void {
    const { arts, center, boardPosition } = options as ICenterArtsOptions & ICenterArtsOptionsDeprecated;

    if (arts.length === 0) {
        return;
    }

    const originalCommonBoundingBox = joinBoundingBoxes(...arts);
    const originalCommonCenter = Vector.average(
        originalCommonBoundingBox.topLeft,
        originalCommonBoundingBox.bottomRight,
    );

    const shiftToCenter = Vector.subtract(center || boardPosition, originalCommonCenter);

    for (const art of arts) {
        art.setShift(Vector.add(art.shift, shiftToCenter));
    }
}

/**
 * TODO: [🎼] Relation between topleftArts and centerArts
 * TODO: This should be done by LIB xyzt boundingBox and AbstractPlacedArt should implement interface IBoundingBox
 * TODO: Use rotation and scale we should probbably bahave isotropically and isometrically (pass here transform instead of center)
 */
