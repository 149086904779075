import React from 'react';

const SCALER_MAIN_SIZE = 15;
const SCALER_SECONDARY_SIZE = 8;
export const SCALER_OUTLINE_OFFSET = 5;

/**
 * @collboard-modules-sdk
 */
export function ArtScaler() {
    const absolutePositionValueMain = -SCALER_OUTLINE_OFFSET - SCALER_MAIN_SIZE / 2;
    const absolutePositionValueSecondary = -SCALER_OUTLINE_OFFSET - SCALER_SECONDARY_SIZE / 2;
    const absolutePositionValueMiddle = 'calc(50% - ' + SCALER_SECONDARY_SIZE / 2 + 'px)';
    return (
        <>
            <div
                className="art-scaler"
                style={{
                    bottom: absolutePositionValueMain,
                    right: absolutePositionValueMain,
                    width: SCALER_MAIN_SIZE,
                    height: SCALER_MAIN_SIZE,
                    cursor: 'nwse-resize',
                }}
            ></div>
            <div
                className="art-scaler"
                style={{
                    top: absolutePositionValueMain,
                    right: absolutePositionValueMain,
                    width: SCALER_MAIN_SIZE,
                    height: SCALER_MAIN_SIZE,
                    cursor: 'nesw-resize',
                }}
            ></div>
            <div
                className="art-scaler"
                style={{
                    bottom: absolutePositionValueMain,
                    left: absolutePositionValueMain,
                    width: SCALER_MAIN_SIZE,
                    height: SCALER_MAIN_SIZE,
                    cursor: 'nesw-resize',
                }}
            ></div>
            <div
                className="art-scaler"
                style={{
                    left: absolutePositionValueMain /* <- LIB xyzt .toTopLeft() */,
                    top: absolutePositionValueMain /* <- LIB xyzt .toTopLeft() */,
                    width: SCALER_MAIN_SIZE,
                    height: SCALER_MAIN_SIZE,
                    cursor: 'nwse-resize',
                }}
            ></div>

            <div
                className="art-scaler"
                style={{
                    bottom: absolutePositionValueSecondary,
                    left: absolutePositionValueMiddle,
                    width: SCALER_SECONDARY_SIZE,
                    height: SCALER_SECONDARY_SIZE,
                    cursor: 'ns-resize',
                }}
            ></div>
            <div
                className="art-scaler"
                style={{
                    left: absolutePositionValueSecondary /* <- LIB xyzt .toTopLeft() */,
                    top: absolutePositionValueMiddle /* <- LIB xyzt .toTopLeft() */,
                    width: SCALER_SECONDARY_SIZE,
                    height: SCALER_SECONDARY_SIZE,
                    cursor: 'ns-resize',
                }}
            ></div>
            <div
                className="art-scaler"
                style={{
                    left: absolutePositionValueMiddle /* <- LIB xyzt .toTopLeft() */,
                    top: absolutePositionValueSecondary /* <- LIB xyzt .toTopLeft() */,
                    width: SCALER_SECONDARY_SIZE,
                    height: SCALER_SECONDARY_SIZE,
                    cursor: 'ew-resize',
                }}
            ></div>
            <div
                className="art-scaler"
                style={{
                    top: absolutePositionValueMiddle,
                    right: absolutePositionValueSecondary,
                    width: SCALER_SECONDARY_SIZE,
                    height: SCALER_SECONDARY_SIZE,
                    cursor: 'ew-resize',
                }}
            ></div>
        </>
    );
}

/**
 * TODO: [🏊‍♂️] Better naming and distinction of Collboard-specific components vs utils components
 */
