import { IPersonProfile, string_person_fullname } from '../../../../../40-utils/typeAliases';
import { IModuleManifest } from '../../../../../50-systems/ModuleStore/interfaces/IModuleManifest/IModuleManifest';
import { consolex } from '../../../../../consolex';

export function parseModuleAuthors({
    author: authorRaw,
    contributors: contributorsRaw,
}: Pick<IModuleManifest, 'author' | 'contributors'>): Array<IPersonProfile> {
    // TODO: What is the best primary unique key for author?
    const contributorsRecord: Record<string_person_fullname, IPersonProfile> = {};

    for (const authorItem of [authorRaw, ...(contributorsRaw || [])]) {
        if (authorItem) {
            if (typeof authorItem === 'object') {
                contributorsRecord[authorItem.name || ''] = authorItem;
            } else {
                for (const author of authorItem.split(',')) {
                    if (author) {
                        try {
                            const parsing =
                                /^\s*(?<name>.*?)\s*(<\s*(?<email>.*)\s*>\s*)?(\(\s*(?<url>.*)\s*\)\s*)?$/.exec(author);

                            if (!parsing) {
                                throw new Error(`There was error when parsing author from string "${author}".`);
                            }

                            const { name, email, url } = parsing.groups as any;
                            contributorsRecord[name] = { name, email, url };
                        } catch (error) {
                            consolex.error(error);
                        }
                    }
                }
            }
        }
    }
    const contributors = Object.values(contributorsRecord);

    for (const contributor of contributors) {
        if (contributor.name === '') {
            delete contributor.name;
        }
        if (contributor.email === '') {
            delete contributor.email;
        }
        if (contributor.url === '') {
            delete contributor.url;
        }
    }

    if (contributors.length === 0) {
        return [{}];
    } else {
        return contributors;
    }
}
