import React from 'react';
import { Menu } from '../../30-components/menu/Menu';
import { AsyncContentComponent } from '../../30-components/utils/AsyncContentComponent';
import { factor } from '../../40-utils/IFactory';
import { useObservable } from '../../40-utils/react-hooks/useObservable';
import { IconsToolbar } from './IconsToolbar';

interface IIconsToolbarMenuComponentProps {
    iconsToolbar: IconsToolbar;
}

/**
 * Renders the menu of currently active icon in the toolbar
 *
 * There are two similar components:
 * - **IconsToolbarComponent** which renders the toolbar
 * - **IconsToolbarMenuComponent** which renders the menu of currently active icon in the toolbar
 *
 * @not-collboard-modules-sdk because IconsToolbarMenuComponent is a private component of ToolbarSystem
 */
export function IconsToolbarMenuComponent({ iconsToolbar }: IIconsToolbarMenuComponentProps) {
    const { value: icons } = useObservable(iconsToolbar.icons);
    const activeIcons = icons.filter(({ isActive }) => isActive);

    return (
        <>
            {activeIcons
                .filter((icon) => icon.menu)
                .map((icon, index) => (
                    <Menu orientation="horizontal" key={index}>
                        <AsyncContentComponent alt="Menu content content to be created" content={factor(icon.menu!)} />
                        {' ' /* TODO: <- Is whitespace necessary */}
                    </Menu>
                ))}
        </>
    );
}

/**
 * TODO: [🎲] It is a bit inconsistent that renderMenu wraps content with <Menu but renderToolbar not
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
