import { Registration } from 'destroyable';
import { Speech } from '../../40-utils/Speech';
import { string_color } from '../../40-utils/typeAliases';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { IFontStyleAttributeValue } from '../../70-attributes/FontStyleAttributeModule';
import { TextArt } from '../../71-arts/50-TextArt/TextArt';
import { consolex } from '../../consolex';

internalModules.declareModule(() => ({
    manifest: {
        name: 'SpeechText',
        title: { en: 'Speech to text', cs: 'Diktování textu' },
        description: {
            en: 'You can dictate text instead of writing it.',
            cs: 'Můžete diktovat text místo jeho psaní.',
        },

        screenshots: [
            /*TODO:*/
        ],
        categories: [
            'Productivity',
            'Experimental' /* TODO: Probbably experimental should be flag or some dev stage */,
        ],
        author: Authors.hejny,
    },

    async setup(systems) {
        const { touchController, collSpace, attributesSystem, materialArtVersioningSystem } = await systems.request(
            'touchController',
            'collSpace',
            'attributesSystem',
            'materialArtVersioningSystem',
        );

        return Registration.fromSubscription(() =>
            touchController.touches.subscribe(async (touch) => {
                /*
                TODO:
                touch.frames.subscribe(
                    () => {},
                    () => {},
                    () => {
                        // tslint:disable-next-line:no-shadowed-variable
                        operation.updateWithMutatingCallback((art) => {
                            const textArt = art as TextArt;
                            textArt.setShift(collSpace.module.collSpace.pickPoint(touch.frames.value.position).point);
                        });
                    },
                );
                */

                const speech = new Speech();
                const operation = materialArtVersioningSystem
                    .createPrimaryOperation()
                    .newArts(
                        new TextArt(
                            '…' /* TODO: DRY */,
                            attributesSystem.getAttributeValue('color').value as string_color,
                            attributesSystem.getAttributeValue('fontSize').value as number,
                            (attributesSystem.getAttributeValue('fontStyle').value as IFontStyleAttributeValue).bold,
                            true /* Note: Italic when still not final */,
                            (
                                attributesSystem.getAttributeValue('fontStyle').value as IFontStyleAttributeValue
                            ).underline,
                            'none',
                        ).setShift(collSpace.pickPoint(touch.firstFrame.position).point),
                    )
                    .worthless();

                speech.transcript.subscribe(
                    (transcript) => {
                        operation.updateWithMutatingCallback((art) => {
                            const textArt = art as TextArt;
                            if (transcript) {
                                textArt.content = transcript;
                            } else {
                                textArt.content = '…' /* TODO: DRY */;
                            }
                        });
                    },
                    (error) => {
                        consolex.error(`SpeechTextModule:\n ${error}`);
                    },
                    () => {
                        if (speech.lastTranscript) {
                            operation
                                .updateWithMutatingCallback((art) => {
                                    const textArt = art as TextArt;
                                    textArt.italic = (
                                        attributesSystem.getAttributeValue('fontStyle')
                                            .value as IFontStyleAttributeValue
                                    ).italic;
                                })
                                .persist();
                        } else {
                            operation.abort();
                        }
                    },
                );
            }),
        );

        // TODO: Probably there should be some on/off button
    },
}));
