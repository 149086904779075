import { Registration } from 'destroyable';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeIconModuleOnModule } from '../50-systems/ModuleStore/makers/makeIconModuleOnModule';
import { ToolbarName } from '../50-systems/ToolbarSystem/0-ToolbarSystem';
import { consolex } from '../consolex';

/**
 * TODO: [🔅] Put all %c styled console logs styles into one config place
 */
export const WHICH_ARTS_STYLE = `background: #66ffff; color: black; font-size: 1.1em; font-weight: bold; padding: 5px; border-radius: 3px;`;

/**
 * Nearby distance to select arts
 */
export const WHICH_ARTS_DISTANCE = 10;

internalModules.declareModule(() =>
    makeIconModuleOnModule({
        manifest: {
            name: '@collboard/internal/which-art-tool',
            title: { en: 'Which art is this', cs: 'Zjistit typ objektu' },
            description: {
                en: 'A developer tool that detects the type and other data of the selected object.',
                cs: 'Vývojářský nástroj, který zjišťuje typ a další data o vybraných objektech na tabuli.',
            },

            categories: ['Development'],
            icon: '❓',

            author: Authors.collboard,
            // TODO: [🏰]> requirePermissions: ['view'],
        },
        toolbar: ToolbarName.Tools,
        icon: {
            name: 'selection',
            icon: '❓',
            boardCursor: 'default',
        },
        moduleActivatedByIcon: {
            async setup(systems) {
                const { touchController, collSpace, materialArtVersioningSystem, artSerializer, artSupportSyncer } =
                    await systems.request(
                        'touchController',
                        'collSpace',
                        'materialArtVersioningSystem',
                        'artSerializer',
                        'artSupportSyncer',
                    );

                return Registration.fromSubscription(async () =>
                    touchController.touches.subscribe(async (touch) => {
                        const { point } = await collSpace.pickPoint(touch.firstFrame.position);

                        const pickedArts = materialArtVersioningSystem.artsPlaced.filter((art) => art.isNear(point));

                        const logger = consolex.group(`%cPicked ${pickedArts.length} arts`, WHICH_ARTS_STYLE);

                        logger.info('Position on screen', touch.firstFrame.position);
                        logger.info('Position on board', point);
                        for (const art of pickedArts) {
                            let moduleManifest = (art as any).constructor.manifest;
                            const serializedArt = await artSerializer.serialize(art);

                            moduleManifest = { name: moduleManifest.name, ...moduleManifest };

                            logger.info({
                                moduleManifest,
                                serializedArt,
                            });
                        }
                        logger.end();
                    }),
                );
            },
        },
    }),
);

/**
 * TODO: Maybe also log virtualArtVersioningSystem
 */
