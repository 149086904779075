import { EMOJIS, EMOJIS_IN_CATEGORIES } from './emojis';
import { randomItem } from './randomItem';
import { string_char_emoji } from './typeAliasEmoji';

/**
 * Only graphical + colored emojis
 */
const emojisForRandom: Set<string_char_emoji> = new Set(EMOJIS);

// TODO: Explain for which reason excluding each of cathegory
['Non-Emoji Symbols', 'Countries', 'New Emojis 2020', 'New Emoji 2021'].forEach((category) =>
    EMOJIS_IN_CATEGORIES[category]!.forEach((emoji) => emojisForRandom.delete(emoji)),
);

/**
 *
 * Pick random emoji char like "🍆", "🍡", "🍤"...
 *
 * Note: exclude non-graphical and non-colored emojis like "🇧🇾" or "✶"
 *
 * @collboard-modules-sdk
 */
export function randomEmoji(): string_char_emoji {
    return randomItem(...Array.from(emojisForRandom));
}

/**
 * TODO: randomItemInSet as optimization
 * TODO: Constrain by group of emojis like animals, plants,...
 * TODO: To separate emoji LIB
 * TODO: Use in emoji modules
 */
