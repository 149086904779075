import { Registration } from 'destroyable';

export class NotificationRegistration extends Registration {
    public async destroy() {
        try {
            await super.destroy();
        } catch (error) {}
    }

    /**
     *
     * @alias destroy
     */
    public constrict() {
        return this.destroy();
    }

    public static void(): NotificationRegistration {
        return new NotificationRegistration(() => {});
    }
}

/**
 * TODO: Is there some way how to inherit public static void() from Registration without overloading?
 *       @see https://www.facebook.com/groups/typescript.developers/permalink/3365848130303546/
 */
