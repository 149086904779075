import React from 'react';
import ReactDOM from 'react-dom';
import { FileComponent } from '../../30-components/utils/FileComponent';
import { SkinContext } from '../../50-systems/StyleSystem/SkinContext';
import { consolex } from '../../consolex';
import { Color } from '../color/Color';

/**
 * Creates an element from file, JSX, string, any object OR return given element
 *
 * @collboard-modules-sdk
 */
export function createPreviewElement(subject: HTMLElement | File | Blob | JSX.Element | string): HTMLElement {
    if (subject instanceof HTMLElement) {
        return subject;
    }

    let jsxElement: JSX.Element;

    if (typeof subject === 'string') {
        jsxElement = <>{subject}</>;
    } else if (subject instanceof Blob) {
        jsxElement = (
            <SkinContext.Provider
                value={{
                    // TODO: Some DRY way how to fake skin
                    welcomeWallpaper: {
                        backgroundImage: `url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.46/wallpapers/default-education-wallpaper.png')`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                    },
                    borderRadius: 6,
                    colors: {
                        primary: Color.fromHex('#009edd'),
                        light: Color.fromHex('#f2f2f2'),
                        dark: Color.fromHex('#4e4e4e'),

                        success: Color.fromHex('#3da948'),
                        warning: Color.fromHex('#ffcc11'),
                        error: Color.fromHex('#cd1719'),
                        danger: Color.fromHex('#cd1719'),

                        white: Color.fromString('white'),
                        black: Color.fromString('black'),

                        overlay: Color.fromString('black').withAlpha(0.7),
                    },
                    darken: -0.05,
                    lighten: 0.05,
                    circles: true,
                }}
            >
                <FileComponent file={subject} />
            </SkinContext.Provider>
        );
    } else if (React.isValidElement(/* <- TODO: Import and use just a isValidElement */ subject)) {
        jsxElement = subject;
    } else {
        jsxElement = (
            <button
                onClick={() => {
                    consolex.info(subject);
                }}
            >
                Click to log a preview
            </button>
        );
    }

    const element = document.createElement('div');

    ReactDOM.render(jsxElement, element);

    return element;
}
