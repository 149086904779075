import React from 'react';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../../50-systems/ModuleStore/makers/makeModalModule';
import { InstallButton } from './InstallButton';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            name: '@collboard/internal/install',
            deprecatedNames: '@collboard/install',
            description: 'Install the Collboard on your device',
            flags: {
                isHidden: true /* <- TODO: Modal modules should be always hidden*/,
            },
            requirePermissions: [],
        },
        async createModal() {
            return (
                <>
                    <InstallButton>Install</InstallButton>
                </>
            );
        },
    }),
);
