import { registerItemsInSubjectOfArrays, Registration } from 'destroyable';
import React from 'react';
import { BehaviorSubject } from 'rxjs';
import { AsyncContentComponent } from '../../30-components/utils/AsyncContentComponent';
import { LoaderInline } from '../../30-components/utils/Loader/LoaderInline';
import { string_attribute, string_attribute_value_scope } from '../../40-utils/typeAliases';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { InputsRender } from './components/InputsRender';
import { attribute_value } from './IAttribute';
import { IAttributeRule } from './IAttributeRule';

/**
 * AttributesSystem manages shared art attributes and modules capable of selecting from them. It auto-installs / uninstalls attribute modules.
 *
 * @collboard-system
 */
export class AttributesSystem extends AbstractSystem {
    private attributesRules: Record<string_attribute, BehaviorSubject<Array<IAttributeRule<attribute_value>>>> = {};

    protected async init() {}

    /*
    private static isEqual(attribute1: IAttribute, attribute2: IAttribute): boolean {
        // TODO: Better comparing
        return attribute1.name === attribute2.name && attribute1.type === attribute2.type;
    }
    */

    public registerAttributeRule(rule: IAttributeRule<attribute_value>): Registration {
        // TODO: Check consistency

        /*
        const existingAttribute = this.attributeModules[module.attribute.name][0]!;

        if (existingAttribute && !AttributesManager.isEqual(existingAttribute, newAttribute)) {
            consolex.log('existingAttribute', existingAttribute);
            consolex.log('newAttribute', newAttribute);
            throw new Error(
                `Trying to register attribute "${newAttribute.name}" that is not consistent with already register one "${existingAttribute.name}".`,
            );
        }

        this.attributes[newAttribute.name] = newAttribute;
        */

        //----

        this.attributesRules[rule.attribute.name] =
            this.attributesRules[rule.attribute.name] || new BehaviorSubject([]);

        return registerItemsInSubjectOfArrays({
            currentValue: this.attributesRules[rule.attribute.name].value,
            base: this.attributesRules[rule.attribute.name],
            add: [rule],
            compare: (rule1, rule2) => rule1.moduleName === rule2.moduleName /* TODO: Probbably better comparison */,
        });
    }

    /**
     *
     * Note: this is not async because when it need to do some async stuff it will return AsyncContentComponent immediatelly and load in in the fly
     * TODO: Is it a good solution?
     */
    public inputRender(
        attributeName: string_attribute,
        options?: {
            /* TODO: DRY */
            valueScope?: string_attribute_value_scope;
            overrideValue?: attribute_value;
            additionaOnChange?: (value: attribute_value) => void;
        },
    ): JSX.Element | null {
        if (
            [
                'size' /* <- TODO: Special attributes that has no inputRender but they are still there - Keep them in one place */,
            ].includes(attributeName)
        ) {
            return null;
        }

        // TODO: DRY
        const attributeInputRenders = this.attributesRules[attributeName] || new BehaviorSubject([]);

        if (!options) options = {};

        if (attributeInputRenders.value.length > 0) {
            return this.inputRenderDefined(attributeName, options); // TODO: DRY
        } else {
            return (
                /*
                TODO: !!
                Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
                in AsyncContentComponent (at 0-AttributesSystem.tsx:96)
                */
                <AsyncContentComponent
                    alt={`Input for attribute ${attributeName}`}
                    loader={<LoaderInline alt={`Input for attribute ${attributeName}`} />}
                    content={async () => {
                        await (await this.systems.attributeSupportSyncer).installSupportForAttribute(attributeName);
                        return this.inputRenderDefined(attributeName, options!); // TODO: DRY
                    }}
                />
            );
        }
    }

    private inputRenderDefined(
        attributeName: string_attribute,
        options: {
            /* TODO: DRY */
            valueScope?: string_attribute_value_scope;
            overrideValue?: attribute_value;
            additionaOnChange?: (value: attribute_value) => void;
        },
    ): JSX.Element {
        const { valueScope: context, overrideValue, additionaOnChange } = options;
        const attributesRules = this.attributesRules[attributeName];

        if (!attributesRules) {
            throw new Error(
                `There is no registered InputRender for attribute "${attributeName}". There is probbably not installed module for its support.`,
            );
        }

        return (
            <InputsRender
                {...{
                    attributesManager: this,
                    attributesRules,
                    attributeName,
                    valueScope: context,
                    overrideValue,
                    additionaOnChange,
                }}
            />
        );
    }

    private attributesValue: Record<
        string_attribute,
        Record<string_attribute_value_scope, BehaviorSubject<attribute_value>>
    > = {};

    public getAttributeValue(
        attributeName: string_attribute,
        valueScope?: string_attribute_value_scope,
    ): BehaviorSubject<attribute_value> {
        if (!valueScope) valueScope = 'DEFAULT';

        if (this.attributesRules[attributeName] && this.attributesRules[attributeName].value[0]!) {
            return this.getAttributeValueDefined(attributeName, valueScope); // TODO: DRY
        } else {
            const loadingValue = new BehaviorSubject({} /* <- TODO: What should be initial temporary value? */ as any);

            (async () => {
                await (await this.systems.attributeSupportSyncer).installSupportForAttribute(attributeName);
                const value = this.getAttributeValueDefined(attributeName, valueScope); // TODO: DRY
                loadingValue.next(value.value);
            })();

            return loadingValue;
        }
    }

    private getAttributeValueDefined(
        attributeName: string_attribute,
        valueScope: string_attribute_value_scope,
    ): BehaviorSubject<attribute_value> {
        const attributeRule = this.attributesRules[attributeName].value[0]!;

        if (!attributeRule) {
            throw new Error(
                `There is not registered attribute with name "${attributeName}". There is probbably not installed module for its support.`,
            );
        }

        if (!this.attributesValue[attributeName]) {
            this.attributesValue[attributeName] = {};
        }

        if (!this.attributesValue[attributeName][valueScope]) {
            /*consolex.info(
                `AttributesManager: Creating new default value "${attribute.defaultValue}" for attribute "${attributeName}".`,
            );*/
            this.attributesValue[attributeName][valueScope] = new BehaviorSubject(attributeRule.attribute.defaultValue);
        }

        return this.attributesValue[attributeName][valueScope];
    }

    public get registeredAttributeNames(): Array<string_attribute> {
        return Object.keys(this.attributesRules);
    }
}

/**
 * TODO: Check attribute name in correct format AS "timerControls"
 */
