import { string_module_category } from '../../../40-utils/typeAliases';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';

export function isInCategory(manifest: IModuleManifest, category: string_module_category): boolean {
    return (manifest.categories || []).includes(category);
}

/**
 * TODO: Normalize list of categories to some dictionary
 * TODO: Unit test
 */
