/**
 * Throws error if the passed options are incompatible with the passed schema.
 *
 * @param options all options
 * @param compatibleKeys list of compatible keys
 * @returns
 *
 * @collboard-modules-sdk
 */
export function checkOptionsCompatibility<TOptions extends Record<TAllowedKey, unknown>, TAllowedKey extends string>(
    options: TOptions,
    compatibleKeys: Array<TAllowedKey>,
): Pick<TOptions, TAllowedKey> {
    const keys = Object.keys(options) as Array<TAllowedKey>;
    for (const key of keys) {
        if (!compatibleKeys.includes(key)) {
            throw new Error(`Unsupported option ${key}`);
        }
    }
    return options;
}
