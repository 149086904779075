import React from 'react';
import { classNames } from '../../40-utils/classNames';

/**
 * @collboard-modules-sdk
 */
type IMenuProps =
    React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{
        /**
         * Menu orientation
         *
         * Possible values:
         * - `"horizontal"` (horizontal left to right)
         * - `"vertical"` (vertical top to bottom)
         */
        orientation: 'horizontal' | 'vertical';

        /**
         * Aditional class name set on the menu component
         */
        className?: string;

        onClick?: React.MouseEventHandler/* <- TODO: Import and use just a MouseEventHandler */ <any>;
    }>;

/**
 * Menu component
 *
 * @collboard-modules-sdk
 */
export function Menu({ orientation, className, onClick, children }: IMenuProps) {
    return (
        <div
            className={classNames(
                'menu',
                orientation === 'horizontal' ? 'menu-h' : 'menu-v',
                onClick && 'menu-clickable',
                className,
            )}
            {...{ onClick }}
        >
            {children}
        </div>
    );
}
