import { Authors } from '../../ModuleStore/Authors';
import { internalModules } from '../../ModuleStore/internalModules';
import { makeIconModuleOnRoute } from '../../ModuleStore/makers/makeIconModuleOnRoute';
import { ToolbarName } from '../../ToolbarSystem/0-ToolbarSystem';

/**
 * @deprecated in favour of @collboard/internal/add-icon
 */
internalModules.declareModule(() =>
    makeIconModuleOnRoute({
        manifest: {
            name: '@collboard/internal/import-icon',
            deprecatedNames: ['@collboard/import-icon', 'ImportIcon'],
            title: { en: 'Import external content', cs: 'Vložit externí obsah' },

            categories: ['Basic', 'Productivity'],

            author: Authors.collboard,

            flags: {
                isDeprecated: true,
            },
        },
        toolbar: ToolbarName.Tools,
        icon: {
            name: 'import',
            order: 60,

            icon: 'link',
        },
        routeParams: {
            moduleName: '@collboard/internal/import',
            modulePath: null,
        },
    }),
);

/**
 * TODO: [🐅] Maybe some more elegant way how to create icon-window pairs of modules
 */
