import { ISerializerRule } from '../interfaces/ISerializerRule';

/**
 * @@x
 */
export const pureArraySerializerRule: ISerializerRule = {
    name: 'PURE_ARRAY',

    // @@x [🕳️] Anotate both in pureObjectSerializerRule pureArraySerializerRule why for objects omiting undefined without error and in arrays throwing errors
    priority: -Infinity /* <- TODO: [🍉] One place with priorities */,
    serialize({ value, serialize, next }) {
        if (typeof value !== 'object') {
            return next();
        }

        if (!Array.isArray(value)) {
            return next();
        }

        return value.map((item) => serialize(item /* <- Note: [🕳️] When undefined this will just throw error */));
    },
    deserialize({ serialized, deserialize, next }) {
        if (typeof serialized !== 'object') {
            return next();
        }

        if (!Array.isArray(serialized)) {
            return next();
        }

        return serialized.map((item) => deserialize(item));
    },
};

/**
 * TODO: [🧠] Maybe rename to just arraySerializerRule
 * TODO: setSerializerRule
 * TODO: [🌉] In future use satisfies ISerializerRule
 */
