import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { toArray } from '../../../40-utils/toArray';
import { Arrayable } from '../../../40-utils/typeHelpers';
import { DEBUG_CSS_RULE } from '../../../config';
import { consolexBase } from '../../../consolex';
import { string_animation } from '../../menu/animations';
import { string_icon } from '../../menu/icons';
import { ILoaderProps } from './ILoaderProps';
import { useTimeout } from './utils/useTimeout';

interface ILoaderInlineProps extends ILoaderProps {
    /**
     * Icon to be shown
     */
    icon?: string_icon;

    /**
     * Animation or array of animations to be played
     *
     * Note: That some animations overrides the icon
     *
     *
     */
    animation?: Arrayable<string_animation>;

    /**
     * Content after the loading icon
     */
    children?: ReactNode;
}

/**
 * @collboard-modules-sdk
 */
export function LoaderInline({ children, icon, animation, alt, canLoadForever }: ILoaderInlineProps) {
    useTimeout({ duration: canLoadForever ? Infinity : 3000, alt });

    return (
        <LoaderInlineContainer
            {...{ icon, animation }}
            onClick={() => consolexBase.group(`🔄 Loading of "${alt}"`).trace().end()}
        >
            <div className="spinner-inline">&nbsp;</div> <div className="spinner-inline-text">{children}</div>
        </LoaderInlineContainer>
    );
}

const LoaderInlineContainer = styled.div<Pick<ILoaderInlineProps, 'icon' | 'animation'>>`
    ${DEBUG_CSS_RULE}
    margin-left: 10px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;

    .spinner-inline {
        ${DEBUG_CSS_RULE}
        display: inline-block;
        vertical-align: middle;

        width: 2em;
        height: 2em;
        ${({ icon }) => icon && `background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.46/icons/${icon}@2x.png');`}
        filter: invert(50%) blur(0.5px);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        animation-name: ${({ animation }) => toArray(animation).join(', ')};
        animation-duration: 4s;
        animation-iteration-count: infinite;
    }

    .spinner-inline-text {
        ${DEBUG_CSS_RULE}
        display: inline-block;
        vertical-align: middle;
    }
`;

/**
 * TODO: [☮️] Implement new loading /handwritten/ design
 */
