import { string_module_name, string_version } from '../../40-utils/typeAliases';
import { clientVersion } from '../../config';

// TODO: Write documentation
export interface IModuleSignature {
    // TODO: Probably move to Module folder
    name: string_module_name;
    version?: string_version;
}
export const CORE_MODULE_SIGNATURE: IModuleSignature = {
    // TODO: Probably move to Module folder
    name: 'core',
    version: clientVersion,
};
