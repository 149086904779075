import { string_css } from '../../typeAliases';

/**
 * Parses styled components styles from html
 *
 * @returns CSS styles as a string
 * @sideeffect Not pure - reads from window.document style elements
 * @collboard-modules-sdk
 */
export function getGlobalStyles(): string_css {
    const styledComponentsStyleElement = window.document.querySelector('style[data-styled="active"]');

    if (!styledComponentsStyleElement) {
        return ``;
    }

    return styledComponentsStyleElement.innerHTML;
}

/**
 * Note: Originally this was a function parseGlobalStyles which used css-tree to parse css but it is unnecessary and too heavy so it was transformed to getGlobalStyles
 *       Last commit with this is a0d06c564cee94f9d7b88de59f7d3067149d9a65
 */
