import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';

export function getModuleSortingPriority(manifest: IModuleManifest): number {
    let priority = 0;

    if (!manifest) {
        return 0;
    }

    if (
        manifest.categories &&
        manifest.categories.some((category2) => category2.toUpperCase() /* TODO: Some normalization */ === 'BASIC')
    ) {
        priority += 10;
    }

    if (
        manifest.categories &&
        manifest.categories.some(
            (category2) => category2.toUpperCase() /* TODO: Some normalization */ === 'EXPERIMENTAL',
        )
    ) {
        priority -= 100;
    }

    return priority;
}
