import fetch from 'cross-fetch'; /* <- TODO: [🌿] Use the Node native fetch */
import spaceTrim from 'spacetrim';
import { blobToFile } from '../files/blobToFile';
import { dataurlToBlob } from '../files/dataurlToBlob';
import { isValidDataurl } from '../files/isValidDataurl';
import { isValidUrl } from '../isValidUrl';
import { shorten } from '../shorten';
import { string_url } from '../typeAliases';
import { fetchProxied } from './fetchProxied';

/**
 * Makes GET request* and return response as a file.
 *
 * Tip: You can pass dataurl here and then * it converts it to a File
 *
 * @collboard-modules-sdk
 */
export async function fetchAsFile(url: URL | string_url, proxyUrl?: URL | string_url): Promise<File> {
    const urlString = url.toString();

    if (!isValidUrl(urlString)) {
        throw new Error(
            spaceTrim(`
                Can not fetch as file invalid url

                Invalid url:
                ${shorten(urlString, 100)}
            `),
        );
    }

    if (isValidDataurl(urlString)) {
        return await blobToFile(await dataurlToBlob(urlString));
    }

    const response = !proxyUrl ? await fetch(urlString) : await fetchProxied(urlString, proxyUrl.toString());

    const content = await response.blob();
    const name =
        response.headers.get('content-disposition')?.split('filename=')[1]! ||
        new URL(urlString).pathname.split('/').pop() ||
        'untitled';

    const type = response.headers.get('content-type') || 'application/octet-stream';
    return new File([content], name, { type });
}

/**
 * TODO: Probably some other way to pass proxyUrl - maybe with high order function?
 */
