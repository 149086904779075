import React from 'react';
import { induceFileDownload } from '../../../../40-utils/files/induceFileDownload';
import { useSystems } from '../../../../40-utils/react-hooks/useSystems';
import { ExportScopePickerComponent } from '../../../../50-systems/ExportSystem/components/0-ExportScopePickerComponent';
import { ExportScopeSimple, IExportScope } from '../../../../50-systems/ExportSystem/interfaces/IExportScope';
import { HtmlPreviewFileComponent } from './HtmlPreviewFileComponent';

/**
 *
 * Note: Need to be used inside of a module
 *
 * @not-collboard-modules-sdk too specific that is used only in @collboard/internal/html
 */
export function HtmlPreviewComponent() {
    const { appState, exportSystem, translationsSystem } = useSystems('appState', 'exportSystem', 'translationsSystem');
    const [scope, setScope] = React.useState/* <- TODO: Import and use just a useState */ <IExportScope>(
        ExportScopeSimple.Board,
    );

    return (
        <>
            <ExportScopePickerComponent
                {...{ exportSystem, translationsSystem, appState }}
                value={scope}
                onChange={setScope}
            />

            <button
                className="button button-primary"
                onClick={async () => {
                    const iframeElement = document.querySelector('.html-preview iframe') as HTMLIFrameElement;
                    window.open(
                        iframeElement.src,
                        '_blank',
                        `location=yes,height=570,width=520,scrollbars=yes,status=yes`,
                    );
                }}
            >
                Popup ↗️
            </button>

            <button
                className="button button-primary"
                onClick={async () => {
                    const iframeElement = document.querySelector('.html-preview iframe') as HTMLIFrameElement;
                    window.open(iframeElement.src, '_blank');
                }}
            >
                New tab ↗️
            </button>

            <button
                className="button button-primary"
                onClick={async () => {
                    const iframeElement = document.querySelector('.html-preview iframe') as HTMLIFrameElement;
                    await induceFileDownload(iframeElement.src);
                }}
            >
                Download 💾
            </button>

            <div className="html-preview">
                <HtmlPreviewFileComponent {...{ exportSystem, scope }} />
            </div>
        </>
    );
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
