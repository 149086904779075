import { ILogger, ISubLogger } from './ILogger';

export interface IRecordLoggerLog {
    // TODO: Unite IRecordLoggerLog.type and IFilterLoggerOptions.predicate.type
    type: 'INFO' | 'WARN' | 'ERROR' | 'TRACE';
    message: any;
    optionalParams: Array<any>;
}
export class RecordLogger implements ILogger, ISubLogger {
    private _logs: Array<IRecordLoggerLog> = [];
    private _isEnded = false;

    public get logsStructured(): Array<IRecordLoggerLog> {
        return this._logs;
    }

    public get logs(): string {
        return this._logs.map(({ type, message }) => `[${type}] ${message}`).join('\n');
    }

    public info(message?: any, ...optionalParams: Array<any>): this {
        this._logs.push({ type: 'INFO', message, optionalParams });
        return this;
    }
    public warn(message?: any, ...optionalParams: Array<any>): this {
        this._logs.push({ type: 'WARN', message, optionalParams });
        return this;
    }
    public error(message?: any, ...optionalParams: Array<any>): this {
        // TODO: !! Record stack trace
        this._logs.push({ type: 'ERROR', message, optionalParams });
        return this;
    }

    public trace(...data: Array<any>): this {
        // TODO: !! Record stack trace
        this._logs.push({ type: 'TRACE', message: data, optionalParams: [] });
        return this;
    }

    public get isEnded(): boolean {
        return this._isEnded;
    }

    public appear(): this {
        // Note: Do nothing here
        return this;
    }

    public end(): void {
        if (this._isEnded) {
            throw new Error('RecordLogger was already ended');
        }
        this._isEnded = true;
    }

    public group(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        return this;
    }

    public groupCollapsed(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        return this;
    }
}

/**
 * TODO: Probably rename logs to logsText and logsStructured to logs
 * TODO: Better support for the groups
 */
