/**
 * @@x
 *
 * @param input
 * @returns
 *
 * @collboard-modules-sdk
 */
export function isPureObject(input: unknown): input is Record<string, unknown> {
    if (input === null) {
        return false;
    }

    if (typeof input !== 'object') {
        return false;
    }

    if (!Object.getPrototypeOf(input).isPrototypeOf(Object)) {
        return false;
    }

    /*
    TODO: [🥗]
    if (!Object.keys(input as Record<any, unknown>).every((key) => typeof key === 'string')) {
        return false;
    }
    */

    return true;
}

/**
 * TODO: Test with non-string keys
 * TODO: Better name than pure, maybe base, basic, abstract,...
 * TODO: [🥗] Return false for objects with non-string keys, this feature worked but suddenly stopped somewhere before or just at commit 2e42af00f5ef410c0e8b13ea3f815c481682c50d
 */
