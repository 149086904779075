import { IDestroyable } from 'destroyable';
import React, { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { randomJavascriptName } from '../../40-utils/randomJavascriptName';
import { EditableHtmlContent } from './EditableHtmlContent';

/**
 * Shows the content with optional contenteditable optimized for multiplayer apps
 */
export function EditablePlayground() {
    const [focusedSection, setFocusedSection] = useState('HTML2');
    const [html, setHtml] = useState(`Hello <b>World</b>`);
    const [text, setText] = useState(`Hello <b>World</b>`);

    useEffect(() => {
        // console.log(`Running copilotMonkeys`);
        const htmlSubject = new BehaviorSubject(html);
        const subscription = htmlSubject.subscribe(setHtml);
        const copilot = copilotMonkey(htmlSubject);
        return () => {
            subscription.unsubscribe();
            copilot.destroy();
        };
    }, [html]);

    return (
        <>
            <h2>EditableHtmlContent:</h2>
            <button onClick={() => setFocusedSection('HTML1')}>focus</button>
            <EditableHtmlContent html={html} isEditableAndFocused={focusedSection === 'HTML1'} onHtmlChange={setHtml} />
            <EditableHtmlContent
                html={html}
                isEditableAndFocused={false}
                onHtmlChange={setHtml}
                style={{ opacity: 0.5 }}
            />
            <hr />
            <h2>EditableHtmlContent with isHtmlPasteAllowed:</h2>
            <button onClick={() => setFocusedSection('HTML2')}>focus</button>
            <EditableHtmlContent
                html={html}
                isEditableAndFocused={focusedSection === 'HTML2'}
                onHtmlChange={setHtml}
                isHtmlPasteAllowed
            />
            <EditableHtmlContent
                html={html}
                isEditableAndFocused={false}
                onHtmlChange={setHtml}
                style={{ opacity: 0.5 }}
            />
        </>
    );
}

/**
 * Copilot monkey will randomly edit the given text if some random user-like interval:
 *
 * - It will write text at the end
 * - It will write a text in the middle/begining
 * - It will delete text
 * - It will cut/copy chunks of the text
 * - Each action can be made with simple caret or selection
 *
 * Note: Text can be edited by multiple acters at once
 *
 * @param editable
 * @return object which represents the copilot which can be destroyed=turned off
 * @see https://sharegpt.com/c/QI2fgGz
 */
function copilotMonkey(editable: BehaviorSubject<string>): IDestroyable {
    let isDestroyed = false;
    const actions = ['insertEnd', 'insertMiddle', 'delete', 'cutCopy'];

    const getRandomIndex = () => Math.floor(Math.random() * editable.getValue().length);
    const getRandomAction = () => actions[Math.floor(Math.random() * actions.length)];

    const edit = () => {
        if (isDestroyed) {
            return;
        }
        const text = editable.getValue();
        const index = getRandomIndex();
        const action = getRandomAction();

        switch (action) {
            case 'insertEnd':
                const newText = text + ' ' + randomJavascriptName({ length: Math.random() * 10 });
                editable.next(newText);
                break;
            case 'insertMiddle':
                const before = text.substring(0, index);
                const after = text.substring(index);
                const middleText = ' ' + randomJavascriptName({ length: Math.random() * 10 }) + ' ';
                const newMiddleText = before + middleText + after;
                editable.next(newMiddleText);
                break;
            case 'delete':
                const newTextWithoutChar = text.substring(0, index) + text.substring(index + 1);
                editable.next(newTextWithoutChar);
                break;
            case 'cutCopy':
                const startIndex = getRandomIndex();
                const endIndex = getRandomIndex();
                const start = Math.min(startIndex, endIndex);
                const end = Math.max(startIndex, endIndex);
                const cutCopyText = text.substring(start, end);
                const newTextWithCutCopy = text.substring(0, start) + cutCopyText + text.substring(end);
                editable.next(newTextWithCutCopy);
                break;
            default:
                break;
        }
        setTimeout(edit, 1000 + 2000 * Math.random());
    };

    setTimeout(edit, 100);

    return {
        get isDestroyed() {
            return isDestroyed;
        },
        destroy: () => {
            // TODO: Clear the timeout
            isDestroyed = true;
        },
    };
}
