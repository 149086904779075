import { blackholeStorage, BrowserHistoryQueryStorage, BrowserHistoryUrlStorage, IBrowserHistoryStorageOptions, IJson, IObservableStorage } from 'everstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { string_uri_part } from '../../40-utils/typeAliases';
import { RemoveIndex } from '../../40-utils/typeHelpers';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { StorageSystem } from '../StorageSystem/StorageSystem';
import { decodeUrl } from './routePath/decodeUrl';
import { encodeUrl } from './routePath/encodeUrl';
import { DEFAULT_URL_VARIABLES, IUrlVariables } from './routePath/IUrlVariables';

//import URI from 'urijs';

/**
 * @singleton
 */
export let routingSystem: RoutingSystem;

/**
 * RoutingSystem provides for core, other systems and modules registration of routes and hashtag routes.
 * @see https://github.com/collboard/collboard/issues/97
 * TODO: What about #hash routes
 *
 * @collboard-system
 */
export class RoutingSystem extends AbstractSystem {
    /**
     * Original url from startup of the application
     * Note: Collboard normalizes URL and this method is a way how to get the original query params and other things in the URL
     */
    public readonly originalUrl: URL = new URL(window.location.toString());

    public readonly viewUriId = new BehaviorSubject<string_uri_part | null>(null); //.pipe(publish());
    public readonly viewUrl = new Observable<URL>((subscribe) =>
        /* [🏔️]  */
        routingSystem.viewUriId
            .pipe(
                map((uriId) =>
                    encodeUrl({
                        ...decodeUrl(this.selfUrl),
                        uriId,
                    }),
                ),
            )
            .subscribe(subscribe),
    );

    private storageSystem: StorageSystem;

    public get selfUrl(): URL {
        /* TODO: Make it load probbably from API - solve with apiUrl */
        const selfUrl = new URL(window.location.toString());
        selfUrl.search = '';
        selfUrl.hash = '';
        selfUrl.pathname = '';
        return selfUrl;
    }

    protected async init() {
        // TODO: Delete this subscription with system
        this.urlVariables.values.subscribe((value) => {
            /*/
            const group = consolex.groupCollapsed(
                // [🔅]
                '%c' + encodeUrl(value),
                `background: #cccccc; font-size: 0.8em; font-weight: bold; padding: 3px; border-radius: 1px;`,
            );

            group.info(
                // TODO: [🐋]  Make support for table in ILogger
                value,
            );
            group.end();
            /**/
        });

        routingSystem = this;
        this.storageSystem = await this.systems.storageSystem;
    }

    readonly urlVariables: BrowserHistoryUrlStorage<RemoveIndex<IUrlVariables>> = new BrowserHistoryUrlStorage<
        RemoveIndex<IUrlVariables>
    >(
        decodeUrl,
        encodeUrl,
        DEFAULT_URL_VARIABLES,
        {
            saveToHistory: true,
            saveToStorage: false,
            debounceInterval: 50,
            uniqueIdentifier: 'RoutingSystem',
        },
        blackholeStorage /* TODO: Probably more elegant way in LIB everstorage when saveToStorage is false */,
    );

    public navigateHome() {
        return this.urlVariables.setValue(this.home);

        /*
        TODO: More elegant syntax like:
              return this.urlVariables.setValue({
                  ...DEFAULT_URL_VARIABLES,
                  ...pick( this.urlVariables.value, ['protocol', 'host', 'businessName']),
              });
        */
    }

    public get homeUrl(): URL {
        return this.urlVariables.createLink(this.home);
    }

    private get home(): RemoveIndex<IUrlVariables> {
        return {
            ...DEFAULT_URL_VARIABLES,
            protocol: this.urlVariables.value.protocol,
            host: this.urlVariables.value.host,
            businessName: this.urlVariables.value.businessName,
        };
    }

    /**
     * TODO: !! Use this in all places
     */
    public navigateBoardHome() {
        // TODO: throw error if not on board
        return this.urlVariables.setValue({ moduleName: undefined, modulePath: undefined });
    }

    /**
     *  TODO: Make this save to history and check that is is working together with urlVariabiles in routingSystem
     */
    public registerQuery<TValue extends IJson>(
        defaultParams: TValue,
        options?: Partial<IBrowserHistoryStorageOptions>,
    ): IObservableStorage<TValue> {
        const scope = this.urlVariables.value.uriId;

        if (!scope) {
            throw new Error(`You should use RoutingSystem.registerQuery when board selected.`);
        }

        return new BrowserHistoryQueryStorage<TValue>(defaultParams, options, this.storageSystem.getStorage(scope));
    }
}

/**
 * TODO: For URLs (view, edit...) should be probbably some better system
 * TODO: [🌵] Choose between boardSystem.navigateBoard(null) VS routingSystem.navigateBoardHome()
 */
