export function messageEventToModuleScope(event: MessageEvent): string {
    if (event.origin === null || event.origin === 'null') {
        return `unknown`;
    }

    // TODO: What is the best way to convert origin (url) to module scope?
    return event.origin
        .replace(/^https?:\/\//, '')
        .split('[.:]')
        .join('-');
}
