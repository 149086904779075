import { IStorage } from 'everstorage';
import { BehaviorSubject } from 'rxjs';
import { SetRequired } from 'type-fest';
import { forCondition } from '../../00-lib/waitasecond/forCondition';
import { DuplicateError } from '../../40-utils/errors/DuplicateError';
import { InvalidError } from '../../40-utils/errors/InvalidError';
import { string_license_token } from '../../40-utils/typeAliases';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { IModuleManifest } from '../ModuleStore/interfaces/IModuleManifest/IModuleManifest';
import { IModuleManifestUsageLicense } from '../ModuleStore/interfaces/IModuleManifest/IModuleManifestUsageLicense';
import { IModuleManifestUsageLicensePayed } from '../ModuleStore/interfaces/IModuleManifest/IModuleManifestUsageLicensePayed';
import { IUsageLicenseInfo } from './interfaces/IUsageLicenseInfo';

/**
 * LicenseSystem is a system that manages the licenses for modules
 *
 * @see more on https://github.com/collboard/collboard/blob/main/documents/license-system.md
 * @collboard-system
 */
export class LicenseSystem extends AbstractSystem {
    private storage: IStorage<Array<string_license_token>>;

    public readonly licensesTokens = new BehaviorSubject<Set<string_license_token>>(new Set());

    protected async init() {
        this.storage = (await this.systems.storageSystem).getStorage<Array<string_license_token>>(`LicenseSystem`);
        const savedLicenses = (await this.storage.getItem('licensesTokens')) || [];

        this.licensesTokens.subscribe(async (licensesTokens) => {
            // TODO: This is not a efficient way to wait for some moment in the app livecycle - make for this some system which tracks and allows to check and await for app loading milestones
            await forCondition(async () => (await this.systems.moduleStore).connectors.length !== 0);

            // TODO: Maybe debounce here
            await (await this.systems.licenseSyncer).syncSupportForLicenses(...Array.from(licensesTokens));
        });

        this.licensesTokens.next(new Set(savedLicenses));

        await this.activateLicenseTokensFromGetParams();
    }

    private async activateLicenseTokensFromGetParams() {
        const addLicenses = ((await this.systems.routingSystem).originalUrl.searchParams.get('add-license') || '')
            .split(',')
            .filter((licenseToken) => licenseToken.trim() !== '');

        for (const license of addLicenses) {
            // TODO: [🥇] When error should be crash here OR just show warning and go on
            await this.activateLicenseToken(license);
        }
    }

    public async activateLicenseToken(licenseToken: string_license_token) {
        if (this.licensesTokens.value.has(licenseToken)) {
            throw new DuplicateError(`License token "${licenseToken}" is already activated`);
        }

        if (licenseToken.length < 3 /* <- TODO: [🥇] Better checking of license tokens */) {
            // TODO: [🥇][🥇0][🥇1]  Check if license exists on some module OR make some checksum in the license token
            throw new InvalidError(`License token "${licenseToken}" is not valid`);
        }

        const licensesTokens = new Set(this.licensesTokens.value);
        licensesTokens.add(licenseToken);

        // TODO: [🥇] Maybe do this also via subscribing on this.licensesTokens + DRY [1]
        // TODO: [🥇] Debounce to save only once + DRY [1]
        await this.storage.setItem('licensesTokens', Array.from(licensesTokens));

        this.licensesTokens.next(licensesTokens);
    }

    public async removeLicenseToken(licenseToken: string_license_token) {
        // TODO: [🥇]  Check if license already activated - if no warn or error

        const licensesTokens = new Set(this.licensesTokens.value);
        licensesTokens.delete(licenseToken);

        // TODO: [🥇] Maybe do this also via subscribing on this.licensesTokens + DRY [1]
        // TODO: [🥇] Debounce to save only once + DRY [1]
        await this.storage.setItem('licensesTokens', Array.from(licensesTokens));

        this.licensesTokens.next(licensesTokens);
    }

    public async getModuleLicences(moduleManifest: IModuleManifest): Promise<Array<IModuleManifestUsageLicense>> {
        // Note: keeping this async to allow server-checks to be done in future
        const licenses: Array<IModuleManifestUsageLicense> = moduleManifest.usageLicenses || [{ type: 'FREE' }];

        // TODO: await whenReady

        return licenses
            .filter((usageLicense) => {
                if (usageLicense.type === 'FREE') {
                    return true;
                } else if (usageLicense.type === 'SIMPLE_TOKEN') {
                    return this.licensesTokens.value.has(usageLicense.token);
                } else {
                    return false;
                }
            })
            .sort((a, b) => {
                // Note: Returning free at last place
                // TODO: Some funtionality to add importance of the license

                if (a.type === 'FREE') {
                    return 1;
                }

                return 0;
            });
    }

    public async getModuleLicenceInfo(moduleManifest: IModuleManifest): Promise<IUsageLicenseInfo> {
        // Note: keeping this async to allow server-checks to be done in future

        const licenses = await this.getModuleLicences(moduleManifest);

        if (licenses.length === 0) {
            const payedLicenses = (moduleManifest.usageLicenses || []).filter(
                ({ type }) => type !== 'FREE',
            ) as Array<IModuleManifestUsageLicensePayed>;
            const licensesToBuy = payedLicenses.filter(({ buyLink }) => buyLink) as Array<
                SetRequired<IModuleManifestUsageLicensePayed, 'buyLink'>
            >;

            return {
                isValid: false,
                licensesToBuy,
            };
        } else {
            const license = licenses[0]!;
            return {
                isValid: true,
                license,
            };
        }
        // TODO: In future not the first license but aggregate best of all licenses
    }

    public async hasModuleValidLicence(moduleManifest: IModuleManifest): Promise<boolean> {
        // Note: Keeping both hasModuleLicence and getModuleLicenceInfo to allow more optimal usage in future
        // Note: keeping this async to allow server-checks to be done in future

        return await (
            await this.getModuleLicenceInfo(moduleManifest)
        ).isValid;
    }
}

/**
 * TODO: Sign token and also sign date limitations
 * TODO: Maybe create DuplicateLicenseError and InvalidLicenseError
 */
