import { createPreviewElement } from './createPreviewElement';

/**
 * Creates simple TESTING preview overlay on top of the document
 *
 * @collboard-modules-sdk
 */
export function preview(subject: HTMLElement | File | Blob | JSX.Element | string): void {
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
    overlay.style.display = 'flex';
    overlay.style.alignItems = 'center';
    overlay.style.justifyContent = 'center';
    overlay.style.zIndex = '99999999999';

    const container = document.createElement('div');
    container.style.border = '1px solid #ff0000';

    overlay.addEventListener('click', () => {
        overlay.remove();
    });

    container.appendChild(createPreviewElement(subject));
    overlay.appendChild(container);
    window.document.body.appendChild(overlay);
}

/**
 * TODO: Prevent using this in production (probbably via linting)
 * TODO: Probably return destroyable
 */

// Note: To test uncomment one of blocks below:
/*/
// Note: Testing HTMLElement
preview(
    (() => {
        const img = document.createElement('img');
        img.src =
            'https://collboard.fra1.cdn.digitaloceanspaces.com/usercontent/education/image/jpeg/c/a/68/Van_Gogh_-_Starry_Night_-_Google_Art_Project%20(1).jpg';
        return img;
    })(),
);
/**/
/*/
// Note: Testing Blob (same as File)
(async () => {
    preview(new Blob(['Hello world'], { type: 'text/plain' }));
})();

/**/
/*/
// Note: Testing JSX
preview(
    <img
        alt="Van Gogh - Starry Night"
        src="https://collboard.fra1.cdn.digitaloceanspaces.com/usercontent/education/image/jpeg/c/a/68/Van_Gogh_-_Starry_Night_-_Google_Art_Project%20(1).jpg"
    />,
);
/**/
/*/
// Note: Testing string
preview('Hello world!');
/**/
