import { string_mime_type } from '../typeAliases';
import { findPropperFileType } from './findPropperFileType';

/**
 * Checks if the given file is executable.
 *
 * - Windows executables are identified as executable
 * - Javascript executables are identified as executable
 * - CSS Styleshees are considered as executable
 * - ⚠️ It is not bulletproof - Other languages can be marked as non-executables
 *
 * @param file checked file or its mime type
 * @returns true if file is executable
 *
 * @collboard-modules-sdk
 */
export async function isFileExecutable(fileOrType: File | Blob | string_mime_type): Promise<boolean> {
    const type = typeof fileOrType === 'string' ? fileOrType : await findPropperFileType(fileOrType);

    // @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types

    /*
    Note: We are not marking all application/* types as executable because this will mark lof of false positives like PDFs.
    if (/^application\/.*$/.test(type)) {
        return true;
    }
    */

    if (type === 'application/octet-stream') {
        // Windows executables, other unknown files
        return true;
    }

    if (/^.*\/javascript$/.test(type)) {
        return true;
    }

    if (/^.*\/css$/.test(type)) {
        // Note: Not sure if CSS files should be considered as executables or not
        return false;
    }

    return false;
}
