import React from 'react';
import { factor } from '../IFactory';
import { ITrayDynamicDefinition } from './interfaces/ITrayDynamicItemTypes';
import { RenderTrayItem } from './RenderTrayItem';

interface ITrayItemTemplateProps {
    scale: number;
    id: string;
    setSelectedItemId: (newId: string | null) => void;
    trayDefinition: ITrayDynamicDefinition;
}

export function TrayItemTemplate(props: ITrayItemTemplateProps) {
    return (
        <div
            className="trayToolbarItem"
            onMouseOver={() => {
                props.setSelectedItemId(props.id);
            }}
            onMouseOut={() => {
                props.setSelectedItemId(null);
            }}
            onPointerEnter={() => {
                props.setSelectedItemId(props.id);
            }}
            onPointerLeave={() => {
                props.setSelectedItemId(null);
            }}
        >
            {RenderTrayItem(
                factor(props.trayDefinition.getItems),
                props.trayDefinition.className,
                props.id,
                props.scale,
            )}
        </div>
    );
}
