import React from 'react';
import { merge, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Menu } from '../../30-components/menu/Menu';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { ControlSystem } from '../ControlSystem/ControlSystem';
import { FocusSystem } from '../FocusSystem/0-FocusSystem';
import { UserInterfaceSystem } from '../UserInterfaceSystem/0-UserInterfaceSystem';
import { UserInterfaceElementPlace } from '../UserInterfaceSystem/UserInterfaceElementPlace';
import { IconsToolbar } from './IconsToolbar';
import { IconsToolbarComponent } from './IconsToolbarComponent';
import { IconsToolbarMenuComponent } from './IconsToolbarMenuComponent';
import { IToolbarIcon } from './IToolbarIcon';

/**
 *
 * TODO: Unite naming ToolbarName, which can be part of IToolbarIcon (similar as section on it) OR combine it with UserInterfaceElementPlace
 *
 * @deprecated [🍿] Do not use enums but object as const OR 'LITERAL VALUES' instead
 *
 * @collboard-modules-sdk
 */
export enum ToolbarName {
    Tools = 'TOOLS',
    Navigation = 'NAVIGATION',
    Social = 'SOCIAL',
}

/**
 * ToolbarSystem can register and manage toolbars and icons which there are.
 * TODO: Destroy all its IconsToolbar with it
 *
 * @collboard-system
 */
export class ToolbarSystem extends AbstractSystem {
    private toolbars: Partial<Record<ToolbarName, IconsToolbar>> = {};
    private focusSystem: FocusSystem;
    private controlSystem: ControlSystem;
    private userInterfaceSystem: UserInterfaceSystem;

    protected async init() {
        this.controlSystem = await this.systems.controlSystem;
        this.focusSystem = await this.systems.focusSystem;
        this.userInterfaceSystem = await this.systems.userInterfaceSystem;

        for (const toolbarName of Object.values(ToolbarName)) {
            this.getToolbar(toolbarName);
        }

        this.addToolbarsToUserInterface();
    }

    private addToolbarsToUserInterface(): void {
        this.addSubdestroyable(
            this.userInterfaceSystem.registerElement({
                systems: this.systems,
                place: UserInterfaceElementPlace.EdgeBottom,
                order: -1,
                element: (
                    <>
                        {/* [🎲] */}
                        <Menu orientation="horizontal">{this.renderToolbar(ToolbarName.Tools)}</Menu>
                        {this.renderMenuOfActiveIcon(ToolbarName.Tools)}
                    </>
                ),
            }),
        );
        // }

        this.addSubdestroyable(
            this.userInterfaceSystem.registerElement({
                systems: this.systems,
                place: UserInterfaceElementPlace.EdgeRight,
                element: <Menu orientation="vertical">{this.renderToolbar(ToolbarName.Navigation)}</Menu>,
            }),
        );

        this.addSubdestroyable(
            this.userInterfaceSystem.registerElement({
                systems: this.systems,
                place: UserInterfaceElementPlace.EdgeTop,
                element: (
                    <Menu orientation="horizontal" className="d-none d-sm-block">
                        {this.userInterfaceSystem.render(UserInterfaceElementPlace.Social) /* <- [👩‍👩‍👦] */}
                        {this.renderToolbar(ToolbarName.Social)}
                    </Menu>
                ),
            }),
        );
    }

    /**
     * Renders the toolbar
     *
     */
    private renderToolbar(toolbarName: ToolbarName): JSX.Element {
        return <IconsToolbarComponent iconsToolbar={this.getToolbar(toolbarName)} />;
    }

    /**
     * Renders the menu of currently active icon in the toolbar
     *
     */
    private renderMenuOfActiveIcon(toolbarName: ToolbarName): JSX.Element {
        return <IconsToolbarMenuComponent iconsToolbar={this.getToolbar(toolbarName)} />;
    }

    /**
     * Returns the toolbar
     *
     * Note: If you want to render the toolbar use renderToolbar or renderMenu instead
     */
    public getToolbar(toolbarName: ToolbarName): IconsToolbar {
        if (!this.toolbars[toolbarName]) {
            this.toolbars[toolbarName] = new IconsToolbar(toolbarName, this.controlSystem, this.focusSystem);
        }
        return this.toolbars[toolbarName]!;
    }

    /**
     * List all the toolbars
     */
    public getAllToolbars(): Record<ToolbarName, IconsToolbar> {
        this.checkIsReady(/* TODO: Use in more systems */);
        // TODO: Probbably create all [🍿] enum toolbars as empty before returning
        return this.toolbars as Record<ToolbarName, IconsToolbar>;
    }

    /**
     * All actions from all toolbars will be merged into one observable stream
     */
    public readonly allActiveIcons = new Observable<Array<IToolbarIcon>>((subscribe) =>
        /* [🏔️]  */
        merge(...Object.values(this.getAllToolbars()).map((toolbar) => toolbar.icons))
            .pipe(map((icons) => icons.flat().filter(({ isActive }) => isActive)))
            .pipe(
                filter(
                    (icons) =>
                        icons.length !==
                        0 /* <- Note: This is trick how not to render BoardComponent for every tool change twice */,
                ),
            )

            .subscribe(subscribe),
    );

    public async destroy() {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        for (const toolbar of Object.values(this.toolbars)) {
            await toolbar?.destroy();
        }
    }
}

/**
 * TODO: [0] Some better way to decide if the icon has editing capabilities or not
 * TODO: [🎲] It is a bit inconsistent that renderMenu wraps content with <Menu but renderToolbar not
 * TODO: Add method allIcons and use it internally in allActiveIcons
 * Note: [👩‍👩‍👦] This is a bit strange piece of logic:
 *             1) TollbarSystem registers some own components into UserInterfaceSystem
 *             2) In one of this components there is rendered the place from UserInterfaceSystem
 *             Maybe solve this better (non-cyclical way) in the future
 */
