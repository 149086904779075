import * as _configchecker from 'configchecker';
import * as _destroyable from 'destroyable';
import * as _React from 'react';
import * as _styledComponents from 'styled-components';
import * as _touchcontroller from 'touchcontroller';

/**
 * Note: Multiple libraries are reexported for the modules via modules SDK. It has two reason:
 *       1. Some libraries (like React) are not compatible in the same application running two separate instances.
 *       2. To save resources
 *
 * TODO: !! Put this description into modules SDK
 */

/**
 * @collboard-modules-sdk
 */
export const configchecker = _configchecker;

/**
 * @collboard-modules-sdk
 */
export const destroyable = _destroyable;

/**
 * @collboard-modules-sdk
 */
export const React = _React;

/**
 * Styled components all export
 * > import * as styledComponents from 'styled-components';
 * @collboard-modules-sdk
 */
export const styledComponents = _styledComponents;

/**
 * Styled components default export
 * > import styled from 'styled-components';
 * @collboard-modules-sdk
 * @alias of styledComponents
 */
export const styled = _styledComponents.default;

/**
 * @collboard-modules-sdk
 */
export const touchcontroller = _touchcontroller;

/**
 * @collboard-modules-sdk
 */
export const libraries = {
    configchecker: _configchecker,
    destroyable: _destroyable,
    React: _React,
    styledComponents: _styledComponents,
    styled: _styledComponents,
    touchcontroller: _touchcontroller,
};

/**
 * @collboard-modules-sdk
 * @alias of libraries
 */
export const libs = libraries;

/**
 * TODO: Probably alse add:
 *  - "everstorage"
 *  - "json5"
 *  - "lodash"
 *  - "mime-types"
 *  - "moment"
 *  - "papaparse"
 *  - "react-dom"
 *  - "showdown"
 *  - "socket.io-client"
 *  - "spacetrim"
 *  - "uuid"
 *  - "waitasecond"
 *  - "xyzt"
 */
