import React from 'react';
import { classNames } from '../../40-utils/classNames';
import { NonBrakingSpaces } from '../../40-utils/react-components/NonBrakingSpaces';
import { useMeasure } from '../../40-utils/react-hooks/useMeasure';
import { IIconAnonymous } from './IIconAnonymous';

interface IIconTextProps extends Omit<IIconAnonymous, 'icon'> {
    children: string;
    // TODO: isVertical?: boolean;
}

/**
 * Icon with text
 *
 * Note: If not rendered within a menu, there is no guarantee
 * of being rendered correctly
 *
 * @collboard-modules-sdk
 */
export function IconText(props: IIconTextProps) {
    const { className, active, inactive, children, onClick: onClickInner } = props;

    // TODO: Some hook util for sync sizes
    const resizeRef = React.useRef/* <- TODO: Import and use just a useRef */ <HTMLDivElement>(null);
    const measureRef = useMeasure<HTMLDivElement>((size) => {
        if (resizeRef.current) {
            resizeRef.current.style.minWidth = size.x + 'px';
        }
    });

    return (
        <div className={classNames('icon', active && 'active', inactive && 'inactive', className)} ref={resizeRef}>
            <div
                className="click-detector"
                onClick={() => {
                    if (!inactive && onClickInner) {
                        onClickInner();
                    }
                }}
            >
                <span ref={measureRef}>
                    <NonBrakingSpaces>{`  ${children}  `}</NonBrakingSpaces>
                </span>
            </div>
        </div>
    );
}
