import styled from 'styled-components';
import { Color } from '../../../40-utils/color/Color';
import { ISkin } from '../../../50-systems/StyleSystem/ISkin';

export const ModalGroupTitleStyledDiv = styled.div<{ skin: ISkin; isTitleVisibleOnSmallScreen: boolean }>`
    font-size: 0.9em;
    color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    margin-bottom: 10px;

    ${({ isTitleVisibleOnSmallScreen }) =>
        isTitleVisibleOnSmallScreen
            ? ``
            : `
                @media /*[🍕]*/ only screen and (max-width: 850px) {
                  display: none;
                }
    `}
`;
