import { uuid } from '../../../40-utils/typeAliases';

/**
 * @collboard-modules-sdk
 */
export function findArtElement(artId: uuid): HTMLElement | null {
    const shellElement = document.getElementById(artId /* <- TODO: Probably some prefix in html element ID*/);

    if (!shellElement) {
        return null;
    }

    const artElement = shellElement.querySelector('.art') as HTMLElement;

    if (artElement) {
        return artElement;
    }

    if (shellElement.children.length === 1) {
        return shellElement.children[0]! as HTMLElement;
    }

    return null;
}

/**
 * TODO:This should be part of some rendering system
 */
