import { Destroyable, IDestroyable } from 'destroyable';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { forImmediate } from 'waitasecond';
import { ModuleDeclarationError, ModuleDeclarationMissingManifestError } from '../../../40-utils/errors/ModuleDeclarationError';
import { factor } from '../../../40-utils/IFactory';
import { toArray } from '../../../40-utils/toArray';
import { string_module_name } from '../../../40-utils/typeAliases';
import { consolex } from '../../../consolex';
import { IModule, IModuleDefinition } from '../interfaces/IModule';
import { IModulesStorageStrong } from '../interfaces/IModulesStorage';
export class ModulesStorage extends Destroyable implements IModulesStorageStrong, IDestroyable {
    private readonly modulesObservable: Subject<Array<IModuleDefinition>> = new BehaviorSubject(
        [] as Array<IModuleDefinition>,
    );
    private modules: Record<string_module_name, IModuleDefinition> = {};

    public getModule(name: string_module_name): IModuleDefinition | null {
        if (this.modules[name]) {
            return this.modules[name];
        }
        for (const module of Object.values(this.modules)) {
            if (module.manifest) {
                if (toArray(module.manifest.deprecatedNames).includes(name)) {
                    consolex.warn(
                        `Internal module ${module.manifest.name} was referenced by its deprecated name ${name}.`,
                    );
                    return module;
                }
            }
        }
        return null;
    }

    public getAllModules(): Array<IModuleDefinition> {
        return Object.values(this.modules);
    }

    public observeAllModules(): Observable<Array<IModuleDefinition>> {
        // TODO: [0] Performance: Initialize modulesObservable only when this called
        // TODO: [0] Performance: Debounce
        return this.modulesObservable;
    }

    public async declareModule(...modules: Array<IModule>): Promise<void> {
        for (const module of modules) {
            // TODO: [🍖] Call declareOneModule in parallel (and design some integration test to make sure that it works)
            await this.declareOneModule(module);
        }
    }

    private async declareOneModule(module: IModule): Promise<void> {
        // TODO: Save factory form and call it with every new setup
        // TODO: Check collisions with existing modules

        try {
            await forImmediate();
            //                   <- TODO: Describe why waiting forImmediate
            //                   <- TODO: Probbably wait forMoment not forImmediate

            const moduleDefinition = factor(module);

            if (!moduleDefinition.manifest) {
                throw new ModuleDeclarationMissingManifestError();
            }

            this.modules[moduleDefinition.manifest.name] = moduleDefinition;
            // TODO: [0] Performance: Maybe do not emit if there are no subscribers and no call of observeAllModules
            this.modulesObservable.next(Object.values(this.modules));
        } catch (error) {
            if (error instanceof Error) {
                throw new ModuleDeclarationError(error, `Error occured while declaring module.`);
            } else {
                throw error;
            }
        }
    }
}
