import React from 'react';
import { string_url_image } from '../40-utils/typeAliases';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { Abstract2dBoxArt } from './27-Abstract2dBoxArt';

internalModules.declareModule(() => makeArtModule(ImageArt));

/**
 * Art rendering a given image
 *
 * @collboard-modules-sdk
 */
export class ImageArt extends Abstract2dBoxArt {
    public static serializeName = 'Image';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/image-art',
        deprecatedNames: '@collboard/image-art',
    };

    /**
     * @param src Image source
     * @param alt Image alt text
     */
    public constructor(public src: string_url_image, public alt: string) {
        super(null);
    }

    // tslint:disable-next-line:variable-name
    private __element: HTMLImageElement | null = null;

    /**
     * Rendered element on board or null in case the image is not used in any operation yet
     */
    public get element(): HTMLImageElement | null {
        return this.__element;
    }

    private ref(element: HTMLImageElement | null) {
        if (!element) {
            return;
        }
        this.__element = element;
    }

    public renderBox() {
        if (this.measured) {
            return (
                <img
                    src={this.src}
                    alt={this.alt}
                    ref={(element) => this.ref(element)}
                    width={this.size.x || 0}
                    height={this.size.y || 0}
                />
            );
        } else {
            return (
                <img
                    src={this.src}
                    alt={this.alt}
                    ref={(element) => {
                        this.ref(element);
                        this.measure(element);
                    }}
                />
            );
        }
    }
}

/**
 * TODO: [🍎]  Use IMaterial instead of color
 * TODO: [🍎]  Use IShape instead of weight, points,...
 * TODO: [🎚️] Implement IArt
 */
