import { AbstractArt } from './20-AbstractArt';

/*
 * Note: [🖇] This is not in the internal module because some of the Arts are so
 *       tightly connected with the core that they need to be there, not in
 *       optionally deactivateable module.
 *
 */

/**
 * AbstractEnvironmentArt creates a category of arts that changes the environment on board like background, pattern, skybox etc.
 *
 * @collboard-modules-sdk
 */
export abstract class AbstractEnvironmentArt extends AbstractArt {}

/**
 * TODO: [🎚️] Maybe get rit of AbstractArts and make makers for arts which returns IArts
 * TODO: SkyboxArtModule
 *       LightArtModule
 *       ...
 */
