import React from 'react';
import styled from 'styled-components';
import { Color } from '../../../40-utils/color/Color';
import { useSkin } from '../../../40-utils/react-hooks/useSkin';
import { ISkin } from '../../../50-systems/StyleSystem/ISkin';

/**
 * Categorized modal left sidebar wrapper
 * Note: Use only inside component <CategorizedModal
 *
 * @collboard-modules-sdk
 */

export function CategorizedModalMenu(props: { children: JSX.Element }) {
    const { skin } = useSkin();

    return (
        <CategorizedModalMenuStyle className="categorized-modal-menu" {...{ skin }}>
            {props.children}
        </CategorizedModalMenuStyle>
    );
}

const CategorizedModalMenuStyle = styled.div<{ skin: ISkin }>`
    .category-picker {
        list-style: none;
        padding-left: 0;
        margin-top: 20px;
    }
    .category-picker a {
        text-decoration: none;
    }
    .category-picker a li {
        color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    }
    .category-picker li {
        margin-bottom: 10px;
        cursor: pointer;
    }
    .category-picker li.category-separator {
        border-bottom: solid 2px ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
        cursor: default;
    }
    .category-picker li.category-selected {
        color: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
    }

    .category-picker li.category-empty {
        display: none; /*User will not see but still appear to translations*/
        /* TODO: In future when only few empty categories there can be opacity: 0.5;*/
    }
`;

/**
 * TODO: [🦅] Maybe just CategorizedModalBodyStyle as CategorizedModalBody will be enough
 */
