import { internalModules } from '../../../../50-systems/ModuleStore/internalModules';
import { makeIconModuleOnRoute } from '../../../../50-systems/ModuleStore/makers/makeIconModuleOnRoute';
import { ToolbarName } from '../../../../50-systems/ToolbarSystem/0-ToolbarSystem';

internalModules.declareModule(() =>
    makeIconModuleOnRoute({
        manifest: {
            name: '@collboard/internal/module-store-icon',
            deprecatedNames: ['@collboard/module-store-icon', 'ModuleStoreIcon'],
            title: { en: 'Module store on toolbar', cs: 'Obchod s moduly na liště' },
            flags: {
                isHidden: true,
            },
        },
        toolbar: ToolbarName.Tools,
        icon: {
            name: 'store' /* For triggering externally */,
            order: 21000000,
            icon: 'package',
        },
        routeParams: { moduleName: '@collboard/internal/module-store', modulePath: null },
    }),
);

/**
 * TODO: [🐅] Maybe some more elegant way how to create icon-window pairs of modules
 */
