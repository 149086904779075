import { IModuleManifestAuthor } from './interfaces/IModuleManifest/IModuleManifestAuthor';

/**
 * List of common-used authors
 *
 * @collboard-modules-sdk
 * @deprecated use external modules package.info instead
 */
export const Authors: Record<string, IModuleManifestAuthor> = {
    hejny: {
        name: 'Pavol Hejný',
        email: 'pavol.hejny@collboard.com',
        url: 'https://www.pavolhejny.com/' /* TODO: Better page directly as author on Collboard */,
    },
    rosecky: {
        /* @roseckyk Probbably change to what do you want */
        name: 'Jonáš Rosecký',
        email: 'jonas.rosecky@collboard.com',
        url: 'https://www.muni.cz/lide/485124-jonas-rosecky' /* TODO: Better page directly as author on Collboard */,
    },
    collboard: {
        name: 'Collboard',
        email: 'info@collboard.com',
        url: 'http://collboard.com/',
    },
    hedu: {
        name: 'H-edu',
        email: 'dev@h-edu.cz',
        url: 'https://www.h-edu.org/',
    },
    firchova: {
        name: 'Alena Firchová',
        email: 'me+alena@pavolhejny.com',
    },
    /**
     * This is just a valentine easter egg 🥀
     */
    fromHejnyToFirchova: {
        name: 'Pavlíka pro Alenu',
        email: 'me+alena@pavolhejny.com',
    },
};
