import { IDependency, IDependencyDetail } from '../interfaces/IDependencies';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';

/**
 * Strips name from dependency so it became dependency detail
 * @param dependency
 */
export function dependencyToDependencyDetail(dependency: IDependency | IModuleManifest): IDependencyDetail {
    const dependencyDetail: IDependencyDetail = { ...dependency };
    delete (dependencyDetail as any).name;
    return dependencyDetail;
}
