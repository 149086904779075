// 🏭 GENERATED WITH generate-messages-api
// ⚠️ Warning: Do not edit by hand, all changes will be lost on next execution!

import Ajv from 'ajv/dist/2020' /* <- [👨‍👨‍👦‍👦] */;
import coreInitializedEvent from '../../30-Core/schemas/Core.initialized.event.schema.json';
import boardSystemCreateNewBoardRequest from '../../BoardSystem/schemas/BoardSystem.createNewBoard.request.schema.json';
import boardSystemCreateNewBoardResponse from '../../BoardSystem/schemas/BoardSystem.createNewBoard.response.schema.json';
import testSystemEchoRequest from '../../TestSystem/schemas/TestSystem.echo.request.schema.json';
import testSystemEchoResponse from '../../TestSystem/schemas/TestSystem.echo.response.schema.json';
import errorSchema from './error.abstract-schema.json';
import eventSchema from './event.abstract-schema.json';
import logSchema from './log.abstract-schema.json';
import requestSchema from './request.abstract-schema.json';
import responseSchema from './response.abstract-schema.json';

/**
 * All schemas for message API
 *
 * @singleton
 */

let schemas: Ajv | null = null;

/**
 * Create schemas for Messages API
 *
 * @heavy on first call
 * @idempotent this function is cached so it will return cached schemas second time
 */
export function createSchemas(): Ajv {
    if (schemas) {
        return schemas;
    }

    schemas = new Ajv();

    schemas.addSchema(requestSchema);
    schemas.addSchema(responseSchema);
    schemas.addSchema(logSchema);
    schemas.addSchema(errorSchema);
    schemas.addSchema(eventSchema);
    schemas.addSchema(coreInitializedEvent);
    schemas.addSchema(boardSystemCreateNewBoardRequest);
    schemas.addSchema(boardSystemCreateNewBoardResponse);
    schemas.addSchema(testSystemEchoRequest);
    schemas.addSchema(testSystemEchoResponse);

    return schemas;
}

/**
 * TODO: Standard way how to make (async/parallel) singleton cache
 *       + Way how to anotate this
 *       + Probbably ask
 * TODO: [Optimization][InitialLoading] Probbably break into the forMoment(s) (and put it in the generator)
 *       Reflect changes to the generator
 *       Reflect changes to the filename
 *       Reflect changes to the anotation
 *       Double-check that changes are working
 */
