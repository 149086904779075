import React from 'react';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ObservableContentComponent } from '../../../../30-components/utils/ObservableContentComponent';
import { internalModules } from '../../../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../../../50-systems/ModuleStore/makers/makeModalModule';
import { Translate } from '../../../../50-systems/TranslationsSystem/components/Translate';
import { ModuleProfileComponent } from './components/00-ModuleProfileComponent';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            name: '@collboard/internal/module',
            deprecatedNames: '@collboard/module',
            title: { en: 'Show profile of the module', cs: 'Zobrazení profilu modulu' },
            requirePermissions: [],
        },
        async createModal(systems) {
            const { routingSystem, moduleStore } = await systems.request('routingSystem', 'moduleStore');

            return (
                <ObservableContentComponent
                    alt="Module profile"
                    content={routingSystem.urlVariables.values
                        .pipe(distinctUntilChanged((a, b) => a.modulePath === b.modulePath))
                        .pipe(
                            map(async ({ modulePath }) => {
                                if (!modulePath || modulePath === '/') {
                                    return (
                                        <Translate name="module / no module" html>
                                            Omlouváme se, ale tento modul neexistuje.
                                        </Translate>
                                        // TODO: Link to the module store
                                        // TODO: Handle situations with corrupted link> parseModuleName(modulePath);
                                    );
                                }

                                modulePath = modulePath.replace(/^\//, '');
                                const module = await moduleStore.getModule(modulePath);

                                if (!module) {
                                    return (
                                        <Translate name="module / module does not exists" html>
                                            Omlouváme se, ale tento modul neexistuje.
                                        </Translate>
                                    );
                                }

                                if (!module.manifest) {
                                    return (
                                        <Translate name="module / module is anonymous" html>
                                            Omlouváme se, ale tento modul neexistuje.
                                        </Translate>
                                    );
                                }

                                const { manifest } = module;

                                return <ModuleProfileComponent {...{ manifest }} />;
                            }),
                        )}
                />
            );
        },
    }),
);

/**
 * TODO: Way how to change title in the modal to module name
 */
