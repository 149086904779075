import React from 'react';
import { createTimeout } from './createTimeout';
import { ITimeoutOptions } from './ITimeoutOptions';

/**
 * React hook for warn if the component is mounted longer than certain time
 *
 * @collboard-modules-sdk
 */
export function useTimeout(options: ITimeoutOptions): void {
    React.useEffect(
        /* <- TODO: Import and use just a useEffect */ () => {
            const timeout = createTimeout(options);
            return () => void timeout.destroy();
        },
    );
}
