import { IVectorData, Vector } from 'xyzt';

interface IFlipNegativeSizeValue<TVectorData extends IVectorData> {
    translate: TVectorData;
    size: TVectorData;
}

/**
 * Flip negative size values to positive and change according to it translate
 * Or preserves both size and translate when size is positive
 * If there is only one axis negative, it will be flipped and positive axis will be preserved
 *
 * @collboard-modules-sdk
 */
export function flipNegativeSize({
    translate,
    size,
}: IFlipNegativeSizeValue<IVectorData>): IFlipNegativeSizeValue<Vector> {
    const sizePositive = new Vector(size).map(Math.abs);
    const sizeIsNegative = new Vector(size).map((value) => (value < 0 ? 1 : 0));
    const translateFlipped = new Vector(translate).subtract(sizePositive.multiply(sizeIsNegative));
    return {
        translate: translateFlipped,
        size: sizePositive,
    };
}
