import React from 'react';
import { Icon } from '../../30-components/menu/Icon';
import { Separator } from '../../30-components/menu/Separator';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeAttributeModule } from '../../50-systems/ModuleStore/makers/makeAttributeModule';

/**
 *
 * Note: In future this file will we in independent repository as external module.
 *
 */
internalModules.declareModule(() =>
    makeAttributeModule({
        manifest: {
            name: 'DiceSidesAttribute',

            contributors: [Authors.rosecky],
        },
        standard: true,
        attribute: {
            type: 'number',
            name: 'diceSides',
            defaultValue: 6,
        },
        inputRender(value: number, onChange: (value: number) => void) {
            const changeValue = (change: number) => {
                if (change < 0 && value + change > 1) {
                    onChange(value + change);
                }
                if (change > 0 && value + change < 100) {
                    onChange(value + change);
                }
            };

            const setValue = (newValue: number) => {
                onChange(newValue);
            };

            return (
                <>
                    <Icon icon="platonic-4" active={value === 4} onClick={() => setValue(4)} />
                    <Icon icon="platonic-6" active={value === 6} onClick={() => setValue(6)} />
                    <Icon icon="platonic-8" active={value === 8} onClick={() => setValue(8)} />
                    <Icon icon="platonic-12" active={value === 12} onClick={() => setValue(12)} />
                    <Icon icon="platonic-20" active={value === 20} onClick={() => setValue(20)} />
                    <Separator />
                    <Icon icon="minus" onClick={() => changeValue(-1)} />
                    <div className="label">{value} stěn</div> {/* TODO: Translation */}
                    <Icon icon="plus" onClick={() => changeValue(1)} />
                </>
            );
        },
    }),
);
