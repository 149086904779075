import React from 'react';
import { forAnimationFrame } from 'waitasecond';
import { classNames } from '../../../40-utils/classNames';
import { ImportArt, ImportStrategy } from '../../../71-arts/35-ImportArt';
import { MaterialArtVersioningSystem } from '../../ArtVersionSystem/0-MaterialArtVersioningSystem';
import { RoutingSystem } from '../../RoutingSystem/0-RoutingSystem';
import { Translate } from '../../TranslationsSystem/components/Translate';

/**
 * @deprecated in favour of @collboard/internal/add-icon
 */
interface IImportModalComponentProps {
    materialArtVersioningSystem: MaterialArtVersioningSystem;
    routingSystem: RoutingSystem;
}

/**
 * @deprecated in favour of @collboard/internal/add-icon
 */
interface IImportModalComponentState {
    ref: React.RefObject/* <- TODO: Import and use just a RefObject */ <HTMLInputElement>;
    valid: boolean;
}

/**
 * @deprecated in favour of @collboard/internal/add-icon
 */
export class ImportModalComponent extends React.Component/* <- TODO: Import and use just a Component */ <
    IImportModalComponentProps,
    IImportModalComponentState
> {
    state: IImportModalComponentState = {
        ref: React
            .createRef /* <- TODO: Import and use just a createRef */
            (),
        valid: true,
    };

    componentDidUpdate() {
        (async () => {
            await forAnimationFrame(/* <- TODO: Describe why waiting forAnimationFrame */);
            if (this.state.ref && this.state.ref.current) this.state.ref.current.focus();
        })();
    }

    render() {
        return (
            <>
                <Translate name="ImportToolbarIcon / header paragraph" html>
                    <p>
                        Na webech, které s námi spolupracují, můžete najít tlačítko <i>Zobrazit v Collboardu</i>. Toto
                        tlačítko by vám mělo vytvořit odkaz, který sem můžete vložit a následně si tento obsah zobrazit
                        na své tabuli.
                    </p>
                    <p>
                        Stejně tak sem můžete vložit adresu jiné tabule a vloží se vám se její obsah. Nevíte, kde začít?
                        Zkuste
                        <a href="https://h-edu.cz" target="_blank" rel="noopener noreferrer">
                            H-edu
                        </a>
                        !
                    </p>
                </Translate>
                <span className="modal-input-title">
                    <Translate name="ImportToolbarIcon/ link for import :" html>
                        Odkaz pro import:
                    </Translate>
                </span>
                <input type="text" className="modal-input" placeholder="http://www.h-edu.cz/" ref={this.state.ref} />
                <div className="custom-modal-footer">
                    <button
                        type="button"
                        className={classNames('button button-primary modal-button', this.state.valid ? '' : 'disabled')}
                        onClick={() => {
                            if (this.state.ref && this.state.ref.current) {
                                // TODO: Use here MimeSupportSystem instead of creating Materialized import
                                this.props.materialArtVersioningSystem
                                    .createPrimaryOperation()
                                    .newArts(new ImportArt(this.state.ref.current.value, ImportStrategy.Materialize))
                                    .persist();
                            }
                            this.props.routingSystem.navigateBoardHome();
                        }}
                    >
                        <Translate name="ImportToolbarIcon/ insert :" html>
                            Vložit
                        </Translate>
                    </button>
                </div>
            </>
        );
    }
}

/**
 * TODO: [🏛️] Convert Class components to Functional (with hooks).
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
