import { Observable, Observer } from 'rxjs';
import { consolex } from '../consolex';

//import 'rxjs/add/operator/share';

/**
 *
 * TODO: To some system?
 * TODO: Cleanup
 *
 * @collboard-modules-sdk
 */
export class Speech {
    public transcript: Observable<string | null>;
    public lastTranscript: string | null = null; // TODO:Is there some better way how to get value in complete callback
    private transcriptObserver: Observer<string | null>;

    public constructor() {
        this.transcript = Observable.create(
            (/* TODO: [🎎] Probbably just use Subject -> */ observer: Observer<string | null>) => {
                this.transcriptObserver = observer;
            },
        ); // TODO: Probably .share(); // TODO: Share vs publish

        /*
        this.transcript.subscribe((transcript) => {
            this.lastTranscript = transcript;
        });
        */

        this.listen();
    }

    /**
     * TODO: Cleanup
     */
    private listen() {
        const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
        // const SpeechGrammarList = window.SpeechGrammarList || (window as any).webkitSpeechGrammarList;
        //const SpeechRecognitionEvent = window.SpeechRecognitionEvent || (window as any).webkitSpeechRecognitionEvent;

        const recognition = new SpeechRecognition();
        recognition.continuous = true;
        recognition.lang = 'cs' /* TODO: DO not hardcode params */; //'en-US';
        recognition.interimResults = true;
        recognition.maxAlternatives = 1;

        recognition.start();

        recognition.onresult = (event: any /* TODO SpeechRecognitionEvent*/) => {
            consolex.info('SpeechTextModule: onresult');

            /**
             * Notes:
             * The SpeechRecognitionEvent results property returns a SpeechRecognitionResultList object
             * The SpeechRecognitionResultList object contains SpeechRecognitionResult objects.
             * It has a getter so it can be accessed like an array
             * The first [0] returns the SpeechRecognitionResult at the last position.
             * Each SpeechRecognitionResult object contains SpeechRecognitionAlternative objects that contain individual results.
             * These also have getters so they can be accessed like arrays.
             * The second [0] returns the SpeechRecognitionAlternative at position 0.
             * We then return the transcript property of the SpeechRecognitionAlternative object
             *
             */

            // TODO: There should be maybe somewhere used recognition.stop();

            for (const result of Array.from(event.results)) {
                // TODO: There should be maybe somewhere used result.isFinal

                for (const part of Array.from(result as any)) {
                    if (
                        (part as any).transcript !==
                        '' /* Note: On Android there are sometimes more parts first is empty and second is real transcript. */
                    ) {
                        this.transcriptObserver.next((part as any).transcript);
                        this.lastTranscript = (part as any).transcript;
                    }
                }
            }
        };

        recognition.onspeechend = () => {
            // TODO: There should be maybe somewhere used recognition.stop();
            this.transcriptObserver.complete();
        };

        recognition.onnomatch = (event: any) => {
            this.transcriptObserver.next(null);
            this.lastTranscript = null;
            this.transcriptObserver.complete();

            // this.transcriptObserver.error(`There was no match in recognition.`);
        };

        recognition.onerror = (event: any /*TODO: :SpeechRecognitionErrorEvent*/) => {
            consolex.error(event);

            if (event.error === 'aborted') {
                this.transcriptObserver.next(null);
                this.lastTranscript = null;
                this.transcriptObserver.complete();
                return;
            }

            this.transcriptObserver.error(`There was an error in recognition "${event.message}".`);
        };

        // TODO: Deleting recognition object
    }
}
