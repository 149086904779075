import { compareStrings } from '../compareStrings';
import { jsxToHtmlSimple } from './jsxToHtmlSimple';
import { jsxToText } from './jsxToText';

/**
 * Compares two JSX elements for sorting purposes
 * @param a first JSX Element
 * @param b second JSX Element
 * @returns -1 if a < b, 0 if a == b, 1 if a > b
 *
 * @collboard-modules-sdk
 */
export function compareJsxs(a: JSX.Element, b: JSX.Element): 1 | -1 | 0 {
    const order = compareStrings(jsxToText(a), jsxToText(b));
    if (order !== 0) {
        return order;
    } else {
        return compareStrings(jsxToHtmlSimple(a), jsxToHtmlSimple(b));
    }
}
