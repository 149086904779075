import { IDestroyable } from 'destroyable';
import { errorMessageWithAdditional } from '../../40-utils/errors/errorMessageWithAdditional';
import { isCloneable } from '../../40-utils/isCloneable';
import { FlatLogger } from '../../40-utils/logger/FlatLogger';
import { ILogger, ISubLogger } from '../../40-utils/logger/ILogger';
import { ProxyLogger } from '../../40-utils/logger/ProxyLogger';
import { Queue } from '../../40-utils/tasks/Queue';
import { consolexBase } from '../../consolex';
import { SystemName } from '../00-SystemsContainer/ISystems';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { isMessageEventForCollboard } from './utils/isMessageEventForCollboard';
import { IParseMessageEventResult, parseMessageEvent } from './utils/parseMessageEvent';

/**
 * System that recieves and executes the post message API
 *
 * @collboard-system
 */
export class MessagesApiSystem extends AbstractSystem implements IDestroyable {
    protected async init() {
        window.addEventListener('message', async (event) => {
            if (!isMessageEventForCollboard(event)) {
                return;
            }

            const logger = consolexBase.groupCollapsed(
                `%cRecieved message from ${event.origin}`,
                `background: #33cc33; color: white; font-size: 1.1em; font-weight: bold; padding: 5px; border-radius: 3px;`,
            );

            let systemName: SystemName | undefined;
            let actionName: string | undefined /* <- TODO: [🐳] Use propper type */;
            let requestId: string | number | undefined;

            try {
                logger.info('event', event);

                const parsedEvent = parseMessageEvent(event);

                logger.info('parsedEvent', parsedEvent);

                requestId = parsedEvent.requestId;
                systemName = parsedEvent.systemName;
                actionName = parsedEvent.actionName;
                const { options, moduleSignature } = parsedEvent;

                const result = await this.performMessage({
                    systemName,
                    actionName,
                    options,
                    moduleSignature,
                    logger,
                    actionLogger: new FlatLogger((type, message, ...additional) => {
                        this.reactionMessage({
                            event,
                            logger,
                            type: 'LOG',
                            requestId,
                            systemName,
                            actionName,
                            data: {
                                message: `${type}: ${message}`, // <- TODO: [🍃] Pass here type of the log (info,warning,error) as a propper property
                                additional,
                            },
                        });
                    }),
                });

                logger.info('result', result);

                // TODO: [🍃] [5] Validate result

                this.reactionMessage({
                    event,
                    logger,
                    type: 'RESPONSE',
                    requestId,
                    systemName,
                    actionName,
                    data: result,
                });
            } catch (error) {
                if (!(error instanceof Error)) {
                    throw error;
                }

                this.reactionMessage({
                    event,
                    logger,
                    type: 'ERROR',
                    requestId /* <- TODO: [🍃] Only if provided */,
                    systemName,
                    actionName,
                    data: {
                        name: error.name,
                        message: error.message,
                    },
                });

                logger.error(error);
            } finally {
                logger.end();
            }
        });
    }

    private messageQueue = new Queue<any>();

    private performMessage(
        message: Omit<IParseMessageEventResult, 'requestId'> & {
            /**
             * This logger will send all logs:
             *   - Logs from the action
             *   - Logs around (from this method)
             */
            logger: ISubLogger;

            /**
             * This logger will send logs directly from the action
             */
            actionLogger: ILogger;
        },
    ): Promise<any> {
        const {
            logger,
            actionLogger,
            systemName,
            actionName,
            options,
            /* [0] moduleSignature*/
        } = message;

        return this.messageQueue.task(async () => {
            // TODO: [🍃] [0] Check moduleSignature and ask for permission to pass down
            // TODO: [🍃] [1] Use useSystems mechanism to check permissions
            // TODO: [🍃] await checkModuleTrust(moduleSignature);

            const system = (await this.systems[systemName]) /* <- [1] */ as any; /* <- TODO: [🍃] [🐳] Remove any */

            logger.info('system', system);

            if (!system) {
                throw new Error(
                    errorMessageWithAdditional(`Can not find ${systemName}`, {
                        systemName,
                        actionName,
                        options,
                        system,
                    }),
                );
            }

            const action = system[actionName] as (options: any) => Promise<any>;

            logger.info('action', action);

            if (!action) {
                throw new Error(
                    errorMessageWithAdditional(`Can not find method ${actionName} on ${systemName}`, {
                        systemName,
                        system,
                        actionName,
                        action,
                        options,
                    }),
                );
            }

            logger.info(`Calling ${actionName} on ${systemName}...`);

            const result = await action.call(system, { ...options, logger: new ProxyLogger(logger, actionLogger) });

            return result;
        });
    }

    public emitEvent(eventData: any) {
        // TODO: [🍃] [5] Validate eventData

        window.opener?.postMessage(
            eventData,
            '*' /* <- TODO: [🍃] [🐖] Is there some way to not have a wildcard here? */,
        );
        window.parent?.postMessage(
            eventData,
            '*' /* <- TODO: [🍃] [🐖] Is there some way to not have a wildcard here? */,
        );
    }

    /**
     * Reaction is response, error or log
     */
    private reactionMessage({
        event,
        logger,
        type,
        requestId,
        systemName,
        actionName,
        data,
    }: {
        event: MessageEvent;
        logger: ISubLogger;
        type: 'RESPONSE' | 'ERROR' | 'LOG';
        requestId?: string | number;
        systemName?: SystemName;
        actionName?: string /* <- TODO: [🐳] Use propper type */;
        data: Record<string, any>;
    }) {
        try {
            const unsendableData: Record<string, any> = {};
            const sendableData: Record<string, any> = {};
            for (const [key, value] of Object.entries(data)) {
                if (isCloneable(value)) {
                    sendableData[key] = value;
                } else {
                    unsendableData[key] = value;
                }
            }

            if (Object.keys(unsendableData).length) {
                logger.warn(`Try to send uncloneable data via postMessage`, {
                    event,
                    logger,
                    type,
                    requestId,
                    systemName,
                    actionName,
                    data,
                    unsendableData,
                    sendableData,
                });
            }

            event.source!.postMessage(
                {
                    // TODO: Remove undefined from object OR is it automatically
                    //     > foo: undefined,

                    type,
                    requestId,
                    systemName,
                    actionName,
                    ...sendableData,
                },
                {
                    // TODO: [🍃] Is this necessary? What is the difference between MessageEventSource and Window?
                    targetOrigin: event.origin === 'null' ? '*' : event.origin,
                },
            );
        } catch (error) {
            logger.error(error);
        }
    }
}

/**
 * TODO: [🍃] processMessage maybe public
 * TODO: [🍃] There should be some concept of private system
 * TODO: [🍃] This (or some similar) system should manage console access
 * TODO: [🍃] messageQueue maybe ThrottleQueue (not a simple Queue)
 */
