import { DestroyableLoop } from 'destroyable';
import { forTimeSynced } from 'waitasecond';
import { randomItem } from '../../40-utils/randomItem';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { NotificationPlace } from '../../50-systems/NotificationSystem/INotification';

internalModules.declareModule(() => ({
    manifest: {
        /* TODO: Delete SampleNotifications module */
        flags: { isHidden: true }, // TODO: How to deal and show this dev modules
        name: 'SampleNotifications',
        title: { en: 'Sample of notifications', cs: 'Testování notifikací' },
        author: Authors.hejny,
        requirePermissions: [],
    },

    async setup(systems) {
        const { notificationSystem } = await systems.request('notificationSystem');

        return new DestroyableLoop(async (amIDestroyed) => {
            let i = 1;
            while (true) {
                await forTimeSynced(1000 * 1);
                if (amIDestroyed() || true) return;

                const priority = Math.random(/* <- TODO: [🐉] Probbably use seed random */);
                const notificationRegistration = notificationSystem.publish({
                    title: 'titulek ' + i++,
                    tag: 'test',
                    body: `test\npriotity=${priority}`,
                    priority,
                    places: [NotificationPlace.Board, NotificationPlace.WelcomeModalAnnouncement],
                    type: randomItem('info', 'warning', 'error', 'success'),
                    actions: [
                        {
                            action: 'cancel',
                            title: 'Cancel',
                            onClick: () => notificationRegistration.destroy(),
                        },
                        { action: 'ok', title: 'Ok', onClick: () => notificationRegistration.destroy() },
                    ],
                });
            }
        });
    },
}));
