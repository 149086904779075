import { forAnimationFrame, forImmediate, forTime } from 'waitasecond';
import { Vector } from 'xyzt';
import { consolexBase } from '../../consolex';
import { ObjectUrl } from '../files/ObjectUrl';
import { string_url_image } from '../typeAliases';

/**
 * Get image size after its rendered on its natural size
 *
 * @collboard-modules-sdk
 */
export async function measureImageSize(source: File | Blob | string_url_image): Promise<Vector> {
    // TODO: Can be this do more elegantly without creating ton of objects?

    const objectUrl = ObjectUrl.fromBlobOrUrl(source);
    const imageElement = document.createElement('img');
    imageElement.src = objectUrl.src;

    let imageSize: Vector = Vector.zero();

    for (const imageMaybeMeasured of [
        /* Note: Need to wait for browser to recognize image size but it vary */
        /* [🧙‍♀️] */
        Promise.resolve(),
        forImmediate(),
        forAnimationFrame(),
        forTime(10),
        forTime(100),
        forTime(1000) /* <- TODO: Some util for making such a waiting chain */,
    ]) {
        await imageMaybeMeasured;
        imageSize = Vector.fromObject(imageElement, ['naturalWidth', 'naturalHeight']);

        if (imageSize.x > 0 && imageSize.y > 0) {
            await objectUrl.destroy();
            return imageSize;
        }
    }

    // Note: Do not destroying objecturl for debugging purposes

    consolexBase.error('Image size was measured zero or negative', { source, imageSize, imageElement, objectUrl });
    return imageSize;
}
