import React from 'react';
import { AsyncContentComponent } from '../../../../../30-components/utils/AsyncContentComponent';
import { LoaderInline } from '../../../../../30-components/utils/Loader/LoaderInline';
import { flagsToObject } from '../../../../../40-utils/flagsToObject';
import { isEmoji } from '../../../../../40-utils/isEmoji';
import { usePromise } from '../../../../../40-utils/react-hooks/usePromise';
import { useSystems } from '../../../../../40-utils/react-hooks/useSystems';
import { IModuleManifest } from '../../../../../50-systems/ModuleStore/interfaces/IModuleManifest/IModuleManifest';
import { IModulePersister } from '../../../../../50-systems/ModuleStore/interfaces/IModulePersister';
import { IModuleStatusReporter } from '../../../../../50-systems/ModuleStore/interfaces/IModuleStatusReporter';
import { isExperimental } from '../../../../../50-systems/ModuleStore/utils/isExperimental';
import { isPresented } from '../../../../../50-systems/ModuleStore/utils/isPresented';
import { Translate } from '../../../../../50-systems/TranslationsSystem/components/Translate';
import { parseModuleAuthors } from '../utils/parseModuleAuthors';
import { ModuleStatusComponent } from './20-ModuleStatusComponent';

interface IModuleBoxComponentProps {
    manifest: IModuleManifest;
    moduleStatusReporter: IModuleStatusReporter;
    persister: IModulePersister | null;
}

/**
 * One module in the catalogue (list) in module store
 * Note: This component should be child of <ModulesCatalogueComponent
 */
export function ModuleBoxComponent({ manifest, moduleStatusReporter, persister }: IModuleBoxComponentProps) {
    const { translationsSystem, businessSystem, licenseSystem } = useSystems(
        'translationsSystem',
        'businessSystem',
        'licenseSystem',
    );
    const { value: businessConfiguration } = usePromise(businessSystem.businessConfiguration);

    const isIconChar = manifest.icon && (isEmoji(manifest.icon) || manifest.icon.length <= 3);
    // TODO const isIconUrl ...

    return (
        <div className="module">
            <div className={`${!isPresented(manifest, businessConfiguration || undefined) && 'servicing'}`}>
                <div
                    className="image"
                    style={{
                        backgroundImage: isIconChar ? 'none' : `url(${manifest.icon})`,
                    }}
                >
                    {isIconChar && <>{manifest.icon}</>}
                </div>

                <h2>{translationsSystem.pickStringMessage(manifest.title || manifest.name)}</h2>

                <h3>
                    <Translate name="ModuleStoreToolbarIcon/ module by">Od</Translate>
                    {parseModuleAuthors(manifest).map(({ name, email, url }, i) => (
                        <React.Fragment /* <- TODO: Import and use just a Fragment */ key={name || i}>
                            {i === 0 ? ' ' : ', '}
                            <span
                                onClick={() => {
                                    /* TODO: In future here will be triggered a search of authors modules */
                                }}
                            >
                                {name || (
                                    <Translate name="ModuleStoreToolbarIcon/ module by / anonymous">
                                        neznámého autora
                                    </Translate>
                                )}
                            </span>
                        </React.Fragment /* <- TODO: Import and use just a Fragment */>
                    ))}
                </h3>

                {/*<Translate name={`ModuleStoreToolbarIcon/ type / ${this.props.module.type}`}>
                    {this.props.module.type}
                                </Translate>*/}

                <div className="description">
                    {manifest.description && (
                        <div className="desc-content">{translationsSystem.pickStringMessage(manifest.description)}</div>
                    )}

                    {isExperimental(manifest) && (
                        <div className="warning">
                            <Translate name="ModuleStoreToolbarIcon/ Experimental / text">
                                Experimentální modul
                            </Translate>
                        </div>
                    )}

                    {!isPresented(manifest) && (
                        <div className="warning">
                            <Translate name="ModuleStoreToolbarIcon/ not presented / text">Skrytý modul</Translate>
                        </div>
                    )}

                    {flagsToObject(manifest.flags).isDeprecated && (
                        <div className="warning">
                            <Translate name="ModuleStoreToolbarIcon/ Deprecated / text">Zastaralý modul</Translate>
                        </div>
                    )}

                    <div className="badge-wrapper">
                        <AsyncContentComponent
                            alt="Module status - loading license info"
                            loader={
                                <LoaderInline alt="Module status - loading license info" /> /* TODO: Some way how to pass alt to loader in DRY way */
                            }
                            content={async () => (
                                <ModuleStatusComponent
                                    {...{
                                        shownModule: manifest,
                                        moduleStatusReporter,
                                        persister,
                                        usageLicenseInfo: await licenseSystem.getModuleLicenceInfo(manifest),
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

/**
 * TODO: [🏴‍☠️] Across the projects components+files with styled name call WelcomeModalStyle (not WelcomeModalDiv)
 */

/*
TODO: Module settings

@hejny ->  @roseckyj I will do it better in other place
<div className="onHover modules-badge badge-options">
    <div className="text">
        <label>
            <Translate name="ModuleStoreToolbarIcon/ options">Možnosti</Translate>
            {/* This is done real hacky way! Should have it's own modal, where it will have details and where it can be toggled * /}
            <select className="invisible-dropdown">
                <option value="A">
                    {<Translate name="ModuleStoreToolbarIcon/ active for owners and editors">Funkce je přidaná pro žáky i učitele</Translate>}
                </option>
                <option value="B">
                    {<Translate name="ModuleStoreToolbarIcon/ active for owners">Funkce je přidaná pouze pro učitele</Translate>}
                </option>
            </select>
        </label>
    </div>
</div>
*/

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
