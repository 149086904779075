import React from 'react';
import { internalModules } from '../../../../50-systems/ModuleStore/internalModules';
import { makeUserInterfaceModule } from './../../../../50-systems/ModuleStore/makers/makeUserInterfaceModule';
import { UserInterfaceElementPlace } from './../../../../50-systems/UserInterfaceSystem/UserInterfaceElementPlace';

internalModules.declareModule(() =>
    makeUserInterfaceModule({
        manifest: {
            name: '@collboard/internal/share-indicator',
            title: { en: 'Share indicator on toolbar', cs: 'Indikátor sdílení na liště' },
            requirePermissions: ['view'],
        },
        place: UserInterfaceElementPlace.EdgeTop,
        render(systems) {
            return (
                <div
                    style={{
                        // marginTop: 15,
                        // margin: 5,
                        transform: 'translateY(4px)',
                        outline: '1px solid #22ff22',
                        padding: 7,
                        borderRadius: 5,
                        color: '#22ff22',
                    }}
                >
                    • Sdíleno
                </div>
            );
        },
    }),
);

/**
 * TODO: [🐅] Maybe some more elegant way how to create icon-window pairs of modules
 */
