import React from 'react';
import { Icon } from '../30-components/menu/Icon';
import { ISystems } from '../50-systems/00-SystemsContainer/ISystems';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeAttributeModule } from '../50-systems/ModuleStore/makers/makeAttributeModule';

internalModules.declareModule(() =>
    makeAttributeModule({
        manifest: {
            // Note: This art module is not auto-activated on initialization because when it appears on the board or in some toolbar, it will be auto-activated afterward.
            name: 'WeightAttribute',

            contributors: [Authors.rosecky, Authors.hejny],
        },
        standard: true,
        attribute: {
            type: 'number',
            name: 'weight',
            defaultValue: 2,
            // TODO: min, max
        },
        async inputRender(value: number, onChange: (value: number) => void, systems: ISystems) {
            const { appState } = await systems.request('appState');
            const z = appState.transform.value.scale.x;

            return (
                <>
                    {/* TODO: Configurable */}
                    <Icon icon="stroke-1" active={value === 2 / z} onClick={() => onChange(2 / z)} />
                    <Icon icon="stroke-2" active={value === 5 / z} onClick={() => onChange(5 / z)} />
                    <Icon icon="stroke-3" active={value === 15 / z} onClick={() => onChange(15 / z)} />
                </>
            );
        },
    }),
);
