import styled from 'styled-components';
import { Color } from '../../../../40-utils/color/Color';
import { ISkin } from '../../../../50-systems/StyleSystem/ISkin';

export const WelcomeModalStyle = styled.div<{ skin: ISkin }>`
    .welcomeBigBlue {
        display: inline-block;
        width: 130px;
        height: 130px;
        text-align: center;
        padding-top: 100px;
        background-color: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
        color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
        border-radius: ${({ skin }) => skin.borderRadius}px;
        margin: 35px 10px 10px 10px;
        vertical-align: top;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top center;
    }

    .welcomeBigBlue a {
        color: inherit;
        text-decoration: none;
    }

    .welcomeBigBlue:hover {
        background-color: ${({ skin }) => Color.from(skin.colors.primary).addLightness(skin.darken).opaque.toString()};
    }

    .welcomeTemplate,
    .welcomeTemplateMore {
        display: inline-block;
        width: 90px;
        height: 90px;
        text-align: center;
        background-color: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
        color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
        border-radius: ${({ skin }) => skin.borderRadius}px;
        margin: 3px 0 0 7px;
        font-size: 0.7rem;
        line-height: 12px;
        position: relative;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top center;
    }

    .welcomeTemplateMore {
        background-color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
        cursor: default;
    }

    .welcomeTemplateTitle {
        position: absolute;
        bottom: 5px;
        left: 5px;
        right: 5px;
        background-color: ${({ skin }) => Color.from(skin.colors.light).toString()};
        color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    }

    .welcomeTemplateMoreTitle {
        position: absolute;
        left: 50%;
        top: 0;
        right: 0;
        transform: translate(0, -50%);
    }

    .welcomeTemplate:hover {
        background-color: ${({ skin }) => Color.from(skin.colors.light).addLightness(skin.darken).opaque.toString()};
    }

    .welcomeWrapper {
        display: inline-flex;
        width: calc(100% - 150px);
        position: relative;

        flex-wrap: wrap;
        justify-content: space-around;
        align-items: space-around;
    }

    .welcomeBoard {
        display: inline-block;
        width: calc(25% - 7px);
        height: 105px;
        text-align: center;
        background-color: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
        border-radius: ${({ skin }) => skin.borderRadius}px;
        margin: 3px 0 0 7px;
        font-size: 0.8rem;
        line-height: 12px;
        position: relative;
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .welcomeBoard * {
        color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    }

    .welcomeBoardTitle {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: 10px;
        border-radius: 0 0 ${({ skin }) => skin.borderRadius}px ${({ skin }) => skin.borderRadius}px;
        background-color: ${({ skin }) => Color.from(skin.colors.light).withAlpha(0.8).toString()};
    }

    .welcomeBoard:hover .welcomeBoardTitle {
        padding: 15px 10px 10px 10px;
    }

    .welcomeBoardSubtitle {
        display: none;
        opacity: 0.7;
        font-size: 0.9em;
    }

    .welcomeBoard:hover .welcomeBoardSubtitle {
        display: block;
    }

    .centered {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    @media /*[🍕]*/ only screen and (max-width: 575px) {
        .welcomeBigBlue {
            width: 100%;
            height: 100px;
            padding-top: 70px;
            margin: 0px 0 10px 0;
        }

        .welcomeTemplateMore {
            display: none;
        }

        .comingSoon {
            padding-top: 0;
        }

        .welcomeWrapper {
            width: 100%;
        }

        .welcomeBoard {
            display: inline-block;
            width: 100%;
            height: 100px;
            text-align: left;
            margin: 4px 0 4px 0;
            background-size: cover;
            border: solid 2px ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
        }

        .welcomeBoardTitle {
            position: unset;
            height: 32px;
            border-radius: ${({ skin }) => skin.borderRadius}px ${({ skin }) => skin.borderRadius}px 0 0;
        }

        .welcomeBoard:hover .welcomeBoardTitle {
            padding: 10px;
        }

        .welcomeBoardSubtitle {
            display: block;
            float: right;
            opacity: 0.7;
            font-size: 0.9em;
        }
    }
`;

/**
 * TODO: [🏴‍☠️] Across the projects components+files with styled name call WelcomeModalStyle (not WelcomeModalDiv)
 */
