interface IPromptDialogueOptions {
    message?: string;
    defaultValue?: string;
}

/**
 *
 * @collboard-modules-sdk
 */
export async function promptDialogue(options: IPromptDialogueOptions | string): Promise<string | null> {
    let message: string | undefined;
    let defaultValue: string | undefined;
    if (typeof options === 'string') {
        message = options;
    } else {
        message = options.message;
        defaultValue = options.defaultValue;
    }

    // TODO: @hejny -> @roseckyj I want to replace all blocking stuff by async functions with nicer UI
    return prompt(message, defaultValue);
}

/**
 * TODO: [🔊] This should be just a shortcut for using notificationSystem (OR other similar system)
 * TODO: [🔊] Return IDestroyable & Notification object
 */
