import React from 'react';
import { CopyComponent } from '../../../../30-components/utils/CopyComponent';
import { Loader } from '../../../../30-components/utils/Loader/Loader';
import { Obfuscate } from '../../../../30-components/utils/Obfuscate';
import { QRCode } from '../../../../30-components/utils/QRCode';
import { classNames } from '../../../../40-utils/classNames';
import { useObservable } from '../../../../40-utils/react-hooks/useObservable';
import { useSystems } from '../../../../40-utils/react-hooks/useSystems';
import { encodeUrl } from '../../../../50-systems/RoutingSystem/routePath/encodeUrl';
import { Translate } from '../../../../50-systems/TranslationsSystem/components/Translate';

export function ShareModalEditLink() {
    const { routingSystem } = useSystems('routingSystem');
    const { value: editUrl } = useObservable(routingSystem.urlVariables.values);

    if (!editUrl) {
        return <Loader alt="Waiting for editUrl" />;
    }

    const href = encodeUrl({
        // TODO: Spread only board and business (+port&hostname) instead of excluding extension, module and modulePath
        ...editUrl,
        extension: null,
        moduleName: null,
        modulePath: null,
    }).href;

    return (
        <>
            <span className="modal-input-title">
                <Translate name="ShareIcon/ edit link" html>
                    Odkaz pro úpravy:
                </Translate>
            </span>
            <Obfuscate
                buttonContent={<Translate name="ShareIcon/obfuscation button">Zobrazit</Translate>}
                content={
                    <Translate name="ShareIcon/obfuscation desc">Z bezpečnostních důvodů je odkaz skrytý</Translate>
                }
            >
                <CopyComponent className={classNames('modal-input', 'input-copy')} value={href} />
                <div style={{ textAlign: 'center' }}>
                    <QRCode text={href} width={200} />
                </div>
            </Obfuscate>
        </>
    );
}
