import { StatusCodes } from 'http-status-codes';
import { string_url } from './typeAliases';

/**
 * Check if given url exists and is accessible
 *
 * @param url url to check
 * @returns true if url is valid and returns 200 status code
 *
 * @collboard-modules-sdk
 */
export async function isFetchable(url: string_url | URL): Promise<boolean> {
    try {
        const response = await fetch(url.toString(), {
            method: 'GET',
        });

        return response.status !== StatusCodes.NOT_FOUND;
    } catch (e) {
        return false;
    }
}
