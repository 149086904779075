import { IDestroyable, NotFoundError, registerPairsInObject } from 'destroyable';
import { forImmediate } from 'waitasecond';
import { errorMessageWithAdditional } from '../../../40-utils/errors/errorMessageWithAdditional';
import { ThrottleQueues } from '../../../40-utils/tasks/ThrottleQueues';
import { string_attribute } from '../../../40-utils/typeAliases';
import { IDependenciesRecord } from '../interfaces/IDependencies';
import { ISyncer } from '../interfaces/ISyncer';
import { dependenciesSetToDependenciesRecord } from '../utils/dependenciesSetToDependenciesRecord';
import { AbstractSyncer } from './AbstractSyncer';
/**
 * AttributeSupportSyncer installs / uninstalls ONLY STANDARD attribute modules when needed
 *
 * @private
 * @collboard-system
 */
export class AttributeSupportSyncer extends AbstractSyncer implements ISyncer, IDestroyable {
    private attributeDependencies: IDependenciesRecord = {};
    private throttleQueues = new ThrottleQueues({ throttler: forImmediate /* <- TODO: Maybe use here forMoment */ });

    public async installSupportForAttribute(attributeName: string_attribute) {
        return this.throttleQueues.getThrottleQueue(attributeName).task(async () => {
            const { manifests } = await (
                await this.systems.moduleStore
            ).search({
                limit: 1,
                supports: {
                    attribute: attributeName,
                },
            });

            this.checkWhetherNotDestroyed(
                `AttributeSupportSyncer is destroyed so it can not install support for ${attributeName}.`,
            );

            if (!manifests.length) {
                throw new NotFoundError(
                    errorMessageWithAdditional(
                        `AttributeSupportSyncer can not find module that add support for ${attributeName}.`,
                        {
                            moduleStore: await this.systems.moduleStore,
                        },
                    ),
                );
            }

            const attributeDependency: IDependenciesRecord = dependenciesSetToDependenciesRecord(manifests);

            const registration = registerPairsInObject({
                base: this.attributeDependencies,
                add: attributeDependency,
                collisionStrategy: 'SKIP',
            });

            this.addSubdestroyable(registration);
            await this.sync(this.attributeDependencies);
            return registration;
        });
    }

    public async destroy() {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        await this.throttleQueues.destroy();
    }
}
