import toArray from 'lodash/toArray';
import React from 'react';
import { IVectorData } from 'xyzt';
import { classNames } from '../40-utils/classNames';
import { string_module_name } from '../40-utils/typeAliases';
import { IModuleManifest } from '../50-systems/ModuleStore/interfaces/IModuleManifest/IModuleManifest';
import { IModulePersister } from '../50-systems/ModuleStore/interfaces/IModulePersister';
import { Abstract2dArt } from './26-Abstract2dArt';

/*
 * Note: [🖇] This is not in the internal module because some of the Arts are so
 *       tightly connected with the core that they need to be there, not in
 *       optionally deactivateable module.
 *
 */

/**
 * Cornerstone holds all informations about a particular board, such as
 * board name, active and inactive modules.
 *
 * Every board contains exactly one CornerstoneArt and it cannot be deleted.
 *
 * Cornerstone for a current board can be obtained through
 * `MaterialArtVersioningSystem.cornerstoneArts.value`
 *
 * @extends Abstract2dArt
 *
 * @collboard-modules-sdk
 */
export class CornerstoneArt
    extends Abstract2dArt /* TODO: Probably only AbstractPlacedArt */
    implements IModulePersister
{
    public static serializeName = 'Cornerstone';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/cornerstone-art',
        deprecatedNames: '@collboard/cornerstone-art',
    };

    /**
     * String containing user-editable board name
     */
    public boardname: string;

    public get topLeft() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return this.shift;
    }
    public get bottomRight() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return this.shift;
    }

    public isNear(pointToTest: IVectorData) {
        return false;
    }

    public get acceptedAttributes() {
        return [];
    }

    public render(isSelected: boolean) {
        return (
            <div
                // TODO: [🍒][0]! This should became <ArtOwnShell
                className={classNames('art', 'cornerstone', isSelected && 'selected')}
                style={{
                    position: 'absolute',
                    left: this.shift.x || 0 /* <- LIB xyzt .toTopLeft() */,
                    top: this.shift.y || 0 /* <- LIB xyzt .toTopLeft() */,
                }}
            />
        );
    }

    /**
     * List of user-activated modules on a board
     */
    public modulesActive?: Array<string_module_name>; // TODO: Probably name modules as On and Off

    /**
     * List of user-inactivated modules on a board
     */
    public modulesInactive?: Array<string_module_name>;

    /**
     * Removes module from `modulesActive` and `modulesInactive`
     */
    private moduleRemoveFromActivateAndDeactivateList(moduleName: string_module_name) {
        this.modulesActive = (this.modulesActive || []).filter((moduleName2) => moduleName2 !== moduleName);
        this.modulesInactive = (this.modulesInactive || []).filter((moduleName2) => moduleName2 !== moduleName);
    }

    /**
     * Add a module among user-activated modules on board
     */
    public moduleActivate(moduleManifest: IModuleManifest) {
        // Note+TODO: This is kind of action on art - in future every art should have its list of action on its data
        this.moduleRemoveFromActivateAndDeactivateList(moduleManifest.name);
        this.modulesActive?.push(moduleManifest.name);
    }

    /**
     * Add a module among user-deactivated modules on board
     */
    public moduleDeactivate(moduleManifest: IModuleManifest) {
        // Note+TODO: This is kind of action on art - in future every art should have its list of action on its data
        this.moduleRemoveFromActivateAndDeactivateList(moduleManifest.name);

        for (const deprecatedName of toArray(moduleManifest.deprecatedNames)) {
            this.moduleRemoveFromActivateAndDeactivateList(deprecatedName);
        }

        this.modulesInactive?.push(moduleManifest.name);
    }
}

/**
 * virtualCornerstoneArt is "fake" corner stone for yet non existing boards
 *
 * @collboard-modules-sdk
 */
export const virtualCornerstoneArt = new CornerstoneArt();

/**
 * TODO: [🧗‍♀️] CornerstoneArt extends PointArt
 * TODO: [🍫] In entire repository go through all Arrays and repace some of them with Sets:
 *       Example: modulesActive and modulesInactive should be Set<string_module_name> because it does not depend on order and should be unique
 * TODO: [🎚️] Implement IArt
 */
