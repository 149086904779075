import { Registration } from 'destroyable';
import React from 'react';
import { forTime } from 'waitasecond';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { Translate } from '../../../50-systems/TranslationsSystem/components/Translate';
internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/eu-cookies-warning',
        deprecatedNames: ['@collboard/eu-cookies-warning', 'EuCookiesWarning'],
        flags: {
            isHidden: true,
        },
        requirePermissions: [],
    },
    // TODO: Probably this notification should be also on welcome screen? Or not?
    async setup(systems) {
        const { storageSystem, notificationSystem, routingSystem } = await systems.request(
            'storageSystem',
            'notificationSystem',
            'routingSystem',
        );

        if (routingSystem.urlVariables.value.extension === 'embed') {
            return Registration.void();
        }

        // TODO: DRY - make some helper for notifications with memory
        await forTime(500 /* <- TODO: Is this waiting necessary */);
        const storage = storageSystem.getStorage(`EuCookiesWarning`);

        const accepted = await storage.getItem(`accepted`);
        if (!accepted) {
            const notificationRegistration = notificationSystem.publish({
                tag: 'EuCookiesWarning',
                // TODO: Probably should notifications be natively translatable with {en: ..., cs: ...}
                title: <Translate name="EuCookiesWarning / title">Accept cookies on this browser?</Translate>,
                body: (
                    <Translate name="EuCookiesWarning / body">
                        We use cookies and localstorage to help personalize and improve content and services and provide
                        a safer experience.
                    </Translate>
                ),
                type: 'info',

                actions: [
                    // TODO: Do we want here a cancel button?
                    {
                        action: 'agree',
                        title: <Translate name="EuCookiesWarning / agree">Accept All</Translate>,
                        type: 'ok',
                        onClick: () => {
                            notificationRegistration.destroy();
                            storage.setItem(`accepted`, true /* TODO: When possible set with some expiration */);
                        },
                    },
                ],
            });

            return notificationRegistration;
        }
        return Registration.void();
    },
}));
