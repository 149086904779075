import React from 'react';
import { IBaseMessage } from '../../50-systems/TranslationsSystem/interfaces/IMessage';
import { jsxToText } from '../jsx-html/jsxToText';

interface IAlertDialogueOptions {
    message: IBaseMessage;
}

/**
 *
 * @collboard-modules-sdk
 */
export async function alertDialogue(options: IAlertDialogueOptions | IBaseMessage): Promise<void> {
    let message: IBaseMessage;
    if (
        typeof options === 'string' ||
        React.isValidElement(/* <- TODO: Import and use just a isValidElement */ options)
    ) {
        message = options;
    } else {
        message = (options as IAlertDialogueOptions).message;
    }

    // TODO: @hejny -> @roseckyj I want to replace all blocking stuff by async functions with nicer UI
    alert(jsxToText(message));
}

/**
 * TODO: Maybe split into multiple stages of importance - info/warning/error
 * TODO: [🔊] This should be just a shortcut for using notificationSystem (OR other similar system)
 * TODO: [🔊] Return IDestroyable & Notification object
 */

/*
[🔊] Something like this:

(await this.systems.notificationSystem).publish({
type: 'warning',
canBeClosed:
    true /* <- TODO: (probbably @roseckyj) Make some way how to autoclose (with coundown on X) the notifications * /,
    title: options.src.toString(),
    body: (
        <LoaderInline alt="Importing" canLoadForever icon="file-image">
            <Translate name="ImportSystem / invalid url">Neplatná URL adresa</Translate>
        </LoaderInline>
    ),
});

*/
