import { Color } from '../../40-utils/color/Color';
import { ISkin } from './ISkin';

/**
 * Default skin configuration
 *
 * Note: Always prefer skin provided by StyleSystem, use this only when StyleSystem not available OR as a base object for extending to create new skin module
 *
 * @collboard-modules-sdk
 */
export const DEFAULT_SKIN: ISkin = {
    borderRadius: 6,
    colors: {
        primary: Color.fromHex('#009edd'),
        light: Color.fromHex('#f2f2f2'),
        dark: Color.fromHex('#4e4e4e'),

        success: Color.fromHex('#3da948'),
        warning: Color.fromHex('#ffcc11'),
        error: Color.fromHex('#cd1719'),
        danger: Color.fromHex('#cd1719'),

        white: Color.fromString('white'),
        black: Color.fromString('black'),

        overlay: Color.fromString('black').withAlpha(0.7),
    },
    darken: -0.05,
    lighten: 0.05,
    circles: true,
};
