import { IBoundingBoxData } from '../../ExportSystem/interfaces/IBoundingBoxData';

/**
 * @deprecated use BoundingBox.merge from LIB xyzt
 */
export function joinBoundingBoxes(...boundingBoxes: Array/*Full*/ <IBoundingBoxData>): IBoundingBoxData {
    let joinedBoundingBox: IBoundingBoxData | null = null;
    for (const boundingBox of boundingBoxes) {
        if (!joinedBoundingBox) {
            joinedBoundingBox = boundingBox;
        } else {
            // TODO: DRY
            joinedBoundingBox.topLeft.x = Math.min(joinedBoundingBox.topLeft.x || 0, boundingBox.topLeft.x || 0);
            joinedBoundingBox.topLeft.y = Math.min(joinedBoundingBox.topLeft.y || 0, boundingBox.topLeft.y || 0);
            joinedBoundingBox.bottomRight.x = Math.max(
                joinedBoundingBox.bottomRight.x || 0,
                boundingBox.bottomRight.x || 0,
            );
            joinedBoundingBox.bottomRight.y = Math.max(
                joinedBoundingBox.bottomRight.y || 0,
                boundingBox.bottomRight.y || 0,
            );
        }
    }
    if (!joinedBoundingBox) {
        throw new Error(`You need to pass at least one boundingBox.`);
    }
    return joinedBoundingBox;
}
