/**
 * Converts typescript enum to array without number values
 *
 * @deprecated [🍿] Do not use enums but object as const OR 'LITERAL VALUES' instead
 *
 * @collboard-modules-sdk
 */
export function enumToArray(enumerable: any): Array<string> {
    const enumMembers: Array<any> = Object.keys(enumerable).map((key) => enumerable[key]);
    const enumValues: Array<string> = enumMembers.filter((v) => typeof v === 'string');
    return enumValues;
}

/**
 * TODO: [🍿] Do not use enums but object as const OR 'LITERAL VALUES' instead
 *       @see https://youtu.be/jjMbPt_H3RQ
 */
