import React from 'react';
import { map } from 'rxjs/operators';
import { ObservableContentComponent } from '../../../../30-components/utils/ObservableContentComponent';
import { internalModules } from '../../../../50-systems/ModuleStore/internalModules';
import { makeUserInterfaceModule } from '../../../../50-systems/ModuleStore/makers/makeUserInterfaceModule';
import { ColorScheme } from '../../../../50-systems/StyleSystem/utils/watchPreferedColorScheme';
import { UserInterfaceElementPlace } from '../../../../50-systems/UserInterfaceSystem/UserInterfaceElementPlace';
import { ThemeWrapperStyledDiv } from './ThemeWrapperStyledDiv';

internalModules.declareModule(() =>
    makeUserInterfaceModule({
        manifest: {
            flags: { isHidden: true },
            name: 'ThemeSelect',
            requirePermissions: [],

            /**
             * TODO: How to put sth. like external inspiration?
             *       @see https://daily-dev-tips.com/posts/creating-day-night-css-only-toggle-switch/
             */
        },

        place: UserInterfaceElementPlace.RootComponent,
        order: 10,
        async render(systems) {
            const { styleSystem } = await systems.request('styleSystem');

            return (
                <>
                    <ThemeWrapperStyledDiv>
                        <ObservableContentComponent
                            alt="Content dependent on system color scheme"
                            content={styleSystem.colorScheme.pipe(
                                map((colorScheme) => (
                                    <>
                                        <input
                                            type="checkbox"
                                            id="toggle"
                                            className="toggle--checkbox"
                                            checked={colorScheme === ColorScheme.Dark}
                                            onChange={(event) =>
                                                styleSystem.colorScheme.next(
                                                    event.target.checked ? ColorScheme.Dark : ColorScheme.Light,
                                                    // TODO: Persist this option
                                                )
                                            }
                                        />
                                        <label htmlFor="toggle" className="toggle--label">
                                            <span className="toggle--label-background"></span>
                                        </label>
                                        <div className="background"></div>
                                    </>
                                )),
                            )}
                        />
                    </ThemeWrapperStyledDiv>
                </>
            );
        },
    }),
);

// TODO: Probbably @roseckyj 🎨 Avoid annoying blinks when changing theme
