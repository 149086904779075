import { BehaviorSubject } from 'rxjs';
import { forImmediate, forTime } from 'waitasecond';
import { Queue } from '../../40-utils/tasks/Queue';
import { toArray } from '../../40-utils/toArray';
import { string_uriid } from '../../40-utils/typeAliases';
import { FreehandArt } from '../../71-arts/50-FreehandArt/FreehandArt';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { IBoardSystem, IBoardSystem_createNewBoard_request, IBoardSystem_createNewBoard_response } from './interfaces/0-IBoardSystem';

/**
 * ApiClient provides API calls to the remote server.
 *
 * @collboard-system
 */
export class BoardSystem extends AbstractSystem implements IBoardSystem {
    protected async init() {}

    /**
     * Current board with information if this board is fully loaded or in phase of switching the boards
     */
    public currentBoard = new BehaviorSubject<{ uriId: string_uriid | null; isLoaded: boolean }>({
        uriId: null,
        isLoaded: false,
    });

    private switchBoardQueue = new Queue<void>();

    /**
     * Navigate to the board
     *
     * @param uriId string to navigate to board OR null to go to welcome page
     * @returns A Promise which will be resolved when the board is fully navigated
     */
    public navigateBoard(uriId: string_uriid | null): Promise<void> {
        return this.switchBoardQueue.task(async () => {
            const routingSystem = await this.systems.routingSystem;

            (async () => {
                // Note: Using IIFE to setValue just after setting up the subscription
                await forImmediate();
                routingSystem.urlVariables.setValue({ uriId });
            })();

            return new Promise((resolve) => {
                const subscription = this.currentBoard.subscribe((currentBoard) => {
                    if (!currentBoard.isLoaded) {
                        return;
                    }

                    if (currentBoard.uriId !== uriId) {
                        return;
                    }

                    resolve();

                    subscription.unsubscribe();
                });
            });
        });
    }

    /**
     * Creates a new board and navigate to it
     *
     * @param options for creating the new board
     * @returns information of the newly created board
     *          A Promise which will be resolved when the board is created and fully navigated
     */
    public async createNewBoard(
        options: IBoardSystem_createNewBoard_request,
    ): Promise<IBoardSystem_createNewBoard_response> {
        const {
            logger,
            boardname,
            useTemplate,
            modulesOn,
            modulesOff,
            isNewBoardNavigated,

            /* TODO: Use "isPersistent" for garbage collector */
        } = options;

        const apiClient = await this.systems.apiClient;
        const importSystem = await this.systems.importSystem;

        const { uriId, links } = await apiClient.createNewBoard({
            boardname,
            modulesOn: toArray(modulesOn) /* <- TODO: [🏤] Maybe toggle on frontend */,
            modulesOff: toArray(modulesOff) /* <- TODO: [🏤] Maybe toggle on frontend */,
        });

        logger.info(`New board created` /*, { uriId, links }*/);

        if (isNewBoardNavigated) {
            logger.info(`Navigating new board`);
            await this.navigateBoard(uriId);
        }

        // TODO: [🍃][☔️] Using templates without isNewBoardNavigated
        for (const template of toArray(useTemplate, null)) {
            if (template === null) {
                continue;
            }

            await importSystem.importUrl({
                logger,
                src: template,
                isNotified: false,
                isSelected: false,
                isPersisted: true /* <- Note: isPersisted on import is indipendent on isPersistent on createNewBoard */,
            });
            // TODO: [🍃] Some mechanism for rollback if import fails
        }

        (async () => {
            // Note: This is hack to enforce the firts render to hackfix https://github.com/collboard/collboard/issues/614
            await forTime(500 /* <- TODO: Tweak this time */);
            const virtualArtVersioningSystem = await this.systems.virtualArtVersioningSystem;
            virtualArtVersioningSystem.createPrimaryOperation().newArts(new FreehandArt([], 'black', 0)).persist();
        })();

        return {
            // [🍃]!! Fix this
            editId: links.edit.split('/')[1]! /* <- TODO: Better */,
            viewId: links.view.split('/')[1]! /* <- TODO: Better */,
            editLink: links.edit,
            viewLink: links.view /* <- TODO: Never null, NaN or sth wrong */,
        };
    }
}

/**
 * TODO: [🐙] What to do on Error for currentBoard - should be this state considered as non-loaded?
 * TODO: [☔️] It does not make sense to create a new board without navigating it
 * TODO: [🌵] Choose between boardSystem.navigateBoard(null) VS routingSystem.navigateBoardHome()
 */
