import React, { useState } from 'react';
import styled from 'styled-components';
import { classNames } from '../../40-utils/classNames';
import { Color } from '../../40-utils/color/Color';
import { useSkin } from '../../40-utils/react-hooks/useSkin';
import { ISkin } from '../../50-systems/StyleSystem/ISkin';
import { Translate } from '../../50-systems/TranslationsSystem/components/Translate';
import { IBaseMessage } from '../../50-systems/TranslationsSystem/interfaces/IMessage';
import { Modal } from '../modal/Modal';
import { string_icon } from './icons';

interface IConfirmIconProps {
    icon: string_icon;
    onConfirm: () => void;
    className?: string;
    /* TODO: [✨] Add is prefix */ inactive?: boolean;
    title: IBaseMessage;
    subtitle?: IBaseMessage;
}

export function ConfirmIcon(props: IConfirmIconProps) {
    const { icon, onConfirm, className, inactive, title, subtitle } = props;
    const [isModalOpen, setModalOpen] = useState(false);
    const { skin } = useSkin();

    return (
        <>
            <div className={classNames('icon', 'icon-' + icon, inactive && 'inactive', className)}>
                <div className="click-detector" onClick={() => setModalOpen(true)}></div>
            </div>
            {isModalOpen && (
                <Modal title={title} onClickClose={() => setModalOpen(false)} className="modal-warning">
                    {subtitle && <div className="mb-3">{subtitle}</div>}
                    <div className="custom-modal-footer">
                        <ConfirmButton
                            type="button"
                            className="btn modal-button confirm-no"
                            onClick={() => setModalOpen(false)}
                            {...{ skin }}
                        >
                            <Translate name="ConfirmIcon / NO ">Ne</Translate>
                        </ConfirmButton>
                        <ConfirmButton
                            type="button"
                            className="btn modal-button confirm-yes"
                            onClick={() => {
                                setModalOpen(false);
                                onConfirm();
                            }}
                            {...{ skin }}
                        >
                            <Translate name="ConfirmIcon / YES ">Ano</Translate>
                        </ConfirmButton>
                    </div>
                </Modal>
            )}
        </>
    );
}

const ConfirmButton = styled.button<{ skin: ISkin }>`
    background-color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.danger).opaque.toString()};
    font-size: 1.2rem;
    height: unset;
    width: 100px;
    font-weight: bold;
    margin: 10px;
    border: solid 2px ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};

    &:hover {
        color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
        background-color: ${({ skin }) => Color.from(skin.colors.danger).opaque.toString()};
    }

    &.confirm-no {
        font-size: 2rem;
        padding: 8px 25px;
        width: 150px;
        font-weight: bold;
    }
`;

/**
 * TODO: [🙋] Make this component more common - not only for one tool (erase)
 */
