import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';

/**
 * HintSystem creates support for hinting the User's next steps, for alerts, warnings, etc.
 *
 * @private
 * @collboard-system
 */
export class HintSystem extends AbstractSystem {
    /*
        TODO: This class is a boilerplate of the system that we have not started working on yet.
        @see https://github.com/collboard/collboard/issues/100
    */

    protected async init() {}
}
