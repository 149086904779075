import { JsonValue } from 'type-fest';
import { DetailedError } from '../../../40-utils/errors/DetailedError';
import { IBaseSerializerErrorDetails } from './00-IBaseSerializerErrorDetails';

/**
 * @@x
 */
export interface IDeserializationErrorDetails extends IBaseSerializerErrorDetails {
    /**
     * @@x
     */
    serialized: JsonValue;
}

/**
 * @@x
 */
export class DeserializationError extends DetailedError<IDeserializationErrorDetails> {
    public readonly name = 'DeserializationError';

    public constructor(message: string, details: IDeserializationErrorDetails) {
        super(message, details);
        Object.setPrototypeOf(this, DeserializationError.prototype);
    }
}
