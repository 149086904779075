import { Registration } from 'destroyable';
import { forTime } from 'waitasecond';
import { isMessageEventForCollboard } from '../../50-systems/MessagesApiSystem/utils/isMessageEventForCollboard';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';

internalModules.declareModule(() => ({
    manifest: {
        flags: { isHidden: true },
        name: '@collboard/internal/blank',
        deprecatedNames: '@collboard/blank',
    },
    async setup(systems) {
        // [🏍️] disableLogging('listening to messages API');

        let wasMessageForCollboard = false;

        const listenerRegistration = Registration.create(({ isDestroyed }) => {
            function onMessage(event: MessageEvent) {
                if (isMessageEventForCollboard(event)) {
                    wasMessageForCollboard = true;
                }
            }

            window.addEventListener('message', onMessage);

            return () => window.removeEventListener('message', onMessage);
        });

        const notificationRegistration = Registration.create(async ({ isDestroyed /* [0] ,addSubdestroyably */ }) => {
            await forTime(1000 /* to wait if Collboard recieve message, if not then shows a warning */);

            // TODO: [0] As a subdestroyable listenerRegistration

            if (!wasMessageForCollboard && !isDestroyed()) {
                // TODO: [0] Notification registration as a subdestroyable
                // alert('NO messages!');
                // TODO: [🍃] Give here warning that no messages were received yet
            }
        });

        return Registration.join(listenerRegistration, notificationRegistration);
        /*
        const { storageSystem, notificationSystem } = await systems.request('storageSystem', 'notificationSystem');

        // TODO: DRY - make some helper for notifications with memory
        await forTime(500);
        const storage = storageSystem.getStorage(`DevelopmentWarning` /* TODO: Limited validity storage * /);

        const accepted = await storage.getItem(`accepted`);
        if (!accepted) {
            const notificationRegistration = notificationSystem.publish({
                tag: 'DevelopmentWarning',
                type: 'warning',
                //TODO: Probably should notifications be natively translatable with {en: ..., cs: ...}
                title: <Translate name="DevelopmentWarning / title">You are in development mode</Translate>,
                body: (
                    <Translate name="DevelopmentWarning / body" html>
                        You have opened Collboard on <b>development mode</b>. Content created here will not be
                        preserved. This version is for developing new modules <b>not real usage</b>.
                    </Translate>
                ),
                actions: [
                    {
                        action: 'cancel',
                        type: 'cancel',
                        title: (
                            <Translate name="DevelopmentWarning / go to normal version">
                                Back to Collboard.com
                            </Translate>
                        ),
                        href: `https://www.collboard.com`,
                    },
                    {
                        action: 'more',
                        type: 'more',
                        title: <Translate name="DevelopmentWarning / show more">Show more</Translate>,
                        // TODO: Refer to DevelopmentAboutModule
                        href: `https://github.com/collboard/modules-sdk`,
                    },
                    {
                        action: 'understand',
                        type: 'ok',
                        title: <Translate name="DevelopmentWarning / agree">I understand</Translate>,
                        onClick: () => {
                            notificationRegistration.destroy();
                            storage.setItem(`accepted`, true /* TODO: When possible set with some expiration * /);
                        },
                    },
                ],
            });

            return notificationRegistration;
        }

        */
    },
}));
