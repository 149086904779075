import React from 'react';
import { ITransformData, IVectorData, Vector } from 'xyzt';
import { take } from '../../00-lib/take/take';
import { classNames } from '../../40-utils/classNames';
import { number_positive, string_color, string_module_name } from '../../40-utils/typeAliases';
import { getFullAdvancedAppearance } from '../../50-systems/CollSpace/appearance/getFullAdvancedAppearance';
import { getFullPhongMaterial } from '../../50-systems/CollSpace/appearance/getFullPhongMaterial';
import { IAppearance } from '../../50-systems/CollSpace/appearance/IAppearance';
import { textureToColorSync } from '../../50-systems/CollSpace/appearance/textureToColorSync';
import { textureToSvg } from '../../50-systems/CollSpace/appearance/textureToSvg';
import { IArt } from '../../50-systems/CollSpace/IArt';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { NEAR_DISTANCE } from '../25-AbstractPlacedArt';
import { Abstract2dArt } from '../26-Abstract2dArt';
import { ISvgPath } from '../50-FreehandArt/utils/svgPath/ISvgPath';
import { stringifySvgPath } from '../50-FreehandArt/utils/svgPath/stringifySvgPath';
import { IPolygonShape } from './IPolygonShape';

internalModules.declareModule(() => makeArtModule(PolygonArt));

/**
 * Universal polygon art with N points
 * Polygon is always closed - there is always last edge from last point back to the first point
 *
 * @collboard-modules-sdk
 */
export class PolygonArt extends Abstract2dArt implements IArt<IPolygonShape> {
    public static serializeName = 'Polygon';
    public static manifest = {
        name: '@collboard/internal/polygon-art',
        deprecatedNames: '@collboard/polygon-art',
    };

    private minX: number;
    private maxX: number;
    private minY: number;
    private maxY: number;

    public constructor(public shape: IPolygonShape, public appearance: IAppearance, transform: ITransformData) {
        super();
        this.transform = transform;
    }

    public get moduleName(): string_module_name {
        return PolygonArt.manifest.name;
    }

    private get path(): ISvgPath {
        if (this.shape.points.length < 2) {
            /* [1] */
            return [];
        }

        const remainderFrom = new Vector(this.minX, this.minY).subtract(Vector.square(this.shape.edgeSize));
        const remainder = remainderFrom.subtract(remainderFrom.map(Math.floor));

        let points = this.shape.points;

        // TODO: Do only when there is a stroke
        if (points.length > 2) {
            // Note: Adding first point to the end to close up the polygon stroke
            points = [...points, points[0]!];
        }

        return points.map((point, index) => {
            const pointRelative = Vector.fromObject(point)
                .subtract(new Vector(this.minX - this.shape.edgeSize, this.minY - this.shape.edgeSize))
                .add(remainder);
            return { command: index === 0 ? 'M' : 'L', positions: [pointRelative] };
        });
    }

    public get topLeft() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return new Vector(this.minX, this.minY).add(this.shift);
    }
    public get bottomRight() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return new Vector(this.maxX, this.maxY).add(this.shift);
    }
    public get size() {
        return this.bottomRight.subtract(this.topLeft);
    }
    public set size(newSize: IVectorData) {
        try {
            const scaleX = (newSize.x || 0) / (this.maxX - this.minX);
            const scaleY = (newSize.y || 0) / (this.maxY - this.minY);

            this.shape.points.forEach((point) => {
                const position = point.position as any;
                position.x = (point.x || 0) * scaleX;
                position.y = (point.y || 0) * scaleY;
            });
            this.calculateBoundingBox();
        } catch (e) {
            this.calculateBoundingBox();
        }
    }

    /**
     * Get the color
     * @deprecated [🐀] this is only way how to support old attribute system with the new IArt
     */
    public get color(): string_color {
        return take(this.appearance)
            .then(getFullAdvancedAppearance)
            .then(({ fill }) => fill /* <- TODO: What is the best fill, edge or spot? */)
            .then(getFullPhongMaterial)
            .then(({ emissiveTexture }) => emissiveTexture)
            .then(textureToColorSync)
            .toString();
    }

    /**
     * Set the color
     * @deprecated [🐀] this is only way how to support old attribute system with the new IArt
     */
    public set color(color: string_color) {
        this.appearance = { color };
    }

    /**
     * Get the weight
     * @deprecated [🐀] this is only way how to support old attribute system with the new IArt
     */
    public get weight(): number_positive {
        return this.shape.spotSize /* <- TODO: What is the best spotSize or edgeSize? */;
    }

    /**
     * Set the weight
     * @deprecated [🐀] this is only way how to support old attribute system with the new IArt
     */
    public set weight(weight: number_positive) {
        this.shape = {
            ...this.shape,
            spotSize: weight,
            edgeSize: weight /* <- TODO: What is the best spotSize or edgeSize? */,
        };
    }

    public isNear(pointToTest: IVectorData) {
        // TODO: Detect inside of polygon
        return (
            this.shape.points.filter(
                (point1) => Vector.add(point1, this.shift).distance(pointToTest) <= this.shape.edgeSize + NEAR_DISTANCE,
            ).length > 0
        );
    }

    public get acceptedAttributes() {
        return ['color', 'weight', 'size'];
    }

    /**
     * @deprecated [🍒] remove this method
     */
    private calculateBoundingBox() {
        // TODO: Probably use BoundingBox from LIB touchcontroller

        const xVals = this.shape.points.map((point) => point.x || 0);
        const yVals = this.shape.points.map((point) => point.y || 0);

        // TODO: Use Just Math.min(...) + across the repositiory
        this.minX = Math.min.apply(null, xVals) - this.shape.edgeSize;
        this.maxX = Math.max.apply(null, xVals) + this.shape.edgeSize;
        this.minY = Math.min.apply(null, yVals) - this.shape.edgeSize;
        this.maxY = Math.max.apply(null, yVals) + this.shape.edgeSize;
    }

    public render(isSelected: boolean) {
        this.calculateBoundingBox();

        // TODO: [🍒][🍽️] There is some performance issue that one art is rendered looot of times

        /*
        TODO: [🎢] Use textureDefinition,spotTextureId
        const { textureId: spotTextureId, textureDefinition: spotTextureDefinition } = take(this.appearance)
            .then(getAppearanceFullAdvanced)
            .then(({ spot }) => spot)
            .then(getMaterialFullPhong)
            .then(({ emissiveTexture }) => emissiveTexture)
            .then(textureToSvg);
        */

        const { textureId: edgeTextureId, textureDefinition: edgeTextureDefinition } = take(this.appearance)
            .then(getFullAdvancedAppearance)
            .then(({ edge }) => edge)
            .then(getFullPhongMaterial)
            .then(({ emissiveTexture }) => emissiveTexture)
            .then(textureToSvg);

        const { textureId: fillTextureId, textureDefinition: fillTextureDefinition } = take(this.appearance)
            .then(getFullAdvancedAppearance)
            .then(({ fill }) => fill)
            .then(getFullPhongMaterial)
            .then(({ emissiveTexture }) => emissiveTexture)
            .then(textureToSvg);

        return (
            <div
                // TODO: [🍒][0]! This should became <ArtOwnShell
                className={classNames('art', isSelected && 'selected')}
                style={{
                    position: 'absolute',
                    fontSize: 0 /* <- Note: Removing fontSize to get rid of artefacts of whitespaces in deep zoom */,
                    left: Math.floor(
                        this.minX - this.shape.edgeSize + (this.shift.x || 0),
                    ) /* <- LIB xyzt .toTopLeft() */,
                    top: Math.floor(
                        this.minY - this.shape.edgeSize + (this.shift.y || 0),
                    ) /* <- LIB xyzt .toTopLeft() */,
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={Math.ceil(this.maxX - this.minX + 2 * this.shape.edgeSize) + 1}
                    height={Math.ceil(this.maxY - this.minY + 2 * this.shape.edgeSize) + 1}
                >
                    <defs>
                        {/* [🎢] */}
                        {edgeTextureDefinition}
                        {fillTextureDefinition}
                        {/* <- TODO: Use defs tag only when needed */}
                    </defs>

                    <path
                        d={stringifySvgPath(this.path)}
                        stroke={edgeTextureId}
                        strokeWidth={this.shape.edgeSize}
                        strokeOpacity="null"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill={fillTextureId}
                        className="collisions"
                    />
                </svg>
            </div>
        );
    }
}

/**
 * TODO: [🐁] What is the best order of constructor attributes> public shape: IPolygonShape, public appearance: IAppearance,transform: ITransformData
 *       Make this same for every IArt
 * TODO: [🎢] Implement spotSize - when detecting size of the SVG take bigger of spotSize and edgeSize
 * TODO: [1] What about line as a PolygonArt with 2 points?
 * TODO: [2] What about dot/point as a PolygonArt with 1 point?
 *
 * TODO: Instead of x/y pairs use Vector
 * TODO: [✏️] Fix> react-dom.development.js:630 Error: <svg> attribute width: Expected length, "NaN".
 * TODO: Probbably (maybe in html/css values MUST be whole integers): ACTRY To be infinitelly zoomable avoid using Math.ceil, Math.floor, Math.round,...
 *
 *
 * TODO: Better abstraction 2 compeating approaches
 *       > Intermediate object
 *          - Render can create some intermediate objects which are between data and rendering
 *          - Art -> "intermediate object" -> JSX.Element
 *          - Art -> "intermediate object" -> 3D Mesh
 *          + Figure out where to put in the material
 *          + Some tag for new art system
 *       > Every Abstract2DArt became PolygonArt
 *          + So this "intermediate object" will be just the PolygonArt
 *
 *
 *        - How about Text?
 *        - Go through all other Arts
 *        - ArtShell
 *        - Exports?
 *        - Material
 *        - Serialization
 *        - Migrations
 *        - What new system to create
 *        + Some tag for new art approaches(s)
 *
 *
 * TODO: Can there be some way how to extend PolygonArt (as "intermediate object") to 3D
 * TODO: [🚉] There should be some rounding optimization for svg numbers (for example path)
 *       From: d="M60.00000000000006 70.4999999999999 L60.00000000000006 70.4999999999999...
 *       To:   d="M60 70.5 L60 70.5...
 *
 * TODO: [🌫️] Should be shape, appearance and transform readonly properties
 *      > public shape: IPointShape, public appearance: IAppearance
 *      > public readonly shape: IPolygonShape, public readonly appearance: IAppearance
 * TODO: [👨‍🦰] minX, maxX, minY, maxY should not be serialized - make it through getters, private for serializer or use new system of shape+appearance+transform
 *
 */
