import React from 'react';
import { Clickable } from '../../../../30-components/utils/Clickable';
import { TranslationsSystem } from '../../../TranslationsSystem/0-TranslationsSystem';
import { INotificationAction } from '../../INotification';

interface INotificationActionComponentProps {
    translationsSystem: TranslationsSystem;
    action: INotificationAction;
}

export function NotificationActionComponent({
    translationsSystem,
    action: { title, type, onClick, href },
}: INotificationActionComponentProps) {
    return (
        <Clickable {...{ onClick, href }} className={`button button-${type}`}>
            {translationsSystem.pickMessage(title)}
        </Clickable>
    );
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
