import { ILogger, ISubLogger } from './ILogger';

export class SwitchLogger implements ILogger {
    public constructor(private logger: ILogger) {}

    public switch(logger: ILogger) {
        this.logger = logger;
    }

    public info(message?: any, ...optionalParams: Array<any>): this {
        this.logger.info(message, ...optionalParams);
        return this;
    }
    public warn(warning?: any, ...optionalParams: Array<any>): this {
        this.logger.warn(warning, ...optionalParams);
        return this;
    }
    public error(error?: Error | any, ...optionalParams: Array<any>): this {
        this.logger.error(error, ...optionalParams);
        return this;
    }

    public trace(...data: Array<any>): this {
        this.logger.trace(...data);
        return this;
    }

    public group(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        return this.logger.group(groupName, ...optionalParams);
    }

    public groupCollapsed(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        return this.logger.groupCollapsed(groupName, ...optionalParams);
    }
}
