import React from 'react';
import { internalModules } from '../../../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../../../50-systems/ModuleStore/makers/makeModalModule';
import { ShareModal } from './ShareModal';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            name: '@collboard/internal/share',
            deprecatedNames: ['@collboard/share', 'Share'],
            title: { en: 'Share', cs: 'Sdílet' },
            requirePermissions: ['view'],
        },
        async createModal(systems) {
            // TODO: Here should be a systems request
            return <ShareModal />;
        },
    }),
);

/**
 * TODO: [🐅] Maybe some more elegant way how to create icon-window pairs of modules
 */
