import { BehaviorSubject } from 'rxjs';

/**
 * @deprecated [🍿] Do not use enums but object as const OR 'LITERAL VALUES' instead
 */
export enum ColorScheme {
    Dark = 'DARK',
    Light = 'LIGHT',
}

/**
 *
 * Note: Prefer to use value provided by styleSystem.colorScheme
 */
export function watchPreferedColorScheme(userPickedColorScheme?: ColorScheme): BehaviorSubject<ColorScheme> {
    const preferedColorScheme = new BehaviorSubject<ColorScheme>(userPickedColorScheme || systemColorScheme());

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
        if (event.matches) {
            preferedColorScheme.next(ColorScheme.Dark);
        } else {
            preferedColorScheme.next(ColorScheme.Light);
        }
    });

    // TODO: Teardown logic
    // TODO: Protect pushing from outside

    return preferedColorScheme;
}

export function systemColorScheme(): ColorScheme {
    if (!window.matchMedia) {
        return ColorScheme.Light;
    } else {
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? ColorScheme.Dark : ColorScheme.Light;
    }
}

/**
 * TODO: 😎 When dark mode activated change word "whiteboard" to "blackboard"
 */
