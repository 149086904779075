import React, { ReactNode } from 'react';
import { Promisable } from 'type-fest';
import { classNames } from '../../40-utils/classNames';
import { string_href } from '../../40-utils/typeAliases';
import { consolex } from '../../consolex';
import { ILoaderProps } from './Loader/ILoaderProps';

interface IAsyncButtonComponentProps extends ILoaderProps {
    /**
     * Component to be rendered after button is clicked and before onClick fullfils
     *
     * If not set, default <Loader/> is used
     */
    loader?: JSX.Element;

    /**
     * Click handler which can return promise and the button will show loading state (and can not be pressed twice) until resolved
     */
    onClick(
        event: React.MouseEvent/* <- TODO: Import and use just a MouseEvent */ <
            HTMLAnchorElement | HTMLDivElement,
            MouseEvent
        >,
    ): Promisable<void>;

    /**
     * If set, <a href="..."> will be used
     *
     * Note: Outer element will be always <div to keep consistency
     */
    href?: string_href;

    /**
     * Additional className(s)
     */
    className?: string;

    /**
     * Additional styling
     */
    style?: any; //React.StyleHTMLAttributes/* <- TODO: Import and use just a StyleHTMLAttributes */<HTMLDivElement>;

    /**
     * If set, onClick will not be called
     */
    isDisabled?: boolean;

    /**
     * Content of the button
     */
    children: ReactNode;
}

/**
 * Utility for button with pending state after click
 *
 * @collboard-modules-sdk
 */
export function AsyncButtonComponent({
    loader,
    onClick,
    href,
    className,
    style,
    children,

    isDisabled: disabled,
}: IAsyncButtonComponentProps) {
    const [isLoading, setIsLoading] = React.useState/* <- TODO: Import and use just a useState */ <boolean>(false);

    const onClickHandler = async (event: React.MouseEvent/* <- TODO: Import and use just a MouseEvent */ <any>) => {
        try {
            setIsLoading(true);
            await onClick(event);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            loader || (
                <div
                    {...{
                        className: classNames(className, 'loading', disabled && 'disabled'),
                        style,
                        disabled,
                    }}
                >
                    {children}
                </div>
            )
        );
    } else {
        // TODO: DRY variant with and without href
        if (href) {
            return (
                <div
                    {...{
                        className: classNames(className, disabled && 'disabled'),
                        style,
                        disabled,
                        onClick: disabled
                            ? (event) => {
                                  event.preventDefault();
                                  onClickHandler(event);
                              }
                            : onClickHandler,
                    }}
                >
                    <a
                        {...{
                            href,
                        }}
                        onClick={(event) => event.preventDefault()}
                    >
                        {children}
                    </a>
                </div>
            );
        } else {
            return (
                <div
                    {...{
                        className: classNames(className, disabled && 'disabled'),
                        style,
                        disabled,
                        onClick: disabled
                            ? () => {
                                  consolex.info(`Nothing to do, button is disabled`);
                              }
                            : onClickHandler,
                    }}
                >
                    {children}
                </div>
            );
        }
    }
}

/**
 * TODO: <Clickable vs. <Button (use <button as element) vs. (deprecated <AsyncButtonComponent)
 * TODO: Use here alt and canLoadForever
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
