import React from 'react';
import { AsyncButtonComponent } from '../../../../30-components/utils/AsyncButtonComponent';
import { induceFileDownload } from '../../../../40-utils/files/induceFileDownload';
import { useSystems } from '../../../../40-utils/react-hooks/useSystems';
import { ExportScopeSimple } from '../../../../50-systems/ExportSystem/interfaces/IExportScope';
import { Translate } from '../../../../50-systems/TranslationsSystem/components/Translate';

export function ShareModalDownload() {
    // console.log('🅰️', 'ShareModalDownload', 'render');

    const { exportSystem } = useSystems('exportSystem');

    return (
        <>
            <hr />
            {/* TODO: [🚥] <ModalLine /> */}
            <span className="modal-input-title">
                <Translate name="ShareIcon/ download as html" html>
                    Stáhnout jako HTML <i>(pro offline použití)</i>:
                </Translate>
            </span>
            <AsyncButtonComponent
                alt="Exporting"
                style={{ marginLeft: 0 }}
                className="button button-primary modal-button"
                onClick={async () => {
                    const [preparedFileExport] = await exportSystem.exportFilesPrepare({
                        isHeavyExport: true,
                        scope: ExportScopeSimple.Board,
                        mimeType: 'text/html',
                        isNative: true,
                        isMaterialized: true /* <-[👿] */,
                    });
                    await induceFileDownload(await preparedFileExport.exporting!());
                }}
            >
                <Translate name="Download">Download</Translate>
                {/*' .' + extension(preparedFileExport.fileSupporter.mimeType)*/}
            </AsyncButtonComponent>
        </>
    );
}
