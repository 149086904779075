import { ITranslateMessagePicker } from '../../TranslationsSystem/interfaces/ITranslateMessagePicker';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { getModuleSortingPriority } from './getModuleSortingPriority';

export function sortModules(
    messagePicker: ITranslateMessagePicker,
    manifest1: IModuleManifest,
    manifest2: IModuleManifest,
): 0 | 1 | -1 {
    const priority1 = getModuleSortingPriority(manifest1);
    const priority2 = getModuleSortingPriority(manifest2);

    if (priority1 > priority2) {
        return -1;
    } else if (priority1 < priority2) {
        return 1;
    }

    const title1 = messagePicker.pickStringMessage(manifest1?.title || manifest1?.name || 'untitled');
    const title2 = messagePicker.pickStringMessage(manifest2?.title || manifest2?.name || 'untitled');

    if (title1 > title2) {
        return 1;
    } else if (title1 < title2) {
        return -1;
    }

    // TODO: Probably add detetministic sorting by name, and other params

    return 0;
}

/**
 *  TODO: Unit test
 */
