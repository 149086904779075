import React from 'react';
import { useSystems } from '../../../../40-utils/react-hooks/useSystems';
import { ShareModalDownload } from './ShareModalDownload';
import { ShareModalEditLink } from './ShareModalEditLink';
import { ShareModalViewLink } from './ShareModalViewLink';

export function ShareModal() {
    // console.log('🅰️', 'ShareModal, 'render'');

    const { materialArtVersioningSystem } = useSystems('materialArtVersioningSystem');

    return (
        <>
            {materialArtVersioningSystem.permissions.edit && <ShareModalEditLink />}
            <ShareModalViewLink />
            <ShareModalDownload />
        </>
    );
}

/**
 * TODO: ShareModalDownload can be rendered without routingSystem loaded
 */
