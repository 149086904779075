import React from 'react';
import { forTime } from 'waitasecond';
import { ILoaderProps } from './Loader/ILoaderProps';

interface IRefreshContentComponentProps extends ILoaderProps {
    /**
     * Refresh interval in milisecondes
     */
    interval: number;

    /**
     * The content to be rendered
     */
    content: () => JSX.Element | Array<JSX.Element>;
}

interface IRefreshContentComponentState {
    content: JSX.Element | Array<JSX.Element> | null;
}

/**
 * Utility for mounting content, which should be regularly refreshed
 *
 * @collboard-modules-sdk
 */
export class RefreshContentComponent extends React.Component/* <- TODO: Import and use just a Component */ <
    IRefreshContentComponentProps,
    IRefreshContentComponentState
> {
    state: IRefreshContentComponentState;

    public constructor(props: IRefreshContentComponentProps) {
        super(props);
        this.loop();
    }

    private async loop() {
        while (true) {
            const content = this.props.content();
            this.setState({ content });
            await forTime(this.props.interval);
        }
    }

    componentWillUnmount() {
        // TODO: Implement
    }

    render() {
        if (!this.state) return null;
        return this.state.content;
    }
}

/**
 * TODO: [🏛️] Convert Class components to Functional (with hooks).
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 * TODO: [🧵] Move to external LIB for react loadables
 */
