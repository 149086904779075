import { IDestroyable } from 'destroyable';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { share } from 'rxjs/operators';
import { forValueDefined } from 'waitasecond';
import { Commit } from '../../10-database/Commit';
import { ICommitData } from '../../10-database/interfaces/ICommitData';
import { meaningfullError } from '../../40-utils/errors/meaningfullError';
import { uuid } from '../../40-utils/typeAliases';
import { AbstractArt } from '../../71-arts/20-AbstractArt';
import { AbstractPlacedArt } from '../../71-arts/25-AbstractPlacedArt';
import { CornerstoneArt, virtualCornerstoneArt } from '../../71-arts/30-CornerstoneArt';
import { consolex } from '../../consolex';
import { ISystemsExtended } from '../00-SystemsContainer/ISystems';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { ArtSerializer } from '../ArtSerializer/ArtSerializer';
import { deepClone } from '../ArtSerializer/utils/deepClone';
import { IClosePreventionSystem } from '../ClosePreventionSystem/IClosePreventionSystem';
import { IdentitySystem } from '../IdentitySystem/0-IdentitySystem';
import { IArtVersioningSystem } from './IArtVersionSystem';
import { IModuleSignature } from './IModuleSignature';
import { IPermissions } from './IPermissions';
import { Operation } from './Operation';
/**
 * ArtVersionSystem synchronizes the arts with the remote server.
 *
 * @collboard-system
 */
export class MaterialArtVersioningSystem extends AbstractSystem implements IArtVersioningSystem, IDestroyable {
    public readonly commitsObservable: Observable<Commit>;
    public readonly artsObservable: Observable<AbstractArt>;
    public permissions: IPermissions; // TODO: Use it inside to entangle behaviour of ArtVersionSystem

    public /* TODO: Private*/ commitsPool: Array<ICommitData> = [];
    private currentCommitsPool: Record<uuid, ICommitData> = {}; // TODO: Is it needed? Maybe replace with just commitsPool;
    private commitsObserver?: Observer<ICommitData>;

    public constructor(systems: ISystemsExtended, readonly moduleSignature: IModuleSignature) {
        super(systems);

        this.commitsObservable = Observable.create(
            (/* TODO: [🎎] Probbably just use Subject -> */ observer: Observer<ICommitData>) => {
                this.commitsObserver = observer;
            },
        ).pipe(share()); // TODO: Probably publish or none

        this.artsObservable = Observable.create(
            (/* TODO: [🎎] Probbably just use Subject -> */ observer: Observer<AbstractArt>) => {
                this.commitsObservable.subscribe((commit) => {
                    observer.next(commit.art as AbstractArt);
                });
            },
        ).pipe(share()); // TODO: Probably publish or none

        this.artsObservable.subscribe((art) => {
            // TODO: This subscription should be isDestroyed with a system
            if (art instanceof CornerstoneArt) {
                this.cornerstoneArts.next(art);
            }
        });
    }

    protected closePreventionSystem: IClosePreventionSystem;
    private identitySystem: IdentitySystem;
    private artSerializer: ArtSerializer;

    protected async init() {
        this.identitySystem = await this.systems.identitySystem;
        this.closePreventionSystem = await this.systems.closePreventionSystem;
        this.artSerializer = await this.systems.artSerializer;

        /*/
        // Note: [🧙‍♂️] Keeping this for future debugging
        (async () => {
            while (true) {
                await forTime(300);
                consolex.info(this.commitsPool.length);
                // consolex.info(this.commitsPool[0]!?.artClass);
            }
        })();
        /**/
    }

    public get clientId() {
        // TODO: Should ArtVersionSystem do proxy for this?
        this.checkWhetherNotDestroyed(`${this.constructor.name}: Can not get clientId, because I am destroyed.`);
        return this.identitySystem.browserId;
    }

    public signAs(moduleSignature: IModuleSignature): MaterialArtVersioningSystem {
        // TODO: !! security chceck of moduleSignature vs this.moduleSignature
        //const signed = new ArtVersionSystem(this.systemsContainer, moduleSignature);
        //return signed;

        return { ...this, moduleSignature };
    }

    public createOperation(operationName: string): Operation {
        this.checkWhetherNotDestroyed(`${this.constructor.name}: Can not create operation, because I am destroyed.`);
        return new Operation(
            this,
            this.pushCommitsToVersioningSystem.bind(this),
            this.closePreventionSystem,
            operationName,
        );
    }

    public createPrimaryOperation(): Operation {
        return this.createOperation('PRIMARY');
    }

    protected async pushCommitsToVersioningSystem(...commits: Array<ICommitData>): Promise<void> {
        // TODO: this method should be protected - probbably via a Symbol
        this.checkWhetherNotDestroyed(
            `${this.constructor.name}: Can not recieve commit, because recieving versioning system is already destroyed.`,
            () => {
                consolex.info({ commits });
            },
        );
        const commitsObserver = await meaningfullError(
            async () => await forValueDefined(() => this.commitsObserver),
            `this.commitsObserver is not defined for a reasonable time`,
        );

        for (const commit of commits) {
            commit.addSeenBy(this.identitySystem.browserId);

            /*
            TODO: Optimalization
            //consolex.log(`${commit.previousId} => ${commit.commitId}`);
            if (commit.previousId) {
                if (!this.previousIdChains[commit.previousId] || commit.persist) {
                    this.previousIdChains[commit.commitId] = commit.previousId;
                } else {
                    this.previousIdChains[commit.commitId] = this.previousIdChains[commit.previousId];

                    /*
                    consolex.log(
                        `${this.previousIdChains[commit.commitId]} => ${this.previousIdChains[commit.previousId]}`,
                    );* /
                }
                delete this.previousIdChains[commit.previousId];
            }
            */

            if (commit.replacingStrategy === 'REPLACE' && commit.previousId && !commit.isSeenBy('remote')) {
                /*
                    TODO: Optimalization
                    if (this.previousIdChains[commit.previousId]) {
                        consolex.info(`Replacing previousId because it was chained.`);
                        commit.previousId = this.previousIdChains[commit.previousId];
                        //delete this.previousIdChains[commit.previousId];
                    }
                    */

                /**/
                // TODO: Probably code in this block can be more simple
                // TODO: What about the server part
                let wasPrevious = false;
                let previousId: string | null = null;
                this.commitsPool = this.commitsPool.filter((commit2) => {
                    const isPrevious = commit2.commitId === commit.previousId;
                    if (isPrevious) {
                        if (!wasPrevious) {
                            wasPrevious = true;
                            previousId = commit2.previousId;
                        } else {
                            throw new Error(
                                `${this.constructor.name}: There are more commits with commitId ${commit.previousId}.`,
                            );
                        }
                    }

                    return !isPrevious;
                });

                if (!wasPrevious) {
                    consolex.warn(`${this.constructor.name}: There are no commits with commitId ${commit.previousId}.`);
                } else {
                    // consolex.warn(`${this.constructor.name}: Changing previousId ${commit.previousId} to ${previousId}.`);
                    commit.previousId = previousId;
                    commit.replacingStrategy = 'KEEP';
                }
                /**/
            }

            // TODO: Is there a need for clonning?
            // TODO: Limit number of commits kept in memory

            if (commit.persist && !commit.isSeenBy('remote')) {
                // consolex.info(`${this.constructor.name}: Making deep clone of ${commit.operationName}`);
                this.commitsPool.push(
                    (await deepClone({ serializer: this.artSerializer, value: commit })) as ICommitData,
                );
            } else {
                this.commitsPool.push(commit);
            }

            this.currentCommitsPool[commit.treeId] = commit;
        }
        for (const commit of commits) {
            commitsObserver.next(commit);
        }
    }

    public get lastCommitId() {
        this.checkWhetherNotDestroyed(`${this.constructor.name}: Can not get last commit id, because I am destroyed.`);
        return Math.max(
            -1,
            ...Object.values(this.currentCommitsPool)
                .map((commit) => commit.id)
                .filter(Boolean),
        );
    }

    public setCommitWasPersisted(commitId: uuid, id: number): void {
        this.checkWhetherNotDestroyed(
            `${this.constructor.name}: Can not setCommitWasPersisted, because I am destroyed.`,
        );
        for (const commit of Object.values(this.currentCommitsPool)) {
            if (commit.commitId === commitId) {
                if (commit.id) {
                    consolex.info(`${this.constructor.name}: commit`, commit);
                    consolex.info(`${this.constructor.name}: id`, id);
                    throw new Error(
                        `${this.constructor.name}: Can not persist commit, because it is already persisted.`,
                    );
                }
                commit.id = id;
                return;
            }
        }
        // TODO: This error occured lot of times - it does not cause troubles but in future we should fix it
        // throw new Error(`${this.constructor.name}: Can not persist commit, because it does not exists.`);
    }

    public get commits(): Array<ICommitData> {
        this.checkWhetherNotDestroyed(`${this.constructor.name}: Can not get commits, because I am destroyed.`);
        return Object.values(this.currentCommitsPool);
    }

    public get arts(): Array<AbstractArt> {
        this.checkWhetherNotDestroyed(`${this.constructor.name}: Can not get arts, because I am destroyed.`);
        return this.commits.map((commit) => commit.art as AbstractArt);
    }

    public readonly cornerstoneArts = new BehaviorSubject<CornerstoneArt>(virtualCornerstoneArt);

    public get commitsPlaced(): Array<ICommitData> {
        this.checkWhetherNotDestroyed(`${this.constructor.name}: Can not get commitsPlaced, because I am destroyed.`);
        return this.commits.filter((commit) => commit.art instanceof AbstractPlacedArt);
    }

    public get artsPlaced(): Array<AbstractPlacedArt> {
        this.checkWhetherNotDestroyed(`${this.constructor.name}: Can not get artsPlaced, because I am destroyed.`);
        return this.arts.filter((art) => art instanceof AbstractPlacedArt) as Array<AbstractPlacedArt>;
    }

    public get lastCommit(): ICommitData | null {
        this.checkWhetherNotDestroyed(`${this.constructor.name}: Can not get lastCommit, because I am destroyed.`);
        return this.commitsPool[this.commitsPool.length - 1] || null;
    }

    public findPreviousCommit(commit: ICommitData): ICommitData | null {
        this.checkWhetherNotDestroyed(
            `${this.constructor.name}: Can not find previous commit, because I am destroyed.`,
        );
        return this.findCommitById(commit.previousId);
    }

    public findNextCommits(commit: ICommitData): Array<ICommitData> {
        this.checkWhetherNotDestroyed(`${this.constructor.name}: Can not find next commits, because I am destroyed.`);
        // TODO: Probably more efficient
        // TODO: DRY
        return this.commitsPool.filter(
            (maybeNextCommit) => /* TODO: Probably check treeId? */ commit.commitId === maybeNextCommit.previousId,
        );
    }

    public findNextCommit(commit: ICommitData): ICommitData | null {
        this.checkWhetherNotDestroyed(`${this.constructor.name}: Can not find next commit, because I am destroyed.`);
        // TODO: Probably more efficient
        // TODO: DRY
        return (
            this.commitsPool.find(
                (maybeNextCommit) => /* TODO: Probably check treeId? */ commit.commitId === maybeNextCommit.previousId,
            ) || null
        );
    }

    public hasNextCommits(commit: ICommitData): boolean {
        this.checkWhetherNotDestroyed(
            `${this.constructor.name}: Can not check if commit has next commits, because I am destroyed.`,
        );
        // TODO: Probably more efficient
        // TODO: DRY
        return this.commitsPool.some(
            (maybeNextCommit) => /* TODO: Probably check treeId? */ commit.commitId === maybeNextCommit.previousId,
        );
    }

    public findOriginCommit(commit: ICommitData): ICommitData {
        this.checkWhetherNotDestroyed(`${this.constructor.name}: Can not find origin commit, because I am destroyed.`);
        const originCommit = this.commitsPool.find(
            (maybeOriginCommit) => commit.treeId === maybeOriginCommit.treeId && !maybeOriginCommit.previousId,
        );

        if (!originCommit) {
            consolex.info(`${this.constructor.name}: commit`, commit);
            consolex.info(
                `${this.constructor.name}: commits where operationName = ORIGIN`,
                this.commitsPool.filter((commit2) => commit2.operationName === 'ORIGIN'),
            );
            consolex.info(
                `${this.constructor.name}: commits where previousId is null`,
                this.commitsPool.filter((commit2) => !commit2.previousId),
            );
            consolex.info(
                `${this.constructor.name}: commits where treeId = ${commit.treeId}`,
                this.commitsPool.filter((commit2) => commit2.treeId === commit.treeId),
            );
            throw new Error(`${this.constructor.name}: Origin commit should exists in the pool.`);
        }

        return originCommit;
    }

    public isLastInItsTree(commit: ICommitData): boolean {
        this.checkWhetherNotDestroyed(
            `${this.constructor.name}: Can not check id commit is last in its tree, because I am destroyed.`,
        );
        for (let i = this.commitsPool.length - 1; i >= 0; i--) {
            const maybeNextCommit = this.commitsPool[i];

            if (commit === maybeNextCommit) {
                // TODO: Is this correct optimization
                return true;
            }

            if (commit.treeId === maybeNextCommit.treeId) {
                // TODO: Probably more correct will be via commit.commitId === maybeNextCommit.previousId
                return false;
            }
        }
        return true;
    }

    public findLastCommitOfArt(art: AbstractArt): ICommitData | null {
        this.checkWhetherNotDestroyed(
            `${this.constructor.name}: Can not find last commit of art, because I am destroyed.`,
        );
        for (const commit of Object.values(this.currentCommitsPool)) {
            if (commit.art === art) {
                return commit;
            }
        }
        return null;
    }

    /**
     *
     * @param commitId when null or undefined returns null
     */
    public findCommitById(commitId: uuid | null | undefined): ICommitData | null {
        this.checkWhetherNotDestroyed(`${this.constructor.name}: Can not find commit by id, because I am destroyed.`);
        if (!commitId) return null;

        const commit = this.commitsPool.find((commit2) => commit2.commitId === commitId);
        if (commit) {
            return commit;
        } else {
            consolex.info(`${this.constructor.name}: commitsPool`, this.commitsPool);
            consolex.warn(`${this.constructor.name}: Can not find commit with id ${commitId}.`);
            return null;
        }
    }

    /**
     * Note: This is just a debug class
     */
    public get sortCommitsByOperationId(): Array<Array<ICommitData>> {
        this.checkWhetherNotDestroyed(
            `${this.constructor.name}: Can not sort commits by operation id, because I am destroyed.`,
        );
        const commitsByOperationId: Array<Array<ICommitData>> = [];
        for (const commit of this.commitsPool) {
            let commitsByOperationIdOperation: Array<ICommitData>;
            const commitsByOperationIdOperation2 = commitsByOperationId.find((commits) =>
                commits.some((commit2) => commit2.operationId === commit.operationId),
            );
            if (commitsByOperationIdOperation2) {
                commitsByOperationIdOperation = commitsByOperationIdOperation2;
            } else {
                commitsByOperationIdOperation = [];
                commitsByOperationId.push(commitsByOperationIdOperation);
            }
            commitsByOperationIdOperation.push(commit);
        }
        return commitsByOperationId;
    }

    public findCommitsByOperationId(operationId: uuid): Array<ICommitData> {
        this.checkWhetherNotDestroyed(
            `${this.constructor.name}: Can not find commits by operation id, because I am destroyed.`,
        );
        // TODO: Find only last commit of the Tree
        return this.commitsPool.filter((commit) => commit.operationId === operationId);
    }
}

/**
 * TODO: This is used a lot so maybe we can figure out some better name
 * TODO: In future move out permissions to PermissionsSystem
 * TODO: [🏔️]  All art getters should be cold obserservables
 */
