import React from 'react';
import { ISystemsExtended, SystemName } from '../../50-systems/00-SystemsContainer/ISystems';
import { ISystemsResolved } from '../../50-systems/00-SystemsContainer/ISystemsResolved';

/**
 * Context for systems container used in modules
 * Note: This context is provided at top render level (with translations and skin)
 *
 * @not-collboard-modules-sdk because this is core hook
 */
export const UnsignedSystemsContainerContext =
    React.createContext/* <- TODO: Import and use just a createContext */ <ISystemsExtended | null>(null);

/**
 * Use systems in core
 *
 * Note: This function is working ONLY in core and NOT in modules because it works with unsigned systems
 *
 * @param requestedSystemsNames Names of requested systems
 * @returns record object which contains all requested systems
 *
 * @not-collboard-modules-sdk because this is core util
 */
export function useSystemsInCore<TSystemNames extends SystemName>(
    ...requestedSystemsNames: Array<TSystemNames>
): Pick<ISystemsResolved, TSystemNames> {
    const unsignedSystemsContainerContext = React.useContext(
        /* <- TODO: Import and use just a useContext */ UnsignedSystemsContainerContext,
    );

    // Note: unsignedSystemsContainerContext is always defined because it is provided at top render level (with translations and skin)
    const systems = unsignedSystemsContainerContext!.use(...requestedSystemsNames);
    return systems;
}

/**
 * Note: For security reason there are two separate hooks and contexts:
 *       - useSystems is used in modules and gives only the requested systems
 *       - useSystemsInCore which contains all systems provided to core and it is not exported to modules (= like a root access)
 */
