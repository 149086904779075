import React from 'react';
import { AsyncSelectComponent } from '../../../30-components/utils/select/AsyncSelectComponent';
import { useSystems } from '../../../40-utils/react-hooks/useSystems';
import { ExportScopeSimple, IExportScope } from '../interfaces/IExportScope';

interface IExportScopePickerComponentProps {
    value: IExportScope | null;
    onChange(exportScope: IExportScope): void;
}

/**
 * Picker for export scope (=which arts to include in export)
 * Note: Need to be used inside of a module
 *
 * @collboard-modules-sdk
 */
export function ExportScopePickerComponent({ value, onChange }: IExportScopePickerComponentProps) {
    const { appState, exportSystem, translationsSystem } = useSystems('appState', 'exportSystem', 'translationsSystem');

    return (
        <AsyncSelectComponent
            {...{ value, onChange }}
            options={async () =>
                [
                    {
                        title: translationsSystem.translate(`Download / Entire board`, `Celá tabule`),
                        value: ExportScopeSimple.Board,
                    },
                    {
                        title: translationsSystem.translate(`Download / Selection`, `Označené`),
                        value: ExportScopeSimple.Selection,
                        isDisabled:
                            appState.selected.value.length ===
                            0 /* <- Note: There is no need for observing selected here */,
                    },

                    // TODO: There should be some observation for aviable frames
                    ...(await exportSystem.getFrames()).map((frame, index) => ({
                        title: translationsSystem.pickStringMessage(frame.art.title),
                        value: frame.art,
                    })),
                ].filter(Boolean)
            }
        />
    );
}

/**
 * TODO: Listen for new frames
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
