import React from 'react';
import { Observable } from 'rxjs';
import { Promisable } from 'type-fest';
import { consolex } from '../../consolex';
import { ILoaderProps } from './Loader/ILoaderProps';
import { Loader } from './Loader/Loader';
import { LOADING_CONSOLE_WARN_STYLE } from './Loader/utils/createTimeout';

interface IObservableContentComponentProps extends ILoaderProps {
    /**
     * Component to be rendered before the content is loaded
     * If not set, default <Loader/> is used
     */
    loader?: JSX.Element;

    /**
     * You can put here any RxJS observable. For example BehaviorSubject.
     */
    content: Observable<Promisable<JSX.Element | Array<JSX.Element>>>;

    /**
     * Will be debugged in the console
     */
    isDebugging?: boolean;
}

interface IObservableContentComponentState {
    content: JSX.Element | Array<JSX.Element>;
}

/**
 * Utility for mounting RxJS observable content
 *
 * @deprecated [📠] Use useObservable/useLoadable hook instead
 * @collboard-modules-sdk
 */
export function ObservableContentComponent({ loader, content, alt, isDebugging }: IObservableContentComponentProps) {
    if (isDebugging) {
        consolex.info('%c Debugging ObservableContentComponent', LOADING_CONSOLE_WARN_STYLE);
    }

    const [state, setState] =
        React.useState/* <- TODO: Import and use just a useState */ <IObservableContentComponentState>({
            content: loader || <Loader {...{ alt }} />,
        });

    React.useEffect(
        /* <- TODO: Import and use just a useEffect */ () => {
            const subscription = content.subscribe(async (newContentAwaitable) => {
                if (isDebugging) {
                    consolex.info('%c New content arrived', LOADING_CONSOLE_WARN_STYLE);
                }
                const newContent = await newContentAwaitable;

                // Note+TODO: On this line the component can be unmounted but setState is still called one time again. React will indicates a memory leak (it happen only once), it is not a bit deal but maybe solve it.

                if (isDebugging) {
                    consolex.info('%c New content aviable', LOADING_CONSOLE_WARN_STYLE);
                }

                setState({ content: newContent });
            });

            return () => subscription.unsubscribe();
        },
        [content, isDebugging],
    );

    return <>{state.content}</>;
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 * TODO: [🧵] Move to external LIB for react loadables
 */
