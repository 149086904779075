import { ISerializable } from 'everstorage';
import { ISerializer } from '../interfaces/ISerializer';

interface IDeepCloneOptions<TCloned extends ISerializable> {
    serializer: ISerializer<TCloned>;
    value: TCloned;
}

/**Route
 * Deeply clones object by serializing and deserializing it.
 *
 * @param instance to be clonned
 * @returns new instance of the same class with same data
 */
export async function deepClone<TCloned extends ISerializable>(options: IDeepCloneOptions<TCloned>): Promise<TCloned> {
    const { serializer, value } = options;
    const serialized = await serializer.serialize(value);
    // Note: We do not need to call JSON.stringify + JSON.parse here because
    //       the serializer already iterates over all objects and arrays and copy each item
    return await serializer.deserialize(serialized);
}

/**
 * TODO Use instead of deepClone the native structuredClone
 */
