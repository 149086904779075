import React from 'react';
import { ITransformData, IVectorData, Vector } from 'xyzt';
import { take } from '../../00-lib/take/take';
import { classNames } from '../../40-utils/classNames';
import { number_positive, string_color, string_module_name } from '../../40-utils/typeAliases';
import { getFullAdvancedAppearance } from '../../50-systems/CollSpace/appearance/getFullAdvancedAppearance';
import { getFullPhongMaterial } from '../../50-systems/CollSpace/appearance/getFullPhongMaterial';
import { IAppearance } from '../../50-systems/CollSpace/appearance/IAppearance';
import { textureToColorSync } from '../../50-systems/CollSpace/appearance/textureToColorSync';
import { textureToSvg } from '../../50-systems/CollSpace/appearance/textureToSvg';
import { IArt } from '../../50-systems/CollSpace/IArt';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { NEAR_DISTANCE } from '../25-AbstractPlacedArt';
import { Abstract2dArt } from '../26-Abstract2dArt';
import { IPointShape } from './IPointShape';

internalModules.declareModule(() => makeArtModule(PointArt));

/**
 * Just a point/flag/marker or whatever pointy on the board
 *
 * @collboard-modules-sdk
 */
export class PointArt extends Abstract2dArt implements IArt<IPointShape> {
    public static serializeName = 'Point';
    public static manifest = {
        name: '@collboard/internal/point-art',
        deprecatedNames: '@collboard/point-art',
    };

    public constructor(public shape: IPointShape, public appearance: IAppearance, transform: ITransformData) {
        super();
        this.transform = transform;
    }

    public get moduleName(): string_module_name {
        return PointArt.manifest.name;
    }

    public get topLeft() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return this.shift;
    }
    public get bottomRight() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return this.shift;
    }
    public get size() {
        return Vector.zero();
    }
    public set size(newSize: IVectorData) {
        // Note: There should be setter for the size property but it should intentionally do nothing
        return;
    }

    /**
     * Get the color
     * @deprecated [🐀] this is only way how to support old attribute system with the new IArt
     */
    public get color(): string_color {
        return take(this.appearance)
            .then(getFullAdvancedAppearance)
            .then(({ spot }) => spot)
            .then(getFullPhongMaterial)
            .then(({ emissiveTexture }) => emissiveTexture)
            .then(textureToColorSync)
            .toString();
    }

    /**
     * Set the color
     * @deprecated [🐀] this is only way how to support old attribute system with the new IArt
     */
    public set color(color: string_color) {
        this.appearance = { color };
    }

    /**
     * Get the weight
     * @deprecated [🐀] this is only way how to support old attribute system with the new IArt
     */
    public get weight(): number_positive {
        return (this.shape.spotSize - 5) / 3 /* <- DRY [🌞] */;
    }

    /**
     * Set the weight
     * @deprecated [🐀] this is only way how to support old attribute system with the new IArt
     */
    public set weight(weight: number_positive) {
        this.shape = { spotSize: weight * 3 + 5 /* <- DRY [🌞] */ };
    }

    public isNear(pointToTest: IVectorData) {
        return Vector.distance(this.shift, pointToTest) < this.shape.spotSize + NEAR_DISTANCE;
    }

    public get acceptedAttributes() {
        return ['color', 'weight'];
    }

    public render(isSelected: boolean) {
        const { textureId, textureDefinition } = take(this.appearance)
            .then(getFullAdvancedAppearance)
            .then(({ spot }) => spot)
            .then(getFullPhongMaterial)
            .then(({ emissiveTexture }) => emissiveTexture)
            .then(textureToSvg);

        return (
            <div
                // TODO: [🍒][0]! This should became <ArtOwnShell
                className={classNames('art', isSelected && 'selected')}
                style={{
                    position: 'absolute',
                    fontSize: 0 /* <- Note: Removing fontSize to get rid of artefacts of whitespaces in deep zoom */,
                    left: Math.floor(-this.shape.spotSize / 2 + (this.shift.x || 0)) /* <- LIB xyzt .toTopLeft() */,
                    top: Math.floor(-this.shape.spotSize / 2 + (this.shift.y || 0)) /* <- LIB xyzt .toTopLeft() */,
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width={this.shape.spotSize} height={this.shape.spotSize}>
                    <defs>{textureDefinition}</defs> {/* <- TODO: Use defs tag only when needed */}
                    <circle cx="50%" cy="50%" r={this.shape.spotSize / 2} fill={textureId} />
                </svg>
            </div>
        );
    }
}

/**
 * [🧗‍♀️] CornerstoneArt extends PointArt
 * TODO: Also other designs of the point like ❌, ⭕, 🚩,...
 * TODO: [🌫️]
 * TODO: [🐁]
 */
