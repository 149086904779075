import { string_uri_part } from '../typeAliases';
import { NotFoundError } from './NotFoundError';

/**
 * @collboard-modules-sdk
 */

export class BoardNotFoundError extends NotFoundError {
    public constructor(uriId: string_uri_part) {
        super(`Board /${uriId} not found.`);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, BoardNotFoundError.prototype);
    }
}
