import { IDestroyable } from 'destroyable';
import { clientVersion } from '../../config';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';

/**
 * Core System
 *
 * @collboard-system
 */
export class Core extends AbstractSystem implements IDestroyable {
    protected async init() {
        /* not await */ this.emitLoadedEvent();
    }

    private async emitLoadedEvent() {
        await (
            await this.systems.messagesApiSystem
        ).emitEvent({
            type: 'EVENT',
            systemName: 'core',
            eventName: 'initialized',
            version: clientVersion,
            // TODO: [📐]
        });
    }

    public async about(): Promise<{}> {
        // TODO: [📐]
        // TODO: Something like stats page - use in stats page
        return {};
    }
}
