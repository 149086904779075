/**
 * Copy content to clipboard
 * @deprecated
 *
 * @not-collboard-modules-sdk
 */
export function copyContent(target: HTMLInputElement): void {
    if (target) {
        // Copy text to clipboard, see https://www.w3schools.com/howto/howto_js_copy_clipboard.asp

        //  Select the text field
        target.select();
        target.setSelectionRange(0, 99999); // for mobile

        //  Copy the text inside the text field
        document.execCommand('copy');
    }
}
