import React from 'react';
import { Icon } from '../30-components/menu/Icon';
import { ISystems } from '../50-systems/00-SystemsContainer/ISystems';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeAttributeModule } from '../50-systems/ModuleStore/makers/makeAttributeModule';

internalModules.declareModule(() =>
    makeAttributeModule({
        manifest: {
            // Note: This art module is not auto-activated on initialization because when it appears on the board or in some toolbar, it will be auto-activated afterward.
            name: 'FontSizeAttribute',

            contributors: [Authors.rosecky, Authors.hejny],
        },
        standard: true,
        attribute: {
            type: 'number',
            name: 'fontSize',
            defaultValue: 21,
            // TODO: min, max
        },
        async inputRender(value: number, onChange: (value: number) => void, systems: ISystems) {
            const { appState } = await systems.request('appState');
            const z = appState.transform.value.scale.x;

            return (
                <>
                    {/* TODO: Configurable */}
                    {/* TODO: Some math behing sizes of text */}
                    <Icon icon="font-size-1" active={value === 12 / z} onClick={() => onChange(12 / z)} />
                    <Icon icon="font-size-2" active={value === 21 / z} onClick={() => onChange(21 / z)} />
                    <Icon icon="font-size-3" active={value === 42 / z} onClick={() => onChange(42 / z)} />
                    <Icon
                        icon="font-size-3"
                        /* <- TODO: @roseckyj Icons for 4. and 5. size of the text */ active={value === 70 / z}
                        onClick={() => onChange(70 / z)}
                    />
                </>
            );
        },
    }),
);

/**
 * TODO: On deep zoom better changing of the font size.
 */
