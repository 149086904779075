import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { FocusScope } from './FocusScope';

/**
 *
 * Represents type of the focus which can be taken each by one thing in the Collboard app
 *
 * @deprecated [🍿] Do not use enums but object as const OR 'LITERAL VALUES' instead
 *
 * @collboard-modules-sdk
 */
export enum FocusScopeName {
    Tool = 'TOOLS',
    Navigation = 'NAVIGATION',
    Social = 'SOCIAL',
}

/**
 * FocusSystem can register and manage unique focuses and icons which there are.
 *
 * @collboard-system
 */
export class FocusSystem extends AbstractSystem {
    private focusScopes: Partial<Record<FocusScopeName | string, FocusScope<any>>> = {};

    protected async init() {}

    public getFocus<TIdentification>(focusName: FocusScopeName | string): FocusScope<TIdentification> {
        if (!this.focusScopes[focusName]) {
            this.focusScopes[focusName] = new FocusScope();
        }
        return this.focusScopes[focusName]!;
    }

    public getAllFocusScopes<TIdentification>(): Record<FocusScopeName, FocusScope<TIdentification>> {
        // TODO: Probbably create all [🍿] enum focus scopes as empty before returning
        return this.focusScopes as Record<FocusScopeName, FocusScope<TIdentification>>;
    }

    public async destroy() {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        for (const focusScope of Object.values(this.focusScopes)) {
            await focusScope?.destroy();
        }
    }
}

/**
 *  TODO: Interact with <HtmlContent/> and <TextContent/> isEditableAndFocused
 *        Make Singleton lock on isEditableAndFocused
 */
