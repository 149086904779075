import React from 'react';
import { Vector } from 'xyzt';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../50-systems/ModuleStore/makers/makeModalModule';
import { ShapeArt, ShapeName } from '../../71-arts/50-ShapeArt';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            flags: { isHidden: true }, // TODO: How to deal and show this dev modules
            name: 'PlayingWithVirtualArts' /* <- TODO: New name under @test scope */,
            title: 'Sample of virtual arts',
            description: `Samples how you can use Collboard API to controll virtual arts`,
            author: Authors.collboard,
            requirePermissions: ['view'],
        },
        async createModal(systems) {
            const { virtualArtVersioningSystem } = await systems.request('virtualArtVersioningSystem');
            return (
                <>
                    <button
                        onClick={async () => {
                            virtualArtVersioningSystem
                                .createPrimaryOperation()
                                .newArts(
                                    new ShapeArt(
                                        ShapeName.Circle,
                                        '#ff0000',
                                        5,
                                        new Vector(
                                            /* TODO: In LIB xyzt Vector.random and random creation in some boundingbox/area*/ (Math.random(/* <- TODO: [🐉] Probbably use seed random */) -
                                                0.5) *
                                                500,
                                            (Math.random(/* <- TODO: [🐉] Probbably use seed random */) - 0.5) * 500,
                                        ),
                                        Vector.square(20),
                                    ),
                                )
                                .persist(/* Note: This is a virtual operation but we want still to keep same API. */);
                        }}
                    >
                        Create virtual art
                    </button>
                </>
            );
        },
    }),
);
