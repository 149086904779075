import { extension } from 'mime-types';
import React from 'react';
import { AsyncSelectComponent } from '../../../30-components/utils/select/AsyncSelectComponent';
import { useSystems } from '../../../40-utils/react-hooks/useSystems';
import { IExportScope } from '../interfaces/IExportScope';
import { IPreparedFileExporting } from '../interfaces/IPreparedFileExport';

interface IExporterPickerComponentProps {
    scope: IExportScope;
    value: IPreparedFileExporting | null;
    onChange(preparedFileExporting: IPreparedFileExporting): void;
}

/**
 * Picker for exporter (=module =mime type) to use for exporting
 * Note: Need to be used inside of a module
 *
 * @collboard-modules-sdk
 */
export function ExporterPickerComponent({ scope, value, onChange }: IExporterPickerComponentProps) {
    const { exportSystem } = useSystems('exportSystem');

    return (
        <AsyncSelectComponent
            {...{ value, onChange }}
            options={async () => {
                // [1] await forTime(1000);
                return (
                    await exportSystem.exportFilesPrepare({
                        isHeavyExport: true,
                        scope,
                        mimeType: '*' /* <- TODO: Make here some ignore option */,
                        isNative: true,
                        isMaterialized: true /* <-[👿] */,
                    })
                )
                    .filter(
                        ({ fileSupporter }) =>
                            !['text/plain' /* <- TODO: Does it make sence to download as a plain text */].includes(
                                fileSupporter.mimeType,
                            ),
                    )
                    .map((fileExport) => ({
                        title: ` .${extension(fileExport.fileSupporter.mimeType) || 'unknown'}`,
                        value: fileExport as IPreparedFileExporting,
                        isDisabled: !fileExport.exporting,
                    }));
            }}
        />
    );
}

/**
 * TODO: [1] Listen for new exporters
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
