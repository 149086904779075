import { forDocumentReady } from 'waitasecond';
import { consolex } from '../consolex';

/**
 * Register the specified service workers and unregister all other which are running
 *
 * @param serviceWorkerUrls URLS of the service workers you want to be registered
 */
export async function syncServiceWorkers(...paths: Array<string>): Promise<void> {
    if (!('serviceWorker' in navigator)) {
        throw new Error(`[⚙️] Browser does not support service workers`);
    }

    await forDocumentReady();

    const urls = paths.map((path) => {
        const url = new URL(window.location.href);
        url.pathname = path;
        return url.href;
    });

    const existingRegistrations = await navigator.serviceWorker.getRegistrations();

    for (const existingRegistration of existingRegistrations) {
        // console.log(existingRegistration.active?.scriptURL, urls);

        if (existingRegistration.active?.scriptURL && urls.includes(existingRegistration.active?.scriptURL)) {
            continue;
        }

        const result = await existingRegistration.unregister();

        consolex.info(`[⚙️] Service worker unregistered`, {
            result,
            canceledRegistration: existingRegistration,
        });
    }

    for (const url of urls) {
        const existingRegistration = existingRegistrations.find(
            (registration) => registration.active?.scriptURL === url,
        );
        if (existingRegistration) {
            existingRegistration.update();
            consolex.info(`[⚙️] Service worker updated`, {
                url,
                existingRegistration,
            });
        } else {
            const newRegistration = await navigator.serviceWorker.register(url, { scope: './' });
            consolex.info(`[⚙️] Service worker registered`, {
                url,
                newRegistration,
            });
        }
    }
}

/**
 * TODO: Make console logs around service worker more compact
 */
