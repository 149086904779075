import { IToolbarIcon } from './IToolbarIcon';

const TOOLBARICON_COMPARE_KEYS: Array<keyof IToolbarIcon> = [
    'name',
    'order',
    'section',
    'boardCursor',
    'active',
    'inactive',
    'icon',
    'className',
    // TODO: Probably shortcut
];

export function compareToolbarIcons(icon1: IToolbarIcon, icon2: IToolbarIcon): boolean {
    // TODO: Probbably some general util to construct theese comparers
    for (const key of TOOLBARICON_COMPARE_KEYS) {
        if (icon1[key] !== icon2[key]) {
            return false;
        }
    }
    return true;
}
