import { forAnimationFrame } from 'waitasecond';
import { Vector } from 'xyzt';
import { string_html } from '../typeAliases';

/**
 * Get size of html
 *
 * @collboard-modules-sdk
 */
export async function measureHtmlSize(html: string_html): Promise<Vector> {
    const containerElement = document.createElement('div');
    containerElement.style.display = 'inline-block' /* <- Note: To bound block elements in measured html */;

    containerElement.innerHTML = html;

    document.body.appendChild(containerElement);

    // TODO: Probbably same waiting chain as here [🧙‍♀️] + create some util for making such a chains in LIB waitasecond
    await forAnimationFrame();
    const { width, height } = containerElement.getBoundingClientRect();

    document.body.removeChild(containerElement);

    return new Vector(width, height);
}
