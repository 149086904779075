import { forImage } from '../../00-lib/waitasecond/forImage';
import { blobToDataurl } from '../files/blobToDataurl';
import { string_url_image } from '../typeAliases';
import { measureImageSize } from './measureImageSize';

/**
 * Converts image to canvas
 * Tip: It can be usefull for converting SVG images into bitmap images
 *
 * @collboard-modules-sdk
 */
export async function imageToCanvas(source: File | Blob | string_url_image): Promise<HTMLCanvasElement> {
    const image = document.createElement('img');

    // [♍] const objectUrl = ObjectUrl.fromBlobOrUrl(source);
    // [♍] image.src = objectUrl.src;

    image.src = await blobToDataurl(source);

    await forImage(image);

    const size = await measureImageSize(image.src);

    const canvas = document.createElement('canvas');
    canvas.width = size.x;
    canvas.height = size.y;
    const ctx = canvas.getContext('2d')!;
    ctx.drawImage(image, 0, 0);

    // [♍] await objectUrl.destroy();

    return canvas;
}

/**
 * Note: [♍] Not using ObjectUrl because it causes image tainting
 */
