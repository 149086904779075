import { Registration } from 'destroyable';
import { Promisable } from 'type-fest';
import { Vector } from 'xyzt';
import { windowSize } from '../../../40-utils/getWindowSize';
import { toArray } from '../../../40-utils/toArray';
import { Arrayable } from '../../../40-utils/typeHelpers';
import { Abstract2dArt } from '../../../71-arts/26-Abstract2dArt';
import { createSelectionToolBehavior } from '../../../72-tools/behaviors/selectionTool/0-createSelectionToolBehavior';
import { consolex } from '../../../consolex';
import { ISystems } from '../../00-SystemsContainer/ISystems';
import { IOngoingMaterialOperation } from '../../ArtVersionSystem/IOperation';
import { Operation } from '../../ArtVersionSystem/Operation';
import { IModuleDefinition } from '../interfaces/IModule';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';

/**
 * Internal module maker used by makeTrayModule (which gets exported and should be used)
 *
 * @not-collboard-modules-sdk
 */
export function makeTrayToolModule<TArt extends Abstract2dArt>(protoModule: {
    manifest?: IModuleManifest;
    getSelectedItemId(): string | null;
    isMouseOverToolbar(): boolean;
    newArtMaker(options: {
        itemId: string;
        boardPosition: Vector;
        systems: ISystems /* TODO: DRY [🧧] */;
    }): Promisable<Arrayable<TArt> | IOngoingMaterialOperation>;
    getToolbarBodyRef(): HTMLDivElement | null;
}): IModuleDefinition {
    const {
        manifest,
        getSelectedItemId,
        isMouseOverToolbar,
        newArtMaker,
        getToolbarBodyRef: toolbarBodyRef,
    } = protoModule;

    return {
        manifest,
        async setup(systems) {
            const { touchController, collSpace, materialArtVersioningSystem } = await systems.request(
                'touchController',
                'collSpace',
                'materialArtVersioningSystem',
            );

            const selectionToolBehavior = await createSelectionToolBehavior();

            return Registration.fromSubscription((registerAdditionalSubscription) =>
                touchController.touches.subscribe(async (touch) => {
                    const selectedItemId = getSelectedItemId();
                    const boardPosition = collSpace.pickPoint(touch.firstFrame.position).point;

                    if (isMouseOverToolbar()) {
                        let operation: IOngoingMaterialOperation | null = null;
                        let frames = 0;
                        let isScrolling = false;
                        let isDraggedOut = false;
                        let originalScroll = 0;

                        if (selectedItemId) {
                            const artsOrOperation = await newArtMaker({
                                itemId: selectedItemId,
                                boardPosition: boardPosition.subtract(
                                    new Vector(20, 20) /* <- TODO: To config, unhardcode */,
                                ),
                                systems,
                            });

                            if (
                                artsOrOperation instanceof
                                Operation /* <- TODO: Allow this can be object implementing IOngoingMaterialOperation but not created from Operation*/
                            ) {
                                operation = artsOrOperation;
                            } else {
                                operation = materialArtVersioningSystem
                                    .createOperation('TrayModule')
                                    .newArts(...toArray(artsOrOperation as Arrayable<TArt>));
                            }
                        }

                        registerAdditionalSubscription(
                            touch.frames.subscribe({
                                async next(touchFrame) {
                                    frames++;
                                    const boardPositionCurrent = (await collSpace.pickPoint(touchFrame.position)).point;

                                    if (frames === 20) {
                                        const diff = boardPositionCurrent.subtract(boardPosition);
                                        if (Math.abs(diff.y) < Math.abs(diff.x) && touch.type === 'TOUCH') {
                                            if (operation) {
                                                operation.abort();
                                            }
                                            isScrolling = true;
                                            const ref = toolbarBodyRef();
                                            if (ref) {
                                                originalScroll = ref.scrollLeft;
                                            }
                                        } else if (operation) {
                                            isScrolling = false;
                                            isDraggedOut = true;
                                        }
                                    }
                                    if (frames > 20 /* <- TODO: To config, unhardcode */) {
                                        if (isScrolling) {
                                            const ref = toolbarBodyRef();
                                            const diff = boardPositionCurrent.subtract(boardPosition);
                                            if (ref) {
                                                ref.scrollTo({ top: 0, left: originalScroll - diff.x });
                                            }
                                        } else if (operation) {
                                            operation.updateWithMutatingCallback((art) => {
                                                art.shift = boardPositionCurrent.subtract(
                                                    Vector.square(20 /* <- TODO: To config, unhardcode */),
                                                );
                                            });
                                        }
                                    }
                                },
                                async complete() {
                                    consolex.info('Tray drag complete', {
                                        isScrolling,
                                        operation,
                                        isDraggedOut,
                                        isMouseOverToolbar: isMouseOverToolbar(),
                                    });

                                    if (!isScrolling && operation && isDraggedOut) {
                                        if (
                                            isMouseOverToolbar(/* TODO: isMouseOverToolbar is not working in this situation ? */)
                                        ) {
                                            // Note: Item was dragged from tray and then dragged back
                                            operation.abort();
                                        } else {
                                            operation.persist();
                                        }
                                    } else if (frames < 20 /* <- TODO: To config, unhardcode */ && selectedItemId) {
                                        // Note: Tap for add

                                        const center = (
                                            await collSpace
                                                // TODO: [🌷] Some helper for picking middle point in collSpace
                                                .pickPoint(windowSize.value.half())
                                        ).point;

                                        if (operation) {
                                            operation
                                                .updateWithMutatingCallback((art) => {
                                                    art.shift = center.subtract(
                                                        Vector.square(20 /* <- TODO: To config, unhardcode */),
                                                    );
                                                })
                                                .persist();
                                        }
                                    }
                                },
                            }),
                        );
                    } else {
                        await selectionToolBehavior({ registerAdditionalSubscription, touch, systems });
                    }
                }),
            );
        },
    };
}

/**
 * TODO: Add with click on tray
 */
