import React from 'react';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IVectorData, Transform, Vector } from 'xyzt';
import { windowSize } from '../../40-utils/getWindowSize';
import { isEqualArray } from '../../40-utils/isEqualArray';
import { pointsToSquare, square } from '../../40-utils/pointsToSquare';
import { string_attribute, uuid } from '../../40-utils/typeAliases';
import { AbstractPlacedArt } from '../../71-arts/25-AbstractPlacedArt';
import { DEFAULT_BOARD_NAME } from '../../config-universal';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';

/**
 * AppState is not quite a system but an object representing the state of the Collboard app.
 *
 * @deprecated This system will be split into two CollSpace and SelectionSystem and removed
 * @collboard-system
 */
export class AppState extends AbstractSystem {
    protected async init() {
        // this.testSelected();
        // this.testSelection();
    }

    /**
     * Just mirroring the Cornerstone commit with boardname
     *
     * @deprecated Put to separate system instead
     */
    public readonly boardname = new BehaviorSubject<string>(DEFAULT_BOARD_NAME);

    /**
     * This represents user-view on the current board, Every user can have different.
     * For example every user can have different position on the board.
     *
     * @deprecated Put to CollSpace system instead
     */
    public readonly transform = new BehaviorSubject<Transform>(
        Transform.fromObject({
            // Note: now we are still in transform testing phase so keeping this. After we will use Transform.neutral();
            // translate: { x: -300, y: -200 },
            // scale: 1.3,
            // rotate: Math.PI / 18,
        }),
    );

    /**
     * Selected arts
     */
    public readonly selected = new BehaviorSubject<Array<AbstractPlacedArt>>([]);

    /**
     * Selection is just a temporary box created from mousedown until mouseup
     * After the releasing of the mouse selection became selected
     */
    public readonly selection = new BehaviorSubject<null | {
        point1: IVectorData;
        point2: IVectorData;
    }>(null);

    public setSelection({
        selected,
        selection,
    }: {
        selected?: Array<AbstractPlacedArt>;
        selection?: null | { point1: IVectorData; point2: IVectorData };
    }) {
        if (selected !== undefined && !isEqualArray(selected, this.selected.value)) {
            this.selected.next(selected);
        }

        if (selection !== undefined /* TODO: Also use isEqual as upper */) {
            this.selection.next(selection);
        }
    }

    /**
     * Set selection to nothing selected
     *
     * @idempotent If you call this function twice, nothing will happen
     */
    public cancelSelection(): void {
        if (this.selected.value.length !== 0) {
            this.selected.next([]);
        }

        if (this.selection.value !== null) {
            this.selection.next(null);
        }
    }

    public getSelection(): null | square {
        if (this.selection.value === null) {
            return null;
        }

        return pointsToSquare(this.selection.value.point1, this.selection.value.point2);
    }

    /**
     * This bounding box is relative to screen
     */
    public getSelectedBoundingBox() {
        if (!this.selected.value) {
            return undefined;
        }
        const selectedTransformed = this.selected.value.map(({ topLeft, bottomRight }) => {
            const t = this.transform.value.pick('rotate', 'scale'); //.apply(Transform.translate(this.windowSize.half())); //.pick('rotate', 'scale');
            topLeft = Vector.fromObject(topLeft)
                .apply(t)
                .add(windowSize.value.half())
                .add(this.transform.value.translate); //this.systems.(collSpace.pickPoint(topLeft)).point;
            bottomRight = Vector.fromObject(bottomRight)
                .apply(t)
                .add(windowSize.value.half())
                .add(this.transform.value.translate); //this.systems.(collSpace.pickPoint(bottomRight)).point;

            return { topLeft, bottomRight };
        });

        return {
            topLeft: new Vector(
                Math.min.apply(
                    null,
                    selectedTransformed.map((point) => point.topLeft.x || 0),
                ),
                Math.min.apply(
                    null,
                    selectedTransformed.map((point) => point.topLeft.y || 0),
                ),
            ),
            bottomRight: new Vector(
                Math.max.apply(
                    null,
                    selectedTransformed.map((point) => point.bottomRight.x || 0),
                ),
                Math.max.apply(
                    null,
                    selectedTransformed.map((point) => point.bottomRight.y || 0),
                ),
            ),
        };
    }

    /**
     * Checks if there is any selected art which has given attribute
     */
    public hasAnyOfSelectedArtsGivenAttribute(attributeName: string_attribute) {
        return !!this.selected.value.reduce(
            (value, current) => (value === (current as any)[attributeName] ? value : null),
            (this.selected.value[0]! as any)[attributeName],
        );
    }

    /**
     * Get common attributes of all selected arts
     *
     * @example If there is no selected art, returns []
     * @example If there is one selected art, returns its attributes
     * @example If there are two selected arts
     *          - Art A with attributes `color` and `size`
     *          - Art B with attributes `foo` and `color`
     *          It returns `color`
     */
    public getCommonAttributesOfSelectedArts(): Array<string_attribute> {
        return this.selected.value.length > 0
            ? this.selected.value.reduce(
                  (attributes, art) => attributes.filter((o) => art.acceptedAttributes.includes(o)),
                  this.selected.value[0]!.acceptedAttributes,
              )
            : [];
    }

    /**
     *
     *
     * [🌌]
     */
    public isArtSelected({ artId, isExclusive }: IIsArtSelectedOptions): boolean {
        const isSelected = this.selected.value.some((selectedArt) => selectedArt.artId === artId);

        if (!isSelected) {
            return false;
        }

        if (!isExclusive) {
            return true;
        }

        return this.selected.value.length === 1;
    }

    /**
     *
     *
     * [🌌]
     */
    public useArtSelected({ artId, isExclusive }: IIsArtSelectedOptions): boolean {
        // Note: Hooks as methods are definitely fine
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [isSelected, setSelected] = React.useState/* <- TODO: Import and use just a useState */ <boolean>(
            this.isArtSelected({ artId, isExclusive }),
        );

        // Note: Hooks as methods are definitely fine
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(
            /* <- TODO: Import and use just a useEffect */ () => {
                // TODO: Analyze performance after fixing [🍽️]> debugger;
                const subscription = this.selected
                    .pipe(map(() => this.isArtSelected({ artId, isExclusive })))
                    // TODO: Optimize with> .pipe(distinct())
                    /*
                    TODO: Analyze performance after fixing [🍽️]
                    .pipe(
                        map((isSelected) => {
                            debugger;
                            return isSelected;
                        }),
                    )
                    */
                    .subscribe((isSelected2) => setSelected(isSelected2));

                return () => {
                    //TODO: Analyze performance after fixing [🍽️]> debugger;
                    subscription.unsubscribe();
                };
            },
            [artId, isExclusive],
        );

        return isSelected;
    }

    /**
     * Testing code to showcase when selected is changed
     */
    private testSelected() {
        this.selected.subscribe((value) => {
            console.log('✴️ Selected changed', value);
        });
    }

    /**
     * Testing code to showcase when selection is changed
     */
    private testSelection() {
        this.selection.subscribe((value) => {
            console.log('✴️ Selection changed', value);
        });
    }
}

interface IIsArtSelectedOptions {
    /**
     * Unique ID of the art
     */
    artId: uuid;

    /**
     * If true, it will return true only if the art is selected and no other
     */
    isExclusive: boolean;
}

/**
 * TODO: screenBorder should be maybe RxJs observable
 * TODO: [🍵] Make SelectionSystem a system and much better with ONLY one BehaviorSubject with data and other stuff just Observables derived from it.
 * TODO: Selected: When loosing focus in the app (for example clicking on board name or opening a modal), selection should be canceled
 * TODO: Selection: Some better name like selectBox AND rname it globally not only here
 * TODO: Selection: Should return LIB xyzt boundingBox
 * TODO: [💉] Selected virtual arts
 * TODO: [🪑] Compute selection from selected should be probbably done ONLY in AppState (or in future SelectionSystem)
 */
