import { Registration } from 'destroyable';
import { isEventOnBoard } from '../../../50-systems/ControlSystem/utils/isEventOnBoard';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';

internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/clipboard-paste-shortcut',
        deprecatedNames: ['@collboard/clipboard-paste-shortcut', 'ClipboardPasteShortcut'],
        requirePermissions: ['edit'],
    },
    async setup(systems) {
        const { importSystem } = await systems.request('importSystem');
        return Registration.createEventListener({
            element: window.document,
            type: 'paste',
            listener: (event: ClipboardEvent) => {
                if (!isEventOnBoard(event)) {
                    return;
                }

                event.preventDefault();

                /* TODO: Getting all mime type versions from clipboard is maybe a bit inefficient, in futute maybe have some pickBestType in ImportSystem */
                const textAndHtmlFiles = (event.clipboardData?.types || [])
                    .filter((type) => type !== 'Files')
                    .map((type) => new Blob([event.clipboardData?.getData(type) || ''], { type }));
                const imageAndOtherFiles = Array.from(event.clipboardData?.files || []);

                const files = [...textAndHtmlFiles, ...imageAndOtherFiles];

                /* not await */ importSystem.importOneOfFiles({
                    files,
                });
            },
        });

        /*
        TODO: [🐦] We have compatibility trouble with ClipboardItem so we have used ClipboardEvent
              Look at this in future and decide if we dont want go back to ClipboardItem
              @see https://www.pavolhejny.com/clipboardevent-vs-clipboarditem/

        return controlSystem.registerControl(['Control', 'v'], () => paste(importSystem, appState));
        */
    },
}));

/*
TODO: [🐦] We have compatibility trouble with ClipboardItem so we have used ClipboardEvent
      Look at this in future and decide if we dont want go back to ClipboardItem
      @see https://www.pavolhejny.com/clipboardevent-vs-clipboarditem/



TO DO: There is no reason for paste to be a separated from module declaration, maybe join back
export async function paste(importSystem: ImportSystem, appState: AppState): Promise<void> {
    const itemsFromClipboard = await (navigator.clipboard as any).read(/* TODO: Do we need fallback to readText * /);

    for (const itemFromClipboard of itemsFromClipboard) {
        importSystem.importFiles({
             file: await (
                /* TODO: Getting all mime type versions from clipboard is maybe a bit inefficient, in futute maybe have some pickBestType in ImportSystem * /
                itemFromClipboard.types.mapAsync((type: any) => itemFromClipboard.getType(type)),
            )
        });
    }
}
*/

// TODO: @roseckyj Co myslíš, když něco vložím, měl by se mi označit selection tool, abych s vloženým artem mohl hned nakládat?
