import { Destroyable, IDestroyable } from 'destroyable';
import { Promisable } from 'type-fest';
import { forImmediate } from 'waitasecond';
import { ITaskRunner } from './ITaskRunner';

// TODO: Deadlock detection
// TODO: Probably options with param parallelRunningTasks: number

/**
 * Queue manages tasks running in queue and ensures that every task run (function run) run one after another and not mixed up
 *
 * Imagine timeline of task runs:
 *
 * Input:
 * __🥝🍓🍋_____🍏🍍🍇__🍉_🍌🍒___🥑🍎🍈
 *
 * Output:
 * ___🥝_🍓_🍋______🍏_🍍_🍇__🍉_____🍌_🍒___🥑_🍎_🍈
 *
 * TODO: Error handling
 * TODO: Probably debouncing
 * TODO: timeouts
 *
 * @collboard-modules-sdk
 */
export class Queue<TTaskResult> extends Destroyable implements ITaskRunner<TTaskResult>, IDestroyable {
    private taskToWait: Promise<void> = Promise.resolve();

    public async task(runner: () => Promisable<TTaskResult>): Promise<TTaskResult> {
        const previousTask = this.taskToWait;
        let releaseLock: () => void;
        this.taskToWait = new Promise((resolve) => {
            releaseLock = resolve;
        });
        await previousTask;

        try {
            return await runner();
        } catch (error) {
            throw error;
        } finally {
            await forImmediate(/* to release just after returning */);
            releaseLock!();
        }
    }
}

/**
 * TODO: Implement destroy
 */
