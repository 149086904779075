import React from 'react';
import { Promisable } from 'type-fest';
import { ITransformData } from 'xyzt';
import { Abstract2dBoxArt } from '../../../../71-arts/27-Abstract2dBoxArt';
import { IAppearance } from '../../../CollSpace/appearance/IAppearance';
import { IArtData } from '../../../CollSpace/IArtData';
import { IShape } from '../../../CollSpace/IShape';
import { IModuleManifest } from '../../interfaces/IModuleManifest/IModuleManifest';
import { IArtModuleDefinition, makeFunctionalArtModule } from './30-makeFunctionalArtModule';

interface ICanvasArtOptions {
    /**
     * TODO: !!x [5]
     * @@x
     */
    manifest: IModuleManifest;

    /**
     * @@x
     */
    draw(ctx: CanvasRenderingContext2D /* TODO: !!x Pass appereance,... maybe via options */): Promisable<void>;

    // [6]
}

/**
 * @@x
 *
 * @param options @@x
 * @returns @@x
 *
 * @collboard-modules-sdk
 */
export function makeCanvasArtModule<TShape extends IShape = IShape, TAppearance extends IAppearance = IAppearance>(
    options: ICanvasArtOptions,
): IArtModuleDefinition<TShape, TAppearance> {
    const { manifest, /* TODO: !!x icon,*/ draw } = options;

    // Note: Why using class @@x and what TODO in future
    class Art extends Abstract2dBoxArt /* <- TODO: Use Abstract2dArt with <Measure/> */ {
        public constructor(public shape: TShape, public appearance: TAppearance, transform: ITransformData) {
            super();
            this.transform = transform;
        }

        public renderBox() {
            return (
                <canvas
                    style={{ borderColor: '1px dotted red' /* <- TODO: !!x */ }}
                    width={500}
                    height={500}
                    ref={(canvasElement) => {
                        if (!canvasElement) {
                            // TODO: Handle this in some user-friendly way
                            return;
                        }

                        const ctx = canvasElement.getContext('2d');

                        if (!ctx) {
                            // TODO: Handle this in some user-friendly way
                            return;
                        }

                        // TODO: !!x Apply appearance

                        draw(ctx);
                    }}
                />
            );
        }
    }

    return makeFunctionalArtModule({
        manifest,

        /**
         * @@x
         */
        createArt(artData: IArtData<TShape, TAppearance>) {
            const { transform, shape, appearance } = artData;
            // TODO: !!x! What about artId
            return new Art(shape, appearance, transform);
        },
    });
}

/**
 * TODO: !!x makeReactArt, makeBabylonArt, makeDomArt, makeJqueryArt
 * TODO: !!x [6] Unhardcode size and origin - but in future infinite canvas
 *       TODO: !!x Use internally global translate, rotate on canvas ctx?
 * TODO: !!x Some makers does not return ModuleDefinition but Art or multiple modules, should they be called makers
 * TODO: !!x makeCanvasArtModule vs makeCanvasModule
 * TODO: !!x Use or not to use simple prefix in maker
 * TODO: !!x Optimize re-draw
 * TODO: Do not internally use class
 * TODO: [🧠] !!x return type Promisable<void>
 *       - What about animated arts
 *       - What about heavy arts
 * TODO: In renderBox maybe make propper component like <FrameArtComponent title={this.title} size={new Vector(this.size)} /> instead of returning JSX
 */
