import React from 'react';
import spaceTrim from 'spacetrim';
import { take } from '../../00-lib/take/take';
import { EditableHtmlContent } from '../../30-components/utils/EditableHtmlContent';
import { useSkin } from '../../40-utils/react-hooks/useSkin';
import { useSystems } from '../../40-utils/react-hooks/useSystems';
import { getFullAdvancedAppearance } from '../../50-systems/CollSpace/appearance/getFullAdvancedAppearance';
import { getFullPhongMaterial } from '../../50-systems/CollSpace/appearance/getFullPhongMaterial';
import { IAppearance } from '../../50-systems/CollSpace/appearance/IAppearance';
import { textureToColorSync } from '../../50-systems/CollSpace/appearance/textureToColorSync';
import { AbstractArt } from '../20-AbstractArt';
import { IListStyle } from './interfaces/IListStyle';
import { TextArt } from './TextArt';
import { abortTextOperation, getTextOperation } from './textOperations';

interface IArtComponentProps<TArt extends AbstractArt, TShape extends object> {
    art: TArt;
    appearance: IAppearance;
    shape: TShape;
}

interface ITextArtShape {
    content: string;
    fontSize: number;
    /* TODO: [✨] Maybe add is prefix */
    bold: boolean;
    /* TODO: [✨] Maybe add is prefix */
    italic: boolean;
    /* TODO: [✨] Maybe add is prefix */
    underline: boolean;
    listStyle: IListStyle;
}

/**
 * This is internal (how it looks like without transform) component that is used to render TextArt
 */
export function TextArtComponent({
    art,
    appearance,
    shape: { content, fontSize, bold, italic, underline, listStyle },
}: IArtComponentProps<TextArt, ITextArtShape>) {
    /* [🌌]  */
    const { appState, materialArtVersioningSystem } = useSystems('appState', 'materialArtVersioningSystem');

    // TODO: Analyze performance after fixing [🍽️]> debugger;

    const isSelected = appState.useArtSelected({ artId: art.artId, isExclusive: true });

    if (!isSelected && spaceTrim(content) === '') {
        const operation = getTextOperation(materialArtVersioningSystem, art, false);

        if (operation) {
            abortTextOperation(operation);
        }
    }

    const {
        skin: {
            colors: { dark },
        },
    } = useSkin();

    const color = take(appearance)
        .then(getFullAdvancedAppearance)
        .then(({ fill }) => fill)
        .then(getFullPhongMaterial)
        .then(({ emissiveTexture }) => emissiveTexture)
        .then(textureToColorSync);

    return (
        <EditableHtmlContent
            html={content}
            isEditableAndFocused={isSelected}
            style={{
                fontSize,
                color: (
                    color || dark
                ).toHex(/* Note: [🏌️‍♂️] Not using .toString because of take chain method name collision */),
                fontFamily: `'comenia-sans-web', sans-serif` /* <- TODO: Allow to pick font as attribute */,
                fontWeight: bold ? 'bold' : undefined,
                fontStyle: italic ? 'italic' : undefined,
                textDecoration: underline ? 'underline' : undefined,

                outline: 'none',
                /*/
                outlineOffset: 10,
                outline: `1px dotted gray`,
                /**/
            }}
            onHtmlChange={(newContent) => {
                if (!isSelected) {
                    // Note: This should never happen, this check is here just for case
                    throw new Error(`TextArtComponent received input event but art is not selected`);
                }
                console.log(newContent);

                getTextOperation(materialArtVersioningSystem, art, true)!
                    .updateWithMutatingCallback((item) => {
                        (item as TextArt).content = newContent;
                    })
                    .persist();

                // TODO: [🍣] Where to do the persisting ???
            }}
        />
    );
}

/**
 * TODO: [🍣]  Sanitize dangerous things - Allow only text with <p>
 * TODO: [🍣]  This was changed from EditableTextContent to EditableHtmlContent - ensure backward compatibility (Maybe add migrations)
 * TODO: Make Full HTML Equivalent
 * TODO: Allow to toggle <b>, <i>, <ul>,... inside HTML
 * TODO: Make option to make <ul>, <ol>, todo lists, emoji bulletes,...
 * TODO: Multiple people editing one text
 *       Now when there are two people editing same text and both have focus
 *       When Alice changes something Bob is still focused but gets his cursor at the end
 *       It works same when there is not only car|et but multiple sel███ed chars
 *       Ideally it should work simmilar to Google drive
 */
