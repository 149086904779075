import { BehaviorSubject } from 'rxjs';

/**
 * Utility which can send next value to BehaviorSubject based on mutation function.
 *
 * @collboard-modules-sdk
 */
export function nextWithMutation<TItem>(subject: BehaviorSubject<TItem>, mutate: (value: TItem) => void) {
    const value = subject.value;
    mutate(value);
    subject.next(value);
}
