import React from 'react';
import { internalModules } from '../../ModuleStore/internalModules';
import { makeModalModule } from '../../ModuleStore/makers/makeModalModule';
import { AddModalComponent } from '../components/AddModalComponent';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            name: '@collboard/internal/add',
            deprecatedNames: ['@collboard/add', 'Import'],
            title: { en: 'Add content', cs: 'Přidat obsah' },
        },
        async createModal(systems) {
            const { importSystem, routingSystem } = await systems.request('importSystem', 'routingSystem');
            return <AddModalComponent {...{ importSystem, routingSystem }} />;
        },
    }),
);
