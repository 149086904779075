import { Destroyable, IDestroyable, registerItemsInSubjectOfArrays, Registration } from 'destroyable';
import { BehaviorSubject } from 'rxjs';
import { forImmediate } from 'waitasecond';
import { NotFoundError } from '../../40-utils/errors/NotFoundError';
import { randomJavascriptName } from '../../40-utils/randomJavascriptName';
import { ThrottleQueue } from '../../40-utils/tasks/ThrottleQueue';
import { ControlSystem } from '../ControlSystem/ControlSystem';
import { FocusScopeName, FocusSystem } from '../FocusSystem/0-FocusSystem';
import { ToolbarName } from './0-ToolbarSystem';
import { compareToolbarIcons } from './compareToolbarIcons';
import { IToolbarIcon } from './IToolbarIcon';
export class IconsToolbar extends Destroyable implements IDestroyable {
    public readonly icons = new BehaviorSubject<Array<IToolbarIcon & { isActive: boolean }>>([]);

    /**
     *
     * @param toolbarName does not effect IconsToolbar behaviour, it solves only for identification purposes.
     */
    public constructor(
        readonly toolbarName: ToolbarName,
        private controlSystem: ControlSystem,
        private focusSystem: FocusSystem,
    ) {
        super();
    }

    private selectThrottleQueue = new ThrottleQueue({ throttler: forImmediate });

    private iconOrNameToIcon(iconOrName: IToolbarIcon | string): IToolbarIcon {
        let resultIcon: IToolbarIcon;

        if (typeof iconOrName === 'string') {
            const foundIcon = this.icons.value.find(({ name }) => name === iconOrName);
            if (!foundIcon) {
                throw new NotFoundError(
                    `Can not select icon. Icon with name "${iconOrName}" is not registered.\n${
                        this.icons.value.length === 0
                            ? `No icons are available. It seems you are trying to do stuff with icon which is not registered`
                            : `These icons are available ${this.icons.value.map(({ icon }) => `"${icon}"`).join(', ')}`
                    }.`,
                );
            }
            resultIcon = foundIcon;
        } else {
            resultIcon = iconOrName;
        }
        return resultIcon;
    }

    /**
     * Trigger clicking on icon.
     *
     * @param iconOrName You can use here either full IToolbarIcon (same reference that is registered) or name (especially usefull when triggering externally)
     *
     */
    public async clickOnIcon(iconOrName: IToolbarIcon | string) {
        await this.selectThrottleQueue.task(async () => {
            const icon = this.iconOrNameToIcon(iconOrName);

            if (icon.onClick) {
                await icon.onClick();
            }
            if (icon.onSelect) {
                const focusScope = this.focusSystem.getFocus<IToolbarIcon>(
                    (({ focusScopeName, name, icon: iconGraphic }) => {
                        if (focusScopeName === undefined) {
                            return FocusScopeName.Tool;
                        } else if (focusScopeName === null) {
                            // TODO: DRY icon unique identifier
                            if (name) {
                                return name;
                            } else if (iconGraphic) {
                                return iconGraphic.toString();
                            } else {
                                return randomJavascriptName({ prefix: 'focusScope' });
                            }
                        } else {
                            return focusScopeName;
                        }
                    })(icon),
                );

                if (!this.isIconActive(icon)) {
                    await focusScope.takeFocus({
                        subject: icon,
                        create: () =>
                            Registration.join(
                                icon.onSelect!(),
                                Registration.create(() => {
                                    this.icons.next(
                                        this.icons.value.map((icon2) => {
                                            if (compareToolbarIcons(icon, icon2)) {
                                                return { ...icon, isActive: true };
                                            } else {
                                                return icon2;
                                            }
                                        }),
                                    );

                                    return () => {
                                        this.icons.next(
                                            this.icons.value.map((icon2) => {
                                                if (compareToolbarIcons(icon, icon2)) {
                                                    return { ...icon, isActive: false };
                                                } else {
                                                    return icon2;
                                                }
                                            }),
                                        );
                                    };
                                }),
                            ),
                    });
                } else if (icon.togglable) {
                    await focusScope.blurFocus();
                }
            }
        });
    }

    /**
     * @param iconOrName You can use here either full IToolbarIcon (same reference that is registered) or name (especially usefull when triggering externally)
     */
    public isIconActive(iconOrName: IToolbarIcon | string) {
        try {
            const icon = this.iconOrNameToIcon(iconOrName);

            for (const activeIcon of this.icons.value.filter(({ isActive }) => isActive)) {
                if (compareToolbarIcons(icon, activeIcon)) {
                    return true;
                }
            }

            return false;
        } catch (error) {
            if (error instanceof NotFoundError) {
                return false;
            } else {
                throw error;
            }
        }
    }

    public registerIcon(icon: IToolbarIcon): Registration {
        // TODO: Test uniqueness of name

        if ((icon.autoSelect ?? true) && icon.onSelect) {
            /*not await*/ this.clickOnIcon(icon);
        }
        return Registration.join(
            registerItemsInSubjectOfArrays({
                currentValue: this.icons.value /* <- [🌹] Not necessary */,
                base: this.icons,
                add: [{ ...icon, isActive: false }],
                compare: compareToolbarIcons,
            }),
            !icon.shortcut
                ? Registration.void()
                : this.controlSystem.registerControl({
                      defaultShortcut: icon.shortcut,
                      executor: async () => {
                          await this.clickOnIcon(icon);
                      },
                  }),
        );
    }

    public async destroy() {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        await this.selectThrottleQueue.destroy();
    }
}
