import React from 'react';
import { windowSize } from '../40-utils/getWindowSize';
import { useObservable } from '../40-utils/react-hooks/useObservable';
import { useSystemsInCore } from '../40-utils/react-hooks/useSystemsInCore';

export function BoardComponentSelection() {
    const { appState, materialArtVersioningSystem } = useSystemsInCore(
        'appState',

        'materialArtVersioningSystem',
    );

    // Note: [🍵] Observing without getting value is a bit ugly trick but it works because methods and subcomponents bellow are dependent on observed values
    useObservable(windowSize);
    useObservable(appState.transform);
    useObservable(materialArtVersioningSystem.artsObservable);
    useObservable(appState.selection);

    const selection = appState.getSelection();

    return (
        <>
            {selection && (
                <div
                    className="selection"
                    style={{
                        left: /*translate.x +*/ selection.topLeft.x /* <- LIB xyzt .toTopLeft() */,
                        top: /*translate.y +*/ selection.topLeft.y /* <- LIB xyzt .toTopLeft() */,
                        width: selection.bottomRight.x - selection.topLeft.x,
                        height: selection.bottomRight.y - selection.topLeft.y,
                    }}
                ></div>
            )}
        </>
    );
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 * TODO: [🏊‍♂️] Better naming and distinction of Collboard-specific components vs utils components
 */
