import React from 'react';
import { classNames } from '../../40-utils/classNames';
import { string_color } from '../../40-utils/typeAliases';

interface IIconColorProps {
    color: string_color;
    onClick?: () => void;
    className?: string;

    /* TODO: [✨] Add is prefix */ active?: boolean;
    /* TODO: [✨] Add is prefix */ inactive?: boolean;
}
/**
 * Color picker icon component
 *
 * *Note: If not rendered within a menu, there is no guarantee
 * of being rendered correctly*
 *
 * @collboard-modules-sdk
 */

export function IconColor({ className, color, active, inactive, onClick }: IIconColorProps) {
    return (
        <div
            className={classNames('icon', 'icon-color', active && 'active', inactive && 'inactive', className)}
            title={!inactive ? '' : 'Usilovně na tom pracujeme...'}
        >
            <span style={{ backgroundColor: color }}></span>
            <div
                className="click-detector"
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                }}
            ></div>
        </div>
    );
}
