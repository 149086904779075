import { IVectorData, Vector } from 'xyzt';
import { ISerializerRule } from '../interfaces/ISerializerRule';

/**
 * @@x
 */
export const vectorSerializerRule: ISerializerRule = {
    name: 'VECTOR',
    priority: 10 /* <- TODO: [🍉] One place with priorities */,
    serialize({ value, next }) {
        if (typeof value !== 'object') {
            return next();
        }

        if (value === null) {
            return next();
        }

        if (value instanceof Vector) {
            const vectorData = value.toObject();
            if (vectorData.z === 0) {
                // TODO: This logic should be in xyzt
                delete vectorData.z;
            }

            return vectorData;
        }

        const axes = new Set(Object.keys(value));

        if (axes.size === 3 && axes.has('x') && axes.has('y') && axes.has('z')) {
            const { x, y, z } = value;

            if (typeof x === 'number' && typeof y === 'number' && typeof z === 'number') {
                return { x, y, z };
            }
        }

        // TODO: Also test varian with just x and y

        return next();
    },
    deserialize({ serialized, next }) {
        if (serialized === null) {
            return next();
        }

        if (!(typeof serialized === 'object')) {
            return next();
        }

        if (!('x' in serialized && 'y' in serialized)) {
            return next();
        }

        if ('__class' in serialized && serialized.__class !== 'Vector') {
            // Note: This is here because of legacy
            return next();
        }

        const axes = new Set(Object.keys(serialized));
        axes.delete('x');
        axes.delete('y');
        axes.delete('z');
        axes.delete('__class');

        if (axes.size !== 0) {
            // TODO: Maybe there should be some reasoning passed to next()
            //       next(`serialized seems like a Vector but it contains extra keys ${...}`)
            return next();
        }

        return Vector.fromObject(serialized as IVectorData);
    },
};

/**
 * TODO: Color serializer rule
 * TODO: [🌉] In future use satisfies ISerializerRule
 */
