import React from 'react';
import { IModalProps } from '../../../../30-components/modal/Modal';
import { ModalStyle } from '../../../../30-components/modal/ModalStyle';
import { PageOverlay } from '../../../../30-components/modal/PageOverlay';
import { classNames } from '../../../../40-utils/classNames';
import { useSkin } from '../../../../40-utils/react-hooks/useSkin';
import { useSystemsInCore } from '../../../../40-utils/react-hooks/useSystemsInCore';
import { NotificationsComponent } from '../../../../50-systems/NotificationSystem/components/notifications/NotificationsComponent';
import { NotificationPlace } from '../../../../50-systems/NotificationSystem/INotification';
import { UserInterfaceElementPlace } from '../../../../50-systems/UserInterfaceSystem/UserInterfaceElementPlace';
import { WelcomeModalStyle } from './WelcomeModalStyle';

export function WelcomeModal(props: IModalProps) {
    const { title, onClickOverlay, onClickClose, className, wide, fullSize, children } = props;

    const { userInterfaceSystem } = useSystemsInCore('userInterfaceSystem');
    const { skin } = useSkin();

    return (
        <WelcomeModalStyle {...{ skin }}>
            <PageOverlay onPointerDown={() => (onClickOverlay || onClickClose || (() => {}))()}>
                <ModalStyle {...{ skin }}>
                    <div
                        className={classNames(
                            'custom-modal',
                            wide && 'custom-modal-wide',
                            fullSize && 'custom-modal-fullsize',
                            className,
                        )}
                    >
                        <div className="custom-modal-content">
                            <div className="custom-modal-header">
                                <>
                                    {onClickClose && (
                                        <div className="custom-modal-close" onClick={() => onClickClose!()} />
                                    )}
                                </>
                                <span>{title}</span>
                            </div>

                            <div className="custom-modal-adjanced-in">
                                {userInterfaceSystem.render(UserInterfaceElementPlace.WelcomeModalAdjacent)}
                            </div>
                            {children}
                        </div>
                        <div className="custom-modal-adjanced-out">
                            {userInterfaceSystem.render(UserInterfaceElementPlace.WelcomeModalAdjacent)}
                        </div>
                    </div>
                    {userInterfaceSystem.render(UserInterfaceElementPlace.WelcomeModal)}

                    <NotificationsComponent place={NotificationPlace.WelcomeModalAnnouncement} />
                </ModalStyle>
            </PageOverlay>
        </WelcomeModalStyle>
    );
}
