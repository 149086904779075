import React from 'react';
import { forAnimationFrame } from 'waitasecond';
import { IVectorData, Vector } from 'xyzt';
import { SelfUpdateContentComponent } from '../30-components/utils/SelfUpdateContentComponent';
import { classNames } from '../40-utils/classNames';
import { string_attribute } from '../40-utils/typeAliases';
import { ISystemsExtended } from '../50-systems/00-SystemsContainer/ISystems';
import { getBoundingClientRectWithoutTransform } from '../50-systems/CollSpace/utils/getBoundingClientRectWithoutTransform';
import { Abstract2dArt } from './26-Abstract2dArt';

/*
 * Note: This is not in the internal module because some of the Arts are so
 * tightly connected with the core that they need to be there, not in
 * optionally deactivateable module.
 *
 * TODO: Inspire by Abstract3dBoxArt (for example in size fluent API)
 */

/**
 * Abstract2dArt implements some of the functionality of `Abstract2dArt` to
 * make implementation of square objects simpler.
 *
 * @deprecated use hook useMeasure or Measure component instead @see TextArt
 * @collboard-modules-sdk
 */
export abstract class Abstract2dBoxArt extends Abstract2dArt {
    /**
     * @param size null means it will be automatically measured and set
     * @param scale rescale after measuring; it will be used only if size is null
     */
    public constructor(
        private privateSize: /* <- [🍈] name privateSize is confusing, use something instead like size (=privateSize) vs. originalSize */ IVectorData | null = null,
        // tslint:disable-next-line:variable-name
        private __scale: number = 1,
    ) {
        // TODO: When serializing what is serialized privateSize or size (should be size)
        super();
    }

    /**
     * Readonly property containing information about object's original size
     */
    public originalSize = Vector.zero();

    public get size() {
        return this.privateSize || Vector.zero();
    }
    public set size(size: IVectorData) {
        this.privateSize = size;
    }

    public get topLeft() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return this.shift;
    }
    public get bottomRight() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return Vector.add(this.shift, this.size);
    }

    /**
     * Was the art already measured?
     */
    public get measured() {
        // TODO: Probably use here countImageSize
        return this.privateSize !== null && (this.privateSize.x || 0) > 0 && (this.privateSize.y || 0) > 0;
    }

    public get acceptedAttributes(): Array<string_attribute> {
        return ['size' /* TODO: This is a bit weird due to size is not registered attribute */];
    }

    public render(isSelected: boolean, systems: ISystemsExtended) {
        return (
            <SelfUpdateContentComponent
                content={(update) => (
                    <div
                        // TODO: [🍒][0]! This should became <ArtOwnShell
                        className={classNames('art', isSelected && 'selected')}
                        style={{
                            position: 'absolute',
                            left: this.shift.x || 0 /* <- LIB xyzt .toTopLeft() */,
                            top: this.shift.y || 0 /* <- LIB xyzt .toTopLeft() */,
                            width: this.measured ? this.size.x || 0 : undefined,
                            height: this.measured ? this.size.y || 0 : undefined,
                        }}
                        ref={this.privateSize ? undefined : (element) => this.measure(element, update)}
                    >
                        {this.renderBox(systems)}
                    </div>
                )}
            />
        );
    }

    /**
     * Measure the object and set `privateSize` and `originalSize`
     */
    protected async measure(element: HTMLElement | null, update?: () => void) {
        if (!element) {
            return;
        }

        // TODO: Probably use here countImageSize
        // TODO: Probbably same waiting chain as here [🧙‍♀️]
        await forAnimationFrame();
        const boundingBox = getBoundingClientRectWithoutTransform(element);
        this.privateSize = new Vector(boundingBox.width, boundingBox.height).scale(this.__scale);
        this.originalSize = new Vector(boundingBox.width, boundingBox.height);
        if (update) {
            await forAnimationFrame(/* <- TODO: Describe why waiting forAnimationFrame */);
            update();
        }
    }

    /**
     * Function called when object needs to be rendered on screen
     * This is an abstract wrapper over Abstract2dArt's `render`
     *
     * @param systemsContainer contains all systems, which can be used
     * within rendering
     *
     * *Note: This can be called many times a second when user is scrolling
     * or not at all when art is out of screen*
     */
    protected abstract renderBox(systems: ISystemsExtended): JSX.Element;
}

/**
 * TODO: [🍎]  Use IShape instead of weight, points,...
 * TODO: [🎚️] Maybe get rit of AbstractArts and make makers for arts which returns IArts
 */
