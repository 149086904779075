import { isPrivateNetwork } from '../../../40-utils/isPrivateNetwork';
import { string_host } from '../../../40-utils/typeAliases';
import { BusinessName } from '../configuration/BusinessName';
import { UnknownBusinessError } from './UnknownBusinessError';

/**
 * To have nicer formatting
 */
const ______________________________________ = false;

/**
 * This function converts a hostname to a business name + normalizedHost
 *
 * @param host hostname with optional port like "collboard.com" or "localhost:9977"
 * @returns pair of normalizedHost and businessName
 */
export function hostToBusiness(host: string_host): {
    normalizedHost: string_host;
    /* TODO: [✨] Add is prefix - isHttpsEnforced */ enforceHttps: boolean;
    businessName: BusinessName;
} {
    const [hostname] = host.split(':' /* TODO: Not a univeral solution - for example for ipv6 */);

    if (false) {
    }

    //============================= Education on localhost =============================
    else if (
        hostname === 'e.localhost' ||
        hostname === 'edu.localhost' ||
        hostname === 'education.localhost' ||
        ______________________________________
    ) {
        return { normalizedHost: host, enforceHttps: false, businessName: BusinessName.Education };
    }
    //============================= Business on localhost =============================
    else if (
        hostname === 'b.localhost' ||
        hostname === 'business.localhost' ||
        ______________________________________
    ) {
        return { normalizedHost: host, enforceHttps: false, businessName: BusinessName.Business };
    }
    //============================= Development on localhost =============================
    else if (
        /*
        hostname === 'd.localhost' ||
        hostname === 'dev.localhost' ||
        hostname === 'development.localhost' ||
        */
        isPrivateNetwork(hostname)
    ) {
        return { normalizedHost: host, enforceHttps: false, businessName: BusinessName.Development };
    }
    //============================= Development on GitPod =============================
    else if (
        hostname.match(/gitpod\.io$/) ||
        ______________________________________ ||
        ______________________________________
    ) {
        return { normalizedHost: host, enforceHttps: true, businessName: BusinessName.Development };
    }
    //============================= Development on remote =============================
    else if (
        hostname === 'd.collboard.com' ||
        hostname === 'dev.collboard.com' ||
        hostname === 'development.collboard.com' ||
        // TODO: Develop.collboard.com and test.collboard.com and maybe other variations for other businesses
        ______________________________________
    ) {
        return { normalizedHost: `dev.collboard.com`, enforceHttps: true, businessName: BusinessName.Development };
    }
    //============================= Education on remote =============================
    else if (
        hostname === 'collboard.com' ||
        hostname === 'e.collboard.com' ||
        hostname === 'edu.collboard.com' ||
        hostname === 'education.collboard.com' ||
        ______________________________________
    ) {
        return { normalizedHost: `collboard.com`, enforceHttps: true, businessName: BusinessName.Education };
    }
    //============================= Business on remote =============================
    else if (
        hostname === 'b.collboard.com' ||
        hostname === 'business.collboard.com' ||
        ______________________________________
    ) {
        return { normalizedHost: `business.collboard.com`, enforceHttps: true, businessName: BusinessName.Business };
    }
    //============================= Unknown business =============================
    else {
        throw new UnknownBusinessError(`Can not figure out business of host "${host}".`);
    }
}
