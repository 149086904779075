import { Registration } from 'destroyable';
import React from 'react';
import { string_url } from '../../../../40-utils/typeAliases';
import { BusinessName } from '../../../../50-systems/BusinessSystem/configuration/BusinessName';
import { internalModules } from '../../../../50-systems/ModuleStore/internalModules';
import { INotification, INotificationAction } from '../../../../50-systems/NotificationSystem/INotification';
import { Translate } from '../../../../50-systems/TranslationsSystem/components/Translate';
import { fetchGoogleSpreadsheet } from './fetchGoogleSpreadsheet';

internalModules.declareModule(() => ({
    manifest: {
        name: 'RemoteAnnouncements',
        requirePermissions: [],
    },
    async setup(systems) {
        const { businessSystem, notificationSystem, storageSystem } = await systems.request(
            'businessSystem',
            'notificationSystem',
            'storageSystem',
        );

        const storage = storageSystem.getStorage<Date>('RemoteAnnouncements');

        const allAnnouncments: Array<
            INotification & {
                businesses: Array<BusinessName>;
                start: Date;
                end: Date;
                more: string_url;
                repeatInDays: number;
            }
        > = await fetchGoogleSpreadsheet(
            `https://docs.google.com/spreadsheets/d/e/2PACX-1vR4RLUbWjJE6Eev3-hjh01yfwmFzO6jbHd_xmHfG0HB5QKvTOnS5T16l29kIrpDLwfL23hoaq1iqP-8/pub?output=csv`,
        );

        const businessName = await businessSystem.businessName;

        const businessAnnouncments = allAnnouncments.filter(({ businesses }) => businesses.includes(businessName));

        const currentAnnouncments = businessAnnouncments
            .filter(({ businesses }) => businesses.includes(businessName))
            .filter(({ start }) => start.getTime() < new Date().getTime())
            .filter(({ end }) => end.getTime() > new Date().getTime());

        const currentNotDismissedAnnouncments = await currentAnnouncments.filterAsync(async ({ repeatInDays, tag }) => {
            if (repeatInDays === 0) {
                return true;
            }

            const lastSeen = new Date((await storage.getItem(tag)) || '1970-01-01');
            const repeatOn = new Date(lastSeen.getTime() + repeatInDays * 24 * 60 * 60 * 1000);

            // consolex.log({ tag, [tag]: await storage.getItem(tag), lastSeen, repeatOn, current: new Date() });

            return repeatOn.getTime() < new Date().getTime();
        });

        /*
        TODO: [🐋]  Make support for table in ILogger

        consolex.groupCollapsed('📢 Announcements');
        for (const [key, value] of Object.entries({
            allAnnouncments,
            businessAnnouncments,
            currentAnnouncments,
            currentNotDismissedAnnouncments,
        })) {
            consolex.info(`%c${key}:`, `background: #dd9700; color: black; padding: 5px; border-radius: 3px;`);
            consolex.table(value);
        }
        consolex.groupEnd();
        */

        return Registration.join(
            ...currentNotDismissedAnnouncments
                .map((announcment) => ({
                    ...announcment,
                    href: announcment.more,
                    actions: [
                        {
                            type: 'primary',
                            action: 'more',
                            title: <Translate name="Announcements / more">More</Translate>,
                            href: announcment.more,
                        },
                        announcment.repeatInDays === 0
                            ? null
                            : {
                                  action: 'dismiss',
                                  type: 'info' /* Note: not using cancel to not show red button */,
                                  title: <Translate name="Announcements / dismiss">Dismiss</Translate>,
                                  onClick: (event: MouseEvent) => {
                                      event.preventDefault();
                                      notificationSystem.constrict(announcment.tag);

                                      /* not await */ storage.setItem(announcment.tag, new Date());
                                  },
                              },
                    ].filter(Boolean) as Array<INotificationAction>,
                }))
                .map((announcment) => notificationSystem.publish(announcment)),
        );
    },
}));
