import React from 'react';
import styled from 'styled-components';
import { BoundingBox, IVectorData, Vector } from 'xyzt';
import { Color } from '../40-utils/color/Color';
import { useSkin } from '../40-utils/react-hooks/useSkin';
import { IFramable } from '../50-systems/ExportSystem/interfaces/IFramable';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { ISkin } from '../50-systems/StyleSystem/ISkin';
import { Abstract2dBoxArt } from './27-Abstract2dBoxArt';

internalModules.declareModule(() => makeArtModule(FrameArt));

/**
 * Frame for export
 *
 * @collboard-modules-sdk
 */
export class FrameArt extends Abstract2dBoxArt implements IFramable {
    public static serializeName = 'Frame';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/frame-art',
        deprecatedNames: '@collboard/frame-art',
    };

    public get boundingBox(): BoundingBox {
        return BoundingBox.fromTransform({
            // TODO: BoundingBox and transform should be aviable in Abstract2dBoxArt or Abstract2dArt
            translate: this.shift,
            scale: this.size,
        });
    }

    /**
     * @param src Iframe source
     * @param title Iframe title (alt)
     */
    public constructor(size: IVectorData, public title: string) {
        super(size);
    }

    public renderBox() {
        return <FrameArtComponent title={this.title} size={new Vector(this.size)} />;
    }
}

function FrameArtComponent({ title, size }: { title: string; size: Vector }) {
    return (
        <FrameArtDiv {...useSkin()} style={{ width: size.x, height: size.y }}>
            <div className="frame-label">{title}</div>
        </FrameArtDiv>
    );
}

const FrameArtDiv = styled.div<{ skin: ISkin }>`
    border: 2px solid ${({ skin }) => Color.from(skin.colors.dark).withAlpha(0.5).toString()};

    .frame-label {
        /*border: 2px dotted red;*/

        display: inline-block;
        font-size: 15px;
        font-weight: bold;
        padding: 7px;
        color: ${({ skin }) => Color.from(skin.colors.dark).withAlpha(0.5).toString()};
    }
`;

/**
 * TODO: Pick on borders + one corner
 * TODO: [🎚️] Implement IArt
 */
