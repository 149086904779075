interface IFilterWithLimitOptions<TItem> {
    array: Array<TItem>;
    limit: number;
    predicate: (value: TItem, index?: number, array?: Array<TItem>) => boolean;
}

/**
 * Filter array with limit and predicate
 *
 * @deprecated Extend Array prototype instead
 * @collboard-modules-sdk
 */
export function filterWithLimit<TItem>({ array, limit, predicate }: IFilterWithLimitOptions<TItem>): Array<TItem> {
    const filtered: Array<TItem> = [];
    const arrayLength = array.length;

    for (let i = 0; i < arrayLength && filtered.length < limit; i++) {
        if (predicate(array[i], i, array)) {
            filtered.push(array[i]);
        }
    }

    return filtered;
}
