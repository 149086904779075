import React from 'react';
import { forAnimationFrame } from 'waitasecond';
import { Vector } from 'xyzt';
import { getBoundingClientRectWithoutTransform } from '../../50-systems/CollSpace/utils/getBoundingClientRectWithoutTransform';

/**
 * This hook will measure the size of the children.
 * Note: It internally uses useMeasure hook so you can decide if use useMeasure(...) OR <Measure>{...}</Measure>
 *
 * @collboard-modules-sdk
 */
export function useMeasure<TElement extends HTMLElement>(
    onMeasured: (size: Vector) => void,
): React.RefObject/* <- TODO: Import and use just a RefObject */ <TElement> {
    const measureRef = React.useRef/* <- TODO: Import and use just a useRef */ <TElement>(null);

    (async () => {
        // TODO: Probbably same waiting chain as here [🧙‍♀️] + create some util for making such a chains in LIB waitasecond
        await forAnimationFrame(/* to propperly render an object */);

        let element = measureRef.current;

        if (!element) {
            // TODO: Wait until something is mounted
            return;
        }

        if (element.classList.contains('wrapper') /* <- [🌹] */) {
            element = element.children[0]! as TElement;
        }

        const { width, height } = getBoundingClientRectWithoutTransform(element);
        onMeasured(new Vector(width, height));
    })();

    return measureRef;
}

/**
 * TODO: [🌺] Measure more often than once (via infinite loop + useEffect)
 * TODO: [⚜️] Maybe instead of onMeasured callback receive subject as a box to put new values in
 * TODO: [🌹] Maybe instead of "wrapper" class hack use some ref unwrapping/forwarding technique
 * TODO: [🌹] Maybe put "wrapper" implementation into some separate util to be shared with other hooks/element functions
 * TODO: [🌹] Maybe use data-wrapper for wrapper not the css class
 */
