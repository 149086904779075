import { IDestroyable } from 'destroyable';
import { Observable } from 'rxjs';
import { alertDialogue } from '../../../40-utils/dialogues/alertDialogue';
import { ModuleDeclarationError, ModuleDeclarationMissingManifestError } from '../../../40-utils/errors/ModuleDeclarationError';
import { factor } from '../../../40-utils/IFactory';
import { string_module_name } from '../../../40-utils/typeAliases';
import { consolex } from '../../../consolex';
import { ISystemsExtended } from '../../00-SystemsContainer/ISystems';
import { ModulesStorage } from '../connectors/ModulesStorage';
import { IModule, IModuleDefinition } from '../interfaces/IModule';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { IModulePersister } from '../interfaces/IModulePersister';
import { IModulesStorageStrong } from '../interfaces/IModulesStorage';
import { ISyncer } from '../interfaces/ISyncer';
import { AbstractSyncer } from './AbstractSyncer';
export interface IStorageSyncerOptions {
    /* TODO: [✨] Add is prefix */
    activateDeclaredModules: boolean;
    // TODO: Probably put default modules here
}

/**
 * StorageSyncer will install every module what is declared into the storage
 * When the module is redeclared, module is installed and uninstalled
 *
 * TODO: Probably only installer because here we do not use any advantages of the syncer
 *
 * @private
 * @collboard-system
 */
export abstract class StorageSyncer
    extends AbstractSyncer
    implements ISyncer, IModulePersister, IModulesStorageStrong, IDestroyable
{
    protected readonly modulesStorage: IModulesStorageStrong = new ModulesStorage();

    public constructor(
        systems: ISystemsExtended,
        private readonly options: IStorageSyncerOptions = { activateDeclaredModules: true },
    ) {
        super(systems);
    }

    /**
     * @proxy
     */
    public getModule(name: string_module_name): IModuleDefinition | null {
        return this.modulesStorage.getModule(name);
    }

    /**
     * @proxy
     */
    public getAllModules(): Array<IModuleDefinition> {
        return this.modulesStorage.getAllModules();
    }

    /**
     * @proxy
     */
    public observeAllModules(): Observable<Array<IModuleDefinition>> {
        return this.modulesStorage.observeAllModules();
    }

    public async declareModule(...modules: Array<IModule>): Promise<void> {
        for (const module of modules) {
            // TODO: [🍖] Call declareOneModule in parallel (and design some integration test to make sure that it works)
            await this.declareOneModule(module);
        }
    }

    private async declareOneModule(module: IModule): Promise<void> {
        try {
            const moduleDefinition = factor(module);

            if (!moduleDefinition.manifest) {
                throw new ModuleDeclarationMissingManifestError();
            }

            await this.uninstall(moduleDefinition.manifest);

            if (this.options.activateDeclaredModules) {
                await this.moduleActivate(moduleDefinition.manifest);
            }

            // Note: declareModule to show in /DevelopmentColldevModal modal
            await this.modulesStorage.declareModule(module);

            // Note: Install the module
            await this.install({ name: moduleDefinition.manifest.name }, this.constructor.name);
        } catch (error) {
            consolex.error('error', { error });

            if (error instanceof Error) {
                if (
                    error.name ===
                    'ModuleInstallationError' /* Note: Not using instanceof because on errors it is not working propperly */
                ) {
                    throw error;
                } else {
                    throw new ModuleDeclarationError(error, `Error occured while declaring module.`);
                }
            } else {
                throw error;
            }
        }
    }

    /*
    TODO:
    // TODO: Code bellow should be DRY with CornerstoneArt
    private modulesActive?: string_module_name[];
    private modulesInactive?: string_module_name[];


    private moduleRemoveFromActivateAndDeactivateList(moduleManifest: IModuleManifest) {
        this.modulesActive = (this.modulesActive || []).filter((moduleName2) => moduleName2 !== moduleName);
        this.modulesInactive = (this.modulesInactive || []).filter((moduleName2) => moduleName2 !== moduleName);
    }
    */

    async moduleActivate(moduleManifest: IModuleManifest) {
        /*
        TODO:
        this.moduleRemoveFromActivateAndDeactivateList(moduleName);
        this.modulesActive?.push(moduleName);
        */
    }

    async moduleDeactivate(moduleManifest: IModuleManifest) {
        await alertDialogue(`You are currently developing this module so you cannot deactivate it.`);
        /*
        TODO:
        this.moduleRemoveFromActivateAndDeactivateList(moduleName);
        this.modulesInactive?.push(moduleName);
        */
    }

    public async destroy(): Promise<void> {
        await Promise.all([super.destroy(), this.modulesStorage.destroy()]);
    }
}
