import { IVectorData, Vector } from 'xyzt';
import { SCALER_OUTLINE_OFFSET } from '../../../30-components/ArtScaler';
import { Additional } from '../../../40-utils/Additional';
import { AbstractArt } from '../../../71-arts/20-AbstractArt';
import { AbstractPlacedArt } from '../../../71-arts/25-AbstractPlacedArt';
import { IBehaviorOptions } from '../0-IBehavior';

/**
 * @deprecated This should be exported from some system or util to be user-settable
 */
const SCALING_DISTANCE = 10;

/**
 * Part of createSelectionToolBehavior
 * @see ./0-createSelectionToolBehavior.ts
 */
export async function selectionToolScalingBehavior(behaviorProps: IBehaviorOptions): Promise<boolean> {
    const { touch, registerAdditionalSubscription, systems } = behaviorProps;

    const { appState, collSpace, materialArtVersioningSystem } = await systems.request(
        'appState',
        'collSpace',
        'materialArtVersioningSystem',
    );

    const screenPosition = touch.firstFrame.position;
    const boardPosition = collSpace.pickPoint(screenPosition).point;
    const selectedBoundingBox = appState.getSelectedBoundingBox();

    const selectedAreLocked =
        appState.selected.value.length > 0 && appState.hasAnyOfSelectedArtsGivenAttribute('locked') === true;

    if (appState.selected.value.length > 0 && selectedBoundingBox && !selectedAreLocked) {
        const scalable = appState.selected.value.filter((art) =>
            art.acceptedAttributes.includes('size' /* TODO: Check if this is correct*/),
        );
        if (scalable.length === appState.selected.value.length) {
            const originalSize = selectedBoundingBox.bottomRight.subtract(selectedBoundingBox.topLeft);

            // Note: This should be only 2D - TODO: Probably some method Vector.2d
            const originalCenterOnBoard = collSpace.pickPoint(
                originalSize.scale(0.5).add(selectedBoundingBox.topLeft),
            ).point;
            const originalSizeByMouse = boardPosition.subtract(originalCenterOnBoard).map(Math.abs);

            // Note: This should be only 2D
            const topRight = new Vector(selectedBoundingBox.bottomRight.x, selectedBoundingBox.topLeft.y);

            // Note: This should be only 2D
            const bottomLeft = new Vector(selectedBoundingBox.topLeft.x, selectedBoundingBox.bottomRight.y);

            // Corners - keep aspect ration
            if (
                selectedBoundingBox.topLeft
                    .add(new Vector(-SCALER_OUTLINE_OFFSET, -SCALER_OUTLINE_OFFSET))
                    .distance(screenPosition) < SCALING_DISTANCE ||
                topRight.add(new Vector(SCALER_OUTLINE_OFFSET, -SCALER_OUTLINE_OFFSET)).distance(screenPosition) <
                    SCALING_DISTANCE ||
                bottomLeft.add(new Vector(-SCALER_OUTLINE_OFFSET, SCALER_OUTLINE_OFFSET)).distance(screenPosition) <
                    SCALING_DISTANCE ||
                selectedBoundingBox.bottomRight
                    .add(new Vector(SCALER_OUTLINE_OFFSET, SCALER_OUTLINE_OFFSET))
                    .distance(screenPosition) < SCALING_DISTANCE
            ) {
                const operation = materialArtVersioningSystem.createPrimaryOperation().takeArts(...scalable);

                const additional = new Additional<
                    AbstractArt,
                    { originalArtSize: IVectorData; originalArtShift: IVectorData }
                >(scalable, (art) => {
                    const originalArtSize = (art as AbstractPlacedArt).size;
                    const originalArtShift = (art as AbstractPlacedArt).shift;
                    return {
                        originalArtSize,
                        originalArtShift,
                    };
                });

                registerAdditionalSubscription(
                    touch.frames.subscribe(
                        async (frame) => {
                            const boardPositionCurrent = collSpace.pickPoint(frame.position).point;

                            // Note: This should be only 2D
                            const newX = Math.abs(
                                (boardPositionCurrent.x - originalCenterOnBoard.x) / originalSizeByMouse.x,
                            );
                            const newY = Math.abs(
                                (boardPositionCurrent.y - originalCenterOnBoard.y) / originalSizeByMouse.y,
                            );

                            const scale = newX < newY ? newX : newY;

                            operation.updateWithMutatingCallback((art) => {
                                const { originalArtSize, originalArtShift } = additional.get(art);

                                (art as AbstractPlacedArt).size = Vector.scale(originalArtSize, scale);
                                (art as AbstractPlacedArt).shift = Vector.subtract(
                                    originalArtShift,
                                    originalCenterOnBoard,
                                )
                                    .scale(scale)
                                    .add(originalCenterOnBoard);
                            });
                        },
                        () => {},
                        () => {
                            operation.persist();
                        },
                    ),
                );
                return true;
            }

            // Sides - ignore aspect ration
            //  vertical scaling
            // TODO: DRY
            if (
                new Vector(
                    (selectedBoundingBox.topLeft.x + selectedBoundingBox.bottomRight.x) / 2,
                    selectedBoundingBox.topLeft.y,
                ).distance(screenPosition) < SCALING_DISTANCE ||
                new Vector(
                    (selectedBoundingBox.topLeft.x + selectedBoundingBox.bottomRight.x) / 2,
                    selectedBoundingBox.bottomRight.y,
                ).distance(screenPosition) < SCALING_DISTANCE
            ) {
                const operation = materialArtVersioningSystem.createPrimaryOperation().takeArts(...scalable);

                const additional = new Additional<
                    AbstractArt,
                    { originalArtSize: IVectorData; originalArtShift: IVectorData }
                >(scalable, (art) => {
                    const originalArtSize = (art as AbstractPlacedArt).size;
                    const originalArtShift = (art as AbstractPlacedArt).shift;
                    return {
                        originalArtSize,
                        originalArtShift,
                    };
                });

                registerAdditionalSubscription(
                    touch.frames.subscribe({
                        async next(frame) {
                            const boardPositionCurrent = collSpace.pickPoint(frame.position).point;

                            const scale = Math.abs(
                                (boardPositionCurrent.y - originalCenterOnBoard.y) / originalSizeByMouse.y,
                            );

                            operation.updateWithMutatingCallback((art) => {
                                const { originalArtSize, originalArtShift } = additional.get(art);

                                (art as AbstractPlacedArt).size = new Vector(
                                    originalArtSize.x,
                                    (originalArtSize.y || 0) * scale,
                                );
                                (art as AbstractPlacedArt).shift = new Vector(
                                    originalArtShift.x,
                                    ((originalArtShift.y || 0) - originalCenterOnBoard.y) * scale +
                                        originalCenterOnBoard.y,
                                );
                            });
                        },
                        complete() {
                            operation.persist();
                        },
                    }),
                );

                return true;
            }
            //  horizontal scaling
            // TODO: DRY
            if (
                // TODO: Probably optimize by using distanceSquared not distance
                new Vector(
                    selectedBoundingBox.topLeft.x,
                    (selectedBoundingBox.topLeft.y + selectedBoundingBox.bottomRight.y) / 2,
                ).distance(screenPosition) < SCALING_DISTANCE ||
                new Vector(
                    selectedBoundingBox.bottomRight.x,
                    (selectedBoundingBox.topLeft.y + selectedBoundingBox.bottomRight.y) / 2,
                ).distance(screenPosition) < SCALING_DISTANCE
            ) {
                const operation = materialArtVersioningSystem.createPrimaryOperation().takeArts(...scalable);

                const additional = new Additional<
                    AbstractArt,
                    { originalArtSize: IVectorData; originalArtShift: IVectorData }
                >(scalable, (art) => {
                    const originalArtSize = (art as AbstractPlacedArt).size;
                    const originalArtShift = (art as AbstractPlacedArt).shift;
                    return {
                        originalArtSize,
                        originalArtShift,
                    };
                });

                registerAdditionalSubscription(
                    touch.frames.subscribe({
                        async next(frame) {
                            const boardPositionCurrent = collSpace.pickPoint(frame.position).point;

                            const scale = Math.abs(
                                (boardPositionCurrent.x - originalCenterOnBoard.x) / originalSizeByMouse.x,
                            );

                            operation.updateWithMutatingCallback((art) => {
                                const { originalArtSize, originalArtShift } = additional.get(art);

                                (art as AbstractPlacedArt).size = new Vector(
                                    (originalArtSize.x || 0) * scale,
                                    originalArtSize.y,
                                );
                                (art as AbstractPlacedArt).shift = new Vector(
                                    ((originalArtShift.x || 0) - originalCenterOnBoard.x) * scale +
                                        originalCenterOnBoard.x,
                                    originalArtShift.y,
                                );
                            });
                        },
                        complete() {
                            operation.persist();
                        },
                    }),
                );

                return true;
            }
        }
    }
    return false;
}

/**
 * TODO: [🎂] Probably remove systems from IBehaviorProps and use useSystems (or similar mechanism) instead
 */
