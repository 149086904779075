/*
 * Generated type guards for "IAppearance.ts".
 * WARNING: Do not manually change this file.
 */
import { IAdvancedAppearance, IAppearance, IFullAdvancedAppearance } from './IAppearance';
import { isIMaterial } from './IMaterial.guard';
import { isIColorTexture, isITexture, isITextureTexture } from './ITexture.guard';

/* tslint:disable */
/* eslint-disable */

export function isIAppearance(obj: unknown): obj is IAppearance {
    const typedObj = obj as any;
    return (
        (isIColorTexture(typedObj) as boolean) ||
        (isITextureTexture(typedObj) as boolean) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isITexture(typedObj['emissiveTexture']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['diffuseTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['diffuseTexture']) as boolean) ||
                (isITextureTexture(typedObj['diffuseTexture']) as boolean)) &&
            (typeof typedObj['specularTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['specularTexture']) as boolean) ||
                (isITextureTexture(typedObj['specularTexture']) as boolean)) &&
            (typeof typedObj['ambientTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['ambientTexture']) as boolean) ||
                (isITextureTexture(typedObj['ambientTexture']) as boolean)) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isITexture(typedObj['diffuseTexture']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['emissiveTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['emissiveTexture']) as boolean) ||
                (isITextureTexture(typedObj['emissiveTexture']) as boolean)) &&
            (typeof typedObj['specularTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['specularTexture']) as boolean) ||
                (isITextureTexture(typedObj['specularTexture']) as boolean)) &&
            (typeof typedObj['ambientTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['ambientTexture']) as boolean) ||
                (isITextureTexture(typedObj['ambientTexture']) as boolean)) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isITexture(typedObj['specularTexture']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['emissiveTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['emissiveTexture']) as boolean) ||
                (isITextureTexture(typedObj['emissiveTexture']) as boolean)) &&
            (typeof typedObj['diffuseTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['diffuseTexture']) as boolean) ||
                (isITextureTexture(typedObj['diffuseTexture']) as boolean)) &&
            (typeof typedObj['ambientTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['ambientTexture']) as boolean) ||
                (isITextureTexture(typedObj['ambientTexture']) as boolean)) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isITexture(typedObj['ambientTexture']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['emissiveTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['emissiveTexture']) as boolean) ||
                (isITextureTexture(typedObj['emissiveTexture']) as boolean)) &&
            (typeof typedObj['diffuseTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['diffuseTexture']) as boolean) ||
                (isITextureTexture(typedObj['diffuseTexture']) as boolean)) &&
            (typeof typedObj['specularTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['specularTexture']) as boolean) ||
                (isITextureTexture(typedObj['specularTexture']) as boolean)) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (isIFullAdvancedAppearance(typedObj) as boolean) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isIMaterial(typedObj['spot']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['edge'] === 'undefined' ||
                (isIColorTexture(typedObj['edge']) as boolean) ||
                (isITextureTexture(typedObj['edge']) as boolean) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['emissiveTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['diffuseTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['specularTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['ambientTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function'))) &&
            (typeof typedObj['fill'] === 'undefined' ||
                (isIColorTexture(typedObj['fill']) as boolean) ||
                (isITextureTexture(typedObj['fill']) as boolean) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['emissiveTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['diffuseTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['specularTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['ambientTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function'))) &&
            (typeof typedObj['default'] === 'undefined' ||
                (isIColorTexture(typedObj['default']) as boolean) ||
                (isITextureTexture(typedObj['default']) as boolean) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['emissiveTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['diffuseTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['specularTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['ambientTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function'))) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isIMaterial(typedObj['edge']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['spot'] === 'undefined' ||
                (isIColorTexture(typedObj['spot']) as boolean) ||
                (isITextureTexture(typedObj['spot']) as boolean) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['emissiveTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['diffuseTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['specularTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['ambientTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function'))) &&
            (typeof typedObj['fill'] === 'undefined' ||
                (isIColorTexture(typedObj['fill']) as boolean) ||
                (isITextureTexture(typedObj['fill']) as boolean) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['emissiveTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['diffuseTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['specularTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['ambientTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function'))) &&
            (typeof typedObj['default'] === 'undefined' ||
                (isIColorTexture(typedObj['default']) as boolean) ||
                (isITextureTexture(typedObj['default']) as boolean) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['emissiveTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['diffuseTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['specularTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['ambientTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function'))) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isIMaterial(typedObj['fill']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['spot'] === 'undefined' ||
                (isIColorTexture(typedObj['spot']) as boolean) ||
                (isITextureTexture(typedObj['spot']) as boolean) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['emissiveTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['diffuseTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['specularTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['ambientTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function'))) &&
            (typeof typedObj['edge'] === 'undefined' ||
                (isIColorTexture(typedObj['edge']) as boolean) ||
                (isITextureTexture(typedObj['edge']) as boolean) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['emissiveTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['diffuseTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['specularTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['ambientTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function'))) &&
            (typeof typedObj['default'] === 'undefined' ||
                (isIColorTexture(typedObj['default']) as boolean) ||
                (isITextureTexture(typedObj['default']) as boolean) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['emissiveTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['diffuseTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['specularTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['ambientTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function'))) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isIMaterial(typedObj['default']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['spot'] === 'undefined' ||
                (isIColorTexture(typedObj['spot']) as boolean) ||
                (isITextureTexture(typedObj['spot']) as boolean) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['emissiveTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['diffuseTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['specularTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['ambientTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function'))) &&
            (typeof typedObj['edge'] === 'undefined' ||
                (isIColorTexture(typedObj['edge']) as boolean) ||
                (isITextureTexture(typedObj['edge']) as boolean) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['emissiveTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['diffuseTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['specularTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['ambientTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function'))) &&
            (typeof typedObj['fill'] === 'undefined' ||
                (isIColorTexture(typedObj['fill']) as boolean) ||
                (isITextureTexture(typedObj['fill']) as boolean) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['emissiveTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['diffuseTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['specularTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['ambientTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function'))) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function'))
    );
}

export function isIAdvancedAppearance(obj: unknown): obj is IAdvancedAppearance {
    const typedObj = obj as any;
    return (
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isIMaterial(typedObj['spot']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['edge'] === 'undefined' ||
                (isIColorTexture(typedObj['edge']) as boolean) ||
                (isITextureTexture(typedObj['edge']) as boolean) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['emissiveTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['diffuseTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['specularTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['ambientTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function'))) &&
            (typeof typedObj['fill'] === 'undefined' ||
                (isIColorTexture(typedObj['fill']) as boolean) ||
                (isITextureTexture(typedObj['fill']) as boolean) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['emissiveTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['diffuseTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['specularTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['ambientTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function'))) &&
            (typeof typedObj['default'] === 'undefined' ||
                (isIColorTexture(typedObj['default']) as boolean) ||
                (isITextureTexture(typedObj['default']) as boolean) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['emissiveTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['diffuseTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['specularTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['ambientTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function'))) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isIMaterial(typedObj['edge']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['spot'] === 'undefined' ||
                (isIColorTexture(typedObj['spot']) as boolean) ||
                (isITextureTexture(typedObj['spot']) as boolean) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['emissiveTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['diffuseTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['specularTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['ambientTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function'))) &&
            (typeof typedObj['fill'] === 'undefined' ||
                (isIColorTexture(typedObj['fill']) as boolean) ||
                (isITextureTexture(typedObj['fill']) as boolean) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['emissiveTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['diffuseTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['specularTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['ambientTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function'))) &&
            (typeof typedObj['default'] === 'undefined' ||
                (isIColorTexture(typedObj['default']) as boolean) ||
                (isITextureTexture(typedObj['default']) as boolean) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['emissiveTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['diffuseTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['specularTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['ambientTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function'))) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isIMaterial(typedObj['fill']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['spot'] === 'undefined' ||
                (isIColorTexture(typedObj['spot']) as boolean) ||
                (isITextureTexture(typedObj['spot']) as boolean) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['emissiveTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['diffuseTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['specularTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['ambientTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function'))) &&
            (typeof typedObj['edge'] === 'undefined' ||
                (isIColorTexture(typedObj['edge']) as boolean) ||
                (isITextureTexture(typedObj['edge']) as boolean) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['emissiveTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['diffuseTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['specularTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['ambientTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function'))) &&
            (typeof typedObj['default'] === 'undefined' ||
                (isIColorTexture(typedObj['default']) as boolean) ||
                (isITextureTexture(typedObj['default']) as boolean) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['emissiveTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['diffuseTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['specularTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['ambientTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function')) ||
                (((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                    typeof typedObj['default'] === 'function') &&
                    (isITexture(typedObj['default']['ambientTexture']) as boolean) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function') &&
                    (typeof typedObj['default']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['default']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['default']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['default']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['default']['specularTexture']) as boolean)) &&
                    ((typedObj['default'] !== null && typeof typedObj['default'] === 'object') ||
                        typeof typedObj['default'] === 'function'))) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isIMaterial(typedObj['default']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['spot'] === 'undefined' ||
                (isIColorTexture(typedObj['spot']) as boolean) ||
                (isITextureTexture(typedObj['spot']) as boolean) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['emissiveTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['diffuseTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['specularTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['ambientTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function')) ||
                (((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                    typeof typedObj['spot'] === 'function') &&
                    (isITexture(typedObj['spot']['ambientTexture']) as boolean) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function') &&
                    (typeof typedObj['spot']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['spot']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['spot']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['spot']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['spot']['specularTexture']) as boolean)) &&
                    ((typedObj['spot'] !== null && typeof typedObj['spot'] === 'object') ||
                        typeof typedObj['spot'] === 'function'))) &&
            (typeof typedObj['edge'] === 'undefined' ||
                (isIColorTexture(typedObj['edge']) as boolean) ||
                (isITextureTexture(typedObj['edge']) as boolean) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['emissiveTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['diffuseTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['specularTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['ambientTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function')) ||
                (((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                    typeof typedObj['edge'] === 'function') &&
                    (isITexture(typedObj['edge']['ambientTexture']) as boolean) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function') &&
                    (typeof typedObj['edge']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['edge']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['edge']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['edge']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['edge']['specularTexture']) as boolean)) &&
                    ((typedObj['edge'] !== null && typeof typedObj['edge'] === 'object') ||
                        typeof typedObj['edge'] === 'function'))) &&
            (typeof typedObj['fill'] === 'undefined' ||
                (isIColorTexture(typedObj['fill']) as boolean) ||
                (isITextureTexture(typedObj['fill']) as boolean) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['emissiveTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['diffuseTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['specularTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['ambientTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['ambientTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['ambientTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function')) ||
                (((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                    typeof typedObj['fill'] === 'function') &&
                    (isITexture(typedObj['fill']['ambientTexture']) as boolean) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function') &&
                    (typeof typedObj['fill']['emissiveTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['emissiveTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['emissiveTexture']) as boolean)) &&
                    (typeof typedObj['fill']['diffuseTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['diffuseTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['diffuseTexture']) as boolean)) &&
                    (typeof typedObj['fill']['specularTexture'] === 'undefined' ||
                        (isIColorTexture(typedObj['fill']['specularTexture']) as boolean) ||
                        (isITextureTexture(typedObj['fill']['specularTexture']) as boolean)) &&
                    ((typedObj['fill'] !== null && typeof typedObj['fill'] === 'object') ||
                        typeof typedObj['fill'] === 'function'))) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function'))
    );
}

export function isIFullAdvancedAppearance(obj: unknown): obj is IFullAdvancedAppearance {
    const typedObj = obj as IFullAdvancedAppearance;
    return (
        ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
        (isIMaterial(typedObj['spot']) as boolean) &&
        (isIMaterial(typedObj['edge']) as boolean) &&
        (isIMaterial(typedObj['fill']) as boolean)
    );
}
