import { forTime } from 'waitasecond';
import { TestError } from '../../40-utils/errors/TestError';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { ITestSystem, ITestSystem_echo_request, ITestSystem_echo_response } from './interfaces/0-ITestSystem';

/**
 * TestSystem just for testing purposes.
 *
 * @collboard-system
 */
export class TestSystem extends AbstractSystem implements ITestSystem {
    protected async init() {}

    public async echo({ message, logger }: ITestSystem_echo_request): Promise<ITestSystem_echo_response> {
        if (typeof message === 'string' && message.startsWith('throw')) {
            const errorMessage = message
                .split(/^throw/)
                .join('')
                .trim();

            logger.info(`You have started you echo with "throw" phrase so Error will be thrown!`);

            throw new TestError(errorMessage);
        } else if (typeof message === 'string' && message.startsWith('log')) {
            const logMessage = message.split(/^log/).join('').trim();

            logger.info(`Your message will be logged 5x in the next 5 seconds`);

            for (let i = 0; i < 5; i++) {
                await forTime(1000);
                logger.info(`#${i} ${logMessage}`);
            }
        }

        return { message };
    }
}
