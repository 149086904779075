import { ReplaySubject } from 'rxjs';
import { Promisable } from 'type-fest';
import { forImmediate } from 'waitasecond';
import { ThrottleQueue } from '../../../40-utils/tasks/ThrottleQueue';
import { AbstractSystem } from '../../10-AbstractSystem/AbstractSystem';
import { IDependenciesRecord, IDependency } from '../interfaces/IDependencies';
import { IInstaller } from '../interfaces/IInstaller';
import { IModulesStorageWeak } from '../interfaces/IModulesStorage';
import { ISyncer } from '../interfaces/ISyncer';
import { ModuleInstaller } from '../ModuleInstaller';
import { createInstallPlan } from '../utils/createInstallPlan';

/**
 * This class represents entity which synchonizes installs / uninstalls modules for some reason. For each reason there will be separate extended syncer from this abstract class.
 * Some syncers can run from begining, some re  connected with board route and be constructed for each board
 * Syncers are aviable in systems container and some of them can have methods to iteract with them for example to actovate some support
 */
export abstract class AbstractSyncer extends AbstractSystem implements ISyncer {
    protected modulesStorage: Promisable<IModulesStorageWeak> = this.systems.moduleStore;
    private moduleInstaller: IInstaller;
    private throttleQueue = new ThrottleQueue({ throttler: forImmediate });

    public get statusOf() {
        return this.moduleInstaller.statusOf.bind(this.moduleInstaller);
    }

    public get installations() {
        return this.moduleInstaller.installations;
    }

    public async install(dependency: IDependency, syncerName?: string) {
        return this.moduleInstaller.install(dependency, syncerName);
    }
    public async uninstall(dependency: IDependency): Promise<void> {
        return this.moduleInstaller.uninstall(dependency);
    }

    public async uninstallAll(): Promise<void> {
        return this.moduleInstaller.uninstallAll();
    }

    protected async init() {
        this.moduleInstaller = new ModuleInstaller(await this.modulesStorage, this.systems);
        await this.initSyncer();
    }

    protected initSyncer(): void | Promise<void> {}

    private currentDependencies: IDependenciesRecord = {};

    // TODO: Probably installs / uninstalls modules in paralel

    /**
     * Promise that resolves when the first synchronization is done
     */
    public get firstSynchronization(): Promise<void> {
        return new Promise((resolve) => {
            this.doneSynchronizations.subscribe({
                next: resolve,
            });
        });
    }

    private doneSynchronizations: ReplaySubject<void> = new ReplaySubject(1);

    /**
     * TODO: !! Probbably do not use confusing shortcut sync but synchronize
     */
    public async sync(dependenciesTarget: IDependenciesRecord) {
        this.checkWhetherNotDestroyed(`${this.constructor.name}: Can not sync modules, because I am destroyed.`);

        // Note: Making shallow copy (maybe TODO deep?) because dependenciesTarget can be mutated by a consumer and this will prevent it.
        dependenciesTarget = { ...dependenciesTarget };
        await this.throttleQueue.task(async () => {
            const { uninstall, install } = createInstallPlan(this.currentDependencies, dependenciesTarget);

            this.currentDependencies = dependenciesTarget;

            // TODO: Throw error if module is not installed (for example when on development mode clicking to uninstall FontSizeAttribute)

            for (const moduleName of Object.keys(uninstall)) {
                await this.moduleInstaller.uninstall({ name: moduleName });
            }

            for (const moduleName of Object.keys(install)) {
                await this.moduleInstaller.install({ name: moduleName }, this.constructor.name);
            }
        });

        this.doneSynchronizations.next();
    }

    public async destroy() {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        await this.throttleQueue.destroy();
        await this.moduleInstaller.destroy();
    }
}
