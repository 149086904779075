import { Destroyable, IDestroyable } from 'destroyable';
import { IThrottleQueueOptions, ThrottleQueue } from './ThrottleQueue';
/**
 * @collboard-modules-sdk
 */
export class ThrottleQueues<TName, TTaskResult> extends Destroyable implements IDestroyable {
    private throttleQueues = new Map<TName, ThrottleQueue<TTaskResult>>();

    public constructor(private options: IThrottleQueueOptions) {
        super();
    }

    getThrottleQueue(key: TName): ThrottleQueue<TTaskResult> {
        const existingThrottleQueue = this.throttleQueues.get(key);
        if (existingThrottleQueue) {
            return existingThrottleQueue;
        } else {
            const newThrottleQueue = new ThrottleQueue<TTaskResult>({ ...this.options });
            this.throttleQueues.set(key, newThrottleQueue);
            return newThrottleQueue;
        }
    }

    public async destroy() {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        for (const throttleQueue of Object.values(this.throttleQueues)) {
            await throttleQueue.destroy();
        }
    }
}

/**
 * TODO: Handle errors same as in Queue
 */
