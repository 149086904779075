import { IArtContainer } from '../interfaces/IFileExportSupporterOptions';

/**
 * Utility for file export supporter which sort art containers by screen position
 * It is usefull for exporting order dependent arts like text or html
 *
 * @collboard-modules-sdk
 */
export function sortArtContainersByScreenPosition(artContainers: Array<IArtContainer>): Array<IArtContainer> {
    return artContainers
        .filter((artContainer) => artContainer.element)
        .map((artContainer) => ({
            ...artContainer,
            bounding: artContainer.element!.getBoundingClientRect(),
        }))
        .map((artContainer) => ({
            ...artContainer,
            //sort: artContainer.art.shift.x,
            sort: artContainer.bounding.top + artContainer.bounding.left,
        }))
        .sort((a, b) => (a.sort > b.sort ? 1 : -1));
}

/**
 * [🐽] TODO: Sorting of IArtContainers
 */
