import { RemoveIndex } from '../../../40-utils/typeHelpers';
import { ISystems } from '../../00-SystemsContainer/ISystems';
import { IUrlVariables } from '../../RoutingSystem/routePath/IUrlVariables';
import { ToolbarName } from '../../ToolbarSystem/0-ToolbarSystem';
import { IToolbarIcon } from '../../ToolbarSystem/IToolbarIcon';
import { IModuleDefinition } from '../interfaces/IModule';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';

/**
 * Makes toolbar icon module which setup/unsetup given submodule when user select/unselect icon
 * TODO: Probbably better name (makeIconModuleOnModule vs. makeIconModuleOnRoute)
 *
 * @collboard-modules-sdk
 */
export function makeIconModuleOnRoute(protoModule: {
    manifest?: IModuleManifest;
    toolbar: ToolbarName;
    icon: IToolbarIcon | ((systems: ISystems) => IToolbarIcon);

    // TODO: Is this a good name?
    routeParams: Partial<RemoveIndex<IUrlVariables>>;
}): IModuleDefinition {
    const { manifest, routeParams } = protoModule;

    return {
        manifest,
        async setup(systems: ISystems) {
            const { toolbarSystem, routingSystem } = await systems.request('toolbarSystem', 'routingSystem');

            const { icon: protoIcon, toolbar } = protoModule;
            let icon: IToolbarIcon;

            if (typeof protoIcon === 'function') {
                icon = protoIcon(systems);
            } else {
                icon = protoIcon;
            }

            return toolbarSystem.getToolbar(toolbar).registerIcon({
                ...icon,
                onClick: () => {
                    routingSystem.urlVariables.setValue(routeParams);
                },
            });
        },
    };
}

/**
 * TODO: !!x Rename to makeIconShortcutModule
 */
