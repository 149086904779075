import { internalModules } from '../../../ModuleStore/internalModules';
import { makeTraySimpleModule } from '../../../ModuleStore/makers/makeTraySimpleModule';

internalModules.declareModule(() =>
    makeTraySimpleModule({
        manifest: {
            name: '@test/simple-token-license/gold-stickers',
            title: '🏅 Gold stickers',
            description: { en: 'Testing SIMPLE_TOKEN usage license' },
            usageLicenses: [
                {
                    type: 'SIMPLE_TOKEN',
                    token: '2d9fbd9a-607f-44ab-87f1-2808c36ef5f0',
                    price: {
                        amount: 2,
                        currency: 'EUR',
                    },
                    buyLink:
                        'https://github.com/collboard/collboard/blob/main/src/50-systems/LicenseSystem/modules/mocked-license-eshop.md',
                    isModuleAutoInstalled: true /* <- TODO: Should it be true or false? */,
                },
                {
                    type: 'SIMPLE_TOKEN',
                    token: 'gold',
                    price: 0,
                    isModuleAutoInstalled: true,
                },
            ],
            // TODO: This should also appear in the templates
            flags: {
                isShowcase: true,
            },
        },
        icon: {
            icon: '🏅',
        } /* <- TODO: [💽] This is a but ugly, maybe allow IToolbarIcon to be just a string_char_emoji */,
        trayDefinition: [
            {
                title: 'Medals',
                icon: '🏅',
                groups: [
                    {
                        title: 'Medals',
                        items: [
                            {
                                title: 'Gold medal 1',
                                imageSrc:
                                    'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/325/sports-medal_1f3c5.png',
                            },
                            {
                                title: 'Gold medal 2',
                                imageSrc:
                                    'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/google/350/sports-medal_1f3c5.png',
                            },
                            {
                                title: 'Gold medal 3',
                                imageSrc:
                                    'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/samsung/349/sports-medal_1f3c5.png',
                            },
                            {
                                title: 'Gold medal 4',
                                imageSrc:
                                    'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/microsoft/319/sports-medal_1f3c5.png',
                            },
                        ],
                    },
                ],
            },
        ],
    }),
);

/**
 * TODO: !! Do not show on wellcome screen
 */
