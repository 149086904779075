import React from 'react';
import { string_url } from '../40-utils/typeAliases';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { Abstract2dBoxArt } from './27-Abstract2dBoxArt';

internalModules.declareModule(() => makeArtModule(IframeArt));

/**
 * Art rendering an iframe
 *
 * @collboard-modules-sdk
 */
class IframeArt extends Abstract2dBoxArt {
    public static serializeName = 'Iframe';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/iframe-art',
        deprecatedNames: '@collboard/iframe-art',
    };

    /**
     * @param src Iframe source
     * @param title Iframe title (alt)
     */
    public constructor(public src: string_url, public title: string = '') {
        super(null);
    }

    public renderBox() {
        if (this.measured) {
            return (
                <div style={{ padding: 10 }}>
                    <iframe
                        src={this.src}
                        className="wanted-iframe"
                        title={this.title}
                        frameBorder={0}
                        width={this.size.x || 0}
                        height={this.size.y || 0}
                    />
                </div>
            );
        } else {
            return (
                <div style={{ padding: 10 }}>
                    <iframe
                        src={this.src}
                        className="wanted-iframe"
                        title={this.title}
                        frameBorder={0}
                        ref={this.measure.bind(this)}
                    />
                </div>
            );
        }
    }
}

/**
 * TODO: [🎚️] Implement IArt
 */
