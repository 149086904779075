import React from 'react';
import { Authors } from '../../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../../50-systems/ModuleStore/makers/makeModalModule';
import { Translate } from '../../../50-systems/TranslationsSystem/components/Translate';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            flags: { isHidden: true },
            name: 'Error404ForBoard',
            title: { en: 'Collboard error 404 screen for board', cs: 'Collboard obrazovka s chybou 404 pro tabuli' },
            author: Authors.collboard,
            requirePermissions: [],
        },
        async createModal(systems) {
            const { routingSystem } = await systems.request('routingSystem');

            return {
                // TODO: Make some better copywriting for this module
                title: <Translate name="Error404ForBoard / title">:(</Translate>,
                // TODO: Remove> isUserInterfaceRendered: true,
                onClickClose: async () => {
                    routingSystem.navigateHome();
                },
                onClickOverlay: async () => {
                    routingSystem.navigateHome();
                },
                content: (
                    <>
                        <Translate name="Error404ForBoard / board does not exists" html>
                            Omlouváme se, ale tato tabule neexistuje.
                        </Translate>

                        <hr />
                        {/* TODO: [🚥] <ModalLine /> */}

                        <button
                            onClick={async () => {
                                routingSystem.navigateHome();
                            }}
                        >
                            <Translate name="Error404ForBoard / go to welcome">
                                Vybrat některou z existujících nebo založit novou.
                            </Translate>
                        </button>
                    </>
                ),
            };
        },
    }),
);
