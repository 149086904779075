import { expectExactlyOne } from '../../../40-utils/expectExactlyOne';
import { addWhiteBackground } from '../../../40-utils/image/addWhiteBackground';
import { imageToCanvas } from '../../../40-utils/image/imageToCanvas';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';

['png', 'jpeg', 'webp'].forEach((extension) =>
    internalModules.declareModule(() => ({
        manifest: {
            name: `@collboard/internal/${extension}-export`,
            deprecatedNames: `@collboard/${extension}-export`,
            requirePermissions: ['view'],
        },
        async setup(systems) {
            const { exportSystem } = await systems.request('exportSystem');

            // TODO: This exporter is not implemented because of an error in browser "DOMException: Support for multiple ClipboardItems is not implemented.". If this solved, implement it.
            return exportSystem.registerFileSupport({
                priority: 0,
                mimeType: `image/${extension}`,
                isHeavy: true,
                exportFile({
                    scope,
                    boundingBox,

                    quality,
                    scale,
                    // Note: isMaterialized doesn't matter here
                    // Note: isLinked doesn't matter here
                    isTransparent,
                    isTesting,
                }) {
                    if (boundingBox.width * boundingBox.height === 0) {
                        return null;
                    }

                    return async () => {
                        const exportOptions = {
                            // TODO: [🌚] Some way how to chain exports - pass useOtherExport util into IFileExportOptions
                            mimeType: 'image/svg+xml',
                            isHeavyExport: true,
                            scope,
                            scale,
                            isTransparent,

                            isMaterialized:
                                true /* <- Note: Materialization is essential to propper bitmap conversion */,
                            isTesting,
                        };
                        const svgFile = expectExactlyOne(
                            /* <- TODO: [🌁] Probbably there should be method exportSystem.exportFile */
                            'exported SVG file',
                            await exportSystem.exportFiles(exportOptions),
                            { exportOptions },
                        );

                        let canvas = await imageToCanvas(svgFile);

                        if (extension === 'jpeg') {
                            canvas = addWhiteBackground(canvas);
                        }

                        return canvas.toDataURL(`image/${extension}`, quality);
                    };

                    /*
                    Note: Old method just for backup>

                    if (artContainers.map((artContainer) => artContainer.element).filter(Boolean).length === 0) {
                        return null;
                    }

                    return async () => {
                        const canvas = document.createElement('canvas');
                        canvas.width = boundingBox.width;
                        canvas.height = boundingBox.height;
                        const ctx = canvas.getContext('2d')!;

                        // TODO: Can be done this in paralelel?
                        for (const artContainer of sortArtContainersByScreenPosition(artContainers)) {
                            if (!artContainer.element) {
                                continue;
                            }

                            try {
                                const { x, y } = artContainer.element.getBoundingClientRect();
                                const artCanvas = await html2canvas(artContainer.element, {
                                    proxy: `http://localhost:9950/` /* <- As a config * /,
                                });

                                ctx.drawImage(artCanvas, x, y);

                            } catch (error) {
                                consolex.error(error);
                            }
                        }

                        return canvas.toDataURL(`image/${extension}`);
                    };

                    */
                },
            });
        },
    })),
);
