import React from 'react';
import { useSkin } from '../../../40-utils/react-hooks/useSkin';
import { IModalGroupProps } from './IModalGroupProps';
import { ModalGroupStyledDiv } from './ModalGroupStyledDiv';
import { ModalGroupTitleStyledDiv } from './ModalGroupTitleStyledDiv';

/**
 *
 * @collboard-modules-sdk
 */
export function ModalGroup({ children, title, isTitleVisibleOnSmallScreen }: IModalGroupProps) {
    const { skin } = useSkin();

    return (
        <ModalGroupStyledDiv {...{ skin }}>
            {title && (
                <ModalGroupTitleStyledDiv {...{ skin, isTitleVisibleOnSmallScreen }}>{title}</ModalGroupTitleStyledDiv>
            )}
            {children}
        </ModalGroupStyledDiv>
    );
}
