import { BehaviorSubject } from 'rxjs';
import { Vector } from 'xyzt';

/**
 * @private
 */
function getWindowSize(): Vector {
    return Vector.fromObject(window, ['innerWidth', 'innerHeight']);
}

/**
 * Window size as observable object
 *
 * @singleton
 * @collboard-modules-sdk
 */
export const windowSize = new BehaviorSubject(getWindowSize());

window.addEventListener('resize', () => {
    windowSize.next(getWindowSize());
});

/**
 * TODO: windowSize is more like system than a singleton
 */
