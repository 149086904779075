import React from 'react';
import { windowSize } from '../../40-utils/getWindowSize';

export interface ISnowflakesProps {
    amount: number;
}

export class Snowflakes extends React.Component/* <- TODO: Import and use just a Component */ <ISnowflakesProps, {}> {
    private timer: NodeJS.Timeout;
    private flakes: Array<Snowflake> = [];

    /*
    public constructor(props: ISnowflakesProps) {
        super(props);
    }
    */

    componentDidMount() {
        this.flakes = [];
        for (let i = 0; i < this.props.amount; i++) {
            this.flakes.push(new Snowflake());
        }

        this.timer = setInterval(async () => {
            this.flakes.forEach((flake) => flake.tick());
            this.forceUpdate();
        }, 50);
    }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer);
    }

    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className="snowingWrapper">
                {this.flakes.map((flake, index) => flake.render(index))}
            </svg>
        );
    }
}

class Snowflake {
    public constructor() {
        this.replace(false);
    }

    private positionX: number;
    private positionY: number;
    private radius: number;
    private speedX: number;
    private speedY: number;

    public tick() {
        this.positionY += this.speedY;
        this.positionX += this.speedX;

        if (
            this.positionY > windowSize.value.y + this.radius ||
            this.positionX > windowSize.value.x + this.radius ||
            this.positionX < -this.radius
        ) {
            this.replace(true);
        }
    }

    private replace(top: boolean) {
        this.positionX = Math.random(/* <- TODO: [🐉] Probbably use seed random */) * windowSize.value.x;
        this.positionY = top
            ? -this.radius
            : Math.random(/* <- TODO: [🐉] Probbably use seed random */) * windowSize.value.y;
        this.radius = Math.random(/* <- TODO: [🐉] Probbably use seed random */) * 5;
        this.speedX = (Math.random(/* <- TODO: [🐉] Probbably use seed random */) - 0.5) * 0.5;
        this.speedY = (Math.random(/* <- TODO: [🐉] Probbably use seed random */) + 0.1) * 3;
    }

    public render(key: number) {
        return (
            <circle
                cx={this.positionX}
                cy={this.positionY}
                r={this.radius}
                style={{ fill: '#009edf33' }}
                {...{ key }}
            />
        );
    }
}

/**
 * TODO: [🏛️] Convert Class components to Functional (with hooks).
 */
