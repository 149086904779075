import { forTime } from 'waitasecond';
import { BoundingBox, IVectorData, NEGLIGIBLE_THRESHOLD, Vector } from 'xyzt';
import { windowSize } from '../../40-utils/getWindowSize';
import { LoadingArt } from '../../71-arts/30-LoadingArt';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { AppState } from '../AppState/0-AppState';

/**
 * CollSpace manages 3D objects rendered by WebGL (BABYLON JS) and provides all the tooling around the 3D scene, positioning, textures, materials, etc.
 *
 * @collboard-system
 */
export class CollSpace extends AbstractSystem {
    private appState: AppState;

    protected async init() {
        this.appState = await this.systems.appState;

        // /* not await */ this.testScreenBorder();
        // /* not await */ this.testPickPoint();
    }

    /**
     * Border of the user screen
     */
    get screenBorder(): BoundingBox {
        this.checkIsReady();
        return BoundingBox.fromTransform({
            // TODO: Optimize OR use internally pickPoint OR make whole CollSpace better
            translate: this.appState.transform.value.translate
                .negate()
                .scale(1 / this.appState.transform.value.scale.x),
            scale: windowSize.value.scale(1 / this.appState.transform.value.scale.x),
        });
    }

    /**
     * Pick point on the board based on point on users screen
     *
     * @param pointOnScreen [0,0] means top-left corner of the window
     * @return point on the board with normal vector (if there is 3D shape)
     */
    public pickPoint(pointOnScreen: IVectorData): { point: Vector; normal: Vector } {
        this.checkIsReady();
        let point = Vector.fromObject(pointOnScreen)
            .subtract(windowSize.value.half())
            .apply(this.appState.transform.value.inverse());

        if (point.z !== 0 && point.z < NEGLIGIBLE_THRESHOLD) {
            // TODO: LIB xyzt .setX(0).setY(0).setZ(0)
            // TODO: LIB xyzt Do wa want inPlace mutable methods?
            point = point.rearrangeAxes(([x, y, z]) => [x, y, 0]);
        }

        return {
            point,
            normal: new Vector(0, 0, 1),
        };
    }

    /**
     * Testing code to showcase screenBorder property
     */
    private async testScreenBorder() {
        // [🧙‍♂️]
        const virtualArtVersioningSystem = await this.systems.virtualArtVersioningSystem;
        await virtualArtVersioningSystem.ready;

        const operation = virtualArtVersioningSystem
            .createPrimaryOperation()
            .newArts(
                new LoadingArt('Testing screenBorder'),
                new LoadingArt('Testing screenBorder'),
                new LoadingArt('Testing screenBorder'),
                new LoadingArt('Testing screenBorder'),
            );
        while (true) {
            await forTime(100);
            // console.log(this.screenBorder.topLeft);
            operation.update(
                new LoadingArt('Testing screenBorder topLeft').setShift(this.screenBorder.topLeft),
                new LoadingArt('Testing screenBorder topRight').setShift(this.screenBorder.topRight),
                new LoadingArt('Testing screenBorder bottomLeft').setShift(this.screenBorder.bottomLeft),
                new LoadingArt('Testing screenBorder bottomRight').setShift(this.screenBorder.bottomRight),
            );
        }
    }

    /**
     * Testing code to showcase pickPoint method
     */
    private async testPickPoint() {
        // [🧙‍♂️]

        const virtualArtVersioningSystem = await this.systems.virtualArtVersioningSystem;
        await virtualArtVersioningSystem.ready;

        const operation = virtualArtVersioningSystem
            .createPrimaryOperation()
            .newArts(new LoadingArt('Testing testPickPoint'), new LoadingArt('Testing testPickPoint'));
        while (true) {
            await forTime(100);
            operation.update(
                new LoadingArt('Testing testPickPoint center').setShift(this.pickPoint(new Vector(0, 0)).point),
                new LoadingArt('Testing testPickPoint topLeft').setShift(this.pickPoint(windowSize.value.half()).point),
            );
        }
    }
}

/**
 * TODO: [🍤] This should be splitted/renamed into ArtSystem, SpaceSystem, RenderingSystem and MaterialSystem
 */
