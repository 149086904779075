import React from 'react';
import spaceTrim from 'spacetrim';

/**
 * Puts inlined javascript into page inside <script> element
 *
 * Note: Script is spacetrimmed
 * Note: It do not use dangerouslySetInnerHTML but still DO NOT PASS HERE ANY USER INPUT
 *
 * @collboard-modules-sdk
 */
export function Script({ children }: { children: string }) {
    const script = React.useRef/* <- TODO: Import and use just a useRef */ <HTMLScriptElement>(null);
    React.useEffect(
        /* <- TODO: Import and use just a useEffect */ () => {
            if (script.current) {
                script.current.innerHTML = spaceTrim(children);
            }
        },
        [children],
    );

    return <script ref={script} />;
}

/**
 * TODO: Use instead of every <script> in project
 * TODO: Src
 * TODO: isAsync, isDeferred,...
 */
