import * as Sentry from '@sentry/browser';
import { BlackholeLogger } from './40-utils/logger/BlackholeLogger';
import { ConsoleLogger } from './40-utils/logger/ConsoleLogger';
import { FilterLogger } from './40-utils/logger/FilterLogger';
import { ProxyLogger } from './40-utils/logger/ProxyLogger';
import { SentryLogger } from './40-utils/logger/SentryLogger';
import { SwitchLogger } from './40-utils/logger/SwitchLogger';
import { clientVersion } from './config';

Sentry.init({
    // TODO: Unhardcode this (in LoggingSystem)
    dsn: 'https://b0ead50bb15e4d8c92621e630bcd8d4c@o1135278.ingest.sentry.io/6184025',

    // TODO: Should be integrations used?
    integrations: [
        /*new BrowserTracing()*/
    ],

    // TODO: Adjusting this value in development to 1.0
    tracesSampleRate: 0.1,
});

// TODO: This should be done through a LoggingSystem
Sentry.setContext('version', {
    clientVersion,
});

/**
 * TODO: Anotate
 */
export const consolexBase = new ProxyLogger(
    ConsoleLogger.console,
    new FilterLogger({
        predicate({ type }) {
            return ['warn', 'error'].includes(type);
        },
        logger: new SentryLogger(Sentry),
    }),
);

/**
 * TODO: Anotate
 * TODO: Better name than consolex
 */
export const consolex = new SwitchLogger(consolexBase);

/**
 * @singleton
 */
let isLoggingDisabled = false;

/**
 * Disables logging of Collboard internal messages
 * It is useful when you develop something build on top of Collboard and you want to avoid confusion which messages are from Collboard and which are from the developed app
 *
 * Note: Calling this function is idempotent - it will do nothing if called second time
 *
 * @singleton
 */
export function disableLogging(reason: string) {
    if (!isLoggingDisabled) {
        // tslint:disable-next-line: no-console
        console.clear();
        console.info(`%cLogging from Collboard core was disabled because of ${reason}`, DISABLE_LOGGING_STYLE);
        consolex.switch(new BlackholeLogger());
        isLoggingDisabled = true;
    }
}

/**
 * TODO: [🔅] Put all %c styled console logs styles into one config place
 */

export const DISABLE_LOGGING_STYLE = `color: #888;`;

/**
 * TODO: LoggingSystem
 * TODO: Make is as non-singleton
 * TODO: Move it to some other file
 * TODO: Better name than consolex like clientConsole
 * TODO: Better management of logging - disableLogging is a bit ugly - make some manages / system to manage logging
 * TODO: [🔅] New comsole should have native way how to log messages with style (replacing %c on frontend and chalk.bgRed,.. on backend)
 */
