import { string_url } from '../typeAliases';

/**
 * Tests if given string is valid DataURL.
 *
 * @collboard-modules-sdk
 */
export function isValidDataurl(url: string_url): boolean {
    try {
        const urlObject = new URL(url);

        if (!['data:'].includes(urlObject.protocol)) {
            return false;
        }

        return true;
    } catch (error) {
        return false;
    }
}
