import { BehaviorSubject } from 'rxjs';
import spaceTrim from 'spacetrim';
import { TouchController } from 'touchcontroller';
import { createTimeout } from '../../30-components/utils/Loader/utils/createTimeout';
import { PermissionError } from '../../40-utils/errors/PermissionError';
import { consolex } from '../../consolex';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { Core } from '../30-Core/0-Core';
import { ApiClient } from '../ApiClient/0-ApiClient';
import { BoardApiClient } from '../ApiClient/BoardApiClient';
import { AppState } from '../AppState/0-AppState';
import { ArtSerializer } from '../ArtSerializer/ArtSerializer';
import { MaterialArtVersioningSystem } from '../ArtVersionSystem/0-MaterialArtVersioningSystem';
import { VirtualArtVersioningSystem } from '../ArtVersionSystem/0-VirtualArtVersioningSystem';
import { AttributesSystem } from '../AttributesSystem/0-AttributesSystem';
import { BoardSystem } from '../BoardSystem/0-BoardSystem';
import { BusinessSystem } from '../BusinessSystem/0-BusinessSystem';
import { ClosePreventionSystem } from '../ClosePreventionSystem/0-ClosePreventionSystem';
import { CollSpace } from '../CollSpace/0-CollSpace';
import { ControlSystem } from '../ControlSystem/ControlSystem';
import { CreateSystem } from '../CreateSystem/0-CreateSystem';
import { ExportSystem } from '../ExportSystem/0-ExportSystem';
import { FilepickSystem } from '../FilepickSystem/0-FilepickSystem';
import { FocusSystem } from '../FocusSystem/0-FocusSystem';
import { FractalSystem } from '../FractalSystem/0-FractalSystem';
import { GamificationSystem } from '../GamificationSystem/0-GamificationSystem';
import { GenerateSystem } from '../GenerateSystem/0-GenerateSystem';
import { HintSystem } from '../HintSystem/0-HintSystem';
import { IdentitySystem } from '../IdentitySystem/0-IdentitySystem';
import { ImportSystem } from '../ImportSystem/0-ImportSystem';
import { LicenseSystem } from '../LicenseSystem/0-LicenseSystem';
import { LicenseSyncer } from '../LicenseSystem/LicenseSyncer';
import { MessagesApiSystem } from '../MessagesApiSystem/0-MessagesApiSystem';
import { ModuleStore } from '../ModuleStore/connectors/0-ModuleStore';
import { ArtSupportSyncer } from '../ModuleStore/Syncers/ArtSupportSyncer';
import { AttributeSupportSyncer } from '../ModuleStore/Syncers/AttributeSupportSyncer';
import { CornerstoneSyncer } from '../ModuleStore/Syncers/CornerstoneSyncer';
import { FileSupportSyncer } from '../ModuleStore/Syncers/FileSupportSyncer';
import { RouteAndBusinessSyncer } from '../ModuleStore/Syncers/RouteAndBusinessSyncer';
import { NotificationSystem } from '../NotificationSystem/0-NotificationSystem';
import { PointerSystem } from '../PointerSystem/0-PointerSystem';
import { RoutingSystem } from '../RoutingSystem/0-RoutingSystem';
import { SnapSystem } from '../SnapSystem/0-SnapSystem';
import { SoundSystem } from '../SoundSystem/0-SoundSystem';
import { StorageSystem } from '../StorageSystem/StorageSystem';
import { StyleSystem } from '../StyleSystem/0-StyleSystem';
import { TestSystem } from '../TestSystem/0-TestSystem';
import { ToolbarSystem } from '../ToolbarSystem/0-ToolbarSystem';
import { TranslationsSystem } from '../TranslationsSystem/0-TranslationsSystem';
import { UsercontentSystem } from '../UsercontentSystem/0-UsercontentSystem';
import { UserInterfaceSystem } from '../UserInterfaceSystem/0-UserInterfaceSystem';
import { VoiceSystem } from '../VoiceSystem/0-VoiceSystem';
import { ISystemsExtended, SystemName } from './ISystems';
import { ISystemsResolved } from './ISystemsResolved';
import { signatureManager } from './SignatureManager';

/**
 * TODO: Protected systems and permissions
 */
export class SystemsContainer implements ISystemsExtended {
    /**
     * Request to use systems in the module
     *
     * @param requestedSystemsNames Names of requested systems
     * @returns Promise of all record object which contains all requested systems which will be resolved after permissions are granted
     */
    public async request<TSystemNames extends SystemName>(
        ...requestedSystemsNames: Array<TSystemNames>
    ): Promise<Pick<ISystemsResolved, TSystemNames>> {
        const systems: any = {};

        // TODO: Optimization: Obtain systems in paralel
        // TODO: Reliability: Test in random order
        // TODO: Singing (not only here but when requesting system by all possible ways)

        for (const requestedSystemName of requestedSystemsNames) {
            const timeout = createTimeout({
                duration: 100,
                alt: requestedSystemName,
                additional: {
                    module: signatureManager.getSignature(this),

                    requestedSystemName,
                    requestedSystemsNames,

                    systems,
                    systemsContainer: this,
                },
            });

            const system = await this[requestedSystemName];

            if ((system as any).ready) {
                await (system as any).ready;
            }

            // TODO: Optimize: Sign only needed systems
            systems[requestedSystemName] = signatureManager.sign(system, signatureManager.getSignature(this));

            timeout.destroy();

            // TODO: Save signed system and use it here [🍩]
        }

        return systems;
    }

    /**
     * Use previously requested systems
     *
     * Note: You need first to request the systems you want to use
     *
     * @param requestedSystemsNames Names of requested systems
     * @returns record object which contains all requested systems
     */
    public use<TSystemNames extends SystemName>(
        ...requestedSystemsNames: Array<TSystemNames>
    ): Pick<ISystemsResolved, TSystemNames> {
        const systems: any = {};

        // TODO: Optimization: Obtain systems in paralel
        // TODO: Reliability: Test in random order
        // TODO: Singing (not only here but when requesting system by all possible ways)

        for (const requestedSystemName of requestedSystemsNames) {
            // TODO: [🍩] Just go through already signed systems - if some missing throw a PermissionError

            const system: AbstractSystem | null = (this as any)[requestedSystemName + 'Subject'].value;

            if (!system) {
                const argumentsAsString = requestedSystemsNames.map((name) => `"${name}"`).join(', ');
                throw new PermissionError(
                    spaceTrim(`
                        System ${requestedSystemName} is not available

                        Before calling systems.use(${argumentsAsString}) or using hook useSystems(${argumentsAsString}) you need to call:

                        > await systems.request(${argumentsAsString});

                        ${
                            /*
                            TODO: When [0] also show:

                            > Available systems:
                            >
                            > apiClient, boardApiClient, appState, materialArtVersioningSystem, virtualArtVersioningSystem
                            */ ''
                        }


                    `),
                );
            }
            systems[requestedSystemName] = signatureManager.sign(system, signatureManager.getSignature(this));
        }

        return systems;
    }

    /**
     * Generator: Systems
     * Add: TouchController
     * Pattern: private readonly <system>Subject = new BehaviorSubject<<System> | null>(null);
     */

    /* GENERATED BY genetate-systems */
    /* Warning: Do not edit this part by hand, all changes will be lost on next execution! */
    private readonly coreSubject = new BehaviorSubject<Core | null>(null);
    private readonly apiClientSubject = new BehaviorSubject<ApiClient | null>(null);
    private readonly boardApiClientSubject = new BehaviorSubject<BoardApiClient | null>(null);
    private readonly appStateSubject = new BehaviorSubject<AppState | null>(null);
    private readonly artSerializerSubject = new BehaviorSubject<ArtSerializer | null>(null);
    private readonly materialArtVersioningSystemSubject = new BehaviorSubject<MaterialArtVersioningSystem | null>(null);
    private readonly virtualArtVersioningSystemSubject = new BehaviorSubject<VirtualArtVersioningSystem | null>(null);
    private readonly attributesSystemSubject = new BehaviorSubject<AttributesSystem | null>(null);
    private readonly boardSystemSubject = new BehaviorSubject<BoardSystem | null>(null);
    private readonly businessSystemSubject = new BehaviorSubject<BusinessSystem | null>(null);
    private readonly closePreventionSystemSubject = new BehaviorSubject<ClosePreventionSystem | null>(null);
    private readonly collSpaceSubject = new BehaviorSubject<CollSpace | null>(null);
    private readonly controlSystemSubject = new BehaviorSubject<ControlSystem | null>(null);
    private readonly createSystemSubject = new BehaviorSubject<CreateSystem | null>(null);
    private readonly exportSystemSubject = new BehaviorSubject<ExportSystem | null>(null);
    private readonly filepickSystemSubject = new BehaviorSubject<FilepickSystem | null>(null);
    private readonly focusSystemSubject = new BehaviorSubject<FocusSystem | null>(null);
    private readonly fractalSystemSubject = new BehaviorSubject<FractalSystem | null>(null);
    private readonly gamificationSystemSubject = new BehaviorSubject<GamificationSystem | null>(null);
    private readonly generateSystemSubject = new BehaviorSubject<GenerateSystem | null>(null);
    private readonly hintSystemSubject = new BehaviorSubject<HintSystem | null>(null);
    private readonly identitySystemSubject = new BehaviorSubject<IdentitySystem | null>(null);
    private readonly importSystemSubject = new BehaviorSubject<ImportSystem | null>(null);
    private readonly licenseSystemSubject = new BehaviorSubject<LicenseSystem | null>(null);
    private readonly licenseSyncerSubject = new BehaviorSubject<LicenseSyncer | null>(null);
    private readonly messagesApiSystemSubject = new BehaviorSubject<MessagesApiSystem | null>(null);
    private readonly moduleStoreSubject = new BehaviorSubject<ModuleStore | null>(null);
    private readonly artSupportSyncerSubject = new BehaviorSubject<ArtSupportSyncer | null>(null);
    private readonly attributeSupportSyncerSubject = new BehaviorSubject<AttributeSupportSyncer | null>(null);
    private readonly cornerstoneSyncerSubject = new BehaviorSubject<CornerstoneSyncer | null>(null);
    private readonly fileSupportSyncerSubject = new BehaviorSubject<FileSupportSyncer | null>(null);
    private readonly routeAndBusinessSyncerSubject = new BehaviorSubject<RouteAndBusinessSyncer | null>(null);
    private readonly notificationSystemSubject = new BehaviorSubject<NotificationSystem | null>(null);
    private readonly pointerSystemSubject = new BehaviorSubject<PointerSystem | null>(null);
    private readonly routingSystemSubject = new BehaviorSubject<RoutingSystem | null>(null);
    private readonly snapSystemSubject = new BehaviorSubject<SnapSystem | null>(null);
    private readonly soundSystemSubject = new BehaviorSubject<SoundSystem | null>(null);
    private readonly storageSystemSubject = new BehaviorSubject<StorageSystem | null>(null);
    private readonly styleSystemSubject = new BehaviorSubject<StyleSystem | null>(null);
    private readonly testSystemSubject = new BehaviorSubject<TestSystem | null>(null);
    private readonly toolbarSystemSubject = new BehaviorSubject<ToolbarSystem | null>(null);
    private readonly translationsSystemSubject = new BehaviorSubject<TranslationsSystem | null>(null);
    private readonly usercontentSystemSubject = new BehaviorSubject<UsercontentSystem | null>(null);
    private readonly userInterfaceSystemSubject = new BehaviorSubject<UserInterfaceSystem | null>(null);
    private readonly voiceSystemSubject = new BehaviorSubject<VoiceSystem | null>(null);
    private readonly touchControllerSubject = new BehaviorSubject<TouchController | null>(null);
    /* END OF GENERATED BY genetate-systems */

    /**
     * Generator: Systems
     * Add: TouchController
     * Pattern:

    public get <system>(): Promise<<System>> {
        return this.<system>Subject.value
            ? Promise.resolve(this.<system>Subject.value)
            : new Promise((resolve) => {
                  const subscription = this.<system>Subject.subscribe((<system>) => {
                      if (<system>) {
                          subscription.unsubscribe();
                          resolve(<system>);
                      }
                  });
              });
    }

    public async set<System>(<system>: <System>| null) {
        if(this.<system>Subject.value){
          if(<system>){
            consolex.warn(`<System> is going to be set twice to SystemsContainer.`);
          }
          await this.<system>Subject.value.destroy();
        }
        this.<system>Subject.next(<system>);
    }

    // ----
    */

    /* GENERATED BY genetate-systems */
    /* Warning: Do not edit this part by hand, all changes will be lost on next execution! */
    public get core(): Promise<Core> {
        return this.coreSubject.value
            ? Promise.resolve(this.coreSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.coreSubject.subscribe((core) => {
                      if (core) {
                          subscription.unsubscribe();
                          resolve(core);
                      }
                  });
              });
    }

    public async setCore(core: Core | null) {
        if (this.coreSubject.value) {
            if (core) {
                consolex.warn(`Core is going to be set twice to SystemsContainer.`);
            }
            await this.coreSubject.value.destroy();
        }
        this.coreSubject.next(core);
    }

    // ----
    public get apiClient(): Promise<ApiClient> {
        return this.apiClientSubject.value
            ? Promise.resolve(this.apiClientSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.apiClientSubject.subscribe((apiClient) => {
                      if (apiClient) {
                          subscription.unsubscribe();
                          resolve(apiClient);
                      }
                  });
              });
    }

    public async setApiClient(apiClient: ApiClient | null) {
        if (this.apiClientSubject.value) {
            if (apiClient) {
                consolex.warn(`ApiClient is going to be set twice to SystemsContainer.`);
            }
            await this.apiClientSubject.value.destroy();
        }
        this.apiClientSubject.next(apiClient);
    }

    // ----
    public get boardApiClient(): Promise<BoardApiClient> {
        return this.boardApiClientSubject.value
            ? Promise.resolve(this.boardApiClientSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.boardApiClientSubject.subscribe((boardApiClient) => {
                      if (boardApiClient) {
                          subscription.unsubscribe();
                          resolve(boardApiClient);
                      }
                  });
              });
    }

    public async setBoardApiClient(boardApiClient: BoardApiClient | null) {
        if (this.boardApiClientSubject.value) {
            if (boardApiClient) {
                consolex.warn(`BoardApiClient is going to be set twice to SystemsContainer.`);
            }
            await this.boardApiClientSubject.value.destroy();
        }
        this.boardApiClientSubject.next(boardApiClient);
    }

    // ----
    public get appState(): Promise<AppState> {
        return this.appStateSubject.value
            ? Promise.resolve(this.appStateSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.appStateSubject.subscribe((appState) => {
                      if (appState) {
                          subscription.unsubscribe();
                          resolve(appState);
                      }
                  });
              });
    }

    public async setAppState(appState: AppState | null) {
        if (this.appStateSubject.value) {
            if (appState) {
                consolex.warn(`AppState is going to be set twice to SystemsContainer.`);
            }
            await this.appStateSubject.value.destroy();
        }
        this.appStateSubject.next(appState);
    }

    // ----
    public get artSerializer(): Promise<ArtSerializer> {
        return this.artSerializerSubject.value
            ? Promise.resolve(this.artSerializerSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.artSerializerSubject.subscribe((artSerializer) => {
                      if (artSerializer) {
                          subscription.unsubscribe();
                          resolve(artSerializer);
                      }
                  });
              });
    }

    public async setArtSerializer(artSerializer: ArtSerializer | null) {
        if (this.artSerializerSubject.value) {
            if (artSerializer) {
                consolex.warn(`ArtSerializer is going to be set twice to SystemsContainer.`);
            }
            await this.artSerializerSubject.value.destroy();
        }
        this.artSerializerSubject.next(artSerializer);
    }

    // ----
    public get materialArtVersioningSystem(): Promise<MaterialArtVersioningSystem> {
        return this.materialArtVersioningSystemSubject.value
            ? Promise.resolve(this.materialArtVersioningSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.materialArtVersioningSystemSubject.subscribe(
                      (materialArtVersioningSystem) => {
                          if (materialArtVersioningSystem) {
                              subscription.unsubscribe();
                              resolve(materialArtVersioningSystem);
                          }
                      },
                  );
              });
    }

    public async setMaterialArtVersioningSystem(materialArtVersioningSystem: MaterialArtVersioningSystem | null) {
        if (this.materialArtVersioningSystemSubject.value) {
            if (materialArtVersioningSystem) {
                consolex.warn(`MaterialArtVersioningSystem is going to be set twice to SystemsContainer.`);
            }
            await this.materialArtVersioningSystemSubject.value.destroy();
        }
        this.materialArtVersioningSystemSubject.next(materialArtVersioningSystem);
    }

    // ----
    public get virtualArtVersioningSystem(): Promise<VirtualArtVersioningSystem> {
        return this.virtualArtVersioningSystemSubject.value
            ? Promise.resolve(this.virtualArtVersioningSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.virtualArtVersioningSystemSubject.subscribe(
                      (virtualArtVersioningSystem) => {
                          if (virtualArtVersioningSystem) {
                              subscription.unsubscribe();
                              resolve(virtualArtVersioningSystem);
                          }
                      },
                  );
              });
    }

    public async setVirtualArtVersioningSystem(virtualArtVersioningSystem: VirtualArtVersioningSystem | null) {
        if (this.virtualArtVersioningSystemSubject.value) {
            if (virtualArtVersioningSystem) {
                consolex.warn(`VirtualArtVersioningSystem is going to be set twice to SystemsContainer.`);
            }
            await this.virtualArtVersioningSystemSubject.value.destroy();
        }
        this.virtualArtVersioningSystemSubject.next(virtualArtVersioningSystem);
    }

    // ----
    public get attributesSystem(): Promise<AttributesSystem> {
        return this.attributesSystemSubject.value
            ? Promise.resolve(this.attributesSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.attributesSystemSubject.subscribe((attributesSystem) => {
                      if (attributesSystem) {
                          subscription.unsubscribe();
                          resolve(attributesSystem);
                      }
                  });
              });
    }

    public async setAttributesSystem(attributesSystem: AttributesSystem | null) {
        if (this.attributesSystemSubject.value) {
            if (attributesSystem) {
                consolex.warn(`AttributesSystem is going to be set twice to SystemsContainer.`);
            }
            await this.attributesSystemSubject.value.destroy();
        }
        this.attributesSystemSubject.next(attributesSystem);
    }

    // ----
    public get boardSystem(): Promise<BoardSystem> {
        return this.boardSystemSubject.value
            ? Promise.resolve(this.boardSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.boardSystemSubject.subscribe((boardSystem) => {
                      if (boardSystem) {
                          subscription.unsubscribe();
                          resolve(boardSystem);
                      }
                  });
              });
    }

    public async setBoardSystem(boardSystem: BoardSystem | null) {
        if (this.boardSystemSubject.value) {
            if (boardSystem) {
                consolex.warn(`BoardSystem is going to be set twice to SystemsContainer.`);
            }
            await this.boardSystemSubject.value.destroy();
        }
        this.boardSystemSubject.next(boardSystem);
    }

    // ----
    public get businessSystem(): Promise<BusinessSystem> {
        return this.businessSystemSubject.value
            ? Promise.resolve(this.businessSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.businessSystemSubject.subscribe((businessSystem) => {
                      if (businessSystem) {
                          subscription.unsubscribe();
                          resolve(businessSystem);
                      }
                  });
              });
    }

    public async setBusinessSystem(businessSystem: BusinessSystem | null) {
        if (this.businessSystemSubject.value) {
            if (businessSystem) {
                consolex.warn(`BusinessSystem is going to be set twice to SystemsContainer.`);
            }
            await this.businessSystemSubject.value.destroy();
        }
        this.businessSystemSubject.next(businessSystem);
    }

    // ----
    public get closePreventionSystem(): Promise<ClosePreventionSystem> {
        return this.closePreventionSystemSubject.value
            ? Promise.resolve(this.closePreventionSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.closePreventionSystemSubject.subscribe((closePreventionSystem) => {
                      if (closePreventionSystem) {
                          subscription.unsubscribe();
                          resolve(closePreventionSystem);
                      }
                  });
              });
    }

    public async setClosePreventionSystem(closePreventionSystem: ClosePreventionSystem | null) {
        if (this.closePreventionSystemSubject.value) {
            if (closePreventionSystem) {
                consolex.warn(`ClosePreventionSystem is going to be set twice to SystemsContainer.`);
            }
            await this.closePreventionSystemSubject.value.destroy();
        }
        this.closePreventionSystemSubject.next(closePreventionSystem);
    }

    // ----
    public get collSpace(): Promise<CollSpace> {
        return this.collSpaceSubject.value
            ? Promise.resolve(this.collSpaceSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.collSpaceSubject.subscribe((collSpace) => {
                      if (collSpace) {
                          subscription.unsubscribe();
                          resolve(collSpace);
                      }
                  });
              });
    }

    public async setCollSpace(collSpace: CollSpace | null) {
        if (this.collSpaceSubject.value) {
            if (collSpace) {
                consolex.warn(`CollSpace is going to be set twice to SystemsContainer.`);
            }
            await this.collSpaceSubject.value.destroy();
        }
        this.collSpaceSubject.next(collSpace);
    }

    // ----
    public get controlSystem(): Promise<ControlSystem> {
        return this.controlSystemSubject.value
            ? Promise.resolve(this.controlSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.controlSystemSubject.subscribe((controlSystem) => {
                      if (controlSystem) {
                          subscription.unsubscribe();
                          resolve(controlSystem);
                      }
                  });
              });
    }

    public async setControlSystem(controlSystem: ControlSystem | null) {
        if (this.controlSystemSubject.value) {
            if (controlSystem) {
                consolex.warn(`ControlSystem is going to be set twice to SystemsContainer.`);
            }
            await this.controlSystemSubject.value.destroy();
        }
        this.controlSystemSubject.next(controlSystem);
    }

    // ----
    public get createSystem(): Promise<CreateSystem> {
        return this.createSystemSubject.value
            ? Promise.resolve(this.createSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.createSystemSubject.subscribe((createSystem) => {
                      if (createSystem) {
                          subscription.unsubscribe();
                          resolve(createSystem);
                      }
                  });
              });
    }

    public async setCreateSystem(createSystem: CreateSystem | null) {
        if (this.createSystemSubject.value) {
            if (createSystem) {
                consolex.warn(`CreateSystem is going to be set twice to SystemsContainer.`);
            }
            await this.createSystemSubject.value.destroy();
        }
        this.createSystemSubject.next(createSystem);
    }

    // ----
    public get exportSystem(): Promise<ExportSystem> {
        return this.exportSystemSubject.value
            ? Promise.resolve(this.exportSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.exportSystemSubject.subscribe((exportSystem) => {
                      if (exportSystem) {
                          subscription.unsubscribe();
                          resolve(exportSystem);
                      }
                  });
              });
    }

    public async setExportSystem(exportSystem: ExportSystem | null) {
        if (this.exportSystemSubject.value) {
            if (exportSystem) {
                consolex.warn(`ExportSystem is going to be set twice to SystemsContainer.`);
            }
            await this.exportSystemSubject.value.destroy();
        }
        this.exportSystemSubject.next(exportSystem);
    }

    // ----
    public get filepickSystem(): Promise<FilepickSystem> {
        return this.filepickSystemSubject.value
            ? Promise.resolve(this.filepickSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.filepickSystemSubject.subscribe((filepickSystem) => {
                      if (filepickSystem) {
                          subscription.unsubscribe();
                          resolve(filepickSystem);
                      }
                  });
              });
    }

    public async setFilepickSystem(filepickSystem: FilepickSystem | null) {
        if (this.filepickSystemSubject.value) {
            if (filepickSystem) {
                consolex.warn(`FilepickSystem is going to be set twice to SystemsContainer.`);
            }
            await this.filepickSystemSubject.value.destroy();
        }
        this.filepickSystemSubject.next(filepickSystem);
    }

    // ----
    public get focusSystem(): Promise<FocusSystem> {
        return this.focusSystemSubject.value
            ? Promise.resolve(this.focusSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.focusSystemSubject.subscribe((focusSystem) => {
                      if (focusSystem) {
                          subscription.unsubscribe();
                          resolve(focusSystem);
                      }
                  });
              });
    }

    public async setFocusSystem(focusSystem: FocusSystem | null) {
        if (this.focusSystemSubject.value) {
            if (focusSystem) {
                consolex.warn(`FocusSystem is going to be set twice to SystemsContainer.`);
            }
            await this.focusSystemSubject.value.destroy();
        }
        this.focusSystemSubject.next(focusSystem);
    }

    // ----
    public get fractalSystem(): Promise<FractalSystem> {
        return this.fractalSystemSubject.value
            ? Promise.resolve(this.fractalSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.fractalSystemSubject.subscribe((fractalSystem) => {
                      if (fractalSystem) {
                          subscription.unsubscribe();
                          resolve(fractalSystem);
                      }
                  });
              });
    }

    public async setFractalSystem(fractalSystem: FractalSystem | null) {
        if (this.fractalSystemSubject.value) {
            if (fractalSystem) {
                consolex.warn(`FractalSystem is going to be set twice to SystemsContainer.`);
            }
            await this.fractalSystemSubject.value.destroy();
        }
        this.fractalSystemSubject.next(fractalSystem);
    }

    // ----
    public get gamificationSystem(): Promise<GamificationSystem> {
        return this.gamificationSystemSubject.value
            ? Promise.resolve(this.gamificationSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.gamificationSystemSubject.subscribe((gamificationSystem) => {
                      if (gamificationSystem) {
                          subscription.unsubscribe();
                          resolve(gamificationSystem);
                      }
                  });
              });
    }

    public async setGamificationSystem(gamificationSystem: GamificationSystem | null) {
        if (this.gamificationSystemSubject.value) {
            if (gamificationSystem) {
                consolex.warn(`GamificationSystem is going to be set twice to SystemsContainer.`);
            }
            await this.gamificationSystemSubject.value.destroy();
        }
        this.gamificationSystemSubject.next(gamificationSystem);
    }

    // ----
    public get generateSystem(): Promise<GenerateSystem> {
        return this.generateSystemSubject.value
            ? Promise.resolve(this.generateSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.generateSystemSubject.subscribe((generateSystem) => {
                      if (generateSystem) {
                          subscription.unsubscribe();
                          resolve(generateSystem);
                      }
                  });
              });
    }

    public async setGenerateSystem(generateSystem: GenerateSystem | null) {
        if (this.generateSystemSubject.value) {
            if (generateSystem) {
                consolex.warn(`GenerateSystem is going to be set twice to SystemsContainer.`);
            }
            await this.generateSystemSubject.value.destroy();
        }
        this.generateSystemSubject.next(generateSystem);
    }

    // ----
    public get hintSystem(): Promise<HintSystem> {
        return this.hintSystemSubject.value
            ? Promise.resolve(this.hintSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.hintSystemSubject.subscribe((hintSystem) => {
                      if (hintSystem) {
                          subscription.unsubscribe();
                          resolve(hintSystem);
                      }
                  });
              });
    }

    public async setHintSystem(hintSystem: HintSystem | null) {
        if (this.hintSystemSubject.value) {
            if (hintSystem) {
                consolex.warn(`HintSystem is going to be set twice to SystemsContainer.`);
            }
            await this.hintSystemSubject.value.destroy();
        }
        this.hintSystemSubject.next(hintSystem);
    }

    // ----
    public get identitySystem(): Promise<IdentitySystem> {
        return this.identitySystemSubject.value
            ? Promise.resolve(this.identitySystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.identitySystemSubject.subscribe((identitySystem) => {
                      if (identitySystem) {
                          subscription.unsubscribe();
                          resolve(identitySystem);
                      }
                  });
              });
    }

    public async setIdentitySystem(identitySystem: IdentitySystem | null) {
        if (this.identitySystemSubject.value) {
            if (identitySystem) {
                consolex.warn(`IdentitySystem is going to be set twice to SystemsContainer.`);
            }
            await this.identitySystemSubject.value.destroy();
        }
        this.identitySystemSubject.next(identitySystem);
    }

    // ----
    public get importSystem(): Promise<ImportSystem> {
        return this.importSystemSubject.value
            ? Promise.resolve(this.importSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.importSystemSubject.subscribe((importSystem) => {
                      if (importSystem) {
                          subscription.unsubscribe();
                          resolve(importSystem);
                      }
                  });
              });
    }

    public async setImportSystem(importSystem: ImportSystem | null) {
        if (this.importSystemSubject.value) {
            if (importSystem) {
                consolex.warn(`ImportSystem is going to be set twice to SystemsContainer.`);
            }
            await this.importSystemSubject.value.destroy();
        }
        this.importSystemSubject.next(importSystem);
    }

    // ----
    public get licenseSystem(): Promise<LicenseSystem> {
        return this.licenseSystemSubject.value
            ? Promise.resolve(this.licenseSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.licenseSystemSubject.subscribe((licenseSystem) => {
                      if (licenseSystem) {
                          subscription.unsubscribe();
                          resolve(licenseSystem);
                      }
                  });
              });
    }

    public async setLicenseSystem(licenseSystem: LicenseSystem | null) {
        if (this.licenseSystemSubject.value) {
            if (licenseSystem) {
                consolex.warn(`LicenseSystem is going to be set twice to SystemsContainer.`);
            }
            await this.licenseSystemSubject.value.destroy();
        }
        this.licenseSystemSubject.next(licenseSystem);
    }

    // ----
    public get licenseSyncer(): Promise<LicenseSyncer> {
        return this.licenseSyncerSubject.value
            ? Promise.resolve(this.licenseSyncerSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.licenseSyncerSubject.subscribe((licenseSyncer) => {
                      if (licenseSyncer) {
                          subscription.unsubscribe();
                          resolve(licenseSyncer);
                      }
                  });
              });
    }

    public async setLicenseSyncer(licenseSyncer: LicenseSyncer | null) {
        if (this.licenseSyncerSubject.value) {
            if (licenseSyncer) {
                consolex.warn(`LicenseSyncer is going to be set twice to SystemsContainer.`);
            }
            await this.licenseSyncerSubject.value.destroy();
        }
        this.licenseSyncerSubject.next(licenseSyncer);
    }

    // ----
    public get messagesApiSystem(): Promise<MessagesApiSystem> {
        return this.messagesApiSystemSubject.value
            ? Promise.resolve(this.messagesApiSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.messagesApiSystemSubject.subscribe((messagesApiSystem) => {
                      if (messagesApiSystem) {
                          subscription.unsubscribe();
                          resolve(messagesApiSystem);
                      }
                  });
              });
    }

    public async setMessagesApiSystem(messagesApiSystem: MessagesApiSystem | null) {
        if (this.messagesApiSystemSubject.value) {
            if (messagesApiSystem) {
                consolex.warn(`MessagesApiSystem is going to be set twice to SystemsContainer.`);
            }
            await this.messagesApiSystemSubject.value.destroy();
        }
        this.messagesApiSystemSubject.next(messagesApiSystem);
    }

    // ----
    public get moduleStore(): Promise<ModuleStore> {
        return this.moduleStoreSubject.value
            ? Promise.resolve(this.moduleStoreSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.moduleStoreSubject.subscribe((moduleStore) => {
                      if (moduleStore) {
                          subscription.unsubscribe();
                          resolve(moduleStore);
                      }
                  });
              });
    }

    public async setModuleStore(moduleStore: ModuleStore | null) {
        if (this.moduleStoreSubject.value) {
            if (moduleStore) {
                consolex.warn(`ModuleStore is going to be set twice to SystemsContainer.`);
            }
            await this.moduleStoreSubject.value.destroy();
        }
        this.moduleStoreSubject.next(moduleStore);
    }

    // ----
    public get artSupportSyncer(): Promise<ArtSupportSyncer> {
        return this.artSupportSyncerSubject.value
            ? Promise.resolve(this.artSupportSyncerSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.artSupportSyncerSubject.subscribe((artSupportSyncer) => {
                      if (artSupportSyncer) {
                          subscription.unsubscribe();
                          resolve(artSupportSyncer);
                      }
                  });
              });
    }

    public async setArtSupportSyncer(artSupportSyncer: ArtSupportSyncer | null) {
        if (this.artSupportSyncerSubject.value) {
            if (artSupportSyncer) {
                consolex.warn(`ArtSupportSyncer is going to be set twice to SystemsContainer.`);
            }
            await this.artSupportSyncerSubject.value.destroy();
        }
        this.artSupportSyncerSubject.next(artSupportSyncer);
    }

    // ----
    public get attributeSupportSyncer(): Promise<AttributeSupportSyncer> {
        return this.attributeSupportSyncerSubject.value
            ? Promise.resolve(this.attributeSupportSyncerSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.attributeSupportSyncerSubject.subscribe((attributeSupportSyncer) => {
                      if (attributeSupportSyncer) {
                          subscription.unsubscribe();
                          resolve(attributeSupportSyncer);
                      }
                  });
              });
    }

    public async setAttributeSupportSyncer(attributeSupportSyncer: AttributeSupportSyncer | null) {
        if (this.attributeSupportSyncerSubject.value) {
            if (attributeSupportSyncer) {
                consolex.warn(`AttributeSupportSyncer is going to be set twice to SystemsContainer.`);
            }
            await this.attributeSupportSyncerSubject.value.destroy();
        }
        this.attributeSupportSyncerSubject.next(attributeSupportSyncer);
    }

    // ----
    public get cornerstoneSyncer(): Promise<CornerstoneSyncer> {
        return this.cornerstoneSyncerSubject.value
            ? Promise.resolve(this.cornerstoneSyncerSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.cornerstoneSyncerSubject.subscribe((cornerstoneSyncer) => {
                      if (cornerstoneSyncer) {
                          subscription.unsubscribe();
                          resolve(cornerstoneSyncer);
                      }
                  });
              });
    }

    public async setCornerstoneSyncer(cornerstoneSyncer: CornerstoneSyncer | null) {
        if (this.cornerstoneSyncerSubject.value) {
            if (cornerstoneSyncer) {
                consolex.warn(`CornerstoneSyncer is going to be set twice to SystemsContainer.`);
            }
            await this.cornerstoneSyncerSubject.value.destroy();
        }
        this.cornerstoneSyncerSubject.next(cornerstoneSyncer);
    }

    // ----
    public get fileSupportSyncer(): Promise<FileSupportSyncer> {
        return this.fileSupportSyncerSubject.value
            ? Promise.resolve(this.fileSupportSyncerSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.fileSupportSyncerSubject.subscribe((fileSupportSyncer) => {
                      if (fileSupportSyncer) {
                          subscription.unsubscribe();
                          resolve(fileSupportSyncer);
                      }
                  });
              });
    }

    public async setFileSupportSyncer(fileSupportSyncer: FileSupportSyncer | null) {
        if (this.fileSupportSyncerSubject.value) {
            if (fileSupportSyncer) {
                consolex.warn(`FileSupportSyncer is going to be set twice to SystemsContainer.`);
            }
            await this.fileSupportSyncerSubject.value.destroy();
        }
        this.fileSupportSyncerSubject.next(fileSupportSyncer);
    }

    // ----
    public get routeAndBusinessSyncer(): Promise<RouteAndBusinessSyncer> {
        return this.routeAndBusinessSyncerSubject.value
            ? Promise.resolve(this.routeAndBusinessSyncerSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.routeAndBusinessSyncerSubject.subscribe((routeAndBusinessSyncer) => {
                      if (routeAndBusinessSyncer) {
                          subscription.unsubscribe();
                          resolve(routeAndBusinessSyncer);
                      }
                  });
              });
    }

    public async setRouteAndBusinessSyncer(routeAndBusinessSyncer: RouteAndBusinessSyncer | null) {
        if (this.routeAndBusinessSyncerSubject.value) {
            if (routeAndBusinessSyncer) {
                consolex.warn(`RouteAndBusinessSyncer is going to be set twice to SystemsContainer.`);
            }
            await this.routeAndBusinessSyncerSubject.value.destroy();
        }
        this.routeAndBusinessSyncerSubject.next(routeAndBusinessSyncer);
    }

    // ----
    public get notificationSystem(): Promise<NotificationSystem> {
        return this.notificationSystemSubject.value
            ? Promise.resolve(this.notificationSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.notificationSystemSubject.subscribe((notificationSystem) => {
                      if (notificationSystem) {
                          subscription.unsubscribe();
                          resolve(notificationSystem);
                      }
                  });
              });
    }

    public async setNotificationSystem(notificationSystem: NotificationSystem | null) {
        if (this.notificationSystemSubject.value) {
            if (notificationSystem) {
                consolex.warn(`NotificationSystem is going to be set twice to SystemsContainer.`);
            }
            await this.notificationSystemSubject.value.destroy();
        }
        this.notificationSystemSubject.next(notificationSystem);
    }

    // ----
    public get pointerSystem(): Promise<PointerSystem> {
        return this.pointerSystemSubject.value
            ? Promise.resolve(this.pointerSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.pointerSystemSubject.subscribe((pointerSystem) => {
                      if (pointerSystem) {
                          subscription.unsubscribe();
                          resolve(pointerSystem);
                      }
                  });
              });
    }

    public async setPointerSystem(pointerSystem: PointerSystem | null) {
        if (this.pointerSystemSubject.value) {
            if (pointerSystem) {
                consolex.warn(`PointerSystem is going to be set twice to SystemsContainer.`);
            }
            await this.pointerSystemSubject.value.destroy();
        }
        this.pointerSystemSubject.next(pointerSystem);
    }

    // ----
    public get routingSystem(): Promise<RoutingSystem> {
        return this.routingSystemSubject.value
            ? Promise.resolve(this.routingSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.routingSystemSubject.subscribe((routingSystem) => {
                      if (routingSystem) {
                          subscription.unsubscribe();
                          resolve(routingSystem);
                      }
                  });
              });
    }

    public async setRoutingSystem(routingSystem: RoutingSystem | null) {
        if (this.routingSystemSubject.value) {
            if (routingSystem) {
                consolex.warn(`RoutingSystem is going to be set twice to SystemsContainer.`);
            }
            await this.routingSystemSubject.value.destroy();
        }
        this.routingSystemSubject.next(routingSystem);
    }

    // ----
    public get snapSystem(): Promise<SnapSystem> {
        return this.snapSystemSubject.value
            ? Promise.resolve(this.snapSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.snapSystemSubject.subscribe((snapSystem) => {
                      if (snapSystem) {
                          subscription.unsubscribe();
                          resolve(snapSystem);
                      }
                  });
              });
    }

    public async setSnapSystem(snapSystem: SnapSystem | null) {
        if (this.snapSystemSubject.value) {
            if (snapSystem) {
                consolex.warn(`SnapSystem is going to be set twice to SystemsContainer.`);
            }
            await this.snapSystemSubject.value.destroy();
        }
        this.snapSystemSubject.next(snapSystem);
    }

    // ----
    public get soundSystem(): Promise<SoundSystem> {
        return this.soundSystemSubject.value
            ? Promise.resolve(this.soundSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.soundSystemSubject.subscribe((soundSystem) => {
                      if (soundSystem) {
                          subscription.unsubscribe();
                          resolve(soundSystem);
                      }
                  });
              });
    }

    public async setSoundSystem(soundSystem: SoundSystem | null) {
        if (this.soundSystemSubject.value) {
            if (soundSystem) {
                consolex.warn(`SoundSystem is going to be set twice to SystemsContainer.`);
            }
            await this.soundSystemSubject.value.destroy();
        }
        this.soundSystemSubject.next(soundSystem);
    }

    // ----
    public get storageSystem(): Promise<StorageSystem> {
        return this.storageSystemSubject.value
            ? Promise.resolve(this.storageSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.storageSystemSubject.subscribe((storageSystem) => {
                      if (storageSystem) {
                          subscription.unsubscribe();
                          resolve(storageSystem);
                      }
                  });
              });
    }

    public async setStorageSystem(storageSystem: StorageSystem | null) {
        if (this.storageSystemSubject.value) {
            if (storageSystem) {
                consolex.warn(`StorageSystem is going to be set twice to SystemsContainer.`);
            }
            await this.storageSystemSubject.value.destroy();
        }
        this.storageSystemSubject.next(storageSystem);
    }

    // ----
    public get styleSystem(): Promise<StyleSystem> {
        return this.styleSystemSubject.value
            ? Promise.resolve(this.styleSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.styleSystemSubject.subscribe((styleSystem) => {
                      if (styleSystem) {
                          subscription.unsubscribe();
                          resolve(styleSystem);
                      }
                  });
              });
    }

    public async setStyleSystem(styleSystem: StyleSystem | null) {
        if (this.styleSystemSubject.value) {
            if (styleSystem) {
                consolex.warn(`StyleSystem is going to be set twice to SystemsContainer.`);
            }
            await this.styleSystemSubject.value.destroy();
        }
        this.styleSystemSubject.next(styleSystem);
    }

    // ----
    public get testSystem(): Promise<TestSystem> {
        return this.testSystemSubject.value
            ? Promise.resolve(this.testSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.testSystemSubject.subscribe((testSystem) => {
                      if (testSystem) {
                          subscription.unsubscribe();
                          resolve(testSystem);
                      }
                  });
              });
    }

    public async setTestSystem(testSystem: TestSystem | null) {
        if (this.testSystemSubject.value) {
            if (testSystem) {
                consolex.warn(`TestSystem is going to be set twice to SystemsContainer.`);
            }
            await this.testSystemSubject.value.destroy();
        }
        this.testSystemSubject.next(testSystem);
    }

    // ----
    public get toolbarSystem(): Promise<ToolbarSystem> {
        return this.toolbarSystemSubject.value
            ? Promise.resolve(this.toolbarSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.toolbarSystemSubject.subscribe((toolbarSystem) => {
                      if (toolbarSystem) {
                          subscription.unsubscribe();
                          resolve(toolbarSystem);
                      }
                  });
              });
    }

    public async setToolbarSystem(toolbarSystem: ToolbarSystem | null) {
        if (this.toolbarSystemSubject.value) {
            if (toolbarSystem) {
                consolex.warn(`ToolbarSystem is going to be set twice to SystemsContainer.`);
            }
            await this.toolbarSystemSubject.value.destroy();
        }
        this.toolbarSystemSubject.next(toolbarSystem);
    }

    // ----
    public get translationsSystem(): Promise<TranslationsSystem> {
        return this.translationsSystemSubject.value
            ? Promise.resolve(this.translationsSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.translationsSystemSubject.subscribe((translationsSystem) => {
                      if (translationsSystem) {
                          subscription.unsubscribe();
                          resolve(translationsSystem);
                      }
                  });
              });
    }

    public async setTranslationsSystem(translationsSystem: TranslationsSystem | null) {
        if (this.translationsSystemSubject.value) {
            if (translationsSystem) {
                consolex.warn(`TranslationsSystem is going to be set twice to SystemsContainer.`);
            }
            await this.translationsSystemSubject.value.destroy();
        }
        this.translationsSystemSubject.next(translationsSystem);
    }

    // ----
    public get usercontentSystem(): Promise<UsercontentSystem> {
        return this.usercontentSystemSubject.value
            ? Promise.resolve(this.usercontentSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.usercontentSystemSubject.subscribe((usercontentSystem) => {
                      if (usercontentSystem) {
                          subscription.unsubscribe();
                          resolve(usercontentSystem);
                      }
                  });
              });
    }

    public async setUsercontentSystem(usercontentSystem: UsercontentSystem | null) {
        if (this.usercontentSystemSubject.value) {
            if (usercontentSystem) {
                consolex.warn(`UsercontentSystem is going to be set twice to SystemsContainer.`);
            }
            await this.usercontentSystemSubject.value.destroy();
        }
        this.usercontentSystemSubject.next(usercontentSystem);
    }

    // ----
    public get userInterfaceSystem(): Promise<UserInterfaceSystem> {
        return this.userInterfaceSystemSubject.value
            ? Promise.resolve(this.userInterfaceSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.userInterfaceSystemSubject.subscribe((userInterfaceSystem) => {
                      if (userInterfaceSystem) {
                          subscription.unsubscribe();
                          resolve(userInterfaceSystem);
                      }
                  });
              });
    }

    public async setUserInterfaceSystem(userInterfaceSystem: UserInterfaceSystem | null) {
        if (this.userInterfaceSystemSubject.value) {
            if (userInterfaceSystem) {
                consolex.warn(`UserInterfaceSystem is going to be set twice to SystemsContainer.`);
            }
            await this.userInterfaceSystemSubject.value.destroy();
        }
        this.userInterfaceSystemSubject.next(userInterfaceSystem);
    }

    // ----
    public get voiceSystem(): Promise<VoiceSystem> {
        return this.voiceSystemSubject.value
            ? Promise.resolve(this.voiceSystemSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.voiceSystemSubject.subscribe((voiceSystem) => {
                      if (voiceSystem) {
                          subscription.unsubscribe();
                          resolve(voiceSystem);
                      }
                  });
              });
    }

    public async setVoiceSystem(voiceSystem: VoiceSystem | null) {
        if (this.voiceSystemSubject.value) {
            if (voiceSystem) {
                consolex.warn(`VoiceSystem is going to be set twice to SystemsContainer.`);
            }
            await this.voiceSystemSubject.value.destroy();
        }
        this.voiceSystemSubject.next(voiceSystem);
    }

    // ----
    public get touchController(): Promise<TouchController> {
        return this.touchControllerSubject.value
            ? Promise.resolve(this.touchControllerSubject.value)
            : new Promise((resolve) => {
                  const subscription = this.touchControllerSubject.subscribe((touchController) => {
                      if (touchController) {
                          subscription.unsubscribe();
                          resolve(touchController);
                      }
                  });
              });
    }

    public async setTouchController(touchController: TouchController | null) {
        if (this.touchControllerSubject.value) {
            if (touchController) {
                consolex.warn(`TouchController is going to be set twice to SystemsContainer.`);
            }
            await this.touchControllerSubject.value.destroy();
        }
        this.touchControllerSubject.next(touchController);
    }

    // ----
    /* END OF GENERATED BY genetate-systems */
}

/**
 * TODO: [🏄] Rename ready to whenReady
 */
