import React from 'react';
import { AsyncButtonComponent } from '../../../30-components/utils/AsyncButtonComponent';
import { blobToDataurl } from '../../../40-utils/files/blobToDataurl';
import { useSystems } from '../../../40-utils/react-hooks/useSystems';
import { ExportScopeSimple } from '../../../50-systems/ExportSystem/interfaces/IExportScope';
import { Translate } from '../../../50-systems/TranslationsSystem/components/Translate';
import { consolex } from '../../../consolex';

export function CloneBoardButtonComponent() {
    // console.log('🅰️', 'CloneBoardButtonComponent', 'render');

    const {
        exportSystem,
        translationsSystem,
        materialArtVersioningSystem: { cornerstoneArts },
        boardSystem,
    } = useSystems('translationsSystem', 'materialArtVersioningSystem', 'exportSystem', 'boardSystem');

    return (
        <AsyncButtonComponent
            alt="Creating new board"
            className="button button-primary button-vertical"
            onClick={async () => {
                const cornerstoneArt = cornerstoneArts.value;

                const boardData = await exportSystem.exportFile({
                    // TODO: exportFile
                    scope: ExportScopeSimple.Board,
                    mimeType: 'text/html',
                    isNative:
                        true /* <- TODO: It is a bit inefficient to export whole html in situation when it is immediatelly parsed but only its native part */,
                });

                await boardSystem.createNewBoard({
                    logger: consolex,
                    boardname: `${translationsSystem.translate('CloneBoardButton / copy of', 'Kopie')} ${
                        cornerstoneArt.boardname
                    }`,
                    useTemplate: await blobToDataurl(boardData) /* <- Allow in useTemplate to recieve blob */,
                    modulesOn: cornerstoneArt.modulesActive?.filter((moduleName) => moduleName !== 'CloneBoardButton'),
                    modulesOff: cornerstoneArt.modulesInactive,
                    isNewBoardNavigated: true,
                    isPersistent: true,
                });
            }}
        >
            <Translate name="CloneBoardButtonModule / clone board button materialize">Vytvořit vlastní kopii</Translate>
        </AsyncButtonComponent>
    );
}
