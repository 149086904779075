import React from 'react';
import { Icon } from '../../30-components/menu/Icon';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeAttributeModule } from '../../50-systems/ModuleStore/makers/makeAttributeModule';
import { ITimerAndStopwatchControls } from './TimerControlsAttributeModule';

internalModules.declareModule(() =>
    makeAttributeModule<ITimerAndStopwatchControls>({
        manifest: {
            name: 'StopwatchControlsAttribute',

            contributors: [Authors.rosecky],
        },
        standard: true,
        attribute: {
            type: 'string',
            name: 'stopwatchControls',
            defaultValue: 'INITIAL',
        },
        inputRender(value: ITimerAndStopwatchControls, onChange: (value: ITimerAndStopwatchControls) => void) {
            // TODO: [🎱] This inputRender is rendered much more often than needed when observing arts, window resize, etc.
            // console.log('🅰️', 'StopwatchControlsAttribute', 'inputRender');

            return (
                <>
                    <Icon icon="run" active={value === 'RUNNING'} onClick={() => onChange('RUNNING')} />
                    <Icon icon="reset" onClick={() => onChange('STOPPED')} />
                </>
            );
        },
    }),
);

/**
 * Note: In future this file will we in independent repository as external module.
 */
