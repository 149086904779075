import React from 'react';
import { BehaviorSubject, Observable } from 'rxjs';
import { Transform } from 'xyzt';
import { randomTag } from '../40-utils/randomTag';
import { useObservable } from '../40-utils/react-hooks/useObservable';
import { UnsignedSystemsContainerContext, useSystemsInCore } from '../40-utils/react-hooks/useSystemsInCore';
import { ArtShell } from '../50-systems/CollSpace/utils/ArtShell';
import { AbstractPlacedArt } from '../71-arts/25-AbstractPlacedArt';
import { consolex } from '../consolex';
import { AsyncContentComponent } from './utils/AsyncContentComponent';

interface IBoardComponentArtsProps {
    artsPlaced: Observable<Array<AbstractPlacedArt>>;

    /**
     * Transform of the user`s view on the board
     */
    transform: BehaviorSubject<Transform>;
}

export function BoardComponentArts(props: IBoardComponentArtsProps) {
    const { artsPlaced, transform } = props;

    const { appState } = useSystemsInCore('appState');
    const unsignedSystemsContainerContext = React.useContext(
        /* <- TODO: Import and use just a useContext */ UnsignedSystemsContainerContext,
    );
    const { value: artsPlacedValue } = useObservable(artsPlaced);

    if (!artsPlacedValue) {
        return <></>;
    }

    return (
        <>
            {artsPlacedValue
                .map((art) => {
                    try {
                        const content = art.render(
                            // TODO: [🍒] Putting systems here makes no sence because they can be used by useSystems hook in the art component.
                            //       [🍒] Same with isSelected - most of the arts do not need it AND for rest there should be some kind of selection system which returns BehaviorSubject which tells if art is selected <- implemented here [🌌]

                            appState.selected.value.includes(art) &&
                                appState.selection.value !==
                                    null /* <- TODO: (But not here [🍒]) This is not observed BUT it probbably should be */,
                            unsignedSystemsContainerContext /* <- TODO: (But not here [🍒]) This is a bit security concern, here should be systems signed and permission-limited for art module */,
                        );

                        if (content instanceof Promise) {
                            return {
                                art,
                                content: <AsyncContentComponent alt="Rendered art" loader={<></>} content={content} />,
                            };
                        }

                        return { art, content };
                    } catch (error) {
                        // TODO: Also handle async errors

                        const tag = randomTag();

                        return {
                            art,
                            content: (
                                <div
                                    style={{
                                        backgroundColor: 'rgba(255,0,0,0.3',
                                        padding: 5,
                                        transform: 'translate(-50%,-50%)',
                                    }}
                                    onClick={() => {
                                        consolex.info(tag, { error, art });
                                    }}
                                >
                                    {/* [💇‍♂️] Some component for rendering error states */}
                                    <span /*style={{ color: 'red' }}*/>Something went wrong.</span>
                                    <br />
                                    {tag} More in the console.
                                    {/*<br />
                                <i>(You can click on this message to show it again in the console)</i>*/}
                                </div>
                            ),
                        };
                    }
                })
                .map(({ art, content }) => (
                    <ArtShell
                        key={art.artId}
                        artId={art.artId}
                        zIndex={(art.locked ? 0 : 1) + art.defaultZIndex}
                        {...{ transform }}
                        opacity={art.opacity}
                    >
                        {content}
                    </ArtShell>
                ))}
        </>
    );
}

/**
 * TODO: [📕] Unite ArtShell
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 * TODO: [🏊‍♂️] Better naming and distinction of Collboard-specific components vs utils components
 */
