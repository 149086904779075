import React from 'react';

interface ISelfUpdateContentComponentProps {
    /**
     * The async content to be rendered
     */
    content: (rerender: () => void) => JSX.Element;
}

/**
 * Utility for mounting self-updating content
 *
 * @deprecated [📠] Use hook instead
 * @collboard-modules-sdk
 */
export function SelfUpdateContentComponent({ content }: ISelfUpdateContentComponentProps) {
    const [version, setVersion] = React.useState(/* <- TODO: Import and use just a useState */ 0);

    return (
        <>
            {content(() => {
                setVersion(version + 1);
            })}
        </>
    );
}

/**
 * Note: Maybe this behavior can be achieved more elegantly some React API/hook - if yes please do it!
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
