import { randomEmoji } from './randomEmoji';

/**
 *
 * Pick random tag char like "[🍆]", "[🍡]", "[🍤]"...
 *
 * @collboard-modules-sdk
 */
export function randomTag(): string {
    return `[${randomEmoji()}]`;
}
