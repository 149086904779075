/**
 * Adds a white background to an canvas image and returns it as the new canvas
 *
 * @collboard-modules-sdk
 */
export function addWhiteBackground(canvas: HTMLCanvasElement): HTMLCanvasElement {
    const canvasWithBackground = document.createElement('canvas');
    canvasWithBackground.width = canvas.width;
    canvasWithBackground.height = canvas.height;
    const ctx = canvasWithBackground.getContext('2d')!;

    ctx.beginPath();
    ctx.rect(0, 0, canvasWithBackground.width, canvasWithBackground.height);
    ctx.fillStyle = '#ffffff';
    ctx.fill();
    ctx.drawImage(canvas, 0, 0);

    return canvasWithBackground;
}

/**
 * TODO: Allow to pick a background (color)
 */
