import React from 'react';
import { useSkin } from '../40-utils/react-hooks/useSkin';
import './animations.css';
import { BoardStyles } from './board';
import { CommonStyles } from './common';
import './icons.css';
import './index.css';

export function GlobalStyles() {
    return (
        <>
            <BoardStyles {...useSkin()} />
            <CommonStyles {...useSkin()} />
        </>
    );
}

/**
 * TODO: [Optimization][InitialLoading] Optimize this for initial loading
 */
