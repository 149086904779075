import { string_file_extension, string_host, string_module_name, string_protocol, string_uri, string_uri_part } from '../../../40-utils/typeAliases';
import { BusinessName } from '../../BusinessSystem/configuration/BusinessName';

/**
 * Matches a Collboard uri adress
 *
 * For examples of valid/invalid uris:
 *     @see https://regex101.com/r/irJH7h/1
 *     @see /src/50-systems/RoutingSystem/routePath/decodeUrl.test.ts
 *
 * @collboard-modules-sdk
 */
export const URI_PATTERN =
    /^\/(?<uriId>[a-zA-Z0-9_-]*)(\.(?<extension>[a-z0-9]+))?((\/(?<moduleScope>@[a-zA-Z0-9_-]+))?\/(?<moduleName>[/a-zA-Z0-p_-]+)(\/~(?<modulePath>[/@a-zA-Z0-9_-]*))?)?$/;

/**
 * Represents URL in parsed from
 *
 * @collboard-modules-sdk
 */
export interface IUrlVariables {
    protocol: string_protocol;
    host: string_host;
    businessName: BusinessName;
    uriId: string_uri_part | null;
    extension?: string_file_extension | null;
    moduleName?: string_module_name | null;
    modulePath?: string_uri | null;

    /**
     * Note: Only for everstorage library
     */
    [key: string]: string | null | undefined;
}

/**
 * Defaults for IUrlVariables
 *
 * @collboard-modules-sdk
 */
export const DEFAULT_URL_VARIABLES: IUrlVariables = {
    // Note: There is important to enumerate all keys from IUrlVariables
    protocol: 'https:',
    host: 'collboard.com',
    businessName: BusinessName.Education,
    uriId: null,
    extension: undefined,
    moduleName: undefined,
    modulePath: undefined,
};

/**
 * TODO: Do more constraints on IUrlVariables:
 *       - modulePath can not be defined if module is undefined
 *       - ...
 */
