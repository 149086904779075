import React from 'react';
import { useSystemsInCore } from '../40-utils/react-hooks/useSystemsInCore';
import { NotificationsComponent } from '../50-systems/NotificationSystem/components/notifications/NotificationsComponent';
import { NotificationPlace } from '../50-systems/NotificationSystem/INotification';
import { UserInterfaceElementPlace } from '../50-systems/UserInterfaceSystem/UserInterfaceElementPlace';
import { BoardComponent } from './BoardComponent';
import { MenuWrapper } from './menu/MenuWrapper';
import { UploadOverlayWrapper } from './utils/UploadOverlayWrapper';

export function RootComponentForBoard() {
    const { importSystem, collSpace, userInterfaceSystem } = useSystemsInCore(
        'importSystem',
        'collSpace',
        'userInterfaceSystem',
    );

    return (
        <>
            <div className="page-is-loaded" />

            {/* TODO: UploadOverlayWrapper here can be integrated with UserInterfaceSystem */}
            <UploadOverlayWrapper {...{ importSystem, collSpace }}>
                <BoardComponent />
            </UploadOverlayWrapper>
            <MenuWrapper position="right">
                {userInterfaceSystem.render(UserInterfaceElementPlace.EdgeRight)}
            </MenuWrapper>
            <MenuWrapper position="top-ltr">
                {/* [👩‍👩‍👦] */}
                {userInterfaceSystem.render(UserInterfaceElementPlace.EdgeTop)}
            </MenuWrapper>
            <MenuWrapper position="bottom-ltr">
                {userInterfaceSystem.render(UserInterfaceElementPlace.EdgeBottom)}
            </MenuWrapper>
            <NotificationsComponent place={NotificationPlace.Board} />
            {userInterfaceSystem.render(UserInterfaceElementPlace.RootComponent)}
            {userInterfaceSystem.render(UserInterfaceElementPlace.RootComponentForBoard)}
        </>
    );
}

/**
 * TODO: [☮️] Remove all initial <Loader/> and <LoaderInline/>
 * TODO: [0][👩‍👩‍👦] It is a bit inconsistent that some parts are wrapped with <Menu (EdgeTop,Social) and others not
 * TODO: [0][👩‍👩‍👦] It is a bit inconsistent that some parts are only managed by userInterfaceSystem and others by userInterfaceSystem+toolbarSystem
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 * TODO: [🏊‍♂️] Better naming and distinction of Collboard-specific components vs utils components
 */
