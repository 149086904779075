import { DetailedError } from '../../../40-utils/errors/DetailedError';
import { ISerializationErrorDetails } from './10-SerializationError';

/**
 * @@x
 */
interface IArtSerializationErrorDetails extends ISerializationErrorDetails {
    /**
     * @@x
     */
    errors?: Array<Error>;
}

/**
 * @@x
 */
export class ArtSerializationError extends DetailedError<IArtSerializationErrorDetails> {
    public readonly name = 'ArtSerializationError';

    public constructor(message: string, details: IArtSerializationErrorDetails) {
        super(message, details);
        Object.setPrototypeOf(this, ArtSerializationError.prototype);
    }
}
