/**
 * @deprecated [🍿] Do not use enums but object as const OR 'LITERAL VALUES' instead
 */
export enum ToolTimerType {
    Timer,
    Stopwatch,
}

/**
 * Note: In future this file will we in independent repository as external module.
 */
