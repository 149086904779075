import spaceTrim from 'spacetrim';
import { string_css, string_html } from '../../typeAliases';

interface IInsertStyleIntoHtmlOptions {
    html: string_html;
    css: string_css;
}

/**
 * Insert style into html.
 *  - If there is <html> structure it inserts into <head>
 *  - If there is <svg> structure it inserts before </svg>
 *  - If there is no <html> structure it inserts at the end of the document
 *
 * @collboard-modules-sdk
 */
export function insertStyleIntoHtml({ html, css }: IInsertStyleIntoHtmlOptions): string_html {
    const styleElement = spaceTrim(
        (block) => `
          <style>
            ${block(css)}
          </style>
        `,
    );

    const htmlWithStyle = html.replace(/<\/head>/, `${styleElement}\n</head>`);

    if (htmlWithStyle !== html) {
        return htmlWithStyle;
    }

    const htmlWithStyleSvg = html.replace(/<\/svg>/, `${styleElement}\n</svg>`);

    if (htmlWithStyleSvg !== html) {
        return htmlWithStyleSvg;
    }

    return `${html}\n${styleElement}`;
}

/**
 * TODO: [🎞️] Probbably work with XMLs in some object-based method
 */
