/**
 * Split an array into subarrays using the specified separator and return them as an array of arrays.
 *
 * @param array An array that will be splitted in chunks
 * @param splitter An item that can split a array.
 * @param limit A value used to limit the number of elements returned in the array.
 *
 * @collboard-modules-sdk
 */
export function splitArray<TItem>(array: Array<TItem>, splitter: TItem, limit?: number): Array<Array<TItem>> {
    const chunks: Array<Array<TItem>> = [];
    let currentChunk: Array<TItem> = [];

    chunks.push(currentChunk);

    for (const item of array) {
        if (item === splitter && chunks.length < (limit || Infinity)) {
            currentChunk = [];
            chunks.push(currentChunk);
        } else {
            currentChunk.push(item);
        }
    }
    return chunks;
}
