import React from 'react';
import { internalModules } from '../../ModuleStore/internalModules';
import { makeModalModule } from '../../ModuleStore/makers/makeModalModule';
import { DownloadPreviewComponent } from '../components/DownloadPreviewComponent';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            name: '@collboard/internal/download',
            deprecatedNames: '@collboard/download',
            title: { en: 'Download', cs: 'Stáhnout' },
            requirePermissions: ['view'],
        },
        async createModal(systems) {
            await systems.request('exportSystem', 'translationsSystem', 'appState');

            return <DownloadPreviewComponent />;
        },
    }),
);

/**
 * TODO: [🐅] Maybe some more elegant way how to create icon-window pairs of modules
 * TODO: Allow to search for new modules
 * TODO: [🍩][🧠] Requesting systems non-magically
 * TODO: [🍩] Omitting systems.request should fail bacause of unrequested systems.
 */
