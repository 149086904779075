import { createGlobalStyle } from 'styled-components';
import { Color } from '../40-utils/color/Color';
import { ISkin } from '../50-systems/StyleSystem/ISkin';

export const CommonStyles = createGlobalStyle<{ skin: ISkin }>`

input.stealth {
    /* TODO: [0] Probbably make some component <InputComponent isStealth isWide using internally styled-components */
    background: none;
    border: none;
    padding: 0;
    color: inherit;
}

input.wide {
    /* TODO: [0] */
    width: 300px;
}

/*
TODO:
.btn {
    outline: none !important;
}
*/

/* Custom colors */
.btn-primary,
.btn-primary:focus,
.btn-primary:active {
    color: ${({ skin }) => Color.from(Color.from(skin.colors.white)).opaque.toString()} !important;
    background-color: ${({ skin }) => Color.from(Color.from(skin.colors.primary)).opaque.toString()} !important;
    border: 2px solid ${({ skin }) => Color.from(Color.from(skin.colors.primary)).opaque.toString()} !important;
}
.btn-primary:hover {
    color: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()} !important;
    background-color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()} !important;
}
.bg-primary {
    background-color: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()} !important;
}
.bg-secondary {
    background-color: ${({ skin }) =>
        Color.from(skin.colors.primary).addLightness(skin.darken).opaque.toString()} !important;
}

.btn-add,
.btn-add:hover,
.btn-add:focus,
.btn-add:active {
    /* TODO: @roseckyj make it hover as primary*/
    color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()} !important;
    background-color: ${({ skin }) => Color.from(skin.colors.success).opaque.toString()} !important;
    border: 0.05rem solid ${({ skin }) => Color.from(skin.colors.success).opaque.toString()} !important;
}

.btn-remove,
.btn-remove:hover,
.btn-remove:focus,
.btn-remove:active {
    /* TODO: @roseckyj make it hover as primary*/
    color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()} !important;
    background-color: ${({ skin }) => Color.from(skin.colors.danger).opaque.toString()} !important;
    border: 0.05rem solid ${({ skin }) => Color.from(skin.colors.danger).opaque.toString()} !important;
}

.btn-disabled,
.btn-disabled:hover,
.btn-disabled:focus,
.btn-disabled:active {
    color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()} !important;
    background-color: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()} !important;
    border: 0.05rem solid ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()} !important;
    opacity: 0.5;
}

a {
    color: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
}

/* Scrollbars */

/* Mozilla */
* {
    scrollbar-width: thin;
    scrollbar-color: #83838370 #81818130;
}

/* Webkit */
*::-webkit-scrollbar {
    width: ${({ skin }) => (skin.circles ? '10px' : '0px')};
}

*::-webkit-scrollbar-track {
    background-color: ${({ skin }) => Color.from(skin.colors.light).toString()};
    border-radius: ${({ skin }) => (skin.circles ? '10px' : '0px')};
}

*::-webkit-scrollbar-thumb {
    background-color: ${({ skin }) => Color.from(skin.colors.dark).withAlpha(0.2).toString()};
    border-right: solid 2px ${({ skin }) => Color.from(skin.colors.light).toString()};
    border-radius: ${({ skin }) => (skin.circles ? '10px' : '0px')};
}

.block {
  background: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
  border-radius: ${({ skin }) => skin.borderRadius}px;
  padding: 2px;
  color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
}
`;

/**
 * TODO: [🗾] Get rid of majority of theese styles - scope them OR move to each modules
 */
