import React from 'react';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { Abstract2dBoxArt } from './27-Abstract2dBoxArt';

internalModules.declareModule(() => makeArtModule(HTMLArt));

//TODO: Probably rename to HtmlArt - we need to fugure out consistent namings of acronyms in CamelCasingLolVsWTF

/**
 * Art rendering a given HTML content
 *
 * **Warning: HTML is dangerously set inside a div element, please sanitize the
 * content of any malicious scripts before creating art!**
 *
 * @collboard-modules-sdk
 */
export class HTMLArt extends Abstract2dBoxArt {
    public static serializeName = 'HTML';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/html-art',
        deprecatedNames: '@collboard/html-art',
    };

    /**
     * @param content HTML content as string
     * @param scale rescale after measuring
     */
    public constructor(public content: string, scale: number = 1) {
        super(null, scale);
    }

    public defaultZIndex = 1;

    public renderBox() {
        if (this.measured) {
            return (
                <div
                    style={{
                        pointerEvents: 'none',
                        transform:
                            'scale(' +
                            (this.size.x || 0) / this.originalSize.x +
                            ', ' +
                            (this.size.y || 0) / this.originalSize.y +
                            ')',
                        transformOrigin: '0% 0%',
                        width: this.originalSize.x,
                        height: this.originalSize.y,
                    }}
                    // TODO: Use here <InnerHtml component
                    dangerouslySetInnerHTML={{ __html: this.content }}
                />
            );
        } else {
            return (
                <div
                    style={{
                        position: 'absolute',
                    }}
                    ref={(element) => {
                        if (!element) {
                            return;
                        }
                        // TODO: ref is blinking it is made by async calls ( maybe do it with dangerouslySetInnerHTML )
                        element.innerHTML = this.content;
                        this.measure(element);
                    }}
                />
            );
        }
    }
}

/**
 * TODO: [🎚️] Implement IArt
 */
