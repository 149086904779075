import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';

/**
 * PointerSystem allows us to bind a different controller then touch or mouse. For example, it is syncing the phone with accelerometer or VR controllers.
 *
 * @private
 * @collboard-system
 */
export class PointerSystem extends AbstractSystem {
    /*
        TODO: This class is a boilerplate of the system that we have not started working on yet.
        @see https://github.com/collboard/collboard/issues/90
    */

    protected async init() {}
}

/**
 * TODO: To LIB touchcontroller
 */
