import React from 'react';
import { Icon } from '../30-components/menu/Icon';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeAttributeModule } from '../50-systems/ModuleStore/makers/makeAttributeModule';

internalModules.declareModule(() =>
    makeAttributeModule({
        manifest: {
            // Note: This art module is not auto-activated on initialization because when it appears on the board or in some toolbar, it will be auto-activated afterward.
            name: 'ListStyleAttribute',

            contributors: [Authors.rosecky, Authors.hejny],
        },
        standard: true,
        attribute: {
            type: 'string',
            name: 'listStyle',
            defaultValue: 'none',
            // TODO: Pattern or enums
        },
        inputRender(value: string, onChange: (value: string) => void) {
            return (
                <>
                    <Icon
                        icon="numbers"
                        active={value === 'ordered'}
                        onClick={() => onChange(value === 'ordered' ? 'none' : 'ordered')}
                    />
                    <Icon
                        icon="list"
                        active={value === 'unordered'}
                        onClick={() => onChange(value === 'unordered' ? 'none' : 'unordered')}
                    />
                </>
            );
        },
    }),
);
