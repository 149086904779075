import React from 'react';
import { internalModules } from '../../../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../../../50-systems/ModuleStore/makers/makeModalModule';
import { HtmlPreviewComponent } from './HtmlPreviewComponent';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            // TODO: This module should be obsolete because it should work via extension>
            //       -  NO: http://localhost:9977/s4dsqgp2dc3kl3bglbol/html <- this module
            //       - YES: http://localhost:9977/s4dsqgp2dc3kl3bglbol.html
            //       + In download-preview module the format (and frame) should be persisted in URL
            name: '@collboard/internal/html' /* <- TODO: Is better '@collboard/internal/html' OR '@collboard/internal/preview-html' */,
            title: { en: 'HTML preview', cs: 'HTML náhled' },
            flags: {
                isHidden: true /* <- TODO: Modal modules should be always hidden*/,
            },
            requirePermissions: ['view'],
        },
        async createModal(systems) {
            await systems.request('exportSystem', 'translationsSystem', 'appState');

            // TODO: Wait for arts to be loaded
            // TODO: Observe arts + frames

            return {
                wide: true,
                content: <HtmlPreviewComponent />,
            };
        },
    }),
);

/**
 * TODO: [🍩][🧠] Requesting systems non-magically
 * TODO: [🍩] Omitting systems.request should fail bacause of unrequested systems.
 */
