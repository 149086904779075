import { consolex } from '../consolex';
import { blobToDataurl } from './files/blobToDataurl';
import { fetchAsFile } from './files/fetchAsFile';
import { string_url } from './typeAliases';

/**
 * Go through all the sources and replace them with inlined downloaded version
 * For example:
 *    From: <img src="https://collboard.fra1.cdn.digitaloceanspaces.com/assets/21.0.3/languages/cs.svg" />
 *    To: <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMDAiIGhlaWdodD0iNjAwIj4KCTxyZWN0IHdpZHRoPSIxMDAwIiBoZWlnaHQ9IjYwMCIgZmlsbD0iI2Q3MTQxYSIvPgoJPHJlY3Qgd2lkdGg9IjEwMDAiIGhlaWdodD0iMzAwIiBmaWxsPSIjZmZmIi8+Cgk8cGF0aCBkPSJNIDUwMCwzMDAgMCwwIHYgNjAwIHoiIGZpbGw9IiMxMTQ1N2UiLz4KPC9zdmc+" />
 *
 * @param html HTML string in which to search for sources.
 */
export async function materializeSources(html: string, proxyUrl?: URL | string_url): Promise<string> {
    const images = html.matchAll(/<(img|image|link|script)[^>]+(src|href)="(?<src>[^"]+)"/g);

    if (!images) {
        return html;
    }

    for (const image of Array.from(images)) {
        const src = image.groups!.src;

        consolex.info(`🌒 Materializing ${src}`);

        html = html.replace(src, await blobToDataurl(await fetchAsFile(src, proxyUrl)));
        // [♍] html = html.replace(src, ObjectUrl.fromBlob(await fetchAsFile(src, proxyUrl)).src);
    }

    return html;
}

/**
 * TODO: Probably materialize <link rel="stylesheet" href=" and <script src=" more efficiently into <style> and <script> tags
 * Note: [♍] Not using ObjectUrl because it causes image tainting
 *       NOT-TODO: [LEAK] Destroy created ObjectUrls
 * TODO: Exclude already inlined images
 * TODO: What if there are more images with same src
 */
