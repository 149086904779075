import { routingSystem } from '../../RoutingSystem/0-RoutingSystem';
import { isElementEditable } from './isElementEditable';

/**
 * To ignore event (like keypress) because its target is not on board (but for example on input element).
 * TODO: Probably in universal utils not just ControlSystems utils
 *
 * @collboard-modules-sdk
 */
export function isEventOnBoard(event: Event): boolean {
    const path = Array.from(event.composedPath());
    const deepestNode = path[0]! as Element;

    if (isElementEditable(deepestNode, { input: true, contentEditable: true })) {
        return false;
    }

    if (routingSystem.urlVariables.value.moduleName) {
        return false;
    }

    if (deepestNode.tagName === 'BODY') {
        return true;
    }

    for (const node of path) {
        if (
            (node as Element)?.classList?.contains(
                `board-container` /* TODO: Some better way how to identify board wrapper container */,
            )
        ) {
            return true;
        }
    }

    return false;
}

/**
 * TODO: [Optimization][InitialLoading] Optimize this for initial loading
 */
