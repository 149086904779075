import { extension } from 'mime-types';
import { findPropperFileType } from './findPropperFileType';

/**
 * Amend propper file type to given file or blob
 * If you give a blob there will be also added provisional file name
 *
 * Note: This function is idempotent
 *
 * @param file File or Blob which will be analyzed
 * @returns File with propper type
 *
 * @collboard-modules-sdk
 */
export async function amendPropperFileTypeAndName(file: Blob | File): Promise<File> {
    const type = await findPropperFileType(file);

    const fileWithType = new File([file], file instanceof File ? file.name : `untitled.${extension(type) || 'dat'}`, {
        type,
    });
    return fileWithType;
}
