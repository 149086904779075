import { errorMessageWithAdditional } from '../../../40-utils/errors/errorMessageWithAdditional';
import { EMPTY_APPEARANCE, EMPTY_MATERIAL } from './defaults';
import { IAppearance, IFullAdvancedAppearance } from './IAppearance';
import { isIAdvancedAppearance, isIFullAdvancedAppearance } from './IAppearance.guard';
import { isIMaterial } from './IMaterial.guard';

/**
 * Converts appearance in any form to the advanced one
 *
 * @param appearance in any form (simple one material, advanced material)
 * @returns IFullAdvancedAppearance
 *
 * @collboard-modules-sdk
 */
export function getFullAdvancedAppearance(appearance: IAppearance): IFullAdvancedAppearance {
    if (appearance === undefined || appearance === null) {
        return getFullAdvancedAppearance(EMPTY_APPEARANCE);
    } else if (isIFullAdvancedAppearance(appearance)) {
        return appearance;
    } else if (isIAdvancedAppearance(appearance)) {
        const { default: defaultMbterial, spot, edge, fill } = appearance;
        return {
            spot: spot || defaultMbterial || EMPTY_MATERIAL,
            edge: edge || defaultMbterial || EMPTY_MATERIAL,
            fill: fill || defaultMbterial || EMPTY_MATERIAL,
        };
    } else if (isIMaterial(appearance)) {
        return {
            spot: appearance,
            edge: appearance,
            fill: appearance,
        };
    }

    throw new Error(errorMessageWithAdditional(`Can not get full advanced appearance`, { appearance })); /* <- [🍚] */
}
