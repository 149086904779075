import React from 'react';
import { string_translate_name_not_normalized } from '../../../40-utils/typeAliases';
import { TranslateContext } from './TranslateContext';

/**
 * ....
 * @collboard-modules-sdk
 */
export function Translate(
    props: React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{
        name: string_translate_name_not_normalized; // TODO: It is a bit confising name vs nameNN
        /* TODO: [✨] Maybe add is prefix */ html?: boolean;
        isNonBreakSpaced?: true;
        parameters?: any;
    }>,
): JSX.Element {
    const translateSystem = React.useContext(/* <- TODO: Import and use just a useContext */ TranslateContext);

    if (!translateSystem) {
        throw new Error(`Using <Translate/> outside of TranslateContext`);
    }

    return <translateSystem.Translate {...props} />;
}

/**
 * TODO: Translations in (external) modules
 */
