import { Registration } from 'destroyable';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { isEventOnBoard } from '../../../50-systems/ControlSystem/utils/isEventOnBoard';
import { ExportScopeSimple } from '../../../50-systems/ExportSystem/interfaces/IExportScope';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { consolex } from '../../../consolex';

internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/clipboard-copy-and-cut-shortcut',
        deprecatedNames: ['@collboard/clipboard-copy-and-cut-shortcut', 'ClipboardCopyAndCutShortcut'],
        flags: {
            isHidden: true,
        },
        requirePermissions: ['view'],
    },
    async setup(systems) {
        const { exportSystem, appState, materialArtVersioningSystem } = await systems.request(
            'exportSystem',
            'appState',
            'materialArtVersioningSystem',
        );
        /**
         * Note: This is just a hack that we (@hejny and @roseckyj) discussed
         *       We cannot setData of cliboard after some async waiting, so we are
         *       proactivly creating this content when user change selection or arts inside selection
         *       (creating after presssing Ctrl is not enough and sometimes it cannot made before Ctrt+C)
         */
        let contentForClipboard: Array<{ type: string; content: string }> | null = null;

        return Registration.join(
            Registration.fromSubscription(() =>
                appState.selected.pipe(debounce(() => interval(30))).subscribe(async () => {
                    // Note: [🐦] This is done everytime selection changes, it is not ideal but necessary for compatibility reason
                    //       Because content for Ctrl+C clipboard can not be provided asynchronously

                    contentForClipboard = null;
                    // consolex.info(`Preparing for copy clipboard.`);
                    const files = await exportSystem.exportFiles({
                        scope: ExportScopeSimple.Selection,
                        mimeType: '*',
                        isHeavyExport: false,
                        isMaterialized: false,
                        isTesting: false,
                        isLinked: false,
                        isNative: true,
                    });
                    if (!files) {
                        contentForClipboard = null;
                        return;
                    }

                    contentForClipboard = [];
                    for (const file of files) {
                        contentForClipboard.push({ type: file.type, content: await file.text() });
                    }

                    /*consolex.info(
                        `Prepared for copy ${contentForClipboard
                            .map(({ type }) => type)
                            .join(', ')} into the clipboard.`,
                    );*/
                }),
            ),
            Registration.createEventListener({
                element: window.document,
                type: 'copy',
                listener: (event: ClipboardEvent) => {
                    // TODO: DRY copy and cut listener

                    if (!isEventOnBoard(event)) {
                        return;
                    }

                    event.preventDefault();

                    if (!contentForClipboard) {
                        consolex.warn(`There is nothing prepaired to copy into the clipboard.`);
                        // @hejny -> @roseckyj Co myslíš, že by se mělo stát, nic nebo udělat screen toho, co je vidět?
                        return;
                    } else {
                        consolex.info(
                            `Copy ${contentForClipboard.map(({ type }) => type).join(', ')} into the clipboard.`,
                        );
                    }

                    for (const { type, content } of contentForClipboard) {
                        event.clipboardData?.setData(type, content);
                    }
                },
            }),
            Registration.createEventListener({
                element: window.document,
                type: 'cut',
                listener: (event: ClipboardEvent) => {
                    // TODO: DRY copy and cut listener

                    if (!isEventOnBoard(event)) {
                        return;
                    }

                    event.preventDefault();

                    if (!contentForClipboard) {
                        consolex.warn(`There is nothing prepaired to cut into the clipboard.`);
                        return;
                    } else {
                        consolex.info(
                            `Cut ${contentForClipboard.map(({ type }) => type).join(', ')} into the clipboard.`,
                        );
                    }

                    for (const { type, content } of contentForClipboard) {
                        event.clipboardData?.setData(type, content);
                    }

                    // TODO: DRY with DeleteShortcut
                    materialArtVersioningSystem
                        .createOperation('Cut from board')
                        .takeArts(
                            ...materialArtVersioningSystem.artsPlaced.filter((art) =>
                                appState.selected.value.includes(art),
                            ),
                        )
                        .delete();

                    // TODO: When canceling selection after some operation, make this selection again if this operation undone
                    appState.cancelSelection();
                },
            }),
        );

        /*
        TODO: [🐦] We have compatibility trouble with ClipboardItem so we have used ClipboardEvent
              Look at this in future and decide if we dont want go back to ClipboardItem
              @see https://www.pavolhejny.com/clipboardevent-vs-clipboarditem/

        return controlSystem.registerControl(['Control', 'c'], () => copy(exportSystem));
        */
    },
}));

/**
 * TODO: [🏰] Split between copy and cut and split requirePermissions
 * TODO: !! Make this module - where this refactoring all beging
 */
/*
TODO: [🐦] We have compatibility trouble with ClipboardItem so we have used ClipboardEvent
      Look at this in future and decide if we dont want go back to ClipboardItem
      @see https://www.pavolhejny.com/clipboardevent-vs-clipboarditem/


export async function copy(exportSystem: ExportSystem): Promise<void> {
    const files = await exportSystem.exportFiles({ scope: 'SELECTION' });
    if (!files) {
        consolex.warn(`There is nothing selected to copy into the clipboard.`);
        return;
    }
    const clipboardData = filesToClipboardData(files);

    // TODO: !! What about DOMException: Support for multiple ClipboardItems is not implemented.
    await (navigator.clipboard as any).write(/* TODO: Do we need fallback to writeText * / clipboardData);
}
*/
