import { Promisable } from 'type-fest';
import { Factory } from '../IFactory';
import { errorMessageWithAdditional } from './errorMessageWithAdditional';

/**
 * This util catches an error and rethrows more meaningfull error message to better debugging
 * TODO: More oprions how to handle and rewrap the error - for example by a function
 *
 * @deprecated this is used only in one place internally and will be removed in next major version
 * @collboard-modules-sdk
 */
export async function meaningfullError<TValue>(valueFactory: Factory<Promisable<TValue>>, errorMessage: string) {
    try {
        return await valueFactory();
    } catch (originalError) {
        throw new Error(errorMessageWithAdditional(errorMessage, { originalError, valueFactory }));
    }
}

/**
 * TODO: Remove this unused utility
 */
