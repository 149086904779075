/**
 * @collboard-modules-sdk
 */
export class Additional<TKey, TValue> {
    private lib: Array<{ key: TKey; value: TValue }>;

    public constructor(keys: Array<TKey>, valueCallback: (key: TKey) => TValue) {
        this.lib = keys.map((key) => ({
            key,
            value: valueCallback(key),
        }));
    }

    public get(key: TKey): TValue {
        const item = this.lib.find(({ key: key2 }) => key2 === key);
        if (!item) {
            throw new Error(`Cannot find key in Additional library.`);
        }
        return item.value;
    }
}
