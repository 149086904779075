import { errorMessageWithAdditional } from '../../../40-utils/errors/errorMessageWithAdditional';
import { SystemName } from '../../00-SystemsContainer/ISystems';
import { IModuleSignature } from '../../ArtVersionSystem/IModuleSignature';
import { createSchemas } from '../schemas/schemas';
import { messageEventToModuleScope } from './messageEventToModuleScope';

export interface IParseMessageEventResult {
    requestId?: string | number;
    moduleSignature: IModuleSignature;
    systemName: SystemName;
    actionName: string /* <- TODO: [🐳] Use propper type */;
    options: any;
}

export function parseMessageEvent(event: MessageEvent): IParseMessageEventResult {
    const message = event.data;
    const schema = createSchemas().getSchema<any>('https://schema.collboard.com/request')!;
    const isValid = schema(message);

    if (!isValid) {
        throw new Error(
            errorMessageWithAdditional(
                `Recieved message from postMessage is not valid\n\n` +
                    schema.errors?.map(({ message: errorMessage }) => ' - ' + errorMessage).join('\n'),
                {
                    validationErrors: schema.errors,
                    event,
                    message,
                    schema,
                },
            ),
        );
    }

    const { systemName, actionName, requestId } = message;

    const options = { ...message };

    delete options.requestId;
    delete options.systemName;
    delete options.actionName;

    return {
        requestId,
        systemName,
        actionName,
        options,
        moduleSignature: {
            name: `@${messageEventToModuleScope(
                event,
            )}/post-message` /* <- TODO: [🍃] What is the best template name */,
        },
    };
}

/**
 * TODO: [Optimization][InitialLoading]  Break into the forMoment(s)
 *       Double-check that changes are working
 * TODO: Maybe use compileAsync
 * TODO: [👨‍👨‍👦‍👦] What is the right version to use for the schema? Currently 2020-12
 *       Double-check that changes are working
 */
