import { Registration } from 'destroyable';
import { factor, Factorable } from '../../../40-utils/IFactory';
import { consolex } from '../../../consolex';
import { ISystems } from '../../00-SystemsContainer/ISystems';
import { IModule, IModuleDefinition } from '../interfaces/IModule';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
/**
 * Takes an array of of modules (or just a setup functions) and create a composition module from it.
 * This is usefull if you want to use some maker with some custom functionality
 * Manifest is either given or it will be taken from the first non-anonymous module or returned module will be anonymous
 * @returns a factory (not definition) which will dynamically makes multimodule again and again because only this can ensure one run per one instance policy.
 *
 * @collboard-modules-sdk
 */
export function makeMultiModule(
    modulesOrProtomodule:
        | {
              manifest?: IModuleManifest;
              modules: Factorable<Array<IModule>>;
          }
        | Factorable<Array<IModule>>,
): IModuleDefinition {
    let protomodule: {
        manifest?: IModuleManifest;
        modules: Factorable<Array<IModule>>;
    };

    if (Array.isArray(modulesOrProtomodule) || typeof modulesOrProtomodule === 'function') {
        protomodule = { modules: modulesOrProtomodule };
    } else {
        protomodule = modulesOrProtomodule;
    }

    const { manifest: givenManifest, modules } = protomodule;

    return (() => {
        const modulesDefinitions = factor(modules).map(factor);

        // tslint:disable-next-line: no-shadowed-variable
        const manifests = [givenManifest, ...modulesDefinitions.map(({ manifest }) => manifest)].filter(
            // tslint:disable-next-line: no-shadowed-variable
            (manifest) => manifest,
        );
        // tslint:disable-next-line: no-shadowed-variable
        const setups = modulesDefinitions.map(({ setup }) => setup);

        // TODO: Use expectExactlyOneItem
        // tslint:disable-next-line: no-shadowed-variable
        let manifest: IModuleManifest | undefined;
        if (manifests.length === 0) {
            manifest = undefined;
        } else if (manifests.length === 1) {
            manifest = manifests[0]!;
        } else {
            consolex.warn(
                `There are more (${manifests.length}) manifest when composing multimodule, using only first one.`,
            );
            manifest = manifests[0]!;
        }

        const setup = (systems: ISystems) =>
            // tslint:disable-next-line: no-shadowed-variable
            Registration.join(...setups.map((setup) => setup(systems)));

        return {
            manifest,
            setup,
        };
    })();
}
