import { MaterialArtVersioningSystem } from '../../50-systems/ArtVersionSystem/0-MaterialArtVersioningSystem';
import { IOngoingMaterialOperation } from '../../50-systems/ArtVersionSystem/IOperation';
import { TextArt } from './TextArt';

/**
 * @singleton in scope of TextArt / TextTool modules
 * @not-collboard-modules-sdk
 */
let textOperations: Array<IOngoingMaterialOperation> = [];

/**
 * Create new text art operation
 *
 * Working with @singleton textOperations
 * @not-collboard-modules-sdk
 */
export function createTextOperation(materialArtVersioningSystem: MaterialArtVersioningSystem, art: TextArt) {
    textOperations.push(materialArtVersioningSystem.createPrimaryOperation().newArts(art).worthless());
}

/**
 * Gets ongoing operation with given TextArt OR create new one
 *
 * Working with @singleton textOperations
 * @not-collboard-modules-sdk
 */
export function getTextOperation(
    materialArtVersioningSystem: MaterialArtVersioningSystem,
    art: TextArt,
    isCreatedNewIfNotExist: boolean,
): IOngoingMaterialOperation | null {
    const textOperation = textOperations.find(
        (textOperation2) => textOperation2.arts.length === 1 && textOperation2.arts[0]!.artId === art.artId,
    );

    if (textOperation) {
        return textOperation;
    }

    if (isCreatedNewIfNotExist) {
        return (
            materialArtVersioningSystem
                // Not needed> .createOperation('Text change from board')
                .createPrimaryOperation()
                .takeArts(art)
        );
    }

    return null;
}

/**
 * Abort and delete from list ongoing operation with given TextArt
 *
 * Working with @singleton textOperations
 * @not-collboard-modules-sdk
 */
export function abortTextOperation(operation: IOngoingMaterialOperation) {
    operation.abort();
    textOperations = textOperations.filter((textOperation) => textOperation === operation);
}
