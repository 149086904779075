import React from 'react';
import { ISystems, SystemName } from '../../50-systems/00-SystemsContainer/ISystems';
import { ISystemsResolved } from '../../50-systems/00-SystemsContainer/ISystemsResolved';
import { PermissionError } from '../errors/PermissionError';

/**
 * Context for systems container used in modules
 * Note: This context is provided at UserInterfaceSystem
 *
 * @collboard-modules-sdk
 */
export const SystemsContainerContext =
    React.createContext/* <- TODO: Import and use just a createContext */ <ISystems | null>(null);

/**
 * Use previously requested systems
 *
 * Note: You need first to request the systems you want to use
 *
 * @param requestedSystemsNames Names of requested systems
 * @returns record object which contains all requested systems
 *
 * @collboard-modules-sdk
 */
export function useSystems<TSystemNames extends SystemName>(
    ...requestedSystemsNames: Array<TSystemNames>
): Pick<ISystemsResolved, TSystemNames> {
    const systemsContainer = React.useContext(/* <- TODO: Import and use just a useContext */ SystemsContainerContext);

    if (!systemsContainer) {
        throw new PermissionError(`Calling useSystems out of module context`);
    }

    const systems = systemsContainer.use(...requestedSystemsNames);
    return systems;
}

/**
 * Note: For security reason there are two separate hooks and contexts:
 *       - useSystems is used in modules and gives only the requested systems
 *       - useSystemsInCore which contains all systems provided to core and it is not exported to modules (= like a root access)
 */
