import { string_module_name, string_url } from '../../40-utils/typeAliases';
import { IMessage } from '../TranslationsSystem/interfaces/IMessage';

/**
 * Similar interface to Notification Web API
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Notification
 *
 * @collboard-modules-sdk
 */
export interface INotification {
    /**
     * Identifying tag for the notification.
     */
    readonly tag: string;
    /*
    TODO: Pass module automaticaly - for security (one module cannot pass identity of another) and easier usage
    */
    readonly module?: string_module_name;
    readonly actions?: Array<INotificationAction>;
    readonly title?: IMessage;
    readonly subtitle?: IMessage;
    readonly body?: IMessage;
    // TODO: readonly lang: string;
    readonly type?: 'info' | 'warning' | 'error' | 'success';
    readonly priority?: number;
    readonly places?: Array<NotificationPlace>;
    readonly canBeClosed?: boolean;
    readonly onClick?: INotificationOnClickHandler;
    readonly href?: string_url;
    // TODO: Persistency and scope - session, user, identity...
}

/**
 * @deprecated [🍿] Do not use enums but object as const OR 'LITERAL VALUES' instead
 *
 * @collboard-modules-sdk
 */
export enum NotificationPlace {
    Board = 'BOARD',
    WelcomeModalAnnouncement = 'ANNOUNCEMENT',
}

/**
 * @collboard-modules-sdk
 */
export interface INotificationAction {
    /**
     * Identifying name for the action.
     */
    readonly action: string;
    /**
     *The string describing the action that is displayed to the user.
     */
    readonly title: IMessage;
    /**
     * The URL of the image used to represent the notification when there is not enough space to display the notification itself.
     */
    readonly icon?: string;
    readonly type?: 'ok' | 'cancel' | 'more' | 'primary';
    readonly onClick?: INotificationOnClickHandler;
    readonly href?: string_url;
}

export type INotificationOnClickHandler = (
    event: React.MouseEvent/* <- TODO: Import and use just a MouseEvent */ <HTMLDivElement, MouseEvent>,
    // TODO: Is MouseEvent correct? Should not it be PointerEvent?
    // TODO: Probably pass notificationRegistration: NotificationRegistration
) => void;
