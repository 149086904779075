import { Vector } from 'xyzt';
import { factor } from '../40-utils/IFactory';
import { ITrayDynamicDefinition } from '../40-utils/trayModules/interfaces/ITrayDynamicItemTypes';
import { RenderTrayItem } from '../40-utils/trayModules/RenderTrayItem';
import { Abstract2dBoxArt } from './27-Abstract2dBoxArt';

/**
 * @collboard-modules-sdk
 */
export abstract class AbstractTrayArt extends Abstract2dBoxArt {
    public color = 'white';
    public defaultZIndex = 3;

    public abstract getDefinition(): ITrayDynamicDefinition;

    public constructor(public itemId: string, public shift: Vector) {
        super(null);
        this.init();
    }

    private init() {
        this.color = this.isValid ? factor(this.getDefinition().getItems)[this.itemId].defaultColor : 'white';
    }

    private get isValid() {
        return !!factor(this.getDefinition().getItems)[this.itemId];
    }

    public get acceptedAttributes() {
        return ['color'];
    }

    public renderBox() {
        return RenderTrayItem(
            factor(this.getDefinition().getItems),
            this.getDefinition().className,
            this.itemId,
            1,
            this.color,
        );
    }
}

/**
 * TODO: [🎚️] Maybe get rit of AbstractArts and make makers for arts which returns IArts
 */
