import { IFramable } from '../interfaces/IFramable';

export function isFramable(framable: any): framable is IFramable {
    return framable.boundingBox !== undefined && framable.title !== undefined;
}

/**
 * TODO: There should be function BoundingBox.isBoundingBox in xyzt
 * TODO: [🥟] Autogenerate suffix as .guard.ts
 * TODO: [🥟] This should be propper typeguard not just testing one property
 * TODO: [🥟] All typeguards should recieve unknown
 */
