import { string_module_name } from '../../../40-utils/typeAliases';
import { MODULE_NAME_PATTERN } from '../../../config';
import { consolex } from '../../../consolex';

/**
 * Looks at the file for import-with-module directive
 *
 * @param file where to search
 * @returns name of the native importer module or null if not found
 */
export async function getFileNativeImporter(file: File): Promise<string_module_name | null> {
    const fileContent = await file.text();

    const match = fileContent.match(/import-with-module="(?<moduleName>.*?)"/);

    if (!match?.groups?.moduleName) {
        return null;
    }

    const moduleName = match.groups.moduleName;

    if (!MODULE_NAME_PATTERN.test(moduleName)) {
        consolex.error(
            `Found native import-with-module but with invalid module name: "${moduleName}" which is not matching /${MODULE_NAME_PATTERN.source}/.`,
        );
        return null;
    }

    return moduleName;
}

/**
 * TODO: Also search in metadata
 * TODO: Allow other formats of import-with-module="@collboard/internal/html-native-import"
 * TODO: Write tests before 👆
 * TODO: Scenarios when more than one import-with-module found
 */
