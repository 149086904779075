import React from 'react';
import { IVectorData, Vector } from 'xyzt';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { Abstract2dArt } from '../../71-arts/26-Abstract2dArt';
import { TimerComponent } from './TimerComponent';
import { ITimerAndStopwatchControls } from './TimerControlsAttributeModule';

internalModules.declareModule(() => makeArtModule(StopwatchArt));

export class StopwatchArt extends Abstract2dArt {
    public static serializeName = 'Stopwatch';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/stopwatch-art',
        deprecatedNames: '@collboard/stopwatch-art',
    };

    public running: boolean;
    public elapsed: number;
    public timestamp: number; // Warning: not UNIX timestamp since it is in millis

    /**
     * @deprecated [🍈] name privateSize is confusing, use something instead like size (=privateSize) vs. originalSize
     */
    private privateSize: IVectorData = new Vector(300, 75);

    public constructor(shift: Vector) {
        super();
        this.shift = shift;
        this.reset();
    }

    start() {
        this.running = true;
        this.timestamp = new Date().getTime();
    }

    stop() {
        this.running = false;
        this.elapsed += new Date().getTime() - this.timestamp;
        this.timestamp = new Date().getTime();
    }

    reset() {
        this.running = false;
        this.elapsed = 0;
        this.timestamp = new Date().getTime();
    }

    // Hack to control via floating menu
    public set stopwatchControls(value: ITimerAndStopwatchControls) {
        switch (value) {
            case 'INITIAL': // Stop
                this.stop();
                return;
            case 'RUNNING': // Start
                this.start();
                return;
            case 'STOPPED': // Reset
                this.reset();
                return;
        }
    }
    public get stopwatchControls(): ITimerAndStopwatchControls {
        return this.running ? 'RUNNING' : 'INITIAL';
    }

    public get acceptedAttributes() {
        return ['stopwatchControls'];
    }
    public get topLeft() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return this.shift;
    }
    public get bottomRight() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return Vector.add(this.shift, this.privateSize);
    }

    render() {
        return (
            <div
                className="block"
                style={{
                    width: this.privateSize.x || 0,
                    height: this.privateSize.y || 0,
                    position: 'absolute',
                    left: this.shift.x || 0 /* <- LIB xyzt .toTopLeft() */,
                    top: this.shift.y || 0 /* <- LIB xyzt .toTopLeft() */,
                }}
            >
                {this.running ? (
                    <TimerComponent millis={10} since={this.timestamp - this.elapsed} />
                ) : (
                    <TimerComponent millis={10} static={this.elapsed} />
                )}
            </div>
        );
    }
}

/**
 * Note: In future this file will we in independent repository as external module.
 */
