import { randomString } from './randomString';
import { string_uri_part } from './typeAliases';

// TODO: Automatically converts uppercase to lowercase to provide more redundancy for user

/**
 *
 * Generates a random uriId name like "ggpsehhglbdoejrhpmal", "h2m24iceax4qtxnk7pzj", "krc73zrdryyuaebj74du"...
 *
 * @collboard-modules-sdk
 */
export function randomUriId(): string_uri_part {
    /**
     * All chars are:
     * 0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ
     * But we are using Base58 to prevent misspelling!
     *
     * 11 chars are on YouTube
     * But we are using 17 because it also act as a security token
     *
     */
    return randomString(20, 'abcdefghijklmnopqrstuvwxyz234567');

    // TODO: !! Is it safe enough
}
