import React from 'react';

interface IInnerHtmlProps {
    children: string;
}

/**
 * InnerHtml is wrapper for innerHTML of a DOM element.
 *
 * @collboard-modules-sdk
 */
export function InnerHtml(props: IInnerHtmlProps) {
    const { children } = props;

    return <div dangerouslySetInnerHTML={{ __html: children }} />;
}

/**
 * TODO: Add prop isSafe
 */
