import React from 'react';
import { BehaviorSubject } from 'rxjs';
import { useObservable } from '../../../40-utils/react-hooks/useObservable';
import { usePromise } from '../../../40-utils/react-hooks/usePromise';
import { attribute_value } from '../IAttribute';
import { IAttributeInputRender } from '../IAttributeInputRender';

interface IInputRenderProps {
    inputRender: IAttributeInputRender<attribute_value>;
    value: BehaviorSubject<attribute_value>;
    onChange: (value: attribute_value) => void;
}

export function InputRender(props: IInputRenderProps) {
    // console.log('🅰️', 'InputRender', 'render');

    const { inputRender, value, onChange } = props;

    const { value: currentValue } = useObservable(value);
    const { value: inputRenderElement } = usePromise(inputRender(currentValue, onChange), [
        inputRender,
        currentValue,
        onChange,
    ]);

    return inputRenderElement || <></>;
}
