export type IReplacer = (key: string, note?: string) => string;

interface ITemplateArguments {
    template: string;
    replace?: IReplacer;
    params?: Record<string, string | URL | null | undefined>;
    //removeEmptyParams = true*/
}

/**
 *
 * Note: Originally it was named useTemplate but it has conflict with name of one react hook
 *
 */
export function applyParamsOnTemplate({ template, params, replace }: ITemplateArguments): string {
    // Note: not using some template framework as Mustache because it is not dealing with note and brings lot more logic than we need

    // TODO: Warn if some remaining params when using params

    const replaceWithParams: IReplacer = (key: string, note?: string) => {
        if (params) {
            const value = params[key];
            if (value !== undefined) {
                return (value || '').toString();
            }
        }
        if (replace) return replace(key, note);
        throw new Error(`Can not replace key "${key}".`);
    };

    // TODO: Probbably use replaceAll instead of replace
    return template.replace(/\{\{(.*?)\}\}/gs, (match) => {
        // TODO: Can i do it some more elegant and DRY way with replace
        const [, inner] = Array.from(match.match(/\{\{(.*?)\}\}/s)!);

        const [key, note] = inner.split('||', 2);
        const value = replaceWithParams(key, note);

        // TODO: If the value is null remove whole element
        // TODO: Security: context replacing and escaping
        return value;
    });
}
