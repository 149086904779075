export function nullsafeify<TArg, TResult>(fn: (arg: TArg) => TResult): (arg: TArg | null) => TResult | null {
    return (arg: TArg | null) => {
        if (arg === null) {
            return null;
        } else {
            return fn(arg);
        }
    };
}

/**
 * TODO: Can we make resulting function null generic-dependent?
 *       If user pass null it will return null and it will be overloaded to null
 */
