/**
 * Convert Blob or File to binary string
 *
 * @collboard-modules-sdk
 */
export function blobToBinaryString(source: Blob | File): Promise<string> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', async (event) => {
            const binary = reader.result as string;
            resolve(binary);
        });
        reader.readAsBinaryString(source);
    });
}
