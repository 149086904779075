import { Destroyable } from 'destroyable';
import { forTime } from 'waitasecond';
import { filterWithLimit } from '../../../40-utils/filterWithLimit';
import { string_module_category, string_module_name } from '../../../40-utils/typeAliases';
import { ISystemsExtended } from '../../00-SystemsContainer/ISystems';
import { IModuleDefinition } from '../interfaces/IModule';
import { IModuleSearchCriteria } from '../interfaces/IModuleSearchCriteria';
import { IModulesStorageStrong } from '../interfaces/IModulesStorage';
import { IModuleStoreConnector } from '../interfaces/IModuleStoreConnector';
import { IModuleStoreConnectorSearchResult } from '../interfaces/IModuleStoreConnectorSearchResult';
import { isModulePassingSearchCriteria } from '../utils/isModulePassingSearchCriteria';
import { sortModules } from '../utils/sortModules';

/**
 * StorageModuleStoreConnector searches through IModulesStorage modules (which are already delcared in memory).
 * This is used for internal modules + modules in development by colldev
 */
export class StorageModuleStoreConnector extends Destroyable implements IModuleStoreConnector {
    public constructor(private systems: ISystemsExtended, private readonly moduleStorage: IModulesStorageStrong) {
        super();
    }

    /**
     *
     * @proxy
     */
    public getModule(name: string_module_name): IModuleDefinition | null {
        return this.moduleStorage.getModule(name);
    }

    public async getCategories(): Promise<Set<string_module_category>> {
        const categories = new Set<string_module_category>();
        for (const module of this.moduleStorage.getAllModules()) {
            if (module.manifest) {
                for (const category of module.manifest.categories || []) {
                    categories.add(category);
                }
            }
        }
        return categories;
    }

    public async search(searchCriteria: IModuleSearchCriteria): Promise<IModuleStoreConnectorSearchResult> {
        await forTime(
            10 /* !! lower and test higher */,
        ); /* TODO: Remove this line @roseckyj after creating some prettier loader */

        const { translationsSystem, businessSystem } = await this.systems.request(
            'translationsSystem',
            'businessSystem',
        );

        const businessName = await businessSystem.businessName;
        const businessConfiguration = await businessSystem.businessConfiguration;

        const filteredModules = filterWithLimit({
            array: this.moduleStorage.getAllModules(),
            limit: searchCriteria.limit || Infinity,
            predicate: (module) =>
                isModulePassingSearchCriteria({
                    moduleManifest: module.manifest,
                    searchCriteria,
                    businessName,
                    businessConfiguration,
                }).value,
        });

        const filteredManifests = filteredModules.map(({ manifest }) => manifest!);

        filteredManifests.sort(sortModules.bind(null, translationsSystem));

        return { manifests: filteredManifests };
    }
}
