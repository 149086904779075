import { IStorage } from 'everstorage';
import { BehaviorSubject } from 'rxjs';
import SocketIO from 'socket.io-client';
import { ModuleDeclarationMissingManifestError } from '../../40-utils/errors/ModuleDeclarationError';
import { factor } from '../../40-utils/IFactory';
import { loadAndRunExternalScript } from '../../40-utils/loadAndRunExternalScript';
import { nextWithMutation } from '../../40-utils/nextWithMutation';
import { randomJavascriptName } from '../../40-utils/randomJavascriptName';
import { Queue } from '../../40-utils/tasks/Queue';
import { IModule } from '../../50-systems/ModuleStore/interfaces/IModule';
import { StorageSyncer } from '../../50-systems/ModuleStore/Syncers/StorageSyncer';
import { StorageSystem } from '../../50-systems/StorageSystem/StorageSystem';
import { consolex, disableLogging } from '../../consolex';
import { COLLDEV_SYNCER_STYLE } from './COLLDEV_SYNCER_STYLE';
import { IColldevSyncerSocket } from './IColldevSyncerSocket';

/**
 * ColldevSyncer installs / uninstalls modules according colldev CLI command server
 * it contains internally its modulesStorage
 * it is connected via socket.io to colldev CLI command server
 */
export class ColldevSyncer extends StorageSyncer {
    private declareModuleCallbackName: string;
    private socketClient: SocketIO.Socket;
    private storageSystem: StorageSystem;
    private storage: IStorage<string>;

    private _colldevUrl: string;

    public get colldevUrl(): string {
        return this._colldevUrl;
    }

    public set colldevUrl(colldevUrl: string) {
        this._colldevUrl = colldevUrl;
        this.storage.setItem('colldevUrl', colldevUrl);
        this.reestablishConnection();
    }

    public readonly clientStatus = new BehaviorSubject<IColldevSyncerSocket.clientStatus>({
        version: 0,
        isConnected: false,
        isReady: false,
        errors: [],
        url: window.location.toString(),
        boardId: null,
        modules: {},
    });

    protected async initSyncer() {
        await super.initSyncer();

        this.storageSystem = await this.systems.storageSystem;
        this.storage = this.storageSystem.getStorage('ColldevSyncer');
        const colldevUrlFromUrl = (await this.systems.routingSystem).originalUrl.searchParams.get('colldevUrl');

        if (colldevUrlFromUrl) {
            this.colldevUrl = colldevUrlFromUrl;
            await this.storage.setItem('colldevUrl', colldevUrlFromUrl);
        } else {
            const colldevUrlFromStorage = await this.storage.getItem('colldevUrl');
            if (colldevUrlFromStorage) {
                this.colldevUrl = colldevUrlFromStorage;
            } else {
                this.colldevUrl = `http://localhost:3000/`;
            }
        }

        // Note: ColldevSyncer does not need keepConnectionAlive so probbably BoardApiClient wont either

        // TODO: This code bellow can be maybe some internal function of ModulesStorage
        this.declareModuleCallbackName = randomJavascriptName({ prefix: 'declareModule' });

        // consolex.info('%c🧪 declareModuleCallbackName', COLLDEV_SYNCER_STYLE, this.declareModuleCallbackName);
        (window as any)[this.declareModuleCallbackName] = async (module: IModule) => {
            // consolex.info(`%c🧪 window.${this.declareModuleCallbackName} was called`, COLLDEV_SYNCER_STYLE, module);

            try {
                const moduleDefinition = factor(module);

                if (!moduleDefinition.manifest) {
                    throw new ModuleDeclarationMissingManifestError();
                }

                this.clientStatus.next({
                    ...this.clientStatus.value,
                    errors: [],
                });

                try {
                    nextWithMutation(this.clientStatus, (clientStatusValue) => {
                        clientStatusValue.modules[moduleDefinition.manifest!.name] = {
                            isDeclared: false,
                            isInstalled: false,
                            errors: [],
                        };
                    });

                    this.sendClientStatus();
                    await this.declareModule(module);

                    nextWithMutation(this.clientStatus, (clientStatusValue) => {
                        this.clientStatus.value.modules[moduleDefinition.manifest!.name].isDeclared = true;
                    });
                } catch (error) {
                    if (error instanceof Error) {
                        nextWithMutation(this.clientStatus, (clientStatusValue) => {
                            clientStatusValue.modules[moduleDefinition.manifest!.name].errors = [
                                {
                                    // TODO: How to do error typing. "Object is of type 'unknown'.ts(2571)"
                                    name: (error as Error).name,
                                    message: (error as Error).message,
                                    stack: (error as Error).stack,
                                },
                            ];
                        });
                    }
                    throw error /* Note: We want error to appear in console and stop executing this function */;
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.clientStatus.next({
                        ...this.clientStatus.value,
                        errors: [
                            {
                                name: error.name,
                                message: error.message,
                                stack: error.stack,
                            },
                        ],
                    });
                }
                throw error /* Note: We want error to appear in console and stop executing this function */;
            } finally {
                this.clientStatus.next({
                    ...this.clientStatus.value,
                    isReady: true,
                });

                this.sendClientStatus();
            }
        };
    }

    private bundleRecievedQueue = new Queue<void>();

    private async reestablishConnection() {
        // consolex.groupCollapsed(`%c🧪 (Re)establishing connection to Colldev.`, COLLDEV_SYNCER_STYLE).info('this._colldevUrl', this._colldevUrl).end();

        if (this.socketClient) {
            this.socketClient.disconnect();
            await this.uninstallAll();
        }

        this.socketClient = SocketIO.io(this._colldevUrl, {
            transports: ['websocket', 'polling'],
        });

        this.socketClient.on('bundle', ({ bundleUrl }: IColldevSyncerSocket.bundle) =>
            this.bundleRecievedQueue.task(async () => {
                // consolex.log(`%c🧪 Recieved bundle `, bundleUrl);

                this.clientStatus.next({
                    ...this.clientStatus.value,
                    modules: {},
                });

                this.sendClientStatus();
                await this.uninstallAll();

                disableLogging('developing modules with Colldev');

                await loadAndRunExternalScript(`${bundleUrl}?declareModuleCallback=${this.declareModuleCallbackName}`);
            }),
        );

        this.socketClient.on('connect', async () => {
            const { boardSystem } = await this.systems.request('boardSystem', 'apiClient');

            if (!boardSystem.currentBoard.value.uriId) {
                // consolex.info(`%c🧪 Connected to Colldev: creating new board for module development.`, COLLDEV_SYNCER_STYLE,);

                const { editId } = await boardSystem.createNewBoard({
                    logger: consolex,
                    boardname: `🧪 Test board by Colldev`,
                    isNewBoardNavigated: true,
                    isPersistent: false,
                });
                consolex.info(`%c🧪 Colldev created board "${editId}".`, COLLDEV_SYNCER_STYLE);
            }

            this.clientStatus.next({
                ...this.clientStatus.value,
                isConnected: true,
            });

            this.socketClient.emit('identify', {
                instanceId: (await this.systems.identitySystem).instanceId,
            } as IColldevSyncerSocket.identify);
        });

        this.socketClient.on('disconnect', async () => {
            this.clientStatus.next({
                ...this.clientStatus.value,
                isConnected: false,
            });
        });
    }

    private sendClientStatus() {
        // consolex.log(`%c🧪 this.clientStatus `, COLLDEV_SYNCER_STYLE, toJS(this.clientStatus));

        this.clientStatus.next({
            ...this.clientStatus.value,
            version: this.clientStatus.value.version + 1,
        });

        this.socketClient.emit('clientStatus', this.clientStatus.value as IColldevSyncerSocket.clientStatus);
    }

    public async destroy(): Promise<void> {
        await Promise.all([super.destroy(), this.socketClient.disconnect()]);
    }
}
