import { TouchController } from 'touchcontroller';
import { errorMessageWithAdditional } from '../../40-utils/errors/errorMessageWithAdditional';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { IModuleManifest } from '../ModuleStore/interfaces/IModuleManifest/IModuleManifest';
import { ISystems } from './ISystems';

type ISignableEntity = ISystems | AbstractSystem | TouchController;
type ISignature = IModuleManifest | null;

class SignatureManager {
    private readonly signatureKey = Symbol();

    /**
     * Signs given entity with given module signature.
     * This mechanism is used for knowning which module does what.
     *
     * @param signableEntity for examle System
     * @param signature
     * @returns Signed version of given entity; it does not mutate given entity it returns proxied version with signature.
     */
    public sign<TSignableEntity extends ISignableEntity>(
        signableEntity: TSignableEntity,
        signature: ISignature,
    ): TSignableEntity {
        const existingSignature = this.getSignature(signableEntity);
        if (existingSignature) {
            // TODO: Some way how to require signature / require unsigned through signatureManager
            throw new Error(
                errorMessageWithAdditional(`Can not sign already signed system.`, {
                    existingSignature,
                    newSignature: signature,
                }),
            );
        }

        const self = this;
        return new Proxy(signableEntity, {
            get(target, property, receiver) {
                if (property === self.signatureKey) {
                    return signature;
                }
                return Reflect.get(target, property, receiver);
            },
        });
    }

    /**
     * Gets signature of given entity.
     */
    public getSignature(signableEntity: ISignableEntity): ISignature {
        // TODO: Is this externally secure?
        return (signableEntity as any)[this.signatureKey] || null;
    }
}

/**
 * Instance of SignatureManager used in Collboard as a singleton.
 *
 * TODO: SignatureManager should be probbably own system NOT const singleton
 */
export const signatureManager = new SignatureManager();

/**
 * TODO: Probably more universally - DO not hardcode SystemsContainer, AbstractSystem or IModuleManifest
 * TODO: Is this the best way to do this?
 * TODO: Is this method preventing from removing the sign from external modules reliably:
 *       Can be hack some modification of:
 *       - delete (systems as any)[(signatureManager as any).signatureKey];
 *       - Wrapping system in another proxy with signatureKey of null/undefined
 *       - Modifying original signatureManager from external code
 */
