import { normalizeTo_SCREAMING_CASE } from '@promptbook/utils';
import { Destroyable, IDestroyable } from 'destroyable';
import spaceTrim from 'spacetrim';
import { forImmediate, forTime } from 'waitasecond';
import { Commit } from '../../10-database/Commit';
import { ICommitData } from '../../10-database/interfaces/ICommitData';
import { errorMessageWithAdditional } from '../../40-utils/errors/errorMessageWithAdditional';
import { HighOrderError } from '../../40-utils/errors/HighOrderError';
import { randomUuid } from '../../40-utils/randomUuid';
import { uuid } from '../../40-utils/typeAliases';
import { AbstractArt } from '../../71-arts/20-AbstractArt';
import { DeletedArt } from '../../71-arts/30-DeletedArt';
import { consolex } from '../../consolex';
import { IClosePreventable } from '../ClosePreventionSystem/IClosePreventable';
import { IClosePreventionSystem } from '../ClosePreventionSystem/IClosePreventionSystem';
import { IArtVersioningSystem } from './IArtVersionSystem';
import { IFreshMaterialOperation, IOngoingMaterialOperation } from './IOperation';
/**
 * Start with takeArts, takeCommits, newArts or takeArtsOrCommitsOrNulls continue with update (oe other update methods) and end with persist or delete.
 */
export class Operation
    extends Destroyable
    implements IClosePreventable, IDestroyable, IFreshMaterialOperation, IOngoingMaterialOperation
{
    private operationId: uuid;
    private operationName: string;
    public static ORIGIN = Symbol();

    public constructor(
        private artVersioningSystem: IArtVersioningSystem,
        private pushCommitsToVersioningSystem: (...commits: Array<ICommitData>) => void,
        private closePreventionSystem: IClosePreventionSystem,
        operationName: string,
    ) {
        super();

        this.operationId = randomUuid();
        this.operationName = normalizeTo_SCREAMING_CASE(operationName);

        this.checkIfStartedProperly(); // Note: Here should NOT be await despite it is async function
        this.checkIfPersistedProperly(); // Note: Here should NOT be await despite it is async function

        this.closePreventionSystem.registerClosePrevention(this);
        // TODO: !! There should be also closePrevention.unregister(this);
    }

    private previousCommitsOrNullsOrOrigins: Array<ICommitData | null | typeof Operation.ORIGIN>;

    private get /* TODO: Should this be private? */ commits(): Array<ICommitData> {
        return this.previousCommitsOrNullsOrOrigins.map((commitOrNullOrOrigin) => {
            if (Commit.isCommitLike(commitOrNullOrOrigin)) {
                return commitOrNullOrOrigin;
            }

            throw new Error(
                `Can not get commits in this state because some of them are still in null or ORIGIN phase.`,
            );
        });
    }

    public get canBeClosed(): boolean {
        return (this.unpersistedCommits.length === 0 || !this.isValuable) && this.unpushedCommits.length === 0;
    }

    private isValuable: boolean;

    /**
     * Tells that this state of operation and its arts can be instantly closed without destructing valuable user data.
     * This will be reverted automatically on new update - i.e takeArts(), takeCommits(), newArts().
     */
    public worthless(): this {
        this.isValuable = false;
        return this;
    }

    /**
     * This reverts worthless() method.
     */
    public valuable(): this {
        this.isValuable = true;
        return this;
    }

    public get arts(): Array<AbstractArt> {
        return this.commits.map((commit) => commit.art);
    }

    public takeArts(...previousArts: Array<AbstractArt>): this {
        return this.takeArtsOrCommitsOrNullsOrOrigins(...previousArts);
    }

    public takeCommits(...previousCommits: Array<ICommitData>): this {
        return this.takeArtsOrCommitsOrNullsOrOrigins(...previousCommits);
    }

    private takeArtsOrCommitsOrNullsOrOrigins(
        ...previousCommits: Array<AbstractArt | ICommitData | null | typeof Operation.ORIGIN>
    ): this {
        this.expectNoUnpersistedCommits();
        this.previousCommitsOrNullsOrOrigins = previousCommits.map((artOrCommitOrNullOrOrigin) => {
            if (artOrCommitOrNullOrOrigin instanceof AbstractArt) {
                const commit = this.artVersioningSystem.findLastCommitOfArt(artOrCommitOrNullOrOrigin);
                if (!commit) {
                    consolex.info('Missing art: ', artOrCommitOrNullOrOrigin);
                    throw new Error(`You have provided Art that is in no commit.`);
                }
                return commit;
            } else if (Commit.isCommitLike(artOrCommitOrNullOrOrigin)) {
                return artOrCommitOrNullOrOrigin;
            } else if (!artOrCommitOrNullOrOrigin) {
                return null;
            } else if (artOrCommitOrNullOrOrigin === Operation.ORIGIN) {
                return Operation.ORIGIN;
            } else {
                throw new Error(`You should provide only Commits and AbstractArts.`);
            }
        });
        return this.valuable();
    }

    public newArts(...arts: Array<AbstractArt>): this {
        this.expectNoUnpersistedCommits();
        this.previousCommitsOrNullsOrOrigins = arts.map((art) => Operation.ORIGIN);
        return this.update(...arts).valuable();
    }

    private unpushedCommits: Array<ICommitData> = []; // TODO: Probably better name

    private updatedCountInCurrentPersisting = 0;
    public update(...arts: Array<AbstractArt>): this {
        if (arts.length !== this.previousCommitsOrNullsOrOrigins.length) {
            throw new Error(`Expexted ${this.previousCommitsOrNullsOrOrigins.length} arts but got ${arts.length}.`);
        }

        for (let i = 0; i < this.previousCommitsOrNullsOrOrigins.length; i++) {
            this.updateOne(arts[i], i);
        }

        this.updatedCountInCurrentPersisting++;

        this.pushTick();

        return this;
    }

    private updateOne(art: AbstractArt, i: number /**/, creatingNewTreeIfNeeded = true /**/): ICommitData {
        const previousCommitOrNullOrOrigin = this.previousCommitsOrNullsOrOrigins[i];
        let previousCommitOrNull: ICommitData | null;

        if (previousCommitOrNullOrOrigin === Operation.ORIGIN /**/ && creatingNewTreeIfNeeded /**/) {
            previousCommitOrNull = null;
            /**/
            const [originCommit] = new Operation(
                this.artVersioningSystem,
                this.pushCommitsToVersioningSystem,
                this.closePreventionSystem,
                'ORIGIN',
            )
                .takeArtsOrCommitsOrNullsOrOrigins(null)
                .update(new DeletedArt())
                .worthless().commits;

            previousCommitOrNull = originCommit;
            /* */
        } else if (Commit.isCommitLike(previousCommitOrNullOrOrigin)) {
            previousCommitOrNull = previousCommitOrNullOrOrigin;
        } else {
            previousCommitOrNull = null;
        }

        const commit = new Commit();

        try {
            commit.treeId = previousCommitOrNull ? previousCommitOrNull.treeId : randomUuid();
            commit.version = previousCommitOrNull ? previousCommitOrNull.version + 1 : 0;
            commit.commitId = randomUuid();

            commit.art = art;
            commit.persist = 0;

            commit.operationId = this.operationId;
            commit.operationName = this.operationName;
            commit.module = this.artVersioningSystem.moduleSignature.name;
            commit.moduleVersion =
                this.artVersioningSystem.moduleSignature.version || '0.0.0' /* <- TODO: Allow NULL here */;
            commit.replacingStrategy = this.updatedCountInCurrentPersisting === 0 ? 'KEEP' : 'REPLACE';

            commit.created = new Date();
            commit.author = this.artVersioningSystem.clientId;

            commit.previousId = previousCommitOrNull ? previousCommitOrNull.commitId : null;

            this.unpushedCommits.push(commit);

            this.previousCommitsOrNullsOrOrigins[i] = commit;
            return commit;
        } catch (error) {
            if (error instanceof Error) {
                throw new HighOrderError(error, `Operation: error occured when creating a commit.`);
            } else {
                throw error;
            }
        }
    }

    /**
     *
     * Note: This is helper for using just method update
     * @param artCallback
     */
    public updateWithCallback(artCallback: (art: AbstractArt) => AbstractArt): this {
        return this.update(
            ...this.previousCommitsOrNullsOrOrigins.map((commit) =>
                artCallback((commit as unknown as AbstractArt).art as unknown as AbstractArt),
            ),
        );
    }

    /**
     *
     * Note: This is helper for using just method update
     * @param artMutatingCallback
     */
    public updateWithMutatingCallback(artMutatingCallback: (art: AbstractArt) => void): this {
        return this.updateWithCallback((art: AbstractArt) => {
            artMutatingCallback(art);
            return art;
        });
    }

    /**
     * This method will delete all arts in the operation and persist them
     *
     * Note: This is helper for using just methods update and persist
     */
    public deleteArts(): this {
        return this.update(
            // TODO: DRY
            ...this.previousCommitsOrNullsOrOrigins.map(() => new DeletedArt() /* TODO: Probably recycle deletedArt */),
        ).persist();
    }

    /**
     * @deprecated use deleteArts
     */
    public delete(): this {
        return this.deleteArts();
    }

    /**
     * Abort whole operation and do not persist it
     * Note: Not returning this because this is totally final command
     */
    public abort(): void {
        this.update(
            // TODO: DRY
            ...this.previousCommitsOrNullsOrOrigins.map(() => new DeletedArt() /* TODO: Probably recycle deletedArt */),
        );
        this.previousCommitsOrNullsOrOrigins = [] /* TODO: Is this a best way? */;
    }

    public async destroy(): Promise<void> {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        this.abort();
    }

    /**
     * Note: returning this because you can persist operation multiple times
     * @idempotent If you call persist multiple times in a row, it will just persist once
     */
    public persist(): this {
        // TODO: Check if persisted - everything should be finally persisted
        // TODO: Can be persist called multiple times during the operation or it definitelly closes the operation?

        if (this.unpersistedCommits.length === 0) {
            return this;
            // Note: This is not error anymore, maybe figure out some dev warning from it> throw new Error(`There are no unpersisted commits.`);
        }

        this.updatedCountInCurrentPersisting = 0;

        for (const commit of this.unpersistedCommits) {
            commit.persist = 1;
            if (!this.unpushedCommits.includes(commit)) {
                this.unpushedCommits.push(commit);
            }
        }

        this.pushTick();

        return this;
    }

    private get unpersistedCommits(): Array<ICommitData> {
        // TODO: Probably Cache
        return (this.previousCommitsOrNullsOrOrigins || []).filter(
            (commitOrNullOrOrigin) => Commit.isCommitLike(commitOrNullOrOrigin) && commitOrNullOrOrigin.persist === 0,
        ) as Array<Commit>;
    }

    private lastPushTick: Date = new Date();
    private async pushTick() {
        await forImmediate(/* <- TODO: Describe why waiting forImmediate OR remove */);
        this.pushCommitsToVersioningSystem(...this.unpushedCommits);
        this.unpushedCommits = [];
        this.lastPushTick = new Date();
    }

    private expectNoUnpersistedCommits() {
        if (this.unpersistedCommits.length !== 0) {
            throw new Error(
                errorMessageWithAdditional(
                    `${this.artVersioningSystem.moduleSignature.name}/${this.operationName}: There are still unpersisted commits, so you can not change arts in the operation.`,
                    {
                        isValuable: this.isValuable,
                        commits: this.commits,
                        arts: this.arts,
                        artVersioningSystem: this.artVersioningSystem,
                        unpersistedCommits: this.unpersistedCommits,
                    },
                ),
            );
        }
    }

    private async checkIfStartedProperly() {
        await forTime(
            1000 /* as a limit between operation is created and something is done (and if not warning will be logged) */,
        );

        if (!this.previousCommitsOrNullsOrOrigins) {
            // TODO: Some tracing in what part of the code it happen
            consolex.error(`The operation was created but did not start.`);
            return;
        }

        if (!this.previousCommitsOrNullsOrOrigins) {
            // TODO: Some tracing in what part of the code it happen
            consolex.error(`The operation was created, started but you did no update.`);
            return;
        }
    }

    private async checkIfPersistedProperly() {
        // TODO: Probably some better way how to check that Operation was not persisted propperly

        // TODO: Refactor this - isValuable should be checked [0] or [1] but not here
        if (!this.isValuable) {
            return;
        }

        // Note: Here will be waited until no more commits are pushed but the operation is still not persisted for 5s
        while (new Date().getTime() - this.lastPushTick.getTime() < 5000) {
            await forTime(100);
            // [0]
        }

        // [1]

        if (this.unpersistedCommits.length !== 0) {
            // TODO: Some tracing in what part of the code it happen
            consolex.error(
                spaceTrim(
                    `
                        ${this.artVersioningSystem.moduleSignature.name}/${this.operationName}: There are still unpersisted commits after a long time.
                        Did you ended operation correctly and persist it (or ended by delete)?
                    `,
                ),
                {
                    isValuable: this.isValuable,
                    commits: this.commits,
                    arts: this.arts,
                    artVersioningSystem: this.artVersioningSystem,
                    unpersistedCommits: this.unpersistedCommits,
                },
            );
            return;
        }
    }
}

/**
 * TODO: Override destroy method and really destroy ongoing stuff
 */
