import styled from 'styled-components';

/**
 * Inspired by @see https://daily-dev-tips.com/posts/creating-day-night-css-only-toggle-switch/
 */

// TODO: Probably use Color class instead of string

/** sunny side **/
const blueBackground = '#c2e9f6';
const blueBorder = '#72cce3';
const blueColor = '#96dcee';
const yellowBackground = '#fffaa8';
const yellowBorder = '#f5eb71';
/** dark side **/
const indigoBackground = '#808fc7';
const indigoBorder = '#5d6baa';
const indigoColor = '#6b7abb';
const grayBorder = '#e8e8ea';
const grayDots = '#e8e8ea';
/** general **/
const white = '#fff';

export const ThemeWrapperStyledDiv = styled.div`
    position: fixed;
    left: calc(100vw - 80px);
    top: 0;
    width: 0;
    height: 50px;
    z-index: 10000;

    transform: scale(0.3);

    border: 1px dotted red;
    //background-color: rgba(255, 0, 0, 0.5);

    display: grid;
    place-items: center;
    //min-height: 100vh;
    position: relative;

    .background {
        //border: 1px dotted red;
        //display: none;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: ${blueBackground};
        z-index: -1;
        transition: all 250ms ease-in;
    }

    .toggle--checkbox {
        display: none;
    }
    .toggle--checkbox:checked {
        /** This will all flip from sun to moon **/
        /** Change the label color **/
    }
    .toggle--checkbox:checked ~ .background {
        background: ${indigoBackground};
    }
    .toggle--checkbox:checked + .toggle--label {
        background: ${indigoColor};
        border-color: ${indigoBorder};
        /** Change the cloud to stars **/
        /** Change the sun into the moon **/
        /** Show the dimples on the moon **/
    }
    .toggle--checkbox:checked + .toggle--label .toggle--label-background {
        left: 60px;
        width: 5px;
    }
    .toggle--checkbox:checked + .toggle--label .toggle--label-background:before {
        width: 5px;
        height: 5px;
        top: -25px;
    }
    .toggle--checkbox:checked + .toggle--label .toggle--label-background:after {
        width: 5px;
        height: 5px;
        left: -30px;
        top: 20px;
    }
    .toggle--checkbox:checked + .toggle--label:before {
        background: ${white};
        border-color: ${grayBorder};
        animation-name: switch;
        animation-duration: 350ms;
        animation-fill-mode: forwards;
    }
    .toggle--checkbox:checked + .toggle--label:after {
        transition-delay: 350ms;
        opacity: 1;
    }
    .toggle--label {
        /** Placeholder element, starting at blue **/
        width: 200px;
        height: 100px;
        background: ${blueColor};
        border-radius: 100px;
        border: 5px solid ${blueBorder};
        display: flex;
        position: relative;
        transition: all 350ms ease-in;
        /** The sun cloud and moon stars **/
        /** Sun/Moon element **/
        /** Gray dots on the moon **/
    }
    .toggle--label-background {
        width: 10px;
        height: 5px;
        border-radius: 5px;
        position: relative;
        background: ${white};
        left: 135px;
        top: 45px;
        transition: all 150ms ease-in;
    }
    .toggle--label-background:before {
        content: '';
        position: absolute;
        top: -5px;
        width: 40px;
        height: 5px;
        border-radius: 5px;
        background: ${white};
        left: -20px;
        transition: all 150ms ease-in;
    }
    .toggle--label-background:after {
        content: '';
        position: absolute;
        top: 5px;
        width: 40px;
        height: 5px;
        border-radius: 5px;
        background: ${white};
        left: -10px;
        transition: all 150ms ease-in;
    }
    .toggle--label:before {
        animation-name: reverse;
        animation-duration: 350ms;
        animation-fill-mode: forwards;
        transition: all 350ms ease-in;
        content: '';
        width: 82px;
        height: 82px;
        border: 5px solid ${yellowBorder};
        left: 4px;
        top: 4px;
        position: absolute;
        border-radius: 82px;
        background: ${yellowBackground};
    }
    .toggle--label:after {
        transition-delay: 0ms;
        transition: all 250ms ease-in;
        position: absolute;
        content: '';
        box-shadow: ${grayDots} -13px 0 0 2px, ${grayDots} -24px 14px 0 -2px;
        left: 143px;
        top: 23px;
        width: 10px;
        height: 10px;
        background: transparent;
        border-radius: 50%;
        opacity: 0;
    }

    @keyframes switch {
        0% {
            left: 4px;
        }
        60% {
            left: 4px;
            width: 112px;
        }
        100% {
            left: 104px;
            width: 82px;
        }
    }
    @keyframes reverse {
        0% {
            left: 104px;
            width: 82px;
        }
        60% {
            left: 72px;
            width: 112px;
        }
        100% {
            left: 4px;
        }
    }
`;
