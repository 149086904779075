import React from 'react';
import { randomTag } from '../../40-utils/randomTag';
import { consolex } from '../../consolex';

type IErrorBoundaryProps =
    React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{}>;

interface IErrorBoundaryState {
    tag?: string;
    hasError: boolean;
}

/**
 * @collboard-modules-sdk
 */
export class ErrorBoundary extends React.Component/* <- TODO: Import and use just a Component */ <
    IErrorBoundaryProps,
    IErrorBoundaryState
> {
    public constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, tag: randomTag() };
    }

    // TODO: Use state for this
    private error: Error;

    componentDidCatch(error: Error) {
        this.error = error;
        consolex.error(this.state.tag, error);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div
                    onClick={() => {
                        consolex.error(this.state.tag, this.error);
                    }}
                >
                    {/* [💇‍♂️] Some component for rendering error states */}
                    <span /*style={{ color: 'red' }}*/>Something went wrong.</span>
                    <br />
                    {this.state.tag} More in the console.
                    {/*<br />
                    <i>(You can click on this message to show it again in the console)</i>*/}
                </div>
            );
        }

        return this.props.children;
    }
}

/**
 * TODO: [🏛️] Convert Class components to Functional (with hooks).
 */
