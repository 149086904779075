import React from 'react';
import { useSystems } from '../../../40-utils/react-hooks/useSystems';
import { ExportScopeSimple, IExportScope } from '../interfaces/IExportScope';
import { IPreparedFileExporting } from '../interfaces/IPreparedFileExport';
import { ExporterPickerComponent } from './0-ExporterPickerComponent';
import { ExportScopePickerComponent } from './0-ExportScopePickerComponent';

interface IExportPrepareComponentProps {
    onChange(fileExporting: IPreparedFileExporting | null): void;
}

/**
 * Compine pickers and options for export
 * Note: Need to be used inside of a module
 *
 * @collboard-modules-sdk
 */
export function ExportPrepareComponent({ onChange }: IExportPrepareComponentProps) {
    const { appState, exportSystem, translationsSystem } = useSystems('appState', 'exportSystem', 'translationsSystem');
    const [scope, setScope] = React.useState/* <- TODO: Import and use just a useState */ <IExportScope>(
        ExportScopeSimple.Board,
    );
    const [preparedFileExport, setPreparedFileExport] =
        React.useState/* <- TODO: Import and use just a useState */ <IPreparedFileExporting | null>(null);
    // [👿]> const [importStrategy, setImportStrategy] = React.useState/* <- TODO: Import and use just a useState */<ImportStrategy>(ImportStrategy.Materialize);
    // TODO: [🍸] Some way how the supporter can tell additional options

    return (
        <>
            <ExportScopePickerComponent
                {...{ exportSystem, translationsSystem, appState }}
                value={scope}
                onChange={(newScope) => {
                    setScope(newScope);

                    // TODO: In future preserve file format when changing the scope
                    setPreparedFileExport(null);
                    onChange(null);
                }}
            />

            <ExporterPickerComponent
                {...{ exportSystem, scope }}
                value={preparedFileExport}
                onChange={(newPreparedFileExport) => {
                    setPreparedFileExport(newPreparedFileExport);
                    onChange(newPreparedFileExport);
                }}
            />
        </>
    );
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
