import styled from 'styled-components';
import { ISkin } from '../../../../50-systems/StyleSystem/ISkin';

export const LanguageWrapperStyledDiv = styled.div<{ skin: ISkin }>`
    position: fixed;
    top: 10px;
    right: -55px;
    z-index: 10000;

    .language {
        display: block;
        width: 30px;
        height: 20px;
        border-radius: ${({ skin }) => skin.borderRadius}px;
        object-fit: cover;
        object-position: center center;
        margin: 10px;
    }

    @media /*[🍕]*/ only screen and (max-width: 850px) {
        /*position: absolute;
        top: 20px;
        right: 50px;*/
        position: unset;
        top: unset;
        right: unset;
        z-index: unset;
        margin-bottom: 20px;

        .language {
            display: inline-block;
            margin: 0 5px 0 0;
        }
    }
`;
