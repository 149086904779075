import React from 'react';
import { ModalScrollStyledDiv } from './ModalScrollStyledDiv';

/**
 * Scrolling content of the modal
 *
 * Tip: If you want to have some fixed content after (on the bottom), use <ModalFooter isSequestered={false}
 *
 * @collboard-modules-sdk
 */
export function ModalScroll(
    props: React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{}>,
) {
    const { children } = props;

    return (
        <ModalScrollStyledDiv>
            {children}
            <div className="bottom-space"></div>
        </ModalScrollStyledDiv>
    );
}
