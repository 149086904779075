import { string_char_emoji } from '../typeAliasEmoji';
import { ConsoleGroupSubLogger } from './ConsoleGroupSubLogger';
import { ILogger, ISubLogger } from './ILogger';

const COLOR_SQUARES = '🟩 🟥 🟦 🟧 ⬛️ 🟨 🟪 ⬜️ 🟫'.split(' ');
function* getColorSquare(): Generator<string_char_emoji> {
    for (let i = 0; true; i++) {
        yield COLOR_SQUARES[i % COLOR_SQUARES.length] as string_char_emoji;
    }
}

const colorSquare = getColorSquare();

export class ConsolePrefixLogger implements ILogger, ISubLogger {
    private readonly prefix: string;

    public constructor(private readonly console: Console, prefix?: string, ...optionalParams: Array<any>) {
        this.prefix = prefix || colorSquare.next().value;
    }

    public info(message?: any, ...optionalParams: Array<any>): this {
        this.console.info(this.prefix, message, ...optionalParams);
        return this;
    }
    public warn(warning?: any, ...optionalParams: Array<any>): this {
        this.console.warn(this.prefix, warning, ...optionalParams);
        return this;
    }
    public error(error?: Error | any, ...optionalParams: Array<any>): this {
        this.console.error(this.prefix, error, ...optionalParams);
        return this;
    }

    public trace(...data: Array<any>): this {
        this.console.trace(this.prefix, ...data);
        return this;
    }

    public group(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        return new ConsoleGroupSubLogger(this.console, false, this.prefix + groupName, ...optionalParams);
    }

    public groupCollapsed(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        return new ConsoleGroupSubLogger(this.console, true, this.prefix + groupName, ...optionalParams);
    }

    public groupPrefixed(groupName?: string, ...optionalParams: Array<any>): ConsolePrefixLogger {
        return new ConsolePrefixLogger(
            this.console,
            this.prefix + (groupName || colorSquare.next().value),
            ...optionalParams,
        );
    }

    /**
     * Note: In PrefixLogger does nothing
     *
     */
    public appear() {
        return this;
    }

    /**
     * Note: In PrefixLogger does nothing
     *
     */
    public end() {}
}
