import React from 'react';
import { useObservable } from '../../../../40-utils/react-hooks/useObservable';
import { useSystemsInCore } from '../../../../40-utils/react-hooks/useSystemsInCore';
import { SHOW_MAXIMUM_NOTIFICATIONS } from '../../../../config';
import { NotificationPlace } from '../../INotification';
import { AnnouncementComponent } from '../announcements/AnnouncementComponent';
import { NotificationComponent } from './NotificationComponent';

interface INotificationsComponentProps {
    place: NotificationPlace;
}

export function NotificationsComponent(props: INotificationsComponentProps) {
    const { place } = props;

    const { notificationSystem, translationsSystem } = useSystemsInCore('notificationSystem', 'translationsSystem');
    const { value: notifications } = useObservable(notificationSystem.notifications);
    const [showMaximumNotifications, setMaximumNotifications] = React.useState(
        /* <- TODO: Import and use just a useState */ SHOW_MAXIMUM_NOTIFICATIONS,
    );

    return (
        <div className={`notification-container notification-container-${place.toLowerCase()}`}>
            {notifications
                .filter((notification) => (notification.places || [NotificationPlace.Board]).includes(place))
                .sort((a, b) => ((a.priority || 0) > (b.priority || 0) ? 1 : -1))
                .slice(-showMaximumNotifications)
                .map((notification, key) => {
                    // TODO: @roseckyj maybe unite AnnouncementComponent and NotificationComponent
                    if (place === NotificationPlace.WelcomeModalAnnouncement) {
                        return (
                            <AnnouncementComponent
                                {...{ translationsSystem, notification, key, order: key }}
                                closeHandler={() => notificationSystem.constrict(notification)}
                            />
                        );
                    } else {
                        return (
                            <NotificationComponent
                                {...{ translationsSystem, notification, key }}
                                closeHandler={() => notificationSystem.constrict(notification)}
                            />
                        );
                    }
                })
                .reverse()}

            {notifications.length > showMaximumNotifications && (
                <div
                    className={`notification notification-warning notification-more `}
                    onClick={() => void setMaximumNotifications(showMaximumNotifications + 1)}
                >
                    <div className="inner">…</div>
                </div>
            )}
        </div>
    );
}

/**
 * TODO: More should be colored (notification-warning) like the most important level of notification hidden in queue
 * TODO: When showMaximumNotifications increases and closing upper notifications showMaximumNotifications should decrease
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
