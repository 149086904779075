import React from 'react';
import { ModulesCatalogueComponent } from '../../../../00-modules/ui/basic/ModuleStore/components/10-ModulesCatalogueComponent';
import { ModuleBoxComponent } from '../../../../00-modules/ui/basic/ModuleStore/components/15-ModuleBoxComponent';
import { usePromise } from '../../../../40-utils/react-hooks/usePromise';
import { useSystems } from '../../../../40-utils/react-hooks/useSystems';
import { string_license_token } from '../../../../40-utils/typeAliases';

interface ILicensesListModulesProps {
    licenseToken: string_license_token;
}

export function LicensesListModules({ licenseToken }: ILicensesListModulesProps) {
    const { moduleStore, licenseSyncer } = useSystems('moduleStore', 'licenseSyncer');
    const { value: searchResult } = usePromise(
        moduleStore.search({ usageLicenseToken: licenseToken }).catch(() => null),
        [licenseToken],
    );

    if (!searchResult?.manifests) {
        // TODO: [🥇][🥇0][🥇1] Show something meaningful when license is not adding any modules
        return <></>;
    }

    return (
        <ModulesCatalogueComponent>
            {searchResult.manifests.map((manifest) => (
                <ModuleBoxComponent
                    key={manifest.name}
                    {...{
                        moduleStatusReporter: licenseSyncer,
                        persister: null,
                        manifest,
                    }}
                />
            ))}
        </ModulesCatalogueComponent>
    );
}
