import { ITransformData, Transform, Vector } from 'xyzt';
import { IShortcut } from '../../../50-systems/ControlSystem/interfaces/IShortcut';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';

/**
 * TODO: Move to some global config
 */
const WHEEL_SCROLL_STEP = 25;

/**
 * TODO: Move to some global config
 */
const WHEEL_SCALE_FACTOR_STEP = 1.1;

internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/scroolwheel-shortcut',
        deprecatedNames: ['@collboard/scroolwheel-shortcut', 'ScroolwheelShortcut'],
        requirePermissions: ['view'],
    },
    async setup(systems) {
        const { controlSystem, appState } = await systems.request('controlSystem', 'appState');
        const defaultShortcuts = new Map<IShortcut, ITransformData>();

        defaultShortcuts.set(['WheelUp'], Transform.translate(new Vector(0, WHEEL_SCROLL_STEP)));
        defaultShortcuts.set(['WheelDown'], Transform.translate(new Vector(0, -WHEEL_SCROLL_STEP)));
        defaultShortcuts.set(['WheelLeft'], Transform.translate(new Vector(WHEEL_SCROLL_STEP, 0)));
        defaultShortcuts.set(['WheelRight'], Transform.translate(new Vector(-WHEEL_SCROLL_STEP, 0)));

        defaultShortcuts.set(['Control', 'WheelUp'], Transform.scale(WHEEL_SCALE_FACTOR_STEP));
        defaultShortcuts.set(['Control', 'WheelDown'], Transform.scale(1 / WHEEL_SCALE_FACTOR_STEP));

        return controlSystem.registerControl({
            defaultShortcuts,
            executor: ({ value }) => {
                const transform = Transform.fromObject(value);
                appState.transform.next(appState.transform.value.apply(transform));
            },
        });
    },
}));

/**
 * TODO: Scroll inertia - by transform inertia
 * TODO: Use .inverse()
 * TODO: Zoom in/out to the cursor not to the center of the screen
 */
