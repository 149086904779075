import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';

/**
 * SoundSystem can play a sound and keep music and UI sounds library.
 * Note: SoundSystem can play a sound vs. VoiceSystem can detect voice or speech a view.
 *
 * @private
 * @collboard-system
 */
export class SoundSystem extends AbstractSystem {
    /*
        TODO: This class is a boilerplate of the system that we have not started working on yet.
        @see https://github.com/collboard/collboard/issues/113
    */

    protected async init() {}
}

/**
 * TODO: Define boundary between SoundSystem and VoiceSystem
 * TODO: Define boundary between utils and system
 */
