import { IFramable } from './IFramable';

/**
 * Export scope specifies which arts should be exported
 *
 * @collboard-modules-sdk
 */

export type IExportScope = ExportScopeSimple | IFramable;
/**
 * Simple export scopes
 *
 * @deprecated [🍿] Do not use enums but object as const OR 'LITERAL VALUES' instead
 *
 * @collboard-modules-sdk
 */

export enum ExportScopeSimple {
    Selection = 'SELECTION',
    Board = 'BOARD',
}
