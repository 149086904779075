import { IDestroyable } from 'destroyable';
import { FakeClosePreventionSystem } from '../ClosePreventionSystem/FakeClosePreventionSystem';
import { MaterialArtVersioningSystem } from './0-MaterialArtVersioningSystem';
import { IArtVersioningSystem } from './IArtVersionSystem';
/**
 *
 * @collboard-system
 */
export class VirtualArtVersioningSystem
    extends MaterialArtVersioningSystem
    implements IArtVersioningSystem, IDestroyable
{
    protected async init() {
        await super.init();
        this.closePreventionSystem = new FakeClosePreventionSystem();
    }
}

/**
 * TODO: VirtualArtVersioningSystem should be a bit les capable then MaterialArtVersioningSystem because it can for example cannot handle persisting (@see IFreshMaterialOperation vs IFreshOperation)
 * TODO: Virtual art operations shoud not require persisting
 * TODO: Virtual art operations should not prevent closing (maybe just canBeClosed always true or completelly not IClosePreventable)
 */
