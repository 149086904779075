/**
 * Converts any object to meaningful string
 * @collboard-modules-sdk
 */
export function toString(value: any): string {
    if (typeof value === 'object') {
        return value.constructor.name;
    } else {
        return value.toString();
    }
}
