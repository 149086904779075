import React from 'react';
import { Icon } from '../../30-components/menu/Icon';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeAttributeModule } from '../../50-systems/ModuleStore/makers/makeAttributeModule';

/**
 *
 * Note: In future this file will we in independent repository as external module.
 *
 */
internalModules.declareModule(() =>
    makeAttributeModule({
        manifest: {
            name: 'DiceControlsAttribute',
            contributors: [Authors.rosecky],
        },
        standard: true,
        attribute: {
            type: 'number',
            name: 'diceControls',
            defaultValue: 0,
        },
        inputRender(value: number, onChange: (value: number) => void) {
            return (
                <>
                    <Icon icon="dice" onClick={() => onChange(0)} />
                </>
            );
        },
    }),
);
