import React from 'react';
import styled from 'styled-components';
import { Color } from '../../../40-utils/color/Color';
import { factor } from '../../../40-utils/IFactory';
import { useSkin } from '../../../40-utils/react-hooks/useSkin';
import { ISkin } from '../../../50-systems/StyleSystem/ISkin';
import { TranslateContext } from '../../../50-systems/TranslationsSystem/components/TranslateContext';
import { AsyncContentComponent } from '../AsyncContentComponent';
import { IAsyncSelectComponentProps } from './0-IAsyncSelectComponentProps';

/**
 * Select component using html <select>
 *
 * @collboard-modules-sdk
 */
export function AsyncSelectComponent<TItem>({ options, value, onChange }: IAsyncSelectComponentProps<TItem>) {
    const translationsSystem = React.useContext(/* <- TODO: Import and use just a useContext */ TranslateContext);
    const [isLoading, setLoading] = React.useState(/* <- TODO: Import and use just a useState */ false);

    return (
        <SelectElement
            {...useSkin()}
            disabled={isLoading}
            onChange={async (event) => {
                setLoading(true);
                const optionIndex = parseInt(event.target.value, 10);
                if (optionIndex === -1) {
                    return;
                }
                const newValue = (await (await factor(options))[optionIndex]).value;
                await onChange(newValue);
                setLoading(false);
            }}
        >
            {value === null && (
                <option value={-1} selected>
                    –⁠–⁠–⁠
                </option>
            )}
            <AsyncContentComponent
                alt="select options for AsyncSelectComponent"
                loader={
                    <option value={-1} disabled>
                        ...{/* TODO: What is the best text for this loading */}
                    </option>
                }
                content={async () =>
                    (await factor(options)).mapAsync((option, optionIndex) => (
                        <AsyncContentComponent
                            alt={`select option #${optionIndex} for AsyncSelectComponent`}
                            loader={
                                <option key={optionIndex} value={optionIndex} disabled>
                                    ...{/* TODO: What is the best text for this loading */}
                                </option>
                            }
                            content={async () => (
                                <option
                                    key={optionIndex}
                                    value={optionIndex}
                                    // TODO: [🍨] Warning: Use the `defaultValue` or `value` props on <select> instead of setting `selected`
                                    selected={(await option).value === value}
                                    disabled={((await option) as any).isDisabled || false}
                                >
                                    {
                                        // TODO: Can we use here JSX (non-string) message @see https://www.w3schools.com/howto/howto_custom_select.asp
                                        translationsSystem.pickStringMessage((await option).title)
                                    }
                                </option>
                            )}
                        />
                    ))
                }
            />
        </SelectElement>
    );
}

const SelectElement = styled.select<{ skin: ISkin }>`
    border: solid 1.5px ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    background-color: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    border-radius: ${({ skin }) => skin.borderRadius}px;
    padding: 7px 18px;
    font-size: 0.9em;
    margin: 0 5px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;

    &:hover {
        border: solid 1.5px ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
    }

    option:disabled {
        color: #ccc;
    }
`;

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
