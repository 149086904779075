import { spaceTrim } from 'spacetrim';
import { parseModuleName } from '../../../40-utils/parseModuleName';
import { RemoveIndex } from '../../../40-utils/typeHelpers';
import { hostToBusiness } from '../../BusinessSystem/utils/hostToBusiness';
import { IUrlVariables } from './IUrlVariables';

export function encodeUrl({
    protocol,
    host,
    businessName,
    uriId,
    extension,
    moduleName: module,
    modulePath,
}: RemoveIndex<IUrlVariables>): URL {
    if (!uriId && extension) {
        throw new Error(`Cannot convert URL with no uriId but extension.`);
    }
    if (extension && module) {
        throw new Error(`Cannot convert URL with extension and module.`);
    }

    if (hostToBusiness(host).businessName !== businessName) {
        throw new Error(
            spaceTrim(`
              Business from host "${host}" is ${hostToBusiness(host)} but in given variables recieved ${businessName}.
              Note: When you are changing RoutingSystem.urlVariables, change host with businessName together.`),
        );
    }

    let urlString = `${protocol}//${host}/${uriId ? uriId : module ? '-' : ''}${extension ? '.' + extension : ''}${
        module
            ? `/${(() => {
                  const { name, scope } = parseModuleName(module);
                  if (scope === 'collboard') {
                      return name.join('/');
                  } else {
                      return `@${scope}/${name.join('/')}`;
                  }
              })()}${modulePath ? `/~/${modulePath}` : ''}`
            : ''
    }`;

    // Note: Replace multiple slashes to one.
    urlString = urlString.split(/\/+/).join('/');

    // Note: Removal of trailing / or /~
    urlString = urlString.split(/(\/+~?)+$/)[0]!;

    try {
        return new URL(urlString);
    } catch (error) {
        if (error instanceof TypeError) {
            throw new TypeError(`${error.message} "${urlString}"`);
        } else {
            throw error;
        }
    }
}
