import { ILogger, ISubLogger } from './ILogger';

interface IFilterLoggerOptions {
    // TODO: Unite IRecordLoggerLog.type and IFilterLoggerOptions.predicate.type
    predicate: (options: { type: keyof ILogger; message: any; index: number }) => boolean;
    logger: ILogger;
}

export class FilterLogger implements ILogger {
    private index = 0;

    public constructor(private readonly options: IFilterLoggerOptions) {}

    public info(message?: any, ...optionalParams: Array<any>): this {
        if (this.options.predicate({ message, index: this.index++, type: 'info' })) {
            this.options.logger.info(message, ...optionalParams);
        }
        return this;
    }
    public warn(warning?: any, ...optionalParams: Array<any>): this {
        if (this.options.predicate({ message: warning, index: this.index++, type: 'warn' })) {
            this.options.logger.warn(warning, ...optionalParams);
        }
        return this;
    }
    public error(error?: Error | any, ...optionalParams: Array<any>): this {
        if (this.options.predicate({ message: error, index: this.index++, type: 'error' })) {
            this.options.logger.error(error, ...optionalParams);
        }
        return this;
    }

    public trace(...data: Array<any>): this {
        if (this.options.predicate({ message: null, index: this.index++, type: 'trace' })) {
            this.options.logger.trace(...data);
        }
        return this;
    }

    public group(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        return this.options.logger.group(groupName, ...optionalParams);
    }

    public groupCollapsed(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        return this.options.logger.group(groupName, ...optionalParams);
    }
}

/**
 * TODO: ALso filter on group and groupCollapsed
 */
