import { CSSProperties } from 'react';
import { Transform, transformToStyleCss } from 'xyzt';
import { AbstractEnvironmentArt } from './25-AbstractEnvironmentArt';

/*
 * Note: [🖇] This is not in the internal module because some of the Arts are so
 *       tightly connected with the core that they need to be there, not in
 *       optionally deactivateable module.
 *
 */

/**
 * This art holds information about a backgroud image of the board.
 *
 * @collboard-modules-sdk
 */
export abstract class AbstractBackgroundArt extends AbstractEnvironmentArt {
    /**
     * @param z Z-position of background. Currently unused
     */
    public constructor(public z: number = 0) {
        super();
    }

    /**
     * Getter with static CSS properties for the `<div class="board-bg">`
     * rendered within `BoardComponent`
     */
    public abstract additionalStyle(): CSSProperties;

    /**
     * Getter with dynamic CSS properties for the `<div class="board-bg">`
     * rendered within `BoardComponent`
     *
     * @argument transform AppState board transform
     */
    public transformStyle(transform: Transform): CSSProperties {
        const { x, y } = transform.translate;
        return {
            backgroundPosition: `calc(50% + ${x}px) calc(50% + ${y}px)`,
            transform: transformToStyleCss(transform.pick('rotate')),
        };
    }
}

/**
 * TODO: [🎚️] Maybe get rit of AbstractArts and make makers for arts which returns IArts
 */
