import { topleftArts } from '../../../50-systems/ImportSystem/utils/topleftArts';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { TextArt } from '../../../71-arts/50-TextArt/TextArt';

internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/text-import',
        deprecatedNames: ['@collboard/text-import', 'TextImport'],
        supports: {
            fileImport: ['text/plain'],
        },
    },
    async setup(systems) {
        const { importSystem, appState, collSpace } = await systems.request('importSystem', 'appState', 'collSpace');

        // TODO: What about installations that need to be reinstalled after board is changed?
        // Note: For lot of systems we are using this makeWhatever helpers. I am trying one system - ImportSystem without make helper to modules just to use this systems methods directly.
        return importSystem.registerFileSupport({
            priority: -10 /* TODO: !! Prompt use if they want to import as text or as html */,

            async importFile({ file, boardPosition, willCommitArts, next }) {
                if (file.type !== 'text/plain') {
                    return next();
                }

                willCommitArts();

                const textArt = new TextArt(
                    await file.text(),
                    'black',
                    21 / appState.transform.value.scale.x,
                    false,
                    false,
                    false,
                    'none',
                );

                // TODO: [🎼] Here should be some combination between topleftArts and centerArts
                //     > centerArts({ arts: [textArt], center: boardPosition });

                topleftArts({
                    arts: [textArt],
                    collSpace,
                });

                return textArt;
            },
        });
    },
}));
