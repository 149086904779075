import React from 'react';
import { IBaseMessage } from '../../50-systems/TranslationsSystem/interfaces/IMessage';

type IObfuscateProps =
    React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{
        content: IBaseMessage;
        buttonContent: IBaseMessage;
    }>;

/**
 * @collboard-modules-sdk
 */
export function Obfuscate({ children, content: description, buttonContent: button }: IObfuscateProps) {
    const [hidden, setHidden] = React.useState(/* <- TODO: Import and use just a useState */ true);

    return hidden ? (
        <>
            <button
                type="button"
                className="button button-primary modal-button"
                style={{ marginLeft: 0 }}
                onClick={() => setHidden(false)}
            >
                {button}
            </button>
            <span style={{ paddingLeft: 10, opacity: 0.5, fontStyle: 'italic' }}>{description}</span>
        </>
    ) : (
        <>{children}</>
    );
}
