/**
 * Utility for testing parts of the application that have unpredicted duration
 *
 * Note:
 *  - In **production** this will resolve immediately
 *  - During the **development** it will wait random amount of time which can be debuged
 *
 *
 * @returns Promise with resolves
 */
export async function forUnpredictedTime(): Promise<void> {
    return;
}
