import { JsonValue } from 'type-fest';
import { IInstantiable } from '../interfaces/IInstantiable';
import { ISerializerRule } from '../interfaces/ISerializerRule';

/**
 * @@x
 */

export interface IClassSerializerRule {
    name: string;
    Class: IInstantiable;
}

/**
 * @@x
 * @param classSerializerRule @@x
 * @returns ISerializerRule @@x
 */
export function createSerializerRuleForClass(classSerializerRule: IClassSerializerRule): ISerializerRule {
    const { name, Class /*checkInstance, checkSerialized*/ } = classSerializerRule;
    return {
        name,
        priority: 20 /* <- TODO: [🍉] One place with priorities */,
        serialize({ value, serialize, next }) {
            if (!(value instanceof Class)) {
                return next();
            }

            const serialized: JsonValue = { __class: name };

            for (const key of Object.keys(value)) {
                if (key.startsWith('__')) {
                    continue;
                }

                const item = value[key];

                if (item === undefined) {
                    // [🕳️]
                    continue;
                }

                serialized[key] = serialize(item);
            }

            return serialized;
        },
        deserialize({ serialized, deserialize, next }) {
            if (serialized === null) {
                return next();
            }

            if (typeof serialized !== 'object') {
                return next();
            }

            if (!('__class' in serialized)) {
                return next();
            }

            if (serialized.__class !== name) {
                return next();
            }

            const instance = new Class();

            for (const key in serialized /* <- Note: Here is irelevant if using for...in or for...of Object.keys( because `serialized` is just a pure base object */) {
                if (key === '__class') {
                    continue;
                }

                instance[key] = deserialize(serialized[key] as JsonValue);
            }

            return instance;
        },
    };
}
