import EmojiRegex from 'emoji-regex';
import { EMOJIS } from './emojis';
import { string_char_emoji } from './typeAliasEmoji';

const emojiRegex = EmojiRegex();

/**
 *
 * @param value some char
 * @returns true if value is emoji
 *
 * @collboard-modules-sdk
 */
export function isEmoji(value: string): value is string_char_emoji {
    if (EMOJIS.has(value)) {
        return true;
    }

    if (emojiRegex.test(value) && value.split(emojiRegex).join('') === '' && value.split(emojiRegex).length === 2) {
        return true;
    }

    if (value === '⭐️' /* <- TODO: Unhardcode */) {
        return true;
    }

    return false;
}

/**
 * Note: Not using only EMOJIS or EMOJIS_IN_CATEGORIES because there can be a new emoji in the future which wont be yet in our system
 * TODO: Some smarter way how to detect new emojis without listing them in EMOJIS
 */
