import React from 'react';
import styled from 'styled-components';
import { ModalGroup } from '../../../../30-components/modal/ModalGroup/ModalGroup';
import { AsyncButtonComponent } from '../../../../30-components/utils/AsyncButtonComponent';
import { Color } from '../../../../40-utils/color/Color';
import { confirmDialogue } from '../../../../40-utils/dialogues/confirmDialogue';
import { useObservable } from '../../../../40-utils/react-hooks/useObservable';
import { useSkin } from '../../../../40-utils/react-hooks/useSkin';
import { useSystems } from '../../../../40-utils/react-hooks/useSystems';
import { ISkin } from '../../../StyleSystem/ISkin';
import { Translate } from '../../../TranslationsSystem/components/Translate';
import { LicensesListModules } from './15-LicensesListModules';

export function LicensesListModalGroup() {
    const { skin } = useSkin();
    const { licenseSystem } = useSystems('licenseSystem');
    const { value: licensesTokens } = useObservable(licenseSystem.licensesTokens);

    if (licensesTokens.size === 0) {
        return <Translate name="Licenses / No active licenses">Nemáte žádnou aktivní licenci.</Translate>;
    }

    return (
        // TODO: [🥇] Work also when no licenses
        <ModalGroup
            isTitleVisibleOnSmallScreen={false}
            title={<Translate name={`Licenses / Active licenses`}>Vaše aktivní licence:</Translate>}
        >
            {Array.from(licensesTokens).map((licenseToken) => (
                <LicenseDiv key={licenseToken} {...{ skin }}>
                    <h2>
                        <Translate name="Licenses / License">Licence</Translate>
                        {` `}
                        {licenseToken /* <- [🥇][🥇0][🥇1] Mask the license number */}
                    </h2>

                    <LicensesListModules {...{ licenseToken }} />

                    <AsyncButtonComponent
                        alt="Removing license"
                        className="button button-primary modal-button"
                        style={{ marginLeft: 0 }}
                        onClick={async () => {
                            if (
                                !(await confirmDialogue(
                                    <Translate name="Licenses/ Confirm remove">
                                        Opravdu chcete zahodit tuto licenci?
                                    </Translate>,
                                ))
                            ) {
                                return;
                            }

                            await licenseSystem.removeLicenseToken(licenseToken);
                        }}
                    >
                        <Translate name="Licenses / Remove license">Odebrat licenci</Translate>
                    </AsyncButtonComponent>
                </LicenseDiv>
            ))}
        </ModalGroup>
    );
}

const LicenseDiv = styled.div<{ skin: ISkin }>`
    padding-bottom: 20px;

    &:not(:last-child) {
        border-bottom: dotted 4px ${({ skin }) => Color.from(skin.colors.light).opaque.toString()} /* <- TODO: [🚥] */;
        /* TODO: [🚥] Make here nice freehanddrawn <hr> as a separator */
    }
`;

/**
 * TODO: What are propper  translate names "Licenses/...", "LicensesList/...", "@collboard/internal/licenses/...", "@collboard/internal/licenses/Licenses/..." or some other way
 */
