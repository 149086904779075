import React from 'react';
import { forEver, forTime } from 'waitasecond';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../../50-systems/ModuleStore/makers/makeModalModule';
import { ModalScroll } from '../../modal/ModalScroll/ModalScroll';
import { AsyncButtonComponent } from '../AsyncButtonComponent';
import { Loader } from './Loader';
import { LoaderInline } from './LoaderInline';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            name: '@test/loader',
            description: 'Module made just for internal testing of misc loader components',
            flags: {
                isHidden: true /* <- TODO: Modal modules should be always hidden*/,
            },
            requirePermissions: [],
        },
        async createModal() {
            return (
                <ModalScroll>
                    <Loader alt="Testing of loader" />
                    <hr />
                    {/* TODO: [🚥] <ModalLine /> */}
                    <div style={{ outline: `1px dotted red`, display: 'inline-block', height: 100, width: '50%' }}>
                        <Loader canLoadForever alt="Testing of loader" isWithBackground />
                    </div>
                    <div style={{ outline: `1px dotted green`, display: 'inline-block', height: 100, width: '50%' }}>
                        <Loader canLoadForever alt="Testing of loader" isWithBackground />
                    </div>
                    <hr />
                    {/* TODO: [🚥] <ModalLine /> */}
                    <div style={{ outline: `1px dotted blue`, display: 'inline-block', height: 100, width: '50%' }}>
                        <Loader canLoadForever alt="Testing of loader" isWithBackground />
                    </div>
                    <div style={{ outline: `1px dotted magenta`, display: 'inline-block', height: 100, width: '50%' }}>
                        <Loader canLoadForever alt="Testing of loader" />
                    </div>
                    <hr />
                    {/* TODO: [🚥] <ModalLine /> */}
                    <LoaderInline canLoadForever alt="Testing of loader" icon="package" />
                    <LoaderInline canLoadForever alt="Importing" icon="file-image" animation="spinning">
                        Something is loading
                    </LoaderInline>
                    <hr />
                    {/* TODO: [🚥] <ModalLine /> */}

                    {[1, 5, 10, 20, 60, 300, 600].map((duration) => (
                        <AsyncButtonComponent
                            key={duration}
                            alt="Testing of AsyncButtonComponent"
                            onClick={async () => {
                                await forTime(1000 * duration);
                            }}
                        >
                            Do something for {duration}s
                        </AsyncButtonComponent>
                    ))}

                    <AsyncButtonComponent
                        alt="Testing of AsyncButtonComponent"
                        href="https://collboard.com/"
                        onClick={async () => {
                            await forEver();
                        }}
                    >
                        Do something infinite
                    </AsyncButtonComponent>

                    <AsyncButtonComponent
                        alt="Testing of AsyncButtonComponent"
                        className="button button-primary modal-button"
                        onClick={async () => {
                            await forTime(1000 * 30);
                        }}
                    >
                        Button
                    </AsyncButtonComponent>
                </ModalScroll>
            );
        },
    }),
);

/**
 * TODO: [☮️] Test here new loading design
 */
