import { Registration } from 'destroyable';
import React from 'react';
import { Vector } from 'xyzt';
import { Icon } from '../30-components/menu/Icon';
import { Separator } from '../30-components/menu/Separator';
import { string_color } from '../40-utils/typeAliases';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeAttributeModule } from '../50-systems/ModuleStore/makers/makeAttributeModule';
import { makeIconModuleOnModule } from '../50-systems/ModuleStore/makers/makeIconModuleOnModule';
import { ToolbarName } from '../50-systems/ToolbarSystem/0-ToolbarSystem';
import { LineArt } from '../71-arts/50-LineArt';
import { ShapeArt, ShapeName } from '../71-arts/50-ShapeArt';
internalModules.declareModule(() => {
    return makeIconModuleOnModule({
        manifest: {
            name: '@collboard/internal/shape-tool',
            deprecatedNames: ['@collboard/shape-tool', 'ShapeTool'],
            title: { en: 'Basic shapes', cs: 'Základní tvary' },
            // Note: for basic modules omitting the description: { en: '', cs: '' },

            categories: ['Basic', 'Art'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.46/icons/shapes.svg',

            author: Authors.collboard,
        },
        toolbar: ToolbarName.Tools,
        async icon(systems) {
            const { attributesSystem } = await systems.request('attributesSystem');
            return {
                //icon: ({ attributesSystem }) => ({
                name: 'shape',
                autoSelect: true,
                order: 20,
                icon: 'shapes',
                boardCursor: 'crosshair',
                menu: (
                    <>
                        {(await attributesSystem).inputRender('shapeName')}
                        <Separator />
                        {(await attributesSystem).inputRender('weight')}
                        <Separator />
                        {(await attributesSystem).inputRender('color')}
                    </>
                ),
            };
        },
        moduleActivatedByIcon: {
            async setup(systems) {
                const { touchController, collSpace, appState, attributesSystem, materialArtVersioningSystem } =
                    await systems.request(
                        'touchController',
                        'collSpace',
                        'appState',
                        'attributesSystem',
                        'materialArtVersioningSystem',
                    );
                return Registration.fromSubscription((registerAdditionalSubscription) =>
                    touchController.touches.subscribe(async (touch) => {
                        const firstPointOnBoard = collSpace.pickPoint(touch.firstFrame.position).point;

                        let artInProcess: ShapeArt | LineArt;
                        if ((attributesSystem.getAttributeValue('shapeName').value as ShapeName) === ShapeName.Line) {
                            artInProcess = new LineArt(
                                attributesSystem.getAttributeValue('color').value as string_color,
                                (attributesSystem.getAttributeValue('weight').value as number) /
                                    appState.transform.value.scale.x,
                                firstPointOnBoard,
                                firstPointOnBoard,
                            );
                        } else {
                            artInProcess = new ShapeArt(
                                attributesSystem.getAttributeValue('shapeName').value as ShapeName,
                                attributesSystem.getAttributeValue('color').value as string_color,
                                (attributesSystem.getAttributeValue('weight').value as number) /
                                    appState.transform.value.scale.x,
                                firstPointOnBoard,
                                Vector.zero(),
                            );
                        }

                        const operation = materialArtVersioningSystem.createPrimaryOperation();
                        operation.newArts(artInProcess);

                        registerAdditionalSubscription(
                            touch.frames.subscribe(
                                async (touchFrame) => {
                                    const lastPointOnBoard = collSpace.pickPoint(touchFrame.position).point;

                                    /**/
                                    // Flipping:
                                    artInProcess.shift = firstPointOnBoard;
                                    artInProcess.size = lastPointOnBoard.subtract(artInProcess.shift);
                                    /**/

                                    /*/
                                    //Not flipping:
                                    artInProcess.shift = new Vector(
                                        // TODO: DRY
                                        Math.min(firstPointOnBoard.x, lastPointOnBoard.x),
                                        Math.min(firstPointOnBoard.y, lastPointOnBoard.y),
                                    );
                                    artInProcess.size = new Vector(
                                        // TODO: DRY
                                        Math.max(firstPointOnBoard.x, lastPointOnBoard.x),
                                        Math.max(firstPointOnBoard.y, lastPointOnBoard.y),
                                    ).subtract(artInProcess.shift);
                                    /**/

                                    operation.update(artInProcess);
                                },
                                () => {},
                                () => {
                                    operation.persist();
                                },
                            ),
                        );
                    }),
                );
            },
        },
    });
});

internalModules.declareModule(() =>
    makeAttributeModule<ShapeName>({
        manifest: {
            name: 'ShapeNameAttribute',
        },
        standard: true,
        attribute: {
            type: 'string',
            name: 'shapeName' /* TODO: How to prevent collisions */,
            defaultValue: ShapeName.Line,
        },
        inputRender(value: ShapeName, onChange: (value: ShapeName) => void) {
            return (
                <>
                    {/* TODO: DRY - iterate */}
                    <Icon
                        icon="line"
                        active={value === ShapeName.Line}
                        onClick={() => {
                            onChange(ShapeName.Line);
                        }}
                    />
                    <Icon
                        icon="square"
                        active={value === ShapeName.Rectange}
                        onClick={() => {
                            onChange(ShapeName.Rectange);
                        }}
                    />
                    <Icon
                        icon="circle"
                        active={value === ShapeName.Circle}
                        onClick={() => {
                            onChange(ShapeName.Circle);
                        }}
                    />
                    <Icon
                        icon="triangle"
                        active={value === ShapeName.Triangle}
                        onClick={() => {
                            onChange(ShapeName.Triangle);
                        }}
                    />
                    <Icon
                        icon="triangle-right"
                        active={value === ShapeName.TriangleRight}
                        onClick={() => {
                            onChange(ShapeName.TriangleRight);
                        }}
                    />
                </>
            );
        },
    }),
);

/**
 * TODO: Maybe create separate @collboard/internal/line-tool
 * TODO: Allow to flip the shape - maybe some attibute / switcher for it?
 * TODO: [🍩][🧠] Requesting systems non-magically
 * TODO: [🍩] Omitting systems.request should fail bacause of unrequested systems.
 * TODO: [🈁]
 */
