import { parseKeywords, searchKeywords } from '@promptbook/utils';
import { flagsToObject } from '../../../40-utils/flagsToObject';
import { patternToRegExp } from '../../../40-utils/patternToRegExp';
import { toArray } from '../../../40-utils/toArray';
import { BusinessName } from '../../BusinessSystem/configuration/BusinessName';
import { IBusinessConfiguration } from '../../BusinessSystem/interfaces/IBusinessConfiguration';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { IModuleSearchCriteria } from '../interfaces/IModuleSearchCriteria';
import { isExperimental } from './isExperimental';
import { isPresented } from './isPresented';
import { parseKeywordsFromManifest } from './parseKeywordsFromManifest';

abstract class SemanticBoolean {
    public abstract readonly value: boolean;
    protected constructor(public readonly justification: string) {}
}

class Yes extends SemanticBoolean {
    public value = true;
    public static because(justification: string): Yes {
        return new Yes(justification);
    }
}

class No extends SemanticBoolean {
    public value = false;
    public static because(justification: string): No {
        return new No(justification);
    }
}

export function isModulePassingSearchCriteria({
    moduleManifest,
    searchCriteria,
    businessName,
    businessConfiguration,
}: {
    searchCriteria: IModuleSearchCriteria;
    moduleManifest?: IModuleManifest;
    businessName?: BusinessName;
    businessConfiguration?: IBusinessConfiguration;
}): SemanticBoolean {
    if (!moduleManifest) {
        return No.because(`Anonymous module can not pass any search criteria`);
    }

    // TODO: Unit test this function
    // TODO: Cannot combine multiple supports OR suppports and other params like needle (Maybe to type)

    if (searchCriteria.name) {
        if (searchCriteria.name === moduleManifest.name) {
            return Yes.because(`Matching the name`);
        } else if (toArray(moduleManifest.deprecatedNames).includes(searchCriteria.name)) {
            return Yes.because(`Matching the deprecated name`);
        } else {
            return No.because(`Searching by name and not matching the name or any of deprecated names`);
        }
    }

    if (searchCriteria.supports?.art) {
        if (toArray(moduleManifest.supports?.art).includes(searchCriteria.supports.art)) {
            return Yes.because(`Supports the art`);
        } else {
            return No.because(`Do not support the art`);
        }
    }

    if (searchCriteria.supports?.attribute) {
        if (toArray(moduleManifest.supports?.attribute).includes(searchCriteria.supports.attribute)) {
            return Yes.because(`Supports the attribute`);
        } else {
            return No.because(`Do not support the attribute`);
        }
    }

    if (searchCriteria.supports?.fileImport) {
        if (
            toArray(moduleManifest.supports?.fileImport).some((mimeTypeWildcard) =>
                patternToRegExp(mimeTypeWildcard).test(searchCriteria.supports!.fileImport!),
            )
        ) {
            return Yes.because(`Supports the file`);
        } else {
            return No.because(`Do not support the file`);
        }
    }

    if (searchCriteria.usageLicenseToken) {
        if (
            (moduleManifest.usageLicenses || []).some(
                (usageLicense) =>
                    usageLicense.type === 'SIMPLE_TOKEN' &&
                    usageLicense.isModuleAutoInstalled &&
                    usageLicense.token === searchCriteria.usageLicenseToken,
            )
        ) {
            return Yes.because(`Is in the license`);
        } else {
            return No.because(`Is not in the license`);
        }
    }

    const { needle, category } = searchCriteria;
    const needleKeywords = parseKeywords(needle);
    const isSearchingThroughHidden = needleKeywords.has('hidden' /* <- TODO: This should be probbablt :hidden */);
    needleKeywords.delete('hidden');

    if (flagsToObject(moduleManifest.flags).isHidden && !isSearchingThroughHidden) {
        return No.because(`The module is hidded`);
    }

    if (isExperimental(moduleManifest) && !category && !needle) {
        return No.because(`The module is experimental and the search has no category or needle`);
    }

    if (businessName && businessName !== BusinessName.Development) {
        if (!isPresented(moduleManifest, businessConfiguration) && !isSearchingThroughHidden) {
            return No.because(`In production show only finished modules`);
        }
    }

    if (needle && moduleManifest && !searchKeywords(parseKeywordsFromManifest(moduleManifest), needleKeywords)) {
        return No.because(`No keywords matching`);
    }

    if (category && !(moduleManifest.categories || []).some((category2) => category2 === category)) {
        return No.because(`No categories matching`);
    }

    return Yes.because(`keywords and categories are matching and module is finished and visible`);
}

/**
 * TODO: Unit test
 * TODO: Make from SemanticBoolean a library if it is usefull in other places
 * TODO: Throw or warn when providing unknown search criteria
 */
