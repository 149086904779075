import React from 'react';
import { BehaviorSubject } from 'rxjs';
import { useObservable } from '../../../40-utils/react-hooks/useObservable';
import { string_attribute, string_attribute_value_scope } from '../../../40-utils/typeAliases';
import { AttributesSystem } from '../0-AttributesSystem';
import { attribute_value } from '../IAttribute';
import { IAttributeRule } from '../IAttributeRule';
import { InputRender } from './InputRender';

interface IInputsRenderProps {
    attributesManager: AttributesSystem;
    attributesRules: BehaviorSubject<Array<IAttributeRule<attribute_value>>>;
    attributeName: string_attribute;
    overrideValue?: attribute_value;
    valueScope?: string_attribute_value_scope; // TODO: Rename context IN ALL files to value context
    additionaOnChange?: (value: attribute_value) => void; // TODO: Rename to somenting that sounds more important and group with defaultValue
}

// TODO: Bit confusing similar namings InputsRender vs. InputRender
// TODO: Probably name component

export function InputsRender(props: IInputsRenderProps) {
    // console.log('🅰️', 'InputsRender', 'render');

    const {
        attributesManager,
        attributesRules,
        attributeName,
        overrideValue,
        valueScope: context,
        additionaOnChange,
    } = props;

    const { value: currentAttributesRules } = useObservable(attributesRules);

    const attributeValue = attributesManager.getAttributeValue(attributeName, context);

    // Note: Refresh when value is changed
    useObservable(attributeValue);

    // Note: Decide if we want to show standard attribute render all other non-standard if there are any
    const attributeRulesFiltered =
        currentAttributesRules.length > 1
            ? currentAttributesRules.filter((inputRender) => !inputRender.standard)
            : currentAttributesRules;

    return (
        <>
            {attributeRulesFiltered.map((rule) => (
                <InputRender
                    key={rule.moduleName + rule.attribute.name}
                    inputRender={rule.inputRender}
                    value={overrideValue ? new BehaviorSubject<attribute_value>(overrideValue) : attributeValue}
                    onChange={(value) => {
                        // console.log('🅰️', 'InputsRender', 'render', 'onChange', { attributeName, context, value });

                        attributeValue.next(value);

                        if (additionaOnChange) {
                            additionaOnChange(value);
                        }
                    }}
                />
            ))}
        </>
    );
}
