import { ILogger, ISubLogger } from './ILogger';

/**
 * This logger will take one callback and puts everything there
 */
export class FlatLogger implements ILogger, ISubLogger {
    public constructor(
        private readonly onLog: (
            type: 'INFO' | 'WARNING' | 'ERROR',
            message?: any,
            ...optionalParams: Array<any>
        ) => void,
    ) {}

    public info(message?: any, ...optionalParams: Array<any>): this {
        this.onLog('INFO', message, ...optionalParams);
        return this;
    }
    public warn(warning?: any, ...optionalParams: Array<any>): this {
        this.onLog('WARNING', warning, ...optionalParams);
        return this;
    }
    public error(error?: Error | any, ...optionalParams: Array<any>): this {
        this.onLog('ERROR', error, ...optionalParams);
        return this;
    }

    public trace(...data: Array<any>): this {
        // Do nothing here
        return this;
    }

    public appear(): this {
        // Do nothing here
        return this;
    }

    public end(): void {
        // Do nothing here
    }

    public group(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        return this;
    }

    public groupCollapsed(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        return this;
    }
}
