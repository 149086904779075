import styled from 'styled-components';
import { Color } from '../../../../40-utils/color/Color';
import { ISkin } from '../../../StyleSystem/ISkin';

export const AnnouncementStyledDiv = styled.div<{ skin: ISkin; order: number }>`
    position: fixed;
    left: calc(10vh + ${({ order }) => order * 200}px);
    top: 0;
    width: 350px;
    color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
    z-index: ${({ order }) => 10000 + order};
    font-size: 0.8em;

    @media /*[🍕]*/ only screen and (max-width: 1220px) {
        display: none;
    }

    :before {
        position: fixed;
        left: 0;
        top: 0;
        content: '';
        display: block;
        width: 100%;
        height: calc(100% + 120px);
        z-index: -10011;
        background: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
        background: linear-gradient(
            180deg,
            ${({ skin }) => Color.from(skin.colors.dark).withAlpha(1).toString()} 0%,
            ${({ skin }) => Color.from(skin.colors.dark).withAlpha(1).toString()} calc(100% - 100px),
            ${({ skin }) => Color.from(skin.colors.dark).withAlpha(0).toString()} 100%
        );
    }

    .announcement-content {
        max-height: 80vh;
        padding: 0 30px;
        overflow-y: auto;
    }

    .title {
        background: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
        border-radius: 0 ${({ skin }) => skin.borderRadius}px ${({ skin }) => skin.borderRadius}px 0;
        padding: 16px 20px 14px 30px;
        margin: 0 -20px 20px -30px;
        font-size: 1.4em;
    }

    .subtitle {
        text-align: right;
        font-size: 0.7em;
        margin-top: -5px;
    }

    .title strong {
        font-size: 1.4em;
    }

    p {
        line-height: 1.6em;
        margin-bottom: 1.5em;
    }

    p.gray {
        color: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
    }

    a {
        color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
    }

    .button {
        margin-left: 0 !important;
    }
`;
