import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { interval } from 'rxjs';
import { debounce, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { TouchController } from 'touchcontroller';
import { forImmediate } from 'waitasecond';
import { setSystemsForColldev } from './00-modules/development/DevelopmentColldevModule';
import { RootComponentForBoard } from './30-components/RootComponentForBoard';
import { RootComponentForWelcome } from './30-components/RootComponentForWelcome';
import { GlobalStyles } from './35-styles/GlobalStyles';
import { BoardNotFoundError } from './40-utils/errors/BoardNotFoundError';
import { isPrivateNetwork } from './40-utils/isPrivateNetwork';
import { UnsignedSystemsContainerContext } from './40-utils/react-hooks/useSystemsInCore';
import { syncServiceWorkers } from './40-utils/syncServiceWorkers';
import { Queue } from './40-utils/tasks/Queue';
import { string_translate_language, string_url } from './40-utils/typeAliases';
import { RemoveIndex } from './40-utils/typeHelpers';
import { SystemsContainer } from './50-systems/00-SystemsContainer/SystemsContainer';
import { Core } from './50-systems/30-Core/0-Core';
import { ApiClient } from './50-systems/ApiClient/0-ApiClient';
import { AppState } from './50-systems/AppState/0-AppState';
import { ArtSerializer } from './50-systems/ArtSerializer/ArtSerializer';
import { MaterialArtVersioningSystem } from './50-systems/ArtVersionSystem/0-MaterialArtVersioningSystem';
import { VirtualArtVersioningSystem } from './50-systems/ArtVersionSystem/0-VirtualArtVersioningSystem';
import { CORE_MODULE_SIGNATURE } from './50-systems/ArtVersionSystem/IModuleSignature';
import { AttributesSystem } from './50-systems/AttributesSystem/0-AttributesSystem';
import { BoardSystem } from './50-systems/BoardSystem/0-BoardSystem';
import { BusinessSystem } from './50-systems/BusinessSystem/0-BusinessSystem';
import { BusinessName } from './50-systems/BusinessSystem/configuration/BusinessName';
import { ClosePreventionSystem } from './50-systems/ClosePreventionSystem/0-ClosePreventionSystem';
import { CollSpace } from './50-systems/CollSpace/0-CollSpace';
import { ControlSystem } from './50-systems/ControlSystem/ControlSystem';
import { CreateSystem } from './50-systems/CreateSystem/0-CreateSystem';
import { ExportSystem } from './50-systems/ExportSystem/0-ExportSystem';
import { FilepickSystem } from './50-systems/FilepickSystem/0-FilepickSystem';
import { FocusSystem } from './50-systems/FocusSystem/0-FocusSystem';
import { FractalSystem } from './50-systems/FractalSystem/0-FractalSystem';
import { GamificationSystem } from './50-systems/GamificationSystem/0-GamificationSystem';
import { GenerateSystem } from './50-systems/GenerateSystem/0-GenerateSystem';
import { HintSystem } from './50-systems/HintSystem/0-HintSystem';
import { IdentitySystem } from './50-systems/IdentitySystem/0-IdentitySystem';
import { ImportSystem } from './50-systems/ImportSystem/0-ImportSystem';
import { LicenseSystem } from './50-systems/LicenseSystem/0-LicenseSystem';
import { LicenseSyncer } from './50-systems/LicenseSystem/LicenseSyncer';
import { MessagesApiSystem } from './50-systems/MessagesApiSystem/0-MessagesApiSystem';
import { ModuleStore } from './50-systems/ModuleStore/connectors/0-ModuleStore';
import { ExternalModuleStoreConnector } from './50-systems/ModuleStore/connectors/ExternalModuleStoreConnector';
import { internalModules } from './50-systems/ModuleStore/internalModules';
import { ArtSupportSyncer } from './50-systems/ModuleStore/Syncers/ArtSupportSyncer';
import { AttributeSupportSyncer } from './50-systems/ModuleStore/Syncers/AttributeSupportSyncer';
import { CornerstoneSyncer } from './50-systems/ModuleStore/Syncers/CornerstoneSyncer';
import { FileSupportSyncer } from './50-systems/ModuleStore/Syncers/FileSupportSyncer';
import { RouteAndBusinessSyncer } from './50-systems/ModuleStore/Syncers/RouteAndBusinessSyncer';
import { NotificationSystem } from './50-systems/NotificationSystem/0-NotificationSystem';
import { PointerSystem } from './50-systems/PointerSystem/0-PointerSystem';
import { RoutingSystem } from './50-systems/RoutingSystem/0-RoutingSystem';
import { IUrlVariables } from './50-systems/RoutingSystem/routePath/IUrlVariables';
import { SnapSystem } from './50-systems/SnapSystem/0-SnapSystem';
import { SoundSystem } from './50-systems/SoundSystem/0-SoundSystem';
import { StorageSystem } from './50-systems/StorageSystem/StorageSystem';
import { StyleSystem } from './50-systems/StyleSystem/0-StyleSystem';
import { TestSystem } from './50-systems/TestSystem/0-TestSystem';
import { ToolbarSystem } from './50-systems/ToolbarSystem/0-ToolbarSystem';
import { TranslationsSystem } from './50-systems/TranslationsSystem/0-TranslationsSystem';
import { UsercontentSystem } from './50-systems/UsercontentSystem/0-UsercontentSystem';
import { UserInterfaceSystem } from './50-systems/UserInterfaceSystem/0-UserInterfaceSystem';
import { VoiceSystem } from './50-systems/VoiceSystem/0-VoiceSystem';
import { CornerstoneArt } from './71-arts/30-CornerstoneArt';
import { clientVersion } from './config';
import { TITLE_SEPARATOR } from './config-universal';
import { consolex } from './consolex';

interface ICollboardAppOptions {
    rootElement: HTMLDivElement;
    language: string_translate_language;
    apiUrl: string_url;
    moduleStoreUrl: string_url;
    proxyUrl: string_url;
    usercontentUrl: string_url;
    // [🍄]
    // Note: To add new service search tag [🧷] for all important places in the code
}

export class CollboardApp {
    private systems: SystemsContainer;

    public constructor(private readonly options: ICollboardAppOptions) {
        this.systems = new SystemsContainer();

        setSystemsForColldev(this.systems);

        this.logVersions();

        this.systems.setTouchController(new TouchController({ elements: [], anchorElement: window.document.body }));
        this.systems.setApiClient(new ApiClient(this.systems, new URL(this.options.apiUrl)));
        this.systems.setUsercontentSystem(new UsercontentSystem(this.systems, new URL(this.options.usercontentUrl)));
        this.systems.setImportSystem(new ImportSystem(this.systems, new URL(this.options.proxyUrl)));
        this.systems.setExportSystem(new ExportSystem(this.systems, new URL(this.options.proxyUrl)));

        /**
         * Generator: Systems
         * Omit: ApiClient,UsercontentSystem,BoardApiClient,MaterialArtVersioningSystem,VirtualArtVersioningSystem,ArtSupportSyncer,AttributeSupportSyncer,CornerstoneSyncer,RouteAndBusinessSyncer,CollSpace,ImportSystem,ExportSystem
         * Pattern: this.systems.set<System>(new <System>(this.systems));
         */

        /* GENERATED BY genetate-systems */
        /* Warning: Do not edit this part by hand, all changes will be lost on next execution! */
        this.systems.setCore(new Core(this.systems));
        this.systems.setAppState(new AppState(this.systems));
        this.systems.setArtSerializer(new ArtSerializer(this.systems));
        this.systems.setAttributesSystem(new AttributesSystem(this.systems));
        this.systems.setBoardSystem(new BoardSystem(this.systems));
        this.systems.setBusinessSystem(new BusinessSystem(this.systems));
        this.systems.setClosePreventionSystem(new ClosePreventionSystem(this.systems));
        this.systems.setControlSystem(new ControlSystem(this.systems));
        this.systems.setCreateSystem(new CreateSystem(this.systems));
        this.systems.setFilepickSystem(new FilepickSystem(this.systems));
        this.systems.setFocusSystem(new FocusSystem(this.systems));
        this.systems.setFractalSystem(new FractalSystem(this.systems));
        this.systems.setGamificationSystem(new GamificationSystem(this.systems));
        this.systems.setGenerateSystem(new GenerateSystem(this.systems));
        this.systems.setHintSystem(new HintSystem(this.systems));
        this.systems.setIdentitySystem(new IdentitySystem(this.systems));
        this.systems.setLicenseSystem(new LicenseSystem(this.systems));
        this.systems.setLicenseSyncer(new LicenseSyncer(this.systems));
        this.systems.setMessagesApiSystem(new MessagesApiSystem(this.systems));
        this.systems.setModuleStore(new ModuleStore(this.systems));
        this.systems.setFileSupportSyncer(new FileSupportSyncer(this.systems));
        this.systems.setNotificationSystem(new NotificationSystem(this.systems));
        this.systems.setPointerSystem(new PointerSystem(this.systems));
        this.systems.setRoutingSystem(new RoutingSystem(this.systems));
        this.systems.setSnapSystem(new SnapSystem(this.systems));
        this.systems.setSoundSystem(new SoundSystem(this.systems));
        this.systems.setStorageSystem(new StorageSystem(this.systems));
        this.systems.setStyleSystem(new StyleSystem(this.systems));
        this.systems.setTestSystem(new TestSystem(this.systems));
        this.systems.setToolbarSystem(new ToolbarSystem(this.systems));
        this.systems.setTranslationsSystem(new TranslationsSystem(this.systems));
        this.systems.setUserInterfaceSystem(new UserInterfaceSystem(this.systems));
        this.systems.setVoiceSystem(new VoiceSystem(this.systems));
        /* END OF GENERATED BY genetate-systems */

        this.run();
    }

    private async loadTranslator() {
        (await this.systems.translationsSystem).language = this.options.language;

        if (isPrivateNetwork(window.location.hostname)) {
            (await this.systems.translationsSystem).missingTranslateMessages.subscribe(
                async (missingTranslateMessage) => {
                    (await this.systems.apiClient).missingTranslateMessage(missingTranslateMessage);
                },
            );
            /* <- TODO: Make it more robust in the config + add this switch to server logic in server/routes/translate/missingTranslateMessagePost.ts */
        }

        await forImmediate(/* <- TODO: Is this waiting necessary */);

        await (
            await this.systems.translationsSystem
        ).pushMessages(...(await (await this.systems.apiClient).translateMessages(this.options.language)));
        // consolex.log('loadTranslator');
    }

    private async logVersions() {
        const { businessSystem, identitySystem, apiClient } = await this.systems.request(
            'businessSystem',
            'identitySystem',
            'apiClient',
        );

        // TODO: Next line should not be nessesary, but it is because request dont wait for system readyness
        await businessSystem.ready;

        consolex.info(
            `%cCollboard for ${(await businessSystem.businessName).toLowerCase()} version ${clientVersion} \n${
                identitySystem.instanceId
            }`,
            `background: #009edd; color: white; font-size: 1.1em; font-weight: bold; padding: 5px; border-radius: 3px;`,
        );

        const { version, remoteInstanceId } = await apiClient.getAbout();
        consolex.info(
            `%cConnected to API version ${version} \n${remoteInstanceId}`,
            `background: #5b1fe7; color: white; font-size: 1.1em; font-weight: bold; padding: 5px; border-radius: 3px;`,
        );

        // TODO: This should be done through a LoggingSystem
        // TODO: Add more info about the client from IdentitySystem (and maybe in IdentitySystem)
        Sentry.setContext('version', {
            clientVersion,
            remoteVersion: version,
        });
    }

    private async render(content: React.ReactNode /* <- TODO: Import and use just a ReactNode */) {
        // [🍄]
        // TODO: Wait for full render and sync of the arts - split between quickfinish (=speed up the handwriting of the loading logo) and fully ready (=fade out the loading logo)
        await (window as any).quickfinishHandwrittenCollboardAnimation();

        // TODO: Maybe there is no need to have separate render function as it is used here only in one place
        const { styleSystem, translationsSystem } = await this.systems.request('styleSystem', 'translationsSystem');
        // TODO: Probably here should be also wrapper of <Sentry.ErrorBoundary> imported from @sentry/react @see https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/
        ReactDOM.render(
            <styleSystem.WithSkinContext>
                <translationsSystem.WithTranslateContext>
                    <UnsignedSystemsContainerContext.Provider value={this.systems}>
                        {content}
                    </UnsignedSystemsContainerContext.Provider>
                </translationsSystem.WithTranslateContext>
                <GlobalStyles />
                {styleSystem.renderStyles()}
            </styleSystem.WithSkinContext>,

            this.options.rootElement,
        );
    }

    private async setupModuleStore() {
        const moduleStore = await this.systems.moduleStore;

        // TODO: [🉐] Module storage should have name prefix which modules it manages
        moduleStore.registerModuleStorage(internalModules);
        moduleStore.registerModuleStoreConnector(
            new ExternalModuleStoreConnector(this.systems, new URL(this.options.moduleStoreUrl)),
        );
        // TODO: Implement or remove new ArticlesModuleStoreConnector(this.systems),
    }

    private async run() {
        await this.loadTranslator();
        await this.setupModuleStore();
        await this.systems.setRouteAndBusinessSyncer(new RouteAndBusinessSyncer(this.systems));
        const { routingSystem, boardSystem } = await this.systems.request('routingSystem', 'boardSystem');
        await this.forEssencialSystemsAndItsParts();

        /* [⚙️] */
        if ((await (await this.systems.businessSystem).businessName) === BusinessName.Development) {
            /* not await */ syncServiceWorkers(`./service-workers/cache.service-worker.js`);
        } else {
            /* not await */ syncServiceWorkers(/* TODO: Use service worker for all businesses*/);
        }

        // TODO: [0] Just remove variable boardQueue
        const boardQueue = new Queue<JSX.Element>();

        routingSystem.urlVariables.values
            .pipe(distinctUntilChanged((a, b) => a.uriId === b.uriId))
            .pipe(
                map(async ({ uriId }: RemoveIndex<IUrlVariables>) =>
                    // TODO: [0] Maybe do some async piping instead of the Queue
                    boardQueue.task(async () => {
                        consolex.info(
                            `%cRunning ${!uriId ? `welcome page` : `board ${uriId}`}`,
                            `background: #009edd; color: white; font-size: 1.1em; font-weight: bold; padding: 5px; border-radius: 3px;`,
                        );

                        // TODO: Refactor code bellow in some non-spaghety way
                        boardSystem.currentBoard.next({ uriId, isLoaded: false });

                        if (uriId) {
                            try {
                                await this.changeVersioningSystems(
                                    await (await this.systems.apiClient).connectToBoard(uriId),
                                );

                                // TODO: Destroying of the CollSpace on each itteration or registring a new board MAYBE same pattern as this.changeArtVersionSystem
                                await this.systems.setCollSpace(new CollSpace(this.systems));

                                boardSystem.currentBoard.next({ uriId, isLoaded: true });
                                return <RootComponentForBoard />;
                            } catch (error) {
                                // [🐙]
                                if (error instanceof BoardNotFoundError) {
                                    (await this.systems.routeAndBusinessSyncer).triggerErrorForBoard(error);
                                } else {
                                    throw error;
                                }
                            }
                        } else {
                            await this.changeVersioningSystems(null);
                            boardSystem.currentBoard.next({ uriId, isLoaded: true });
                        }

                        return <RootComponentForWelcome />;
                    }),
                ),
            )
            .subscribe(async (elementToRender) => {
                // TODO: [☮️] Remove all initial <Loader/> and <LoaderInline/> after this point in <RootComponentForWelcome/>
                // TODO: [☮️] Remove all initial <Loader/> and <LoaderInline/> after this point in <RootComponentForBoard/>

                /* not await */ this.render(
                    await elementToRender /* <-  TODO: [0] Instead of await here use some mapAsync in pipe above */,
                );
            });
    }

    private async forEssencialSystemsAndItsParts() {
        await (
            await this.systems.toolbarSystem
        ).ready;

        await (
            await this.systems.routeAndBusinessSyncer
        ).firstSynchronization;
    }

    /**
     * TODO: !!  To some util which is destroyable and prepairs systems with a container as a whole
     */
    private async changeVersioningSystems(
        materialArtVersioningSystem: MaterialArtVersioningSystem | null,
    ): Promise<void> {
        // Note: First we need to destroy syncers
        await this.systems.setArtSupportSyncer(null);
        await this.systems.setAttributeSupportSyncer(null);
        await this.systems.setCornerstoneSyncer(null);

        // Note: Then wait for a while (probbably some syncers do not destroys its virtual art commits immediatelly TODO: Fix it)
        await forImmediate();

        // Note: And after that we can destroy versioning systems
        if (materialArtVersioningSystem) {
            await this.systems.setMaterialArtVersioningSystem(materialArtVersioningSystem);
            await this.systems.setVirtualArtVersioningSystem(
                new VirtualArtVersioningSystem(this.systems, CORE_MODULE_SIGNATURE),
            );
        } else {
            await this.systems.setMaterialArtVersioningSystem(null);
            await this.systems.setVirtualArtVersioningSystem(null);
        }

        if (!materialArtVersioningSystem) return;

        materialArtVersioningSystem.commitsObservable
            .pipe(filter((commit) => commit.art instanceof CornerstoneArt))
            .subscribe(async (commit) => {
                // TODO: Do this inside a AppState.init
                (await this.systems.appState).boardname.next(commit.art.boardname);
                window.document.title = `${commit.art.boardname}${TITLE_SEPARATOR}Collboard.com`; /* TODO: DRY (title function shared for frontend and server) */
            });

        // TODO: Do this via frontend export + use ONLY postMessage not the strange callbacks
        if ((window as any).renderMe) {
            const subscription = materialArtVersioningSystem.commitsObservable
                .pipe(debounce(() => interval(300)))
                .subscribe(() => {
                    (window as any).renderMe();
                    subscription.unsubscribe();
                });
        }

        await this.systems.setArtSupportSyncer(new ArtSupportSyncer(this.systems));

        await this.systems.setAttributeSupportSyncer(new AttributeSupportSyncer(this.systems));
        await this.systems.setCornerstoneSyncer(new CornerstoneSyncer(this.systems));

        /**
         * Note: [🧙‍♂️]Keeping this for future testing purposes:
         * /
        (async () => {
            const module = await this.moduleStore.getModule('SpaceBackground');
            while (true) {
                await forTime(1000);
                if (module.status === ModuleStatus.NotActivated) {
                    await module.activate();
                } else if (module.status === ModuleStatus.Activated) {
                    await module.deactivate();
                }
            }
        })();
        /**/

        /**
         * Note: [🧙‍♂️] Keeping this for future testing purposes:
         * /
        (async () => {
            await forTime(1500);
            //await alertDialogue('now');
            const module = await this.moduleStore.getModule('Color2Attribute');
            await module.deactivate();
        })();
        /**/

        /*
        This functionality is provided by backend:
            *if (!this.appState.exportUri) {
            *    // No link for view sharing - creating new one
            *    materialArtVersioningSystem
            *        .createCommitsGrid('AUTO_CREATE_VIEW_LINK', 'KEEP')
            *        .assignUri()
            *        .next(new ExportArt(ExportFormat.Native), true);
            *}
        */
    }
}

/**
 * TODO: [Optimization][InitialLoading] Break into the forMoment(s)
 *       Double-check that changes are working
 * TODO: [Optimization][InitialLoading] Split heavy work in main JS into smaller event loop pieces to smooth animation + UI
 * TODO: This file is too big - maybe print on paper and split into multiple logical pieces + use Core(system) as a place
 * TODO: Join app and createApp
 *
 */
