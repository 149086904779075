import { Promisable } from 'type-fest';
import { IVectorData, Vector } from 'xyzt';
import { ISystems } from '../50-systems/00-SystemsContainer/ISystems';
import { AbstractPlacedArt } from './25-AbstractPlacedArt';
/*
 * Note: [🖇] This is not in the internal module because some of the Arts are so
 *       tightly connected with the core that they need to be there, not in
 *       optionally deactivateable module.
 *
 */

/**
 * Abstract2dArt is an abstract class which all arts rendered in 2D extend.
 *
 * @collboard-modules-sdk
 */
export abstract class Abstract2dArt extends AbstractPlacedArt {
    /**
     * Getter for getting element size.
     *
     * Needs to be overridden if art has `"size"` among `AcceptedAttributes`
     *
     * @deprecated Make it using BoundingBox
     */
    public get size(): IVectorData {
        // TODO: Remove size and refactor
        return Vector.zero();
    }

    /**
     * Getter for changing element size.
     *
     * Needs to be overridden if art has `"size"` among `AcceptedAttributes`
     *
     * @deprecated Make it using BoundingBox
     */
    public set size(size: IVectorData) {}

    /**
     * Object rotation
     *
     * @deprecated Make it using BoundingBox
     */
    public rotation: number; // TODO: Remove this rotation and use 3D rotation in AbstractPlacedArt

    /**
     * Function called when object needs to be rendered on screen
     *
     * @param selected indicates, whether given art is currently within
     * user selection
     * @param systemsContainer contains all systems, which can be used
     * within rendering
     *
     * *Note: This can be called many times a second when user is scrolling
     * or not at all when art is out of screen*
     */
    public abstract render /* TODO: [✨] Add is prefix */(
        selected: boolean,
        systems: ISystems,
    ): Promisable<JSX.Element>;

    /**
     * Default z-index of art
     *
     * Needs to be between 1-9 (including), otherwise it can lead to
     * undefined behavior.
     *
     * When locked, decreases by one.
     *
     * *Note: This will be replaced with proper object ordering in the
     * near future, however it is highly suggested to override use property
     * until then.*
     */
    public defaultZIndex: number = 5; // <1 - 9>
}

/**
 * TODO: [🎚️] Maybe get rit of AbstractArts and make makers for arts which returns IArts
 */
