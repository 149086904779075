import React from 'react';
import { ITraySimpleDefinition } from '../../../40-utils/trayModules/interfaces/ITraySimpleItemTypes';
import { ISystems } from '../../00-SystemsContainer/ISystems';
import { IOngoingMaterialOperation } from '../../ArtVersionSystem/IOperation';
import { IToolbarIcon } from '../../ToolbarSystem/IToolbarIcon';
import { IModuleDefinition } from '../interfaces/IModule';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { makeDynamicTrayModule } from './makeTrayDynamicModule';

/**
 * Maker for creating tray-like modules
 * This is the simple version of the maker which is usefull for creating modules with have predefined list of items which can be stored in as pure JSON
 *
 * Note: There are two makers for tray modules:
 *       - **makeTraySimpleModule** will take pre-defined list of items
 *       - **makeTrayDynamicModule** will allow to define items dynamically
 *
 * @collboard-modules-sdk
 */
export function makeTraySimpleModule({
    manifest,
    icon,
    trayDefinition,
}: {
    manifest?: IModuleManifest;

    /**
     * Toolbar icon
     */
    icon: IToolbarIcon | ((systems: ISystems) => IToolbarIcon);

    /**
     * Definition of tray items
     */
    trayDefinition: ITraySimpleDefinition;
}): IModuleDefinition {
    const items = Object.fromEntries(
        trayDefinition
            .flatMap(({ groups }) => groups)
            // tslint:disable-next-line:no-shadowed-variable
            .flatMap(({ items }) => items)
            .map(({ title, imageSrc, artSrc }) => [
                `item-${imageSrc}`,
                {
                    content: (
                        <g>
                            <image href={imageSrc} height="200" width="200" />
                            {/* TODO: [lib] Som way how to add text nativelly */}
                            <text
                                x="0"
                                y="0"
                                opacity={0.5}
                                stroke="none"
                                fill="#555"
                                style={{ font: `bold ${(10 / 0.6) * 0.9}px comenia-sans-web, sans-serif` }}
                            >
                                {title}
                            </text>
                        </g>
                    ),
                    src: artSrc || imageSrc,
                    defaultColor:
                        '#555' /* <- TODO: Not needed; weird way how to pass text color of seemengly unrelated thing */,
                },
            ]),
    );

    return makeDynamicTrayModule({
        manifest,
        icon,
        trayDefinition: {
            getItems: items,
            getToolbarItems() {
                // TODO: Probably cache this?

                // tslint:disable-next-line:no-shadowed-variable
                return trayDefinition.map(({ title, icon, scale, groups }) => ({
                    title,
                    icon,
                    scale: scale || 0.6,
                    // tslint:disable-next-line:no-shadowed-variable
                    items: groups.map(({ title, items }) => ({
                        title,
                        itemIds: items.map(({ imageSrc }) => `item-${imageSrc}`),
                    })),
                }));
            },
        },
        async newArtMaker({ itemId, boardPosition, systems }) {
            const { importSystem } = await systems.request('importSystem');

            const { src } = items[itemId];

            const opreation = await importSystem.importUrl({
                src,
                boardPosition,
                isNotified: false,
                isSelected: false,
                isPersisted: false,
            });

            // TODO: Probably check that opreation is really IOngoingMaterialOperation not just IDestroyable object
            return opreation as IOngoingMaterialOperation;
        },
    });
}
