import React from 'react';
import { string_url } from '../../40-utils/typeAliases';

type IClickableProps =
    React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{
        onClick?: React.MouseEventHandler /* <- TODO: Import and use just a MouseEventHandler */;
        href?: string_url;
        /* TODO: [✨] Add is prefix */ hrefByJavascript?: boolean;
        className?: string;
    }>;

/**
 * @collboard-modules-sdk
 */
export function Clickable({ onClick, href, hrefByJavascript, className, children }: IClickableProps) {
    if (!href) {
        return <div {...{ onClick, className }}>{children}</div>;
    } else {
        if (/^https?:\/\/(.*\.)?collboard\.com/.test(href)) {
            if (!(hrefByJavascript ?? false)) {
                return <a {...{ href, onClick, className }}>{children}</a>;
            } else {
                return (
                    <div
                        onClick={(event) => {
                            if (onClick) {
                                onClick(event);
                            }
                            if (!event.isDefaultPrevented()) {
                                window.location.href = href;
                            }
                        }}
                        style={{ cursor: 'pointer' }}
                        {...{ className }}
                    >
                        {children}
                    </div>
                );
            }
        } else {
            if (!(hrefByJavascript ?? false)) {
                return (
                    <a rel="noopener noreferrer" target="_blank" {...{ href, onClick, className }}>
                        {children}
                    </a>
                );
            } else {
                return (
                    <div
                        onClick={(event) => {
                            if (onClick) {
                                onClick(event);
                            }
                            if (!event.isDefaultPrevented()) {
                                window.open(href, '_blank', 'noopener noreferrer');
                            }
                        }}
                        style={{ cursor: 'pointer' }}
                        {...{ className }}
                    >
                        {children}
                    </div>
                );
            }
        }
    }
}
