import { Destroyable, IDestroyable } from 'destroyable';
/**
 * Make a function cacheable.
 *
 * Note: If the result will be null or Promise<null>, result will not be cached
 * Warning: Use only for pure functions.
 *
 * @collboard-modules-sdk
 *
 */
export class FunctionCache<TInput, TOutput> extends Destroyable implements IDestroyable {
    private cache: Record<string, TOutput> = {};

    public constructor(private executor: (input: TInput) => TOutput) {
        super();
    }

    public get function(): (input: TInput) => TOutput {
        return (input: TInput) => this.execute(input);
    }

    public execute(input: TInput): TOutput {
        const key = JSON.stringify(input);

        if (this.cache[key]) {
            return this.cache[key];
        }

        const result = this.executor(input);

        Promise.resolve(result).then((resultResolved) => {
            if (resultResolved !== null) {
                this.cache[key] = resultResolved;
            }
        });

        return result;
    }
}

/**
 *
 * TODO: Probably work with multiple arguments
 * TODO: Probably do this as decorator
 * TODO: In futute to constructor should be passed storage (which can be time limited)
 *       TODO: This storage from LIB everstorage should be IDestroyable (and here you cound setup if this storage should be isDestroyed with FunctionCache)
 *
 */
