import { SerializationError } from '../errors/10-SerializationError';
import { ISerializerRule } from '../interfaces/ISerializerRule';

/**
 * @@x
 */
export const primitivesSerializerRule: ISerializerRule = {
    name: 'PRIMITIVES',

    priority: 0 /* <- TODO: [🍉] One place with priorities */,
    serialize({ value, next }) {
        if (value === null) {
            return null;
        }

        if (value === undefined) {
            throw new SerializationError(`Can not serialize undefined value because undefined is not in JSON`, {
                value,
                // TODO: Is there a way how to pass here recursionValues
            });
        }

        if (!['boolean', 'number', 'string'].includes(typeof value)) {
            return next();
        }

        if (Number.isNaN(value)) {
            throw new SerializationError(`Can not serialize NaN value because NaN is not in JSON`, { value });
        }

        if (value === Infinity || value === -Infinity /* <- TODO: Maybe use Number.isFinite */) {
            throw new SerializationError(
                `Can not serialize positive or negative Infinity value because Infinity is not in JSON`,
                { value },
            );
        }

        return value;
    },
    deserialize({ serialized, next }) {
        if (serialized === null) {
            return null;
        }

        if (!['boolean', 'number', 'string'].includes(typeof serialized)) {
            return next();
        }
        return serialized;
    },
};

/**
 * TODO: [🌉] In future use satisfies ISerializerRule
 */
