import { number_integer } from './typeAliases';

/**
 *
 * Generates a random integer between a given range
 *
 * @param min Minimum number (inclusive)
 * @param max Maximum number (inclusive)
 *
 * @collboard-modules-sdk
 */
export function randomInteger(min: number_integer, max: number_integer): number_integer {
    const random = Math.random(/* <- TODO: [🐉] Probbably use seed random */);
    const range = max - min + 1;

    return Math.floor(random * range) + min;
}
