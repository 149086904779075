import { IDestroyable, NotFoundError, registerPairsInObject } from 'destroyable';
import { forImmediate } from 'waitasecond';
import { forUnpredictedTime } from '../../../00-lib/waitasecond/forUnpredictedTime';
import { Commit } from '../../../10-database/Commit';
import { errorMessageWithAdditional } from '../../../40-utils/errors/errorMessageWithAdditional';
import { ThrottleQueues } from '../../../40-utils/tasks/ThrottleQueues';
import { toString } from '../../../40-utils/toString';
import { string_module_name } from '../../../40-utils/typeAliases';
import { AbstractArt } from '../../../71-arts/20-AbstractArt';
import { IDependenciesRecord } from '../interfaces/IDependencies';
import { IModuleSearchCriteria } from '../interfaces/IModuleSearchCriteria';
import { ISyncer } from '../interfaces/ISyncer';
import { dependenciesSetToDependenciesRecord } from '../utils/dependenciesSetToDependenciesRecord';
import { AbstractSyncer } from './AbstractSyncer';
/**
 * ArtSupportSyncer installs / uninstalls art support modules
 *
 * @private
 * @collboard-system
 */
export class ArtSupportSyncer extends AbstractSyncer implements ISyncer, IDestroyable {
    private artDependencies: IDependenciesRecord = {};

    private throttleQueues = new ThrottleQueues({ throttler: forImmediate /* <- TODO: Maybe use here forMoment */ });

    public async installSupport(moduleName: string_module_name) {
        return this.throttleQueues.getThrottleQueue(moduleName).task(async () => {
            await forUnpredictedTime();

            const search = {
                limit: 1,
                name: moduleName,
            };
            const { manifests } = await (await this.systems.moduleStore).search(search);

            this.checkWhetherNotDestroyed(
                `ArtSupportSyncer is destroyed so it can not install support for ${moduleName}.`,
            );

            if (!manifests.length) {
                throw new NotFoundError(
                    errorMessageWithAdditional(
                        `ArtSupportSyncer can not find module that add support for ${moduleName}`,
                        {
                            moduleStore: await this.systems.moduleStore,
                            search,
                        },
                    ),
                );
            }

            const artDependency: IDependenciesRecord = dependenciesSetToDependenciesRecord(manifests);
            const registration = registerPairsInObject({
                base: this.artDependencies,
                add: artDependency,
                collisionStrategy: 'SKIP',
                // Delete errorReporting: (error) => consolex.warn(error.message),
            });
            this.addSubdestroyable(registration);

            // TODO: !! add subregistration to registration and sync also when destoroy - Same in all syncers
            await this.sync(this.artDependencies);
            return registration;
        });
    }

    /**
     * @deprecated
     */
    public async installSupportForArt(classNameOrInstance: string | AbstractArt | Commit) {
        return this.throttleQueues.getThrottleQueue(classNameOrInstance).task(async () => {
            await forUnpredictedTime();

            const search: IModuleSearchCriteria = {
                limit: 1,
                supports: {
                    art:
                        typeof classNameOrInstance === 'string'
                            ? classNameOrInstance
                            : (classNameOrInstance.constructor as any).serializeName,
                },
            }; /* <- TODO: Use here satisfies keyword */
            const { manifests } = await (await this.systems.moduleStore).search(search);

            this.checkWhetherNotDestroyed(
                `ArtSupportSyncer is destroyed so it can not install support for ${toString(classNameOrInstance)}.`,
            );

            if (!manifests.length) {
                throw new NotFoundError(
                    errorMessageWithAdditional(
                        `ArtSupportSyncer can not find module that add support for ${toString(classNameOrInstance)}`,
                        {
                            moduleStore: await this.systems.moduleStore,
                            search,
                        },
                    ),
                );
            }

            const artDependency: IDependenciesRecord = dependenciesSetToDependenciesRecord(manifests);
            const registration = registerPairsInObject({
                base: this.artDependencies,
                add: artDependency,
                collisionStrategy: 'SKIP',
                // Delete errorReporting: (error) => consolex.warn(error.message),
            });
            this.addSubdestroyable(registration);

            // TODO: !! add subregistration to registration and sync also when destoroy - Same in all syncers
            await this.sync(this.artDependencies);
            return registration;
        });
    }

    public async destroy() {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        await this.throttleQueues.destroy();
    }
}
