import { Registration } from 'destroyable';
import { forTime } from 'waitasecond';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { ToolbarName } from '../50-systems/ToolbarSystem/0-ToolbarSystem';
internalModules.declareModule(() => ({
    manifest: {
        flags: { isHidden: true },
        name: '@collboard/internal/toolbars-persisting',
        deprecatedNames: ['@collboard/toolbars-persisting', 'ToolbarsPersisting'],
        requirePermissions: ['view'],
        // Note: Persisting module is invisible, and so there is no need to create a massive manifest for them. Write everything into the corresponding tool module.
    },

    async setup(systems) {
        const { toolbarSystem, materialArtVersioningSystem } = await systems.request(
            'toolbarSystem',
            'materialArtVersioningSystem',
        );
        // TODO: Also persist attributes in the future

        await forTime(800 /* TODO: Make waiting for installation tools + to load all changes in Cornerstone smarter */);

        if (!materialArtVersioningSystem.permissions.edit) {
            // TODO: Probably in future do not hardcode MoveTool to viewer mode
            /* not await */ toolbarSystem.getToolbar(ToolbarName.Navigation).clickOnIcon('move');
        } else {
            /* not await */ toolbarSystem.getToolbar(ToolbarName.Tools).clickOnIcon('freehand');

            /*
          TODO: Make this module work and then use code bellow

          const toolbar = this.toolbarSystem.getToolbar(ToolbarName.Tools);
          // TODO: Iterate through all toolbars

          const toolbarQueryParam = this.routingSystem.registerQuery<{
              // TODO: This should identify toolbar not no-meaning key icon
              icon: string | null;
          }>({ icon: 'freehand' }, { debounceInterval: 50, saveToHistory: false });

          observe(toolbar, () => {
              toolbarQueryParam.value = ({ icon: toolbar.activeIconName });
          });

          toolbarQueryParam.values.subscribe(({ icon }) => {
              toolbar.setActive(icon);
          });
          */
        }

        return Registration.void();
    },
}));
