import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';

/**
 * FractalSystem can do procedurally generated part of the board - patterns, iterations, etc.
 * Modules can use it for creating the fractal boards or creating massive scales like sun and atoms by procedural generating.
 *
 * @private
 * @collboard-system
 */
export class FractalSystem extends AbstractSystem {
    /*
        TODO: This class is a boilerplate of the system that we have not started working on yet.
        TODO: Probably this should not be a system
        TODO: Probably rename to recursuion system
        @see https://github.com/collboard/collboard/issues/42
    */

    protected async init() {}
}
