import React from 'react';
import { IVectorData } from 'xyzt';
import { Abstract2dArt } from './26-Abstract2dArt';

/*
 TODO: !! This should be deleted and instead of it should be used some IFramable
*/

/**
 * @deprecated [🍿] Do not use enums but object as const OR 'LITERAL VALUES' instead
 */
export enum ExportFormat {
    Native = 'NATIVE',
    Png = 'PNG',
}

/*
 * Note: This is not in the internal module because some of the Arts are so
 * tightly connected with the core that they need to be there, not in
 * optionally deactivateable module.
 */

/**
 * ExportArt is art for exporting content from the board.
 *
 * @deprecated do this functionality better in the future via FrameArt
 * @collboard-modules-sdk
 */
export class ExportArt extends Abstract2dArt /* TODO: Only AbstractPlacedArt */ {
    public static serializeName = 'Export';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/export-art',
        deprecatedNames: '@collboard/export-art',
    };

    public constructor(public format: ExportFormat) {
        super();
    }

    public get topLeft() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return this.shift;
    }
    public get bottomRight() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return this.shift;
    }

    public isNear(pointToTest: IVectorData) {
        return false;
    }

    public get acceptedAttributes() {
        return [];
    }

    public render(isSelected: boolean) {
        return <></>;
    }
}

/**
 * TODO: [🎚️] Implement IArt
 */
