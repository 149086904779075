import { Color } from '../../../40-utils/color/Color';
import { errorMessageWithAdditional } from '../../../40-utils/errors/errorMessageWithAdditional';
import { ITexture } from './ITexture';
import { isIColorTexture, isITextureTexture } from './ITexture.guard';

/**
 * Converts texture to color
 * If the texture is not a simple color, returns some transparent color instead
 *
 * Note: There are two similar functions:
 * - **textureToColor** Propperly extracts color from image textures but returns a promise
 * - **textureToColorSync** Returns provisional color when image texture is given but works synchronously
 *
 * @param texture
 * @returns Color
 *
 * @collboard-modules-sdk
 */
export function textureToColorSync(texture: ITexture): Color {
    if (isIColorTexture(texture)) {
        return Color.from(texture.color);
    }

    if (!isITextureTexture(texture)) {
        throw new Error(
            errorMessageWithAdditional(`Can not get color definition from texture`, { texture }),
        ); /* <- [🍚] */
    }

    return Color.fromString('transparent') /* <- TODO: Transparent */;
}
