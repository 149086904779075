import { flagsToObject } from '../../../40-utils/flagsToObject';
import { IBusinessConfiguration } from '../../BusinessSystem/interfaces/IBusinessConfiguration';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { parseKeywordsFromManifest } from './parseKeywordsFromManifest';

/**
 * Has this module finished its profile in manifest so it will be presented in the store
 * @param manifest of the module
 * @param installer which will be asked if this module is installed and if yes, module will be presented
 * @returns whether module should be shown publically or not (be hidden to user)
 */
export function isPresented(manifest: IModuleManifest, businessConfiguration?: IBusinessConfiguration): boolean {
    const { title, author, contributors } = manifest;

    if (businessConfiguration) {
        if (
            Object.keys({
                ...businessConfiguration.alwaysRequiredModules,
                ...businessConfiguration.welcomeRequiredModules,
                ...businessConfiguration.boardRequiredModules,
            }).includes(manifest.name)
        ) {
            return false;
        }
    }

    if (parseKeywordsFromManifest(manifest).has('todo')) return false;

    const { isDeprecated, isHidden, isTemplate, isShowcase } = flagsToObject(manifest.flags);
    if (isDeprecated || isHidden || isTemplate || isShowcase) {
        return false;
    }

    // !! How the auto-activated module looks like
    // !! Remove or finish> if ((manifest.usageLicenses||[]).some((usageLicense) => usageLicense.isModuleAutoInstalled)) return false;

    return !!(title && (author || contributors));
}

/**
 *  TODO: Unit test
 */
