import { AbstractPlacedArt } from '../../../71-arts/25-AbstractPlacedArt';
import { CornerstoneArt } from '../../../71-arts/30-CornerstoneArt';
import { ExportArt } from '../../../71-arts/35-ExportArt';
import { ImportArt } from '../../../71-arts/35-ImportArt';
import { IBehaviorOptions } from '../0-IBehavior';

/**
 * Part of createSelectionToolBehavior
 * @see ./0-createSelectionToolBehavior.ts
 */
export async function selectionToolSelectionBoxBehavior(behaviorProps: IBehaviorOptions): Promise<boolean> {
    const { touch, registerAdditionalSubscription, systems } = behaviorProps;

    const { appState, collSpace, controlSystem, materialArtVersioningSystem } = await systems.request(
        'appState',
        'collSpace',
        'controlSystem',
        'materialArtVersioningSystem',
    );

    const point1 = touch.firstFrame.position; //(collSpace.pickPoint(touch.firstFrame.position)).point,

    let previouslySelected: Array<AbstractPlacedArt> = [];
    if (controlSystem.pressedKeys.value.includes('Control') || controlSystem.pressedKeys.value.includes('Shift')) {
        previouslySelected = appState.selected.value;
    }

    appState.setSelection({
        selected: previouslySelected,
        selection: { point1, point2: point1 },
    });

    registerAdditionalSubscription(
        touch.frames.subscribe({
            async next(frame) {
                /* [0] */

                const point2 = frame.position; // (collSpace.pickPoint(frame.position)).point;

                appState.setSelection({
                    selection: { point1, point2 },
                });
            },
            async complete() {
                /* [1] */

                /* [🪑] */
                const selection = appState.getSelection();
                if (!selection) {
                    appState.cancelSelection();
                    return;
                }

                const selectionOnBoard = {
                    /* [🪑] */
                    topLeft: collSpace.pickPoint(selection.topLeft).point,
                    bottomRight: collSpace.pickPoint(selection.bottomRight).point,
                };

                const newlySelected = materialArtVersioningSystem.artsPlaced.filter(
                    (art) =>
                        !art.locked &&
                        (art.topLeft.x || 0) > selectionOnBoard.topLeft.x &&
                        (art.bottomRight.x || 0) < selectionOnBoard.bottomRight.x &&
                        (art.topLeft.y || 0) > selectionOnBoard.topLeft.y &&
                        (art.bottomRight.y || 0) < selectionOnBoard.bottomRight.y &&
                        !(art instanceof CornerstoneArt) &&
                        !(art instanceof ExportArt) &&
                        !(art instanceof ImportArt),
                );

                appState.setSelection({
                    selection: null,
                    selected: [...previouslySelected, ...newlySelected],
                });
            },
        }),
    );
    return true;
}

/**
 * TODO: Originally there was highliting of the objects during unfinished selection
 *       More things happen in [0] next than [1] complete phase
 *       This was removed for performance reasons
 *       [🐦] When there will be able to provide content for Ctrl+C clipboard asynchronously, we can put it back
 *       Last woring version is in commit ac32ca6c3fb9b3aca803046d4a620df9fb0a5888
 *
 * TODO: [🎂] Probably remove systems from IBehaviorProps and use useSystems (or similar mechanism) instead
 * TODO: [🪑] Compute selection from selected should be probbably done ONLY in AppState (or in future SelectionSystem)
 */
