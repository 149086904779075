import { string_uri_part } from './typeAliases';
import { isValidUuid } from './uuid/isValidUuid';

/**
 *
 * @collboard-modules-sdk
 */
export function isValidUriId(uriId: string_uri_part): boolean {
    return /^[a-zA-Z0-9-_]{5,200}$/.test(uriId) && !isValidUuid(uriId);
}
