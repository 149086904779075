/*
 * Generated type guards for "ITexture.ts".
 * WARNING: Do not manually change this file.
 */
import { IColorTexture, ITexture, ITextureTexture } from './ITexture';

/* tslint:disable */
/* eslint-disable */

export function isITexture(obj: unknown): obj is ITexture {
    const typedObj = obj as ITexture;
    return (isIColorTexture(typedObj) as boolean) || (isITextureTexture(typedObj) as boolean);
}

export function isIColorTexture(obj: unknown): obj is IColorTexture {
    const typedObj = obj as IColorTexture;
    return (
        ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
        typeof typedObj['color'] === 'string'
    );
}

export function isITextureTexture(obj: unknown): obj is ITextureTexture {
    const typedObj = obj as ITextureTexture;
    return (
        ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
        typeof typedObj['textureSrc'] === 'string'
    );
}
