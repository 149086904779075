import React from 'react';
import { IVectorData } from 'xyzt';
import { string_url } from '../40-utils/typeAliases';
import { Abstract2dArt } from './26-Abstract2dArt';

/**
 * Strategy for importing external data
 *
 * @deprecated [🍿] Do not use enums but object as const OR 'LITERAL VALUES' instead
 * @deprecated use isMaterialized instead
 */

export enum ImportStrategy {
    /**
     * Importing data will be linked
     */
    Link = 'LINK',

    /**
     * Importing data will be inlined
     */
    Materialize = 'MATERIALIZE',
}

/*
 * Note: This is not in the internal module because some of the Arts are so
 * tightly connected with the core that they need to be there, not in
 * optionally deactivateable module.
 */

/**
 * ImportArt is art for importing external content into the board.
 * @see ExportArt implementation and comments before looking at this
 *
 * @deprecated This functionality is unused
 * @collboard-modules-sdk
 */
export class ImportArt extends Abstract2dArt /* TODO: Only AbstractPlacedArt */ {
    public static serializeName = 'Import';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/import-art',
        deprecatedNames: '@collboard/import-art',
    };

    //: TODO: Implement LINK strategy

    /**
     *
     * @param source  should be pointed to URL compatible with ExportArt='NATIVE'
     * @param strategy should be import live-linked or just isDestroyed and converted to copyied objects?
     */
    public constructor(public source: string_url, public strategy: ImportStrategy) {
        super();
    }

    public get topLeft() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return this.shift;
    }
    public get bottomRight() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return this.shift;
    }

    public isNear(pointToTest: IVectorData) {
        return false;
    }

    public get acceptedAttributes() {
        return [];
    }

    public render(isSelected: boolean) {
        return <></>;
    }
}

/**
 * TODO: [🎚️] Implement IArt
 */
