import { DetailedError } from '../../../40-utils/errors/DetailedError';
import { IDeserializationErrorDetails } from './11-DeserializationError';

/**
 * @@x
 */
interface IArtDeserializationErrorDetails extends IDeserializationErrorDetails {
    /**
     * @@x
     */
    errors?: Array<Error>;
}

/**
 * @@x
 */
export class ArtDeserializationError extends DetailedError<IArtDeserializationErrorDetails> {
    public readonly name = 'ArtDeserializationError';

    public constructor(message: string, details: IArtDeserializationErrorDetails) {
        super(message, details);
        Object.setPrototypeOf(this, ArtDeserializationError.prototype);
    }
}
