import { BlackholeLogger } from './BlackholeLogger';
import { ILogger, ISubLogger } from './ILogger';
import { isLogger } from './utils/isLogger';
import { isSubLogger } from './utils/isSubLogger';

export class ProxyLogger implements ILogger, ISubLogger {
    private readonly loggers: Array<ILogger | ISubLogger>;

    public constructor(...loggers: Array<ILogger | ISubLogger>) {
        this.loggers = loggers;
    }

    public info(message?: any, ...optionalParams: Array<any>): this {
        this.loggers.forEach((logger) => logger.info(message, ...optionalParams));
        return this;
    }
    public warn(warning?: any, ...optionalParams: Array<any>): this {
        this.loggers.forEach((logger) => logger.warn(warning, ...optionalParams));
        return this;
    }
    public error(error?: Error | any, ...optionalParams: Array<any>): this {
        this.loggers.forEach((logger) => logger.error(error, ...optionalParams));
        return this;
    }

    public trace(...data: Array<any>): this {
        this.loggers.forEach((logger) => logger.trace(...data));
        return this;
    }

    public appear(): this {
        this.loggers.forEach((logger) => isSubLogger(logger) && logger.appear());
        return this;
    }

    public end(): void {
        this.loggers.forEach((logger) => isSubLogger(logger) && logger.end());
    }

    public group(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        return new ProxyLogger(
            ...this.loggers.map((logger) =>
                isLogger(logger) ? logger.group(groupName, ...optionalParams) : new BlackholeLogger(),
            ),
        );
    }

    public groupCollapsed(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        return new ProxyLogger(
            ...this.loggers.map((logger) =>
                isLogger(logger) ? logger.groupCollapsed(groupName, ...optionalParams) : new BlackholeLogger(),
            ),
        );
    }
}
