import React from 'react';
import { classNames } from '../../40-utils/classNames';
import { isEmoji } from '../../40-utils/isEmoji';
import { isValidUrl } from '../../40-utils/isValidUrl';
import { consolex } from '../../consolex';
import { IIconAnonymous } from './IIconAnonymous';

/**
 * Generic icon component
 *
 * Note: If not rendered within a menu, there is no guarantee
 * of being rendered correctly
 *
 * @collboard-modules-sdk
 */
export function Icon(props: IIconAnonymous) {
    const { icon, className, active, inactive, onClick: onClickInner } = props;
    function onClick() {
        if (!inactive && onClickInner) {
            onClickInner();
        }
    }

    if (typeof icon === 'string' && isEmoji(icon)) {
        // TODO: DRY
        return (
            <div className={classNames('icon', 'icon-char', active && 'active', inactive && 'inactive', className)}>
                <div className="click-detector" {...{ onClick }}>
                    {icon}
                </div>
            </div>
        );
    } else if (icon instanceof URL || (typeof icon === 'string' && isValidUrl(icon))) {
        // TODO: DRY
        return (
            <div
                className={classNames(
                    'icon',

                    active && 'active',
                    inactive && 'inactive',
                    className,
                )}
                style={{
                    borderRadius: 3,
                    backgroundImage: `url(${icon.toString()})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat' /* !! [🎄] test */,
                }}
            >
                <div className="click-detector" {...{ onClick }}></div>
            </div>
        );
    } else if (typeof icon === 'string') {
        // TODO: DRY
        return (
            <div className={classNames('icon', 'icon-' + icon, active && 'active', inactive && 'inactive', className)}>
                <div className="click-detector" {...{ onClick }}></div>
            </div>
        );
    } else {
        // TODO: Probably some visual error like ErrorComponent (which will fire error in console/sentry + visually show in page). This is hardly debugable
        consolex.error(`Not a valid IIconAnonymous.`, props);

        // TODO: DRY
        return (
            <div className={classNames('icon', 'icon-square', active && 'active', inactive && 'inactive', className)}>
                <div className="click-detector" {...{ onClick }}></div>
            </div>
        );
    }
}
