import { IDestroyable, Registration } from 'destroyable';
import moment from 'moment';
import { consolex } from '../../../../consolex';
import { ITimeoutOptions } from './ITimeoutOptions';

/**
 * TODO: [🔅] Put all %c styled console logs styles into one config place
 */
export const LOADING_CONSOLE_WARN_STYLE = `background: #ffff22; color: black; font-size: 1.1em; font-weight: bold; padding: 5px; border-radius: 3px;`;

/**
 * Creates warning if the returned object is not destroyed in certain time
 *
 * collboard-modules-sdk
 */
export function createTimeout(options: ITimeoutOptions): IDestroyable {
    const { duration, alt, additional } = options;
    return Registration.create(() => {
        if (duration !== Infinity) {
            const timeoutHandler = setTimeout(() => {
                const group = consolex.group(
                    `%c🔄 Loading of "${alt}" is taking more than ${moment.duration(duration).locale('en').humanize()}`,
                    LOADING_CONSOLE_WARN_STYLE,
                );

                if (additional) {
                    group.info(additional);
                }

                group.end();
            }, duration /* TODO: Configurable threshold from props and default global */);

            return () => {
                clearTimeout(timeoutHandler);
            };
        } else {
            return () => {};
        }
    });
}

/**
 * TODO: Handle here negative "duration" value
 */
