import { IVectorData, Vector } from 'xyzt';

interface IFitInsideOptions {
    objectSize: IVectorData;
    containerSize: IVectorData;
    isUpscaling?: boolean;
}

/**
 * Fits object with objectSize inside of container with containerSize.
 * It preserves objectSize ratio
 *
 * @collboard-modules-sdk
 *
 */
export function fitInside({ objectSize, containerSize, isUpscaling }: IFitInsideOptions): Vector {
    if (!objectSize.x || !objectSize.y) {
        throw new Error('Object must have both x and y to count its ratio');
    }

    if (!containerSize.x || !containerSize.y) {
        throw new Error('Container must have both x and y to count its ratio');
    }

    // 🧪 objectSize = new Vector(10, 1),
    // 🧪 containerSize = new Vector(5, 1),

    const objectRatio = objectSize.x / objectSize.y;
    const containerRatio = containerSize.x / containerSize.y;

    const objectSizeScaled = new Vector(objectSize).scale(
        objectRatio > containerRatio ? containerSize.x / objectSize.x : containerSize.y / objectSize.y,
    );

    if (isUpscaling) {
        return objectSizeScaled;
    }

    if (objectSizeScaled.x > objectSize.x) {
        return new Vector(objectSize);
    } else {
        return objectSizeScaled;
    }
}

/**
 * TODO: This util should be part of LIB xyzt
 */
