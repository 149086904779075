import React from 'react';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeUserInterfaceModule } from '../../50-systems/ModuleStore/makers/makeUserInterfaceModule';
import { UserInterfaceElementPlace } from '../../50-systems/UserInterfaceSystem/UserInterfaceElementPlace';
import { Snowflakes } from './Snowflakes';

/**
 *
 * Note: In future this file will we in independent repository as external module.
 *
 */

internalModules.declareModule(() =>
    makeUserInterfaceModule({
        manifest: {
            name: 'XmasSnowing',
            title: { en: 'Snowing', cs: 'Sněžení' },
            description: {
                en: "Let's get the Christmas mood with a bit of snowing",
                cs: 'Pojďme se Vánočně naladit s trochou sněhu na vaší tabuli',
            },

            categories: ['Xmas'],
            icon: '❄',
            flags: {
                isDeprecated: true,
                isHidden: true,
            },
            author: Authors.rosecky,
        },

        place: UserInterfaceElementPlace.RootComponent,
        order: 10,
        render() {
            // TODO: Pack logo image with this module
            // TODO: Indipendent style with style system
            return <Snowflakes amount={200} />;
        },
    }),
);
