import { IDependenciesRecord } from '../interfaces/IDependencies';
import { dependenciesRecordToDependenciesSet } from '../utils/dependenciesRecordToDependenciesSet';
import { dependencyToDependencyDetail } from './dependencyToDependencyDetail';

export function joinDependencies(...dependencieses: Array<IDependenciesRecord>): IDependenciesRecord {
    const joinedDependencies: IDependenciesRecord = {};

    for (const dependencies of dependencieses) {
        for (const dependency of Array.from(dependenciesRecordToDependenciesSet(dependencies))) {
            const existingDependency = joinedDependencies[dependency.name];

            if (existingDependency && (existingDependency.priority || 0) === (dependency.priority || 0)) {
                // TODO: Probably not throw this error when every other param of dependency is same
                throw new Error(
                    `When joining dependencies there were two versions of module ${dependency.name} with same priority ${dependency.priority}.`,
                );
            } else if (!existingDependency || (existingDependency.priority || 0) < (dependency.priority || 0)) {
                joinedDependencies[dependency.name] = dependencyToDependencyDetail(dependency);
            }
        }
    }

    return joinedDependencies;
}
