import { IDestroyable, registerPairsInObject } from 'destroyable';
import { forImmediate } from 'waitasecond';
import { ILogger, ISubLogger } from '../../../40-utils/logger/ILogger';
import { ThrottleQueues } from '../../../40-utils/tasks/ThrottleQueues';
import { string_mime_type, string_module_name } from '../../../40-utils/typeAliases';
import { IDependenciesRecord } from '../interfaces/IDependencies';
import { ISyncer } from '../interfaces/ISyncer';
import { dependenciesSetToDependenciesRecord } from '../utils/dependenciesSetToDependenciesRecord';
import { AbstractSyncer } from './AbstractSyncer';
/**
 * FileSupportSyncer installs / uninstalls support for files for its importing(TODO: /exporting)
 * TODO: Probably name to FileImportSupportSyncer
 */
export class FileSupportSyncer extends AbstractSyncer implements ISyncer, IDestroyable {
    private fileSupportDependencies: IDependenciesRecord = {};
    private throttleQueues = new ThrottleQueues({ throttler: forImmediate /* <- TODO: Maybe use here forMoment */ });

    public async installSupportForFile(mimeType: string_mime_type, logger: ILogger | ISubLogger) {
        // TODO: DRY [0]
        return this.throttleQueues.getThrottleQueue(mimeType).task(async () => {
            const { manifests } = await (
                await this.systems.moduleStore
            ).search({
                supports: {
                    fileImport: mimeType,
                },
            });

            if (this.isDestroyed) {
                return;
            }

            const fileSupportDependency: IDependenciesRecord = dependenciesSetToDependenciesRecord(manifests);

            logger.info(`Installing support for mime-type ${mimeType}`, fileSupportDependency);

            const registration = registerPairsInObject({
                base: this.fileSupportDependencies,
                add: fileSupportDependency,
                collisionStrategy: 'SKIP',
            });

            this.addSubdestroyable(registration);
            await this.sync(this.fileSupportDependencies);
            return registration;
        });
    }

    public async installSupportForNative(moduleName: string_module_name, logger: ILogger | ISubLogger) {
        // TODO: DRY [0]
        const { manifests } = await (
            await this.systems.moduleStore
        ).search({
            name: moduleName,
        });

        if (this.isDestroyed) {
            return;
        }

        const fileSupportDependency: IDependenciesRecord = dependenciesSetToDependenciesRecord(manifests);

        logger.info(`Installing native support ${moduleName}`, fileSupportDependency);

        const registration = registerPairsInObject({
            base: this.fileSupportDependencies,
            add: fileSupportDependency,
            collisionStrategy: 'SKIP',
        });

        this.addSubdestroyable(registration);
        await this.sync(this.fileSupportDependencies);
        return registration;
    }

    public async destroy() {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        //await this.throttleQueues.destroy();
    }
}
