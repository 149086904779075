import React from 'react';
import { jsxToHtml } from '../jsx-html/jsxToHtml';
import { InnerHtml } from './InnerHtml';

/**
 * StaticRender is component that takes a chilren and renders it as a static element.
 *
 * @collboard-modules-sdk
 */
export function StaticRender(
    props: React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{}>,
) {
    const { children } = props;

    return <InnerHtml>{jsxToHtml(<>{children}</>)}</InnerHtml>;
}
