import { registerItemsInSubjectOfArrays } from 'destroyable';
import { BehaviorSubject } from 'rxjs';
import { SetOptional } from 'type-fest';
import { randomJavascriptName } from '../../40-utils/randomJavascriptName';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { INotification } from './INotification';
import { NotificationRegistration } from './NotificationRegistration';
/**
 *
 * @collboard-system
 */
export class NotificationSystem extends AbstractSystem {
    public readonly notifications = new BehaviorSubject<Array<INotification>>([]);

    protected async init() {}

    public publish(notification: SetOptional<INotification, 'tag'>): NotificationRegistration {
        const notificationWithTag: INotification = { tag: randomJavascriptName('notification-'), ...notification };

        return new NotificationRegistration(
            registerItemsInSubjectOfArrays({
                currentValue: this.notifications.value,
                base: this.notifications,
                add: [notificationWithTag],
                // TODO: What happen when the value do not extists in the array because of calling of constrict?
                //     > collisionStrategy: 'SKIP',
                compare(a, b) {
                    return a.tag === b.tag;
                },
            }),
        );
        // TODO: Use also native browser notifications
    }

    /**
     * Constricts (unpublish) published notification
     *
     * @param notification published notification or its tag as string
     */
    public constrict(notificationOrTag: INotification | string): void {
        // TODO: Compare two notifications by more params
        this.notifications.next(
            this.notifications.value.filter(
                (publishedNotification) =>
                    publishedNotification !== notificationOrTag && publishedNotification.tag !== notificationOrTag,
            ),
        );
    }
}

// GRM 20/12

/**
 * TODO: Some simple shortcut to just "alert" message
 * TODO: Helper for notification (with templates like "ok" button)
 */
