import { findArtShellElement } from './findArtShellElement';

/**
 * @collboard-modules-sdk
 */

export function getBoundingClientRectWithoutTransform(element: HTMLElement): DOMRect {
    const artShellElement = findArtShellElement(element);
    if (artShellElement) {
        const transform = artShellElement.style.transform;
        artShellElement.style.transform = 'none';
        const rect = element.getBoundingClientRect();
        artShellElement.style.transform = transform;
        return rect;
    } else {
        return element.getBoundingClientRect();
    }
}

/**
 * TODO: Maybe rename to measureElementSize to be consistent with useMeasure(...) and <Measure>{...}</Measure>
 */
