import React from 'react';
import { Promisable } from 'type-fest';
import { Vector } from 'xyzt';
import { ITrayDynamicDefinition } from '../../../40-utils/trayModules/interfaces/ITrayDynamicItemTypes';
import { TrayToolbar } from '../../../40-utils/trayModules/TrayToolbar';
import { Arrayable } from '../../../40-utils/typeHelpers';
import { Abstract2dArt } from '../../../71-arts/26-Abstract2dArt';
import { ISystems } from '../../00-SystemsContainer/ISystems';
import { IOngoingMaterialOperation } from '../../ArtVersionSystem/IOperation';
import { ToolbarName } from '../../ToolbarSystem/0-ToolbarSystem';
import { IToolbarIcon } from '../../ToolbarSystem/IToolbarIcon';
import { UserInterfaceElementPlace } from '../../UserInterfaceSystem/UserInterfaceElementPlace';
import { IModuleDefinition } from '../interfaces/IModule';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { makeIconModuleOnModule } from './makeIconModuleOnModule';
import { makeMultiModule } from './makeMultiModule';
import { makeTrayToolModule } from './makeTrayToolModule';
import { makeUserInterfaceModule } from './makeUserInterfaceModule';

const TOOL_ACTIVATED_ON_EXIT = 'selection';

/**
 * Maker for creating tray-like modules (like H-edu or Montessori)
 * This is the advanced version of the maker which is usefull for creating modules with can have dynamic number, order or other specific properties
 *
 * Note: There are two makers for tray modules:
 *       - **makeTraySimpleModule** will take pre-defined list of items
 *       - **makeTrayDynamicModule** will allow to define items dynamically
 *
 * @collboard-modules-sdk
 */
export function makeDynamicTrayModule<TArt extends Abstract2dArt>(protoModule: {
    manifest?: IModuleManifest;
    /**
     * Toolbar icon
     */
    icon:
        | IToolbarIcon
        | ((systems: ISystems) => IToolbarIcon) /* <- TODO: Some way how to pass arguments into IFactorable */;

    /**
     * Definition of tray items
     */
    trayDefinition: ITrayDynamicDefinition;

    /**
     * Function returning new Art from id and position
     */
    newArtMaker(options: {
        itemId: string;
        boardPosition: Vector;
        systems: ISystems /* TODO: DRY [🧧] */;
    }): Promisable<Arrayable<TArt> | IOngoingMaterialOperation>;
}): IModuleDefinition {
    let toolbarBodyRef: HTMLDivElement | null = null;
    let selectedItemId: string | null = null;
    let isMouseOverToolbar: boolean = false;

    const { manifest, icon, trayDefinition, newArtMaker } = protoModule;

    return makeIconModuleOnModule({
        manifest,
        toolbar: ToolbarName.Tools,
        icon,
        moduleActivatedByIcon: makeMultiModule({
            modules: [
                makeTrayToolModule<TArt>({
                    getSelectedItemId: () => selectedItemId,
                    isMouseOverToolbar: () => isMouseOverToolbar,
                    getToolbarBodyRef: () => toolbarBodyRef,
                    newArtMaker,
                }),
                makeUserInterfaceModule({
                    place: UserInterfaceElementPlace.RootComponentForBoard,

                    async render(systems) {
                        const { touchController, toolbarSystem, translationsSystem } = await systems.request(
                            'touchController',
                            'toolbarSystem',
                            'translationsSystem',
                        );
                        return (
                            <TrayToolbar
                                onMouseOver={() => {
                                    isMouseOverToolbar = true;
                                }}
                                onMouseOut={() => {
                                    isMouseOverToolbar = false;
                                }}
                                onClose={() => {
                                    toolbarSystem
                                        .getToolbar(ToolbarName.Tools)
                                        .clickOnIcon(TOOL_ACTIVATED_ON_EXIT)
                                        .catch((e) => {
                                            isMouseOverToolbar = false;
                                            /* Tool cannot be activated, but we just keep the H-edu tool active */
                                        });
                                }}
                                setToolbarBodyRef={(object: HTMLDivElement) => (toolbarBodyRef = object)}
                                setSelectedItemId={(newId: string | null) => (selectedItemId = newId)}
                                {...{ touchController, trayDefinition, translationsSystem }}
                            />
                        );
                    },
                }),
            ],
        }),
    });
}
