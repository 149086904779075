import React from 'react';
import { AsyncButtonComponent } from '../../../30-components/utils/AsyncButtonComponent';
import { FileComponent } from '../../../30-components/utils/FileComponent';
import { induceFileDownload } from '../../../40-utils/files/induceFileDownload';
import { useSystems } from '../../../40-utils/react-hooks/useSystems';
import { consolex } from '../../../consolex';
import { Translate } from '../../TranslationsSystem/components/Translate';
import { ExportPrepareComponent } from './ExportPrepareComponent';

/**
 * Note: Need to be used inside of a module
 *
 * @not-collboard-modules-sdk too specific that is used only in @collboard/internal/download
 */
export function DownloadPreviewComponent() {
    const { appState, exportSystem, translationsSystem } = useSystems('appState', 'exportSystem', 'translationsSystem');
    const [isLoading, setLoading] = React.useState/* <- TODO: Import and use just a useState */ <boolean>(false);
    const [file, setFile] = React.useState/* <- TODO: Import and use just a useState */ <File | null>(null);

    return (
        <>
            <ExportPrepareComponent
                {...{ exportSystem, translationsSystem, appState }}
                onChange={async (preparedFileExport) => {
                    setLoading(true);

                    if (!preparedFileExport) {
                        return;
                    }

                    setFile(await preparedFileExport.exporting());
                    setLoading(false);
                }}
            />

            {file && (
                <AsyncButtonComponent
                    alt="Exporting"
                    className="button button-primary modal-button"
                    onClick={async () => {
                        consolex.info('Downloading', file);
                        await induceFileDownload(file);
                    }}
                >
                    <Translate name="Download">Download</Translate>
                    {/*' .' + extension(preparedFileExport.fileSupporter.mimeType)*/}
                </AsyncButtonComponent>
            )}

            {file && <FileComponent {...{ file, isLoading }} />}
        </>
    );
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
