import React from 'react';

/**
 * @collboard-modules-sdk
 */
export function TestingSquare() {
    return (
        <div style={{ position: 'relative', zIndex: -1 }}>
            <div
                style={{
                    position: 'absolute',
                    width: 100,
                    height: 100,
                    border: `1px dotted red`,
                    backgroundColor: 'yellow',
                }}
                // onClick={() => {
                //     await alertDialogue(`art-transform-wrapper`);
                // }}
                // onMouseEnter={() => {
                //     await alertDialogue(`art-transform-wrapper`);
                // }}
            >
                TestingSquare
            </div>
        </div>
    );
}
