import React from 'react';
import { CategorizedModal } from '../../../../../30-components/modal/categorized-modal/CategorizedModal';
import { CategorizedModalBody } from '../../../../../30-components/modal/categorized-modal/CategorizedModalBody';
import { CategorizedModalMenu } from '../../../../../30-components/modal/categorized-modal/CategorizedModalMenu';
import { AsyncContentComponent } from '../../../../../30-components/utils/AsyncContentComponent';
import { ErrorBoundary } from '../../../../../30-components/utils/ErrorBoundary';
import { classNames } from '../../../../../40-utils/classNames';
import { useSkin } from '../../../../../40-utils/react-hooks/useSkin';
import { useSystems } from '../../../../../40-utils/react-hooks/useSystems';
import { IModuleSearchCriteria } from '../../../../../50-systems/ModuleStore/interfaces/IModuleSearchCriteria';
import { joinModuleStatusReporters } from '../../../../../50-systems/ModuleStore/utils/joinModuleStatusReporters';
import { Translate } from '../../../../../50-systems/TranslationsSystem/components/Translate';
import { ModulesCatalogueComponent } from './10-ModulesCatalogueComponent';
import { ModuleBoxComponent } from './15-ModuleBoxComponent';
import { ModuleStoreStyle } from './ModuleStoreStyle';
export { CategorizedModal } from '../../../../../30-components/modal/categorized-modal/CategorizedModal';
interface IModuleStoreModalComponentProps {
    // Note: I am not putting here ArtVersionSystem as a prop directly because it can be undefined when activating the module but available on use.
    search: IModuleSearchCriteria;
    onSearchChange(value: IModuleSearchCriteria): void;
}

export function ModuleStoreModalComponent({ search, onSearchChange }: IModuleStoreModalComponentProps) {
    const {
        translationsSystem,
        businessSystem,
        routingSystem,
        licenseSystem,
        moduleStore,
        cornerstoneSyncer,
        routeAndBusinessSyncer,
        attributeSupportSyncer,
        artSupportSyncer,
        licenseSyncer,
    } = useSystems(
        'translationsSystem',
        'businessSystem',
        'routingSystem',
        'licenseSystem',
        'moduleStore',
        'cornerstoneSyncer',
        'routeAndBusinessSyncer',
        'attributeSupportSyncer',
        'artSupportSyncer',
        'licenseSyncer',
    );
    const { skin } = useSkin();
    const needleInputElement =
        React.useRef/* <- TODO: Import and use just a useRef */ <HTMLInputElement>() as React.MutableRefObject/* <- TODO: Import and use just a MutableRefObject */ <HTMLInputElement>;

    //----- Note: Sync needle field
    // TODO: Probably wrap into some hook
    const currentNeedleInputElement = needleInputElement.current;
    if (currentNeedleInputElement && (search.needle || '') !== currentNeedleInputElement.value) {
        currentNeedleInputElement.value = search.needle || '';
    }
    //-----

    return (
        <ModuleStoreStyle {...{ skin }}>
            <CategorizedModal
                title={<Translate name="ModuleStoreToolbarIcon / title">Přidat funkce</Translate>}
                toggleModal={() => {
                    routingSystem.urlVariables.setValue({ moduleName: undefined });
                }}
                wide
                fullSize
            >
                <CategorizedModalMenu>
                    <>
                        {/* TODO: Some smart compromise between defaultValue and value in <input below */}
                        <input
                            type="Text"
                            defaultValue={search.needle}
                            ref={needleInputElement}
                            placeholder={translationsSystem.translate(
                                `ModuleStoreToolbarIcon / needle / placeholder`,
                                'Vyhledávání',
                            )}
                            onChange={(event) =>
                                onSearchChange({
                                    needle: event.target.value,
                                })
                            }
                            className="search"
                        />
                        <ul className="category-picker">
                            <li
                                className={!search.category && !search.needle ? 'category-selected' : ''}
                                onClick={() => onSearchChange({ needle: undefined, category: undefined })}
                            >
                                <Translate name="ModuleStoreToolbarIcon / category / all">Všechny doplňky</Translate>
                            </li>
                            <li className="category-separator" />

                            <AsyncContentComponent
                                alt="Modules categories"
                                content={async () => {
                                    const categories = await moduleStore.getCategories();

                                    return (
                                        <>
                                            {Array.from(categories).map((category) => (
                                                <li
                                                    key={category}
                                                    className={classNames(
                                                        search.category === category && 'category-selected',
                                                        /*
                                                TODO !!
                                                !systemsContainer.moduleStore.categoryHasVisibleModules(
                                                    category,
                                                ) && 'category-empty ',*/
                                                    )}
                                                    onClick={() =>
                                                        onSearchChange({
                                                            needle: undefined,
                                                            category,
                                                        })
                                                    }
                                                >
                                                    <Translate name={`ModuleStoreToolbarIcon / category / ${category}`}>
                                                        {category}
                                                    </Translate>
                                                </li>
                                            ))}
                                        </>
                                    );
                                }}
                            />
                        </ul>
                    </>
                </CategorizedModalMenu>
                <CategorizedModalBody>
                    <AsyncContentComponent
                        alt="Modules search result"
                        content={async () => {
                            const { manifests } = await moduleStore.search(search);

                            return (
                                <ModulesCatalogueComponent>
                                    {manifests.map((manifest, index) => (
                                        <ErrorBoundary key={index /* TODO: Probably better key */}>
                                            <ModuleBoxComponent
                                                {...{
                                                    moduleStatusReporter: joinModuleStatusReporters(
                                                        cornerstoneSyncer,
                                                        routeAndBusinessSyncer,
                                                        attributeSupportSyncer,
                                                        artSupportSyncer,
                                                        licenseSyncer,
                                                    ),
                                                    persister: cornerstoneSyncer,
                                                    translationsSystem,
                                                    businessSystem,
                                                    licenseSystem,
                                                    manifest,
                                                }}
                                            />
                                        </ErrorBoundary>
                                    ))}
                                </ModulesCatalogueComponent>
                                // TODO: Maybe add here some [+] button with text "Navrhněte (si) vlastní modul"
                            );
                        }}
                    />
                </CategorizedModalBody>
            </CategorizedModal>
        </ModuleStoreStyle>
    );
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
