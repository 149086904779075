import { IDependenciesRecord, IDependenciesSet } from '../interfaces/IDependencies';

export function dependenciesRecordToDependenciesSet(dependenciesRecord: IDependenciesRecord): IDependenciesSet {
    const dependeciesSet: IDependenciesSet = new Set();

    for (const [name, dependency] of Object.entries(dependenciesRecord)) {
        dependeciesSet.add({ name, ...dependency });
    }

    return dependeciesSet;
}
