import React from 'react';

/**
 * @collboard-modules-sdk
 */
export type Factory<TValue> = () => TValue;

/**
 * @collboard-modules-sdk
 */
export type Factorable<TValue> = TValue | Factory<TValue>;

/**
 *
 * @collboard-modules-sdk
 */
export function factor<Value>(valueOrFunction: Factorable<Value>): Value {
    if (
        typeof valueOrFunction === 'function' &&
        !React.isValidElement(/* <- TODO: Import and use just a isValidElement */ valueOrFunction) &&
        !(valueOrFunction as any)[NOT_CONSTRUCTABLE]
    ) {
        // TODO: Probably cache factory
        return (valueOrFunction as any)();
    } else {
        return valueOrFunction as Value;
    }
}

/**
 *
 * @collboard-modules-sdk
 */
export const NOT_CONSTRUCTABLE = Symbol(`This object is not constructable`);

/**
 * TODO: Probably better name as Create/Creatable/factor
 */
