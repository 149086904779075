import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';

/**
 * VoiceSystem can recognize the voice and play speech from text.
 * Note: SoundSystem can play a sound vs. VoiceSystem can detect voice or speech.
 *
 * @private
 * @collboard-system
 */
export class VoiceSystem extends AbstractSystem {
    /*
        TODO: This class is a boilerplate of the system that we have not started working on yet.
        @see https://github.com/collboard/collboard/issues/91
    */

    protected async init() {}
}

/**
 * TODO: Define boundary between SoundSystem and VoiceSystem
 */
