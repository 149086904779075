import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';

/**
 * GenerateSystem is a kind of import system which allows us to import/create whole boards from other sources.
 * Note: CreateSystem - for individual arts, GenerateSystem - for whole board
 * Note: CreateSystem+GenerateSystem and ExportSystem are in some kind opposites.
 *
 * @private
 * @collboard-system
 */
export class GenerateSystem extends AbstractSystem {
    /*
        TODO: This class is a boilerplate of the system that we have not started working on yet.
        @see https://github.com/collboard/collboard/issues/153
    */

    protected async init() {}
}
