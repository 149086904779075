import { Promisable } from 'type-fest';
import { factor } from '../../../40-utils/IFactory';
import { ISystems } from '../../00-SystemsContainer/ISystems';
import { ToolbarName } from '../../ToolbarSystem/0-ToolbarSystem';
import { IToolbarIcon } from '../../ToolbarSystem/IToolbarIcon';
import { IModule, IModuleDefinition } from '../interfaces/IModule';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { ModuleInstallation } from '../ModuleInstallation';
/**
 * Makes toolbar icon module which setup/unsetup given submodule when user select/unselect icon
 * TODO: Probbably better name (makeIconModuleOnModule vs. makeIconModuleOnRoute)
 *
 * @collboard-modules-sdk
 */
export function makeIconModuleOnModule(protoModule: {
    manifest?: IModuleManifest;
    toolbar: ToolbarName;
    icon: IToolbarIcon | ((systems: ISystems) => Promisable<IToolbarIcon>);
    moduleActivatedByIcon: IModule;
}): IModuleDefinition {
    const { manifest } = protoModule;

    return {
        manifest,
        async setup(systems: ISystems) {
            const { toolbarSystem } = await systems.request('toolbarSystem');
            const { icon: protoIcon, toolbar } = protoModule;
            let icon: IToolbarIcon;

            if (typeof protoIcon === 'function') {
                icon = await protoIcon(systems);
            } else {
                icon = protoIcon;
            }

            const moduleActivatedByIconDefinition = factor(protoModule.moduleActivatedByIcon);

            if (!moduleActivatedByIconDefinition.manifest && manifest) {
                moduleActivatedByIconDefinition.manifest = {
                    name: `${manifest.name}/inner`,
                    requirePermissions: [
                        // TODO: What is better here, to keep requirePermissions empty or pass requirePermissions from the icon module?
                    ],
                };
            }

            return toolbarSystem.getToolbar(toolbar).registerIcon({
                ...icon,
                onSelect: () =>
                    ModuleInstallation.install(
                        moduleActivatedByIconDefinition,

                        systems,
                        `makeIconModuleOnModule`,
                    ),
            });
        },
    };
}

/**
 * TODO: !!x Rename to makeToolModule
 */
