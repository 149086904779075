import { errorMessageWithAdditional } from '../../../40-utils/errors/errorMessageWithAdditional';
import { EMPTY_MATERIAL } from './defaults';
import { IFullPhongMaterial, IMaterial } from './IMaterial';
import { isIPhongMaterial } from './IMaterial.guard';
import { isITexture } from './ITexture.guard';

/**
 * Converts material in any form to the phong one
 *
 * @param material in any form (just texture, phong)
 * @returns IFullPhongMaterial
 *
 * @collboard-modules-sdk
 */
export function getFullPhongMaterial(material: IMaterial): IFullPhongMaterial {
    if (material === undefined || material === null) {
        return getFullPhongMaterial(EMPTY_MATERIAL);
    } else if (isIPhongMaterial(material)) {
        return {
            ...EMPTY_MATERIAL,
            ...material,
        };
    } else if (isITexture(material)) {
        return {
            ...EMPTY_MATERIAL,
            emissiveTexture: material,
        };
    }

    throw new Error(errorMessageWithAdditional(`Can not get full phong material`, { material })); /* <- [🍚] */
}

/**
 * TODO: [🍚] Ask throw syntax:
 *       1) if(){ return }else if(){ return } throw
 *       2) if(){ return }else if(){ return }else{ throw }
 *       3) if(){ return } if(){ return } throw
 *
 */
