import React from 'react';
import styled from 'styled-components';
import { useSkin } from '../../../40-utils/react-hooks/useSkin';
import { ISkin } from '../../../50-systems/StyleSystem/ISkin';

/**
 * Categorized modal main body wrapper
 * Note: Use only inside component <CategorizedModal
 *
 * @collboard-modules-sdk
 */

export function CategorizedModalBody(props: { children: JSX.Element }) {
    const { skin } = useSkin();

    return (
        <CategorizedModalBodyStyle className="categorized-modal-body" {...{ skin }}>
            {props.children}
        </CategorizedModalBodyStyle>
    );
}

const CategorizedModalBodyStyle = styled.div<{ skin: ISkin }>`
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;
`;

/**
 * TODO: Maybe remove CategorizedModalBodyStyle + with this remove tag [🦅]
 * TODO: [🦅] Maybe just CategorizedModalBodyStyle as CategorizedModalBody will be enough
 */
