import React from 'react';
import { internalModules } from '../../../../50-systems/ModuleStore/internalModules';
import { makeUserInterfaceModule } from '../../../../50-systems/ModuleStore/makers/makeUserInterfaceModule';
import { UserInterfaceElementPlace } from '../../../../50-systems/UserInterfaceSystem/UserInterfaceElementPlace';
import { LanguageSelectComponent } from './LanguageSelectComponent';

internalModules.declareModule(() =>
    makeUserInterfaceModule({
        manifest: {
            flags: { isHidden: true },
            name: 'LanguageSelect',
            requirePermissions: [],
        },

        place: UserInterfaceElementPlace.WelcomeModalAdjacent,
        order: 10,
        async render(systems) {
            const { styleSystem } = await systems.request('styleSystem');

            // TODO: Pack language images with this module OR provide it from TranslationsSystem
            return <LanguageSelectComponent {...{ styleSystem }} />;
        },
    }),
);

/**
 * TODO: Probbably also use ReactContext for providing language so language can be switched during livetime of app without reloading
 */
