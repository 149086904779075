import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';

/**
 * The standard window that can be used by modules to choose a file.
 *
 * @private
 * @collboard-system
 */
export class FilepickSystem extends AbstractSystem {
    /*
        TODO: This class is a boilerplate of the system that we have not started working on yet.
        @see https://github.com/collboard/collboard/issues/202
    */

    protected async init() {}
}
