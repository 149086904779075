import React from 'react';
import { Vector } from 'xyzt';
import { useMeasure } from '../react-hooks/useMeasure';

interface IMeasureProps {
    onMeasured: (size: Vector) => void;
}

/**
 * Measure is component that will measure the size of the children.
 * Note: It internally uses useMeasure hook so you can decide if use useMeasure(...) OR <Measure>{...}</Measure>
 *
 * @collboard-modules-sdk
 */
export function Measure(
    props: React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <IMeasureProps>,
) {
    const { onMeasured, children } = props;

    const measureRef = useMeasure<HTMLDivElement>(onMeasured);

    return (
        <div
            ref={measureRef}
            className="wrapper" /* <- TODO: Maybe better name like "fragment" and some functional prefix "__fragment" */
        >
            {children}
        </div>
    );
}

/**
 * TODO: [🌺] Measure more often than once
 * TODO: [⚜️] Maybe instead of onMeasured callback receive subject as a box to put new values in
 * TODO: [🌹] Maybe instead of "wrapper" class hack use some ref unwrapping/forwarding technique
 */
