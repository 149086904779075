import { ISvgPath } from './ISvgPath';

export function stringifySvgPath(pathSegments: ISvgPath): string {
    return (
        '\n' +
        pathSegments
            .map(
                ({ command, positions }, index) =>
                    `${command === pathSegments[index - 1]?.command ? ` ` : command} ${positions
                        .map((position) => `${position.x.toFixed(1)},${position.y.toFixed(1)}`)
                        .join(' ')}`,
            )
            .join('\n') +
        '\n'
    );
}

/**
 * TODO: Use toFixed ARCY
 * TODO: ${firstPoint.x} ${firstPoint.y}`  <- via xyzt method
 */
