import * as SentryBrowser from '@sentry/browser';
import * as SentryNode from '@sentry/node';
import { ILogger, ISubLogger } from './ILogger';

export class SentryLogger implements ILogger, ISubLogger {
    private readonly loggers: Array<ILogger>;

    public constructor(private readonly sentry: typeof SentryBrowser | typeof SentryNode) {}

    public info(message?: any, ...optionalParams: Array<any>): this {
        this.sentry.captureMessage(message);
        return this;
    }
    public warn(warning?: any, ...optionalParams: Array<any>): this {
        // TODO: Warning servility
        this.sentry.captureMessage(warning);
        return this;
    }
    public error(error?: Error | any, ...optionalParams: Array<any>): this {
        this.sentry.captureException(error);
        return this;
    }

    public trace(...data: Array<any>): this {
        // Note: ignoring trace
        return this;
    }

    public appear() {
        // Note: no effect
        return this;
    }

    public end() {
        // Note: no effect
    }

    public group(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        // TODO: Trace groupings
        return new SentryLogger(this.sentry);
    }

    public groupCollapsed(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        // TODO: Trace groupings
        return new SentryLogger(this.sentry);
    }
}

/**
 * TODO: !! Log environment / business
 * TODO: Process optionalParams
 * TODO: Also log { level: Sentry.Severity.Info }
 */
