import { Arrayable, ArrayableFull, ArrayFull } from './typeHelpers';

/**
 *
 * @collboard-modules-sdk
 */
export function toArray<TItem>(input?: ArrayableFull<TItem> | string, delimiter?: string | null): ArrayFull<TItem>;
export function toArray<TItem>(input?: Arrayable<TItem> | string, delimiter?: string | null): Array<TItem>;
export function toArray<TItem>(input?: Arrayable<TItem> | string, delimiter: string | null = ','): Array<TItem> {
    if (input === undefined) {
        return [];
    }

    if (input instanceof Array) {
        return input;
    }

    if (input instanceof Set) {
        return Array.from(input);
    }

    // TODO: Weird JS arrays convert with Array.from

    if (typeof input === 'string') {
        return (
            (delimiter === null ? [input] : input.split(delimiter))
                .filter(Boolean)
                // TODO: Create some isNumeric util+test and this could be a bigger part of deserializing
                .map((item) => (/^-?\d*\.?\d*$/.test(item) ? parseFloat(item) : item)) as any
        );
    }

    return [input];
}
