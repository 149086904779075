import { JsonValue } from 'type-fest';
import { replaceUuids } from '../../../40-utils/uuid/replaceUuids';
import { centerArts } from '../../../50-systems/ImportSystem/utils/centerArts';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { AbstractPlacedArt } from '../../../71-arts/25-AbstractPlacedArt';

internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/html-native-import',
        deprecatedNames: ['@collboard/html-native-import', 'HtmlImport'],
    },
    async setup(systems) {
        const { importSystem, artSerializer } = await systems.request('importSystem', 'artSerializer');

        // TODO: What about installations that need to be reinstalled after board is changed?
        // Note: For lot of systems we are using this makeWhatever helpers. I am trying one system - ImportSystem without make helper to modules just to use this systems methods directly.
        return importSystem.registerFileSupport({
            priority: 10,

            async importFile({ file, logger, isNativeSupporter, boardPosition, willCommitArts, next }) {
                if (file.type !== 'text/html' && file.type !== 'image/svg+xml' && file.type !== 'image/svg') {
                    return next();
                }

                if (!isNativeSupporter) {
                    return next();
                }

                willCommitArts();
                const html = await file.text();
                const htmlElement = document.createElement('div');
                htmlElement.innerHTML = html
                    .split('collboard:')
                    .join(
                        'data-collboard-' /* <- Note: [0] This is hack how to avoid querySelector-ing namespaced attribute */,
                    );

                let artsJsons = await Array.from(
                    htmlElement.querySelectorAll(`*[data-collboard-art]` /* <- [0] */),
                ).map((artElement) => JSON.parse(artElement.getAttribute('data-collboard-art')!));

                // Note: Here is sanitization and normalization
                artsJsons = replaceUuids(artsJsons);

                const arts = (
                    await artsJsons.mapAsync(async (artJson) => {
                        const art = await artSerializer.deserialize(artJson as JsonValue);

                        if (!(art instanceof AbstractPlacedArt)) {
                            logger.error(`Deserialized object is not instance of AbstractPlacedArt`, {
                                notArt: art,
                            });
                            return null;
                        }

                        return art as AbstractPlacedArt;
                    })
                ).filter(Boolean);

                centerArts({ arts, center: boardPosition });
                return arts;
                // TODO: Instead of returning just remove native nodes and keep going with rest of the file which is stripped
            },
        });
    },
}));

/**
 * TODO: Maybe this should be named more generally as @collboard/internal/xml-native-import
 */
