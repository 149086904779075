import { CSSProperties } from 'react';
import { IVectorData, Transform, Vector } from 'xyzt';
import { string_url_image } from '../40-utils/typeAliases';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { AbstractBackgroundArt } from './26-AbstractBackgroundArt';

internalModules.declareModule(() => makeArtModule(BackgroundPatternArt));

/**
 * Art setting background with pattern style (scrolling)
 *
 * @collboard-modules-sdk
 */
export class BackgroundPatternArt extends AbstractBackgroundArt {
    public static serializeName = 'BackgroundPattern';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/background-pattern-art',
        deprecatedNames: '@collboard/background-pattern-art',
    };

    /**
     * @param src Source of the background pattern tile
     * @param size Size of the background pattern tile
     * @param opacity Opacity applied to background image
     * @param z see `AbstractBackgroundArt`
     */
    public constructor(public src: string_url_image, public size: IVectorData, public opacity: number = 1, z?: number) {
        super(z);
    }

    public additionalStyle(): CSSProperties {
        return {
            backgroundImage: `url('${this.src /* TODO: Escape */}')`,
            opacity: this.opacity,
        };
    }

    public transformStyle(transform: Transform): CSSProperties {
        const { x, y } = Vector.multiply(this.size, transform.scale);
        return {
            ...super.transformStyle(transform),
            backgroundSize: `${x}px ${y}px`, // TODO: xyzt Vector.toCssSize
        };
    }
}

/**
 * TODO: [🎚️] Implement IArt
 */
