import { errorMessageWithAdditional } from '../../../40-utils/errors/errorMessageWithAdditional';
import { ITexture } from './ITexture';
import { isIColorTexture, isITextureTexture } from './ITexture.guard';

interface ITextureToSvgResult {
    /**
     * Identification of stroke/fill which can be used in the SVG image
     * - It can be just in-place color, for example `#009edd` or `rgb(0,158,221)`
     * - It can be reference, for example `url(#c53dd063);`
     */
    textureId: string;

    /**
     * If textureId is reference, this is the source of the referenced definition
     */
    textureDefinition?: JSX.Element;
}

/**
 * Converts texture to SVG props
 *
 * @collboard-modules-sdk
 */
export function textureToSvg(texture: ITexture): ITextureToSvgResult {
    if (isIColorTexture(texture)) {
        return {
            textureId: texture.color /* <- TODO: Do here some sanitization/normalization */,
        };
    }

    if (!isITextureTexture(texture)) {
        throw new Error(
            errorMessageWithAdditional(`Can not get SVG definition from texture`, { texture }),
        ); /* <- [🍚] */
    }

    //  TODO: Implement texture material

    return {
        textureId: '#009edd',
    };
}

/**
 * TODO: Test
 * TODO: Equivalent function for Html> textureToHtml
 * TODO: Equivalent function for Babylon> textureToBabylon
 * TODO: Maybe id: string should have some semantic like id: string_svg_definition
 */
