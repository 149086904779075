/**
 * TODO: Probably in universal utils not just ControlSystems utils
 */
export function isElementEditable(element: Element, detect: { input: boolean; contentEditable: boolean }): boolean {
    if (detect.input && element.tagName === 'INPUT') {
        return true;
    }

    if (detect.contentEditable && element.hasAttribute('contenteditable')) {
        // TODO: Probably test not only deepestNode but iterate up through parants
        return true;
    }

    return false;
}
