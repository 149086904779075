import React from 'react';
import { /* TODO: type BehaviorSubject */ BehaviorSubject } from 'rxjs';
import { Icon } from '../../30-components/menu/Icon';
import { useObservable } from '../../40-utils/react-hooks/useObservable';
import { ToolTimerType } from './ToolTimerType';

interface ITimerToolMenuProps {
    toolTimerType: BehaviorSubject<ToolTimerType>;
}

export function TimerToolMenu(props: ITimerToolMenuProps) {
    const { toolTimerType } = props;

    const { value: toolTimerTypeValue } = useObservable(toolTimerType);

    return (
        <>
            {/* TODO: When more options iterate */}
            <Icon
                icon="timer"
                active={toolTimerTypeValue === ToolTimerType.Stopwatch}
                onClick={() => {
                    toolTimerType.next(ToolTimerType.Stopwatch);
                }}
            />
            <Icon
                icon="countdown"
                active={toolTimerTypeValue === ToolTimerType.Timer}
                onClick={() => {
                    toolTimerType.next(ToolTimerType.Timer);
                }}
            />
        </>
    );
}

/**
 * Note: In future this file will we in independent repository as external module.
 */
