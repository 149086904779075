import React from 'react';
import { EditablePlayground } from '../../30-components/utils/EditablePlayground';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../50-systems/ModuleStore/makers/makeModalModule';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            name: '@test/playground',
            description: 'Module made just for internal testing',
            flags: {
                isHidden: true /* <- TODO: Modal modules should be always hidden*/,
            },
            requirePermissions: [],
        },
        async createModal(systems) {
            /*
            const { exportSystem, translationsSystem, appState } = await systems.request(
                'exportSystem',
                'translationsSystem',
                'appState',
            );
            */

            return (
                <>
                    <EditablePlayground />
                </>
            );
        },
    }),
);
