import { deepMapObject } from '../object/deepMapObject';
import { randomUuid } from '../randomUuid';
import { uuid } from '../typeAliases';
import { isValidUuid } from './isValidUuid';

/**
 * Replaces all UUID strings in the object but preserve its identity
 * - Same UUIDs will be chnaged into another BUT same UUIDs
 * - Different UUIDs will be different
 *
 * @collboard-modules-sdk
 */
export function replaceUuids<TObject extends object>(object: TObject): TObject {
    const uuidsMap = new Map<uuid, uuid>();

    return deepMapObject(object, (value) => {
        if (!isValidUuid(value)) {
            return value;
        }

        if (uuidsMap.has(value)) {
            return uuidsMap.get(value);
        }

        const newUuid = randomUuid();
        uuidsMap.set(value, newUuid);

        return newUuid;
    });
}
