import { string_uri_part } from '../typeAliases';
import { NotFoundError } from './NotFoundError';

/**
 * @collboard-modules-sdk
 */

export class NotBoardError extends NotFoundError {
    public constructor(uriId: string_uri_part, artClass: string) {
        super(`Entity /${uriId} is not a board but ${artClass}.`);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, NotBoardError.prototype);
    }
}
