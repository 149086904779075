import { IVectorData, Vector } from 'xyzt';
import { windowSize } from './getWindowSize';

const scale = (Math.PI / windowSize.value.y) * 2;

/**
 *
 * @deprecated Remove 3D to external modules
 * @collboard-modules-sdk
 */
export function create3dRotation(vector: IVectorData): Vector {
    const scaled = Vector.fromObject(vector).scale(scale);
    const onlyX = scaled.rearrangeAxes(([x, y, z]) => [0, -x, 0]);
    return Vector.fromObject(onlyX);

    /*
    TODO: I do not know how to include both axis just x or y are working (code bellow) but both not.
    // Note: till solve this problem please do not clean the code bellow

    //const y = scaled.map(([x, y, z]) => [y * Math.cos(-x), 0, y * Math.sin(-x)]);


    this._localDirection.copyFromFloats(this.inertialPanningX, this.inertialPanningY, this.inertialPanningY);
    this._localDirection.multiplyInPlace(this.panningAxis);
    this._viewMatrix.invertToRef(this._cameraTransformMatrix);
    Vector3.TransformNormalToRef(this._localDirection, this._cameraTransformMatrix, this._transformedDirection);

    /*
    const scaled = Vector.fromObject(vector).scale(1 / 60);
    scaled.x = (Math.PI / 10) * 9;

    const x = scaled.map(([x, y, z]) => [0, -x, 0]);
    //const y = scaled.map(([x, y, z]) => [Math.cos(-x) * y, -x, Math.sin(-x) * y]);

    const y = scaled.map(([x, y, z]) => [y, 0, 0]);
    //const y = scaled.map(([x, y, z]) => [Math.cos(-x) * y, 0, Math.sin(-x) * y]);

    const yy = scaled.map(([x, y, z]) => [Math.sin(-x * p) * y, 0, Math.cos(-x * p) * y]);

    //return yy;
    return Vector.add(x, yy);

    const qX = Quaternion.FromEulerAngles(x.x, x.y, x.z);
    const qY = Quaternion.FromEulerAngles(y.x, y.y, y.z);

    const rr = Quaternion.RotationQuaternionFromAxis(
        new Vector3(scaled.y, scaled.x, 1),
        new Vector3(0, 0, 0),
        new Vector3(0, 0, 0),
    );

    //const q1 = qX.add(qY);
    //const v = q2.toEulerAngles();

    //return Vector.add(x.scale(Math.cos(scaled.y)), y.scale(Math.sin(scaled.x)));

    //const q = new Quaternion(vector.x, vector.y, 0, 0);
    //const v = q.toEulerAngles();
    return Vector.fromObject(rr);


    /* */
}
