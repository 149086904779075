import React from 'react';
import { TouchFrame } from 'touchcontroller';
import { IVectorData, Vector } from 'xyzt';
import { classNames } from '../../40-utils/classNames';
import { number_positive, string_color } from '../../40-utils/typeAliases';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { NEAR_DISTANCE } from '../25-AbstractPlacedArt';
import { Abstract2dArt } from '../26-Abstract2dArt';
import { ISvgPath } from './utils/svgPath/ISvgPath';
import { stringifySvgPath } from './utils/svgPath/stringifySvgPath';

internalModules.declareModule(() => makeArtModule(FreehandArt));

/**
 * Handwritten free line with lot of segments
 *
 * @collboard-modules-sdk
 */
export class FreehandArt extends Abstract2dArt {
    public static serializeName = 'Freehand';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/freehand-art',
        deprecatedNames: '@collboard/freehand-art',
    };

    public frames: Array<Pick<TouchFrame, 'position' | 'time'>> /* <- TODO: Protect array from writing from outside */;
    public smoothing: 0 | number_positive;
    public color: string_color;
    public weight: 0 | number_positive;

    // tslint:disable-next-line:variable-name
    private __path?: ISvgPath;
    // tslint:disable-next-line:variable-name
    private __minX?: number;
    // tslint:disable-next-line:variable-name
    private __maxX?: number;
    // tslint:disable-next-line:variable-name
    private __minY?: number;
    // tslint:disable-next-line:variable-name
    private __maxY?: number;

    public constructor(frames: Array<TouchFrame>, color: string_color, weight: number) {
        super();
        this.frames = frames;
        this.color = color;
        this.weight = weight;
    }

    public get topLeft() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        this.calculateBoundingBox();
        return new Vector(this.__minX, this.__minY).add(this.shift);
    }
    public get bottomRight() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        this.calculateBoundingBox();
        return new Vector(this.__maxX, this.__maxY).add(this.shift);
    }
    public get size() {
        return this.bottomRight.subtract(this.topLeft);
    }
    public set size(newSize: IVectorData) {
        this.calculateBoundingBox();

        try {
            const scaleX = (newSize.x || 0) / (this.__maxX! - this.__minX!);
            const scaleY = (newSize.y || 0) / (this.__maxY! - this.__minY!);

            this.frames.forEach((frame) => {
                const position = frame.position as any;
                position.x = (frame.position.x || 0) * scaleX;
                position.y = (frame.position.y || 0) * scaleY;
            });
        } finally {
            delete this.__path;
            delete this.__minX;
            delete this.__maxX;
            delete this.__minY;
            delete this.__maxY;
            this.calculatePath();
        }
    }

    public isNear(pointToTest: IVectorData) {
        // TODO: [1] Detect from bézier curve not original points
        // Should detect even near lines, but this is good enough
        return (
            this.frames.filter(
                (frame1) =>
                    Vector.add(frame1.position, this.shift).distance(pointToTest) <= this.weight + NEAR_DISTANCE,
            ).length > 0
        );
    }

    public get acceptedAttributes() {
        return ['color', 'weight', 'size'];
    }

    public pushFrame(frame: Pick<TouchFrame, 'position' | 'time'>): this {
        delete this.__path;
        delete this.__minX;
        delete this.__maxX;
        delete this.__minY;
        delete this.__maxY;
        this.frames.push(frame);
        return this;
    }

    /**
     * @deprecated [🍒] remove this method
     */
    private calculateBoundingBox() {
        // TODO: Probably use BoundingBox from LIB touchcontroller

        if (this.__minX !== undefined) {
            return;
        }

        const smoothingPadding = this.smoothing || 0; /* <- TODO: Maybe it can be less */

        const xVals = this.frames.map((frame) => frame.position.x || 0);
        const yVals = this.frames.map((frame) => frame.position.y || 0);

        // TODO: Use Just Math.min(...) + across the repositiory
        this.__minX = Math.min.apply(null, xVals) - this.weight - smoothingPadding;
        this.__maxX = Math.max.apply(null, xVals) + this.weight + smoothingPadding;
        this.__minY = Math.min.apply(null, yVals) - this.weight - smoothingPadding;
        this.__maxY = Math.max.apply(null, yVals) + this.weight + smoothingPadding;
    }

    private calculatePath() {
        this.calculateBoundingBox();

        if (this.__path !== undefined) {
            return;
        }

        const remainderFrom = new Vector(this.__minX, this.__minY).subtract(Vector.square(this.weight));
        const remainder = remainderFrom.subtract(remainderFrom.map(Math.floor));
        const pointsRelative = this.frames.map((frame) =>
            frame.position.subtract(new Vector(this.__minX! - this.weight, this.__minY! - this.weight)).add(remainder),
        );

        // Note: Simple lines
        this.__path = pointsRelative.map((point, index) => ({
            command: index === 0 ? 'M' : 'L',
            positions: [point],
        }));
    }

    public render(isSelected: boolean) {
        this.calculatePath();
        // TODO: [🍒][🍽️] There is some performance issue that one art is rendered looot of times

        return (
            <div
                // TODO: [🍒][0]! This should became <ArtOwnShell
                className={classNames('art', isSelected && 'selected')}
                style={{
                    position: 'absolute',
                    fontSize: 0 /* <- Note: Removing fontSize to get rid of artefacts of whitespaces in deep zoom */,
                    left: Math.floor(this.__minX! - this.weight + (this.shift.x || 0)) /* <- LIB xyzt .toTopLeft() */,
                    top: Math.floor(this.__minY! - this.weight + (this.shift.y || 0)) /* <- LIB xyzt .toTopLeft() */,
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={Math.ceil(this.__maxX! - this.__minX! + 2 * this.weight) + 1}
                    height={Math.ceil(this.__maxY! - this.__minY! + 2 * this.weight) + 1}
                >
                    <path
                        d={stringifySvgPath(this.__path!)}
                        stroke={this.color}
                        strokeWidth={this.weight}
                        fillOpacity="null"
                        strokeOpacity="null"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        className="collisions"
                    />
                </svg>
            </div>
        );
    }
}

/**
 * TODO: [🏹][👀] Focus cursor from other users in unfinished Freehands
 * TODO: FreehandArt with just 1 frame should be invalid
 * TODO: Instead of x/y pairs use Vector
 * TODO: [✏️] Fix> react-dom.development.js:630 Error: <svg> attribute width: Expected length, "NaN".
 * TODO: Probbably (maybe in html/css values MUST be whole integers): ACTRY To be infinitelly zoomable avoid using Math.ceil, Math.floor, Math.round,...
 * TODO: [🚉] There should be some rounding optimization for svg numbers (for example path)
 *       From: d="M60.00000000000006 70.4999999999999 L60.00000000000006 70.4999999999999...
 *       To:   d="M60 70.5 L60 70.5...
 *
 * TODO: [🍎]  Use IMaterial instead of color
 * TODO: [🍎]  Use IShape instead of weight, points,...
 * TODO: [🕺] Rename weight => spotSize, edgeSize (as it is in PolygonArt and FreehandArt)
 * TODO: [🎚️] Implement IArt
 * Note: [🌡️]
 * TODO: [1] Detect collisions from bézier curve not original points
 */
