import { internalModules } from '../../../50-systems/ModuleStore/internalModules';

internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/delete-shortcut',
        deprecatedNames: ['@collboard/delete-shortcut', 'DeleteShortcut'],
    },
    async setup(systems) {
        const { controlSystem, appState, materialArtVersioningSystem } = await systems.request(
            'controlSystem',
            'appState',
            'materialArtVersioningSystem',
        );
        return controlSystem.registerControl({
            defaultShortcut: ['Delete'],
            executor: () => {
                // TODO: @roseckyj do you think when more objects ask before like in erase all icon?
                materialArtVersioningSystem
                    .createOperation('Delete from board')
                    .takeArts(
                        ...materialArtVersioningSystem.artsPlaced.filter((art) =>
                            appState.selected.value.includes(art),
                        ),
                    )
                    .delete();

                // TODO: When canceling selection after some operation, make this selection again if this operation undone
                appState.cancelSelection();
            },
        });
    },
}));
