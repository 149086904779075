import React from 'react';
import { IVectorData, Vector } from 'xyzt';
import { ErrorBoundary } from '../../30-components/utils/ErrorBoundary';
import { classNames } from '../../40-utils/classNames';
import { Measure } from '../../40-utils/react-components/Measure';
import { SystemsContainerContext } from '../../40-utils/react-hooks/useSystems';
import { string_color } from '../../40-utils/typeAliases';
import { ISystems } from '../../50-systems/00-SystemsContainer/ISystems';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { Abstract2dArt } from '../26-Abstract2dArt';
import { IListStyle } from './interfaces/IListStyle';
import { TextArtComponent } from './TextArtComponent';

internalModules.declareModule(() => makeArtModule(TextArt));

/**
 * @collboard-modules-sdk
 */
export class TextArt extends Abstract2dArt {
    public static serializeName = 'Text';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/text-art',
        deprecatedNames: '@collboard/text-art',
    };

    public constructor(
        public content: string,
        public color: string_color,
        public fontSize: number,
        /* TODO: [✨] Maybe add is prefix */
        public bold: boolean,
        /* TODO: [✨] Maybe add is prefix */
        public italic: boolean,
        /* TODO: [✨] Maybe add is prefix */
        public underline: boolean,
        public listStyle: IListStyle,
    ) {
        super();
    }

    /**
     * This is for backwards compatibility
     *
     * TODO: Is this working?
     * https://collboard.com/aubppdztrgbwa3uyvqu2
     *
     */
    public set point1(point1: IVectorData) {
        this.setShift(point1);
    }

    // tslint:disable-next-line: variable-name
    private __size = new Vector(this.fontSize * 0.5, this.fontSize * 1.5);

    public get size() {
        return this.__size;
    }

    public get topLeft() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return this.shift;
    }
    public get bottomRight() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return Vector.add(this.shift, this.size);
    }

    public isNear(pointToTest: IVectorData) {
        return (
            (pointToTest.x || 0) > (this.shift.x || 0) &&
            (pointToTest.x || 0) < Vector.add(this.shift, this.size).x &&
            (pointToTest.y || 0) > (this.shift.y || 0) &&
            (pointToTest.y || 0) < Vector.add(this.shift, this.size).y
        );
    }

    public get acceptedAttributes() {
        return ['color', 'fontSize', 'fontStyle'];
    }

    public async render(/* @deprecated */ isSelected: boolean, systems: ISystems) {
        /*  [🍒] */

        // TODO:[🍽️] There is some performance issue that one art is rendered looot of times> debugger;

        await systems.request('appState', 'materialArtVersioningSystem');

        return (
            <div
                // TODO: [🍒][0]! This should became <ArtOwnShell
                className={classNames('art', isSelected && 'selected')}
                style={{
                    position: 'absolute',
                    fontSize: 0 /* <- Note: Removing fontSize to get rid of artefacts of whitespaces in deep zoom */,
                    left: Math.floor(
                        +(this.shift.x || 0),
                    ) /* <- LIB xyzt .toTopLeft() */ /* <- LIB xyzt .toTopLeft() */,
                    top: Math.floor(this.shift.y || 0) /* <- LIB xyzt .toTopLeft() */ /* <- LIB xyzt .toTopLeft() */,
                }}
            >
                <SystemsContainerContext.Provider value={systems}>
                    <ErrorBoundary>
                        <Measure
                            onMeasured={(size) =>
                                void (
                                    (this.__size = size.add({
                                        x: this.fontSize * 0.5,
                                    })) /* <- Note+TODO: [🍣] Adding one letter width to hide lagging of selected box after the real siz */
                                )
                            }
                        >
                            <TextArtComponent
                                art={this}
                                appearance={{ color: this.color }}
                                shape={{
                                    // TODO: [🍽️] This is rendered looot of times because here is created same props content but differenmt object
                                    content: this.content,
                                    fontSize: this.fontSize,
                                    bold: this.bold,
                                    italic: this.italic,
                                    underline: this.underline,
                                    listStyle: this.listStyle,
                                }}
                            />
                        </Measure>
                    </ErrorBoundary>
                </SystemsContainerContext.Provider>
            </div>
        );
    }
}

/**
 * TODO: [👀] Focus cursor as a thing which better keeps focus
 * TODO: [👀] Focus cursor from other users in edited texts
 * TODO: [🍒] Every Art should look like this + what do render method here should do RenderSystem OR ArtSchell OR sth. like that
 * TODO: LIB xyzt: Vector.minmax, Vector.min, Vector.max
 */
