import React from 'react';
import { Clickable } from '../../../../30-components/utils/Clickable';
import { useSkin } from '../../../../40-utils/react-hooks/useSkin';
import { TranslationsSystem } from '../../../TranslationsSystem/0-TranslationsSystem';
import { INotification } from '../../INotification';
import { NotificationActionComponent } from '../notifications/NotificationActionComponent';
import { AnnouncementStyledDiv } from './AnnouncementStyledDiv';

interface IAnnouncementComponentProps {
    order: number;
    translationsSystem: TranslationsSystem;
    notification: INotification;
    closeHandler: () => void;
}

/**
 * TODO: @roseckyj maybe unite AnnouncementComponent and NotificationComponent
 */
export function AnnouncementComponent({
    order,
    translationsSystem,
    notification: { type, onClick, href, title, subtitle, canBeClosed, body, actions },
    closeHandler,
}: IAnnouncementComponentProps) {
    return (
        <AnnouncementStyledDiv {...useSkin()} {...{ order }}>
            <Clickable className="announcement-content" {...{ onClick, href }} hrefByJavascript>
                <div className="title">
                    {title && translationsSystem.pickMessage(title)}
                    {subtitle && <div className="subtitle">{translationsSystem.pickMessage(subtitle)}</div>}
                </div>
                {body && translationsSystem.pickMessage(body)}
                {actions && actions.length && (
                    <>
                        {actions?.map((action) => (
                            <NotificationActionComponent key={action.action} {...{ action, translationsSystem }} />
                        ))}
                    </>
                )}
            </Clickable>
        </AnnouncementStyledDiv>
    );
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
