import { ISerializerRule } from '../interfaces/ISerializerRule';

/**
 * @@x
 */
export const dateSerializerRule: ISerializerRule = {
    name: 'DATE',

    priority: 10 /* <- TODO: [🍉] One place with priorities */,
    serialize({ value, next }) {
        if (!(value instanceof Date)) {
            return next();
        }
        return value.toISOString();
    },
    deserialize({ serialized, next }) {
        if (
            typeof serialized !== 'string' ||
            !/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/.test(serialized)
        ) {
            return next();
        }
        return new Date(serialized);
    },
};

/**
 * TODO: Also accept moment object
 * TODO: durationSerializerRule
 * TODO: [🌉] In future use satisfies ISerializerRule
 */
