import React from 'react';
import { windowSize } from '../40-utils/getWindowSize';
import { useObservable } from '../40-utils/react-hooks/useObservable';
import { useSystemsInCore } from '../40-utils/react-hooks/useSystemsInCore';
import { BoardComponentFloatingMenuContent } from './BoardComponentFloatingMenuContent';
import { FloatingMenu } from './menu/FloatingMenu';

export function BoardComponentFloatingMenu() {
    // console.log('🅰️', 'BoardComponentFloatingMenu', 'render');

    const { appState, materialArtVersioningSystem } = useSystemsInCore('appState', 'materialArtVersioningSystem');

    // [🎱]
    // Note: [🍵] Observing without getting value is a bit ugly trick but it works because methods and subcomponents bellow are dependent on observed values
    useObservable(windowSize);
    useObservable(appState.transform);
    useObservable(materialArtVersioningSystem.artsObservable);
    useObservable(appState.selected);
    useObservable(appState.selection);

    return (
        <>
            {appState.selected.value.length > 0 && !appState.selection.value && (
                <FloatingMenu screenPosition={appState.getSelectedBoundingBox()!.topLeft}>
                    <BoardComponentFloatingMenuContent />
                </FloatingMenu>
            )}
        </>
    );
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 * TODO: [🏊‍♂️] Better naming and distinction of Collboard-specific components vs utils components
 */
