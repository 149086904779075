import { IModulesStorageWeak } from '../../50-systems/ModuleStore/interfaces/IModulesStorage';
import { ModuleInstaller } from '../../50-systems/ModuleStore/ModuleInstaller';
import { string_module_name } from '../typeAliases';
import { errorMessageWithAdditional } from './errorMessageWithAdditional';
import { NotFoundError } from './NotFoundError';

/**
 * @collboard-modules-sdk
 */

export class ModuleNotFoundError extends NotFoundError {
    public constructor(options: {
        [key: string]: any;
        moduleName: string_module_name;
        moduleStorage: IModulesStorageWeak;
        modulenstaller: ModuleInstaller;
    }) {
        const { moduleName } = options;

        super(
            errorMessageWithAdditional(
                `
                Cannot find module ${moduleName}.
                Try to regenerate module include list by 'npm run generate-modules-list'
                OR check if modules in DEFAULT_CORNERSTONE_DEPENDENCIES are correctly spelled and up-to-date.
            `,
                options,
            ),
            // TODO: Use mechanism how to label additional info about errors in all places
            // TODO: Use spaceTrim in all multiline error messages
        );

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ModuleNotFoundError.prototype);
    }
}
