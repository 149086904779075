import { Registration } from 'destroyable';
import { Transform } from 'xyzt';
import { FreehandArt } from '../../../../71-arts/50-FreehandArt/FreehandArt';
import { PointArt } from '../../../../71-arts/50-PointArt/50-PointArt';
import { touchFrameToArtFrame } from '../../../../72-tools/FreehandTool';
import { internalModules } from '../../../ModuleStore/internalModules';
import { makeIconModuleOnModule } from '../../../ModuleStore/makers/makeIconModuleOnModule';
import { ToolbarName } from '../../../ToolbarSystem/0-ToolbarSystem';

internalModules.declareModule(() =>
    makeIconModuleOnModule({
        manifest: {
            name: '@test/simple-token-license/gold-freehand',
            title: '🖌️ Gold freehand',
            description: { en: 'Testing SIMPLE_TOKEN usage license' },
            usageLicenses: [
                {
                    type: 'SIMPLE_TOKEN',
                    token: '3e2f8a33-8652-4b37-a65b-28c33000334e',
                    price: {
                        amount: 3,
                        currency: 'USD',
                    },
                    buyLink:
                        'https://github.com/collboard/collboard/blob/main/src/50-systems/LicenseSystem/modules/mocked-license-eshop.md',
                    isModuleAutoInstalled: false,
                },
                {
                    type: 'SIMPLE_TOKEN',
                    token: 'gold',
                    price: 0,
                    isModuleAutoInstalled: true,
                },
            ],

            flags: {
                isShowcase: true,
            },
        },
        toolbar: ToolbarName.Tools,
        icon: {
            icon: '🖌️',
        } /* <- TODO: [💽] This is a but ugly, maybe allow IToolbarIcon to be just a string_char_emoji */,
        moduleActivatedByIcon: {
            async setup(systems) {
                const { touchController, collSpace, appState, materialArtVersioningSystem } = await systems.request(
                    'touchController',
                    'collSpace',
                    'appState',
                    'materialArtVersioningSystem',
                );
                return Registration.fromSubscription((registerAdditionalSubscription) =>
                    touchController.touches.subscribe((touch) => {
                        appState.cancelSelection();

                        const color = 'rgb(217 179 43)';
                        const weight = 5;

                        const freehandInProcess = new FreehandArt([], color, weight);

                        const operation = materialArtVersioningSystem.createPrimaryOperation();
                        operation.newArts(freehandInProcess);

                        registerAdditionalSubscription(
                            touch.frames.subscribe({
                                // TODO: There should be some predetermined order which subscriber (freehand,move,...) to call first which second... and it should be determined by module priority NOT installation (subscription) order
                                async next(touchFrame) {
                                    // TODO: [👘] Nicer syntax
                                    const frame = touchFrameToArtFrame(touchFrame);
                                    frame.position = collSpace.pickPoint(frame.position).point;

                                    operation.update(
                                        freehandInProcess.pushFrame(frame) /* <- TODO: Make pushFrame + use ACRY */,
                                    );
                                },
                                complete() {
                                    if (freehandInProcess.frames.length === 1) {
                                        operation.update(
                                            new PointArt(
                                                {
                                                    spotSize: weight,
                                                },
                                                {
                                                    color,
                                                },
                                                Transform.translate(freehandInProcess.frames[0]!.position),
                                            ),
                                        );
                                    } else {
                                        freehandInProcess.smoothing = weight * 0.3 * 100;
                                        // <- TODO: Allow to choose via attribute
                                        operation.update(freehandInProcess);
                                    }

                                    operation.persist();
                                },
                            }),
                        );
                    }),
                );
            },
        },
    }),
);

/**
 * TODO: DRY some util for making Freehand tools
 */
