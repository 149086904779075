import { IVectorData, Vector } from 'xyzt';

/**
 * @deprecated this should be using LIB xyzt boundingBox
 */
export type square = { topLeft: Vector; bottomRight: Vector };

/**
 * @deprecated this should be using LIB xyzt boundingBox
 */
export function pointsToSquare(point1: IVectorData, point2: IVectorData): square {
    return {
        topLeft: new Vector(Math.min(point1.x || 0, point2.x || 0), Math.min(point1.y || 0, point2.y || 0)),
        bottomRight: new Vector(Math.max(point1.x || 0, point2.x || 0), Math.max(point1.y || 0, point2.y || 0)),
    };
}
