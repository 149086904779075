import { Color } from '../../../40-utils/color/Color';
import { errorMessageWithAdditional } from '../../../40-utils/errors/errorMessageWithAdditional';
import { ITexture } from './ITexture';
import { isIColorTexture, isITextureTexture } from './ITexture.guard';

/**
 * Converts texture to color
 * If the texture is not a simple color, returns average color of the texture
 *
 * @param texture
 * @returns Promise<Color>
 *
 * @collboard-modules-sdk
 */
export async function textureToColor(texture: ITexture): Promise<Color> {
    if (isIColorTexture(texture)) {
        return Color.from(texture.color);
    }

    if (!isITextureTexture(texture)) {
        throw new Error(
            errorMessageWithAdditional(`Can not get color definition from texture`, { texture }),
        ); /* <- [🍚] */
    }

    return await Color.fromImage(texture.textureSrc);
    // TODO: How should this look like tail async return
}
