import React from 'react';
import { Color } from '../../../40-utils/color/Color';

interface IHandwrittenCollboardLogoProps {
    /**
     * If true, testing background will be shown
     */
    isTesting?: boolean;

    /**
     * TODO: [LogoDesign] Remove
     * If true, there will be shown only "Collb"
     */
    isCropped?: boolean;

    color: Color;
    height: number;
    weight: number;
    style?: React.CSSProperties /* <- TODO: Import and use just a CSSProperties */;
    onClick?: React.MouseEventHandler/* <- TODO: Import and use just a MouseEventHandler */ <SVGElement>;
}

export function HandwrittenCollboardLogo(props: IHandwrittenCollboardLogoProps) {
    const { isTesting, isCropped, color, height, weight, style, onClick } = props;

    const viewBox = [0, 0, 740, 255];

    if (isCropped) {
        viewBox[2]! *= 0.45;
    }

    return (
        <svg
            style={{
                height,
                ...style,
            }}
            viewBox={viewBox.join(' ')}
            {...{ onClick }}
        >
            {isTesting && <rect x="-1000" y="-1000" width="3000" height="3000" fill="rgba(255,0,0,0.5)" />}

            {isCropped && (
                <defs>
                    <linearGradient id="logo-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="40%" stop-color={color.toString()} />
                        <stop offset="45%" stop-color={color.withAlpha(0).toString()} />
                    </linearGradient>
                </defs>
            )}

            <g transform="translate(10,10)">
                {/* [🖋️] */}
                <path
                    stroke={isCropped ? `url('#logo-gradient')` : color.toString()}
                    strokeWidth={weight}
                    vectorEffect="non-scaling-stroke"
                    fillOpacity="null"
                    strokeOpacity="null"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="transparent" /* <- TODO: What is the best solution nothing OR fill="none" OR fill="transparent" */
                    d="
                        M
                        90.00,67.00
                        C
                        178.00,-33.00
                        79.00,6.00
                        51.00,35.00
                        22.00,63.00
                        8.00,83.00
                        3.00,129.00
                        c
                        -4.00,46.00
                        12.00,92.00
                        40.00,92.00
                        28.00,0.00
                        64.00,-46.00
                        79.00,-60.00
                        14.00,-13.00
                        20.00,-29.00
                        0.00,-31.00
                        -21.00,-1.00
                        -53.00,92.00
                        -23.00,92.00
                        29.00,0.00
                        42.00,-37.00
                        43.00,-59.00
                        0.00,-16.00
                        -1.00,-36.00
                        -19.00,-33.00
                        -17.00,3.00
                        -9.00,17.00
                        5.00,16.00
                        14.00,-1.00
                        11.00,48.00
                        29.00,36.00
                        17.00,-12.00
                        52.00,-95.00
                        61.00,-125.00
                        12.00,-45.00
                        -22.00,-65.00
                        -36.00,-17.00
                        -13.00,47.00
                        -39.00,183.00
                        -7.00,183.00
                        31.00,0.00
                        76.00,-117.00
                        90.00,-164.00
                        13.00,-46.00
                        -20.00,-66.00
                        -32.00,-18.00
                        -12.00,47.00
                        -35.00,183.00
                        2.00,183.00
                        37.00,0.00
                        69.00,-113.00
                        82.00,-162.00
                        13.00,-49.00
                        -20.00,-69.00
                        -34.00,-21.00
                        -14.00,47.00
                        -31.00,183.00
                        4.00,183.00
                        35.00,0.00
                        36.00,-89.00
                        15.00,-68.00
                        -20.00,21.00
                        44.00,11.00
                        61.00,2.00
                        23.00,-11.00
                        0.00,-41.00
                        -19.00,-12.00
                        -18.00,29.00
                        -28.00,78.00
                        -3.00,78.00
                        24.00,0.00
                        35.00,-36.00
                        40.00,-62.00
                        4.00,-26.00
                        -30.00,-46.00
                        -31.00,-6.00
                        0.00,22.00
                        27.00,0.00
                        53.00,-11.00
                        25.00,-10.00
                        33.00,-12.00
                        33.00,-12.00
                        0.00,0.00
                        -19.00,3.00
                        -33.00,15.00
                        -13.00,11.00
                        -22.00,76.00
                        0.00,77.00
                        22.00,0.00
                        24.00,-63.00
                        33.00,-92.00
                        -3.00,16.00
                        -14.00,89.00
                        9.00,92.00
                        11.00,0.00
                        26.00,-59.00
                        24.00,-92.00
                        -2.00,32.00
                        21.00,30.00
                        29.00,0.00
                        -14.00,41.00
                        -9.00,92.00
                        -3.00,92.00
                        14.00,0.00
                        11.00,-100.00
                        77.00,-83.00
                        0.00,0.00
                        -22.00,-7.00
                        -38.00,6.00
                        -16.00,14.00
                        -23.00,77.00
                        -5.00,76.00
                        17.00,0.00
                        35.00,-52.00
                        46.00,-92.00
                        C
                        588.00,89.00
                        603.00,47.00
                        615.00,2.00
                        570.00,152.00
                        550.00,221.00
                        611.00,222.00
                        c
                        60.00,0.00
                        82.00,-40.00
                        99.00,-79.00
                    "
                />
            </g>
        </svg>
    );
}

/**
 * TODO: Use dynamic id instead of "logo-gradient"
 * TODO: Maybe accept material (basics are set by <defs>)
 *       And behave like Art
 * TODO: Maybe put here image from assets folder
 *       Also remove old logos from assets folder (like logo-small.png)
 * TODO: Naming system for logo - place, color, size (small ), format, .com, icon,...
 * TODO: Put alt in SVG
 *       OR maybe use <figure or <picture @see https://css-tricks.com/accessible-svgs/
 */
