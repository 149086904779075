/**
 * @collboard-modules-sdk
 * @sideeffect dependent on class __ArtShell maked by <ArtShell component
 */
export function findArtShellElement(element: HTMLElement): HTMLElement | null {
    if (element.classList.contains(`__ArtShell`)) {
        return element;
    } else if (element.parentElement) {
        return findArtShellElement(element.parentElement);
    } else {
        return null;
    }
}
