import { Destroyable, IDestroyable, softDestroy } from 'destroyable';
import { Promisable } from 'type-fest';
import { Queue } from '../../40-utils/tasks/Queue';

export class FocusScope<TSubject> extends Destroyable implements IDestroyable {
    private active: null | { subject: TSubject; destroyable: Promisable<IDestroyable> } = null;

    public get activeSubject(): TSubject | null {
        return this.active?.subject || null;
    }

    private queue = new Queue<void>();

    public async takeFocus(options: { subject: TSubject; create: () => Promisable<IDestroyable> }): Promise<void> {
        return this.queue.task(async () => {
            if (this.active) {
                await softDestroy(this.active.destroyable);
            }
            const { subject, create } = options;
            this.active = { subject, destroyable: create() };
        });
    }

    public async blurFocus(): Promise<void> {
        return this.queue.task(async () => {
            if (this.active) {
                await softDestroy(this.active.destroyable);
                this.active = null;
            }
        });
    }
}
