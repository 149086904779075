import { AbstractArt } from './20-AbstractArt';

/*
 * Note: This is not in the internal module because some of the Arts are so
 * tightly connected with the core that they need to be there, not in
 * optionally deactivateable module.
 * This is usage of null object pattern.
 */

/**
 * All removed arts get replaced with DeletedArt as a terminal point of
 * it's existence.
 *
 * *Note: Committing any art with predecessor of type DeletedArt results in
 * undefined behavior since DeletedArts are removed in garbage collection
 * process.*
 *
 * @collboard-modules-sdk
 */
export class DeletedArt extends AbstractArt {
    public static serializeName = 'Deleted';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/deleted-art',
        deprecatedNames: '@collboard/deleted-art',
    };
}

/**
 * TODO: [🎚️] Implement IArt
 */
