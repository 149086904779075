import React from 'react';
import { useSystemsInCore } from '../40-utils/react-hooks/useSystemsInCore';
import { Icon } from './menu/Icon';
import { Separator } from './menu/Separator';

export function BoardComponentFloatingMenuContent() {
    // console.log('🅰️', 'BoardComponentFloatingMenu', 'render');
    const { appState, attributesSystem, materialArtVersioningSystem } = useSystemsInCore(
        'appState',
        'attributesSystem',
        'materialArtVersioningSystem',
    );

    const commonAttributesOfSelectedObjects = appState.getCommonAttributesOfSelectedArts();
    const selectedAreLocked =
        appState.selected.value.length > 0 && appState.hasAnyOfSelectedArtsGivenAttribute('locked') === true;

    // tslint:disable-next-line:ban-types
    const changeAttributeValueOfSelectedArts = (key: string, value: string | number | Object) => {
        materialArtVersioningSystem
            .createOperation('Change attribute')
            .takeArts(...appState.selected.value)
            .updateWithMutatingCallback((art) => {
                if (typeof value === 'object') {
                    // TODO: This object option is here just for TextArtModule - make it universal or fix TextArtModule
                    for (const [subKey, subValue] of Object.entries(value)) {
                        (art as any)[subKey] = subValue;
                    }
                } else {
                    (art as any)[key] = value;
                }
            })
            .persist();
    };

    if (selectedAreLocked) {
        return (
            <Icon
                icon="lock"
                onClick={() => changeAttributeValueOfSelectedArts('locked', !selectedAreLocked)}
                active={selectedAreLocked}
            />
        );
    } else {
        return (
            <>
                {/* TODO: Remove (bin) icon should be module */}
                <Icon
                    icon="bin"
                    onClick={() => {
                        materialArtVersioningSystem
                            .createOperation('Delete from board')
                            .takeArts(
                                ...materialArtVersioningSystem.artsPlaced.filter((art) =>
                                    appState.selected.value.includes(art),
                                ),
                            )
                            .delete();
                        appState.cancelSelection();
                    }}
                />
                {/* TODO: Lock icon should be module */}
                <Icon
                    icon="lock"
                    onClick={() => changeAttributeValueOfSelectedArts('locked', !selectedAreLocked)}
                    active={selectedAreLocked}
                />
                {/* TODO: Copy icon should be module */}
                {/* TODO: Implement
                <Icon
                    icon="copy"
                    onClick={() => {
                        alert(1);
                    }}
                />
                */}
                {/*
                TODO: @roseckyj remove or comment why is this needed here commented
                <>
                    <Separator />
                    <Icon icon="order-top" onClick={() => {}} />
                    <Icon icon="order-front" onClick={() => {}} />
                    <Icon icon="order-bottom" onClick={() => {}} />
                    <Icon icon="order-back" onClick={() => {}} />
                </>
            */}

                {commonAttributesOfSelectedObjects.map((attributeName) => {
                    const inputRender = attributesSystem.inputRender(attributeName, {
                        overrideValue: appState.selected.value[0]![attributeName],
                        additionaOnChange: (value) => {
                            changeAttributeValueOfSelectedArts(attributeName, value);
                        },
                    });
                    return (
                        <React.Fragment /* <- TODO: Import and use just a Fragment */ key={attributeName}>
                            {inputRender && (
                                <>
                                    <Separator />
                                    {inputRender}
                                </>
                            )}
                        </React.Fragment /* <- TODO: Import and use just a Fragment */>
                    );
                })}
            </>
        );
    }
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 * TODO: [🏊‍♂️] Better naming and distinction of Collboard-specific components vs utils components
 */
