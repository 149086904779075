import * as Sentry from '@sentry/browser';
import { IStorage } from 'everstorage';
import { randomUuid } from '../../40-utils/randomUuid';
import { uuid } from '../../40-utils/typeAliases';
import { consolex } from '../../consolex';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { IBoardApiIdentity, IConnectionIdentity, IInstanceIdentity } from './IIdentity';

/**
 * IdentitySystem identifies the User by a pseudonym.
 *
 * @collboard-system
 */
export class IdentitySystem extends AbstractSystem {
    readonly instanceId: uuid = randomUuid();
    private storage: IStorage<uuid>;
    private _browserId: uuid;
    private _sessionId: uuid;

    /*
        TODO: This class is a boilerplate of the system that we have not started working on yet.
        TODO: This is tightly connected with a user system
        @see https://github.com/collboard/collboard/issues/107
    */

    protected async init() {
        this.storage = (await this.systems.storageSystem).getStorage(`IdentitySystem`);
        const sessionStorage = (await this.systems.storageSystem).getStorage<uuid>(`IdentitySystem`, false);

        let browserId =
            (await this.storage.getItem(`clientId`)) || /* Legacy: */ (await this.storage.getItem(`clientUuid`));

        if (!browserId) {
            browserId = randomUuid();
            await this.storage.setItem(`clientId`, browserId);
        }

        this._browserId = browserId;

        let sessionId = await sessionStorage.getItem(`sessionId`);

        if (!sessionId) {
            sessionId = randomUuid();
            await sessionStorage.setItem(`sessionId`, sessionId);
        }

        this._sessionId = sessionId;

        this.track(this.createInstanceIdentity());
    }

    get browserId(): uuid {
        if (!this._browserId) {
            throw new Error(`Identity browserId is not yet generated.`);
        }
        return this._browserId;
    }

    get sessionId(): uuid {
        if (!this._sessionId) {
            throw new Error(`Identity sessionId is not yet generated.`);
        }
        return this._sessionId;
    }

    public createInstanceIdentity(): IInstanceIdentity {
        // TODO: Just getter instanceIdentity AND remove public of browserId and sessionId
        return {
            userId: null /* TODO: Create user system 🤪 */,
            browserId: this.browserId,
            sessionId: this.sessionId,
            instanceId: this.instanceId,
        };
    }

    public createBoardApiIdentity(): IBoardApiIdentity {
        return {
            ...this.createInstanceIdentity(),
            boardApiId: randomUuid(),
        };
    }

    public createConnectionIdentity(boardApiIdentity: IBoardApiIdentity): IConnectionIdentity {
        const identity = {
            ...boardApiIdentity,
            connectionId: randomUuid(),
        };
        this.track(identity);
        return identity;
    }

    private track(identity: Partial<IConnectionIdentity>) {
        // TODO: Probbably to as subscriber and subscribe to identity system - single responsibility

        try {
            Sentry.setUser({
                ...identity,
            });

            (window as any).gtag('event', 'identify', { ...identity });

            (window as any).smartlook('identify', identity.userId || identity.browserId, { ...identity });

            // TODO: Probably Facebook Pixel
        } catch (error) {
            consolex.error(`Error while tracking:`, error);
        }
    }
}

/**
 * TODO: Migrate clientId to probbably browserId
 * TODO: In LIB everstorage make some nicer syntax for legacy + migrations
 */
