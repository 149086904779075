import { normalizeTo_SCREAMING_CASE } from '@promptbook/utils';
import moment from 'moment';
// TODO: Bit inefficient to call all locales
import 'moment/locale/cs';
import 'moment/locale/sk';
import React from 'react';
import { Observable, Observer } from 'rxjs';
import { share } from 'rxjs/operators';
import spaceTrim from 'spacetrim';
import { applyParamsOnTemplate, IReplacer } from '../../40-utils/applyParamsOnTemplate';
import { jsxToHtml } from '../../40-utils/jsx-html/jsxToHtml';
import { jsxToText } from '../../40-utils/jsx-html/jsxToText';
import { prettifyHtml } from '../../40-utils/jsx-html/prettifyHtml';
import { string_translate_language, string_translate_name, string_translate_name_not_normalized } from '../../40-utils/typeAliases';
import { ISystemsExtended } from '../00-SystemsContainer/ISystems';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { TranslateContext } from './components/TranslateContext';
import { IBaseMessage, IMessage } from './interfaces/IMessage';
import { ITranslateMessage } from './interfaces/ITranslateMessage';
import { ITranslateMessagePicker } from './interfaces/ITranslateMessagePicker';

export interface ITranslationMessages {
    [key: string]: string;
}

// TODO: Unite naming Translate vs. translation vs translator

/**
 * TranslationsSystem manages messages across core, systems and modules.
 *
 * @collboard-system
 *
 */
export class TranslationsSystem extends AbstractSystem implements ITranslateMessagePicker {
    private translateMessagesRecord: Record<string_translate_name, ITranslateMessage> = {};

    public readonly missingTranslateMessages: Observable<ITranslateMessage>;
    private missingTranslateMessagesObserver?: Observer<ITranslateMessage>;
    private _language: string_translate_language;

    //TODO: Ready to AbstractSystem
    public ready: Promise<void>;
    private readyResolve: () => void;

    public constructor(systems: ISystemsExtended) {
        super(systems);

        this.ready = new Promise<void>((resolve) => (this.readyResolve = resolve));

        this.missingTranslateMessages = Observable.create(
            (/* TODO: [🎎] Probbably just use Subject -> */ observer: Observer<ITranslateMessage>) => {
                this.missingTranslateMessagesObserver = observer;
            },
        ).pipe(share()); // TODO: Probably publish or none
    }

    protected async init() {
        await this.ready;
    }

    public get translateMessages(): Array<ITranslateMessage> {
        return Object.values(this.translateMessagesRecord);
    }

    public get language(): string_translate_language {
        if (!this._language) {
            throw new Error(`Before using Translator you must set a language.`);
        }
        return this._language;
    }

    public set language(language: string_translate_language) {
        if (this._language) {
            throw new Error(`You can not set language multiple times in Translator.`);
        }
        this._language = language;
        this.readyResolve();
    }

    public pushMessages(...translateMessages: Array<ITranslateMessage>) {
        // TODO: Check message consistency
        // TODO: Check ONLY correct language
        for (const translateMessage of translateMessages) {
            this.translateMessagesRecord[translateMessage.name] = translateMessage;
        }
    }

    private missingTranslation(
        name: string_translate_name,
        nameNN: string_translate_name_not_normalized,
        note?: string,
    ): ITranslateMessage {
        const translateMessage: ITranslateMessage = {
            name,
            nameNN,
            language: this.language,
            message: note || '',
            isAutomaticTranslation: true,
            note,
        };
        this.missingTranslateMessagesObserver?.next(translateMessage);
        return translateMessage;
    }

    /**
     * Translate message
     *
     * Note: Prefer to use component <Translate... because component can be updated during a livetime of the page
     */
    public translate(
        nameNN: string_translate_name_not_normalized,
        note?: string,
        parameters?: { [key: string]: string },
    ): string {
        nameNN = nameNN.replace(/^@collboard\//, '');
        const name = normalizeTo_SCREAMING_CASE(nameNN);
        let translateMessage = this.translateMessagesRecord[name];

        if (note) {
            note = spaceTrim(note);
        }

        if (!translateMessage) {
            translateMessage = this.missingTranslation(name, nameNN, note);
        }

        let message = translateMessage.message;

        // Parameters:
        if (parameters) {
            for (const key of Object.keys(parameters)) {
                message = message.replace(new RegExp('\\{' + key + '\\}', 'gi'), parameters[key]);
            }
        }

        return message;
    }

    // TODO: Why this is called replacer not translate
    public readonly replacer: IReplacer = this.translate.bind(this);
    public readonly Translate = this.TranslateComponent.bind(this);

    /**
     * Creates context for providing translator
     */
    public readonly WithTranslateContext = this._WithTranslateContext.bind(this);

    private TranslateComponent(
        props: React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{
            name: string_translate_name_not_normalized; // TODO: It is a bit confising name vs nameNN
            /* TODO: [✨] Maybe add is prefix */ html?: boolean;
            isNonBreakSpaced?: true;
            parameters?: any;
            //format: 'html' | 'text';
        }>,
    ): JSX.Element {
        // TODO: Use parameters
        let { name } = props;
        const { children, html, isNonBreakSpaced } = props;
        name = name.replace(/^@collboard\//, '');
        const nameNormalized = normalizeTo_SCREAMING_CASE(name);
        let translateMessage = this.translateMessagesRecord[nameNormalized];

        //TODO: DRY with translate
        //TODO: Probably Observable

        if (!translateMessage) {
            translateMessage = this.missingTranslation(
                nameNormalized,
                name,
                spaceTrim(prettifyHtml(jsxToHtml(<>{children}</>))),
            );
        }

        let message = translateMessage.message;

        if (isNonBreakSpaced) {
            message = message.replaceAll(' ', '&nbsp;');
        }

        if (html) {
            // TODO: Span or div?

            return (
                <span
                    // TODO: Use here <InnerHtml component
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            );
        } else {
            return <>{translateMessage.message}</>;
        }
    }

    private _WithTranslateContext({
        children,
    }: React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{}>): JSX.Element {
        return <TranslateContext.Provider value={this}>{children}</TranslateContext.Provider>;
    }

    public useTemplate(html: string): string {
        return applyParamsOnTemplate({ template: html, replace: this.replacer });
    }

    public pickStringMessage(message: IMessage): string {
        return jsxToText(this.pickMessage(message));
    }

    public pickMessage(message: IMessage): JSX.Element {
        if (typeof message === 'string') {
            return <>{message}</>;
        } else if (React.isValidElement(/* <- TODO: Import and use just a isValidElement */ message)) {
            return message;
        } else {
            for (const language of this.preferedLanguages) {
                if ((message as Record<string_translate_language, IBaseMessage>)[language]) {
                    return this.pickMessage((message as Record<string_translate_language, IBaseMessage>)[language]);
                }
            }

            /* TODO: Consolex.error(
                  `Can not pick a message in language "${this.language}" from ${JSON.stringify(messageTranslation)}.`,
              ); */
            return <></>;
        }
    }

    private get preferedLanguages(): Array<string_translate_language> {
        // TODO: Use this also on backend
        // TODO: Unique
        return [this.language, this.secondaryLanguage || 'en', 'en'];
    }

    private get secondaryLanguage(): string_translate_language | null {
        if (this.language === 'cs') return 'sk';
        if (this.language === 'sk') return 'cs';
        return null;
    }

    public showDateAndTime(date: Date | string): string {
        return moment(date).locale(this.language).calendar();
    }
}

/**
 * TODO: [Optimization][InitialLoading] Optimize this for initial loading - Can be somehow optimized loading of moment.js
 * TODO: Translations in (external) modules
 */
