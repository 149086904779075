import React from 'react';
import { BehaviorSubject, Observable } from 'rxjs';
import { Transform } from 'xyzt';
import { useObservable } from '../40-utils/react-hooks/useObservable';
import { AbstractBackgroundArt } from '../71-arts/26-AbstractBackgroundArt';

interface IBoardBackgroundsProps {
    backgroundArts: Observable<Array<AbstractBackgroundArt>>;
    transform: BehaviorSubject<Transform>;
}

export function BoardBackgroundsComponent({ backgroundArts, transform }: IBoardBackgroundsProps) {
    const { value: transformValue } = useObservable(transform);
    const { value: backgroundArtsValue } = useObservable(backgroundArts);

    if (!backgroundArtsValue) {
        return <></>;
    }

    return (
        <>
            {backgroundArtsValue.map((backgroundArt, index) => (
                <div
                    key={index /* TODO: Probably better key */}
                    className="board-bg"
                    style={{
                        zIndex: backgroundArt.z,
                        ...backgroundArt.transformStyle(transformValue),
                        ...backgroundArt.additionalStyle(),
                    }}
                />
            ))}
        </>
    );
}

/**
 * TODO: [🏊‍♀️] Better naming for BoardBackgroundsComponent
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
