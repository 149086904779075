import { Color } from '../../40-utils/color/Color';
import { windowSize } from '../../40-utils/getWindowSize';
import { randomItem } from '../../40-utils/randomItem';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { BackgroundColorArt } from '../../71-arts/40-BackgroundColorArt';
import { TextArt } from '../../71-arts/50-TextArt/TextArt';

// @see https://coolors.co/b5c2b7-8c93a8-62466b-45364b-2d2327
const colors = '906090-b5c2b7-8c93a8-62466b-45364b-2d2327'.split('-');
const pickedColor = randomItem(...colors);

internalModules.declareModule(() => ({
    manifest: {
        name: `sample-deprecated-module-${pickedColor}`,
        deprecatedNames: colors
            .filter((color) => color !== pickedColor)
            .map((color) => `sample-deprecated-module-${color}`),
        icon: '🔠',
        flags: { isDevelopment: true }, // TODO: How to deal and show this dev modules
        title: { en: 'Sample of artificially deprecated modules', cs: 'Testování uměle zastaralých modulů' },
        description:
            'This module pick one of random names each time application starts, all possible names are marked as deprecated.',
    },

    async setup(systems) {
        const { virtualArtVersioningSystem } = await systems.request('virtualArtVersioningSystem');

        return virtualArtVersioningSystem
            .createPrimaryOperation()
            .newArts(
                new BackgroundColorArt(Color.from(pickedColor)),
                new TextArt(
                    pickedColor,
                    Color.from(pickedColor).negative.toString(),
                    20,
                    true,
                    false,
                    false,
                    'none',
                ).setShift(windowSize.value.multiply({ x: 0, y: -0.5 })),
            )
            .persist();
    },
}));
