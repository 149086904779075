import { consolex } from '../consolex';
import { errorMessageWithAdditional } from './errors/errorMessageWithAdditional';

/**
 * Checks if the given array has exactly one item.
 *    If yes, returns the item.
 *    If less, throws an error.
 *    If more, logs a warning and returns the first item.
 *
 * @param array - the array to check
 * @returns the only item
 */
export function expectExactlyOne<TItem>(what = 'item', array: Array<TItem>, additional: Record<string, any>): TItem {
    additional = { array, ...additional };

    if (array.length === 0) {
        // Note: this error will not occur because module @collboard/internal/html-export will always export a file
        throw new TypeError(errorMessageWithAdditional(`Expected exactly one ${what}, got 0`, additional));
    } else if (array.length === 1) {
        return array[0]!;
    } else {
        consolex.trace(
            errorMessageWithAdditional(`Expected exactly one ${what}, got ${array.length} ${what}s`, additional),
        );
        return array[0]!;
    }
}
