import React from 'react';
import { Authors } from '../../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../../50-systems/ModuleStore/makers/makeModalModule';
import { Translate } from '../../../50-systems/TranslationsSystem/components/Translate';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            flags: { isHidden: true },
            name: 'Error404ForModule',
            title: { en: 'Collboard error 404 screen for module', cs: 'Collboard obrazovka s chybou 404 pro modul' },
            author: Authors.collboard,
            requirePermissions: [],
        },
        createModal(systemsContainer) {
            return {
                title: <Translate name="Error404ForModule / title">:(</Translate>,
                // TODO: Remove> isUserInterfaceRendered: true,

                content: (
                    // TODO: Make some better copywriting for this module
                    // TODO: When clicked to the out of the modal start drawing - do it via handwritten loading index
                    <>
                        <Translate name="Error404ForModule / board does not exists">
                            Omlouváme se, ale někde se stala chyba, pokoušíte se zobrazit něco, co neexistuje.
                        </Translate>

                        {/* TODO: Probably make some button as in Error404ForBoard */}
                    </>
                ),
            };
        },
    }),
);
