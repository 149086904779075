import styled from 'styled-components';
import { Color } from '../../../40-utils/color/Color';
import { ISkin } from '../../../50-systems/StyleSystem/ISkin';

/**
 * @private Only used in this folder
 */
export const ModalGroupStyledDiv = styled.div<{ skin: ISkin }>`
    & + & {
        /* Not first of type */
        padding-top: 20px;
        margin-top: 20px;
        border-top: dotted 4px ${({ skin }) => Color.from(skin.colors.light).opaque.toString()} /* <- TODO: [🚥] */;
    }
`;

/**
 * Note: I am here using styled components not global CSS
 * TODO: @roseckyj Do it with styled components across the repositiory
 */
