import React, { useEffect, useState } from 'react';
import { AsyncButtonComponent } from '../../../30-components/utils/AsyncButtonComponent';
import { alertDialogue } from '../../../40-utils/dialogues/alertDialogue';

interface IInstallButtonProps {
    children: React.ReactNode /* <- TODO: Import and use just a ReactNode */;
}

/**
 * Install as PWA
 *
 * @not-collboard-modules-sdk
 */
export function InstallButton({ children }: IInstallButtonProps) {
    //const button = useRef();

    const [prompt, setPrompt] = useState<null | (() => Promise<void>)>(null);

    useEffect(() => {
        window.addEventListener(
            'beforeinstallprompt',
            (beforeInstallPromptEvent: any /* BeforeInstallPromptEvent  */) => {
                beforeInstallPromptEvent.preventDefault();

                setPrompt(() => {
                    return beforeInstallPromptEvent.prompt();
                });
            },
        );
    });

    return (
        <AsyncButtonComponent
            alt="Installing as PWA"
            className="button button-primary modal-button"
            isDisabled={prompt === null}
            onClick={async () => {
                await prompt!();
                alertDialogue(`Installed!`);
            }}
        >
            {children}
        </AsyncButtonComponent>
    );
}
