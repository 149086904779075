import { Promisable } from 'type-fest';
import { ISystems } from '../../00-SystemsContainer/ISystems';
import { attribute_value, IAttribute } from '../../AttributesSystem/IAttribute';
import { IModuleDefinition } from '../interfaces/IModule';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';

export interface IAttributeProtoModule<TAttributeValue> {
    manifest: IModuleManifest;
    attribute: IAttribute;

    /* TODO: [✨] Add is prefix */ standard: boolean;
    inputRender(
        value: TAttributeValue,
        onChange: (value: TAttributeValue) => void,
        systems: ISystems,
    ): Promisable<JSX.Element>;
}

/**
 *
 * @collboard-modules-sdk
 */
export function makeAttributeModule<TAttributeValue /* TODO: Extends attribute_value */>(
    protoModule: IAttributeProtoModule<TAttributeValue>,
): IModuleDefinition {
    const { manifest, attribute, standard, inputRender } = protoModule;

    const module = {
        manifest,
        // @deprecated DO not need to pass attribute,
        async setup(systems: ISystems) {
            const { attributesSystem } = await systems.request('attributesSystem');
            return attributesSystem.registerAttributeRule({
                moduleName: manifest.name,
                attribute,
                standard,
                inputRender(
                    value: attribute_value /* TODO: Should be T */,
                    onChange: (value: attribute_value /* TODO: Should be T */) => void,
                ) {
                    return inputRender(
                        value as any as TAttributeValue,
                        onChange as any as (value: TAttributeValue) => void,
                        systems,
                    );
                },
            });
        },
    };

    if (protoModule.standard) {
        // TODO: Som way how to categorize bonus attribute modules OR get rid of standard/bonus and bring up priority

        module.manifest.supports = {
            attribute: attribute.name,
        };
    }

    return module;
}
