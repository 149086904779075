import spaceTrim from 'spacetrim';
import { ITransformData, IVectorData, Transform, Vector } from 'xyzt';
import { string_attribute } from '../40-utils/typeAliases';
import { IBoundingBoxData } from '../50-systems/ExportSystem/interfaces/IBoundingBoxData';
import { AbstractArt } from './20-AbstractArt';

/*
 * TODO: Move somewhere else
 */

/**
 * Constant, which should be used to determine, whether a point is near the art
 *
 * @collboard-modules-sdk
 * @deprecated This should be exported from some system or util to be user-settable
 */
export const NEAR_DISTANCE = 20;

// ------------

/*
 * Note: [🖇] This is not in the internal module because some of the Arts are so
 *       tightly connected with the core that they need to be there, not in
 *       optionally deactivateable module.
 *
 */

/**
 * AbstractPlacedArt is an abstract class which all arts with position and size
 * extend.
 *
 * @collboard-modules-sdk
 */
export abstract class AbstractPlacedArt extends AbstractArt implements IBoundingBoxData {
    /**
     * Get the transform which is created from a deprecated shift
     */
    public get transform(): Transform {
        return Transform.translate(this.shift);
    }

    /**
     * Set the transform which is converted and saved as a deprecated shift
     */
    public set transform(transformData: ITransformData) {
        const transform = Transform.fromObject(transformData);

        if (transform.translate.z !== 0) {
            throw new Error(
                spaceTrim(`
                    Can not set transform.translate on AbstractPlacedArt with non-zero z axis
                    Not implemented yet

                    Transform:
                    (${transform.toString()})
                `),
            );
        }

        // TODO: LIB xyzt should have on object Transform methods isTranslateNeutral, isScaleNeutral, isRotateNeutral

        if (transform.scale.x !== 1 && transform.scale.y !== 1 && transform.scale.y !== 1) {
            throw new Error(
                spaceTrim(`
                    Can not set non-neutral transform.scale on AbstractPlacedArt
                    Not implemented yet

                    Transform:
                    (${transform.toString()})
                `),
            );
        }

        if (transform.rotate.x !== 0 && transform.rotate.y !== 0 && transform.rotate.z !== 0) {
            throw new Error(
                spaceTrim(`
                    Can not set non-neutral transform.scale on AbstractPlacedArt
                    Not implemented yet

                    Transform:
                    (${transform.toString()})
                `),
            );
        }

        this.setShift(transform.translate);
    }

    /**
     * Translation vector of art
     *
     * @deprecated use transform property instead
     */
    public shift: IVectorData = Vector.zero(); // TODO: Probably rename to position

    /**
     * Marks, whether art is locked
     *
     * TODO: [✨] Maybe add is prefix
     */
    public locked: boolean = false;

    /**
     * Getter with list of attributes particular art supports
     *
     * Value is used to display a floating menu above selection
     *
     * @deprecated Make it using IMaterial
     *
     * @example
     * public get acceptedAttributes() {
     *     return ["color", "size"];
     * }
     */
    public abstract get acceptedAttributes(): Array<string_attribute>;

    /**
     * Opacity of an art
     *
     * Value is automatically used by ArtShell when rendering
     *
     * *Note: only works on 2D arts*
     */
    public opacity?: number; /* TODO: Implement also on 3D arts */

    /**
     * Rotation of an art
     *
     * Value is automatically used by ArtShell when rendering
     *
     * *Note: type needs to be overridden when extending*
     *
     * @deprecated use transform property instead
     */
    public rotation: any = 0; // TODO: Remove number rotation and use 3D rotation here

    /**
     * Position of top left corner of art (absolute including `shift`)
     *
     * Value is used mostly when making selection
     *
     * @deprecated Make it using BoundingBox
     * @abstract
     */
    public abstract get topLeft(): IVectorData; // TODO: Do maybe with TC/vectors boundingbox and this is 3D information

    /**
     * Position of bottom right corner of art (absolute including `shift`)
     *
     * Value is used mostly when making selection
     *
     * @deprecated Make it using BoundingBox
     * @abstract
     */
    public abstract get bottomRight(): IVectorData;

    /**
     * Position of top right corner of art (absolute including `shift`)
     *
     * Value is used mostly when making selection
     *
     * @deprecated Make it using BoundingBox
     */
    public get topRight(): IVectorData {
        return new Vector(this.bottomRight.x, this.topLeft.y, this.topLeft.z);
    }

    /**
     * Position of bottom left corner of art (absolute including `shift`)
     *
     * Value is used mostly when making selection
     *
     * @deprecated Make it using BoundingBox
     */
    public get bottomLeft(): IVectorData {
        return new Vector(this.topLeft.x, this.bottomRight.y, this.bottomRight.z);
    }

    /**
     * Function determining, whether a point is near the art
     *
     * Default implementation uses square bounding box
     *
     * @deprecated Make it using BoundingBox - just only provide BoundingBoxand the calling will look like art.boundingBox.isNear(...)
     */
    public isNear(pointToTest: IVectorData): boolean {
        // TODO: As vector method
        return (
            (pointToTest.x || 0) > (this.topLeft.x || 0) &&
            (pointToTest.x || 0) < (this.bottomRight.x || 0) &&
            (pointToTest.y || 0) > (this.topLeft.y || 0) &&
            (pointToTest.y || 0) < (this.bottomRight.y || 0)
            /*
            Note: I am not checking Z axis because user want to select all Z ray line defined by X and Y.
            &&
            (point2.z || 0) > (this.topLeft.z || 0) &&
            (point2.z || 0) < (this.bottomRight.z || 0)
            */
        );
    }

    /**
     * Move art to certain vector (= absolute)
     *
     * @deprecated use transform property instead
     */
    public setShift(shift: IVectorData): this {
        // TODO: Rename to position
        this.shift = Vector.fromObject(shift /* TODO: Probably Vector.fromObject not nessesary */);
        return this;
    }

    /**
     * Move art to by vector (= relative)
     *
     * @deprecated use transform property instead
     */
    public move(shift: IVectorData): this {
        this.shift = Vector.add(this.shift, shift);
        return this;
    }
}

/**
 * TODO: [🎟️] topLeft, bottomRight, topRight and bottomLeft should be encapsulated in boundingBox property
 * TODO: [🍎]  acceptedAttributes should reflect IMaterial and IShape
 * TODO: [🎚️] Maybe get rit of AbstractArts and make makers for arts which returns IArts
 */
