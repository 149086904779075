import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';

/**
 * SnapSystem /aka. Playground 2.0/ allows us to define a snap point on each art.
 * Note: This is continuing of the Playground in H-edu.
 *
 * @private
 * @collboard-system
 */
export class SnapSystem extends AbstractSystem {
    /*
        TODO: This class is a boilerplate of the system that we have not started working on yet.
        This is kind of Playground 2.0 what was on H-edu
        @see https://github.com/collboard/collboard/issues/140
    */

    protected async init() {}
}
