/**
 * Merges multiple objects into one.
 * It uses some fuzzy logic to merge objects.
 *
 * @collboard-modules-sdk
 */
export function aggregate<TData /* <- TODO: Add some constraint like extends object or Record<string,unknown> */>(
    ...data: Array<TData>
): TData {
    return data.reduce((aggregated, current) => {
        // const _aggregatedOriginal = { ...aggregated };

        for (const key in current /* <- TODO: Change to Object.keys */) {
            if (/^last/i.test(key)) {
                if (current[key] !== undefined) {
                    aggregated[key] = current[key];
                } else {
                    // Keeping the original value
                }
            } else if (aggregated[key] === undefined) {
                aggregated[key] = current[key];
            } else if (current[key] === undefined) {
                // Keeping the original value BUT do not in case of null
            } else {
                if (typeof aggregated[key] !== typeof current[key]) {
                    throw new Error(
                        `Cannot aggregate ${key} because you try to mix ${typeof aggregated[key]} with ${typeof current[
                            key
                        ]} ("${aggregated[key]}" with "${current[key]}" )`,
                    );
                } else if (typeof aggregated[key] === 'number') {
                    (aggregated as any)[key] += current[key];
                } else if (typeof aggregated[key] === 'boolean') {
                    (aggregated as any)[key] = (aggregated as any)[key] || current[key];
                } else if (typeof aggregated[key] === 'string') {
                    aggregated[key] = current[key];
                } else if (aggregated[key] === null) {
                    aggregated[key] = current[key];
                } else {
                    throw new Error(
                        `Cannot aggregate ${key} because aggregation of ${typeof aggregated[
                            key
                        ]} is not implemented.\n${key} = ${JSON.stringify(aggregated[key])}`,
                    );
                }
            }
        }
        return aggregated;
    }, {} as TData);
}

// TODO: This function could be broken up into aggeregator of single values and aggregator of records
