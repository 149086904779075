import { ModuleStatus } from '../ModuleStatus';

export function pickMostImportantModuleStatus(...moduleStatuses: Array<ModuleStatus>): ModuleStatus {
    if (moduleStatuses.includes(ModuleStatus.Installed)) {
        return ModuleStatus.Installed;
    } else if (moduleStatuses.includes(ModuleStatus.Installing)) {
        return ModuleStatus.Installing;
    } else if (moduleStatuses.includes(ModuleStatus.Uninstalling)) {
        return ModuleStatus.Uninstalling;
    } else {
        return ModuleStatus.NotInstalled;
    }
}
