/**
 * Traverse through the object and all its children and replace them via the callback
 *
 * @collboard-modules-sdk
 */
export function deepMapObject<TObject>(value: TObject, callback: (subobject: any) => any): TObject {
    if (Array.isArray(value)) {
        return value.map((itemValue) => deepMapObject(itemValue, callback)) as any as TObject;
    }
    if (typeof value === 'object' && value !== null) {
        return Object.fromEntries(
            Object.entries(value).map(([itemKey, itemValue]) => [itemKey, deepMapObject(itemValue, callback)]),
        ) as any as TObject;
    } else {
        return callback(value) as any as TObject;
    }
}

/**
 * TODO: Protect from passing cyclic objects - isObjectCyclic util
 */
