import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { isInCategory } from './isInCategory';

export function isExperimental(manifest: IModuleManifest): boolean {
    // TODO: !! use instead flagsToObject
    return isInCategory(manifest, `Experimental`);
}

/**
 *  TODO: Unit test
 */
