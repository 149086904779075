import { MODULE_NAME_PATTERN } from '../config';
import { string_module_name } from './typeAliases';

/**
 * Parse Collboard module name
 * @see more in MODULE_NAME_REGEX
 *
 * @param moduleName
 * @returns normalized scope and name split into chunks
 *
 * @example @collboard/internal/foo/bar => { scope: 'collboard', name: ['foo','bar'] }
 *
 * @collboard-modules-sdk
 */
export function parseModuleName(moduleName: string_module_name): {
    scope: string;
    name: Array<string>;
} {
    const match = MODULE_NAME_PATTERN.exec(moduleName);

    if (!match?.groups) {
        return {
            scope: 'collboard',
            name: [moduleName],
        };

        /*
        [🏤]
        Note: Not throwing an error till new module names done @see https://github.com/collboard/collboard/pull/591

        throw new Error(
            spaceTrim(`
                Invalid module name "${moduleName}"
                Module name must be in format "@scope/name"
                Module name must match /${MODULE_NAME_PATTERN.source}/
            `),
        );
        */
    }

    const { scope, name } = match.groups;

    /*
    Note: This situation never happen - MODULE_NAME_REGEX do not allow empty scope.
    if (!scope) {
        throw new Error(
            spaceTrim(`
                Scope is required for module name "${moduleName}"
                Module name must be in format "@scope/name" in your case for example "@my-username/${moduleName}"
            `),
        );
    }
    */

    return { scope /*: scope || null*/, name: name.split('/') };
}

/**
 * TODO: [🌓] Do not allow double dash -- in module name or in the module scope because it is string used to escape
 * TODO: [🚤] Maybe create reverse function - stringifyModulName
 */
