import React from 'react';
import { Icon } from '../../30-components/menu/Icon';
import { Separator } from '../../30-components/menu/Separator';
import { classNames } from '../../40-utils/classNames';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeAttributeModule } from '../../50-systems/ModuleStore/makers/makeAttributeModule';

internalModules.declareModule(() =>
    makeAttributeModule({
        manifest: {
            name: 'TimerTimeAttribute',
            contributors: [Authors.rosecky],
        },
        standard: true,
        attribute: {
            type: 'number',
            name: 'timerTime',
            defaultValue: 60000,
        },
        inputRender(value: number, onChange: (value: number) => void) {
            // TODO: [🎱] This inputRender is rendered much more often than needed when observing arts, window resize, etc.
            // console.log('🅰️', 'TimerTimeAttribute', 'inputRender');

            const h = Math.floor(value / (1000 * 60 * 60));
            const m = Math.floor(value / (1000 * 60) - h * 60);
            const s = Math.floor(value / 1000 - h * (60 * 60) - m * 60);

            const setValue = (hChange: number, mChange: number, sChange: number) => {
                onChange((h + hChange) * 1000 * 60 * 60 + (m + mChange) * 1000 * 60 + (s + sChange) * 1000);
            };

            return (
                <>
                    <Icon icon="minus" inactive={value === -1 || h === 0} onClick={() => setValue(-1, 0, 0)} />
                    <div className={classNames('label', value === -1 && 'inactive')}>hod</div>
                    <Icon icon="plus" inactive={value === -1} onClick={() => setValue(1, 0, 0)} />

                    <Separator className={classNames(value === -1 && 'inactive')} />

                    <Icon icon="minus" inactive={value === -1 || m === 0} onClick={() => setValue(0, -1, 0)} />
                    <div className={classNames('label', value === -1 && 'inactive')}>min</div>
                    <Icon icon="plus" inactive={value === -1} onClick={() => setValue(0, 1, 0)} />

                    <Separator className={classNames(value === -1 && 'inactive')} />

                    <Icon icon="minus" inactive={value === -1 || s === 0} onClick={() => setValue(0, 0, -1)} />
                    <div className={classNames('label', value === -1 && 'inactive')}>sec</div>
                    <Icon icon="plus" inactive={value === -1} onClick={() => setValue(0, 0, 1)} />
                </>
            );
        },
    }),
);

/**
 * Note: In future this file will we in independent repository as external module.
 */
