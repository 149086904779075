import { forImmediate } from 'waitasecond';
import { consolex } from '../../consolex';

/**
 * HighOrderError is high order error to wrap lower order error (like parsing).
 * @see https://www.pavolhejny.com/rozsirene-informace-o-chybach/
 *
 * @deprecated use errorMessageWithAdditional instead
 *
 * @collboard-modules-sdk
 */
export class HighOrderError extends Error {
    readonly name: string = 'HighOrderError';

    public constructor(readonly lowOrderError: Error, readonly highOrderExplanation: string) {
        super(lowOrderError.message);

        (async () => {
            // TODO: Probbably use here errorMessageWithAdditional
            // TODO: Do it better than just console logging lowOrderError OR put some better info around like "More info in console above."
            await forImmediate(/* to log additional into after the actual error */);
            consolex
                .groupCollapsed(`${this.name} ${this.highOrderExplanation}`)
                .error('Low order error: ', lowOrderError)
                .error('High order explanation: ', this.highOrderExplanation)
                .end();
        })();

        Object.setPrototypeOf(this, HighOrderError.prototype);
    }

    /*get stack() {
        return 'aaa';
    }*/
}

/*
export function HighOrderErrorMyException(internal_exception: any, text: any) {
    (this as any).text = text;
    (this as any).internal_exception = internal_exception;
}*/
