import styled from 'styled-components';

/**
 * @private Only used in this folder
 */
export const ModalScrollStyledDiv = styled.div`
    width: calc(100% + 40px);
    padding: 0 20px 0 20px;
    margin: 0 -20px 0 -20px;

    height: calc(100vh - 300px);

    overflow-x: hidden;
    overflow-y: scroll;

    .bottom-space {
        /*/
        outline: 1px dotted red; /**/

        display: block;
        width: 100%;
        height: 100px /* <- Note: To cover a footer */;
    }

    @media /*[🍕]*/ only screen and (max-width: 850px) {
        height: 100vh;
        padding-bottom: 35vh;
    }
`;
