import { Destroyable, IDestroyable, softDestroy } from 'destroyable';
import { BehaviorSubject } from 'rxjs';
import { ModuleNotFoundError } from '../../40-utils/errors/ModuleNotFoundError';
import { toArray } from '../../40-utils/toArray';
import { string_module_name } from '../../40-utils/typeAliases';
import { ISystemsExtended } from '../00-SystemsContainer/ISystems';
import { signatureManager } from '../00-SystemsContainer/SignatureManager';
import { IDependency } from './interfaces/IDependencies';
import { IInstaller } from './interfaces/IInstaller';
import { IModuleManifest } from './interfaces/IModuleManifest/IModuleManifest';
import { IModulesStorageWeak } from './interfaces/IModulesStorage';
import { ModuleInstallation } from './ModuleInstallation';
import { ModuleStatus } from './ModuleStatus';
/**
 * Collboard app has one installer which manages all the module installations, takes care of number of running instances, manages reasons of module activation, syncing, etc.
 * Installer is used internally in syncers
 */
export class ModuleInstaller extends Destroyable implements IInstaller, IDestroyable {
    public installations: Array<ModuleInstallation> = [];

    public constructor(
        private readonly modulesStorage: IModulesStorageWeak,
        private readonly systems: ISystemsExtended,
    ) {
        super();
    }

    /**
     *
     * @param dependency to install
     * @param syncerName just for logging
     */
    public async install(dependency: IDependency, syncerName?: string) {
        this.statusOf(dependency).next(ModuleStatus.Installing);

        // TODO: Probably return destroyable
        const module = this.modulesStorage.getModule(dependency.name);

        if (!module) {
            throw new ModuleNotFoundError({
                moduleName: dependency.name,
                moduleStorage: this.modulesStorage as any,
                modulenstaller: this,
            });
        }

        if (!module.manifest) {
            throw new Error(`
                Cannot install anonymous module (module without manifest) through ModuleInstaller

                Anonymous modules are not installed by syncers but used to compose other modules (for example via module makers)
            `);
        }

        // TODO: Process (change behaviour according to) other dependency details

        this.installations.push(
            await ModuleInstallation.install(
                module,
                signatureManager.sign(this.systems, module.manifest || null),
                syncerName,
            ),
        );

        this.statusOf(dependency).next(ModuleStatus.Installed);
    }

    // TODO: Prevent multiple running processes under ine moduleName - maybe by statuses
    // TODO: Some central way how to do this types of cache
    private statuses: Record<string_module_name, BehaviorSubject<ModuleStatus>> = {};
    public statusOf(moduleManifest: IModuleManifest): BehaviorSubject<ModuleStatus> {
        if (!this.statuses[moduleManifest.name]) {
            for (const deprecatedName of toArray(moduleManifest.deprecatedNames)) {
                if (this.statuses[deprecatedName]) {
                    return this.statuses[deprecatedName];
                }
            }

            this.statuses[moduleManifest.name] = new BehaviorSubject(ModuleStatus.NotInstalled as ModuleStatus);
        }

        return this.statuses[
            moduleManifest.name
        ] /* TODO: For consumer look for writing, sth. like asObservable but wit preservation of .value */;
    }

    public async uninstall(dependency: IDependency) {
        for (const installation of this.installations) {
            if (
                installation.moduleDefinition.manifest!.name === dependency.name ||
                toArray(installation.moduleDefinition.manifest!.deprecatedNames).includes(dependency.name)
            ) {
                this.statusOf(dependency).next(ModuleStatus.Uninstalling);
                await softDestroy(installation);
                this.installations = this.installations.filter((installation2) => installation !== installation2);
                this.statusOf(dependency).next(ModuleStatus.NotInstalled);
            }
        }
    }

    public async uninstallAll() {
        // TODO: Probably uninstall in parallel
        for (const installation of this.installations)
            if (
                installation.moduleDefinition.manifest?.name &&
                // TODO: Refactor installer and when uninstall automatically remove item from installations
                this.statusOf(installation.moduleDefinition.manifest).value === ModuleStatus.Installed
            ) {
                await this.uninstall(installation.moduleDefinition.manifest);
            }
    }

    public async destroy() {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        for (const installation of this.installations) {
            await softDestroy(installation);
        }
    }
}

/**
 * TODO: Refactor installer and when uninstall automatically remove item from installations
 * TODO: Convert to fully destroyable pattetn
 */
