import React from 'react';
import { classNames } from '../../40-utils/classNames';
import { useSkin } from '../../40-utils/react-hooks/useSkin';
import { ModalStyle } from './ModalStyle';
import { PageOverlay } from './PageOverlay';

export type IModalProps =
    React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{
        /**
         * Modal title
         */
        title: string | React.ReactNode /* <- TODO: Import and use just a ReactNode */;

        /**
         * Callback on page overlay clicked
         *
         * *Note: it is highly suggested to use same action as `onClickClose`*
         */
        onClickOverlay?: () => void;

        /**
         * Callback on modal close button clicked
         *
         * When not set, the modal does not have the close button
         */
        onClickClose?: () => void;

        /**
         * Additional class name of the modal component
         */
        className?: string;

        /**
         * Is the modal wide?
         */
        /* TODO: [✨] Add is prefix */ wide?: boolean;

        /**
         * Is the modal full page height?
         */
        /* TODO: [✨] Add is prefix */ fullSize?: boolean;
    }>;

/**
 * Modal component
 *
 * @collboard-modules-sdk
 */
export function Modal(props: IModalProps) {
    const { wide, fullSize, className, onClickClose, onClickOverlay, title, children } = props;
    const { skin } = useSkin();

    return (
        <PageOverlay onPointerDown={() => (onClickOverlay || onClickClose || (() => {}))()}>
            <ModalStyle {...{ skin }}>
                <div
                    className={classNames(
                        'custom-modal',
                        wide && 'custom-modal-wide',
                        fullSize && 'custom-modal-fullsize',
                        className,
                    )}
                >
                    <div className="custom-modal-content">
                        <div className="custom-modal-header">
                            <>{onClickClose && <div className="custom-modal-close" onClick={onClickClose} />}</>
                            <span>{title}</span>
                        </div>

                        {children}
                    </div>
                </div>
            </ModalStyle>
        </PageOverlay>
    );
}
