import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';

/**
 * GamificationSystem creates support for gamification arts like yes-no polls, polls, timers, etc.
 * TODO: Under this  system should be a stopwatch
 *
 * @private
 * @collboard-system
 */
export class GamificationSystem extends AbstractSystem {
    /*
        TODO: This class is a boilerplate of the system that we have not started working on yet.
        @see https://github.com/collboard/collboard/issues/56
    */

    protected async init() {}
}
