import React from 'react';
import { Promisable } from 'type-fest';
import { IModalProps, Modal } from '../../../30-components/modal/Modal';
import { factor, Factorable } from '../../../40-utils/IFactory';
import { ISystems } from '../../00-SystemsContainer/ISystems';
import { TranslationsSystem } from '../../TranslationsSystem/0-TranslationsSystem';
import { UserInterfaceElementPlace } from '../../UserInterfaceSystem/UserInterfaceElementPlace';
import { IModuleDefinition } from '../interfaces/IModule';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { makeUserInterfaceModule } from './makeUserInterfaceModule';
interface ICreateModalReturn extends Partial<Omit<IModalProps, 'children'>> {
    content: Factorable<JSX.Element>;

    /**
     * @deprecated There should be just content and maker should be able to detect automatically if the content is <Modal or not
     */
    wrapModalManually?: boolean;
}

// TODO: [☮️] Make nicer loading here

/**
 * Makes a module which shows a simple modal
 *
 * @collboard-modules-sdk
 */
export function makeModalModule(protoModule: {
    manifest?: IModuleManifest;
    // TODO: Probbably createModal can return only content
    createModal(systems: ISystems): Promisable<JSX.Element | ICreateModalReturn>;
}): IModuleDefinition {
    const { manifest, createModal } = protoModule;

    return makeUserInterfaceModule({
        manifest,
        // TODO: Some special place for modals (not RootComponent)
        place: UserInterfaceElementPlace.RootComponent,
        order: 10 /* TODO: Probably to some config value */,
        async render(systems) {
            const { translationsSystem, routingSystem } = await systems.request('translationsSystem', 'routingSystem');

            const modalOrContent = await createModal(systems);
            let modal: ICreateModalReturn;

            if (React.isValidElement(/* <- TODO: Import and use just a isValidElement */ modalOrContent)) {
                modal = {
                    content: modalOrContent,
                };
            } else {
                modal = modalOrContent as ICreateModalReturn;
            }

            modal.title = createModalTitle({ modal, manifest, translationsSystem });

            const closeHandler = async () => {
                // TODO: Probbaly going home should be behavour of all modal modules so maybe omit onClickClose and onClickOverlay from createModal return value
                routingSystem.urlVariables.setValue({ moduleName: undefined });
            };
            if (!modal.onClickClose) {
                modal.onClickClose = closeHandler;
            }
            if (!modal.onClickOverlay) {
                modal.onClickOverlay = closeHandler;
            }

            const content = factor(modal.content);

            if (modal.wrapModalManually) {
                return content;
            } else {
                return <Modal {...(modal as IModalProps)}>{content}</Modal>;
            }
        },
    });
}

export function createModalTitle({
    modal,
    manifest,
    translationsSystem,
}: {
    modal?: ICreateModalReturn;
    manifest?: IModuleManifest;
    translationsSystem: TranslationsSystem;
}): string | React.ReactNode /* <- TODO: Import and use just a ReactNode */ {
    if (modal && modal.title) {
        return modal.title;
    } else {
        if (manifest && manifest.title) {
            return translationsSystem.pickStringMessage(manifest.title);
        } else if (manifest && manifest.name) {
            return manifest.name;
        } else {
            throw new Error(
                `You must provide modal title or module manifest with title or name when creating modal module.`,
            );
        }
    }
}

/**
 * TODO: Remove all spinning loaders
 * TODO: Split into two files in folder makeModalModule and anotate createModalTitle
 * TODO: [Optimization][InitialLoading] Optimize this for initial loading
 * TODO: Changing title of modal
 */
