import { Vector } from 'xyzt';
import { AbstractPlacedArt } from '../../../71-arts/25-AbstractPlacedArt';
import { CollSpace } from '../../CollSpace/0-CollSpace';
import { joinBoundingBoxes } from './joinBoundingBoxes';

interface ITopleftArtsOptions {
    arts: Array<AbstractPlacedArt>;
    collSpace: CollSpace;
}

/**
 * Moves given arts to be in top left position
 *
 * @sideeffect This will mutate passed arts
 *
 * @collboard-modules-sdk
 */
export function topleftArts({ arts, collSpace }: ITopleftArtsOptions): void {
    if (arts.length === 0) {
        return;
    }

    const topLeft = collSpace.pickPoint(new Vector(10, 100)).point;

    const originalCommonBoundingBox = joinBoundingBoxes(...arts);

    const shift = Vector.subtract(topLeft, originalCommonBoundingBox.topLeft);

    for (const art of arts) {
        art.setShift(Vector.add(art.shift, shift));
    }
}

/**
 * TODO: [🎼] Relation between topleftArts and centerArts
 * TODO: This should be done by LIB xyzt boundingBox and AbstractPlacedArt should implement interface IBoundingBox
 * TODO: Use rotation and scale we should probbably bahave isotropically and isometrically (pass here transform instead of center)
 */
