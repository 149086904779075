import React from 'react';
import { Icon } from '../../30-components/menu/Icon';
import { Separator } from '../../30-components/menu/Separator';
import { joinWithAny } from '../../40-utils/joinWithJsx';
import { useObservable } from '../../40-utils/react-hooks/useObservable';
import { IconsToolbar } from './IconsToolbar';
import { IToolbarIcon } from './IToolbarIcon';

interface IIconsToolbarComponentProps {
    iconsToolbar: IconsToolbar;
}

/**
 * Renders the toolbar
 *
 * There are two similar components:
 * - **IconsToolbarComponent** which renders the toolbar
 * - **IconsToolbarMenuComponent** which renders the menu of currently active icon in the toolbar
 *
 * @not-collboard-modules-sdk because IconsToolbarComponent is a private component of ToolbarSystem
 */
export function IconsToolbarComponent({ iconsToolbar }: IIconsToolbarComponentProps) {
    const { value: icons } = useObservable(iconsToolbar.icons);

    const groups = icons
        .sort((icon1, icon2) => (icon1.order || 0) - (icon2.order || 0))
        .reduce((prev, curr) => {
            if (!prev[curr.section || 0]) {
                prev[curr.section || 0] = [];
            }
            prev[curr.section || 0].push(curr);
            return prev;
        }, {} as { [key: number]: Array<IToolbarIcon> });
    return (
        <>
            {joinWithAny(
                Object.keys(groups)
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((groupKey) =>
                        groups[groupKey as any].map((icon, index) => (
                            <Icon
                                key={`${groupKey}_${index}` /* TODO: Probably better key */}
                                {...icon}
                                onClick={() => {
                                    iconsToolbar.clickOnIcon(icon);
                                }}
                                active={iconsToolbar.isIconActive(icon)}
                            />
                        )),
                    ),
                (key) => (
                    <Separator {...{ key }} />
                ),
            )}
        </>
    );
}

/**
 * TODO: [🎲] It is a bit inconsistent that renderMenu wraps content with <Menu but renderToolbar not
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
