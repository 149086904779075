import { IDependenciesRecord } from '../../../ModuleStore/interfaces/IDependencies';

export const DEFAULT_ACTIVE_MODULE = { priority: 0 /* TODO: Make value true as shortcut for { priority: 0 } */ };
export const COMMON_BOARD_ACTIVE_MODULES: IDependenciesRecord = {
    // TODO: [🏈] Go through all of the modules
    // TODO: [🏈] Some things from here maybe move to nessesary modules

    // Basic (but still in some cases uninstallable [🏈] )
    '@collboard/internal/collboard-logo': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/module-store-icon': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/eu-cookies-warning': DEFAULT_ACTIVE_MODULE,
    '@collboard/background-patterns/dots': DEFAULT_ACTIVE_MODULE,

    // Tools
    '@collboard/internal/board-name': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/fullscreen': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/erase-tool': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/selection-tool': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/freehand-tool': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/home-tool': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/move-tool': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/shape-tool': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/text-tool': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/zoom-tool': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/add-icon': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/toolbars-persisting': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/share-icon': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/share-indicator': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/download-icon': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/download-shortcut': DEFAULT_ACTIVE_MODULE,
    '@collboard/print-icon': DEFAULT_ACTIVE_MODULE /* <- TODO: UI icon should be probbably internal module */,
    '@collboard/print-shortcut': DEFAULT_ACTIVE_MODULE /* <- TODO: Shortcut should be probbably internal module */,
    '@collboard/internal/back-button-toolbar-icon': DEFAULT_ACTIVE_MODULE,

    // Shortcuts
    // TODO: [🏈] Rest to the required modules
    '@collboard/internal/clipboard-copy-and-cut-shortcut': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/clipboard-paste-shortcut': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/delete-shortcut': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/select-all-shortcut': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/scroolwheel-shortcut': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/arrows-shortcut': DEFAULT_ACTIVE_MODULE,

    // Note: File imports are auto-installed
    // File support of well-known types
    // TODO: [🏈] Probbably also auto-installed
    '@collboard/internal/text-export': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/html-export': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/svg-export': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/png-export': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/jpeg-export': DEFAULT_ACTIVE_MODULE,
    '@collboard/internal/webp-export': DEFAULT_ACTIVE_MODULE,
    '@collboard/pdf-export': DEFAULT_ACTIVE_MODULE,

    // Testing
    // '@test/makers/simple-artool/tool': DEFAULT_ACTIVE_MODULE /* <- TODO: !!x Remove */,
};
