import { randomUuid } from '../40-utils/randomUuid';
import { uuid } from '../40-utils/typeAliases';
import { attribute_value } from '../50-systems/AttributesSystem/IAttribute';

/*
 * Note: [🖇] This is not in the internal module because some of the Arts are so
 *       tightly connected with the core that they need to be there, not in
 *       optionally deactivateable module.
 *
 */

/**
 * AbstractArt is an abstract class which all art classes extend.
 *
 * It only provides unique identifier for each art
 *
 * @collboard-modules-sdk
 */
export abstract class AbstractArt {
    /**
     * AbstractArt is considered indexable in order to make serialization
     * and deserialization work
     *
     * TODO: [🍎] Remove when there is only shape and appearance on the arts
     */
    [index: string]: attribute_value | any /* TODO: Better typing the value */;

    /**
     * Unique identifier of each art
     */
    public readonly artId: uuid;

    public constructor() {
        if (!this.artId) {
            // TODO: This should be maybe a hash not generated uuid?
            // TODO: Should be this serialized?
            this.artId = randomUuid();
        }
    }
}

/**
 * TODO: [🎚️] Maybe get rit of AbstractArts and make makers for arts which returns IArts
 */
