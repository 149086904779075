import { Column, Entity, Index, PrimaryGeneratedColumn } from 'typeorm';
import { string_uri_part, string_version, uuid } from '../40-utils/typeAliases';
import { BusinessName } from '../50-systems/BusinessSystem/configuration/BusinessName';
import { ICommitData } from './interfaces/ICommitData';

@Index('commitId', ['commitId'], { unique: true })
@Index('treeId', ['treeId'], {})
@Index('version', ['version'], {})
@Index('artClass', ['artClass'], {})
@Index('uriId', ['uriId'], {}) // TODO: This index should be unique but could be same in same treeId
@Index('boardId', ['boardId'], {})
@Index('current', ['current'], {})
@Index('operationName', ['operationName'], {})
@Index('operationId', ['operationId'], {})
@Index('author', ['author'], {})
@Index('businessName', ['businessName'], {})
@Entity('Commit')
// TODO: There should be unique key treeId_version
export class Commit implements ICommitData {
    [index: string]: string | number | null | any /* TODO: Better typing the value */;

    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: number;

    @Column('char', { name: 'boardId', length: 36 })
    boardId: uuid;

    @Column('char', { name: 'treeId', length: 36 })
    treeId: uuid;

    @Column('char', { name: 'commitId', unique: true, length: 36 })
    commitId: uuid;

    // TODO: Foreign key
    @Column('char', { name: 'previousId', nullable: true, length: 36 })
    previousId: uuid | null;

    @Column('tinyint', { name: 'current' }) // TODO: Add a comment "This is just computed redundant information that the commit is last in the tree."
    current: number;

    @Column('int', { name: 'version' })
    version: number;

    @Column('enum', {
        name: 'replacingStrategy',
        nullable: true,
        enum: ['KEEP', 'REPLACE'],
    })
    replacingStrategy: 'KEEP' | 'REPLACE' | null;

    // TODO: [🧠] What about this and collision with moduleName in art
    //             Maybe rename module to moduleName and art to artData and from artData extract moduleName
    @Column('varchar', { name: 'module', length: 200 })
    module: string;

    @Column('varchar', { name: 'moduleVersion', length: 11 })
    moduleVersion: string_version;

    @Column('varchar', { name: 'remoteVersion', length: 11 })
    remoteVersion: string_version;

    @Column('varchar', { name: 'operationName', nullable: true, length: 200 })
    operationName: string | null;

    @Column('char', { name: 'operationId', nullable: true, length: 36 })
    operationId: uuid | null;

    // TODO:Rename to clientId and add other Ids (or create matching table)
    @Column('char', { name: 'author', length: 36 })
    author: uuid | 'remote';

    // TODO: Probably special string type for seenBy=clientId
    // TODO: This is not needed to persist @Column('json', { name: 'seenBy' })
    seenBy: object;

    public isSeenBy(clientId: string): boolean {
        if (!Array.isArray(this.seenBy)) {
            return false;
        }
        return this.seenBy.includes(clientId);
    }

    public addSeenBy(clientId: string) {
        if (!Array.isArray(this.seenBy)) {
            this.seenBy = [clientId];
        } else {
            this.seenBy = [...this.seenBy.filter((clientId2) => clientId2 !== clientId), clientId];
        }
    }
    @Column('tinyint', { name: 'persist', default: () => "'0'" })
    persist: number;

    @Column('timestamp', { name: 'created', default: () => 'CURRENT_TIMESTAMP' })
    created: Date;

    @Column('enum', {
        name: 'businessName',
        nullable: false,
        enum: BusinessName,
    })
    businessName: BusinessName;

    // TODO: Remove @deprecated @Column('varchar', { name: 'uri', nullable: true, length: 1000 })
    // TODO: Remove @deprecated uri: string | null;

    @Column('varchar', { name: 'uriId', nullable: true, length: 1000 })
    uriId: string_uri_part | null;

    @Column('varchar', { name: 'artClass', length: 200 })
    artClass: string;

    // TODO: ArtHash or artId and special "dark" id for `{"__class": "Deleted"}`

    @Column('json', { name: 'art' })
    art: any; // Note: This is actually AbstractArt but due to import hell we are using here just any

    /**
     * Check if the given data is valid commit
     *
     * @param possibleCommit
     * @returns true if the data are implementing ICommitData
     */
    public static isCommitLike(possibleCommit: any): possibleCommit is ICommitData {
        // TODO: Create type CommitLike
        if (typeof possibleCommit !== 'object') return false;
        if (!possibleCommit) return false;
        for (const requiredKey of ['treeId', 'version', 'commitId']) {
            if (typeof possibleCommit[requiredKey] === 'undefined') {
                return false;
            }
        }
        return true;
    }
}

/**
 * TODO: [🥟] All typeguards should recieve unknown
 * TODO: [🥟] Expose isCommitLike to separate function and autogenerate
 * TODO: [🥟] Add to separate file suffixed .guard.ts
 * TODO: [Optimization][InitialLoading] Optimize this for initial loading
 */
