import { IDestroyable } from 'destroyable';
import { StatusCodes } from 'http-status-codes';
import React from 'react';
import SocketIO from 'socket.io-client';
import { JsonValue } from 'type-fest';
import { forAnimationFrame, forImmediate, forTime, forTimeSynced } from 'waitasecond';
import { Commit } from '../../10-database/Commit';
import { ICommitData } from '../../10-database/interfaces/ICommitData';
import { alertDialogue } from '../../40-utils/dialogues/alertDialogue';
import { BoardNotFoundError } from '../../40-utils/errors/BoardNotFoundError';
import { errorMessageWithAdditional } from '../../40-utils/errors/errorMessageWithAdditional';
import { ModuleMissingError } from '../../40-utils/errors/ModuleMissing';
import { string_uri_part, uuid } from '../../40-utils/typeAliases';
import { Translate } from '../../50-systems/TranslationsSystem/components/Translate';
import { clientVersion } from '../../config';
import { consolex } from '../../consolex';
import { ISystemsExtended } from '../00-SystemsContainer/ISystems';
import { MaterialArtVersioningSystem } from '../ArtVersionSystem/0-MaterialArtVersioningSystem';
import { IArtVersioningSystem } from '../ArtVersionSystem/IArtVersionSystem';
import { CORE_MODULE_SIGNATURE } from '../ArtVersionSystem/IModuleSignature';
import { IClosePreventable } from '../ClosePreventionSystem/IClosePreventable';
import { IBoardApiIdentity } from '../IdentitySystem/IIdentity';
import { IIdentify } from './interfaces/board/IIdentify';
import { IInitial } from './interfaces/board/IInitial';

/**
 * @collboard-system
 */
export class BoardApiClient
    extends MaterialArtVersioningSystem
    implements IClosePreventable, IDestroyable, IArtVersioningSystem
{
    private socketClient: SocketIO.Socket;

    public constructor(
        systems: ISystemsExtended,
        private apiUrl: URL,
        private uriId: string_uri_part /* TODO: Take it from systemsContainer */,
    ) {
        super(systems, CORE_MODULE_SIGNATURE);
    }

    public async destroy(): Promise<void /*  TODO: Wait till everything is send */> {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        await this.socketClient.disconnect();
    }

    protected async init() {
        await super.init();
        await forImmediate(/* <- TODO: Describe why waiting forAnimationFrame OR remove */);
        await this.createIdentity();
        this.establishConnection();
        this.keepConnectionAlive();
        this.syncLoopLocalObjectsToServer();
        // TODO: !! Subdestroy
        (await this.systems.closePreventionSystem).registerClosePrevention(this);
    }

    // TODO: Probably rename to initialConnected
    private atLeastOnceConnectedResolve: () => void;
    private atLeastOnceConnectedReject: (reason?: any) => void;
    public readonly atLeastOnceConnected = new Promise((resolve, reject) => {
        this.atLeastOnceConnectedResolve = resolve as any;
        this.atLeastOnceConnectedReject = reject;
    });

    private connected: boolean = false;
    private boardApiIdentity: IBoardApiIdentity;

    private async createIdentity() {
        this.boardApiIdentity = (await this.systems.identitySystem).createBoardApiIdentity();
    }

    private establishConnection() {
        // TODO: Probably pass through app URL object not string

        this.socketClient = SocketIO.io(`${this.apiUrl.protocol}//${this.apiUrl.host}`, {
            transports: ['websocket'],
            // reconnection: true - This is not working, so I have done it manually bellow
        });
        // TODO: !! this.socket.compress(true /* TODO: Should there be a data compressing? */);
        this.socketClient.on(
            'client_error',
            (
                error: {
                    status?: number;
                    message: string;
                    popup?: [string, string];
                } /* TODO: Interfaces for SocketIO emits */,
            ) => {
                if (error.status === StatusCodes.NOT_FOUND) {
                    return this.atLeastOnceConnectedReject(new BoardNotFoundError(error.message));
                }

                consolex.error(`Client error from API: ${error.message}`);

                if (error.popup) {
                    /*await */ alertDialogue(<Translate name={error.popup[0]!}>{error.popup[1]!}</Translate>);
                }
            },
        );

        this.socketClient.on('connect', async () => {
            const identify: IIdentify = {
                businessName: await (await this.systems.businessSystem).businessName,
                connectionIdentity: (await this.systems.identitySystem).createConnectionIdentity(this.boardApiIdentity),
                clientVersion,
                uriId: this.uriId,
                lastCommitId: this.lastCommitId,
            };
            this.socketClient.emit('identify', identify);
        });
        this.socketClient.on('disconnect', async () => {
            this.connected = false;
        });

        this.socketClient.on('initial', async (initial: IInitial) => {
            //consolex.log(`initial`, initial);
            this.permissions = initial.permissions;
            this.atLeastOnceConnectedResolve();
            this.connected = true;
        });

        this.socketClient.on('commits', async (newCommitsData: Array<JsonValue>) => {
            this.pushCommitsToVersioningSystem(
                ...(
                    await newCommitsData.mapAsync(async (newCommitData) => {
                        try {
                            const newCommit = await (await this.systems.artSerializer).deserialize(newCommitData);

                            if (!(newCommit instanceof Commit)) {
                                throw new Error(
                                    errorMessageWithAdditional(`Recieved unexpected commit`, {
                                        newCommit,
                                        newCommitData,
                                    }),
                                );
                            }

                            newCommit.addSeenBy('remote' /* TODO: Should be this done on client or server? */);
                            return newCommit;
                        } catch (error) {
                            if ((error as any).name === 'DeserializationError') {
                                (async () => {
                                    throw new ModuleMissingError(
                                        error as Error,
                                        `There is probbably some module missing to fully show this board. Did you create on this board some arts with colldev and now forgetten to run it?`,
                                    );
                                })();
                                return null;
                            } else {
                                throw error;
                            }
                        }
                    })
                ).filter(Boolean),
            );
        });

        this.socketClient.on('commit_persisted', (persistedData: { commitId: uuid; id: number }) => {
            try {
                this.setCommitWasPersisted(persistedData.commitId, persistedData.id);
            } catch (error) {
                consolex.error(error);
            }
        });
    }

    /**
     * TODO: ColldevSyncer does not need keepConnectionAlive so probbably BoardApiClient wont either
     */
    private async keepConnectionAlive() {
        while (true) {
            await forTime(1000 /* as a frequency of checking that connection is alive */);
            if (this.isDestroyed) {
                // TODO: Wait till everything is send
                return;
            }
            if (this.socketClient.connected) {
            } else {
                // consolex.info('keepConnectionAlive: disconnected, trying to reconnect,...');
                this.socketClient.connect();
            }
        }
    }

    private queue: Record<uuid, ICommitData> = {};

    private pushToQueue(commit: ICommitData): void {
        this.queue[commit.treeId] = commit;
    }

    private takeFromQueue(): ICommitData | null {
        const keys = Object.keys(this.queue) as Array<uuid>;
        for (const key of keys) {
            const commit = this.queue[key];
            delete this.queue[key];
            return commit;
        }
        return null;
    }

    private async sendFromQueueToServer(): Promise<void> {
        if (this.connected) {
            // TODO: !! Send all or more commits
            const commit = this.takeFromQueue();

            if (commit) {
                // TODO: this.socket.emit should be awaited, but I do not know how?
                this.socketClient.emit('commits', [await (await this.systems.artSerializer).serialize(commit)]);
            }
        }
    }

    private async syncLoopLocalObjectsToServer(): Promise<void> {
        this.commitsObservable.subscribe(async (commit: ICommitData) => {
            if (!commit.isSeenBy('remote')) {
                this.pushToQueue(commit);
            }
        });

        // TODO: Run only once
        while (true) {
            await forAnimationFrame(/* TODO: How long is optimal to wait? */);
            await forTimeSynced(30 /* as a frequency to sync a commits queue on server */);
            if (this.isDestroyed) {
                // TODO: Wait till everything is send
                return;
            }
            await this.sendFromQueueToServer();
        }
    }

    public get canBeClosed(): boolean {
        return Object.keys(this.queue).length === 0;
    }
}
