import { internalModules } from '../../../ModuleStore/internalModules';

internalModules.declareModule(() => ({
    manifest: {
        name: '@test/simple-token-license/gold-membership',
        title: '💛 Gold membership',
        description: { en: 'Testing SIMPLE_TOKEN usage license' },
        usageLicenses: [
            {
                type: 'SIMPLE_TOKEN',
                token: '11307f05-08f6-4f95-8671-7c51db04e768',
                price: {
                    amount: 149,
                    currency: 'CZK',
                },
                buyLink:
                    'https://github.com/collboard/collboard/blob/main/src/50-systems/LicenseSystem/modules/mocked-license-eshop.md',
                isModuleAutoInstalled: true /* <- TODO: [🥇] Visibility in module store?! */,
            },
            {
                type: 'SIMPLE_TOKEN',
                token: 'gold',
                price: 0,
                isModuleAutoInstalled: true,
            },
        ],

        flags: {
            isShowcase: true,
        },
    },

    async setup(systems) {
        const { styleSystem } = await systems.request('styleSystem');

        return styleSystem.registerGlobalStyle(
            `
                .custom-modal-header{
                    background-color: rgb(217 179 43) !important;
                }
            `,
        );
    },
}));

/**
 * TODO: [🥇] When multiple licenses for one module - which buyLink should be shown?
 * TODO: [🏅] Allow to override just one color in the skin via styleSystem
 */
