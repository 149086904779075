import { ISystems } from '../../../../50-systems/00-SystemsContainer/ISystems';
import { ExternalModuleStoreConnector } from '../../../../50-systems/ModuleStore/connectors/ExternalModuleStoreConnector';
import { internalModules } from '../../../../50-systems/ModuleStore/internalModules';
import { makeArticlesSetModule } from '../../../../50-systems/ModuleStore/makers/makeArticlesSetModule';
import { PUBLIC_URL } from '../../../../config';

internalModules.declareModule(() =>
    makeArticlesSetModule({
        manifest: {
            flags: { isHidden: true },
            name: '@collboard/internal/about',
            deprecatedNames: '@collboard/about',
            title: {
                // TODO: How to do article translations
                // TODO: Probably title for article modules can be loaded dynamically from the content
                cs: 'O nás',
                en: 'About',
            },
        },
        defaultArticle: 'team',
        async articles(systems: ISystems) {
            const { moduleStore } = await systems.request('moduleStore');

            // TODO: This supports only and exactly one external connector
            const connector = moduleStore.connectors.find(
                (connector2) => connector2 instanceof ExternalModuleStoreConnector,
            ) as ExternalModuleStoreConnector;

            return {
                ...Object.fromEntries(
                    [
                        'contact.html',
                        'team.html',
                        'principles.md',
                        // TODO: References
                        // TODO: Faq
                        // TODO: Manual
                        // TODO: technologies
                        // TODO: Stats (generate and commit)
                        // TODO: Probbably info about business
                        // TODO: Probbably presentation
                    ].map((page, index) => {
                        const [name, extension] = page.split('.');
                        const order = (index + 1) * 10;
                        return [
                            name,
                            {
                                /* [🌐] */
                                cs: `https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.46/articles/${order}-${name}/${name}.cs.${extension}`,
                                en: `https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.46/articles/${order}-${name}/${name}.en.${extension}`,
                                pl: `https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.46/articles/${order}-${name}/${name}.pl.html`,
                                uk: `https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.46/articles/${order}-${name}/${name}.uk.html`,
                                // Note: sk version is not available - cs is good enough
                            },
                        ];
                    }),
                ),
                status: {
                    en: `${PUBLIC_URL}/status`,
                },
                moduleStore: {
                    en: `${connector.moduleStoreUrl.href}status`,
                },
            };
        },
    }),
);
