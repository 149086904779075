import { Destroyable, IDestroyable, Registration } from 'destroyable';
import { COLLDEV_SYNCER_STYLE } from '../../00-modules/development/COLLDEV_SYNCER_STYLE';
import { errorMessageWithAdditional } from '../../40-utils/errors/errorMessageWithAdditional';
import { factor } from '../../40-utils/IFactory';
import { ISubLogger } from '../../40-utils/logger/ILogger';
import { consolex } from '../../consolex';
import { ISystems } from '../00-SystemsContainer/ISystems';
import { IModule, IModuleDefinition } from './interfaces/IModule';
/**
 * TODO: [🔅] Put all %c styled console logs styles into one config place
 */
const INSTALLATION_CONSOLE_LOG_STYLE = `color: #009edd;`;

/**
 * This represents one installation of one module.
 */
export class ModuleInstallation extends Destroyable implements IDestroyable {
    public static async install(module: IModule, systems: ISystems, syncerName?: string): Promise<ModuleInstallation> {
        const moduleDefinition = factor(module);

        const logInstallation = (error?: Error) => {
            let group: ISubLogger;
            const statusEmoji = !error ? '✓' : '⚠️';

            if (moduleDefinition.manifest) {
                group = consolex.groupCollapsed(
                    `%c[${statusEmoji}] Installing ${moduleDefinition.manifest.name}${
                        !syncerName ? '' : ` (with ${syncerName})`
                    }`,
                    syncerName !== 'ColldevSyncer' ? INSTALLATION_CONSOLE_LOG_STYLE : COLLDEV_SYNCER_STYLE,
                );
            } else {
                group = consolex.groupCollapsed(
                    `%c[${statusEmoji}] Installing anonymous module ${!syncerName ? '' : ` (with ${syncerName})`}`,
                    syncerName !== 'ColldevSyncer' ? INSTALLATION_CONSOLE_LOG_STYLE : COLLDEV_SYNCER_STYLE,
                );
                group.info(moduleDefinition);
                group.info(moduleDefinition.setup.toString());
            }

            if (error) {
                group.error(error);
            }

            group.end();
        };

        let registration: IDestroyable;

        try {
            //---- PermissionsSystem code --------------------------------
            // Note: This code will be under PermissionsSystem in the future
            //       PermissionsSystem will manage all permissions and asks other systems for subpermissions

            const requirePermissions = moduleDefinition.manifest?.requirePermissions || ['edit'];

            // Note: Not requesting materialArtVersioningSystem with request because when materialArtVersioningSystem not defined we want to immediatelly assume that edit permission is false without waiting
            //     > const { materialArtVersioningSystem } = await systems.request('materialArtVersioningSystem');

            const materialArtVersioningSystem = (systems as any).materialArtVersioningSystemSubject.value;

            /**
             * TODO: [✨] What is the best way to mark the permissions and flags
             */
            const editPermission =
                materialArtVersioningSystem?.permissions.edit ??
                true; /* <- Note: When the materialArtVersioningSystem we assume that bu default it will have edit permission, this will allow to load modules (like module store modal) before the MaterialVersioningSystem is even initialized */

            const hasRequiredPermissions = requirePermissions.every((requirePermission) => {
                if (requirePermission === 'view') {
                    return true;
                } else if (requirePermission === 'edit') {
                    return editPermission;
                } else {
                    throw new Error(
                        errorMessageWithAdditional(`Invalid permission "${requirePermission}"`, {
                            module,
                            requirePermissions,
                        }),
                    );
                }
            });
            //---- End of PermissionsSystem code ----------------

            if (hasRequiredPermissions) {
                registration = await moduleDefinition.setup(systems);
                logInstallation();
            } else {
                registration = Registration.void();
                // Note: In case of missing permissions just do not do nothing and ModuleInstallation will be acting as a Null object
            }
        } catch (error) {
            // Note: In case of error during the installation (or checking the permissions) report the error and ModuleInstallation will be acting as a Null object
            registration = Registration.void();
            logInstallation(error as Error);

            // Note: Do not throw the error here, because small errors in the installation process can crash the whole application.
            // throw moduleInstallationError;
        }

        return new ModuleInstallation(moduleDefinition, registration, syncerName);
    }

    private constructor(
        readonly moduleDefinition: IModuleDefinition,
        readonly registration: IDestroyable,
        private readonly syncerName?: string,
    ) {
        super();
        this.addSubdestroyable(registration);
    }

    public async destroy() {
        if (this.moduleDefinition.manifest) {
            consolex.info(
                `%c[✗] Uninstalling ${this.moduleDefinition.manifest?.name}${
                    !this.syncerName ? '' : ` (with ${this.syncerName})`
                }`,
                this.syncerName !== 'ColldevSyncer' ? INSTALLATION_CONSOLE_LOG_STYLE : COLLDEV_SYNCER_STYLE,
            );
        } else {
            consolex
                .groupCollapsed(
                    `%c[✗] Uninstalling anonymous module ${!this.syncerName ? '' : ` (with ${this.syncerName})`}`,
                    this.syncerName !== 'ColldevSyncer' ? INSTALLATION_CONSOLE_LOG_STYLE : COLLDEV_SYNCER_STYLE,
                )
                .info(this.moduleDefinition)
                .info(this.moduleDefinition.setup.toString())
                .end();
        }

        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
    }
}
