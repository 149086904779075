import { Registration } from 'destroyable';
import React from 'react';
import { Menu } from '../../30-components/menu/Menu';
import { ISystemsExtended } from '../../50-systems/00-SystemsContainer/ISystems';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { UserInterfaceElementPlace } from '../../50-systems/UserInterfaceSystem/UserInterfaceElementPlace';
import { ColldevSyncer } from './ColldevSyncer';
import { ColldevSyncerComponent } from './ColldevSyncerComponent';

/**
 * @singleton
 */
let systemsForColldev: ISystemsExtended;

/**
 * We need non-signed systems to be able to use the ColldevSyncer.
 * @singleton
 */
export function setSystemsForColldev(systems: ISystemsExtended) {
    systemsForColldev = systems;
}

/**
 * @singleton
 */
let colldevSyncerPromiseResolve: (value: ColldevSyncer) => void;

/**
 * @singleton
 */
export let colldevSyncerPromise: Promise<ColldevSyncer> = new Promise((resolve) => {
    colldevSyncerPromiseResolve = resolve;
});

internalModules.declareModule(() => ({
    manifest: {
        // TODO: This should be CollboardDevelopmentBusiness
        name: 'DevelopmentColldev' /* TODO: Disable installation from URL */,
        categories: ['Development'],
        author: Authors.collboard,
        requirePermissions: [],
    },
    async setup(systems) {
        // TODO: !! Allow to choose port

        const { routingSystem, userInterfaceSystem } = await systems.request('routingSystem', 'userInterfaceSystem');

        const colldevSyncer = new ColldevSyncer(systemsForColldev, { activateDeclaredModules: true });

        await colldevSyncer.ready;
        colldevSyncerPromiseResolve(colldevSyncer);

        return Registration.join(
            colldevSyncer /* TODO: , moduleStore.registerModuleStorage(colldevSyncer.modulesStorage)*/,
            userInterfaceSystem.registerElement({
                systems,
                place: UserInterfaceElementPlace.EdgeBottom,
                order: -2,
                element: (
                    <Menu
                        orientation="horizontal"
                        onClick={() => {
                            routingSystem.urlVariables.setValue({
                                moduleName: 'DevelopmentColldevModal' /* TODO: Better URL */,
                            });
                        }}
                    >
                        <ColldevSyncerComponent {...{ colldevSyncer }} />
                    </Menu>
                ),
            }),
        );
    },
}));
