import { string_protocol } from '../../../40-utils/typeAliases';
import { RemoveIndex } from '../../../40-utils/typeHelpers';
import { hostToBusiness } from '../../BusinessSystem/utils/hostToBusiness';
import { IUrlVariables, URI_PATTERN } from './IUrlVariables';

export function decodeUrl(url: URL): RemoveIndex<IUrlVariables> {
    // Note: Here was regexp but it bacame such complex, we replace it by split.

    const { host } = url;

    if (url.protocol !== 'http:' && url.protocol !== 'https:') {
        throw new Error(`Cannot decode URL because of unknown protocol "${url.protocol}".`);
    }

    const protocol = url.protocol as string_protocol;
    const { businessName } = hostToBusiness(url.host);

    const match = url.pathname.match(URI_PATTERN);

    if (!match) {
        return { protocol, host, businessName, uriId: null };
    }

    let uriId: string | null = match.groups!.uriId;

    if (/^(-+|null|\s*)$/i.test(uriId)) {
        uriId = null;
    }

    const { extension, moduleScope, moduleName } = match.groups!;
    let modulePath: string | null = match.groups!.modulePath;

    let module: string | null;

    if (moduleName) {
        if (moduleScope) {
            module = `${moduleScope}/${moduleName}`;
        } else {
            module = `@collboard/${moduleName}`;
        }

        if (!modulePath) {
            modulePath = '/';
        }
    } else {
        module = null;
        modulePath = null;
    }

    return { protocol, host, businessName, uriId, extension, moduleName: module, modulePath };
}
