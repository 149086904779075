/**
 * Pick given keys from the recieved options array.
 * Note: It does not mutate recieved object
 *
 * @param options
 * @param keys
 * @returns stripped object
 *
 * @collboard-modules-sdk
 */
export function pickKeys<TObject, TRemove extends keyof TObject>(
    options: TObject,
    keys: Array<TRemove>,
): Pick<TObject, TRemove> {
    const newOptions: any = {};
    for (const key of keys) {
        newOptions[key] = options[key];
    }
    return newOptions;
}
