import { string_css_selector, string_html } from '../../typeAliases';

/**
 * Extract all used selectors from html.
 * It extract selectors:
 *  - from class attributes
 *  - from id attributes
 *
 * @param html - html to extract selectors from.
 * @returns Set with all used selectors
 *
 * @collboard-modules-sdk
 */
export function extractUsedSelectorsFromHtml(html: string_html): Set<string_css_selector> {
    const usedSelectors: Set<string_css_selector> = new Set();

    const classSelectors = html.match(/class=["']([^"']+)["']/g);
    if (classSelectors) {
        classSelectors.forEach((classSelector) => {
            const selector = classSelector.replace(/class=["']/, '').replace(/["']/, '');
            usedSelectors.add(`.${selector}`);
        });
    }

    const idSelectors = html.match(/id=["']([^"']+)["']/g);
    if (idSelectors) {
        idSelectors.forEach((idSelector) => {
            const selector = idSelector.replace(/id=["']/, '').replace(/["']/, '');
            usedSelectors.add(`#${selector}`);
        });
    }

    return usedSelectors;
}

/**
 * TODO: [🎞️] Probbably work with XMLs in some object-based method
 */
