import { IAppearance } from './IAppearance';
import { IFullPhongMaterial } from './IMaterial';
import { IColorTexture } from './ITexture';

/**
 * Default empty texture
 */
export const EMPTY_TEXTURE: IColorTexture = {
    color: 'transparent' /* <- TODO: Maybe in hex or rgba format */,
};

/**
 * Default empty material
 */
export const EMPTY_MATERIAL: IFullPhongMaterial = {
    emissiveTexture: EMPTY_TEXTURE,
    diffuseTexture: EMPTY_TEXTURE /* <- TODO: Maybe black/white */,
    specularTexture: EMPTY_TEXTURE /* <- TODO:Maybe black/white */,
    ambientTexture: EMPTY_TEXTURE /* <- TODO: Maybe black/white */,
};

/**
 * Default empty appearance
 */
export const EMPTY_APPEARANCE: IAppearance = {
    default: EMPTY_MATERIAL,
};

/**
 * TODO: [👩‍🚀] What is the best place and name for this file - defaults.ts, config.ts OR it should be in each folder
 */
