import React from 'react';
import { LoaderInline } from '../../30-components/utils/Loader/LoaderInline';
import { useObservable } from '../../40-utils/react-hooks/useObservable';
import { ColldevSyncer } from './ColldevSyncer';

interface IColldevSyncerComponentProps {
    colldevSyncer: ColldevSyncer;
}
export function ColldevSyncerComponent({ colldevSyncer }: IColldevSyncerComponentProps) {
    const { value: clientStatus } = useObservable(colldevSyncer.clientStatus);

    // TODO: Show more things, probbably simmilar things as in CLI

    if (!clientStatus.isConnected) {
        return (
            <LoaderInline
                alt="Waiting for connection from Colldev"
                canLoadForever
                animation={['spinning', 'unpacking']}
            >
                Connecting to Colldev
            </LoaderInline>
        );
    }

    const workingOnModulesCount = Object.keys(clientStatus.modules).length;
    if (workingOnModulesCount === 0) {
        return (
            <LoaderInline alt="Waiting for module from Colldev" canLoadForever icon="countdown" animation={'spinning'}>
                No module to work on
            </LoaderInline>
        );
    } else if (workingOnModulesCount === 1) {
        const workingOnModuleName = Object.keys(clientStatus.modules)[0]!;
        return (
            <LoaderInline alt="Just effect" canLoadForever icon="package">
                Working on {workingOnModuleName}
            </LoaderInline>
        );
    } else {
        return (
            <LoaderInline alt="Just effect" canLoadForever icon="cubes">
                Working on {workingOnModulesCount} modules
            </LoaderInline>
        );
    }
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
