import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';

/**
 * CreateSystem allows importing which allows to import/create arts from other sources.
 * Note: CreateSystem - for individual arts, GenerateSystem - for whole board
 * Note: CreateSystem+GenerateSystem and ExportSystem are in some kind opposites.
 *
 * @collboard-system
 */
export class CreateSystem extends AbstractSystem {
    /*
        TODO: This class is a boilerplate of the system that we have not started working on yet.
        @see https://github.com/collboard/collboard/issues/152
    */

    protected async init() {}
}
