/**
 * This error occurs when you try to add some item in place where it already exists
 *
 * @collboard-modules-sdk
 */
export class DuplicateError extends Error {
    public readonly name = 'DuplicateError';
    public constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, DuplicateError.prototype);
    }
}
