import { Registration } from 'destroyable';
import React from 'react';
import { forTime } from 'waitasecond';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { Translate } from '../../50-systems/TranslationsSystem/components/Translate';

internalModules.declareModule(() => ({
    manifest: {
        flags: { isHidden: true },
        name: 'DevelopmentWarning' /* TODO: Disable installation from URL */,
        categories: ['Development'],
        author: Authors.collboard,
    },

    // TODO: Probably this notification should be also on welcome screen? Or not?
    async setup(systems) {
        const { storageSystem, notificationSystem } = await systems.request('storageSystem', 'notificationSystem');

        // TODO: DRY - make some helper for notifications with memory
        await forTime(500 /* <- TODO: Is this waiting necessary */);
        const storage = storageSystem.getStorage(`DevelopmentWarning` /* TODO: Limited validity storage */);

        const accepted = await storage.getItem(`accepted`);
        if (!accepted) {
            const notificationRegistration = notificationSystem.publish({
                tag: 'DevelopmentWarning',
                type: 'warning',
                //TODO: Probably should notifications be natively translatable with {en: ..., cs: ...}
                title: <Translate name="DevelopmentWarning / title">You are in development mode</Translate>,
                body: (
                    <Translate name="DevelopmentWarning / body" html>
                        You have opened Collboard on <b>development mode</b>. Content created here will not be
                        preserved. This version is for developing new modules <b>not real usage</b>.
                    </Translate>
                ),
                actions: [
                    {
                        action: 'cancel',
                        type: 'cancel',
                        title: (
                            <Translate name="DevelopmentWarning / go to normal version">
                                Back to Collboard.com
                            </Translate>
                        ),
                        href: `https://www.collboard.com`,
                    },
                    {
                        action: 'more',
                        type: 'more',
                        title: <Translate name="DevelopmentWarning / show more">Show more</Translate>,
                        // TODO: Refer to DevelopmentAboutModule
                        href: `https://github.com/collboard/modules-sdk`,
                    },
                    {
                        action: 'understand',
                        type: 'ok',
                        title: <Translate name="DevelopmentWarning / agree">I understand</Translate>,
                        onClick: () => {
                            notificationRegistration.destroy();
                            storage.setItem(`accepted`, true /* TODO: When possible set with some expiration */);
                        },
                    },
                ],
            });

            return notificationRegistration;
        }
        return Registration.void();
    },
}));
