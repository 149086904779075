import { registerItemsInSubjectOfArrays, Registration } from 'destroyable';
import { BehaviorSubject } from 'rxjs';
import { isEqualArray } from '../../40-utils/isEqualArray';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { IControl } from './interfaces/IControl';
import { IKey } from './interfaces/IKey';
import { IShortcut } from './interfaces/IShortcut';
import { controlToControls, IRegisterControlOptions } from './utils/controlToControls';
import { isEventOnBoard } from './utils/isEventOnBoard';
/**
 * ControlSystem can register and manage keyboard shortcuts like Ctrl + C by modules (or maybe other systems).
 *
 * @collboard-system
 */
export class ControlSystem extends AbstractSystem {
    public readonly pressedKeys = new BehaviorSubject<IShortcut>([]);
    public readonly controls = new BehaviorSubject<Array<IControl>>([]);

    protected async init() {
        this.registerListeners();
    }

    public registerControl<TValue>(options: IRegisterControlOptions<TValue>): Registration {
        const { priority, defaultShortcuts, executor } = controlToControls(options);
        return Registration.join(
            ...Array.from(defaultShortcuts.keys()).map((defaultShortcut) =>
                this.registerOneShortcut({
                    priority,
                    defaultShortcut,
                    async executor() {
                        return await executor({ value: defaultShortcuts.get(defaultShortcut)! });
                    },
                }),
            ),
        );
    }

    private registerOneShortcut(control: IControl) {
        return registerItemsInSubjectOfArrays({
            currentValue: this.controls.value /* <- [🌹] Not necessary */,
            base: this.controls,
            add: [control],
        });
    }

    private async executeShortcutsToCurrentlyPressedKeys(
        pressedKeys: Array<IKey>,
        event: KeyboardEvent | WheelEvent,
    ): Promise<void> {
        const controls = this.controls.value
            .filter(({ defaultShortcut }) => isEqualArray(pressedKeys, defaultShortcut))
            .sort((a, b) => (a.priority || 0) - (b.priority || 0));

        if (controls.some(({ isContinuing }) => !isContinuing)) {
            event.preventDefault();
        }

        for (const control of controls) {
            control.executor();

            if (control.isContinuing) {
                continue;
            } else {
                break;
            }

            // Note: continue + break syntax is for me most readable
        }
    }

    private registerListeners() {
        this.addSubdestroyable(
            Registration.createEventListener({
                element: window.document,
                type: 'keydown',
                listener: (event: KeyboardEvent) => {
                    if (!isEventOnBoard(event)) {
                        return;
                    }

                    const key = event.key as IKey;
                    this.pressedKeys.next([...this.pressedKeys.value.filter((key2) => key2 !== key), key]);

                    /* not await */ this.executeShortcutsToCurrentlyPressedKeys([...this.pressedKeys.value], event);
                },
            }),
        );

        this.addSubdestroyable(
            Registration.createEventListener({
                element: window.document,
                type: 'keyup',
                listener: (event: KeyboardEvent) => {
                    if (!isEventOnBoard(event)) {
                        return;
                    }

                    const key = event.key as IKey;
                    this.pressedKeys.next(this.pressedKeys.value.filter((key2) => key2 !== key));
                },
            }),
        );

        this.addSubdestroyable(
            Registration.createEventListener({
                element: window.document,
                type: 'wheel',
                listener: (event: WheelEvent) => {
                    if (!isEventOnBoard(event)) {
                        return;
                    }

                    // TODO: Probably spacial wheel
                    if (event.deltaY > 0) {
                        /* not await */ this.executeShortcutsToCurrentlyPressedKeys(
                            [...this.pressedKeys.value, 'WheelDown'],
                            event,
                        );
                    } else if (event.deltaY < 0) {
                        /* not await */ this.executeShortcutsToCurrentlyPressedKeys(
                            [...this.pressedKeys.value, 'WheelUp'],
                            event,
                        );
                    }

                    if (event.deltaX > 0) {
                        /* not await */ this.executeShortcutsToCurrentlyPressedKeys(
                            [...this.pressedKeys.value, 'WheelRight'],
                            event,
                        );
                    } else if (event.deltaX < 0) {
                        /* not await */ this.executeShortcutsToCurrentlyPressedKeys(
                            [...this.pressedKeys.value, 'WheelLeft'],
                            event,
                        );
                    }

                    event.preventDefault();
                },
                options: { passive: false },
            }),
        );
    }
}

/**
 *
 * TODO: Add gamecontroller shortcuts
 * TODO: Can we somehow capture modifying key when not focused?
 *     For example I press Control of other window then click on Collboard and press ArrowUp, now I dont get Control+ArrowUp result but only ArrowUp.
 *     But in native events like zooming this modifying is captured
 *
 * TODO: [🎮] Add registrees to controls
 * TODO: [🎮] Sign this system
 * TODO: [🎮] IManifest (with hierarchy)
 *
 * TODO: [🎮] Test combinations with wheel
 *
 */
