import React from 'react';
import { Promisable } from 'type-fest';
import { factor, Factorable } from '../../40-utils/IFactory';
import { ILoaderProps } from './Loader/ILoaderProps';
import { Loader } from './Loader/Loader';

interface IAsyncContentComponentProps extends ILoaderProps {
    /**
     * Component to be rendered before the content is loaded
     * If not set, default <Loader/> is used
     */
    loader?: JSX.Element;

    /**
     * The async content to be rendered
     */
    content: Factorable<Promisable<JSX.Element | Array<JSX.Element>>>;
}

interface IAsyncContentComponentState {
    content: JSX.Element | Array<JSX.Element>;
}

/**
 * Utility for mounting RxJS observable content
 *
 * @deprecated [📠] Use usePromise/useLoadable hook instead
 * @collboard-modules-sdk
 */
export function AsyncContentComponent({ loader, content, alt }: IAsyncContentComponentProps) {
    if (React.isValidElement(/* <- TODO: Import and use just a isValidElement */ content)) {
        return content;
    }

    // Note: This is an optimalization of AsyncContentComponent. It will works as it is not breaking call order
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, setState] = React.useState/* <- TODO: Import and use just a useState */ <IAsyncContentComponentState>(
        {
            content: loader || <Loader {...{ alt }} />,
        },
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(
        /* <- TODO: Import and use just a useEffect */ () => {
            let mounted = true;

            (async () => {
                const contentResolved = await factor(content);

                /*
            TODO: [1] Better mechanism for slowing down components
            if (slowAsyncContentComponentMiliseconds) {
                await forTime(slowAsyncContentComponentMiliseconds);
            }
            */

                if (!mounted) return;
                setState({ content: contentResolved });
            })();

            return () => {
                mounted = false;
            };
        },
        [content],
    );

    // TODO: [🍨] Warning: Each child in a list should have a unique "key" prop.
    return <>{state.content}</>;
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 * TODO: [🧵] Move to external LIB for react loadables

TODO: [1] Better mechanism for slowing down components
let slowAsyncContentComponentMiliseconds = 0;
(window as any).slowAsyncContentComponent = (miliseonds: number) => {
    slowAsyncContentComponentMiliseconds = miliseonds;
};
*/
