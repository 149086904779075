import { CSSProperties } from 'react';
import { Color } from '../40-utils/color/Color';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { AbstractBackgroundArt } from './26-AbstractBackgroundArt';

internalModules.declareModule(() => makeArtModule(BackgroundColorArt));

/**
 * Art setting background with color style
 *
 * @collboard-modules-sdk
 */
export class BackgroundColorArt extends AbstractBackgroundArt {
    public static serializeName = 'BackgroundColor';
    public static manifest = {
        name: '@collboard/internal/background-color-art',
        deprecatedNames: '@collboard/background-color-art',
    };

    /**
     * @param color
     * @param z see `AbstractBackgroundArt`
     */
    public constructor(public color: Color, z?: number) {
        super(z);
    }

    public additionalStyle(): CSSProperties {
        return {
            left: 0 /* <- LIB xyzt .toTopLeft() */,
            top: 0 /* <- LIB xyzt .toTopLeft() */,
            width: '100vw',
            height: '100vh',
            backgroundColor: this.color.toString(),
        };
    }

    public transformStyle(): CSSProperties {
        return {};
    }
}

/**
 * TODO: [🎚️] Implement IArt
 */
