import React from 'react';
import { internalModules } from '../../ModuleStore/internalModules';
import { makeModalModule } from '../../ModuleStore/makers/makeModalModule';
import { ImportModalComponent } from '../components/ImportModalComponent';

/**
 * @deprecated in favour of @collboard/internal/add
 */
internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            name: '@collboard/internal/import',
            deprecatedNames: ['@collboard/import', 'Import'],
            title: { en: 'Import content', cs: 'Importovat obsah' },

            flags: {
                isDeprecated: true,
            },
        },
        async createModal(systems) {
            const { materialArtVersioningSystem, routingSystem } = await systems.request(
                'materialArtVersioningSystem',
                'routingSystem',
            );

            return <ImportModalComponent {...{ materialArtVersioningSystem, routingSystem }} />;
        },
    }),
);

/**
 * TODO: [🐅] Maybe some more elegant way how to create icon-window pairs of modules
 */
