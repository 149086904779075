import React from 'react';
import styled from 'styled-components';
import { Color } from '../../40-utils/color/Color';
import { useSkin } from '../../40-utils/react-hooks/useSkin';
import { ISkin } from '../../50-systems/StyleSystem/ISkin';

type IPageOverlayProps =
    React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{
        onPointerDown?: () => void;
    }>;

/**
 * Renders the content inside a darkened page overlay blocking all
 * interactions with page behind
 *
 * **You probably want to use Modal or CategorizedModal instead.**
 *
 * **Warning: Do not try to use more, than one PageOverlay at a time.**
 *
 * @collboard-modules-sdk
 */
export function PageOverlay({ onPointerDown, children }: IPageOverlayProps) {
    const { skin } = useSkin();

    return (
        <>
            <PageOverlayDiv {...{ skin, onPointerDown }} />
            {children}
        </>
    );
}

const PageOverlayDiv = styled.div<{ skin: ISkin }>`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: ${({ skin }) => Color.from(skin.colors.overlay).toString()};
    vertical-align: middle;
    z-index: 9090;
`;

/**
 * TODO: @roseckyj 🎨 Prevent OR Warn if multiple PageOverlay instances from being rendered at the same time
 */
