import fetch from 'node-fetch'; /* <- TODO: [🌿] Use the Node native fetch */
import { Converter } from 'showdown';
import { FunctionCache } from './FunctionCache';
import { string_html, string_url } from './typeAliases';

/**
 *
 * @collboard-modules-sdk
 */
export const scrapeContentFromUrl = new FunctionCache(scrapeContentFromUrlUncached).function;

/**
 *
 * @collboard-modules-sdk
 */
export async function scrapeContentFromUrlUncached(articleUrl: string_url): Promise<string_html> {
    if (/^https:\/\/([a-z]+\.)?collboard.com\//.test(articleUrl)) {
        // TODO: @roseckyj iframe should fill the modal
        return `<iframe class="wanted-iframe" src="${articleUrl}"></iframe>`;
    }

    const response = await fetch(articleUrl);
    const mimeType = response.headers.get('content-type');

    if (mimeType && /^text\/markdown/.test(mimeType)) {
        const converter = new Converter();
        const html = converter.makeHtml(await response.text());

        // TODO: Add target target="_blank" rel="noreferrer noopener"

        return html;
    } else if (mimeType && /^text\/html/.test(mimeType) && /^https:\/\/docs.google.com\/document/.test(articleUrl)) {
        throw new Error(`Scraping Google documents is temporarily disabled`);

        /*
        const html = await response.text();

        const parsing = /<title>(?<title>.*?)<\/title>.*(?<content><div id="contents">.*?)<div id="footer">/s.exec(
            html,
        );

        if (!parsing) {
            throw new Error(`Google document has unexpected format.\n See ${articleUrl}`);
        }
        const { groups } = parsing;

        // const title = (groups as any).title;
        const content = (groups as any).content;
        const contentWithoutStyle = content.replace(/<style type="text\/css">.*<\/style>/, '');

        return contentWithoutStyle;

        */
    } else if (mimeType && /^text\/html/.test(mimeType)) {
        let html = await response.text();
        html = html.split('<a ').join('<a target="_blank" rel="noopener noreferrer" ');

        return html;
    } else {
        throw new Error(
            `When using makeArticleModule you have to provide url to markdown or html. Url "${articleUrl}" does responded to ${mimeType}.`,
        );
    }
}
