import { IArt } from '../../../../50-systems/CollSpace/IArt';
import { ISystems } from '../../../00-SystemsContainer/ISystems';
import { IAppearance } from '../../../CollSpace/appearance/IAppearance';
import { IArtData } from '../../../CollSpace/IArtData';
import { IShape } from '../../../CollSpace/IShape';
import { IModuleDefinition } from '../../interfaces/IModule';
import { IModuleManifest } from '../../interfaces/IModuleManifest/IModuleManifest';

export type IArtModuleDefinition<
    TShape extends IShape = IShape,
    TAppearance extends IAppearance = IAppearance,
> = IModuleDefinition & /* TODO: !!x Remove IMakeFunctionalArtModuleOptions<TShape, TAppearance>*/ {
    /**
     * @@x
     * TODO: !!x to ICommonMakerOptions
     */
    manifest: IModuleManifest;

    /**
     * @@x
     */
    createArt(artData: IArtData<TShape, TAppearance>): IArt<TShape, TAppearance>;
};

export interface IMakeFunctionalArtModuleOptions<
    TShape extends IShape = IShape,
    TAppearance extends IAppearance = IAppearance,
    // <- TODO: !!x Should be this generic under makeFunctionalArtModule+IMakeFunctionalArtModuleOptions OR just createArt
> {
    /**
     * @@x
     * TODO: !!x to ICommonMakerOptions
     */
    manifest: IModuleManifest;

    /**
     * @@x
     */
    createArt(
        artData: IArtData<TShape, TAppearance>,
    ): Omit<
        IArt<TShape, TAppearance>,
        | 'artId'
        | 'moduleName' /* <- TODO: !!x IRELEVANT, REMOVE COMMENT> Maybe Do not omit moduleName BUT instead just pass createArt and NO manifest and NO IMakeFunctionalArtModuleOptions*/
    >;
}

/**
 * @@x
 *
 * Note: Module still needs to be declared
 * @collboard-modules-sdk
 */
export function makeFunctionalArtModule<TShape extends IShape = IShape, TAppearance extends IAppearance = IAppearance>(
    options: IMakeFunctionalArtModuleOptions<TShape, TAppearance>,
): IArtModuleDefinition<TShape, TAppearance> {
    const { manifest, createArt: createArtInner } = options;

    // Note: Art modules are invisible, and so there is no need to create a massive manifest for them. Write everything into the corresponding tool module.
    // TODO: !!x [0] Probably check consistency between serializeName and module name> name: `${artSerializerRule.name}Art`
    // TODO: !!x Check that manifest is not public

    function createArt(artData: IArtData<TShape, TAppearance>): IArt<TShape, TAppearance> {
        const art /*: [1] IArt<TShape, TAppearance>*/ = createArtInner(artData as any /* <- TODO: !!x Remove any */);

        (art as any).moduleName /* <- TODO: !!x [1] Better solution than using any */ =
            manifest.name /* <- TODO: !!x Check if exists and is same */;
        // TODO: Also with TODO: !!x! artId
        return art as any /* <- TODO: !!x [1] */;
    }

    const module = {
        manifest: {
            ...manifest,
            requirePermissions: ['view'],
        },
        createArt,
        async setup(systems: ISystems) {
            const { artSerializer } = await systems.request('artSerializer');

            return artSerializer.registerRule({
                name: manifest.name,
                priority: 20 /* <- TODO: [🍉] One place with priorities */,
                serialize({ value, serialize, next }) {
                    if (value === null) {
                        return next();
                    }

                    if (typeof value !== 'object') {
                        return next();
                    }

                    // TODO: Maybe next here AbstractArt instances

                    if (!('transform' in value && 'shape' in value && 'appearance' in value)) {
                        // TODO: DRY
                        // TODO: Also check the content of transform, shape and appearance - use typeguards for transform, shape, appearance
                        return next();
                    }

                    const { artId, transform, shape, appearance } = value;

                    // Note: [2] We are not using serialize for transform, shape and appearance because they are already pure objects
                    return { artId, /* !!x!! moduleName */ transform, shape, appearance };
                },
                deserialize({ serialized, deserialize, next }) {
                    if (serialized === null) {
                        return next();
                    }

                    if (typeof serialized !== 'object') {
                        return next();
                    }

                    // !!x!! Test moduleName

                    if (!('transform' in serialized && 'shape' in serialized && 'appearance' in serialized)) {
                        // TODO: DRY
                        // TODO: Also check the content of transform, shape and appearance - use typeguards for transform, shape, appearance
                        return next();
                    }

                    // Note: [2] We are not using deserialize for transform, shape and appearance because they are already pure objects
                    return createArt(serialized as any);
                },
            });
        },
    };

    return module;
}

/**
 * TODO: !!x Extract function createSerializerRuleForArt (or createSerializerRuleForFunctionalArt) (simmilar to createSerializerRuleForClass)
 * TODO: !!x [0] Get we get rid of serializeName and automatically infer it from module name
 * TODO: !!x Extend maker options from ICommonMakerOptions
 * TODO: !!x Unify naming of makers options
 * TODO: !!x Makers should have explicit options interface (use refactoring)
 */
