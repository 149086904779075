import { ISystems } from '../../../00-SystemsContainer/ISystems';
import { IInstantiable } from '../../../ArtSerializer/interfaces/IInstantiable';
import { createSerializerRuleForClass } from '../../../ArtSerializer/rules/createSerializerRuleForClass';
import { IModuleDefinition } from '../../interfaces/IModule';
import { IModuleManifest } from '../../interfaces/IModuleManifest/IModuleManifest';

/**
 * @@x
 * makeClassicalArtModule will make UI module
 *
 * Note: Module still needs to be declared
 * @deprecated please use makeFunctionalArtModule
 * @collboard-modules-sdk
 */
export function makeClassicalArtModule(
    artClass: IInstantiable & {
        /**
         * Name which is unique for each art
         * 💡 Tip: Best practise is to name it same as the class name without "Art" suffix
         */
        serializeName: string;
        manifest: IModuleManifest;
    },
): IModuleDefinition {
    // Note: Art modules are invisible, and so there is no need to create a massive manifest for them. Write everything into the corresponding tool module.
    // TODO: Probably check consistency between serializeName and module name> name: `${artSerializerRule.name}Art`

    const module = {
        manifest: {
            ...artClass.manifest,
            requirePermissions: ['view'],
            supports: {
                art: artClass.serializeName,
            },
        },

        async setup(systems: ISystems) {
            const { artSerializer } = await systems.request('artSerializer');

            return artSerializer.registerRule(
                createSerializerRuleForClass({
                    name: artClass.serializeName,
                    Class: artClass,
                }),
            );
        },
    };

    // TODO: Probbably? instead of artSerializerRule maybe should be pure class and the rure can contain itself
    return module;
}
