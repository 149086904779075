import { DetailedError } from '../../../40-utils/errors/DetailedError';
import { ISerializable } from '../interfaces/ISerializable';
import { IBaseSerializerErrorDetails } from './00-IBaseSerializerErrorDetails';

/**
 * @@x
 */
export interface ISerializationErrorDetails extends IBaseSerializerErrorDetails {
    /**
     * @@x
     */
    value: ISerializable;
}

/**
 * @@x
 */
export class SerializationError extends DetailedError<ISerializationErrorDetails> {
    public readonly name = 'SerializationError';

    public constructor(message: string, details: ISerializationErrorDetails) {
        super(message, details);
        Object.setPrototypeOf(this, SerializationError.prototype);
    }
}
