import styled from 'styled-components';
import { Color } from '../../../40-utils/color/Color';
import { ISkin } from '../../../50-systems/StyleSystem/ISkin';
import { IModalFooterProps } from './IModalFooterProps';

/**
 * @private Only used in this folder
 */
export const ModalFooterStyledDiv = styled.div<IModalFooterProps & { skin: ISkin }>`
    /*/
    outline: 1px dotted red; /**/

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    border-radius: 0 0 ${({ skin }) => skin.borderRadius}px ${({ skin }) => skin.borderRadius}px;

    padding: 5px 30px;
    overflow: hidden;

    @media /*[🍕]*/ only screen and (max-width: 850px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 15px;
    }

    ${({ isSequestered, skin }) =>
        !isSequestered
            ? `

    /* TODO: Also make transition on top */
    border-top: dotted 4px ${Color.from(skin.colors.light).opaque.toString()} /* <- TODO: [🚥] */;
    background-color: ${Color.from(skin.colors.white).opaque.toString()};


            `
            : `

    background-color: ${Color.from(skin.colors.primary).opaque.toString()};
    color: ${Color.from(skin.colors.white).opaque.toString()};
    font-size: 0.8em;

    ul {
        /*/
        outline: 1px dotted red; /**/

        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding-left: 0;
    }

    ul li {
        /*/
        outline: 1px dotted red;/**/
        list-style: none;
        margin: 0 10px;
    }

    /* TODO: Should be ul->li->a OR ul->a->li */
    ul li a {
        /*/
        outline: 1px dotted red; /**/
        display: inline;
        color: ${Color.from(skin.colors.white).opaque.toString()};
    }

    ul li.left {
        margin-left: auto;
    }


    `}
`;

/**
 * Note: I am here using styled components not global CSS
 * TODO: @roseckyj Do it with styled components across the repositiory
 */
