import React from 'react';
import spaceTrim from 'spacetrim';

/**
 * Puts inlined javascript into page inside <script> element
 *
 * Note: Script is spacetrimmed
 *
 * @collboard-modules-sdk
 */
export function ScriptForServerRender({ children }: { children: string }) {
    return (
        <script
            // TODO: Use here <InnerHtml component
            dangerouslySetInnerHTML={{
                __html: spaceTrim(
                    (block) => `
                      <!--
                      ${block(children)}
                      //-->
                    `,
                ),
            }}
        />
    );
}

/**
 * TODO: Use instead of every <script> in project
 * TODO: Src
 * TODO: isAsync, isDeferred,...
 */
