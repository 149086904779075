import React from 'react';
import { forAnimationFrame } from 'waitasecond';
import { Translate } from '../../50-systems/TranslationsSystem/components/Translate';
import { ITrayDynamicItemList } from './interfaces/ITrayDynamicItemTypes';

const BORDER = 5;

const ERROR_TEXT_CSS: React.CSSProperties /* <- TODO: Import and use just a CSSProperties */ = {
    stroke: 'none',
    strokeWidth: '0',
    fontSize: '10px',
    textAnchor: 'middle',
    lineHeight: '10px',
    fill: '#e60000',
};

export function RenderTrayItem(
    items: ITrayDynamicItemList,
    className: string | undefined,
    itemId: string,
    scale: number,
    color?: string,
) {
    const valid = !!items[itemId];
    color = color || (valid && items[itemId].defaultColor) || '#67d5f6';

    const gRef = React.createRef/* <- TODO: Import and use just a createRef */ <SVGGElement>();

    const refresh = (svg: SVGSVGElement | null) => {
        if (!svg || !gRef || !gRef.current) return;

        const bbox = svg.getBBox();
        const width = bbox.width + 2 * BORDER;
        const height = bbox.height + 2 * BORDER;

        svg.setAttribute('width', width + 'px');
        svg.setAttribute('height', height + 'px');

        svg.setAttribute('viewBox', bbox.x - BORDER + ' ' + (bbox.y - BORDER) + ' ' + width + ' ' + height);
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            ref={async (svg) => {
                refresh(svg);
                await forAnimationFrame(/* <- TODO: Describe why waiting forAnimationFrame and refreshing 2x OR just refresh once and remove await forAnimationFrame */);
                refresh(svg);
            }}
            {...{ className }}
        >
            <g
                transform={'translate(' + BORDER + ' ' + BORDER + ') scale(' + scale + ')'}
                style={{ fill: color }}
                strokeLinejoin="round"
                strokeLinecap="round"
                ref={gRef}
                className="trayArtWrapper"
            >
                {valid ? (
                    items[itemId].filters && items[itemId].filters!.length > 0 ? (
                        items[itemId].filters?.reduce((prev, filter) => filter(prev), items[itemId].content)
                    ) : (
                        items[itemId].content
                    )
                ) : (
                    <g>
                        <text x="0" y={18 * 0} style={{ ...ERROR_TEXT_CSS, fontWeight: 'bold' }}>
                            <Translate name="trayModule / error 1">Prostředí '{itemId}' neexistuje</Translate>

                            {itemId}
                        </text>
                        <text x="0" y={18 * 1} style={{ ...ERROR_TEXT_CSS, fontWeight: 'normal' }}>
                            <Translate name="trayModule / error 2">Prosím dejte nám o této chybě vědět</Translate>
                        </text>
                        <text x="0" y={18 + 13} style={{ ...ERROR_TEXT_CSS, fontWeight: 'normal' }}>
                            <Translate name="trayModule / error 3">na Facebooku, nebo na dev@h-edu.cz</Translate>
                        </text>
                        <text
                            x="0"
                            y={18 + 13 + 18}
                            style={{ ...ERROR_TEXT_CSS, fontWeight: 'normal', fontStyle: 'italic' }}
                        >
                            <Translate name="trayModule / error 4">Omlouváme se za problémy, tým H-edu</Translate>
                        </text>
                    </g>
                )}
            </g>
        </svg>
    );
}
