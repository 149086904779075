// TODO: This file is temporarly suspended - Allow to use IFlags<IModuleFlags> and transform format like ['hidden', 'disabled'] to {isHidden: true, isDisabled: true}'];
//       I know how to do it in runtime but I don't know how to do it in TypeScript
//       After globaly replace "flags: { isHidden: true }" to "flags: ['hidden']"

export type Flags<TFlags> = Partial<TFlags> | Array<keyof TFlags>;

export function flagsToObject<TFlags>(flags?: Flags<TFlags>): Partial<TFlags> {
    if (!flags) {
        return {};
    } else if (Array.isArray(flags)) {
        const flagsObject: any = {};
        for (const flag of flags) {
            flagsObject[flag] = true;
        }
        return flagsObject;
    } else {
        return flags;
    }
}
