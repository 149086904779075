import React from 'react';
import { TouchController } from 'touchcontroller';
import { forAnimationFrame } from 'waitasecond';
import { Icon } from '../../30-components/menu/Icon';
import { TranslationsSystem } from '../../50-systems/TranslationsSystem/0-TranslationsSystem';
import { Translate } from '../../50-systems/TranslationsSystem/components/Translate';
import { classNames } from '../classNames';
import { factor } from '../IFactory';
import { isAbsoluteUrl } from '../isAbsoluteUrl';
import { jsxToText } from '../jsx-html/jsxToText';
import { ITrayDynamicDefinition } from './interfaces/ITrayDynamicItemTypes';
import { TrayItemTemplate } from './TrayItemTemplate';

export interface ITrayToolbarProps {
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    onClose: () => void;
    setToolbarBodyRef?: (object: HTMLDivElement) => void;
    touchController: TouchController;
    setSelectedItemId: (newId: string | null) => void;
    trayDefinition: ITrayDynamicDefinition;
    translationsSystem: TranslationsSystem;
}

interface ITrayToolbarState {
    tab: number;
    /* TODO: [✨] Add is prefix */ open: boolean;
}

export class TrayToolbar extends React.Component/* <- TODO: Import and use just a Component */ <
    ITrayToolbarProps,
    ITrayToolbarState
> {
    state: ITrayToolbarState = {
        tab: 0,
        open: false,
    };

    componentDidMount() {
        this.startOpeningAnimation();
    }

    async startOpeningAnimation() {
        await forAnimationFrame(/* <- TODO: Describe why waiting forAnimationFrame */);
        this.setState({ open: true });
    }

    render() {
        return (
            <div
                className={classNames(
                    'trayToolbarWrapper',
                    this.state.open && 'trayToolbarWrapperOpen',
                    this.props.trayDefinition.className,
                )}
            >
                <div className="trayToolbarTabsBar">
                    <div className="trayToolbarTabs">
                        {factor(this.props.trayDefinition.getToolbarItems).map((group, index) => (
                            <div
                                key={index}
                                className={classNames(
                                    'trayToolbarTab',
                                    'trayToolbarTabNew',
                                    this.state.tab === index && 'trayToolbarTabActive',
                                )}
                                onClick={() => this.setState({ tab: index })}
                                title={jsxToText(this.props.translationsSystem.pickMessage(group.title))}
                            >
                                <div
                                    className="trayToolbarTabImage"
                                    style={{
                                        /* Note: [🕵️‍♀️] Border params are inline-duplicated to make them work */
                                        backgroundSize: `cover`,
                                        backgroundRepeat: `no-repeat`,
                                        backgroundPosition: `center center`,
                                        backgroundImage: `url('${
                                            isAbsoluteUrl(group.icon)
                                                ? group.icon
                                                : this.props.trayDefinition.imageFolder + '/categories/' + group.icon
                                        }')`,
                                    }}
                                >
                                    &nbsp;
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className={classNames('trayToolbarTab', 'trayToolbarTabNew')}>
                        <Icon
                            icon="no"
                            onClick={() =>
                                // Closing animation takes 100ms and then the module deactivates
                                this.setState({ open: false }, () => setTimeout(() => this.props.onClose(), 100))
                            }
                        />
                    </div>
                </div>
                <div
                    className="trayToolbarBody"
                    onMouseOver={this.props.onMouseOver}
                    onMouseOut={this.props.onMouseOut}
                    onPointerEnter={this.props.onMouseOver}
                    onPointerLeave={this.props.onMouseOut}
                    ref={(element) => {
                        if (!element) return;
                        element.scrollTo({ top: 0, left: 0 });
                        if (this.props.setToolbarBodyRef) {
                            this.props.setToolbarBodyRef(element);
                        }
                        try {
                            this.props.touchController.addElement(element);
                        } catch (error) {
                            // This is because of multiple calling this ref with same element
                        }
                    }}
                >
                    <div
                        className={classNames('trayToolbarMove')}
                        // Note: Using onPointerUp (not onClick) to prevent creating item under the arrow (instead of scrolling)
                        onPointerUp={(event) => {
                            event.stopPropagation();
                            const selfElement = event.target as HTMLDivElement;
                            const contentElement = selfElement.parentElement!;

                            // TODO: Show arrow on other side
                            // TODO: Do not show when no scroll needed

                            contentElement.scrollBy({
                                left: 0 /* <- LIB xyzt .toTopLeft() */,
                                top:
                                    window.innerWidth *
                                    0.33 /* <- TODO: TO some config */ /* <- LIB xyzt .toTopLeft() */,
                                behavior: 'smooth',
                            });
                        }}
                    >
                        <div className="arrow" />
                    </div>
                    <div className="trayToolbarContentWrapper">
                        {factor(this.props.trayDefinition.getToolbarItems)[this.state.tab].items.length > 0 ? (
                            factor(this.props.trayDefinition.getToolbarItems)[this.state.tab].items.map(
                                (group, index) => (
                                    <div className="trayToolbarGroup" key={index}>
                                        {group.itemIds.map((key, index2) => (
                                            <TrayItemTemplate
                                                key={index2}
                                                setSelectedItemId={this.props.setSelectedItemId}
                                                scale={
                                                    group.scale ||
                                                    factor(this.props.trayDefinition.getToolbarItems)[this.state.tab]
                                                        .scale
                                                }
                                                id={key}
                                                trayDefinition={this.props.trayDefinition}
                                            />
                                        ))}
                                        <div className="trayToolbarGroupTitle">{group.title}</div>
                                    </div>
                                ),
                            )
                        ) : (
                            <div className="trayToolbarWarning">
                                {
                                    <Translate name="trayModule / empty section">
                                        Zatím tu nic není, ale usilovně na tom pracujeme...
                                    </Translate>
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * TODO: [🏛️] Convert Class components to Functional (with hooks).
 */
