import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { CornerstoneArt } from '../../../71-arts/30-CornerstoneArt';
import { ExportArt } from '../../../71-arts/35-ExportArt';

internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/select-all-shortcut',
        deprecatedNames: ['@collboard/select-all-shortcut', 'SelectAllShortcut'],
        // TODO: [🏰]> requirePermissions: ['view'],
    },
    async setup(systems) {
        const { controlSystem, appState, materialArtVersioningSystem } = await systems.request(
            'controlSystem',
            'appState',
            'materialArtVersioningSystem',
        );
        return controlSystem.registerControl({
            defaultShortcut: ['Control', 'a'],
            executor: () => {
                appState.setSelection({
                    selected: materialArtVersioningSystem.artsPlaced.filter(
                        // TODO: This filter is a bit hack; make some util/category/method to find undeletable+unselectable arts on board
                        //       OR method selectAll
                        (art) => !(art instanceof CornerstoneArt || art instanceof ExportArt),
                    ),
                });
            },
        });
    },
}));
