import React from 'react';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Promisable } from 'type-fest';
import { CategorizedModal } from '../../../30-components/modal/categorized-modal/CategorizedModal';
import { CategorizedModalBody } from '../../../30-components/modal/categorized-modal/CategorizedModalBody';
import { CategorizedModalMenu } from '../../../30-components/modal/categorized-modal/CategorizedModalMenu';
import { ObservableContentComponent } from '../../../30-components/utils/ObservableContentComponent';
import { classNames } from '../../../40-utils/classNames';
import { factorWithSystems, IFactorableWithSystems } from '../../../40-utils/IFactoryWithSystems';
import { scrapeContentFromUrl } from '../../../40-utils/scrapeContentFromUrl';
import { string_translate_language, string_uri_part, string_url } from '../../../40-utils/typeAliases';
import { Translate } from '../../TranslationsSystem/components/Translate';
import { IModuleDefinition } from '../interfaces/IModule';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { createModalTitle, makeModalModule } from './makeModalModule';

/**
 * TODO: How to do article translations and flags in window top
 * TODO: Možná by tady nahoře mohlo být menu k dalším stránkám. ArticleSet?
 * TODO: Live reloaded content (like stats)
 * TODO: Scoped css
 * TODO: Namings URL vs SRC
 *
 * Note: If requirePermissions not set then will this maker set them to empty array
 * Note: There is in git history makeArticleModule but we have removed it. If it will be needed, we can bring it back.
 *
 * @collboard-modules-sdk
 */
export function makeArticlesSetModule(protoModule: {
    manifest: IModuleManifest;
    defaultArticle?: string_uri_part;
    articles: IFactorableWithSystems<
        Promisable<Record<string_uri_part, Partial<Record<string_translate_language, string_url>>>>
    >;
}): IModuleDefinition {
    const { manifest, defaultArticle, articles: articlesFactorable } = protoModule;

    return makeModalModule({
        manifest: manifest && {
            requirePermissions: [],
            ...manifest,
        },

        async createModal(systems) {
            const { routingSystem, translationsSystem } = await systems.request(
                'routingSystem',
                'translationsSystem',
                'styleSystem',
            );

            const articles = await factorWithSystems(articlesFactorable, systems);
            const articleNames = Object.keys(articles);

            const ArticlesMenu = ({ selectedArticleName }: { selectedArticleName?: string }) => (
                <CategorizedModalMenu>
                    <ul className="category-picker">
                        {/*
                        <a
                            href={routingSystem.path
                                .createLink(
                                    DEFAULT_ROUTE_PATH_PARAMS,
                                    // TODO: routingSystem shoud have some helper for home buttons
                                )
                                .toString()}
                            onClick={(event) => {
                                event.preventDefault();
                                routingSystem.navigateHome();
                            }}
                        >
                            <li>
                                <Translate name="makeArticlesSetModule / back">Back home</Translate>
                            </li>
                        </a>

                        <li className="category-separator" />
                        */}

                        {articleNames.map((articleName) => {
                            //  TODO: !! There should be similar helpers as in standart router libraries like <Link> <-Use here <Route> ...
                            const linkValue = { modulePath: `/${articleName}` };
                            return (
                                <a
                                    key={articleName}
                                    href={routingSystem.urlVariables
                                        .createLink({
                                            ...routingSystem.urlVariables
                                                .value /* TODO: LIB everstorage should be this automatical and consumer should not need to pass here current value */,
                                            ...linkValue,
                                        })
                                        .toString()}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        routingSystem.urlVariables.setValue(linkValue);
                                    }}
                                >
                                    <li
                                        className={classNames(
                                            articleName === selectedArticleName && 'category-selected',
                                        )}
                                    >
                                        <Translate
                                            name={`${manifest.name.replace(
                                                /^@collboard\/internal\//,
                                                '@collboard/',
                                            )} / Menu / ${articleName}`}
                                        >
                                            {articleName}
                                        </Translate>
                                    </li>
                                </a>
                            );
                        })}
                    </ul>
                </CategorizedModalMenu>
            );

            return {
                wrapModalManually: true,
                content: (
                    <CategorizedModal
                        title={<>{createModalTitle({ manifest, translationsSystem })}</>}
                        toggleModal={() => routingSystem.urlVariables.setValue({ moduleName: undefined })}
                        wide
                        fullSize
                    >
                        <ObservableContentComponent
                            alt="Articles set"
                            loader={
                                <>
                                    <ArticlesMenu />
                                    <CategorizedModalBody>
                                        <>
                                            Načítání...
                                            {/* TODO: @roseckyj Udělal bys prosím, nech je načítání v okně hezké (nepřekrývá titulek apod.),
                                                potom celý loading můžeme dát pryč za ten defaultní <Loader
                                            */}
                                        </>
                                    </CategorizedModalBody>
                                </>
                            }
                            content={routingSystem.urlVariables.values
                                .pipe(distinctUntilChanged((a, b) => a.modulePath === b.modulePath))
                                .pipe(
                                    map(async ({ modulePath }) => {
                                        const articleNameUri =
                                            modulePath && modulePath !== '/'
                                                ? modulePath
                                                : `/${defaultArticle || articleNames[0]!}`;
                                        const articleName = articleNameUri.replace(/^\//, '');
                                        const articleUrls = articles[articleName];

                                        if (!articleUrls) {
                                            return (
                                                <>
                                                    <ArticlesMenu />
                                                    <CategorizedModalBody>
                                                        <Translate name="makeArticlesSetModule / article not found">
                                                            Article not found, please choose from articles available on
                                                            left.
                                                        </Translate>
                                                    </CategorizedModalBody>
                                                </>
                                            );
                                        }
                                        const articleUrl = translationsSystem.pickStringMessage(articleUrls);

                                        return (
                                            <>
                                                <ArticlesMenu selectedArticleName={articleName} />
                                                <CategorizedModalBody>
                                                    <div
                                                        // TODO: Use here <InnerHtml component
                                                        dangerouslySetInnerHTML={{
                                                            __html: await scrapeContentFromUrl(articleUrl),
                                                        }}
                                                    />
                                                </CategorizedModalBody>
                                            </>
                                        );
                                    }),
                                )}
                        />
                    </CategorizedModal>
                ),
            };
        },
    });
}
