import React from 'react';
import { classNames } from '../../40-utils/classNames';

type IMenuWrapperProps =
    React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{
        position: 'left' | 'right' | 'top-ltr' | 'top-rtl' | 'bottom-ltr' | 'bottom-rtl';
    }>;

export function MenuWrapper(props: IMenuWrapperProps) {
    return <div className={classNames('menu-wrapper', props.position)}>{props.children}</div>;
}
