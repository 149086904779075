import { TranslationsSystem } from '../50-systems/TranslationsSystem/0-TranslationsSystem';
import { TITLE_SEPARATOR } from '../config-universal';

interface ICreateTitleArguments {
    // TODO: Use this style across aplication
    boardname?: string;
    translationsSystem: TranslationsSystem;
}

/**
 * Crete page title
 * @not-collboard-modules-sdk
 */
export function createTitle({ boardname, translationsSystem }: ICreateTitleArguments): string {
    boardname = (boardname || '').trim();
    if (boardname.length === 0) {
        return translationsSystem.translate('About/name', 'Collboard.com');
    } else {
        return boardname + TITLE_SEPARATOR + translationsSystem.translate('About/name', 'Collboard.com');
    }
}

/**
 * TODO: Some better system for this
 */
