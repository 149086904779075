import React from 'react';
import { DEFAULT_SKIN } from '../../50-systems/StyleSystem/defaultSkin';
import { ISkin } from '../../50-systems/StyleSystem/ISkin';
import { SkinContext } from '../../50-systems/StyleSystem/SkinContext';

/**
 * Use Collboard skin
 *
 * Note: It @return skin wrapped in object to be used like:
 *     > <FooElement {...useSkin()}
 *
 * @collboard-modules-sdk
 */
export function useSkin(): { skin: ISkin } {
    const skin = React.useContext(/* <- TODO: Import and use just a useContext */ SkinContext);

    if (!skin) {
        return { skin: DEFAULT_SKIN };
    }

    return { skin };
}
