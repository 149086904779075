/**
 *
 * @deprecated [🍿] Do not use enums but object as const OR 'LITERAL VALUES' instead
 */
export enum ModuleStatus {
    NotInstalled = 'NOT_INSTALLED',
    Installing = 'INSTALLING',
    Installed = 'INSTALLED',
    Uninstalling = 'UNINSTALLING',
}
