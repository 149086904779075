import React from 'react';
import { IVectorData, Vector } from 'xyzt';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { Abstract2dArt } from '../../71-arts/26-Abstract2dArt';

/**
 *
 * Note: In future this file will we in independent repository as external module.
 *
 */
internalModules.declareModule(() => makeArtModule(DiceArt));

export class DiceArt extends Abstract2dArt {
    public static serializeName = 'Dice';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/dice-art',
        deprecatedNames: '@collboard/dice-art',
    };

    public value: number;
    public rotation: number;

    /**
     * @deprecated [🍈] name privateSize is confusing, use something instead like size (=privateSize) vs. originalSize
     */
    private privateSize: IVectorData = new Vector(100, 100);

    public constructor(
        shift: IVectorData /* TODO: Should be shift set in the constructor or somehow else (by setShift method)? */,
        public sides: number,
    ) {
        super();
        this.shift = shift;
        this.value = this.random();
        this.rotation = Math.random(/* <- TODO: [🐉] Probbably use seed random */) * 20 - 10;
    }

    random() {
        return Math.floor(Math.random(/* <- TODO: [🐉] Probbably use seed random */) * this.sides) + 1;
    }

    roll() {
        this.value = this.random();
        this.rotation = (this.rotation < 0 ? +1 : -1) * Math.random(/* <- TODO: [🐉] Probbably use seed random */) * 10;
    }

    // Hack to control via floating menu
    set diceControls(operation: number) {
        switch (operation) {
            case 0: // Roll
                this.roll();
                return;
        }
    }
    get diceControls(): number {
        return 0;
    }

    set diceSides(sides: number) {
        this.sides = sides;
        this.value = this.random();
    }

    get diceSides(): number {
        return this.sides;
    }

    public get acceptedAttributes() {
        return ['diceControls', 'diceSides'];
    }
    public get topLeft() /* TODO: This should be done by BoundingBox from LIB xyzt */ {
        return this.shift;
    }
    public get bottomRight() /* TODO: This should be done by BoundingBox from LIB xyzt */ {
        return Vector.add(this.shift, this.privateSize);
    }

    private renderDots() {
        const sides: { [key: number]: Array<number> } = {
            1: [0, 0, 0, 0, 1, 0, 0, 0, 0],
            2: [1, 0, 0, 0, 0, 0, 0, 0, 1],
            3: [1, 0, 0, 0, 1, 0, 0, 0, 1],
            4: [1, 0, 1, 0, 0, 0, 1, 0, 1],
            5: [1, 0, 1, 0, 1, 0, 1, 0, 1],
            6: [1, 0, 1, 1, 0, 1, 1, 0, 1],
            7: [1, 0, 1, 1, 1, 1, 1, 0, 1],
            8: [1, 1, 1, 1, 0, 1, 1, 1, 1],
            9: [1, 1, 1, 1, 1, 1, 1, 1, 1],
        };
        const padding = 25;

        const result: Array<JSX.Element> = [];

        let i = 0;
        for (let y = 0; y < 3; y++)
            for (let x = 0; x < 3; x++) {
                if (sides[this.value] && sides[this.value][i] === 1) {
                    result.push(
                        <div
                            className="diceDot"
                            style={{
                                left: (x / 2.0) * (100 - 2 * padding) + padding + '%' /* <- LIB xyzt .toTopLeft() */,
                                top: (y / 2.0) * (100 - 2 * padding) + padding + '%' /* <- LIB xyzt .toTopLeft() */,
                            }}
                        />,
                    );
                }

                i++;
            }

        return result;
    }

    render() {
        return (
            <div
                className="block"
                style={{
                    width: this.privateSize.x || 0,
                    height: this.privateSize.y || 0,
                    position: 'absolute',
                    left: this.shift.x || 0 /* <- LIB xyzt .toTopLeft() */,
                    top: this.shift.y || 0 /* <- LIB xyzt .toTopLeft() */,
                    transform: 'rotate(' + this.rotation + 'deg)',
                }}
            >
                <div className="dice">{this.sides <= 9 ? this.renderDots() : this.value}</div>
            </div>
        );
    }
}
