/**
 * Remove given keys from the recieved options array.
 * Note: It does not mutate recieved object
 *
 * @param options
 * @param keys
 * @returns stripped object
 *
 * @collboard-modules-sdk
 */
export function removeKeys<TObject, TRemove extends keyof TObject>(
    options: TObject,
    keys: Array<TRemove>,
): Omit<TObject, TRemove> {
    const newOptions = { ...options };
    for (const key of keys) {
        delete newOptions[key];
    }
    return newOptions;
}
