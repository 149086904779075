/*
 * Generated type guards for "IMaterial.ts".
 * WARNING: Do not manually change this file.
 */

import { IFullPhongMaterial, IMaterial, IPhongMaterial } from './IMaterial';
import { isIColorTexture, isITexture, isITextureTexture } from './ITexture.guard';

/* tslint:disable */
/* eslint-disable */

export function isIMaterial(obj: unknown): obj is IMaterial {
    const typedObj = obj as any;
    return (
        (isIColorTexture(typedObj) as boolean) ||
        (isITextureTexture(typedObj) as boolean) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isITexture(typedObj['emissiveTexture']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['diffuseTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['diffuseTexture']) as boolean) ||
                (isITextureTexture(typedObj['diffuseTexture']) as boolean)) &&
            (typeof typedObj['specularTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['specularTexture']) as boolean) ||
                (isITextureTexture(typedObj['specularTexture']) as boolean)) &&
            (typeof typedObj['ambientTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['ambientTexture']) as boolean) ||
                (isITextureTexture(typedObj['ambientTexture']) as boolean)) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isITexture(typedObj['diffuseTexture']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['emissiveTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['emissiveTexture']) as boolean) ||
                (isITextureTexture(typedObj['emissiveTexture']) as boolean)) &&
            (typeof typedObj['specularTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['specularTexture']) as boolean) ||
                (isITextureTexture(typedObj['specularTexture']) as boolean)) &&
            (typeof typedObj['ambientTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['ambientTexture']) as boolean) ||
                (isITextureTexture(typedObj['ambientTexture']) as boolean)) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isITexture(typedObj['specularTexture']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['emissiveTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['emissiveTexture']) as boolean) ||
                (isITextureTexture(typedObj['emissiveTexture']) as boolean)) &&
            (typeof typedObj['diffuseTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['diffuseTexture']) as boolean) ||
                (isITextureTexture(typedObj['diffuseTexture']) as boolean)) &&
            (typeof typedObj['ambientTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['ambientTexture']) as boolean) ||
                (isITextureTexture(typedObj['ambientTexture']) as boolean)) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isITexture(typedObj['ambientTexture']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['emissiveTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['emissiveTexture']) as boolean) ||
                (isITextureTexture(typedObj['emissiveTexture']) as boolean)) &&
            (typeof typedObj['diffuseTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['diffuseTexture']) as boolean) ||
                (isITextureTexture(typedObj['diffuseTexture']) as boolean)) &&
            (typeof typedObj['specularTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['specularTexture']) as boolean) ||
                (isITextureTexture(typedObj['specularTexture']) as boolean)) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function'))
    );
}

export function isIPhongMaterial(obj: unknown): obj is IPhongMaterial {
    const typedObj = obj as IPhongMaterial;
    return (
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isITexture(typedObj['emissiveTexture']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['diffuseTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['diffuseTexture']) as boolean) ||
                (isITextureTexture(typedObj['diffuseTexture']) as boolean)) &&
            (typeof typedObj['specularTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['specularTexture']) as boolean) ||
                (isITextureTexture(typedObj['specularTexture']) as boolean)) &&
            (typeof typedObj['ambientTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['ambientTexture']) as boolean) ||
                (isITextureTexture(typedObj['ambientTexture']) as boolean)) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isITexture(typedObj['diffuseTexture']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['emissiveTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['emissiveTexture']) as boolean) ||
                (isITextureTexture(typedObj['emissiveTexture']) as boolean)) &&
            (typeof typedObj['specularTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['specularTexture']) as boolean) ||
                (isITextureTexture(typedObj['specularTexture']) as boolean)) &&
            (typeof typedObj['ambientTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['ambientTexture']) as boolean) ||
                (isITextureTexture(typedObj['ambientTexture']) as boolean)) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isITexture(typedObj['specularTexture']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['emissiveTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['emissiveTexture']) as boolean) ||
                (isITextureTexture(typedObj['emissiveTexture']) as boolean)) &&
            (typeof typedObj['diffuseTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['diffuseTexture']) as boolean) ||
                (isITextureTexture(typedObj['diffuseTexture']) as boolean)) &&
            (typeof typedObj['ambientTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['ambientTexture']) as boolean) ||
                (isITextureTexture(typedObj['ambientTexture']) as boolean)) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function')) ||
        (((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (isITexture(typedObj['ambientTexture']) as boolean) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
            (typeof typedObj['emissiveTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['emissiveTexture']) as boolean) ||
                (isITextureTexture(typedObj['emissiveTexture']) as boolean)) &&
            (typeof typedObj['diffuseTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['diffuseTexture']) as boolean) ||
                (isITextureTexture(typedObj['diffuseTexture']) as boolean)) &&
            (typeof typedObj['specularTexture'] === 'undefined' ||
                (isIColorTexture(typedObj['specularTexture']) as boolean) ||
                (isITextureTexture(typedObj['specularTexture']) as boolean)) &&
            ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function'))
    );
}

export function isIFullPhongMaterial(obj: unknown): obj is IFullPhongMaterial {
    const typedObj = obj as IFullPhongMaterial;
    return (
        ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
        (isITexture(typedObj['emissiveTexture']) as boolean) &&
        (isITexture(typedObj['diffuseTexture']) as boolean) &&
        (isITexture(typedObj['specularTexture']) as boolean) &&
        (isITexture(typedObj['ambientTexture']) as boolean)
    );
}
