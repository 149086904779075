import { ConsoleGroupSubLogger } from './ConsoleGroupSubLogger';
import { ConsolePrefixLogger } from './ConsolePrefixLogger';
import { ILogger, ISubLogger } from './ILogger';

export class ConsoleLogger implements ILogger {
    public static readonly console = new ConsoleLogger(console);

    public constructor(private readonly console: Console) {}

    public info(message?: any, ...optionalParams: Array<any>): this {
        this.console.info(message, ...optionalParams);
        return this;
    }
    public warn(warning?: any, ...optionalParams: Array<any>): this {
        this.console.warn(warning, ...optionalParams);
        return this;
    }
    public error(error?: Error | any, ...optionalParams: Array<any>): this {
        this.console.error(error, ...optionalParams);
        return this;
    }

    public trace(...data: Array<any>): this {
        this.console.trace(...data);
        return this;
    }

    public group(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        return new ConsoleGroupSubLogger(this.console, false, groupName, ...optionalParams);
    }

    public groupCollapsed(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        return new ConsoleGroupSubLogger(this.console, true, groupName, ...optionalParams);
    }

    /**
     *
     * @deprecated Better to use standard group
     */
    public groupPrefixed(groupName?: string, ...optionalParams: Array<any>): ConsolePrefixLogger {
        return new ConsolePrefixLogger(this.console, groupName, ...optionalParams);
    }
}

/**
 * TODO: Probably split into multiple files
 * TODO: Nicer begin / end messages
 * TODO: Better Graphics of the tree:
 *       > console.log('%c Oh my heavens! ', 'border-left: 30px solid red');console.log('%c Oh my heavens! ', 'border-left: 30px solid red');
 * TODO: Multiple layers
 *       > const gg = g.groupPrefixed();
 */

/*/
const g1 = ConsoleLogger.console.groupPrefixed();
g1.info(`a`);
g1.info(`b`);

const g2 = ConsoleLogger.console.groupPrefixed();
g2.info(`a`);

g1.info(`c`);
g1.end();

g2.info(`b`);
g2.info(`c`);

const g2a = g2.groupPrefixed();
g2a.info(`d`);

/**/
