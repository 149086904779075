import spaceTrim from 'spacetrim';
import { shorten } from '../shorten';
import { string_data_url } from '../typeAliases';
import { isValidDataurl } from './isValidDataurl';

/**
 * Convert string data url to blob
 *
 * Tip: If you want to have more versatile tool look at fetchAsFile which in dataurl cases uses internally this function dataurlToBlob
 *
 * @collboard-modules-sdk
 */
export async function dataurlToBlob(dataurl: string_data_url): Promise<Blob> {
    if (!isValidDataurl(dataurl)) {
        throw new Error(
            spaceTrim(`
                Can not convert invalid dataurl to blob

                Invalid dataurl:
                ${shorten(dataurl, 100)}
            `),
        );
    }

    const { headRaw, data } = dataurl.match(/^(?<headRaw>.*?),(?<data>.*)$/)?.groups!;
    const head = headRaw.split(';');
    const type = head[0]!.split(':')[1]!;
    const isBase64Encoded = head.some((headPart) => headPart === 'base64');

    if (!isBase64Encoded) {
        return new Blob([decodeURIComponent(data)], { type });
    }

    const byteString = atob(data);

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type });
}
