import { CornerstoneArt } from '../../../71-arts/30-CornerstoneArt';
import { IDependenciesRecord } from '../interfaces/IDependencies';

export function createDependenciesFromCornerstone(
    cornerstoneArt: CornerstoneArt,
    priority: number,
): IDependenciesRecord {
    const dependencies: IDependenciesRecord = {};

    for (const name of cornerstoneArt.modulesActive || []) {
        dependencies[name] = { priority };
    }

    for (const name of cornerstoneArt.modulesInactive || []) {
        dependencies[name] = { priority, instances: 0 };
    }

    return dependencies;
}
