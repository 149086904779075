import { IDestroyable } from 'destroyable';
import { forImmediate } from 'waitasecond';
import { forUnpredictedTime } from '../../00-lib/waitasecond/forUnpredictedTime';
import { errorMessageWithAdditional } from '../../40-utils/errors/errorMessageWithAdditional';
import { ThrottleQueue } from '../../40-utils/tasks/ThrottleQueue';
import { string_license_token } from '../../40-utils/typeAliases';
import { consolex } from '../../consolex';
import { IDependenciesRecord } from '../ModuleStore/interfaces/IDependencies';
import { ISyncer } from '../ModuleStore/interfaces/ISyncer';
import { AbstractSyncer } from '../ModuleStore/Syncers/AbstractSyncer';
import { dependenciesSetToDependenciesRecord } from '../ModuleStore/utils/dependenciesSetToDependenciesRecord';

/**
 * LicenseSyncer installs / uninstalls modules according to (payed) license which has flag isModuleAutoInstalled
 *
 * @private
 * @collboard-system
 */
export class LicenseSyncer extends AbstractSyncer implements ISyncer, IDestroyable {
    private queue = new ThrottleQueue({ throttler: forImmediate /* <- TODO: Maybe use here forMoment */ });

    public async syncSupportForLicenses(...usageLicensesTokens: Array<string_license_token>) {
        return this.queue.task(async () => {
            await forUnpredictedTime();

            const manifests = await usageLicensesTokens
                .mapAsync(async (usageLicenseToken) => {
                    const search = {
                        usageLicenseToken,
                    };
                    const { manifests: licenseManifests } = await (await this.systems.moduleStore).search(search);

                    if (!licenseManifests.length) {
                        consolex.warn(
                            errorMessageWithAdditional(
                                `LicenseSyncer can not find module that add support for ${usageLicenseToken}`,
                                {
                                    moduleStore: await this.systems.moduleStore,
                                    search,
                                },
                            ),
                        );
                    }

                    return licenseManifests;
                })
                .then((manifestsToFlat) => manifestsToFlat.flat());

            const dependencies: IDependenciesRecord = dependenciesSetToDependenciesRecord(manifests);
            await this.sync(dependencies);
        });
    }

    public async destroy() {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        await this.queue.destroy();
    }
}

/**
 * Note: [☠️] LicenseSyncer is not in syncers folder but in its own specific place
 */
