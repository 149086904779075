import React from 'react';
import { ISystems } from '../50-systems/00-SystemsContainer/ISystems';
import { NOT_CONSTRUCTABLE } from './IFactory';

/**
 * @collboard-modules-sdk
 */
export type IFactorableWithSystems<TValue> =
    | TValue
    | ((systems: ISystems /* <- TODO: Can be this done as generic in just IFactorable */) => TValue);

/**
 *
 * @collboard-modules-sdk
 */
export function factorWithSystems<TValue>(valueOrFunction: IFactorableWithSystems<TValue>, systems: ISystems): TValue {
    if (
        typeof valueOrFunction === 'function' &&
        !React.isValidElement(/* <- TODO: Import and use just a isValidElement */ valueOrFunction) &&
        !(valueOrFunction as any)[NOT_CONSTRUCTABLE]
    ) {
        // TODO: Probably cache factory
        return (valueOrFunction as any)(systems);
    } else {
        return valueOrFunction as TValue;
    }
}

/**
 * TODO: Use IFactorableWithSystems + factorWithSystems across the code
 * TODO: Probably better name as Create/Creatable/factor
 */
