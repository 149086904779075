import spaceTrim from 'spacetrim';
import { sortArtContainersByScreenPosition } from '../../../50-systems/ExportSystem/utils/sortArtContainersByScreenPosition';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';

internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/text-export',
        deprecatedNames: '@collboard/text-export',
        requirePermissions: ['view'],
    },
    async setup(systems) {
        const { exportSystem } = await systems.request('exportSystem');

        // TODO: This exporter is not implemented because of an error in browser "DOMException: Support for multiple ClipboardItems is not implemented.". If this solved, implement it.
        return exportSystem.registerFileSupport({
            priority: 0,
            mimeType: 'text/plain',
            isHeavy: false,
            async exportFile({
                artContainers,
                // Note: quality doesn't matter here
                // Note: scale doesn't matter here
                // Note: isMaterialized doesn't matter here
                // Note: isLinked doesn't matter here
                // Note: isTransparent doesn't matter here
                isTesting /* <- TODO: Use */,
            }) {
                return (
                    sortArtContainersByScreenPosition(
                        // TODO: [🐽] What is the best sorting for Text export
                        artContainers,
                    )
                        .map(({ element }) => element?.innerText || '')
                        .map((row) => spaceTrim(row))
                        .filter((row) => row !== '')
                        .join('\n') || null
                );
            },
        });
    },
}));
