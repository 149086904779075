import React from 'react';
import { flagsToObject } from '../../../../../40-utils/flagsToObject';
import { useSkin } from '../../../../../40-utils/react-hooks/useSkin';
import { useSystems } from '../../../../../40-utils/react-hooks/useSystems';
import { IModuleManifest } from '../../../../../50-systems/ModuleStore/interfaces/IModuleManifest/IModuleManifest';
import { isExperimental } from '../../../../../50-systems/ModuleStore/utils/isExperimental';
import { isPresented } from '../../../../../50-systems/ModuleStore/utils/isPresented';
import { Translate } from '../../../../../50-systems/TranslationsSystem/components/Translate';
import { parseModuleAuthors } from '../utils/parseModuleAuthors';
import { ModuleStoreStyle } from './ModuleStoreStyle';

interface IIModuleProfileComponentProps {
    manifest: IModuleManifest;
}

export function ModuleProfileComponent({ manifest }: IIModuleProfileComponentProps) {
    const { translationsSystem } = useSystems('translationsSystem');
    const { skin } = useSkin();

    return (
        <ModuleStoreStyle {...{ skin }}>
            <div
                className="image"
                style={{
                    backgroundImage: !(manifest.icon !== 'TODO:' && (manifest.icon || '').length > 3)
                        ? 'none'
                        : 'url("' + manifest.icon /*TODO: There will be url of emoji that is not loaded*/ + '")',
                }}
            >
                {(manifest.icon || '').length <= 3 && manifest.icon !== '' && <>{manifest.icon}</>}
            </div>

            <h2>{translationsSystem.pickStringMessage(manifest.title || manifest.name)}</h2>

            <h3>
                <Translate name="ModuleStoreToolbarIcon/ module by">Od</Translate>
                {parseModuleAuthors(manifest).map(({ name, email, url }, i) => (
                    <React.Fragment /* <- TODO: Import and use just a Fragment */ key={name || i}>
                        {i === 0 ? ' ' : ', '}
                        <span
                            onClick={() => {
                                /* TODO: In future here will be triggered a search of authors modules */
                            }}
                        >
                            {name || (
                                <Translate name="ModuleStoreToolbarIcon/ module by / anonymous">
                                    neznámého autora
                                </Translate>
                            )}
                        </span>
                    </React.Fragment /* <- TODO: Import and use just a Fragment */>
                ))}
            </h3>

            <div className="description">
                {manifest.description && (
                    <div className="desc-content">{translationsSystem.pickStringMessage(manifest.description)}</div>
                )}

                {isExperimental(manifest) && (
                    <div className="warning">
                        <Translate name="ModuleStoreToolbarIcon/ Experimental / text">Experimentální modul</Translate>
                    </div>
                )}

                {!isPresented(manifest) && (
                    <div className="warning">
                        <Translate name="ModuleStoreToolbarIcon/ not presented / text">Skrytý modul</Translate>
                    </div>
                )}

                {flagsToObject(manifest.flags).isDeprecated && (
                    <div className="warning">
                        <Translate name="ModuleStoreToolbarIcon/ Deprecated / text">Zastaralý modul</Translate>
                    </div>
                )}

                <pre>{JSON.stringify(manifest, null, 4)}</pre>
            </div>
        </ModuleStoreStyle>
    );
}

/**
 * TODO: @hejny ->  @roseckyj Design this component - profile page of the module
 * TODO: Probbably allow to install/uninstall/change setting from here
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
