import { ISystemsExtended } from '../00-SystemsContainer/ISystems';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { BusinessModuleConfiguration } from './configuration/BusinessModuleConfiguration';
import { BusinessName } from './configuration/BusinessName';
import { IBusinessConfiguration } from './interfaces/IBusinessConfiguration';

/**
 * BusinessSystem tell things about currently used business
 *
 * @private
 * @collboard-system
 */
export class BusinessSystem extends AbstractSystem {
    public readonly businessName: Promise<BusinessName>;
    public readonly businessConfiguration: Promise<IBusinessConfiguration>;

    constructor(systems: ISystemsExtended) {
        super(systems);

        this.businessName = new Promise(async (resolve) => {
            /**
             * Note: This is a bit dirty trick to ensure that real business from domain name will be loaded not the default one from DEFAULT_URL_VARIABLES
             */
            let isInitial = true;
            (await this.systems.routingSystem).urlVariables.values.subscribe((value) => {
                if (isInitial) {
                    isInitial = false;
                    return;
                }
                resolve(value.businessName);
            });
        });

        this.businessConfiguration = this.businessName.then((businessName) => {
            return BusinessModuleConfiguration[businessName];
        });
    }

    protected async init() {}
}
