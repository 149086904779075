import { Destroyable, IDestroyable } from 'destroyable';
import { Promisable } from 'type-fest';
import { ITaskRunner } from './ITaskRunner';
/**
 * @collboard-modules-sdk
 */
export interface IThrottleQueueOptions {
    throttler: () => Promise<void>;

    // TODO: [0] Probbably crate a debounce throttleQueue or make it as param here
}

/**
 * ThrottleQueue ensures that in every time block separated by throttler will be runned only one task (function run)
 *
 * Imagine timeline of task runs:
 *
 * Input:
 * __🥝🍓🍋_____🍏🍍🍇__🍉_🍌🍒___🥑🍎🍈
 *
 * Output:
 * _________🍋🍋🍋_________🍇🍇🍇_🍉_🍒🍒_🍈🍈🍈
 *
 * TODO: Error handling
 * TODO: Probably debouncing
 * TODO: timeouts
 *
 * @collboard-modules-sdk
 */
export class ThrottleQueue<TTaskResult> extends Destroyable implements ITaskRunner<TTaskResult>, IDestroyable {
    private runner: () => Promisable<TTaskResult>;
    private throttles: Array<Promise<void>> = [];
    private result: Promise<TTaskResult> | null = null;

    public constructor(private options: IThrottleQueueOptions) {
        super();
    }

    public async task(runner: () => Promisable<TTaskResult>): Promise<TTaskResult> {
        this.runner = runner;
        this.throttles.push(this.options.throttler());

        if (!this.result) {
            this.result = new Promise(async (resolve) => {
                let throttlesLength = -1;
                while (throttlesLength !== this.throttles.length) {
                    throttlesLength = this.throttles.length;
                    await Promise.all(this.throttles);
                }

                this.result = null;
                this.throttles = [];

                if (this.isDestroyed) {
                    // TODO: Probably destroy throttleQueue more elegantly
                    return;
                }
                resolve(this.runner());
            });
        }

        return this.result;
    }
}

/**
 * TODO: Handle errors same as in Queue
 * TODO: [0] This should be maybe named DebounceQueue?
 */
