import { ILogger, ISubLogger } from '../ILogger';

export function isLogger(logger: ILogger | ISubLogger): logger is ILogger {
    return typeof (logger as any).group === 'function';
}

/**
 * TODO: [🥟] Autogenerate suffix as .guard.ts
 * TODO: [🥟] This should be propper typeguard not just testing one property
 * TODO: [🥟] All typeguards should recieve unknown
 */
