import { forTime } from 'waitasecond';
import { ISubLogger } from './ILogger';
import { IRecordLoggerLog, RecordLogger } from './RecordLogger';

export class ConsoleGroupSubLogger extends RecordLogger implements ISubLogger {
    private readonly optionalParams: Array<any>;
    public constructor(
        private readonly console: Console,
        private readonly collapsed: boolean,
        private readonly groupName?: string,
        ...optionalParams: Array<any>
    ) {
        super();
        this.optionalParams = optionalParams;

        (async () => {
            await forTime(1000 * 15 /* as a timeout for closing a sublogger */);
            if (this.isEnded) {
                return;
            }
            this.output({
                type: 'WARN',
                message: 'This was not ended in 15s timeout, so consider this as unfinished',
                optionalParams: [],
            });
        })();
    }

    public appear() {
        super.end();
        this.output({
            type: 'INFO',
            message: 'This sublogger is not finished yet, it just appeared in progress',
            optionalParams: [],
        });
        return this;
    }

    public end() {
        super.end();
        this.output();
    }

    private output(...additionalStructuredLogs: Array<IRecordLoggerLog>) {
        if (!this.collapsed) {
            this.console.group(this.groupName, ...this.optionalParams);
        } else {
            this.console.groupCollapsed(this.groupName, ...this.optionalParams);
        }

        for (const { type, message, optionalParams } of [...this.logsStructured, ...additionalStructuredLogs]) {
            switch (type) {
                case 'INFO':
                    this.console.info(message, ...optionalParams);
                    break;
                case 'WARN':
                    this.console.warn(message, ...optionalParams);
                    break;
                case 'ERROR':
                    this.console.error(message, ...optionalParams);
                    break;
            }
        }
        this.console.groupEnd();
    }
}

/**
 * TODO: In constructor optionalParams probbably does not make sense BUT instead of it configure timeout, style,...
 */
