import { HighOrderError } from './HighOrderError';

export class ModuleDeclarationError extends HighOrderError {
    public readonly name = 'ModuleDeclarationError';
    public constructor(lowOrderError: Error, highOrderExplanation: string) {
        super(lowOrderError, highOrderExplanation);
        Object.setPrototypeOf(this, ModuleDeclarationError.prototype);
    }
}

export class ModuleDeclarationMissingManifestError extends Error {
    public readonly name = 'ModuleDeclarationMissingManifestError';
    public constructor() {
        super(
            `Cannot declare module without defined manifest. Modules without manifest (anonymous modules) are typically used as submodules for example as activated tool.`,
        );
        Object.setPrototypeOf(this, ModuleDeclarationMissingManifestError.prototype);
    }
}
