import { registerItemsInSubjectOfArrays, Registration } from 'destroyable';
import React from 'react';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SetOptional } from 'type-fest';
import { ErrorBoundary } from '../../30-components/utils/ErrorBoundary';
import { ObservableContentComponent } from '../../30-components/utils/ObservableContentComponent';
import { compareJsxs } from '../../40-utils/jsx-html/compareJsxs';
import { randomUuid } from '../../40-utils/randomUuid';
import { SystemsContainerContext } from '../../40-utils/react-hooks/useSystems';
import { uuid } from '../../40-utils/typeAliases';
import { ISystems } from '../00-SystemsContainer/ISystems';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { UserInterfaceElementPlace } from './UserInterfaceElementPlace';

// TODO:Remove IRegisterElementOptions and use ONLY IRegisterElementOptionsItem

interface IElementOptions {
    /**
     * Where to place the extra element
     */
    place: UserInterfaceElementPlace;

    /**
     * The JSX element
     */
    element: JSX.Element;

    /**
     * The order rendering of the element
     * Note: This is not z-index ONLY order for ordering
     *
     * @default 0
     */
    order: number;

    /**
     * Systems container from the registrar module
     */
    systems: ISystems;
}

/**
 * UserInterfaceSystem can register and manage additional JSX
 *
 * Note: Using UserInterfaceSystem is not recommended to use directly because it is using very low-level API. Consider using higher-level API like ToolbarSystem, NotificationSystem, etc.
 * Note: UserInterfaceSystem is for JSX (HTML) vs. StyleSystem is for CSS styles
 *
 * @collboard-system
 */
export class UserInterfaceSystem extends AbstractSystem {
    private places: Partial<
        Record<UserInterfaceElementPlace, BehaviorSubject<Array<IElementOptions & { nonce: uuid }>>>
    > = {};

    protected async init() {}

    /**
     * Register element that will be included in UI
     *
     * Note: Consider using higher-level API
     */
    public registerElement({ place, order, element, systems }: SetOptional<IElementOptions, 'order'>): Registration {
        const nonce = randomUuid(); /* TODO: More lightweight  */
        order = order || 0;
        return registerItemsInSubjectOfArrays({
            currentValue: this.getPlaceStorage(place).value,
            base: this.getPlaceStorage(place),
            add: [{ place, order, element, nonce, systems }],
            compare: ({ nonce: a }, { nonce: b }) => a === b,
        });
    }

    public render(place: UserInterfaceElementPlace): JSX.Element {
        // TODO: React.ReactNode/* <- TODO: Import and use just a ReactNode */ vs JSX.Element (in all calses across the repositiory)

        const placeStorage = this.getPlaceStorage(place);

        return (
            <ObservableContentComponent
                alt="Extra JSX"
                loader={<>{/* TODO: Here should be some propper loading*/}</>}
                content={placeStorage.pipe(
                    map((userInterfaceElement) => (
                        <>
                            {userInterfaceElement
                                .sort((a, b) => {
                                    // TODO: !! Same mechanism od deterministic comparision in toolbar
                                    if (a.order > b.order) {
                                        return 1;
                                    } else if (a.order < b.order) {
                                        return -1;
                                    } else {
                                        return compareJsxs(a.element, b.element);
                                    }
                                })
                                .map(({ element: jsx, nonce, systems }, i) => (
                                    <React.Fragment /* <- TODO: Import and use just a Fragment */ key={nonce}>
                                        <SystemsContainerContext.Provider value={systems}>
                                            <ErrorBoundary>{jsx}</ErrorBoundary>
                                        </SystemsContainerContext.Provider>
                                    </React.Fragment /* <- TODO: Import and use just a Fragment */>
                                ))}
                        </>
                    )),
                )}
            />
        );
    }

    private getPlaceStorage(
        place: UserInterfaceElementPlace,
    ): BehaviorSubject<Array<IElementOptions & { nonce: uuid }>> {
        if (!this.places[place]) {
            this.places[place] = new BehaviorSubject<Array<IElementOptions & { nonce: uuid }>>([]);
        }
        return this.places[place]!;
    }
}

/**
 * TODO: Fix doubeling of toolbars or user interface content
 * TODO: Probbably (require OR used sign OR extract from given systems) module signature when registering
 *       ! Warning: render method is used by DIFFERENT module than the one that registered it
 */
