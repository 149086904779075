import React from 'react';
import { map } from 'rxjs/operators';
import { ObservableContentComponent } from '../../30-components/utils/ObservableContentComponent';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../50-systems/ModuleStore/makers/makeModalModule';
import { consolex } from '../../consolex';
import { ModulesCatalogueComponent } from '../ui/basic/ModuleStore/components/10-ModulesCatalogueComponent';
import { ModuleBoxComponent } from '../ui/basic/ModuleStore/components/15-ModuleBoxComponent';
import { COLLDEV_SYNCER_STYLE } from './COLLDEV_SYNCER_STYLE';
import { colldevSyncerPromise } from './DevelopmentColldevModule';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            flags: { isHidden: true },
            name: 'DevelopmentColldevModal',
            title: `Module development`,
            requirePermissions: [],
        },
        async createModal(systems) {
            const { translationsSystem, businessSystem, licenseSystem } = await systems.request(
                'translationsSystem',
                'businessSystem',
                'licenseSystem',
            );
            const colldevSyncer = await colldevSyncerPromise;

            return (
                <>
                    <label>
                        Colldev URL :&nbsp;
                        <input
                            type="text"
                            defaultValue={colldevSyncer.colldevUrl}
                            onChange={(event) => {
                                colldevSyncer!.colldevUrl = event.target.value;
                            }}
                        />
                    </label>

                    <ObservableContentComponent
                        alt="Modules in colldevSyncer"
                        content={colldevSyncer
                            .observeAllModules()
                            .pipe(
                                map((modules) => {
                                    consolex.groupCollapsed(`%c🧪 Modules`, COLLDEV_SYNCER_STYLE).info(modules).end();

                                    return modules;
                                }),
                            )
                            .pipe(
                                map((modules) => (
                                    <>
                                        {Object.values(modules).length ? (
                                            <ModulesCatalogueComponent>
                                                {Object.values(modules).map((module, index) => (
                                                    <ModuleBoxComponent
                                                        key={index /* TODO: Probably better key */}
                                                        {...{
                                                            moduleStatusReporter: colldevSyncer!,
                                                            persister: colldevSyncer!,
                                                            translationsSystem,
                                                            businessSystem,
                                                            licenseSystem,
                                                            manifest: module.manifest || { name: 'Untitled' },
                                                        }}
                                                    />
                                                ))}{' '}
                                            </ModulesCatalogueComponent>
                                        ) : (
                                            <>
                                                <p>
                                                    To start developing a module,
                                                    <a
                                                        href="https://github.com/collboard/modules-sdk"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        please run <b>colldev</b> server
                                                    </a>
                                                    on you
                                                    <a
                                                        href={colldevSyncer!.colldevUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {colldevSyncer!.colldevUrl}
                                                    </a>
                                                    or change colldev URL.
                                                </p>
                                                <p>
                                                    <b>You can start your new project</b> with
                                                    <a
                                                        href="https://github.com/collboard/hello-world"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Hello world template
                                                    </a>
                                                    or
                                                    <a
                                                        href="https://github.com/collboard/module-sample-objects"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        create pair of tool and object on board
                                                    </a>
                                                    .
                                                </p>
                                            </>
                                        )}
                                    </>
                                )),
                            )}
                    />
                </>
            );
        },
    }),
);
