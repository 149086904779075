import { AbstractPlacedArt } from '../../../71-arts/25-AbstractPlacedArt';
import { CornerstoneArt } from '../../../71-arts/30-CornerstoneArt';
import { ExportArt } from '../../../71-arts/35-ExportArt';
import { ImportArt } from '../../../71-arts/35-ImportArt';
import { IBehaviorOptions } from '../0-IBehavior';

/**
 * Part of createSelectionToolBehavior
 * @see ./0-createSelectionToolBehavior.ts
 */
export async function selectionToolDraggingBehavior(behaviorProps: IBehaviorOptions): Promise<boolean> {
    const { touch, registerAdditionalSubscription, systems } = behaviorProps;

    const { appState, collSpace, controlSystem, materialArtVersioningSystem } = await systems.request(
        'appState',
        'collSpace',
        'controlSystem',
        'materialArtVersioningSystem',
    );

    let previouslySelected: Array<AbstractPlacedArt> = [];
    if (controlSystem.pressedKeys.value.includes('Control') || controlSystem.pressedKeys.value.includes('Shift')) {
        previouslySelected = appState.selected.value;
    }

    const screenPosition = touch.firstFrame.position;
    const boardPosition = collSpace.pickPoint(screenPosition).point;
    const selectedBoundingBox = appState.getSelectedBoundingBox();
    const isMouseWithinSelection =
        selectedBoundingBox &&
        screenPosition.x > selectedBoundingBox.topLeft.x &&
        screenPosition.x < selectedBoundingBox.bottomRight.x &&
        screenPosition.y > selectedBoundingBox.topLeft.y &&
        screenPosition.y < selectedBoundingBox.bottomRight.y;

    const nearbyArts = materialArtVersioningSystem.artsPlaced.filter(
        (art) =>
            art.isNear(boardPosition) &&
            !(art instanceof CornerstoneArt) &&
            !(art instanceof ExportArt) &&
            !(art instanceof ImportArt),
        // TODO smarter way
    );

    if (isMouseWithinSelection || nearbyArts.length > 0) {
        let draggedArts: Array<AbstractPlacedArt> = [];

        if (isMouseWithinSelection) {
            // Note: Dragging selected arts
            draggedArts = appState.selected.value.filter((art) => !art.locked);
        } else {
            const draggableArts = nearbyArts.filter((art) => !art.locked);

            if (draggableArts.length) {
                // Note: Dragging unselected art (select top-most)

                appState.setSelection({
                    selected: [...previouslySelected, draggableArts[draggableArts.length - 1]],
                });
                draggedArts = appState.selected.value;
            } else {
                // Note: Tryied to drag locked art - just select without dragging
                appState.setSelection({ selected: [...previouslySelected, nearbyArts[nearbyArts.length - 1]] });
                draggedArts = [];
            }
        }

        if (draggedArts.length) {
            const operation = materialArtVersioningSystem.createPrimaryOperation().takeArts(...draggedArts);

            let lastPosition = touch.firstFrame.position; // TODO: In LIB touchcontroller should be delta on each TouchFrame

            registerAdditionalSubscription(
                touch.frames.subscribe({
                    next(frame) {
                        const moveBy = frame.position
                            .subtract(lastPosition)
                            .apply(appState.transform.value.pick('rotate', 'scale').inverse());
                        lastPosition = frame.position;

                        operation.updateWithMutatingCallback((art) => {
                            art.move(moveBy);
                        });
                    },
                    complete() {
                        operation.persist();
                    },
                }),
            );
        }
        return true;
    }
    return false;
}

/**
 * TODO: [🎂] Probably remove systems from IBehaviorProps and use useSystems (or similar mechanism) instead
 */
