import { IVectorData, Transform, Vector } from 'xyzt';
import { windowSize } from '../../../40-utils/getWindowSize';
import { IShortcut } from '../../../50-systems/ControlSystem/interfaces/IShortcut';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { AbstractPlacedArt } from '../../../71-arts/25-AbstractPlacedArt';

/**
 * TODO: Move to some global config
 */
const MOVE_SMALL_STEP = 1;
/**
 * TODO: Move to some global config
 */
const MOVE_NORMAL_STEP = 10;
/**
 * TODO: Move to some global config
 */
const MOVE_BIG_STEP = 100;

internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/arrows-shortcut',
        deprecatedNames: ['@collboard/arrows-shortcut', 'ArrowsShortcut'],
        requirePermissions: ['view'],
    },
    async setup(systems) {
        const { controlSystem, appState, materialArtVersioningSystem } = await systems.request(
            'controlSystem',
            'appState',
            'materialArtVersioningSystem',
        );

        const defaultShortcuts = new Map<IShortcut, IVectorData>();

        defaultShortcuts.set(['ArrowUp'], new Vector(0, MOVE_NORMAL_STEP));
        defaultShortcuts.set(['Shift', 'ArrowUp'], new Vector(0, MOVE_SMALL_STEP));
        defaultShortcuts.set(['Control', 'ArrowUp'], new Vector(0, MOVE_BIG_STEP));
        defaultShortcuts.set(['ArrowDown'], new Vector(0, -MOVE_NORMAL_STEP));
        defaultShortcuts.set(['Shift', 'ArrowDown'], new Vector(0, -MOVE_SMALL_STEP));
        defaultShortcuts.set(['Control', 'ArrowDown'], new Vector(0, -MOVE_BIG_STEP));
        defaultShortcuts.set(['ArrowLeft'], new Vector(MOVE_NORMAL_STEP, 0));
        defaultShortcuts.set(['Shift', 'ArrowLeft'], new Vector(MOVE_SMALL_STEP, 0));
        defaultShortcuts.set(['Control', 'ArrowLeft'], new Vector(MOVE_BIG_STEP, 0));
        defaultShortcuts.set(['ArrowRight'], new Vector(-MOVE_NORMAL_STEP, 0));
        defaultShortcuts.set(['Shift', 'ArrowRight'], new Vector(-MOVE_SMALL_STEP, 0));
        defaultShortcuts.set(['Control', 'ArrowRight'], new Vector(-MOVE_BIG_STEP, 0));

        defaultShortcuts.set(['PageUp'], new Vector(0, windowSize.value.y * appState.transform.value.scale.y));
        defaultShortcuts.set(['PageDown'], new Vector(0, -windowSize.value.y * appState.transform.value.scale.y));

        return controlSystem.registerControl({
            defaultShortcuts,
            executor: ({ value }) => {
                const transform = Transform.translate(value);
                if (appState.selected.value.length === 0) {
                    // Moving board:
                    appState.transform.next(appState.transform.value.apply(transform));
                } else {
                    // Moving Selected objects:
                    materialArtVersioningSystem
                        .createPrimaryOperation()
                        .takeArts(...appState.selected.value)
                        .updateWithMutatingCallback((art) => {
                            (art as AbstractPlacedArt).shift = new Vector((art as AbstractPlacedArt).shift).apply(
                                transform
                                    .inverse()
                                    .apply(appState.transform.value.inverse().pick('scale', 'rotate'))
                                    .pick('translate'),
                            );
                        })
                        .persist(/* TODO: !! Maybe debounce persiting */);
                }
            },
        });
    },
}));

/**
 * TODO: Use .inverse()
 */
