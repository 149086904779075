import React from 'react';
import { useSkin } from '../../../40-utils/react-hooks/useSkin';
import { IModalFooterProps } from './IModalFooterProps';
import { ModalFooterStyledDiv } from './ModalFooterStyledDiv';

/**
 * Footer component for the modal
 *
 * @collboard-modules-sdk
 */
export function ModalFooter({ children, isSequestered }: IModalFooterProps) {
    const { skin } = useSkin();

    return <ModalFooterStyledDiv {...{ skin, isSequestered }}>{children}</ModalFooterStyledDiv>;
}
