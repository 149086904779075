import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeArticlesSetModule } from '../../50-systems/ModuleStore/makers/makeArticlesSetModule';

internalModules.declareModule(() =>
    makeArticlesSetModule({
        manifest: {
            flags: { isHidden: true },
            name: 'development' /* TODO: This should have module name "DevelopmentAboutModule" but we want nice URLs */,
            title: {
                // TODO: How to do article translations
                // TODO: Probably title for article modules can be loaded dynamically from the content
                cs: 'Vývojáři',
                en: 'About development',
            },
        },

        articles: {
            sdk: {
                cs: `https://github.com/collboard/modules-sdk`,
            },
        },
    }),
);
