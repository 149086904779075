import React from 'react';
import { IVectorData, Vector } from 'xyzt';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { Abstract2dArt } from '../../71-arts/26-Abstract2dArt';

/**
 *
 * Note: In future this file will we in independent repository as external module.
 *
 */

internalModules.declareModule(() => makeArtModule(CurtainArt));

export class CurtainArt extends Abstract2dArt {
    public static serializeName = 'Curtain';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/curtain-art',
        deprecatedNames: '@collboard/curtain-art',
    };

    /* TODO: [✨] Add is prefix */ public visible: boolean = true;
    public color = 'rgb(237, 240, 80)';
    public defaultZIndex = 7;

    /**
     * @deprecated [🍈] name privateSize is confusing, use something instead like size (=privateSize) vs. originalSize
     */
    private privateSize: IVectorData = new Vector(100, 100);

    public constructor(
        shift: IVectorData /* TODO: Should be shift set in the constructor or somehow else (by setShift method)? */,
    ) {
        super();
        this.shift = shift;
    }

    public get size(): IVectorData {
        return this.privateSize;
    }
    public set size(size: IVectorData) {
        this.privateSize = size;
    }

    public get curtainVisible(): number {
        return this.visible ? 1 : 0;
    }
    public set curtainVisible(toggle: number) {
        this.visible = !this.visible;
    }

    public get acceptedAttributes() {
        return ['curtainVisible', 'size', 'color'];
    }
    public get topLeft() /* TODO: This should be done by BoundingBox from LIB xyzt */ {
        return this.shift;
    }
    public get bottomRight() /* TODO: This should be done by BoundingBox from LIB xyzt */ {
        return Vector.add(this.shift, this.privateSize);
    }

    render() {
        return (
            <div
                className="block"
                style={{
                    width: this.privateSize.x || 0,
                    height: this.privateSize.y || 0,
                    position: 'absolute',
                    left: this.shift.x || 0 /* <- LIB xyzt .toTopLeft() */,
                    top: this.shift.y || 0 /* <- LIB xyzt .toTopLeft() */,
                    transform: 'rotate(' + this.rotation + 'deg)',
                    background: this.visible ? this.color : 'none',
                    border: 'solid 3px ' + this.color,
                }}
            ></div>
        );
    }
}
