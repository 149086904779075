import { Destroyable, IDestroyable } from 'destroyable';
import { forImmediate } from 'waitasecond';
import { createTimeout } from '../../30-components/utils/Loader/utils/createTimeout';
import { ISystemsExtended } from '../00-SystemsContainer/ISystems';

export abstract class AbstractSystem extends Destroyable implements IDestroyable {
    public constructor(protected readonly systems: ISystemsExtended) {
        super();

        this.ready = new Promise(async (resolve, reject) => {
            const timeout = createTimeout({
                duration: 3000 /* <- TODO: To some global timeout config / TimeoutSystem */,
                alt: this.constructor.name,
                additional: {
                    system: this,
                    systems,
                },
            });

            await forImmediate(/* <- TODO: Describe why waiting forImmediate OR remove */);
            await this.init();
            timeout.destroy();

            resolve();

            this._isReady = true;

            /*
            If we want to use logging of the systems:

            // TO DO: Move to SystemContainer and also log reinits
            // TO DO: Remove from server |> [✓] TranslationsSystem is initialized
            >  /**
            >   * TODO: [🔅] Put all %c styled console logs styles into one config place
            >   * /
            >  const SYSTEM_INIT_CONSOLE_LOG_STYLE = `color: #8844DD;`;
            >  consolex.info(`%c[✓] ${this.constructor.name} is initialized`, SYSTEM_INIT_CONSOLE_LOG_STYLE);
            */
        });
    }

    private _isReady: boolean = false;

    /**
     * Promise which is resolved when the system is initialized and ready to use.
     */
    public readonly ready: Promise<void>;

    /**
     * Checks if the system is initialized and ready to use.
     * @returns {boolean}
     */
    public get isReady(): boolean {
        return this._isReady;
    }

    protected abstract init(): Promise<void>;

    /**
     * Checks if the system is initialized and ready to use.
     * If not, throws an error.
     * If it is, do nothing.
     */
    protected checkIsReady(): void {
        if (!this.isReady) {
            throw new Error(`${this.constructor.name} is not ready to use`);
        }
    }
}

/**
 * TODO: [🏄] Rename ready to whenReady - then ACRY find and replace other "ready" to "whenReady" or "isReady"
 */
