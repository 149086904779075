import { ILogger, ISubLogger } from '../ILogger';

export function isSubLogger(logger: ILogger | ISubLogger): logger is ISubLogger {
    return typeof (logger as any).end === 'function';
}

/**
 * TODO: [🥟] Autogenerate suffix as .guard.ts
 * TODO: [🥟] This should be propper typeguard not just testing one property
 * TODO: [🥟] All typeguards should recieve unknown
 */
