import React from 'react';
import { Translate } from '../../50-systems/TranslationsSystem/components/Translate';
import { TranslateContext } from '../../50-systems/TranslationsSystem/components/TranslateContext';
import { IPrice } from './IPrice';

export function PriceComponent({ price }: { price: IPrice }) {
    const translateSystem = React.useContext(/* <- TODO: Import and use just a useContext */ TranslateContext);

    if (price === 0) {
        return <Translate name="PriceComponent / free">zdarma</Translate>;
    }

    const { amount, currency } = price;
    return <>{new Intl.NumberFormat(translateSystem.language, { style: 'currency', currency }).format(amount)}</>;
}

/**
 * TODO: Should we use Intl polyfill @see https://formatjs.io/docs/polyfills/intl-numberformat
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
