import { prettifyCss } from '../../jsx-html/prettifyCss';
import { string_html } from '../../typeAliases';
import { insertStyleIntoHtml } from '../misc/insertStyleIntoHtml';
import { getGlobalStyles } from './getGlobalStyles';

/**
 * Adds styles to given html document
 * It recieves html document which is not styled but contains references (classes, ids) to styles which are not there.
 * This function will find all styles used in current page and add them to the given document inlined.
 *
 * @param html without <style> but with elements need to be styled
 * @returns html with <style>
 * @sideeffect Not pure - reads from window.document all the style elements
 */
export async function addGlobalStyles(html: string_html): Promise<string_html> {
    const globalStylesString = prettifyCss(getGlobalStyles());

    return insertStyleIntoHtml({ html, css: globalStylesString });
}

/**
 * TODO: [Optimization][InitialLoading] Optimize this for initial loading
 * TODO: [🐾] Optimize HTML native export
 */
