import { Promisable } from 'type-fest';
import { forTime } from 'waitasecond';
import { errorMessageWithAdditional } from '../../../40-utils/errors/errorMessageWithAdditional';
import { loadAndRunExternalScript } from '../../../40-utils/loadAndRunExternalScript';
import { toArray } from '../../../40-utils/toArray';
import { string_url } from '../../../40-utils/typeAliases';
import { IModuleDefinition } from '../interfaces/IModule';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { IModulesStorageStrong } from '../interfaces/IModulesStorage';

interface IExternalModuleOptions {
    expectedManifest: IModuleManifest;
    cache: IModulesStorageStrong;
    script: Promisable<{
        src: string_url;
    }>;
}

/**
 *
 * @not-collboard-modules-sdk
 *
 * Warning: This is NOT A PURE function.
 *          It creates global `window.declareCallback` function SO use it only once per one cache.
 */
export function makeExternalModule({ expectedManifest, cache, script }: IExternalModuleOptions): IModuleDefinition {
    // TODO: Check that same cache was used
    (window as any).declareModule = cache.declareModule.bind(cache);

    return {
        manifest: {
            name: expectedManifest.name,
            requirePermissions: [],
        },
        async setup(systems) {
            const moduleDefinition =
                cache.getModule(expectedManifest.name) ||
                (await new Promise<IModuleDefinition>(async (resolve, reject) => {
                    const subscription = cache.observeAllModules().subscribe(async (modules) => {
                        const foundModule = modules.find(
                            (module) =>
                                module.manifest?.name === expectedManifest.name ||
                                toArray(module.manifest?.deprecatedNames).includes(expectedManifest.name),
                        );
                        if (foundModule) {
                            subscription.unsubscribe();
                            resolve(foundModule);
                        }
                    });

                    try {
                        const { src } = await script;
                        await loadAndRunExternalScript(src);

                        // Note: When the script is loaded, it will call window.declareModule
                        //       which will trigger cache.observeAllModules and resolve promise
                        //       which will be faster than rejection line bellow

                        // Note: This waiting is not nessasary, but it is a good practice to wait for latecommers loaded scripts 🏃‍♂️
                        await forTime(100);

                        reject(
                            `Script did not called "window.declareModule({...})" with expected module "${expectedManifest.name}"\nScript: ${src}`,
                        );
                    } catch (error) {
                        if (error instanceof Error) {
                            reject(
                                new Error(
                                    errorMessageWithAdditional(`Can not make external module`, {
                                        originalError: error,
                                        expectedManifest,
                                        cache,
                                        script,
                                    }),
                                ),
                            );
                        } else {
                            reject(error);
                        }
                    }
                }));

            // TODO: Probably go through ModuleInstallation
            const instalation = await moduleDefinition.setup(systems);
            return instalation;
        },
    };
}

/**
 * TODO: Verify better that module has expected manifest
 * TODO: Add emoji before all `Warning:`
 */
