import React from 'react';
import { ModalGroup } from '../../../../30-components/modal/ModalGroup/ModalGroup';
import { alertDialogue } from '../../../../40-utils/dialogues/alertDialogue';
import { DuplicateError } from '../../../../40-utils/errors/DuplicateError';
import { InvalidError } from '../../../../40-utils/errors/InvalidError';
import { useSystems } from '../../../../40-utils/react-hooks/useSystems';
import { Translate } from '../../../TranslationsSystem/components/Translate';

export function LicensesAddModalGroup() {
    const { licenseSystem, translationsSystem } = useSystems('licenseSystem', 'translationsSystem');
    const licenseTokenInputRef = React.useRef/* <- TODO: Import and use just a useRef */ <HTMLInputElement>(null);

    return (
        <ModalGroup
            isTitleVisibleOnSmallScreen={false}
            title={<Translate name={`Licenses / Add license`}>Aktivovat novou licenci:</Translate>}
        >
            <form
                onSubmit={async (event) => {
                    event.preventDefault();

                    const licenseTokenInput = licenseTokenInputRef.current;

                    if (!licenseTokenInput) {
                        return;
                    }

                    const licenseToken = licenseTokenInput.value;

                    try {
                        await licenseSystem.activateLicenseToken(licenseToken);
                        licenseTokenInput.value = '';
                    } catch (error) {
                        if (error instanceof InvalidError) {
                            alertDialogue(
                                <Translate name={`Licenses / Invalid license token`}>Neplatné číslo licence</Translate>,
                            );
                        } else if (error instanceof DuplicateError) {
                            alertDialogue(
                                <Translate name={`Licenses / Duplicate license token`}>
                                    Tuto licenci máte již aktivovanou
                                </Translate>,
                            );
                        } else {
                            throw error;
                        }
                    }
                }}
            >
                <input
                    type="text"
                    className="modal-input"
                    ref={licenseTokenInputRef}
                    placeholder={translationsSystem.translate(
                        `Licenses / Add license / placeholder`,
                        `Napište číslo licence`,
                    )}
                />
                <input
                    type="submit"
                    className="button button-primary modal-button"
                    style={{ marginLeft: 0 }}
                    value={translationsSystem.translate('Licenses / Add license', 'Aktivovat licenci')}
                />
            </form>
        </ModalGroup>
    );
}

/**
 * TODO: What are propper  translate names "Licenses/...", "LicensesList/...", "@collboard/internal/licenses/...", "@collboard/internal/licenses/Licenses/..." or some other way
 */
