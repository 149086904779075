import { string_uri_part } from '../typeAliases';
import { NotFoundError } from './NotFoundError';

/**
 * @collboard-modules-sdk
 */

export class BoardAlreadyExistsError extends NotFoundError {
    public constructor(uriId: string_uri_part) {
        super(`Board /${uriId} already exists.`);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, BoardAlreadyExistsError.prototype);
    }
}
