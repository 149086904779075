import { IObservableStorage } from 'everstorage';
import React from 'react';
import { map } from 'rxjs/operators';
import { ObservableContentComponent } from '../../../../30-components/utils/ObservableContentComponent';
import { IModuleSearchCriteria } from '../../../../50-systems/ModuleStore/interfaces/IModuleSearchCriteria';
import { internalModules } from '../../../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../../../50-systems/ModuleStore/makers/makeModalModule';
import { makeMultiModule } from '../../../../50-systems/ModuleStore/makers/makeMultiModule';
import { ModuleStoreModalComponent } from './components/00-ModuleStoreModalComponent';

internalModules.declareModule(() =>
    makeMultiModule(() => {
        let queryStorage: IObservableStorage<IModuleSearchCriteria>;
        return [
            // TODO: Searching should be maybe extracted from the ModuleStoreModalComponent here
            {
                async setup(systems) {
                    const { routingSystem } = await systems.request('routingSystem');

                    queryStorage = routingSystem.registerQuery<IModuleSearchCriteria>(
                        {},
                        {
                            saveToHistory:
                                false /* TODO: Make this save to history and check that is is working together with urlVariabiles in routingSystem */,
                            saveToStorage: false,
                            uniqueIdentifier: 'ModuleStore' /* TODO: ModuleStore vs. ModulesStore */,
                            debounceInterval: 250 /* TODO: Separate interval for category=0 and needle=1000 */,
                        },
                    );
                    return queryStorage;
                },
            },
            makeModalModule({
                manifest: {
                    name: '@collboard/internal/module-store',
                    deprecatedNames: ['@collboard/module-store', 'ModuleStore'],
                    title: { en: 'Add features', cs: 'Přidat funkce', sk: 'Pridať funkcie' },
                },
                async createModal(systems) {
                    await systems.request(
                        'translationsSystem',
                        'businessSystem',
                        'routingSystem',
                        'licenseSystem',
                        'moduleStore',
                        'cornerstoneSyncer',
                        'routeAndBusinessSyncer',
                        'attributeSupportSyncer',
                        'artSupportSyncer',
                    );

                    return {
                        wrapModalManually: true,
                        content: (
                            <ObservableContentComponent
                                alt="Page based on GET query to module store"
                                content={queryStorage.values.pipe(
                                    map((search) => (
                                        <ModuleStoreModalComponent
                                            {...{
                                                search,
                                            }}
                                            onSearchChange={(newSearch) => {
                                                queryStorage.value = {
                                                    ...newSearch,
                                                };
                                            }}
                                        />
                                    )),
                                )}
                            />
                        ),
                    };
                },
            }),
        ];
    }),
);

/**
 * TODO: [🐅] Maybe some more elegant way how to create icon-window pairs of modules
 */
