/**
 * Compares two strings for sorting purposes
 * @param a first string
 * @param b second string
 * @returns -1 if a < b, 0 if a == b, 1 if a > b
 *
 * @collboard-modules-sdk
 */
export function compareStrings(a: string, b: string): 1 | -1 | 0 {
    const order = compareStringsInner(a, b);

    if (order !== 0) {
        return order;
    } else {
        return compareStringsInner(a.trim(), b.trim());
    }
}

function compareStringsInner(a: string, b: string): 1 | -1 | 0 {
    return a.localeCompare(b, 'en' /* TODO: Probably pass translator */, { sensitivity: 'base' }) as 1 | -1 | 0;
}
