import { ILogger, ISubLogger } from './ILogger';

export class BlackholeLogger implements ILogger, ISubLogger {
    public log() {
        // Note: Do nothing
    }

    public info() {
        return this;
        // Note: Do nothing
    }

    public warn() {
        return this;
        // Note: Do nothing
    }

    public error() {
        return this;
        // Note: Do nothing
    }

    public trace() {
        return this;
        // Note: Do nothing
    }

    public appear(): this {
        // Note: Do nothing
        return this;
    }

    public end(): void {
        // Note: Do nothing
    }

    public group(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        // Note: Do nothing
        return this;
    }

    public groupCollapsed(groupName?: string, ...optionalParams: Array<any>): ISubLogger {
        // Note: Do nothing
        return this;
    }
}
