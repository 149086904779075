import React from 'react';
import { Icon } from '../30-components/menu/Icon';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeAttributeModule } from '../50-systems/ModuleStore/makers/makeAttributeModule';

/**
 * @collboard-modules-sdk
 */
export interface IFontStyleAttributeValue {
    /* TODO: [✨] Maybe add is prefix */ bold: boolean;
    /* TODO: [✨] Maybe add is prefix */ italic: boolean;
    /* TODO: [✨] Maybe add is prefix */ underline: boolean;
}

internalModules.declareModule(() =>
    makeAttributeModule({
        manifest: {
            // Note: This art module is not auto-activated on initialization because when it appears on the board or in some toolbar, it will be auto-activated afterward.
            name: 'FontStyleAttribute',

            contributors: [Authors.rosecky, Authors.hejny],
        },
        standard: true,
        attribute: {
            type: 'object',
            name: 'fontStyle',
            defaultValue: {
                bold: false,
                italic: false,
                underline: false,
            } as IFontStyleAttributeValue,
            // TODO: Interface or checker
        },

        inputRender: (
            value: /*IFontStyleAttributeValue*/ any,
            onChange: (value: /*IFontStyleAttributeValue*/ any) => void,
        ) => (
            <>
                <Icon icon="bold" active={value.bold} onClick={() => onChange({ ...value, bold: !value.bold })} />
                <Icon
                    icon="italic"
                    active={value.italic}
                    onClick={() => onChange({ ...value, italic: !value.italic })}
                />
                <Icon
                    icon="underline"
                    active={value.underline}
                    onClick={() => onChange({ ...value, underline: !value.underline })}
                />
            </>
        ),
    }),
);
