import { ISystems } from '../../00-SystemsContainer/ISystems';
import { IModuleDefinition } from '../../ModuleStore/interfaces/IModule';
import { IModuleManifest } from '../../ModuleStore/interfaces/IModuleManifest/IModuleManifest';
import { ISkin } from '../ISkin';

/**
 * makeSkinModule will make skin confuiguration module
 *
 * Note: If requirePermissions not set then will this maker set them to empty array
 * Note: Module still needs to be declared
 * @collboard-modules-sdk
 */
export function makeSkinModule(protoModule: { manifest?: IModuleManifest; skin: ISkin }): IModuleDefinition {
    const { manifest, skin } = protoModule;

    return {
        manifest: manifest && {
            requirePermissions: [],
            ...manifest,
        },
        async setup(systems: ISystems) {
            const { styleSystem } = await systems.request('styleSystem');
            return styleSystem.registerSkin({ skin, priority: manifest?.priority });
        },
    };
}
