import React from 'react';
import { Vector } from 'xyzt';
import { Comment } from '../../../30-components/utils/Comment';
import { Unwrap } from '../../../30-components/utils/Unwrap';
import { createTitle } from '../../../40-utils/createTitle';
import { jsxToHtml } from '../../../40-utils/jsx-html/jsxToHtml';
import { htmlToXmlValidHtml } from '../../../40-utils/jsx-html/makeXmlValid';
import { prettifyHtml } from '../../../40-utils/jsx-html/prettifyHtml';
import { addGlobalStyles } from '../../../40-utils/style/global/addGlobalStyles';
import { getBoundingClientRectWithoutTransform } from '../../../50-systems/CollSpace/utils/getBoundingClientRectWithoutTransform';
import { sortArtContainersByScreenPosition } from '../../../50-systems/ExportSystem/utils/sortArtContainersByScreenPosition';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { TranslateContext } from '../../../50-systems/TranslationsSystem/components/TranslateContext';
import { consolex } from '../../../consolex';

internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/svg-export',
        deprecatedNames: '@collboard/svg-export',
        requirePermissions: ['view'],
    },
    async setup(systems) {
        const { exportSystem, translationsSystem, artSerializer } = await systems.request(
            'exportSystem',
            'translationsSystem',
            'artSerializer',
        );
        return exportSystem.registerFileSupport({
            priority: 10,
            mimeType: 'image/svg+xml',
            isHeavy: false /* <- TODO: [🐈] Maybe use to split between Simple and Complex HTML export */,
            async exportFile({
                artContainers,
                boundingBox,
                // Note: quality is irelevant for SVG export
                //       Maybe TODO: Some degradation algorithms for SVG export
                scale,
                boardname /* <- TODO: Use here framename (or exportname) not boardname WITHOUT to need to transform through createTitle */,
                // TODO: [🎏] Maybe also pass description here
                // Note: isMaterialized is auto-applied by ExportSystem
                // Note: isLinked can not be used here
                isTransparent,
                isTesting,
                isNative,
                isHeavyExport,
                /* [2]> logger */
            }) {
                if (boundingBox.width * boundingBox.height === 0) {
                    return null;
                }
                // TODO: Convert text bold, italic, h1...

                const jsx = (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        {...(!isNative
                            ? {}
                            : {
                                  'xmlns:collboard':
                                      'https://xmlns.collboard.com/' /* <- TODO: [🐸] Add content to this page */,
                              })}
                        // TODO: BoundingBox.toViewBox
                        viewBox={`${boundingBox.topLeft.x} ${boundingBox.topLeft.y} ${boundingBox.width} ${boundingBox.height}`}
                        width={boundingBox.width * scale}
                        height={boundingBox.height * scale}
                        /*  width="1000" /* <- TODO: Probably use + Some configurable ratio */
                        /* TODO: Prevent width="NaN" height="NaN" */
                        /* TODO: Also height according to ratio + some smart count of reasonable width+height */
                    >
                        <>
                            <title>
                                {createTitle({
                                    boardname,
                                    translationsSystem,
                                })}
                            </title>
                            {/* <desc> [🎏] Maybe also pass description here</desc> */}
                            {isNative && (
                                <>
                                    <metadata
                                        {...{
                                            'collboard:import-with-module': '@collboard/internal/html-native-import',
                                        }}
                                    />
                                    <Comment>
                                        ☝️ Note: Using @collboard/internal/html-native-import (not svg) because native
                                        data are same
                                        {/* ☝️ Note: Using <Comment> not React comment to preserve that ☝️ into exported file */}
                                        {/* [💅] */}
                                    </Comment>
                                </>
                            )}
                        </>

                        {isTransparent === false && (
                            <rect
                                x={boundingBox.topLeft.x}
                                y={boundingBox.topLeft.y}
                                width={boundingBox.width}
                                height={boundingBox.height}
                                fill="white"
                            />
                        )}

                        {isTesting && (
                            <>
                                <rect
                                    x={boundingBox.topLeft.x}
                                    y={boundingBox.topLeft.y}
                                    width={boundingBox.width}
                                    height={boundingBox.height}
                                    fill="#9f9"
                                    stroke="#f00"
                                    strokeWidth={10}
                                />
                                <image
                                    href="https://yari-demos.prod.mdn.mozit.cloud/en-US/docs/Web/SVG/Element/image/mdn_logo_only_color.png"
                                    x={boundingBox.topLeft.x}
                                    y={boundingBox.topLeft.y}
                                    height="200"
                                    width="200"
                                />
                            </>
                        )}

                        {artContainers.length}
                        <TranslateContext.Provider value={translationsSystem}>
                            {
                                // TODO: [🐽] What is the best sorting for SVG export
                                await sortArtContainersByScreenPosition(artContainers).mapAsync(
                                    async ({ art, element }, i) => {
                                        // TODO: Do not use element here - use only art like in @collboard/internal/html-export

                                        if (!element) {
                                            return (
                                                <React.Fragment
                                                    /* <- TODO: Import and use just a Fragment */ key={i}
                                                ></React.Fragment /* <- TODO: Import and use just a Fragment */>
                                            );
                                        }

                                        // TODO: This is weird - where is the transfrmation applyed id here is stripped and bellow not used some other way?
                                        const { x, y, width, height } = getBoundingClientRectWithoutTransform(element);

                                        // TODO: [🍬] Pass into artContainers boundingBox to not to need to calculate it from getBoundingClientRect, appState.transform and windowSize.value
                                        // Note: [1] Not needed when using getBoundingClientRectWithoutTransform instead of getBoundingClientRect
                                        const size = new Vector(width, height); // [1] .multiply(appState.transform.value.scale);
                                        const topLeft = new Vector(x, y).subtract(boundingBox.transform.scale.half());
                                        // [1] .subtract(appState.transform.value.translate)
                                        // [1] .subtract(windowSize.value.half());

                                        return (
                                            // TODO: Do not wrap svg-like elements in <foreignObject>
                                            // TODO: Convert art into bitmap OR include styles
                                            // TODO: [🧚] Maybe use <ArtShell isSvg/>
                                            <foreignObject
                                                // Note: [🍒][0]! This div is kind of <ArtOwnShell for the SVG export
                                                key={art.artId}
                                                {...{ x: topLeft.x, y: topLeft.y, width: size.x, height: size.y }}
                                                {...(!isNative
                                                    ? {}
                                                    : {
                                                          'collboard:art': JSON.stringify(
                                                              await artSerializer.serialize(art),
                                                          ),
                                                      })}
                                            >
                                                <div
                                                    {...({
                                                        xmlns: 'http://www.w3.org/1999/xhtml', // <- TODO: Use version for HTML5
                                                    } as any)}
                                                >
                                                    {/* Note: Here is unwrapped the arts div in future [🍒][0]! <ArtOwnShell*/}
                                                    <Unwrap>{await art.render(false, systems)}</Unwrap>
                                                </div>
                                            </foreignObject>
                                        );
                                    },
                                )
                            }
                        </TranslateContext.Provider>
                    </svg>
                );

                const svg = await Promise.resolve(jsx).then(jsxToHtml).then(addGlobalStyles).then(htmlToXmlValidHtml);

                if (!isHeavyExport) {
                    return svg;
                }

                try {
                    return prettifyHtml(svg);
                } catch (error) {
                    // TODO: [2] Here should be used passed logger not consolex
                    consolex.warn(`When exporting SVG, error occured in prettifyHtml:\n`, error);
                    return svg;
                }
            },
        });
    },
}));

/**
 * TODO: [🎇] Full vs linked export
 * TODO: !! Work also when scaled
 * TODO: Is export to SVG more like export to HTML or Bitmap image?
 * TODO: [💅] Decide and choose which type of inserting comments into the export is better
 */
