import React from 'react';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../50-systems/ModuleStore/makers/makeModalModule';
import { DisplayOn } from './DisplayOn';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            name: '@test/displayon-modal',
            description: 'Module made just for internal testing of <DisplayOn component',
            flags: {
                isHidden: true /* <- TODO: Modal modules should be always hidden*/,
            },
            requirePermissions: [],
        },
        async createModal() {
            return (
                <>
                    <p>
                        On every screen size there should be shown exactly <b>3 options:</b>
                    </p>

                    <ul>
                        <DisplayOn>
                            <li>Never shown</li>
                        </DisplayOn>
                        <DisplayOn desktop>
                            <li>
                                <b>Desktop</b>
                            </li>
                        </DisplayOn>
                        <DisplayOn tablet>
                            <li>
                                <b>Tablet</b>
                            </li>
                        </DisplayOn>
                        <DisplayOn mobile>
                            <li>
                                <b>Mobile</b>
                            </li>
                        </DisplayOn>
                        <DisplayOn desktop tablet>
                            <li>Desktop or Tablet</li>
                        </DisplayOn>
                        <DisplayOn tablet mobile>
                            <li>Tablet or Mobile</li>
                        </DisplayOn>
                        <DisplayOn mobile desktop>
                            <li>Mobile or Desktop</li>
                        </DisplayOn>
                        <DisplayOn desktop tablet mobile>
                            <li>Always shown</li>
                        </DisplayOn>
                    </ul>
                </>
            );
        },
    }),
);
