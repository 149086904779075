import React from 'react';
import { classNames } from '../../40-utils/classNames';

export interface ITimerComponentProps {
    millis: number;
    until?: number;
    since?: number;
    static?: number;
    warnAt?: number;
}

interface ITimerComponentState {
    timestamp: number;
}

export class TimerComponent extends React.Component/* <- TODO: Import and use just a Component */ <
    ITimerComponentProps,
    ITimerComponentState
> {
    state: ITimerComponentState = {
        timestamp: new Date().getTime(),
    };

    interval: NodeJS.Timeout;

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ timestamp: new Date().getTime() }), this.props.millis);
    }
    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        let sum = 0;
        if (this.props.until) {
            sum = this.props.until - this.state.timestamp;
        }
        if (this.props.since) {
            sum = this.state.timestamp - this.props.since;
        }
        if (this.props.static) {
            sum = this.props.static;
        }
        if (sum < 0) {
            sum = 0;
        }
        const warn = !!this.props.warnAt && this.props.warnAt >= sum;

        const h = Math.floor(sum / (1000 * 60 * 60));
        const m = Math.floor(sum / (1000 * 60) - h * 60);
        const s = Math.floor(sum / 1000 - h * (60 * 60) - m * 60);
        const millis = sum - h * (60 * 60 * 1000) - m * 60 * 1000 - s * 1000;

        return (
            <div className={classNames('timer', warn && 'ending')}>
                {h > 0 && <div className="hours">{h}</div>}
                {h > 0 ? ':' : ''}
                <div>{m.toString().padStart(2, '0')}</div>:<div>{s.toString().padStart(2, '0')}</div>
                {h === 0 && (
                    <div className="small">
                        {Math.round(millis / 10)
                            .toString()
                            .padStart(2, '0')}
                    </div>
                )}
            </div>
        );
    }
}

/**
 * Note: In future this file will we in independent repository as external module.
 * TODO: [🏛️] Convert Class components to Functional (with hooks).
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
