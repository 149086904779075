import React from 'react';
import { map } from 'rxjs/operators';
import { ObservableContentComponent } from '../../../../../30-components/utils/ObservableContentComponent';
import { confirmDialogue } from '../../../../../40-utils/dialogues/confirmDialogue';
import { flagsToObject } from '../../../../../40-utils/flagsToObject';
import { PriceComponent } from '../../../../../40-utils/price/PriceComponent';
import { IUsageLicenseInfo } from '../../../../../50-systems/LicenseSystem/interfaces/IUsageLicenseInfo';
import { IModuleManifest } from '../../../../../50-systems/ModuleStore/interfaces/IModuleManifest/IModuleManifest';
import { IModulePersister } from '../../../../../50-systems/ModuleStore/interfaces/IModulePersister';
import { IModuleStatusReporter } from '../../../../../50-systems/ModuleStore/interfaces/IModuleStatusReporter';
import { ModuleStatus } from '../../../../../50-systems/ModuleStore/ModuleStatus';
import { isExperimental } from '../../../../../50-systems/ModuleStore/utils/isExperimental';
import { Translate } from '../../../../../50-systems/TranslationsSystem/components/Translate';

interface IModuleStatusComponentProps {
    shownModule: IModuleManifest;
    moduleStatusReporter: IModuleStatusReporter;
    persister: IModulePersister | null;
    usageLicenseInfo: IUsageLicenseInfo;
}

export function ModuleStatusComponent({
    shownModule,
    moduleStatusReporter,
    persister,
    usageLicenseInfo,
}: IModuleStatusComponentProps) {
    return (
        <ObservableContentComponent
            alt="Module status"
            key={shownModule.name}
            loader={
                <>
                    {/* @roseckyj please make default <Loading/> to be responsible and fill up only its space, because in this situation it totally back out whole modal */}
                </>
            }
            content={
                /*TODO: Probbably installed modules should be taken globally not only from cornerstoneSyncer*/
                moduleStatusReporter.statusOf(shownModule).pipe(
                    map((status) => {
                        switch (status) {
                            case ModuleStatus.NotInstalled:
                                if (!usageLicenseInfo.isValid) {
                                    if (usageLicenseInfo.licensesToBuy.length) {
                                        // TODO: [🥇][🥇0] When multiple licenses for one module - which buyLink should be shown?
                                        const licenseToBuy = usageLicenseInfo.licensesToBuy[0]!;

                                        return (
                                            <>
                                                <a
                                                    className="onHover modules-badge badge-buy"
                                                    href={licenseToBuy.buyLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <span className="text">
                                                        <Translate name="ModuleStoreToolbarIcon / buy license" html>
                                                            Koupit
                                                        </Translate>

                                                        <Translate name="ModuleStoreToolbarIcon / for">za</Translate>

                                                        <PriceComponent price={licenseToBuy.price} />
                                                    </span>
                                                </a>
                                            </>
                                        );
                                    } else {
                                        return (
                                            <div className="onHover modules-badge badge-unavailable">
                                                <span className="text">
                                                    <Translate name="ModuleStoreToolbarIcon / unavailable" html>
                                                        Nedostupné
                                                    </Translate>
                                                </span>
                                            </div>
                                        );
                                    }
                                }

                                return (
                                    <>
                                        {!persister ? (
                                            <></>
                                        ) : (
                                            <div
                                                className="onHover modules-badge badge-add"
                                                onClick={async () => {
                                                    if (persister === null) {
                                                        return;
                                                    }

                                                    if (isExperimental(shownModule)) {
                                                        if (
                                                            !(await confirmDialogue(
                                                                <Translate name="ModuleStoreToolbarIcon/ Experimental / confirm">
                                                                    Opravdu chcete nainstalovat experimentální funkci.
                                                                    Může se stát, že bude v budoucnu odstraněna a vy
                                                                    příjdete o věci, které jste s její pomocí vytvořili.
                                                                </Translate>,
                                                            ))
                                                        ) {
                                                            return;
                                                        }
                                                    }

                                                    if (flagsToObject(shownModule.flags).isDeprecated) {
                                                        if (
                                                            !(await confirmDialogue(
                                                                <Translate name="ModuleStoreToolbarIcon/ Deprecated / confirm">
                                                                    Opravdu chcete nainstalovat zastaralou funkci. Může
                                                                    se stát, že bude v budoucnu odstraněna a vy příjdete
                                                                    o věci, které jste s její pomocí vytvořili.
                                                                </Translate>,
                                                            ))
                                                        ) {
                                                            return;
                                                        }
                                                    }

                                                    await persister.moduleActivate(shownModule);
                                                }}
                                            >
                                                <span className="text">
                                                    {usageLicenseInfo.license.type === 'FREE' ||
                                                    usageLicenseInfo.license.price === 0 ? (
                                                        <Translate
                                                            name="ModuleStoreToolbarIcon / activate for free"
                                                            html
                                                        >
                                                            Přidat zdarma
                                                        </Translate>
                                                    ) : (
                                                        <>
                                                            <Translate name="ModuleStoreToolbarIcon / activate" html>
                                                                Přidat
                                                            </Translate>

                                                            <>
                                                                (
                                                                <Translate
                                                                    name="ModuleStoreToolbarIcon / purchased"
                                                                    html
                                                                >
                                                                    Zaplaceno
                                                                </Translate>
                                                                )
                                                            </>
                                                        </>
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    </>
                                );

                            case ModuleStatus.Installing:
                                return (
                                    <>
                                        <div className="modules-badge badge-progress">
                                            <span className="text">
                                                <Translate name="ModuleStoreToolbarIcon/ activating" html>
                                                    Přidávám
                                                </Translate>
                                            </span>
                                        </div>
                                    </>
                                );

                            case ModuleStatus.Installed:
                                return (
                                    <>
                                        {!persister ? (
                                            <></>
                                        ) : (
                                            <div
                                                className="onHover modules-badge badge-remove"
                                                onClick={async () => {
                                                    await persister.moduleDeactivate(shownModule);
                                                }}
                                            >
                                                <div className="onHover text">
                                                    <Translate name="ModuleStoreToolbarIcon/ deactivate">
                                                        Odebrat
                                                    </Translate>
                                                </div>
                                            </div>
                                        )}

                                        <div className="modules-badge badge-added" />
                                    </>
                                );

                            case ModuleStatus.Uninstalling:
                                return (
                                    <>
                                        <div className="modules-badge badge-progress">
                                            <span className="text">
                                                <Translate name="ModuleStoreToolbarIcon/ deactivating" html>
                                                    Odebírám
                                                </Translate>
                                            </span>
                                        </div>
                                    </>
                                );

                            default:
                                throw new Error(`Unknown ModuleStatus "${status}".`);
                        }
                    }),
                )
            }
        />
    );
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
