import React from 'react';
import { Transform, transformToStyleCss } from 'xyzt';
import { useLoadable } from '../../../40-utils/react-hooks/useLoadable';
import { uuid } from '../../../40-utils/typeAliases';
import { Loadable } from '../../../40-utils/typeHelpers';

type IArtShellProps =
    React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{
        artId: uuid;
        zIndex: number;
        transform?: Loadable<Transform>;
        opacity?: number;
        isSelectable?: boolean;
    }>;

/**
 * @collboard-modules-sdk
 * @sideeffect Creating elements with id={artId} to be findable in the DOM
 * @sideeffect Creating elements with class __ArtShell for findArtShellElement
 */
export function ArtShell(props: IArtShellProps) {
    const { artId, zIndex, children, transform, opacity } = props;
    const { value: transformValue } = useLoadable(transform);

    return (
        <div
            id={artId}
            style={{
                position: 'absolute',
                // TODO: Maybe use here observable windowSize
                left: `50%`,
                top: `50%` /* <- Note: Not using 50vh but 50% because on mobile devices vh is polluted with size of top browser bar */,
                zIndex,
            }}
        >
            <div
                className="__ArtShell" // Note: Just for findArtShellElement util + to see ArtShell in the DOM tree
                style={{
                    fontSize: 12,
                    transformOrigin: `center center`,
                    transform: transformToStyleCss(transformValue || Transform.neutral()),
                    opacity: opacity || 1,
                }}
            >
                {children}
            </div>
        </div>
    );
}

/**
 *  TODO: [🍒][0]! This should became a <ArtBoardShell vs <ArtOwnShell
 * TODO: [✏️] Fix> react-dom.development.js:630 Error: <svg> attribute width: Expected length, "NaN".
 * TODO: Probably pass something more abstract than zIndex to ArtShell (or include this into transform)
 * TODO: Use isSelectable
 */
