import '@total-typescript/ts-reset';
import './00-modules/include';
import { CollboardApp } from './CollboardApp';
import * as CollboardSdk from './CollboardSdk';
import { declareGlobals } from './globals';

// TODO: Probably Collboard.App and Collboard.Sdk
(window as any).CollboardApp = CollboardApp;
(window as any).CollboardSdk = CollboardSdk;

// TODO: !! Rename CollboardSdk to standard library
// TODO: !! Expose SDK bridge for webextensions

/*
 * TODO: Export with more deep structure (like librairies)
 */

(window as any).collboardLoaderCallback(CollboardApp);

declareGlobals();
