import packageJson from '../package.json';
import { string_version } from './40-utils/typeAliases';

export const clientVersion: string_version = packageJson.version;

/**
 * @deprecated Used only in one place and will be removed
 */
export const PUBLIC_URL = process.env.PUBLIC_URL; // TODO: PUBLIC_URL does not contain anything - Maybe use normalized url from the business

/**
 * Conversion table from misc units to board pixels
 *
 * @collboard-modules-sdk
 * @deprecated Make ScaleSystem instead
 */
export const SCALE_PIXELS = {
    // TODO: !! Better

    grid: 30, // TODO: Unite grid and field size
    field: 35, // TODO: Use (for example in arrows shortcut) and set
    centimeter: 35,
    kilometer: 3, // TODO: Use and set
    earths: 350,
    AU: 100, // TODO: Use and set

    // TODO: Some ScaleArt
};

/**
 * @deprecated integrate into StyleSystem
 */
export const DRAWING_COLORS: { [key: string]: string } = {
    black: '#000000',
    blue: '#3167A4',
    red: '#EE2333',
    green: '#40B93C',
    orange: '#F36717',
    yellow: '#EDF050',
    white: '#FFFFFF',
};

/* TODO: [✨] Add is prefix */
export const DEBUG_CSS = false;
export const DEBUG_CSS_RULE = !DEBUG_CSS ? `` : `border: 1px dotted red;`;

/**
 * Matches a Collboard module name
 *
 * For examples of valid/invalid names:
 *     @see https://regex101.com/r/oDVYWA/1
 *     @see /src/40-utils/parseModuleName.test.ts
 *
 * @collboard-modules-sdk
 */
export const MODULE_NAME_PATTERN =
    /^(@(?<scope>[a-z0-9][a-z0-9-]*))\/(?<name>([a-z0-9][a-z0-9-]*)(\/([a-z0-9][a-z0-9-]*))*)$/;

/**
 * How much maximum notifications should be shown at one time
 *
 * @collboard-modules-sdk
 */
export const SHOW_MAXIMUM_NOTIFICATIONS = 2;

/**
 * TODO: [🌓] MODULE_NAME_PATTERN Do not allow double dash -- in module name or in the module scope because it is string used to escape
 */
