import React from 'react';
import { classNames } from '../../40-utils/classNames';

interface ISeparatorProps {
    className?: string;
}

/**
 * Menu separator
 *
 * @collboard-modules-sdk
 */
export function Separator(props: ISeparatorProps) {
    return <div className={classNames('menu-separator', props.className)}></div>;
}
