import isEqual from 'lodash/isEqual';
import { difference } from '../../../40-utils/sets/difference';
import { IDependenciesRecord } from '../interfaces/IDependencies';
import { IInstallPlan } from '../interfaces/IInstallPlan';
import { dependenciesRecordToDependenciesSet } from './dependenciesRecordToDependenciesSet';
import { dependenciesSetToDependenciesRecord } from './dependenciesSetToDependenciesRecord';

export function createInstallPlan(
    currentDependencies: IDependenciesRecord,
    targetDependencies: IDependenciesRecord,
): IInstallPlan {
    const current = dependenciesRecordToDependenciesSet(currentDependencies);
    const target = dependenciesRecordToDependenciesSet(targetDependencies);

    const install = difference(target, current, isEqual);
    const uninstall = difference(current, target, isEqual);

    return {
        uninstall: dependenciesSetToDependenciesRecord(uninstall),
        install: dependenciesSetToDependenciesRecord(
            Array.from(install).filter((dependency) => dependency.instances !== 0),
        ),
    };
}

/**
 * TODO: !! In uninstall should not be contained install
 */
