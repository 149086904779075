import { Destroyable, IDestroyable, registerItemsInArray, Registration } from 'destroyable';
import React from 'react';
import { spaceTrim } from 'spacetrim';
import { forImmediate } from 'waitasecond';
import { IVectorData, Vector } from 'xyzt';
import { LoaderInline } from '../../30-components/utils/Loader/LoaderInline';
import { randomColor } from '../../40-utils/color/randomColor';
import { amendPropperFileTypeAndName } from '../../40-utils/files/amendPropperFileTypeAndName';
import { fetchAsFile } from '../../40-utils/files/fetchAsFile';
import { isValidDataurl } from '../../40-utils/files/isValidDataurl';
import { windowSize } from '../../40-utils/getWindowSize';
import { isValidUrl } from '../../40-utils/isValidUrl';
import { ISubLogger } from '../../40-utils/logger/ILogger';
import { randomTag } from '../../40-utils/randomTag';
import { toArray } from '../../40-utils/toArray';
import { string_url } from '../../40-utils/typeAliases';
import { Arrayable } from '../../40-utils/typeHelpers';
import { AbstractArt } from '../../71-arts/20-AbstractArt';
import { AbstractPlacedArt } from '../../71-arts/25-AbstractPlacedArt';
import { consolexBase } from '../../consolex';
import { ISystemsExtended } from '../00-SystemsContainer/ISystems';
import { signatureManager } from '../00-SystemsContainer/SignatureManager';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { IOngoingMaterialOperation } from '../ArtVersionSystem/IOperation';
import { Operation } from '../ArtVersionSystem/Operation';
import { NotificationRegistration } from '../NotificationSystem/NotificationRegistration';
import { ToolbarName } from '../ToolbarSystem/0-ToolbarSystem';
import { Translate } from '../TranslationsSystem/components/Translate';
import { DeletedArt } from './../../71-arts/30-DeletedArt';
import { FILE_IMPORT_SUPPORTER_NEXT, IFileImportSupporter } from './interfaces/IFileImportSupporter';
import { getFileNativeImporter } from './utils/getFileNativeImporter';

interface IImportOptions {
    boardPosition?: IVectorData | null;

    /**
     * If true, the notifications will be shown.
     * @default true
     */
    isNotified?: boolean;

    /**
     * If true, the imported will be selected if supporter wants.
     * @default true
     */
    isSelected?: boolean;

    /**
     * If true, the imported will be persisted (=saved).
     * @default true
     */
    isPersisted?: boolean;

    /**
     * Use some specific or existing logger
     * @default there will be created new logger as groupPrefixed from the console
     */
    logger?: ISubLogger;
}

/**
 * Import system makes support for files which are dragged onto board, imporded or pasted
 * It auto-installs / uninstalls file support modules.
 *
 * @collboard-system
 */
export class ImportSystem extends AbstractSystem {
    public constructor(systems: ISystemsExtended, private proxyUrl: URL) {
        super(systems);
    }

    protected async init() {}

    private fileSupporters: Array<
        IFileImportSupporter & {
            moduleName: string | null;
        }
    > = [];

    public registerFileSupport(fileSupporter: IFileImportSupporter): Registration {
        const signature = signatureManager.getSignature(this);

        if (!signature) {
            // TODO: Some way how to require signature / require unsigned through signatureManager
            throw new Error(`ImportSystem must be signed to register file support.`);
        }

        return registerItemsInArray({
            base: this.fileSupporters,
            add: [
                {
                    ...fileSupporter,
                    moduleName: signature.name || null,
                },
            ],
        });
    }

    /*  TODO: [⚱️]
    public get supportedMimeTypes(): Set<string> {
        return new Set(this.fileSupporters.map(({ mimeType }) => mimeType).filter(Boolean) as string[]);
    }
    */

    /**
     * Imports content from URL
     *
     * @returns objects which can be used to undo the importment (typically ongoing operation) OR null if import fails
     *
     *  Note: If you want to fetch and import content of URL and materialize it, use the importFile with the util fetchAsFile
     */
    public async importUrl(
        options: IImportOptions & {
            src: URL | string_url;
        },
    ): Promise<IOngoingMaterialOperation | IDestroyable | null> {
        const { logger: loggerNullable, isNotified } = options;
        const logger =
            loggerNullable ||
            consolexBase.groupCollapsed(
                `%cImporting ${isValidDataurl(options.src.toString()) ? `DataURL` : options.src.toString()}`,
                `background: #1166cc; color: white; font-size: 1.1em; font-weight: bold; padding: 5px; border-radius: 3px;`,
            );

        logger.info(options);

        if (!isValidUrl(options.src.toString())) {
            logger.error('Invalid url: ', options.src);
            logger.end(/* TODO: !! Do not end */);

            if (isNotified) {
                // TODO: Use here alertDialogue when there is [🔊] finished
                (await this.systems.notificationSystem).publish({
                    type: 'warning',
                    canBeClosed:
                        true /* <- TODO: (probbably @roseckyj) Make some way how to autoclose (with coundown on X) the notifications */,
                    title: options.src.toString(),
                    body: (
                        <LoaderInline alt="Importing" canLoadForever icon="file-image">
                            <Translate name="ImportSystem / invalid url">Neplatná URL adresa</Translate>
                        </LoaderInline>
                    ),
                });
            }
            return null;
        }

        const file = await fetchAsFile(options.src, this.proxyUrl)
            /*
            .then((fetchedFile) => {
                logger.info('fetched file', fetchedFile);
                return fetchedFile;
            })
            */
            .then(amendPropperFileTypeAndName)
            .catch(async (error) => {
                logger.error(error);
                logger.end(/* TODO: !! Do not end */);

                if (isNotified) {
                    // TODO: Use here alertDialogue when there is [🔊] finished
                    (await this.systems.notificationSystem).publish({
                        type: 'warning',
                        canBeClosed:
                            true /* <- TODO: (probbably @roseckyj) Make some way how to autoclose (with coundown on X) the notifications */,
                        title: options.src.toString(),
                        body: (
                            // TODO: Better message than "Bohužel nedokážeme načíst tento obsah"
                            <LoaderInline alt="Importing" canLoadForever icon="file-image">
                                <Translate name="ImportSystem / can not import url">
                                    Bohužel nedokážeme načíst tento obsah
                                </Translate>
                            </LoaderInline>
                        ),
                    });
                }

                return null;
            });

        if (!file) {
            return null;
        }

        // TODO: When importing URLs - ask if link OR materialize (Images, Native, Feeds)
        // TODO: Prevent doubleuploading of same file
        // TODO: Frontend native imports (from H-edu)

        return this.importFile({ ...options, logger, file });
    }

    /**
     * Imports one file into the board.
     *
     * @returns objects which can be used to undo the importment (typically ongoing operation) OR null if import fails
     */
    public async importFile(
        options: IImportOptions & {
            file: Blob | File;
        },
    ): Promise<IOngoingMaterialOperation | IDestroyable | null> {
        return this.importOneOfFiles({
            ...options,
            files: [options.file /* <- Note: this will just import the file */],
        });
    }

    /**
     * Imports ONE of given files into the board. It will be picked according to priority of file support modules.
     *    1) At first it will pick file supporter module with highest priority and gives it all provided files.
     *    2) At second (if the first module refuse to import all of them (by calling next)) it will pick next module
     *     3) And so on.
     * Typically this is usefull when you have the same content in multiple formats like pasting from the clipboard.
     *
     * @returns objects which can be used to undo the importment (typically ongoing operation) OR null if import fails
     */
    public async importOneOfFiles({
        isNotified,
        isSelected,
        isPersisted,
        files,
        boardPosition,
        logger: loggerNullable,
    }: IImportOptions & {
        files: Array<Blob | File>;
    }): Promise<IOngoingMaterialOperation | IDestroyable | null> {
        isNotified = isNotified ?? true;
        isSelected = isSelected ?? true;
        isPersisted = isPersisted ?? true;

        const logger =
            loggerNullable ||
            consolexBase.groupCollapsed(
                `%cImporting file`,
                `background: #1166cc; color: white; font-size: 1.1em; font-weight: bold; padding: 5px; border-radius: 3px;`,
            );

        const tag = randomTag();
        logger.info(tag);

        let notificationTitle: JSX.Element = <></>;

        try {
            if (!boardPosition) {
                // TODO: [🌷] Some helper for picking middle point in collSpace
                const collSpace = await this.systems.collSpace;
                await collSpace.ready;
                boardPosition = collSpace.pickPoint(windowSize.value.half()).point;
            }

            const filesWithType = await files.mapAsync((file) => amendPropperFileTypeAndName(file));
            const filesTypes = new Set(filesWithType.map((fileWithType) => fileWithType.type));

            //----------------Showing Preview + Notification
            const previewOperation = (await this.systems.virtualArtVersioningSystem)
                .createPrimaryOperation()
                .newArts(
                    new DeletedArt(),

                    /*
                    Note: @@x
                        > new LoadingArt(() => {
                        >     logger.appear(); /* <- TODO: [🌔] Make this better * /
                        >     return `Importing ${tag}`;
                        > }).setShift(boardPosition)
                    */
                )
                .persist();

            notificationTitle = (
                <>
                    {files.map((file, index) => (
                        <span key={index}>{file instanceof File ? file.name : ''}</span>
                    ))}
                </>
            );

            // TODO: Use here alertDialogue when there is [🔊] finished
            const importingNotification = !isNotified
                ? NotificationRegistration.void(/* Note: Null design pattern */)
                : (await this.systems.notificationSystem).publish({
                      //tag: 'import-system-importing',
                      type: 'info',
                      // TODO: Probably allow canBeClosed and handle closing as abortion of import
                      // places: [NotificationPlace.Board],
                      title: notificationTitle,
                      body: (
                          <LoaderInline alt={`Importing ${tag}`} canLoadForever icon="file-image" animation="spinning">
                              <Translate name="ImportSystem / importing">Přidávám na tabuli</Translate>
                          </LoaderInline>
                      ),
                  });
            //----------------

            //----------------Getting native supporters
            const filesWithTypeAndNativeSupportersNames = await filesWithType.mapAsync(async (file) => ({
                file,
                nativeSupporter: await getFileNativeImporter(file),
            }));
            const filesNativeSupportersNames = new Set(
                filesWithTypeAndNativeSupportersNames.map(({ nativeSupporter }) => nativeSupporter).filter(Boolean),
            );
            //----------------

            //----------------Logging
            logger.info('Information about file', {
                // TODO: When logger updated make this as table
                boardPosition,
                files,
                filesWithType,
                filesWithTypeAndNativeSupportersNames,
                filesTypes,
                filesNativeSupportersNames,
                previewOperation,
                importingNotification,
            });
            //----------------

            //----------------Installing supports according to file mime-type
            for (const type of Array.from(filesTypes)) {
                await (await this.systems.fileSupportSyncer).installSupportForFile(type, logger).catch((error) => {
                    logger.error(`Error when installing support for mime-type "${type}"\n`, error);
                });
            }
            //----------------

            //----------------Installing supports according to file native supporter
            for (const moduleName of Array.from(filesNativeSupportersNames)) {
                await (await this.systems.fileSupportSyncer)
                    .installSupportForNative(moduleName, logger)
                    .catch((error) => {
                        logger.error(`Error when installing support for native importer "${moduleName}"\n`, error);
                    });
            }
            //----------------

            //----------------Prepare systems for importing
            const toolbarSystem = await this.systems.toolbarSystem;
            const appState = await this.systems.appState;
            //----------------

            //----------------Prioritizing supporters
            const fileSupportersPrioritized = [...this.fileSupporters].sort((supporter1, supporter2) => {
                if (false) {
                    return 0;
                } else if (supporter1.moduleName && filesNativeSupportersNames.has(supporter1.moduleName)) {
                    return -1;
                } else if (supporter2.moduleName && filesNativeSupportersNames.has(supporter2.moduleName)) {
                    return 1;
                } else if (supporter1.priority > supporter2.priority) {
                    return -1;
                } else if (supporter1.priority < supporter2.priority) {
                    return 1;
                } else {
                    // TODO: If same priority, prompt use if they want to import as text or as html
                    return 0;
                }
            });
            //----------------

            //----------------Importing

            let operation: IDestroyable | IOngoingMaterialOperation | null = null;
            let order = 0;
            for (const supporter of fileSupportersPrioritized) {
                // TODO: [🎋] When logger use subloggers for each supporter

                order = order + 1;
                const color = randomColor();
                const prefix: [string, string] = [
                    `%c${order}`,
                    `
                        display: inline-block;
                        background-color: ${color.toString()};
                        color:  ${color.textColor().toString()};
                        padding: 3px;
                        border-radius: 100%;
                        aspect-ratio: 1 / 1;
                    `,
                ];

                logger.info(
                    ...prefix,
                    `Using file supporter ${supporter.moduleName || 'unknown'} (with priority ${supporter.priority})`,
                );

                for (const { file, nativeSupporter } of filesWithTypeAndNativeSupportersNames) {
                    logger.info(
                        ...prefix,
                        `Impoting file ${file.name}${
                            nativeSupporter
                                ? ''
                                : ` (which should be imported with native supporter ${nativeSupporter})`
                        }`,
                    );

                    if (operation) {
                        logger.info(...prefix, `🟦 Already imported`);
                        continue;
                    }

                    try {
                        let isWillCommitArtsWasCalled = false;
                        const isNativeSupporter = supporter.moduleName === nativeSupporter;

                        const result = await supporter.importFile({
                            logger,
                            boardPosition: Vector.fromObject(boardPosition),
                            file,
                            previewOperation,
                            isNativeSupporter,
                            async willCommitArts() {
                                if (isSelected) {
                                    logger.info(...prefix, `🖼️ Expecting to commit arts`);
                                    isWillCommitArtsWasCalled = true;
                                    toolbarSystem.getToolbar(ToolbarName.Tools).clickOnIcon('selection');
                                } else {
                                    logger.info(...prefix, `🖼️ Expecting to commit arts - but not selecting`);
                                }
                            },
                            next() {
                                return FILE_IMPORT_SUPPORTER_NEXT;
                            },
                        });

                        logger.info(...prefix, { result });

                        if (result === FILE_IMPORT_SUPPORTER_NEXT) {
                            if (!isNativeSupporter) {
                                logger.info(`📦📄⏬ File not imported`);
                            } else {
                                logger.warn(
                                    ...prefix,
                                    spaceTrim(
                                        `⏬ File not imported
                                         ⚠️ But it should be, because in the file contents there is an indication that it can be imported with ${
                                             supporter.moduleName || 'unknown'
                                         }`,
                                    ),
                                );
                            }
                            continue;
                        } else if (toArray(result)[0]! instanceof AbstractArt) {
                            // TODO: [🎚️] IArt variant of this

                            operation = (await this.systems.materialArtVersioningSystem)
                                .createPrimaryOperation()
                                .newArts(...toArray(result as Arrayable<AbstractArt>));

                            if (isPersisted) {
                                (operation as Operation).persist();
                            }
                        } else if (Destroyable.isDestroyable(result)) {
                            operation = result;
                        } else {
                            logger.warn(
                                ...prefix,
                                spaceTrim(
                                    `
                                    ⚠️ Unexpected result from file supporter ${supporter.moduleName}
                                    Expecting one of:
                                        - Calling next() to pass the responsibility to another module
                                        - AbstractArt
                                        - Array with AbstractArts
                                        - Anything Destroyable

                                    But got:
                                `,
                                ),
                                result,
                            );

                            // TODO: Should be this state considered as imported - keep it as it is, if not throw error
                        }

                        if (isWillCommitArtsWasCalled) {
                            if (operation instanceof Operation) {
                                await forImmediate(/* <- TODO: Describe why waiting forImmediate */);
                                appState.setSelection({
                                    selected: operation.arts.filter(
                                        (art) => art instanceof AbstractPlacedArt,
                                    ) as Array<AbstractPlacedArt>,
                                });
                            } else {
                                logger.warn(
                                    ...prefix,
                                    `⚠️ File supporter ${supporter.moduleName} did not commit arts after calling willCommitArts, the result was:`,
                                    { result, operation },
                                );
                            }
                        }

                        if (operation) {
                            logger.info(...prefix, `✔️ Imported`);
                        }
                    } catch (error) {
                        logger.error(...prefix, `❌ Error when importing:`);
                        logger.error(error);
                    }
                }
            }
            //----------------

            //---------------- Cleanup
            previewOperation.abort();
            importingNotification.constrict();
            //----------------

            //----------------Not supported
            if (operation === null) {
                // TODO: ErrorWithAdditional - with custom logger
                logger.warn({
                    files,
                    filesWithType,
                    fileTypes: filesTypes,
                    fileSupportersWithPrioritized: fileSupportersPrioritized,
                });
                throw new Error(
                    spaceTrim(`
                     ❌ Can not import ${filesWithType
                         .map((file) => `file ${file.name} of type ${file.type}`)
                         .join(' OR ')}.
                      There is no compatible file support module.
                    `),
                );
            }
            //----------------

            return operation || null;
        } catch (error) {
            //----------------On erron when importing
            logger.error(error);

            if (isNotified) {
                (await this.systems.notificationSystem).publish({
                    //tag: 'import-system-unsupported-file',
                    type: 'warning',
                    canBeClosed:
                        true /* <- TODO: (probbably @roseckyj) Make some way how to autoclose (with coundown on X) the notifications */,
                    // places: [NotificationPlace.Board],
                    title: notificationTitle,

                    body: (
                        <LoaderInline alt={`Importing ${tag}`} canLoadForever icon="file-image">
                            <Translate name="ImportSystem / unsupported">
                                Bohužel nedokážeme zpracovat tento soubor.
                            </Translate>
                        </LoaderInline>
                    ),
                });
            }
            return null;
            //----------------
        } finally {
            logger.end(/* TODO: !! Do not end */);
        }
    }
}

/**
 * TODO: Importing URLs like YouTube
 */
