import { CSSProperties } from 'react';
import { string_url_image } from '../40-utils/typeAliases';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { AbstractBackgroundArt } from './26-AbstractBackgroundArt';

internalModules.declareModule(() => makeArtModule(BackgroundWallpaperArt));

/**
 * Art setting background with wallpaper style (not scrolling)
 *
 * @collboard-modules-sdk
 */
export class BackgroundWallpaperArt extends AbstractBackgroundArt {
    public static serializeName = 'BackgroundWallpaper';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/background-wallpaper-art',
        deprecatedNames: '@collboard/background-wallpaper-art',
    };

    /**
     * @param src Source of the background pattern tile
     * @param z see `AbstractBackgroundArt`
     */
    public constructor(public src: string_url_image, z?: number) {
        super(z);
    }

    public additionalStyle(): CSSProperties {
        return {
            left: 0 /* <- LIB xyzt .toTopLeft() */,
            top: 0 /* <- LIB xyzt .toTopLeft() */,
            width: '100vw',
            height: '100vh',
            backgroundImage: `url('${this.src /* TODO: Escape */}')`,
            backgroundSize: `cover`,
        };
    }

    public transformStyle(): CSSProperties {
        return {};
    }
}

/**
 *  TODO: Allow to patterns to be in front of wallpapers
 * TODO: [🎚️] Implement IArt
 */
