import { registerItemsInArray, Registration } from 'destroyable';
import { JsonValue } from 'type-fest';
import { Vector } from 'xyzt';
import { MAX_RECURSION_LEVEL } from '../../config-universal';
import { SerializationError } from './errors/10-SerializationError';
import { DeserializationError } from './errors/11-DeserializationError';
import { ISerializable } from './interfaces/ISerializable';
import { ISerializer } from './interfaces/ISerializer';
import { ISerializerRule } from './interfaces/ISerializerRule';
import { dateSerializerRule } from './rules/dateSerializerRule';
import { primitivesSerializerRule } from './rules/primitivesSerializerRule';
import { pureArraySerializerRule } from './rules/pureArraySerializerRule';
import { pureObjectSerializerRule } from './rules/pureObjectSerializerRule';
import { vectorSerializerRule } from './rules/vectorSerializerRule';

/**
 * Serializer can serialize/deserialize objects into pure JSON objects
 *
 * - **Serializer** is generic serializer which can work with any rules; it works synchronously
 * - **Serializer with basic rules** is Serializer which has registered basic rules; it works synchronously
 * - **ArtSerializer** serializes and deseriales Collboard arts and other objects; it works asynchronously
 */
export class Serializer<TSerializable extends ISerializable> implements ISerializer<TSerializable> {
    /**
     * @@x
     * @returns Serializer @@x
     */
    public static createSerializerWithBasicRules(): Serializer<boolean | number | string | object | Date | Vector> {
        const serializer = new Serializer();
        serializer.registerRule(primitivesSerializerRule);
        serializer.registerRule(pureObjectSerializerRule);
        serializer.registerRule(pureArraySerializerRule);
        serializer.registerRule(dateSerializerRule);
        serializer.registerRule(vectorSerializerRule);
        return serializer;
    }

    /**
     * @@x
     */
    private rules: Array<ISerializerRule> = [];

    /**
     * @@x
     */
    private get sortedRules(): Array<ISerializerRule> {
        return [...this.rules].sort((rule1, rule2) => (rule1.priority < rule2.priority ? 1 : -1));
    }

    /**
     * Add rule for serialization objects
     *
     * Each rule is recipe how to take instanced object and convert it to pure JSON and back.
     */
    public registerRule(...rules: Array<ISerializerRule>): Registration {
        return registerItemsInArray({
            base: this.rules,
            add: rules,
            // TODO: registerItemsInArray should automatically sort items according to priority (then remove sortedRules)
        });
    }

    /**
     * Take instanced object and convert it to pure JSON
     *
     * @param value to be serialized
     * @returns pure JSON object
     */
    public serialize(value: TSerializable): JsonValue {
        return this.serializeWithRecursionProtection(value, []);
    }

    /**
     * Take instanced object and convert it to pure JSON
     * With recursion protection
     *
     * @param value to be serialized
     * @param recursionValues stack all the recursion values
     * @returns
     */
    public serializeWithRecursionProtection(value: TSerializable, recursionValues: Array<ISerializable>): JsonValue {
        if (recursionValues.length >= MAX_RECURSION_LEVEL) {
            throw new SerializationError(`Too much recursion`, {
                value,
                recursionValues,
            });
        }

        for (const rule of this.sortedRules) {
            const result = rule.serialize({
                value,
                next: () => {
                    return 'NEXT';
                },
                serialize: (subvalue: ISerializable) => {
                    return this.serializeWithRecursionProtection(subvalue, [...recursionValues, value]);
                },
            });

            if (result !== 'NEXT') {
                return result;
            }
        }

        throw new SerializationError(`There is no rule to serialize the value`, {
            value,
            recursionValues,
            rules: this.sortedRules,
        });
    }

    /**
     * Take JSON made by serialize method and deserialize it
     *
     * @param serialized @@x
     * @returns @@x
     */
    public deserialize(serialized: JsonValue): ISerializable {
        return this.deserializeWithRecursionProtection(serialized, []);
    }

    /**
     * Take JSON made by serialize method and deserialize it
     * With recursion protection
     *
     * @param serialized to be serialized
     * @param recursionValues stack all the recursion values
     * @returns
     */
    public deserializeWithRecursionProtection(
        serialized: JsonValue,
        recursionValues: Array<ISerializable>,
    ): ISerializable {
        if (recursionValues.length >= MAX_RECURSION_LEVEL) {
            throw new DeserializationError(`Too much recursion`, {
                serialized,
                recursionValues,
            });
        }

        for (const rule of this.sortedRules) {
            const result = rule.deserialize({
                serialized,
                next: () => {
                    return 'NEXT';
                },
                deserialize: (subserialized: JsonValue) => {
                    return this.deserializeWithRecursionProtection(subserialized, [...recursionValues, subserialized]);
                },
            });

            if (result !== 'NEXT') {
                return result;
            }
        }

        throw new DeserializationError(`Can not deserialize`, { serialized, rules: this.sortedRules });
    }
}
