import { errorMessageWithAdditional } from './errorMessageWithAdditional';

/**
 * @@x
 */
export class DetailedError<TDetails extends {} = Record<string, unknown>> extends Error {
    public constructor(message: string, public readonly details: TDetails) {
        super(
            errorMessageWithAdditional(
                message,
                details,
            ) /* <- TODO: This is maybe redundant - details should be logged here [0] BUT they are often not */,
        );
        Object.setPrototypeOf(this, DetailedError.prototype);
    }
}

window.addEventListener('unhandledrejection', (event) => {
    if (event.reason instanceof DetailedError || 'details' in event.reason) {
        event.preventDefault();
        handleDetailedError(event.reason);
    }
});

function handleDetailedError(error: DetailedError) {
    // TODO: Can be stack trace reported nativelly (as if this wasnt handled)
    console.error('Caught (in handleDetailedError)', error, error.details /* <- [0] */);
}

/**
 * TODO: Why handleDetailedError not working on production
 */
