import React from 'react';
import { Promisable } from 'type-fest';
import { AsyncContentComponent } from '../../../30-components/utils/AsyncContentComponent';
import { ISystems } from '../../00-SystemsContainer/ISystems';
import { UserInterfaceElementPlace } from '../../UserInterfaceSystem/UserInterfaceElementPlace';
import { IModuleDefinition } from '../interfaces/IModule';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';

/**
 * Makes a module that will add any JSX element on requested place.
 *
 * Note: Making this type of module is not recommended because it is using very low-level API. Consider using higher-level API like makeModalModule, makeTrayToolModule, etc.
 *
 * @collboard-modules-sdk
 */
export function makeUserInterfaceModule(protoModule: {
    manifest?: IModuleManifest;
    place: UserInterfaceElementPlace;
    order?: number;
    render(systems: ISystems): Promisable<JSX.Element>;
}): IModuleDefinition {
    const { manifest, place, order, render } = protoModule;

    return {
        manifest,
        async setup(systems: ISystems) {
            const { userInterfaceSystem } = await systems.request('userInterfaceSystem');

            // TODO: Probbaby not use AsyncContentComponent but just await here

            return userInterfaceSystem.registerElement({
                systems,
                place,
                order,
                element: (
                    <AsyncContentComponent
                        alt={`Waiting for async extra JSX from module ${manifest?.name || 'anonymous module'}`}
                        content={render(systems)}
                    />
                ),
            });
        },
    };
}
