/**
 * Compare two arrays
 *
 * @collboard-modules-sdk
 */
export function isEqualArray<TItem>(
    a: Array<TItem>,
    b: Array<TItem>,
    isEqualItem: (a: TItem, b: TItem) => boolean = (a2: TItem, b2: TItem) => a2 === b2,
): boolean {
    if (a.length !== b.length) {
        return false;
    }
    for (let i = 0; i < a.length; i++) {
        if (!isEqualItem(a[i], b[i])) {
            return false;
        }
    }
    return true;
}
