import React from 'react';
import { merge } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { useObservable } from '../40-utils/react-hooks/useObservable';
import { useSystemsInCore } from '../40-utils/react-hooks/useSystemsInCore';
import { UserInterfaceElementPlace } from '../50-systems/UserInterfaceSystem/UserInterfaceElementPlace';
import { AbstractPlacedArt } from '../71-arts/25-AbstractPlacedArt';
import { AbstractBackgroundArt } from '../71-arts/26-AbstractBackgroundArt';
import { BoardBackgroundsComponent } from './BoardBackgroundsComponent';
import { BoardComponentArts } from './BoardComponentArts';
import { BoardComponentFloatingMenu } from './BoardComponentFloatingMenu';
import { BoardComponentSelected } from './BoardComponentSelected';
import { BoardComponentSelection } from './BoardComponentSelection';

export function BoardComponent() {
    const {
        appState,
        materialArtVersioningSystem,
        toolbarSystem,
        touchController,
        virtualArtVersioningSystem,
        userInterfaceSystem,
    } = useSystemsInCore(
        'appState',
        'materialArtVersioningSystem',
        'toolbarSystem',
        'touchController',
        'virtualArtVersioningSystem',
        'userInterfaceSystem',
    );

    const { value: activeIcons } = useObservable(toolbarSystem.allActiveIcons);

    let cursor = 'auto';
    if (activeIcons && activeIcons[0]! && activeIcons[0]!.boardCursor) {
        cursor = activeIcons[0]!.boardCursor;
    }

    // console.log('🅰️', 'BoardComponent', 'render', activeIcons);

    return (
        <div className="board-container">
            <div
                className="board"
                style={{
                    cursor,
                }}
                ref={(element) => {
                    if (!element) return;
                    try {
                        // TODO: Uninit somewhen and somewhere
                        touchController.addElement(element);
                    } catch (error) {
                        // This is because of multiple calling this ref with same element
                        // consolex.info(`Failed: Init board as touchController element.`);
                    }
                }}
            >
                <BoardComponentArts
                    transform={appState.transform}
                    artsPlaced={merge(
                        materialArtVersioningSystem.artsObservable,
                        virtualArtVersioningSystem.artsObservable,
                    ).pipe(
                        map(
                            (/* TODO: [🏔️] Use piped values */) =>
                                [
                                    ...materialArtVersioningSystem.artsPlaced,
                                    ...virtualArtVersioningSystem.artsPlaced,
                                ].filter((art) => art instanceof AbstractPlacedArt) as Array<AbstractPlacedArt>,
                        ),
                    )}
                    {...{
                        appState,
                        toolbarSystem,
                    }}
                />
                <BoardBackgroundsComponent
                    transform={appState.transform}
                    backgroundArts={merge(
                        materialArtVersioningSystem.artsObservable,
                        virtualArtVersioningSystem.artsObservable,
                    )
                        .pipe(
                            map(
                                (/* TODO: [🏔️] Use piped values */) =>
                                    [...materialArtVersioningSystem.arts, ...virtualArtVersioningSystem.arts].filter(
                                        (art) => art instanceof AbstractBackgroundArt,
                                    ) as Array<AbstractBackgroundArt>,
                            ),
                        )
                        .pipe(
                            // Note: Comparing length is good enough, because there is no situation when one background is replaced by another in one operation
                            distinctUntilChanged((a, b) => a.length === b.length),
                        )}
                />

                <BoardComponentSelection />
                <BoardComponentSelected />
            </div>
            <BoardComponentFloatingMenu />
            {userInterfaceSystem.render(UserInterfaceElementPlace.BoardComponent)}
        </div>
    );
}

/**
 * TODO: [🏛️] Convert Class components to Functional (with hooks).
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 * TODO: [🏊‍♂️] Better naming and distinction of Collboard-specific components vs utils components
 */
