import React from 'react';
import { Icon } from '../../../30-components/menu/Icon';
import { UploadZone } from '../../../30-components/utils/UploadZone';
import { classNames } from '../../../40-utils/classNames';
import { RoutingSystem } from '../../RoutingSystem/0-RoutingSystem';
import { Translate } from '../../TranslationsSystem/components/Translate';
import { ImportSystem } from '../0-ImportSystem';

interface IAddModalComponentProps {
    importSystem: ImportSystem;
    routingSystem: RoutingSystem;
}

export function AddModalComponent({ importSystem, routingSystem }: IAddModalComponentProps) {
    const importUrlRef = React.useRef/* <- TODO: Import and use just a useRef */ <HTMLInputElement>(null);

    return (
        <>
            <div>
                <UploadZone
                    isClickable
                    onFiles={async (droppedFiles: Array<File>) => {
                        if (
                            await droppedFiles
                                .mapAsync((file) =>
                                    importSystem.importFile({
                                        file,
                                    }),
                                )
                                .then((statuses) => statuses.some((status) => !!status))
                        ) {
                            // Note: Navigate home only if at least one file was imported
                            routingSystem.navigateBoardHome();
                        }
                    }}
                >
                    {/* TODO: @roseckyj Bigger icon with multiple files */}
                    <Icon icon="file-image" />
                </UploadZone>
                <div style={{ marginTop: 10 }}>
                    <i>
                        <Translate name="AddModalComponent/ tip" html>
                            Tip: Soubory můžete přetahovat přímo na tabuli
                        </Translate>
                    </i>
                </div>
            </div>
            <hr />
            {/* TODO: [🚥] <ModalLine /> */}

            <div>
                {/*
                TODO: @roseckyj Do we need such instructions?
                      Probably not, because it should be on external sites not here. Or not?

                <Translate name="AddModalComponent / header paragraph" html>
                    <p>
                        Na webech, které s námi spolupracují, můžete najít tlačítko <i>Zobrazit v Collboardu</i>. Toto
                        tlačítko by vám mělo vytvořit odkaz, který sem můžete vložit a následně si tento obsah zobrazit
                        na své tabuli.
                    </p>
                    <p>
                        Stejně tak sem můžete vložit adresu jiné tabule a vloží se vám se její obsah. Nevíte, kde začít?
                        Zkuste
                        <a href="https://h-edu.cz" target="_blank" rel="noopener noreferrer">
                            H-edu
                        </a>
                        !
                    </p>
                </Translate>
                */}

                <div>
                    <span className="modal-input-title">
                        <Translate name="AddModalComponent/ link for import :" html>
                            Odkaz pro přidání:
                        </Translate>
                    </span>
                    <input
                        type="text"
                        className="modal-input"
                        placeholder="https://www.h-edu.cz/media/Obrazky/obrazek1.gif"
                        ref={importUrlRef}
                    />
                    <div>
                        <button
                            type="button"
                            className={classNames('button button-primary modal-button', true ? '' : 'disabled')}
                            onClick={async () => {
                                if (
                                    await importSystem.importUrl({
                                        src: importUrlRef?.current?.value || 'Invalid url',
                                    })
                                ) {
                                    // Note: Navigate home only if the url was imported
                                    routingSystem.navigateBoardHome();
                                }
                            }}
                        >
                            <Translate name="AddModalComponent / add :" html>
                                Přidat
                            </Translate>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
