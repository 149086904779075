import React from 'react';
import { classNames } from '../../40-utils/classNames';
import { copyContent } from '../../40-utils/copyContent';

interface ICopyComponentProps {
    value: string;
    className?: string;
}

/**
 * @collboard-modules-sdk
 */
export function CopyComponent({ className, value }: ICopyComponentProps) {
    const [copied, setCopied] = React.useState(/* <- TODO: Import and use just a useState */ false);

    return (
        <input
            type="text"
            className={classNames(className, copied ? 'copy-ok' : undefined)}
            {...{ value }}
            style={{ cursor: 'pointer' }}
            onClick={(event) => {
                copyContent(event.target as HTMLInputElement);
                setCopied(true);
            }}
            readOnly
        />
    );
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
