import { Registration } from 'destroyable';
import { Vector } from 'xyzt';
import { randomEmoji } from '../40-utils/randomEmoji';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeIconModuleOnModule } from '../50-systems/ModuleStore/makers/makeIconModuleOnModule';
import { ToolbarName } from '../50-systems/ToolbarSystem/0-ToolbarSystem';
import { FrameArt } from '../71-arts/50-FrameArt';

internalModules.declareModule(() => {
    return makeIconModuleOnModule({
        manifest: {
            name: '@collboard/internal/frame-tool',
            deprecatedNames: '@collboard/frame-tool',
            title: { en: 'Drawing export frames' },
            flags: { isHidden: true },
            // [0]
        },
        toolbar: ToolbarName.Tools,
        icon(systems) {
            return {
                name: 'shape',
                autoSelect: true,
                order: 20,
                icon: 'board',
                boardCursor: 'crosshair',
            };
        },
        moduleActivatedByIcon: {
            async setup(systems) {
                const { touchController, collSpace, materialArtVersioningSystem } = await systems.request(
                    'touchController',
                    'collSpace',
                    'appState',
                    'attributesSystem',
                    'materialArtVersioningSystem',
                );
                return Registration.fromSubscription((registerAdditionalSubscription) =>
                    touchController.touches.subscribe(async (touch) => {
                        const firstPointOnBoard = collSpace.pickPoint(touch.firstFrame.position).point;

                        const artInProcess = new FrameArt(Vector.zero(), `Frame ${randomEmoji()}`);

                        const operation = materialArtVersioningSystem.createPrimaryOperation();
                        operation.newArts(artInProcess);

                        registerAdditionalSubscription(
                            touch.frames.subscribe(
                                async (touchFrame) => {
                                    const lastPointOnBoard = collSpace.pickPoint(touchFrame.position).point;

                                    /*/
                                    // Flipping:
                                    artInProcess.shift = firstPointOnBoard;
                                    artInProcess.size = lastPointOnBoard.subtract(artInProcess.shift);
                                    /**/

                                    /**/
                                    //Not flipping:
                                    artInProcess.shift = new Vector(
                                        // TODO: DRY
                                        Math.min(firstPointOnBoard.x, lastPointOnBoard.x),
                                        Math.min(firstPointOnBoard.y, lastPointOnBoard.y),
                                    );
                                    artInProcess.size = new Vector(
                                        // TODO: DRY
                                        Math.max(firstPointOnBoard.x, lastPointOnBoard.x),
                                        Math.max(firstPointOnBoard.y, lastPointOnBoard.y),
                                    ).subtract(artInProcess.shift);
                                    /**/

                                    operation.update(artInProcess);
                                },
                                () => {},
                                () => {
                                    operation.update(artInProcess).persist();
                                },
                            ),
                        );
                    }),
                );
            },
        },
    });
});

/**
 * TODO: [🈁]
 * TODO: [0] Maybe some option how this can work in edit mode> requirePermissions: ['view'],
 */
