import { internalModules } from '../../ModuleStore/internalModules';

internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/download-shortcut',
        deprecatedNames: '@collboard/download-shortcut',
        requirePermissions: ['view'],
    },
    async setup(systems) {
        const { controlSystem, routingSystem } = await systems.request('controlSystem', 'routingSystem');
        return controlSystem.registerControl({
            defaultShortcut: ['Control', 's'],
            executor: () => {
                routingSystem.urlVariables.setValue({ moduleName: '@collboard/internal/download', modulePath: null });
            },
        });
    },
}));
