import React from 'react';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../../50-systems/ModuleStore/makers/makeModalModule';
import { Loader } from './Loader';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            name: '@test/loader-modal',
            description: 'Module made just for internal testing of loading of whole modal',
            flags: {
                isHidden: true /* <- TODO: Modal modules should be always hidden*/,
            },
            requirePermissions: [],
        },
        async createModal() {
            return <Loader alt="Testing of loader" />;
        },
    }),
);

/**
 * TODO: [☮️] Test here new loading design
 */
