/**
 * Detects if given value can be clonned (for example for postMessage)
 *
 * @see https://stackoverflow.com/questions/32673518/how-to-check-if-an-object-can-be-cloned-by-the-structured-clone-algorithm
 * @collboard-modules-sdk
 */
export function isCloneable(value: any): boolean {
    try {
        window.postMessage(value, 'https://non-existing-origin/' /* <- TODO: Some better targetOrigin to test */);
    } catch (error) {
        return false;
    }
    return true;
}
