import * as React from 'react';
import { encodeHtmlchars } from '../../40-utils/jsx-html/encodeHtmlchars';

export function Comment({ children }: { children: string }) {
    return (
        <g
            // TODO: Use here <InnerHtml component
            dangerouslySetInnerHTML={{ __html: `<!--${encodeHtmlchars(children)}-->` }}
        />
    );
}

/**
 * TODO: Remove <g wrapper
 * [💅]
 */
