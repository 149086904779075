import { createGlobalStyle } from 'styled-components';
import { Color } from '../40-utils/color/Color';
import { ISkin } from '../50-systems/StyleSystem/ISkin';

export const BoardStyles = createGlobalStyle<{ skin: ISkin }>`

/* Global */
html,
body {
    height: 100%;
    font-family: 'comenia-sans-web', sans-serif !important;
    overflow: hidden;
    user-select: none;
    position: relative;
    font-size: 0.9rem;
    color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    background-color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
}

*,
::after,
::before {
    box-sizing: border-box;
}

hr {
    border: none;
    background: ${({ skin }) => Color.from(skin.colors.dark).withAlpha(0.05).toString()};
    height: 1px;
    margin: 15px 0;
}

h1,
h2,
h3,
h4,
h5 {
    font-size: 1rem;
    margin-bottom: 0;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 5px;
}

h1 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 25px;
}
h2 {
    font-size: 1rem;
    font-weight: bold;
}
h3 {
    font-size: 1rem;
}

h6 /* subtitle */ {
    font-size: 0.8rem;
    color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    opacity: 0.6;
    margin-bottom: 25px;
    margin-top: -23px;
    font-weight: normal;
}
h1 + h6 {
    margin-top: -23px;
}
h2 + h6 {
    margin-top: -3px;
}

p {
    font-size: 0.9rem;
    margin-bottom: 0.5em;
}

.art p {
    font-size: unset;
    margin-bottom: unset;
}

.clear {
    width: 100%;
    height: 0;
    display: block;
    clear: both;
}

/* Buttons */

.button {
    border: solid 1.5px ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    background-color: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    border-radius: ${({ skin }) => skin.borderRadius}px;
    padding: 7px 18px;
    font-size: 0.9em;
    margin: 0 5px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
}

.button-vertical {
    writing-mode: vertical-lr;
    padding: 18px 7px;
    margin: 5px 0;
}



.button:hover:not(.disabled) {
    background-color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
}

.button-light {
    background-color: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
    border: solid 1.5px ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
}
.button-light:hover:not(.disabled) {
    background-color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
}

.button-dark {
    background-color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    border: solid 1.5px ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
}
.button-dark:hover:not(.disabled) {
    background-color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
}

.button-primary {
    background-color: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
    border: solid 1.5px ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
}
.button-primary:hover:not(.disabled) {
    background-color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
}
.button-primary.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button-red {
    background-color: ${({ skin }) => Color.from(skin.colors.danger).opaque.toString()};
    border: solid 1.5px ${({ skin }) => Color.from(skin.colors.danger).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
}
.button-red:hover:not(.disabled) {
    background-color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.danger).opaque.toString()};
}

.button-green {
    background-color: ${({ skin }) => Color.from(skin.colors.success).opaque.toString()};
    border: solid 1.5px ${({ skin }) => Color.from(skin.colors.success).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
}
.button-green:hover:not(.disabled) {
    background-color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.success).opaque.toString()};
}

.button-ok {
    background-color: ${({ skin }) => Color.from(skin.colors.success).opaque.toString()};
    border: solid 1.5px ${({ skin }) => Color.from(skin.colors.success).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
}
.button-ok:hover:not(.disabled) {
    background-color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.success).opaque.toString()};
}

.button-cancel {
    background-color: ${({ skin }) => Color.from(skin.colors.danger).opaque.toString()};
    border: solid 1.5px ${({ skin }) => Color.from(skin.colors.danger).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
}
.button-cancel:hover:not(.disabled) {
    background-color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.danger).opaque.toString()};
}

/* Menus */
.menu-wrapper {
    position: absolute;
    display: flex;
    flex-wrap: wrap-reverse;
    z-index: 999;
}

.menu-wrapper.left,
.menu-wrapper.right {
    top: 0;
    flex-direction: column;
}
.menu-wrapper.left {
    left: 0;
}
.menu-wrapper.right {
    right: 0;
}

.menu-wrapper.top-ltr,
.menu-wrapper.top-rtl {
    top: 0;
    align-items: start;
}
.menu-wrapper.bottom-ltr,
.menu-wrapper.bottom-rtl {
    bottom: 0;
    align-items: end;
}
.menu-wrapper.top-ltr,
.menu-wrapper.bottom-ltr {
    left: 0;
    flex-direction: row;
}
.menu-wrapper.top-rtl,
.menu-wrapper.bottom-rtl {
    right: 0;
    flex-direction: row;
}
.menu-wrapper.top-ltr > *,
.menu-wrapper.top-rtl > * {
    margin-bottom: 0px;
}
.menu-wrapper.bottom-ltr > *,
.menu-wrapper.bottom-rtl > * {
    margin-top: 0px;
}

.menu-wrapper > * {
    /* TODO: Probbably get rid of this and move to particular module that is using it (like with .menu > input; commit after 8ae63cad09f31460e5c7861d6d3e6302274ff2db) */
    margin: 15px;
}

/* Menu items */
.menu {
    background: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
    border-radius: ${({ skin }) => skin.borderRadius}px;
    padding: 2px;
    display: flex;
    color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    /*box-shadow: 0px 0px 20px 20px ${({ skin }) => Color.from(skin.colors.white).opaque.toString()}*/
}

.menu:empty {
    display: none;
}

.menu-h {
    flex-direction: row;
    max-width: calc(100vw - 30px);
    overflow-x: auto;
    height: 46px;
}
.menu-v {
    flex-direction: column;
    max-height: calc(100vh - 30px);
    overflow-y: auto;
    width: 46px;
}

.menu-clickable {
    cursor: pointer;
}

.menu-floating {
    position: absolute;
    z-index: 11;
    overflow-x: auto;
}
.bubble {
    position: absolute;
    width: 0;
    height: 0;
    z-index: 12;
    margin-top: 45px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-top: 10px solid ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
}

.menu-blue {
    background: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
}
.bubble-blue {
    border-top-color: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()} !important;
}

.menu-h .menu-separator {
    height: 32px;
    width: 1.5px;
    min-width: 1.5px;
    background: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    margin: 5px 5px;
    border-radius: ${({ skin }) => skin.borderRadius}px;
}
.menu-v .menu-separator {
    height: 1.5px;
    min-height: 1.5px;
    width: 32px;
    background: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    margin: 5px 5px;
    border-radius: ${({ skin }) => skin.borderRadius}px;
}
.menu-blue .menu-separator {
    background: white !important;
}

.icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border: solid 2px rgba(0, 0, 0, 0);
    border-radius: ${({ skin }) => skin.borderRadius}px;
    margin: 5px;
    position: relative;
    transition: border 0.05s ease-in;
}
.icon:after {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    display: block;
    content: '';
}
.icon-color span {
    display: block;
    width: 22px;
    height: 22px;
    position: absolute;
    left: 3.5px;
    top: 3.5px;
    display: block;
    border-radius: ${({ skin }) => (skin.circles ? '100%' : '0px')};
}

.icon-char {
    font-size: 20px;
}

.menu-blue .icon-color span {
    border: solid 2px ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
}
.inactive {
    opacity: 0.3;
    cursor: not-allowed;
}
.icon:not(.inactive) {
    cursor: pointer;
}
.icon.active {
    border-color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
}
.menu-blue .icon.active {
    border-color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
}

.menu > button {
    /* TODO: Probbably get rid of this and move to particular module that is using it (like with .menu > input; commit after 8ae63cad09f31460e5c7861d6d3e6302274ff2db) */
    margin: 4px 5px;
}

.menu .label {
    height: 32px;
    line-height: 32px;
    margin: 5px 0;
}


/* Board */
.board-container {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
}
.board {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.board .board-bg {
    /* Background */
    display: block;
    position: absolute;
    left: -100vw;
    top: -100vh;
    width: 300vw;
    height: 300vh;
}

.board .selected {
    outline-offset: 5px;
    outline: 2px dashed ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()} !important;
    position: relative;
}

.art-scaler {
    display: block;
    position: absolute;
    border: 2px solid ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
    /*border-radius: 10px;*/
    background: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
    pointer-events: all !important;
}

.board .art {
    /*  TODO: None of this style should be in css * */
    position: absolute;
}

/*
.board .art,
.board .art * {
    pointer-events: none;
}
*/

.board .art .collisions {
    pointer-events: painted;
}


.board .selection {
    position: absolute;
    border: 2px solid ${({ skin }) => Color.from(skin.colors.primary).withAlpha(0.6).toString()};
    background-color: ${({ skin }) => Color.from(skin.colors.primary).withAlpha(0.05).toString()};
}

.board .selectedBoundingBox {
    position: absolute;
    outline-offset: 5px;
    outline: 2px dashed ${({ skin }) => Color.from(skin.colors.primary).withAlpha(0.85).toString()} !important;
    z-index: 11;
}


/* ===== NOTIFICATIONS ===== */

.notification-container-board {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    flex-direction: column;
    z-index: 20000;
    pointer-events: none;
}

.notification {
    display: block;
    width: 580px;
    max-width: 100%;
    padding: 10px 15px;
    margin: 0 auto;
    border-radius: ${({ skin }) => skin.borderRadius}px;
    margin-top: 15px;
    pointer-events: all;
    user-select: text;
}

@media /*[🍕]*/ only screen and (max-width: 575px) {

    .notification {
        margin-top: 0px;
        border-radius: 0;
    }

    .notification:not(:last-child) {
       border-bottom: 1px solid ${({ skin }) => Color.from(skin.colors.white).withAlpha(0.85).toString()};
    }

}


.notification-header {
    display: flex;
    flex-direction: row;
    user-select: text;
}

.notification-title {
    opacity: 0.5;
    font-size: 0.8em;
    margin-bottom: 5px;
    width: 100%;
    user-select: text;
}

.notification-subtitle {
    opacity: 0.5;
    font-size: 0.6em;
    margin-bottom: 5px;
    width: 100%;
    user-select: text;
}

.notification-close {
    display: block;
    width: 17px;
    height: 17px;
    cursor: pointer;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.46/icons/no-w@2x.png');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: -5px;
}

.notification-body {
    margin-bottom: 5px;
}

.notification-footer {
    text-align: right;
    margin-top: 10px;
}

.notification-more{
    width: 100px;
    height: 30px;
    text-align: center;
}

.notification-more .inner{
    font-size: 35px;
    margin-top: -27px;
}



.notification.notification-info {
    background: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.light).textColor().toString()};
}
.notification.notification-warning {
    background: ${({ skin }) => Color.from(skin.colors.warning).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.warning).textColor().toString()};
}

.notification.notification-error {
    background: ${({ skin }) => Color.from(skin.colors.error).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.error).textColor().toString()};
}

.notification.notification-success {
    background: ${({ skin }) => Color.from(skin.colors.success).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.success).textColor().toString()};
}

/* ===== CUSTOM ====== */
input {
    width: 170px;
    font-size: 0.9rem;
    color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    background: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
    border: none;
    padding: 8px 12px;
    border-radius: ${({ skin }) => skin.borderRadius}px;
}
.input-copy {
    position: relative;
}
.input-copy {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.46/icons/copy@2x.png');
    background-position: right 10px center;
    background-size: 20px;
    background-repeat: no-repeat;
    padding-right: 35px;
}
.input-copy.copy-ok {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.46/ok@2x.png');
}

.input-slider {
    width: 100px;
    margin: 0 8px;
    position: relative;
}

.input-slider.input-slider-width:before {
    display: block;
    position: absolute;
    top: 11px;
    width: 0;
    height: 0;
    border-right: 100px solid ${({ skin }) => Color.from(skin.colors.dark).withAlpha(0.098).toString()};
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    background: none;
    border-radius: 100px;
    pointer-events: none;
}

.input-slider.input-slider-width input {
    background: none;
    padding: 0 5px;
    height: 10px;
    margin-top: 16px;
}

.input-slider input {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px;
    background: ${({ skin }) => Color.from(skin.colors.dark).withAlpha(0.2).toString()};
    outline: none;
    padding: 0;
    border-radius: 2px;
    margin-top: 20px;
}

.input-slider input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    cursor: pointer;
}

.input-slider input::-moz-range-thumb {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    cursor: pointer;
    background: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    border: none;
}

.menu-blue .input-slider.input-slider-width:before {
    border-right: 100px solid ${({ skin }) => Color.from(skin.colors.white).withAlpha(0.3).toString()};
}

.menu-blue .input-slider input::-webkit-slider-thumb {
    background: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
}

.menu-blue .input-slider input::-moz-range-thumb {
    background: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
}

.zoom-status {
    font-size: 0.8rem;
    text-align: center;
}
.click-detector {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

/* TODO: [㊗️] All art css should be scoped in arts */

/* TODO: [㊗️] Move to text module */
.text-art {
    overflow: hidden;
    white-space: nowrap;
}

/* TODO: [㊗️] Move to text module */
.text-art p,
.text-field p {
    margin: 0;
    padding: 0;
}

/* TODO: [㊗️] Move to text module */
.text-field.selected {
    user-select: text;
    -webkit-user-select: text;
}

/* TODO: [㊗️] Move to text module */
/*
TODO: @roseckyj remove after "Udělám to asi jinak -> prázdné texty se automaticky smažou" (https://github.com/collboard/collboard/pull/7#discussion_r407359692)
.tool-4 .text-art {
    outline-offset: 5px;
    outline: 2px solid rgb(0, 158, 221, 0.1);
}
*/

/* TODO: [㊗️] Move to text module */
.text-field {
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
    z-index: 11;
}

.loading{
  animation-name: loading;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}


.cornerstone {
    display: block;
    border-radius: 100%;
    background: ${({ skin }) => Color.from(skin.colors.black).withAlpha(0.2).toString()};
    width: 4px;
    height: 4px;
    /* TODO: Probbably use "transform: translate(-50%, -50%);" instead */
    margin-left: -2px;
    margin-top: -2px;
}

.box {
    display: block;
    width: 100%;
    border: solid 2px ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
    border-radius: ${({ skin }) => skin.borderRadius}px;
    margin-bottom: 10px;
}

.box-body {
    padding: 8px 12px;
}

.box-header {
    cursor: pointer;
    background-color: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
    padding: 8px 12px;
}

.box-collapsed .box-body {
    display: none;
}

.source {
    font-family: 'Courier New', Courier, monospace;
    user-select: text;
}



/* ===== TIMER ====== */

.timer {
    font-size: 50px;
    text-align: center;
}

.timer div {
    width: 60px;
    display: inline-block;
    text-align: left;
}

.timer .hours {
    text-align: right;
}

.timer .small {
    font-size: 20px;
    margin-left: 5px;
    width: 30px;
}

.timer.ending {
    animation-name: spinning-colors;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

/* ===== DICE ====== */

.dice {
    font-size: 60px;
    line-height: 90px;
    text-align: center;
}

.diceDot {
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    background-color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
    border-radius: 100%;
    transform: translate(-50%, -50%);
}

/* ===== TRAY ====== */

.trayToolbarWrapper {
    display: block;
    position: absolute;
    bottom: -250px;
    left: 0;
    right: 0;
    z-index: 8889; /* Has to be greater, than 8888 (z-index of snapped button) */
    display: flex;
    flex-direction: column;
    transition: bottom 0.2s ease;
}
.trayToolbarWrapperOpen {
    bottom: 0;
}

.trayToolbarTabsBar {
    display: flex;
    flex-direction: row;
}
.trayToolbarTabs {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    max-width: calc(100% - 42px);
    overflow-x: auto;
}
.trayToolbarTab {
    padding: 0 30px;
    line-height: 45px;
    margin-left: 10px;
    border-radius: 10px 10px 0 0;
    background-color: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
    display: block;
    color: #7d7d7d;
    cursor: pointer;
    transition: all 0.05s ease-in;
    white-space: nowrap;
}
.trayToolbarTabActive {
    background-color: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
    color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
}
.trayToolbarTab:not(.trayToolbarTabActive):hover {
    background-color: #d9d9d9;
    color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
}

.trayToolbarBody {
    background-color: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
    height: 160px;
    padding: 0 20px;
    border-top: solid 6px ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    /* Note: To have place for scrolling arrow */
    padding-right: 100px;
}

.trayToolbarContentWrapper {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
}

.trayToolbarItem {
    display: inline-block;
    position: relative;
    vertical-align: bottom;
    cursor: grab;
    margin-right: 5px;
}

.trayToolbarGroup {
    display: inline-block;
    position: relative;
    margin-right: 20px;
    padding-top: 25px;
    padding-bottom: 0px;
    vertical-align: top;
}

.trayToolbarGroup:not(:last-child) {
    margin-right: 20px;
    padding-right: 20px;
    /*border-right: 2px solid ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()}22;*/
}

.trayToolbarGroupTitle {
    display: block;
    position: absolute;
    left: 8px;
    top: 0;
    opacity: 0.5;
    font-size: 10px;
    font-weight: bold;
}

.trayToolbarTabNew {
    padding: 0;
    border-radius: 35px 35px 0 0;
    width: 45px;
    min-width: 45px;
    height: 45px;
    line-height: unset;
    position: relative;
}
.trayToolbarTabImage {
    display: block;
    width: 37px;
    height: 37px;
    background-color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
    border-radius: 100%;
    position: absolute;
    left: 4px;
    top: 4px;
    /* Note: [🕵️‍♀️] Border params are inline-duplicated to make them work */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.trayToolbarWarning {
    margin-top: 60px;
    margin-left: 30px;
    opacity: 0.5;
    font-style: italic;
}
.trayToolbarWarningEmoji {
    font-size: 50px;
    font-style: normal;
}

 .trayArtWrapper {
  stroke: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
  stroke-width: 3px;
}

 .trayToolbarTabImage {
  border: solid 3px ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
}




.trayToolbarMove{
  z-index: 10;
  position: fixed;
  display: block;
  right: 0;
  width: 50px;
  max-width: 20vw;
  height: 160px;
  background-image: linear-gradient(
    90deg
    ,${({ skin }) => Color.from(skin.colors.white).transparent.toString()}
    ,${({ skin }) => Color.from(skin.colors.white).opaque.toString()}
    );
}

.trayToolbarMove .arrow{

  width: 100%;
  height: 100%;

  pointer-events: none;


  background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.46/ui/arrows/arrow-left.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}


/* ======= H-EDU ========= */
/* TODO: [♨️] This should be transfered into existing external module */

.HeduModule .trayArtWrapper {
    stroke: #1c3660;
    stroke-width: 3px;
}

.HeduModule .trayToolbarTabImage {
    border: solid 3px #d22229;
}

/* ===== MONTESSORI ====== */
/* TODO: [♨️] This should be transfered into existing external module */

.MontessoriModule .trayArtWrapper {
    stroke: #000000;
    stroke-width: 2px;
}

.MontessoriModule .trayToolbarTabImage {
    border: solid 2px #000000;
    background: #ebebeb;
}

/* ======== XMAS ========= */
.snowingWrapper {
    z-index: 1000;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vw;
    pointer-events: none;
}


/* ======== Responsive ========= */

/* XS */
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon {
        width: 28px;
        height: 28px;
        min-width: 28px;
        min-height: 28px;
        margin: 1px;
    }
    .icon-color span {
        width: 20px;
        height: 20px;
        left: 2.5px;
        top: 2px;
    }
    .menu-h .menu-separator {
        height: 28px;
        margin: 1px 5px;
    }
    .menu-v .menu-separator {
        width: 28px;
        margin: 5px 1px;
    }
    .menu-h {
        max-width: calc(100vw - 16px);
        height: 34px;
    }
    .menu-v {
        max-height: calc(100vh - 16px);
        width: 34px;
    }
    .menu-wrapper > * {
        margin: 8px;
    }
    .bubble {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;

        border-top: 8px solid ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
        margin-top: 34px;
    }
}

/* @rosecky design things bellow */

.edge-bottom {
    position: fixed;
    bottom: 0;
    left: 50vw;
    transform: translate(-50%, 0);
    z-index: 8888;
}

.edge-bottom-right-snapped {
    /** /
    border: 1px solid red; /**/
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 8888;
    transform-origin: bottom right;
    transform: rotate(270deg) translate(calc(100% + 10vh), calc(-100% + 36px));
}
`;

/**
 * TODO: [🗾] Get rid of majority of theese styles - scope them OR move to each modules
 */
