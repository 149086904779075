import { registerItemsInArray, Registration } from 'destroyable';
import { errorMessageWithAdditional } from '../../../40-utils/errors/errorMessageWithAdditional';
import { union } from '../../../40-utils/sets/union';
import { string_module_category, string_module_name } from '../../../40-utils/typeAliases';
import { AbstractSystem } from '../../10-AbstractSystem/AbstractSystem';
import { IModuleDefinition } from '../interfaces/IModule';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { IModuleSearchCriteria } from '../interfaces/IModuleSearchCriteria';
import { IModulesStorageStrong } from '../interfaces/IModulesStorage';
import { IModuleStoreConnector } from '../interfaces/IModuleStoreConnector';
import { IModuleStoreConnectorSearchResult } from '../interfaces/IModuleStoreConnectorSearchResult';
import { sortModules } from '../utils/sortModules';
import { StorageModuleStoreConnector } from './StorageModuleStoreConnector';

/**
 * ModuleStore unites all module store connectors into one API, so consumer have same way how to get internal or external module
 *
 * Note: Modules storage - is just getter / setter for modules
 *       Modules store   - has full logic of modules domain
 *
 * @collboard-system
 */
export class ModuleStore extends AbstractSystem implements IModuleStoreConnector {
    public readonly connectors: Array<IModuleStoreConnector> = [];

    /**
     * @proxy
     */
    public getModule(name: string_module_name): IModuleDefinition | null {
        for (const connector of this.connectors) {
            const module = connector.getModule(name);
            if (module) return module;
        }

        return null;
    }

    public async init() {}

    /**
     * Note: When searching with limit, it depends on order of connectors registration
     */
    public registerModuleStoreConnector(modulesConnector: IModuleStoreConnector): Registration {
        const registration = registerItemsInArray({
            base: this.connectors,
            add: [modulesConnector],
        });

        // TODO: How to deal with situation when you return Registration and also adding subdestroyable to itself?
        this.addSubdestroyable(registration);
        return registration;
    }

    /**
     * Note: When searching with limit, it depends on order of connectors registration
     */
    public registerModuleStorage(modulesStorage: IModulesStorageStrong): Registration {
        return this.registerModuleStoreConnector(new StorageModuleStoreConnector(this.systems, modulesStorage));
    }

    public async search(searchCriteria: IModuleSearchCriteria): Promise<IModuleStoreConnectorSearchResult> {
        if (this.connectors.length === 0) {
            throw new Error(
                errorMessageWithAdditional(
                    `Trying to search in the module store but there is no connector registered`,
                    {
                        hint: `Try to search later when the connectors are registered` /* TODO: [🏜️] Maybe make this hint as an official param of errorMessageWithAdditional */,
                        searchCriteria,
                        moduleStore: this,
                    },
                ),
            );
        }

        let manifests: Array<IModuleManifest>;

        if (searchCriteria.limit) {
            manifests = [];
            let limit = searchCriteria.limit;

            for (const connector of this.connectors) {
                const result = await connector.search({ ...searchCriteria, limit });
                manifests.push(...result.manifests);
                limit -= result.manifests.length;

                if (limit <= 0) break;
            }
        } else {
            // TODO: How to deal with situation when one connector returns search results immediately and another is pending?
            //       Maybe it should be RxJS Observable not simple Promise

            manifests = (
                await this.connectors.mapAsync(async (connector) => (await connector.search(searchCriteria)).manifests)
            ).flat();
        }

        manifests.sort(sortModules.bind(null, await this.systems.translationsSystem));

        return {
            manifests,
        };
    }

    public async getCategories(): Promise<Set<string_module_category>> {
        return union(...(await this.connectors.mapAsync(async (connector) => connector.getCategories())));
    }

    // TODO: Probably destroy the connectors
}
