import fetch from 'cross-fetch'; /* <- TODO: [🌿] Use the Node native fetch */
import spaceTrim from 'spacetrim';
import { errorMessageWithAdditional } from '../errors/errorMessageWithAdditional';
import { string_url } from '../typeAliases';

/**
 * Makes GET request directly and if it fails through the given proxy
 *
 * @collboard-modules-sdk
 */
export async function fetchProxied(url: string_url, proxyUrl: string_url): Promise<Response> {
    let nonProxiedResponse: Response | null = null;
    let proxiedResponse: Response | null = null;

    try {
        nonProxiedResponse = await fetch(url);

        if (nonProxiedResponse.status !== 200) {
            throw new Error(`Ended with status ${nonProxiedResponse.status}`);
        }

        return nonProxiedResponse;
    } catch (nonProxiedError: any) {
        try {
            proxiedResponse = await fetch(proxyUrl + `?url=${encodeURIComponent(url.toString())}`);

            if (proxiedResponse.status !== 200) {
                throw new Error(`Ended with status ${proxiedResponse.status}`);
            }

            return proxiedResponse;
        } catch (proxiedError: any) {
            throw new Error(
                errorMessageWithAdditional(
                    spaceTrim(
                        (block) => `
                            Failed to fetch ${url.toString()}

                            Non-proxied error: ${block(nonProxiedError.message)}
                            Proxied error:     ${block(proxiedError.message)}
                        `,
                    ),
                    { nonProxiedResponse, nonProxiedError, proxiedResponse, proxiedError },
                ),
            );
        }
    }
}

/**
 * TODO: Probably some other way to pass proxyUrl - maybe with high order function?
 */
