import React from 'react';
import { IVectorData } from 'xyzt';
import { classNames } from '../../40-utils/classNames';
import { windowSize } from '../../40-utils/getWindowSize';

interface IFloatingMenuProps {
    children: Array<JSX.Element> | JSX.Element;
    className?: string;
    screenPosition: IVectorData;
}

const FLOATING_MENU_PAGE_OFFSET = 20;
const FLOATING_MENU_BUBBLE_OFFSET_X = 7;

export function FloatingMenu(props: IFloatingMenuProps) {
    // TODO: Transform top,left to vector!
    const top = (props.screenPosition.y || 0) - 80;
    const left = (props.screenPosition.x || 0) - 7;

    if (
        top > 0 &&
        (props.screenPosition.y || 0) < windowSize.value.y &&
        (props.screenPosition.x || 0) > 0 &&
        (props.screenPosition.x || 0) < windowSize.value.x
    ) {
        return (
            <>
                <div
                    className={classNames('menu', 'menu-h', 'menu-floating', 'menu-blue', 'shadow', props.className)}
                    style={{
                        top,
                        left,
                    }}
                    ref={(floatingMenuElement) => {
                        if (floatingMenuElement) {
                            const boundingRect = floatingMenuElement.getBoundingClientRect();
                            floatingMenuElement.style.width = 'unset';
                            if (boundingRect.right > windowSize.value.x - FLOATING_MENU_PAGE_OFFSET) {
                                floatingMenuElement.style.left =
                                    windowSize.value.x - FLOATING_MENU_PAGE_OFFSET - boundingRect.width + 'px';
                            }
                            if (boundingRect.left < FLOATING_MENU_PAGE_OFFSET) {
                                floatingMenuElement.style.left = FLOATING_MENU_PAGE_OFFSET + 'px';
                            }
                            if (boundingRect.width >= windowSize.value.x - 2 * FLOATING_MENU_PAGE_OFFSET) {
                                floatingMenuElement.style.left = FLOATING_MENU_PAGE_OFFSET + 'px';
                                floatingMenuElement.style.width =
                                    windowSize.value.x - 2 * FLOATING_MENU_PAGE_OFFSET + 'px';
                            }
                        }
                    }}
                >
                    {props.children}
                </div>
                <div
                    className="bubble bubble-blue"
                    style={{
                        top,
                        left: left + FLOATING_MENU_BUBBLE_OFFSET_X,
                    }}
                    ref={(self) => {
                        if (self) {
                            const boundingRect = self.getBoundingClientRect();
                            if (
                                boundingRect.right >
                                windowSize.value.x - FLOATING_MENU_PAGE_OFFSET - FLOATING_MENU_BUBBLE_OFFSET_X
                            ) {
                                self.style.left =
                                    windowSize.value.x -
                                    FLOATING_MENU_PAGE_OFFSET -
                                    FLOATING_MENU_BUBBLE_OFFSET_X -
                                    boundingRect.width +
                                    'px';
                            }
                            if (boundingRect.left < FLOATING_MENU_PAGE_OFFSET + FLOATING_MENU_BUBBLE_OFFSET_X) {
                                self.style.left = FLOATING_MENU_PAGE_OFFSET + FLOATING_MENU_BUBBLE_OFFSET_X + 'px';
                            }
                        }
                    }}
                />
            </>
        );
    }

    return null;
}
