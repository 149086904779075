import spaceTrim from 'spacetrim';
import { forImmediate } from 'waitasecond';
import { randomTag } from '../randomTag';

/**
 * TODO: [🔅] Put all %c styled console logs styles into one config place
 */
const ADDITIONAL_INFO_CONSOLE_LOG_STYLE = `color: #f55;`;

/**
 * Wraps the error message with additional info that will be nicely logged to the console bellow thrown error
 *
 * @param errorMessage
 * @returns {string} - error message with reference to additional info in console appended to the end
 *
 * @collboard-modules-sdk
 */
export function errorMessageWithAdditional(errorMessage: string, additional: Record<string, any>): string {
    // TODO: Do not take random emoji but deterministic emoji based on errorMessage+additional
    const tag = randomTag(); /* <- TODO: Generate emoji deterministicly from the message */

    (async () => {
        await forImmediate(/* to log additional into after the actual error */);
        // Note: Here should be really used console not consolex because the error is visible and it will be confusiond to have thrown error without additional info
        console.groupCollapsed(`%c${tag} More details about occured error`, ADDITIONAL_INFO_CONSOLE_LOG_STYLE);
        console.info(`${tag} ${errorMessage}`);
        for (const [key, value] of Object.entries(additional)) {
            console.info(`${tag} ${key}: `, value);
        }
        console.groupEnd();
    })();

    return spaceTrim(`
        ${tag} ${errorMessage}

        For more details see message in console marked as ${tag} bellow.
    `);
}

/**
 * TODO: In additional there can be "hint" key to add message before "For more details"
 * TODO: Repair all JSDoc types @see https://jsdoc.app/tags-type.html AND change "jsdoc-format": true,
 * TODO: [🏜️] Maybe make hint as an official param of errorMessageWithAdditional
 */
