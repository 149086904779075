import { Registration } from 'destroyable';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { ModuleInstallation } from '../../../50-systems/ModuleStore/ModuleInstallation';
import { ColorScheme } from '../../../50-systems/StyleSystem/utils/watchPreferedColorScheme';
import { LightDevelopmentSkinModule } from './10-LightDevelopmentSkinModule';
import { DarkDevelopmentSkinModule } from './20-DarkDevelopmentSkinModule';

internalModules.declareModule(() => ({
    manifest: { name: 'DefaultDevelopmentSkin', requirePermissions: [] },
    async setup(systems) {
        const { styleSystem } = await systems.request('styleSystem');

        let installedSkin: ModuleInstallation | null = null;

        return Registration.join(
            Registration.fromSubscription(() =>
                styleSystem.colorScheme.subscribe(async (value) => {
                    if (installedSkin) {
                        installedSkin.destroy();
                    }

                    if (value === ColorScheme.Light) {
                        installedSkin = await ModuleInstallation.install(LightDevelopmentSkinModule, systems);
                    } else {
                        installedSkin = await ModuleInstallation.install(DarkDevelopmentSkinModule, systems);
                    }
                }),
            ),
            new Registration(() => {
                if (installedSkin) {
                    installedSkin.destroy();
                }
            }),
        );
    },
}));

// TODO: 🎨 Installation of skins in the module store
