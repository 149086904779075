import React, { CSSProperties } from 'react';
import ContentEditable from 'react-contenteditable';
import { string_html } from '../../40-utils/typeAliases';

export interface IEditableHtmlContentProps {
    /**
     * The content
     */
    html: string_html;

    /**
     * Is the content editable or just static html
     *
     * - If `false` there will be only simle <div/> with given static html
     * - If `true` it is editable and focused when the isEditableAndFocused is switched
     *
     * Note: On whole page there MUST be ONLY ONE instance of <Content/> which is isEditableAndFocused
     */
    isEditableAndFocused: boolean;

    /**
     * Callback when contenteditable is changed
     *
     * Note: This wont be triggered in any circumstances if isEditableAndFocused is false
     *
     * @param newContent in HTML format after change
     */
    onHtmlChange(newContent: string_html): void;

    /**
     * Callback when editing is finished
     *
    onFinish(){
        // TODO: Use OR loose
    }
    */

    /**
     * Allow to insert rich HTML by Ctrl+V
     */
    isHtmlPasteAllowed?: boolean;

    /**
     * Style for the most internal div
     */
    style?: CSSProperties;
}

/**
 * Shows the content with optional contenteditable optimized for multiplayer apps
 *
 * @collboard-modules-sdk
 */
export function EditableHtmlContent(props: IEditableHtmlContentProps) {
    const { html, isEditableAndFocused, onHtmlChange, isHtmlPasteAllowed, style } = props;

    if (!isEditableAndFocused) {
        return <div dangerouslySetInnerHTML={{ __html: html }} {...{ style }} />;
    }

    return (
        <div
            ref={(element) => {
                if (!element) {
                    return;
                }

                const editableElement = element.children[0]! as HTMLDivElement;
                editableElement.click(/* [🧶] */);
                editableElement.focus(/* [🧶] */);
            }}
        >
            <ContentEditable
                onChange={(event) => void onHtmlChange(event.target.value)}
                {...{ html, style }}
                onPaste={(event) => {
                    if (isHtmlPasteAllowed) {
                        return /* HTML is allowed so keeping default action */;
                    }

                    event.preventDefault();
                    const text = event.clipboardData.getData('text/plain');
                    document.execCommand('insertHTML', false, text);
                }}
            />
        </div>
    );
}

/**
 * TODO: Keep caret @see copilotMonkey
 * TODO: Maybe use this component ACRY instead of dangerouslySetInnerHTML
 * TODO: How is the <ContentEditable made internally
 * TODO: What is difference between onChange vs onInput in react-contenteditable
 */
