import { BehaviorSubject } from 'rxjs';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { IModuleStatusReporter } from '../interfaces/IModuleStatusReporter';
import { ModuleStatus } from '../ModuleStatus';
import { pickMostImportantModuleStatus } from './pickMostImportantModuleStatus';

export function joinModuleStatusReporters(...reporters: Array<IModuleStatusReporter>): IModuleStatusReporter {
    return {
        statusOf(moduleManifest: IModuleManifest) {
            const statusesfromReporters = reporters.map((reporter) => reporter.statusOf(moduleManifest));

            const mostImportantModuleStatus = () =>
                pickMostImportantModuleStatus(
                    ...statusesfromReporters.map((statusFromReporter) => statusFromReporter.value),
                );

            const joinedStatus = new BehaviorSubject<ModuleStatus>(mostImportantModuleStatus());

            statusesfromReporters.forEach((statusFromReporter) =>
                statusFromReporter.subscribe({
                    next() {
                        const candidateForJoinedStatusValue = mostImportantModuleStatus();

                        if (candidateForJoinedStatusValue !== joinedStatus.value) {
                            joinedStatus.next(candidateForJoinedStatusValue);
                        }
                    },
                }),
            );

            return joinedStatus;
        },
        isDestroyed: false,
        destroy: () => {
            /* TODO: Implement */
        },
    };
}

// TODO: Unsubscriptions and memory leaks
