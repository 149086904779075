import { IBehavior, IBehaviorOptions } from '../0-IBehavior';
import { selectionToolDraggingBehavior } from './selectionToolDraggingBehavior';
import { selectionToolScalingBehavior } from './selectionToolScalingBehavior';
import { selectionToolSelectionBoxBehavior } from './selectionToolSelectionBoxBehavior';

interface ISelectionToolBehaviors {
    isScaling?: boolean;
    isDragging?: boolean;
    isRotating?: boolean;
    isSelectionBox?: boolean;
}

const DEFAULT_SELECTION_TOOL_BEHAVIORS: ISelectionToolBehaviors = {
    isDragging: true,
    isScaling: true,
    isRotating: true,
    isSelectionBox: true,
};

/**
 * Creates behavior of the selection tool
 *
 * @param useBehaviors Specifies, which parts of the selection tool behavior
 * should be used. Defaults to `true` on all.
 * - `isScaling` (Scaling objects)
 * - `isDragging` (Moving objects)
 * - `isSelectionBox` (Creating selection box)
 * - `isRotating` (Rotating objects) - **not yet implemented**
 *
 * @see IBehaviorOptions
 *
 * @collboard-modules-sdk
 */
export async function createSelectionToolBehavior(
    useBehaviors: ISelectionToolBehaviors = DEFAULT_SELECTION_TOOL_BEHAVIORS,
): Promise<IBehavior> {
    return async (behaviorProps: IBehaviorOptions): Promise<boolean> => {
        return (
            (!!useBehaviors.isScaling && (await selectionToolScalingBehavior(behaviorProps))) ||
            (!!useBehaviors.isDragging && (await selectionToolDraggingBehavior(behaviorProps))) ||
            (!!useBehaviors.isSelectionBox && (await selectionToolSelectionBoxBehavior(behaviorProps)))
        );
    };
}

/**
 * TODO: [🎂] Probably remove systems from IBehaviorProps and use useSystems (or similar mechanism) instead
 */
