import React from 'react';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../50-systems/ModuleStore/makers/makeModalModule';
import { ToolbarName } from '../../50-systems/ToolbarSystem/0-ToolbarSystem';

internalModules.declareModule(() =>
    makeModalModule({
        manifest: {
            flags: { isHidden: true }, // TODO: How to deal and show this dev modules
            name: 'PlayingWithToolbarSystem',
            description: `Samples what you can do with the toolbar system.`,
            author: Authors.collboard,
            requirePermissions: [],
        },
        async createModal(systems) {
            const { toolbarSystem } = await systems.request('toolbarSystem');
            return (
                <>
                    <button
                        onClick={() => {
                            toolbarSystem.getToolbar(ToolbarName.Tools).clickOnIcon('freehand');
                        }}
                    >
                        Trigger Freehand
                    </button>
                    <br />
                    <button
                        onClick={() => {
                            toolbarSystem.getToolbar(ToolbarName.Tools).clickOnIcon('erase');
                        }}
                    >
                        Trigger Eraser
                    </button>
                    <br />
                    <button
                        onClick={() => {
                            toolbarSystem.getToolbar(ToolbarName.Tools).clickOnIcon('store');
                        }}
                    >
                        Trigger Module store
                    </button>
                    <br />
                    <button
                        onClick={() => {
                            toolbarSystem.getToolbar(ToolbarName.Tools).clickOnIcon('fooooo_nonsense');
                        }}
                    >
                        Trigger some nonsense
                    </button>
                </>
            );
        },
    }),
);
