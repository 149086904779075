import { extension } from 'mime-types';
import { ISystemsExtended } from '../00-SystemsContainer/ISystems';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';

/**
 * UsercontentSystem provides API to upload user content to the server.
 *
 * @collboard-system
 */
export class UsercontentSystem extends AbstractSystem {
    public constructor(systems: ISystemsExtended, private usercontentUrl: URL) {
        super(systems);
    }

    protected async init() {}

    public async upload(file: File | Blob): Promise<URL> {
        // TODO: [🆎] Frontend itself can (pre)generate final url

        // Note: for fulltext search> `/upload`
        const response = await fetch(`${this.usercontentUrl}upload`, {
            method: 'POST',
            body: file,
            headers: {
                'X-Collboard-Business-Name': await (await this.systems.businessSystem).businessName,
                'X-Auth-Token': (await this.systems.identitySystem).browserId,
                'Content-Type': file.type,
                'X-File-Name' /* <- TODO: Probbably use Content-Disposition */:
                    (file as File).name ||
                    `untitled.${
                        extension(
                            file.type,
                        ) /* <- TODO: Probably some util for default finenames like image.png, document.pdf... */
                    }`,
            },
        });

        const body = (await response.json()) as any;

        /*
        TODO:

        if (typeof body !== 'object' || body === null || !('url' in body) || typeof body.url !== 'string') {
            throw new Error(errorMessageWithAdditional(`Unexpected response from usercontent server`, { body }));
        }

        if (!isValidUrl(body.url)) {
            throw new Error(errorMessageWithAdditional(`Corrupted URL from usercontent server`, { body }));
        }
        */

        return new URL(body.url);
    }
}
