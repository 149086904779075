import { Destroyable, NotFoundError } from 'destroyable';
import spaceTrim from 'spacetrim';
import { parseModuleName } from '../../../40-utils/parseModuleName';
import { string_module_category, string_module_name, string_url, string_version_dependency } from '../../../40-utils/typeAliases';
import { consolex } from '../../../consolex';
import { ISystemsExtended } from '../../00-SystemsContainer/ISystems';
import { IModuleDefinition } from '../interfaces/IModule';
import { IModuleSearchCriteria } from '../interfaces/IModuleSearchCriteria';
import { IModulesStorageStrong } from '../interfaces/IModulesStorage';
import { IModuleStoreConnector } from '../interfaces/IModuleStoreConnector';
import { IModuleStoreConnectorSearchResult } from '../interfaces/IModuleStoreConnectorSearchResult';
import { makeExternalModule } from '../makers/makeExternalModule';
import { isModulePassingSearchCriteria } from '../utils/isModulePassingSearchCriteria';
import { ModulesStorage } from './ModulesStorage';

/**
 * ExternalModuleStoreConnector communicates with the server via API and downloads modules from there
 *
 */
export class ExternalModuleStoreConnector extends Destroyable implements IModuleStoreConnector {
    // [🥝] private readonly modulesPath: Promise<string_url>;

    public constructor(private systems: ISystemsExtended, public readonly moduleStoreUrl: URL) {
        super();
        // [🥝] this.modulesPath = this.createModulesPath();
    }

    /*
    [🥝]
    private async createModulesPath() {
        try {
            const response = await fetch(`${this.moduleStoreUrl}about`);
            const content = await response.json();

            // TODO: Verify that it is a valid module store URL + check version compatibility
            // TODO: Probably take whole /about response and save it
            return content.modulesPath;
        } catch (error) {
            if (error instanceof Error) {
                throw new HighOrderError(error, `Can not connect to external module store at ${this.moduleStoreUrl}.`);
            } else {
                throw error;
            }
        }
    }
    */

    private readonly externalModules: IModulesStorageStrong = new ModulesStorage();

    public getModule(moduleName: string_module_name, version: string_version_dependency = '*'): IModuleDefinition {
        return makeExternalModule({
            cache: this.externalModules,
            expectedManifest: {
                name: moduleName,
            },
            script: this.getModuleScript(moduleName),

            /* [🥝] this.modulesPath.then((modulesPath) => ({
                src: `${modulesPath}modules/${scope}/${name}/${version}/${name}.${version}.min.js`,
            })),*/
        });
    }

    private async getModuleScript(
        moduleName: string_module_name,
        version: string_version_dependency = '*',
        _isDeprecatedModuleName = false,
    ): Promise<{ src: string_url }> {
        try {
            const { name, scope } = parseModuleName(moduleName);

            const response = await fetch(`${this.moduleStoreUrl.href}modules/${scope}/${name.join('--')}/*`);
            const content = (await response.json()) as {
                error?: unknown /* <- TODO: What is the propper type here */;
                script: { src: string_url };
            };

            if (content.error) {
                throw new NotFoundError(`Can not load module ${moduleName} from external module store.`);
            }

            return content.script;
        } catch (error) {
            if (_isDeprecatedModuleName) {
                throw error;
            }

            if (!(error instanceof Error)) {
                throw error;
            }

            const { manifests } = await this.search({ name: moduleName });

            if (manifests.length !== 1) {
                error.message = spaceTrim(
                    (block) => `
                      ${block((error as Error).message)}

                      Note: Tried to search module "${moduleName}" from external module store with ${
                        manifests.length
                    } results.
                    `,
                );

                throw error;
            } else {
                const [manifest] = manifests;
                consolex.warn(`External module ${manifest.name} was referenced by its deprecated name ${moduleName}.`);

                return this.getModuleScript(manifest.name, version, true);
            }
        }
    }

    public async search(searchCriteria: IModuleSearchCriteria): Promise<IModuleStoreConnectorSearchResult> {
        const { businessSystem } = await this.systems.request('businessSystem');

        // TODO: Cache queries
        // TODO: Some util to construct URLs from json search params
        const url = new URL(`${this.moduleStoreUrl.href}search`);
        url.searchParams.set('searchCriteria', JSON.stringify(searchCriteria));
        // TODO: It future break searchCriteria into nice params like:
        //       ?needle=foo&supports.art=bar&supports.attribute=baz
        //       > Object.entries(searchCriteria).forEach(([key, value]) => url.searchParams.set(key, JSON.stringify(value)));
        const response = await fetch(url.href);
        const content = (await response.json()) as IModuleStoreConnectorSearchResult;

        // TODO: Check expected script.src in all modules

        // Note: Additional filtering to hide non-presented modules on non-development business
        content.manifests = await content.manifests.filterAsync(
            async (moduleManifest) =>
                isModulePassingSearchCriteria({
                    moduleManifest,
                    searchCriteria,
                    businessName: await businessSystem.businessName,
                    businessConfiguration: await businessSystem.businessConfiguration,
                }).value,
        );

        return content;
    }

    public async getCategories(): Promise<Set<string_module_category>> {
        // TODO: Cache or prebuild categories
        const response = await fetch(`${this.moduleStoreUrl.href}status/categories`);
        const content = (await response.json()) as { categories: Array<string_module_category> };
        return new Set(content.categories);
    }
}

/**
 * TODO: [🥝] Maybe use this shortcut how to get assets url in the future
 * TODO: Probably something like getModuleScript via safe-eval
 */
