import { JsonValue } from 'type-fest';
import { isPureObject } from '../../../40-utils/object/isPureObject';
import { ISerializerRule } from '../interfaces/ISerializerRule';

/**
 * @@x
 */
export const pureObjectSerializerRule: ISerializerRule = {
    name: 'PURE_OBJECT',

    // @@x [🕳️] Anotate both in pureObjectSerializerRule pureArraySerializerRule why for objects omiting undefined without error and in arrays throwing errors
    priority: -Infinity /* <- TODO: [🍉] One place with priorities */,
    serialize({ value, serialize, next }) {
        if (!isPureObject(value)) {
            return next();
        }

        const serialized: Record<string /* <- TODO: Maybe also number? */, JsonValue> = {};

        for (const key of Object.keys(value)) {
            const item = value[key];

            if (item === undefined) {
                // [🕳️]
                continue;
            }

            serialized[key] = serialize(item);
        }

        return serialized;
    },
    deserialize({ serialized, deserialize, next }) {
        if (!isPureObject(serialized)) {
            return next();
        }

        if ('__class' in serialized) {
            // Note: For objects that have a __class property are responsible rules created by createSerializerRuleForClass
            return next();
        }

        if ('moduleName' in serialized) {
            // Note: For objects that have a moduleName property are responsible rules created by createSerializerRuleForArt
            return next();
        }

        const deserialized: Record<string /* <- TODO: Maybe also number? */, any> = {};

        for (const key of Object.keys(serialized)) {
            deserialized[key] = deserialize(serialized[key] as JsonValue);
        }

        return deserialized;
    },
};

/**
 * TODO: [🌉] In future use satisfies ISerializerRule
 */
