import React from 'react';

interface INonBrakingSpacesProps {
    children: string;
}

/**
 * NonBrakingSpaces is component that will receive text and will replace all spaces with non-breaking spaces
 *
 * @collboard-modules-sdk
 */
export function NonBrakingSpaces(props: INonBrakingSpacesProps) {
    const { children: sentence } = props;
    const words = sentence.split(' ');

    return (
        <>
            {words.map((word, index) => (
                <React.Fragment /* <- TODO: Import and use just a Fragment */ key={index}>
                    {word}
                    {index !== words.length - 1 && <>&nbsp;</>}
                </React.Fragment /* <- TODO: Import and use just a Fragment */>
            ))}
        </>
    );
}
