import React from 'react';
import { Clickable } from '../../../../30-components/utils/Clickable';
import { TranslationsSystem } from '../../../TranslationsSystem/0-TranslationsSystem';
import { INotification } from '../../INotification';
import { NotificationActionComponent } from './NotificationActionComponent';

interface INotificationComponentProps {
    translationsSystem: TranslationsSystem;
    notification: INotification;
    closeHandler: () => void;
}

/**
 * TODO: @roseckyj maybe unite AnnouncementComponent and NotificationComponent
 */
export function NotificationComponent({
    translationsSystem,
    notification: { type, onClick, href, title, subtitle, canBeClosed, body, actions },
    closeHandler,
}: INotificationComponentProps) {
    return (
        <Clickable className={`notification notification-${type} shadow`} {...{ onClick, href }} hrefByJavascript>
            <div className="notification-header">
                {title && <div className="notification-title">{translationsSystem.pickMessage(title)}</div>}
                {subtitle && <div className="notification-subtitle">{translationsSystem.pickMessage(subtitle)}</div>}
                {canBeClosed && <div className="notification-close" onClick={closeHandler} />}
            </div>
            {body && <div className="notification-body">{translationsSystem.pickMessage(body)}</div>}
            {actions && actions.length && (
                <div className="notification-footer">
                    {actions?.map((action) => (
                        <NotificationActionComponent key={action.action} {...{ action, translationsSystem }} />
                    ))}
                </div>
            )}
        </Clickable>
    );
}

/**
 * TODO: Probably not use functional component in this project
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
