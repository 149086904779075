import React from 'react';
import { windowSize } from '../40-utils/getWindowSize';
import { useObservable } from '../40-utils/react-hooks/useObservable';
import { useSystemsInCore } from '../40-utils/react-hooks/useSystemsInCore';
import { ArtScaler } from './ArtScaler';

export function BoardComponentSelected() {
    const { appState, toolbarSystem, materialArtVersioningSystem } = useSystemsInCore(
        'appState',
        'toolbarSystem',
        'materialArtVersioningSystem',
    );

    // Note: [🍵] Observing without getting value is a bit ugly trick but it works because methods and subcomponents bellow are dependent on observed values
    useObservable(windowSize);
    useObservable(appState.transform);
    useObservable(materialArtVersioningSystem.artsObservable);
    useObservable(appState.selected);
    useObservable(appState.selection);

    const selectedBoundingBox = appState.getSelectedBoundingBox()!;
    const left = selectedBoundingBox.topLeft.x; /* <- LIB xyzt .toTopLeft() */
    const top = selectedBoundingBox.topLeft.y; /* <- LIB xyzt .toTopLeft() */
    const width = selectedBoundingBox.bottomRight.x - selectedBoundingBox.topLeft.x;
    const height = selectedBoundingBox.bottomRight.y - selectedBoundingBox.topLeft.y;

    if (width === 0 || height === 0) {
        return <></>;
    }

    return (
        <>
            {appState.selected.value.length > 0 && !appState.selection.value && (
                /*
                    TODO: [🍣] Find all simmilar hardcoded traces of text, TextArtModule,...
                    !toolbarSystem
                    .getToolbar(ToolbarName.Tools)
                    .isIconActive('text' /* TODO: This should be also somehow in TextArtModule * /) && */ <div
                    className="selectedBoundingBox"
                    style={{
                        left,
                        top,
                        width,
                        height,
                    }}
                >
                    {appState.selected.value.filter((art) => art.acceptedAttributes.includes('size') && !art.locked)
                        .length === appState.selected.value.length && <ArtScaler />}
                </div>
            )}
        </>
    );
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 * TODO: [🏊‍♂️] Better naming and distinction of Collboard-specific components vs utils components
 */
