import { IDestroyable } from 'destroyable';
import { Subscription } from 'rxjs';
import { CornerstoneArt } from '../../../71-arts/30-CornerstoneArt';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { IModulePersister } from '../interfaces/IModulePersister';
import { ISyncer } from '../interfaces/ISyncer';
import { createDependenciesFromCornerstone } from '../utils/createDependenciesFromCornerstone';
import { joinDependencies } from '../utils/joinDependencies';
import { AbstractSyncer } from './AbstractSyncer';
/**
 * CornerstoneSyncer watches on cornerstone of current board and installs / uninstalls modules according to it
 *
 * @private
 * @collboard-system
 */
export class CornerstoneSyncer extends AbstractSyncer implements ISyncer, IModulePersister, IDestroyable {
    private subscription: Subscription;

    protected async initSyncer() {
        await super.initSyncer();

        this.subscription = (await this.systems.materialArtVersioningSystem).cornerstoneArts.subscribe(
            async (cornerstoneArt) => {
                const dependenciesTarget = joinDependencies(
                    (await (await this.systems.businessSystem).businessConfiguration).boardActiveModules,
                    createDependenciesFromCornerstone(cornerstoneArt as CornerstoneArt, 10),
                );
                await this.sync(dependenciesTarget);
            },
        );
    }

    public async moduleActivate(moduleManifest: IModuleManifest) {
        (await this.systems.materialArtVersioningSystem)
            .createOperation('Activate module from module store')
            .takeArts((await this.systems.materialArtVersioningSystem).cornerstoneArts.value)
            .updateWithMutatingCallback((cornerstoneArt) =>
                (cornerstoneArt as CornerstoneArt).moduleActivate(moduleManifest),
            )
            .persist();
    }

    public async moduleDeactivate(moduleManifest: IModuleManifest) {
        (await this.systems.materialArtVersioningSystem)
            .createOperation('Activate module from module store')
            .takeArts((await this.systems.materialArtVersioningSystem).cornerstoneArts.value)
            .updateWithMutatingCallback((cornerstoneArt) =>
                (cornerstoneArt as CornerstoneArt).moduleDeactivate(moduleManifest),
            )
            .persist();
    }

    public async destroy() {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        await this.subscription.unsubscribe();
    }
}
