import React from 'react';
import { useSkin } from '../40-utils/react-hooks/useSkin';
import { useSystemsInCore } from '../40-utils/react-hooks/useSystemsInCore';
import { UserInterfaceElementPlace } from '../50-systems/UserInterfaceSystem/UserInterfaceElementPlace';
import { MenuWrapper } from './menu/MenuWrapper';

export function RootComponentForWelcome() {
    const { userInterfaceSystem } = useSystemsInCore('userInterfaceSystem');
    const {
        skin: { welcomeWallpaper },
    } = useSkin();

    return (
        <>
            <div className="board-container" style={welcomeWallpaper}>
                <div className="board">
                    <div className="board-bg"></div>
                </div>
            </div>

            <MenuWrapper position="top-ltr">
                {userInterfaceSystem.render(UserInterfaceElementPlace.EdgeTop)}
            </MenuWrapper>
            <MenuWrapper position="bottom-ltr">
                {userInterfaceSystem.render(UserInterfaceElementPlace.EdgeTop)}
            </MenuWrapper>
            <MenuWrapper position="right">
                {userInterfaceSystem.render(UserInterfaceElementPlace.EdgeRight)}
            </MenuWrapper>

            {userInterfaceSystem.render(UserInterfaceElementPlace.RootComponent)}
            {userInterfaceSystem.render(UserInterfaceElementPlace.RootComponentForWelcome)}
        </>
    );
}

/**
 * TODO: [☮️] Remove all initial <Loader/> and <LoaderInline/>
 * TODO: [🏊‍♂️] Better naming and distinction of Collboard-specific components vs utils components
 */
