import React from 'react';
import styled from 'styled-components';
import { factor } from '../../40-utils/IFactory';
import { toArray } from '../../40-utils/toArray';
import { AsyncContentComponent } from './AsyncContentComponent';

/**
 * Unwraps given JSX element from its container and rewraps it in React fragment
 *
 * It will unwrap its children or content (to be compatible with components like AsyncContentComponent and SelfUpdateContentComponent)
 *
 * @collboard-modules-sdk
 */
export function Unwrap({ children }: { children: JSX.Element }) {
    /**
     * Note: There will be always exactly one child element in the container
     */
    const child = children;

    if (child.props.children) {
        return (
            <>
                {toArray(child.props.children).map((childElement, index) => {
                    return (
                        <React.Fragment /* <- TODO: Import and use just a Fragment */ key={index}>
                            {childElement}
                        </React.Fragment /* <- TODO: Import and use just a Fragment */>
                    );
                })}
            </>
        );
    } else if (child.props.content) {
        const content = factor(child.props.content);

        if (!((content instanceof Promise) /* <-  [🐶] */)) {
            return <Unwrap>{content}</Unwrap>;
        } else {
            return (
                <AsyncContentComponent
                    alt="Loading content to unwrap"
                    content={async () => {
                        return <Unwrap>{await content}</Unwrap>;
                    }}
                />
            );
        }
    } else {
        // TODO: ErrorComponent
        return <ErrorDiv>Can not unwrap the content.</ErrorDiv>;
    }
}

const ErrorDiv = styled.div`
    padding: 5px;
    background-color: red;
    border-radius: 5px;
`;

/**
 * TODO: Make ErrorDiv skinned
 * TODO: Use ErrorDiv / ErrorComponent across the repositiory
 * TODO: [🧵] Move to external LIB for react loadables
 * TODO: [📕] Do not unwrap
 */
