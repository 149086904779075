import React, { useState } from 'react';
import { classNames } from '../../../40-utils/classNames';
import { useSkin } from '../../../40-utils/react-hooks/useSkin';
import { ModalStyle } from '../ModalStyle';
import { PageOverlay } from '../PageOverlay';
import { ICategorizedModalProps } from './ICategorizedModalProps';

/**
 * Categorized modal component
 *
 * Wrap the CategorizedModal contents with `CategorizedModalMenu`
 * and `CategorizedModalBody` components
 *
 * @collboard-modules-sdk
 */
export function CategorizedModal(props: ICategorizedModalProps) {
    const { title, toggleModal, className, wide, fullSize, children } = props;
    const [isMenuOpen, setMenuOpen] = useState(false);
    const { skin } = useSkin();

    return (
        <PageOverlay onPointerDown={() => toggleModal()}>
            <ModalStyle {...{ skin }}>
                <div
                    className={classNames(
                        'custom-modal',
                        wide && 'custom-modal-wide',
                        fullSize && 'custom-modal-fullsize',
                        isMenuOpen && 'menu-open',
                        className,
                    )}
                >
                    <div className="custom-modal-content">
                        <div className="custom-modal-header">
                            <div className="custom-modal-close" onClick={() => toggleModal()} />
                            <div className="custom-modal-menu" onClick={() => setMenuOpen(!isMenuOpen)} />
                            {title}
                        </div>

                        <div className={classNames('categorized-modal-content', isMenuOpen && 'menu-open')}>
                            {children}
                        </div>
                    </div>
                </div>
            </ModalStyle>
        </PageOverlay>
    );
}
