import { string_html, string_xml } from '../typeAliases';

/**
 * Make given HTML XML valid
 *
 * It will do:
 * - Automatically end the tags like <img> to <img/>, <link> to <link/>,...
 * - Add <!DOCTYPE html> doctype of the html
 * - Add <?xml version="1.0" encoding="UTF-8"?> doctype of the svg
 * - Automatically end all unended tags
 *
 * @param element HTML or SVG string which can be invalid
 * @returns HTML or SVG string which is also valid XML string
 *
 * @collboard-modules-sdk
 */
export function htmlToXmlValidHtml(html: string_html): string_html & string_xml {
    // Automatically end the tags like <img> to <img/>, <link> to <link/>,...
    html = html.replace(/<(img|link|meta|hr|br)\b([^>]*)>/g, (match, p1, p2) => {
        if (!match.endsWith('/>')) {
            return '<' + p1 + p2 + '/>';
        } else {
            return match;
        }
    });

    return html;
}

/**
 * TODO: [🎞️] Probbably work with XMLs in some object-based method
 */
