import { normalizeToKebabCase } from '@promptbook/utils';
import { registerItemsInArray, Registration } from 'destroyable';
import { extension } from 'mime-types';
import React from 'react';
import spaceTrim from 'spacetrim';
import { BoundingBox, Vector } from 'xyzt';
import { forCondition } from '../../00-lib/waitasecond/forCondition';
import { errorMessageWithAdditional } from '../../40-utils/errors/errorMessageWithAdditional';
import { dataurlToBlob } from '../../40-utils/files/dataurlToBlob';
import { isValidDataurl } from '../../40-utils/files/isValidDataurl';
import { windowSize } from '../../40-utils/getWindowSize';
import { factor } from '../../40-utils/IFactory';
import { jsxToHtml } from '../../40-utils/jsx-html/jsxToHtml';
import { prettifyHtml } from '../../40-utils/jsx-html/prettifyHtml';
import { materializeSources } from '../../40-utils/materializeSources';
import { nullsafeify } from '../../40-utils/nullsafefyfy';
import { patternToRegExp } from '../../40-utils/patternToRegExp';
import { toArray } from '../../40-utils/toArray';
import { string_mime_type } from '../../40-utils/typeAliases';
import { ArrayFull } from '../../40-utils/typeHelpers';
import { AbstractArt } from '../../71-arts/20-AbstractArt';
import { Abstract2dArt } from '../../71-arts/26-Abstract2dArt';
import { CornerstoneArt } from '../../71-arts/30-CornerstoneArt';
import { ExportArt } from '../../71-arts/35-ExportArt';
import { consolex } from '../../consolex';
import { ISystemsExtended } from '../00-SystemsContainer/ISystems';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { findArtElement } from '../CollSpace/utils/findArtElement';
import { getBoundingClientRectWithoutTransform } from '../CollSpace/utils/getBoundingClientRectWithoutTransform';
import { EXPORT_FILE_DEFAULT_OPTIONS, IExportFileOptions } from './interfaces/IExportFileOptions';
import { ExportScopeSimple } from './interfaces/IExportScope';
import { IFileExportSupporter } from './interfaces/IFileExportSupporter';
import { IFileExportSupporterOptions } from './interfaces/IFileExportSupporterOptions';
import { IFramable } from './interfaces/IFramable';
import { IPreparedFileExport, IPreparedFileExporting, IPreparedFileVoidExport } from './interfaces/IPreparedFileExport';
import { isFramable } from './utils/isFramable';

/**
 * ExportSystem creates other files from the board or the part of it.
 * Note: This system is not just for exporting but also saves to native format.
 *
 * @collboard-system
 */
export class ExportSystem extends AbstractSystem {
    /*
        TODO: This class is a boilerplate of the system that we have not started working on yet.
        @see https://github.com/collboard/collboard/issues/78
        @see https://github.com/collboard/collboard/issues/79
        @see https://github.com/collboard/collboard/issues/80
        @see https://github.com/collboard/collboard/issues/81
        @see https://github.com/collboard/collboard/issues/82
        @see https://github.com/collboard/collboard/issues/40
    */

    public constructor(systems: ISystemsExtended, private proxyUrl: URL) {
        super(systems);
    }

    protected async init() {}

    private fileSupporters: Array<IFileExportSupporter> = [];

    public registerFileSupport(fileSupporter: IFileExportSupporter): Registration {
        return registerItemsInArray({
            base: this.fileSupporters,
            add: [fileSupporter],
        });
    }

    /**
     * @deprecated use exportFiles OR prepareExportFiles instaed
     */
    public get supportedMimeTypes(): Set<string> {
        return new Set(this.fileSupporters.map(({ mimeType }) => mimeType));
    }

    /**
     * Get all arts that can be exported OR are frames
     */
    private async getExportRelevantArts(): Promise<Array<{ art: AbstractArt; isMaterial: boolean }>> {
        return [
            { versioningSystem: await this.systems.materialArtVersioningSystem, isMaterial: true },
            { versioningSystem: await this.systems.virtualArtVersioningSystem, isMaterial: false },
        ]
            .flatMap(({ versioningSystem, isMaterial }) => {
                return versioningSystem.artsPlaced.map((art) => ({ art, isMaterial }));
            })
            .filter(({ art }) => !(art instanceof CornerstoneArt || art instanceof ExportArt));
    }

    /**
     * Get all possible exports frames
     */
    public async getFrames(): Promise<Array<{ art: IFramable; isMaterial: boolean }>> {
        return (await this.getExportRelevantArts()).filter(({ art }) => isFramable(art)) as any as Array<{
            art: IFramable;
            isMaterial: boolean;
        }>;
    }

    /**
     * Get all arts (but non frames)
     */
    public async getArts(): Promise<Array<{ art: Abstract2dArt; isMaterial: boolean }>> {
        return (await this.getExportRelevantArts())
            .filter(({ art }) => art instanceof Abstract2dArt)
            .filter(
                ({ art }) => !isFramable(art) /* TODO: Probably do not test for framable but instanceof FrameArt */,
            ) as Array<{ art: Abstract2dArt; isMaterial: boolean }>;
    }

    private async forEssentialFileSupporters(): Promise<void> {
        // TODO: This is bit hardcoded, do this better
        await forCondition(() => this.fileSupporters.length > 2);

        /*return forValueDefined(() => {
            this.fileSupporters.filter((fileSupporter) => fileSupporter.mimeType === 'application/collboard');
        });*/
    }

    /**
     * Prepares export from the Collboard
     *
     * Note: exportFiles (=do all heavy stuff) vs exportFilesPrepare (=only tells which exports are possible)
     */
    public async exportFilesPrepare(options: IExportFileOptions): Promise<Array<IPreparedFileExport>> {
        const { scope, isHeavyExport: isHeavyIncluded, mimeType } = options;
        let arts: Array<{ art: Abstract2dArt; isMaterial: boolean }>;

        /**
         * // TODO: [🐲] What is boundingbox related to? Board? Screen?
         */
        let boundingBox: BoundingBox;

        if (scope === ExportScopeSimple.Board) {
            arts = await this.getArts();
        } else if (scope === ExportScopeSimple.Selection) {
            // TODO: [💉] Just taking arts from selection is not ideal because it will not export relevant virtual arts
            arts = (
                (await this.systems.appState).selected.value.filter(
                    (art) => art instanceof Abstract2dArt,
                ) as Array<Abstract2dArt>
            ).map((art) => ({ art, isMaterial: true }));
        } else if (isFramable(scope)) {
            arts = await this.getArts();
            // TODO: Probbably filter arts only in bounding box> arts = (await this.getArts()).filter((art) => scope.boundingBox.isIn(art));
        } else {
            throw new Error(errorMessageWithAdditional(`Unknown export scope`, { scope }));
        }

        const artContainers = arts.map(({ art, isMaterial }) => ({
            art,
            element: findArtElement(art.artId),
            isMaterial,
        }));

        const mimeTypeRegExp = patternToRegExp.apply(null, toArray(mimeType));

        const boardname = (await this.systems.appState).boardname.value;
        const createDefaultFilename = (mimeType2: string_mime_type) =>
            // TODO: In case of SELECTION scope probably add some suffix to default filename
            `${normalizeToKebabCase(boardname)}.${extension(mimeType2)}`;

        // TODO: [🐲] What is boundingbox related to? Board? Screen?

        if (isFramable(scope)) {
            const { topLeft, bottomRight } = scope.boundingBox;
            boundingBox = BoundingBox.fromPoints(topLeft, bottomRight);

            // Note: [🐲] This needs to be unexpectidly added after migration from MobX to RxJS
            boundingBox.transform.translate = boundingBox.transform.translate.add(windowSize.value.half());
        } else {
            const boundingBoxes = artContainers
                // [🍬] Use board boundingBox not screen one
                .map((artContainer) => nullsafeify(getBoundingClientRectWithoutTransform)(artContainer.element))
                .filter(Boolean)
                .map((domRect) => BoundingBox.fromDomRect(domRect));

            if (boundingBoxes.length === 0) {
                boundingBoxes.push(
                    BoundingBox.fromPoints(Vector.zero(), Vector.zero() /* LIB xyzt <- TODO: BoundingBox.zero() */),
                );
            }

            boundingBox = BoundingBox.merge.apply(BoundingBox, boundingBoxes as ArrayFull<BoundingBox>);
            boundingBox.transform.translate = boundingBox.transform.translate.subtract(
                boundingBox.transform.scale.half(),
            );
        }

        await this.forEssentialFileSupporters();

        return (
            await this.fileSupporters.mapAsync(async (fileSupporter) => {
                if (!isHeavyIncluded && fileSupporter.isHeavy) {
                    return null;
                }

                if (!mimeTypeRegExp.test(fileSupporter.mimeType)) {
                    return null;
                }

                const defaultFilename = createDefaultFilename(fileSupporter.mimeType);

                const supporterOptions: IFileExportSupporterOptions = {
                    ...EXPORT_FILE_DEFAULT_OPTIONS,
                    ...options,
                    artContainers /* <- TODO: [🐽] Sort by defualt z-index */,
                    boundingBox,
                    boardname,
                    defaultFilename,
                    scope,
                };

                const exportResult = await (async () => await fileSupporter.exportFile(supporterOptions))().catch(
                    (error) => {
                        consolex.error(error);
                        return null;
                    },
                );

                // TODO: Measure that non-heavy exports takes only tinytiny amount of time

                if (exportResult === null) {
                    return {
                        options,
                        supporterOptions,
                        fileSupporter,
                        exporting: null,
                    } as IPreparedFileVoidExport;
                }

                const proxyUrl = this.proxyUrl;

                return {
                    options,
                    supporterOptions,
                    fileSupporter,
                    async exporting() {
                        let exported = await factor(exportResult);

                        // TODO: Util for making files from Blob/string/File

                        if (typeof exported === 'string' && isValidDataurl(exported)) {
                            exported = await dataurlToBlob(exported);
                        }

                        if (React.isValidElement(/* <- TODO: Import and use just a isValidElement */ exported)) {
                            const html = jsxToHtml(exported);
                            if (!options.isHeavyExport) {
                                exported = html;
                            } else {
                                exported = prettifyHtml(html);
                            }
                        }

                        if (typeof exported === 'string') {
                            if (supporterOptions.isMaterialized) {
                                // TODO: Probbably materialize also Blobs and Files
                                exported = await materializeSources(exported, proxyUrl);
                            }

                            return new File([exported], defaultFilename, {
                                type: fileSupporter.mimeType,
                            });
                        } else if (exported instanceof Blob) {
                            if (fileSupporter.mimeType !== exported.type) {
                                consolex.warn(
                                    spaceTrim(`
                                      fileSupporter.exportFile returns blob of wrong type
                                      expected "${fileSupporter.mimeType}" got "${exported.type}".
                                    `),
                                );
                            }

                            if (exported instanceof File) {
                                return exported;
                            } else {
                                return new File([exported], createDefaultFilename(exported.type), {
                                    type: exported.type,
                                });
                            }
                        } else {
                            throw new Error(
                                errorMessageWithAdditional(
                                    spaceTrim(`
                                      fileSupporter.exportFile returns unexpected result of type "${typeof exported}".
                                      Expecting Blob with mime "${fileSupporter.mimeType}", string or null.
                                    `),
                                    { exported },
                                ),
                            );
                        }
                    },
                } as IPreparedFileExporting;
            })
        ).filter(Boolean);
    }

    /**
     * Makes export from the Collboard
     *
     * Note: exportFile(s) (=do all heavy stuff) vs exportFilesPrepare (=only tells which exports are possible)
     */
    public async exportFiles(options: IExportFileOptions): Promise<Array<File>> {
        return (
            await (
                await this.exportFilesPrepare(options)
            ).mapAsync(async ({ fileSupporter, exporting }) => {
                if (!exporting) {
                    return null;
                }

                return exporting();
            })
        ).filter(Boolean);
    }

    /**
     * Makes export of one file from the Collboard
     * When there is no file to export it throws error
     *
     * Note: exportFile(s) (=do all heavy stuff) vs exportFilesPrepare (=only tells which exports are possible)
     */
    public async exportFile(options: IExportFileOptions): Promise<File> {
        const files = await this.exportFiles(
            // TODO: There should be some option limit that will limit max. amount of exported files and this option will be omited in exportFiles and used 1
            options,
        );

        if (files.length === 0) {
            throw new Error(`No exported ${options.mimeType} files`);
        }

        return files[0]!;
    }
}
