import { ObjectUrl } from '../../../40-utils/files/ObjectUrl';
import { fitInside } from '../../../40-utils/fitInside';
import { windowSize } from '../../../40-utils/getWindowSize';
import { measureImageSize } from '../../../40-utils/image/measureImageSize';
import { patternToRegExp } from '../../../40-utils/patternToRegExp';
import { centerArts } from '../../../50-systems/ImportSystem/utils/centerArts';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { ImageArt } from '../../../71-arts/50-ImageArt';

internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/images-import',
        deprecatedNames: ['@collboard/images-import', 'ImagesImport'],
        supports: {
            fileImport: ['image/*'],
        },
    },
    async setup(systems) {
        const { importSystem, usercontentSystem, appState } = await systems.request(
            'importSystem',
            'usercontentSystem',
            'appState',
        );

        // Note: For lot of systems we are using this makeWhatever helpers. I am trying one system - ImportSystem without make helper to modules just to use this systems methods directly.
        return importSystem.registerFileSupport({
            priority: 0,

            async importFile({ logger, file, boardPosition, willCommitArts, previewOperation, next }) {
                if (!patternToRegExp('image/*').test(file.type)) {
                    return next();
                }

                willCommitArts();

                const imageObjectUrl = ObjectUrl.fromBlob(file);

                const imageScaledSize = fitInside({
                    isUpscaling: false,
                    objectSize: await (await measureImageSize(file)).divide(appState.transform.value.scale),
                    containerSize: windowSize.value.divide(appState.transform.value.scale),
                });

                const imageArt = new ImageArt(imageObjectUrl.src, 'image');
                imageArt.size = imageScaledSize;
                imageArt.opacity = 0.5;

                logger.info('Imported art', imageArt);

                centerArts({ arts: [imageArt], center: boardPosition });

                previewOperation.update(imageArt);

                // TODO: Limit here max size of images> if(imageSize.x>windowSize.value*transform)

                const imageSrc = (await usercontentSystem.upload(file)).href;
                imageArt.src =
                    imageSrc /* <- TODO: Probbably wait till image from uploaded CDN is loaded until replacing src to prevent annoying blink */;
                imageArt.opacity = 1;

                await imageObjectUrl.destroy();

                return imageArt;
            },
        });
    },
}));
