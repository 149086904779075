import { internalModules } from '../../ModuleStore/internalModules';
import { makeIconModuleOnRoute } from '../../ModuleStore/makers/makeIconModuleOnRoute';
import { ToolbarName } from '../../ToolbarSystem/0-ToolbarSystem';

internalModules.declareModule(() =>
    makeIconModuleOnRoute({
        manifest: {
            name: '@collboard/internal/download-icon',
            deprecatedNames: '@collboard/download-icon',
            title: { en: 'Download icon on toolbar', cs: 'Ikonka stažení na liště' },
            requirePermissions: ['view'],
        },
        toolbar: ToolbarName.Social,
        icon: {
            icon: 'close-down' /* <- TODO: @roseckyj make icon for save / export */,
            order: 20,
        },
        routeParams: { moduleName: '@collboard/internal/download', modulePath: null },
    }),
);

/**
 * TODO: [🐅] Maybe some more elegant way how to create icon-window pairs of modules
 */
