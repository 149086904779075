import { internalModules } from '../../../../50-systems/ModuleStore/internalModules';
import { makeIconModuleOnRoute } from '../../../../50-systems/ModuleStore/makers/makeIconModuleOnRoute';
import { ToolbarName } from '../../../../50-systems/ToolbarSystem/0-ToolbarSystem';

internalModules.declareModule(() =>
    makeIconModuleOnRoute({
        manifest: {
            name: '@collboard/internal/share-icon',
            deprecatedNames: ['@collboard/share-icon', 'ShareIcon'],
            title: { en: 'Share icon on toolbar', cs: 'Ikonka sdílení na liště' },
            requirePermissions: ['view'],
        },
        toolbar: ToolbarName.Social,
        icon: {
            icon: 'share',
            order: 10,
        },
        routeParams: { moduleName: '@collboard/internal/share', modulePath: null },
    }),
);

/**
 * TODO: [🐅] Maybe some more elegant way how to create icon-window pairs of modules
 */
