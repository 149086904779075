import { IDestroyable } from 'destroyable';
import { Subscription } from 'rxjs';
import { forImmediate } from 'waitasecond';
import { BoardNotFoundError } from '../../../40-utils/errors/BoardNotFoundError';
import { ModuleNotFoundError } from '../../../40-utils/errors/ModuleNotFoundError';
import { consolex } from '../../../consolex';
import { ISyncer } from '../interfaces/ISyncer';
import { AbstractSyncer } from './AbstractSyncer';
/**
 * RouteAndBusinessSyncer installs / uninstalls modules according to the app URI
 *
 * @private
 * @collboard-system
 */
export class RouteAndBusinessSyncer extends AbstractSyncer implements ISyncer, IDestroyable {
    private subscription: Subscription;

    protected async initSyncer() {
        await super.initSyncer();

        const { routingSystem, businessSystem } = await this.systems.request('routingSystem', 'businessSystem');
        const { alwaysRequiredModules, welcomeRequiredModules, boardRequiredModules } =
            await businessSystem.businessConfiguration;

        this.subscription = routingSystem.urlVariables.values.subscribe({
            next: async ({ moduleName: module, uriId }) => {
                // TODO: Use modulePath
                // TODO: Probably LanguageSelect-ing in other scenarios

                if (module) {
                    try {
                        if (!uriId) {
                            await this.sync({ [module]: {}, ...alwaysRequiredModules });
                        } else {
                            await this.sync({ [module]: {}, ...alwaysRequiredModules, ...boardRequiredModules });
                        }
                    } catch (error) {
                        if (error instanceof ModuleNotFoundError) {
                            this.triggerErrorForModule(error);
                        } else {
                            throw error;
                        }
                    }
                } else if (!module && !uriId) {
                    await this.sync({ ...alwaysRequiredModules, ...welcomeRequiredModules });
                } else {
                    await this.sync({ ...alwaysRequiredModules, ...boardRequiredModules });
                }
            },
        });
    }

    /**
     * This is for CollboardApp when it not found current board
     */
    public async triggerErrorForBoard(error: BoardNotFoundError) {
        // TODO: Here should be filtered out ONLY NOT_FOUND error
        consolex.error(error);
        await forImmediate(/* <- TODO: Describe why waiting forImmediate */);
        this.sync({ Error404ForBoard: {} });
    }

    /**
     * This is for CollboardApp when it not found current board
     */
    public async triggerErrorForModule(error: ModuleNotFoundError) {
        // TODO: Here should be filtered out ONLY 404 error
        consolex.error(error);
        await forImmediate(/* <- TODO: Describe why waiting forImmediate */);
        this.sync({ Error404ForModule: {} });
    }

    public async destroy() {
        await super.destroy(/* TODO: [🚒] super.destroy should be called at the end of the destroy */);
        await this.subscription.unsubscribe();
    }
}

/**
 * TODO: Split between RouteSyncer and BusinessSyncer
 */
