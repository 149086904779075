import React from 'react';
import { Vector } from 'xyzt';
import { CollSpace } from '../../50-systems/CollSpace/0-CollSpace';
import { ImportSystem } from '../../50-systems/ImportSystem/0-ImportSystem';

type IUploadOverlayProps =
    React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{
        importSystem: ImportSystem;
        collSpace: CollSpace;
    }>;

/**
 * @not-collboard-modules-sdk
 */
export class UploadOverlayWrapper extends React.Component/* <- TODO: Import and use just a Component */ <IUploadOverlayProps> {
    render() {
        const { children, importSystem, collSpace } = this.props;

        /*
        TODO: Probably in future show place, where will be file dropped, use onDragEnter,onDragOver,onDragLeave on this
              Note:Very proppably there is impossible to show a preview @see https://www.pavolhejny.com/muzu-nejakym-zpusobem-cist-soubory-pri-pretahovani-jeste-pred-jejich-pustenim/
        */
        return (
            <div
                onDragOver={(event) => {
                    // Note: onDragOver does prevent brower from just showing a subject of dragging
                    event.preventDefault();
                    event.stopPropagation();
                }}
                onDrop={async (event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    const screenPosition = Vector.fromObject(event, ['clientX', 'clientY']);
                    const boardPosition =
                        /* TODO: In future when xyzt will support coords, here should we simply reference screenCentered*/ (
                            await collSpace.pickPoint(screenPosition)
                        ).point;

                    const files = Array.from(event.dataTransfer.files);

                    // TODO: Probbably do not place all objects in one boardPosition but make some spread
                    for (const file of files) {
                        importSystem.importFile({
                            boardPosition,
                            file,
                        });
                    }
                }}
            >
                {children}
            </div>
        );
    }
}

/**
 * TODO: [🏛️] Convert Class components to Functional (with hooks).
 * TODO: Maybe put reason after all @not-collboard-modules-sdk tags
 */
