import { internalModules } from '../../internalModules';
import { makeCanvasArtModule } from '../art/40-makeCanvasArtModule';
import { makeSimpleArtoolModules } from '../makeSimpleArtoolModules';

/**
 * TODO: !!x This shoud be using makeCanvasArtoatModules (Create maker that combines into one makeCanvasArtModule+makeSimpleArtoatModules and use it here)
 */

internalModules.declareModule(
    ...makeSimpleArtoolModules(
        makeCanvasArtModule({
            manifest: {
                name: `@test/makers/simple-artool`,
            },
            draw: drawSnowflake,
        }),
    ),
);

function drawSnowflake(ctx: CanvasRenderingContext2D): void {
    const canvas = ctx.canvas; /* <- TODO: !!x Pass canvas via options */

    // Clear the canvas
    // TODO: !!x Move to makeCanvasArt
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // TODO: !!xm Take from appereance
    // Set the fill style to white
    ctx.fillStyle = 'white';

    // TODO: !!xm Take from appereance
    // Set the stroke style to black
    ctx.strokeStyle = 'black';

    // TODO: !!xm Take from appereance
    // Set the line width
    ctx.lineWidth = 1;

    // Set the line cap to round
    ctx.lineCap = 'round';

    // Set the line join to round
    ctx.lineJoin = 'round';

    // Set the miter limit to 10
    ctx.miterLimit = 10;

    // Set the global alpha to a random value between 0.5 and 1
    ctx.globalAlpha = Math.random(/* TODO: !!xm All Math.random must be deterministic */) * 0.5 + 0.5;

    // Set the line dash to an empty array
    ctx.setLineDash([]);

    // Begin a new path
    ctx.beginPath();

    // Set the starting point of the path to the center of the canvas
    ctx.moveTo(canvas.width / 2, canvas.height / 2);

    // Add a random number of curves to the path
    const numCurves = Math.floor(Math.random(/* <- TODO: [🐉] Probbably use seed random */) * 5) + 5;
    for (let i = 0; i < numCurves; i++) {
        // Add a symmetrical curve to the path, using the center of the canvas as a control point
        ctx.quadraticCurveTo(
            canvas.width / 2,
            canvas.height / 2,
            (Math.random(/* <- TODO: [🐉] Probbably use seed random */) * canvas.width) / 2 + canvas.width / 4,
            (Math.random(/* <- TODO: [🐉] Probbably use seed random */) * canvas.height) / 2 + canvas.height / 4,
        );
        ctx.quadraticCurveTo(
            canvas.width / 2,
            canvas.height / 2,
            (Math.random(/* <- TODO: [🐉] Probbably use seed random */) * canvas.width) / 2 + canvas.width / 4,
            (Math.random(/* <- TODO: [🐉] Probbably use seed random */) * canvas.height) / 2 + canvas.height / 4,
        );
        ctx.quadraticCurveTo(
            canvas.width / 2,
            canvas.height / 2,
            (Math.random(/* <- TODO: [🐉] Probbably use seed random */) * canvas.width) / 2 + canvas.width / 4,
            (Math.random(/* <- TODO: [🐉] Probbably use seed random */) * canvas.height) / 2 + canvas.height / 4,
        );
    }

    // Close the path
    ctx.closePath();

    // Fill the path with the fill style
    ctx.fill();

    // Stroke the path with the stroke style
    ctx.stroke();
}

/**
 * TODO: [🟨] Make some re-render setting and multistage (like docker images)
 * TODO: Infinité canvas
 * TODO: This should be an external real module
 */
