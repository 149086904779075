import React from 'react';
import styled from 'styled-components';
import { Center } from './Center';

export type IUploadZoneProps =
    React.PropsWithChildren/* <- TODO: Use `children?: ReactNode` in the interface OR Import and use just a PropsWithChildren */ <{
        isClickable?: boolean;
        onFilesOver?: (isFileOver: boolean) => void;
        onFiles: (droppedFiles: Array<File>) => void;
    }>;

export function UploadZone({ children, isClickable, onFilesOver, onFiles }: IUploadZoneProps) {
    const [isFilesOver, setFilesOver] = React.useState(/* <- TODO: Import and use just a useState */ false);

    const onFileOverWrapper = (isFileOver: boolean) => {
        setFilesOver(isFileOver);

        if (onFilesOver) {
            onFilesOver(isFileOver);
        }
    };

    let uploadClick: () => void;

    return (
        <UploadZoneDiv
            className={isFilesOver ? 'files-over' : ''}
            onClick={() => {
                if (isClickable) {
                    uploadClick();
                }
            }}
            onMouseEnter={() => {
                onFileOverWrapper(true);
            }}
            onMouseLeave={() => {
                onFileOverWrapper(false);
            }}
            onDragEnter={(event) => {
                event.stopPropagation();
                event.preventDefault();
            }}
            onDragOver={(event) => {
                event.stopPropagation();
                event.preventDefault();
                onFileOverWrapper(true);
            }}
            onDragExit={(event) => {
                event.stopPropagation();
                event.preventDefault();
                onFileOverWrapper(false);
            }}
            onDragEnd={(event) => {
                event.stopPropagation();
                event.preventDefault();
                onFileOverWrapper(false);
            }}
            onDragEndCapture={(event) => {
                event.stopPropagation();
                event.preventDefault();
                onFileOverWrapper(false);
            }}
            onDrop={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onFileOverWrapper(false);

                const files = Array.from(event.dataTransfer.files); // TODO: @hejny Maybe there should be event.dataTransfer.items handler
                onFiles(files);
            }}
        >
            <input
                type="file"
                multiple
                ref={(element) => {
                    if (!element) {
                        return;
                    }
                    uploadClick = () => {
                        onFileOverWrapper(true);
                        (element as HTMLInputElement).click();
                    };
                }}
                onChange={(event) => {
                    if (!event || !event.target || !event.target.files) return;
                    onFiles(Array.from(event.target.files));
                }}
            />
            <Center className="upload-inner">{children}</Center>
        </UploadZoneDiv>
    );
}

const UploadZoneDiv = styled.div`
    width: 100%;
    height: 10vh;
    padding: 100px;
    border: 5px dashed #cccccc;
    border-radius: 5px;

    input {
        display: none;
    }

    transition: border-color 0.2s ease;
    .upload-inner {
        transition: transform 0.1s ease;
    }

    &.files-over {
        border-color: #009edf;
    }

    &.files-over .upload-inner {
        transform: scale(105%);
    }
`;

/**
 * TODO: @hejny Probbably when there is only one UploadZone rendered on entire page, expand invisible dropzone to full page
 * TODO: @hejny Do not allow to drop placeholder of SigmaStamp logo
 * TODO: @hejny Allow to recieve item from Clipboard
 */
