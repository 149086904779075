import debounce from 'lodash/debounce';
import React from 'react';
import { Icon } from '../../30-components/menu/Icon';
import { IconColor } from '../../30-components/menu/IconColor';
import { string_color } from '../../40-utils/typeAliases';
import { IAttributeString } from '../../50-systems/AttributesSystem/IAttribute';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeAttributeModule } from '../../50-systems/ModuleStore/makers/makeAttributeModule';
import { DRAWING_COLORS } from '../../config';

/**
 * Note: Color2AttributeModule, Color3AttributeModule and Weight2AttributeModule are here just to demonstrate how non standard attribute modules works.
 */

const CUSTOM_COLORS = Object.values(DRAWING_COLORS).length;

internalModules.declareModule(() => makeAttributeModule(new Color3AttributeModule()));

class Color3AttributeModule {
    // TODO: This module should be refactored
    // TODO: And probbably bahave better (when selecting existing color from html5 colorpicker, select this color on palet)
    manifest = {
        name: 'Color3Attribute',
        title: { en: 'More colors', cs: 'Více barev' },
        description: {
            en: 'Allows user to pick more colors from the default system color picker.',
            cs: 'Umožňuje výběr více barev ze standardního systémového dialogového okna.',
        },

        categories: ['Art', 'Colors'],
        icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.46/modules/Color/icon.svg',
        screenshots: [
            /*TODO:*/
        ],
        author: Authors.rosecky,
        contributors: [Authors.hejny],
    };
    standard = false;
    attribute: IAttributeString = {
        type: 'string',
        name: 'color',
        defaultValue: '#000000',
        // TODO: Pattern:
    };

    private previousColors: Array<string> = Object.values(DRAWING_COLORS);
    private handleColorpickerChange = debounce((color: string_color, onChange: (value: string) => void) => {
        // TODO: Probably crop array here instead cropping when viewing
        if (!this.previousColors.includes(color)) {
            this.previousColors.push(color);
        }
        onChange(color);
    }, 250 /* <- TODO: From some ConfigSystem */);

    inputRender = (value: string, onChange: (value: string) => void) => (
        <>
            {this.previousColors.map((color, index) =>
                index >= this.previousColors.length - CUSTOM_COLORS ? (
                    <IconColor
                        key={`${color}-${index}`}
                        {...{ color }}
                        active={value === color}
                        onClick={() => onChange(color)}
                    />
                ) : null,
            )}
            <label style={{ margin: 0 }}>
                <Icon icon="add" />
                <input
                    type="color"
                    {...{ value }}
                    onChange={(event) => this.handleColorpickerChange(event.target.value, onChange)}
                    style={{ display: 'none' }}
                />
            </label>
        </>
    );
}
