import { string_mime_type_with_wildcard } from '../../../40-utils/typeAliases';
import { ArrayableFull, FlipOptional } from '../../../40-utils/typeHelpers';
import { IExportScope } from './IExportScope';

/**
 * Options for file export
 *
 * @not-collboard-modules-sdk
 */
export interface IExportFileOptions {
    /**
     * Which arts should be exported
     *
     */
    scope: IExportScope;

    /**
     * What mime types you are interested in
     *
     * For example:
     * - Specify exact types ['image/png', 'image/jpeg','application/pdf']
     * - Specify wildcard types ['image/*']
     * - Specify all types ['*']
     */
    mimeType: ArrayableFull<string_mime_type_with_wildcard>;

    /**
     * Should be heavy (=takes lot of resources) exports included?
     *
     * @default false
     */
    isHeavyExport?: boolean;

    /**
     * Quality of the exported image
     * It should be between 0 and 1 (=100%)
     *
     * Note: There are two distinct options influencing quality of export - `quality` and `scale`
     * @default 0.92
     */
    quality?: number;

    /**
     * Enlarge the exported image against default zoom scale
     *
     * Note: There are two distinct options influencing quality of export - `quality` and `scale`
     * @default 1
     */
    scale?: number;

    /**
     * Incorporate external sources into exported file
     *
     * @default false
     */
    isMaterialized?: boolean;

    /**
     * Link to the live board
     *
     * @deprecated Probbably better and more clear name according to isNative
     * @default false
     */
    isLinked?: boolean;

    /**
     * Add native data to the exported file
     *
     *
     * @default true
     */
    isNative?: boolean;

    /**
     * Keep board background transparent
     *
     * @default true
     */
    isTransparent?: boolean;

    /**
     * Export should contain texting UI elements / testing metadata
     *
     * @default false
     */
    isTesting?: boolean;
}

export const EXPORT_FILE_DEFAULT_OPTIONS: FlipOptional<IExportFileOptions> = {
    isHeavyExport: false,
    quality: 0.92,
    scale: 1,
    isMaterialized: false,
    isLinked: false,
    isNative: true,
    isTransparent: true,
    isTesting: false,
};

/**
 * TODO: isAnimated
 * TODO: [🍸] Some way how the supporter can tell additional options + some of listed options above are not relevant for all exporters
 * TODO: Probbably allow more scopes> scope: IArrayableFull<IExportScope>
 * TODO: Find the best quality value
 */
