import QRCodeGenerator, { QRCodeRenderersOptions } from 'qrcode';
import React from 'react';
import { consolex } from '../../consolex';

interface IQRCodeProps extends QRCodeRenderersOptions {
    text: string;
}

/**
 * @collboard-modules-sdk
 */
export function QRCode(props: IQRCodeProps) {
    return (
        <canvas
            ref={(canvasElement) => {
                if (!canvasElement) {
                    return;
                }

                // Note: Making some manipulation (probbably) due to internal errors of qrcode package
                const options: QRCodeRenderersOptions = { ...props };
                delete (options as any).text;
                options.color = options.color || {};

                QRCodeGenerator.toCanvas(canvasElement, props.text, options, (error) => {
                    if (error) {
                        consolex.error(error);
                    }
                    // consolex.log('success!');
                });
            }}
        />
    );
}

/**
 * TODO: Move to external LIB
 */
