/**
 * Enumerates all the possible businesses. In Collboard, a business for which purpose is Collboard used.
 *
 * @deprecated [🍿] Do not use enums but object as const OR 'LITERAL VALUES' instead
 *
 * @collboard-modules-sdk
 */

export enum BusinessName {
    Education = 'EDUCATION',
    Business = 'BUSINESS',
    Development = 'DEVELOPMENT',
}

/**
 * TODO: Probbably move to some other foleder
 */
