/**
 * Checks that value is defined
 * - If YES return the value
 * - If NO then throw error
 *
 * @collboard-modules-sdk
 */
export function requireValue<TValue>(value: TValue | undefined, errorMessage: string = 'value is required'): TValue {
    if (value === undefined) {
        throw new Error(errorMessage);
    }

    return value;
}

/**
 * TODO: Use typescript assert in return
 * TODO: Use across the repositiory instead of !
 */
