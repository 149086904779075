import React from 'react';
import { FileComponent } from '../../../../30-components/utils/FileComponent';
import { Loader } from '../../../../30-components/utils/Loader/Loader';
import { expectExactlyOne } from '../../../../40-utils/expectExactlyOne';
import { useAsyncMemo } from '../../../../40-utils/react-hooks/useAsyncMemo';
import { useSystems } from '../../../../40-utils/react-hooks/useSystems';
import { IExportScope } from '../../../../50-systems/ExportSystem/interfaces/IExportScope';

interface IHtmlPreviewFileComponentProps {
    scope: IExportScope;
}

/**
 * Note: Need to be used inside of a module
 *
 * @not-collboard-modules-sdk too specific that is used only in @collboard/internal/html
 */
export function HtmlPreviewFileComponent({ scope }: IHtmlPreviewFileComponentProps) {
    const { exportSystem } = useSystems('exportSystem');
    const htmlFile = useAsyncMemo<File>(async () => {
        const exportOptions = {
            scope,
            isHeavyExport: true,
            mimeType: 'text/html',
            isTesting: false,
            isMaterialized: false,
        };
        return expectExactlyOne(
            /* <- TODO: [🌁] Probbably there should be method exportSystem.exportFile */
            'exported HTML file',
            await exportSystem.exportFiles(exportOptions),
            { exportOptions },
        );
    }, [exportSystem, scope]);

    if (htmlFile === undefined) {
        return <Loader alt="Creating HTML" />;
    } else {
        return <FileComponent file={htmlFile} isIframeEnforced />;
    }
}

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
