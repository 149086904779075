import { Registration } from 'destroyable';
import spaceTrim from 'spacetrim';
import { JsonValue } from 'type-fest';
import { Vector } from 'xyzt';
import { Commit } from '../../10-database/Commit';
import { ICommitData } from '../../10-database/interfaces/ICommitData';
import { isPureObject } from '../../40-utils/object/isPureObject';
import { AbstractArt } from '../../71-arts/20-AbstractArt';
import { CornerstoneArt } from '../../71-arts/30-CornerstoneArt';
import { DeletedArt } from '../../71-arts/30-DeletedArt';
import { ExportArt } from '../../71-arts/35-ExportArt';
import { ImportArt } from '../../71-arts/35-ImportArt';
import { MAX_RECURSION_LEVEL } from '../../config-universal';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';
import { IArt } from '../CollSpace/IArt';
import { LoadingArt } from './../../71-arts/30-LoadingArt';
import { SerializationError } from './errors/10-SerializationError';
import { DeserializationError } from './errors/11-DeserializationError';
import { ArtSerializationError } from './errors/20-ArtSerializationError';
import { ArtDeserializationError } from './errors/21-ArtDeserializationError';
import { ISerializer } from './interfaces/ISerializer';
import { ISerializerRule } from './interfaces/ISerializerRule';
import { createSerializerRuleForClass } from './rules/createSerializerRuleForClass';
import { Serializer } from './Serializer';

/**
 * @@x
 */
type ICollboardSerializable = Commit | ICommitData | IArt | AbstractArt | Vector;

/**
 * ArtSerializer serializes and deseriales Collboard arts and other objects
 *
 * - **Serializer** is generic serializer which can work with any rules; it works synchronously
 * - **Serializer with basic rules** is Serializer which has registered basic rules; it works synchronously
 * - **ArtSerializer** serializes and deseriales Collboard arts and other objects; it works asynchronously
 *
 * @collboard-system
 */
export class ArtSerializer extends AbstractSystem implements ISerializer<ICollboardSerializable> {
    /**
     * @@x
     * Using composition over inheritance patern
     */
    private readonly serializer = Serializer.createSerializerWithBasicRules();

    protected async init() {
        this.serializer.registerRule(createSerializerRuleForClass({ name: 'Commit', Class: Commit }));

        // Note: @@x Theese are Art objects not in module that needs to be serialized:
        this.serializer.registerRule(createSerializerRuleForClass({ name: 'Deleted', Class: DeletedArt }));
        this.serializer.registerRule(createSerializerRuleForClass({ name: 'Cornerstone', Class: CornerstoneArt }));
        this.serializer.registerRule(createSerializerRuleForClass({ name: 'Export', Class: ExportArt }));
        this.serializer.registerRule(createSerializerRuleForClass({ name: 'Import', Class: ImportArt }));
        this.serializer.registerRule(
            createSerializerRuleForClass({
                name: 'Loading',
                Class: LoadingArt /* <- TODO: This should be never serialized on server */,
            }),
        );
    }

    /**
     * @@x copy from serializer
     */
    public registerRule(...rules: Array<ISerializerRule>): Registration {
        return this.serializer.registerRule(...rules);
    }

    /**
     * @@x copy from serializer
     */
    public async serialize(value: ICollboardSerializable): Promise<JsonValue> {
        // TODO: [🧠] Tail async recursion:
        //     > return this.serialize(...)
        //   vs> return await this.serialize(...)
        return await this.serializeWithRecursionProtection(value, []);
    }

    /**
     * @@x copy from serializer
     */
    public async serializeWithRecursionProtection(
        value: ICollboardSerializable,
        errors: Array<SerializationError>,
    ): Promise<JsonValue> {
        if (errors.length >= MAX_RECURSION_LEVEL) {
            throw new ArtSerializationError(`Too much attempts to install missing support modules`, {
                value,
                errors,
            });
        }

        try {
            return this.serializer.serialize(value);
        } catch (error) {
            if (!(error instanceof SerializationError)) {
                throw error;
            }

            errors = [...errors, error];

            // Note: Trying to Install the support and serialize once again
            let isAttemptToInstallMissingSupport = false;

            // Note: Installing for new functional IArts
            if ('moduleName' in error.details.value) {
                // TODO: Maybe also test that moduleName is string
                isAttemptToInstallMissingSupport = true;
                await (await this.systems.artSupportSyncer).installSupport(error.details.value.moduleName);
            }

            // Note: Installing for old classical AbstractArts
            if (error.details.value instanceof AbstractArt || error.details.value instanceof Commit) {
                isAttemptToInstallMissingSupport = true;
                await (await this.systems.artSupportSyncer).installSupportForArt(error.details.value);
            }

            if (!isAttemptToInstallMissingSupport) {
                throw new ArtSerializationError(
                    spaceTrim(`
                        Can not find way how to install missing support module

                        In details:
                        - **value** is the top value ArtSerializer trying to serialize (usually a Commit)
                        - in **errors** you can find the actual value which cannot be installed support for
                    `),
                    {
                        value,
                        errors,
                    },
                );
            }

            if (
                errors.length >= 2 &&
                errors[errors.length - 2].details.value === errors[errors.length - 1].details.value
            ) {
                throw new ArtSerializationError(
                    spaceTrim(`
                        Same error occured after an attempt to install missing support module

                        It means that Collboard can not find way how to serialize the value
                        In details:
                        - **value** is the top value ArtSerializer trying to serialize (usually a Commit)
                        - in **errors** you can find the actual value which cannot be installed support for
                    `),
                    {
                        value,
                        errors,
                    },
                );
            }

            return await this.serializeWithRecursionProtection(value, errors);
        }
    }

    /**
     * @@x copy from serializer
     */
    public async deserialize(serialized: JsonValue): Promise<ICollboardSerializable> {
        return await this.deserializeWithRecursionProtection(serialized, []);
    }

    /**
     * @@x copy from serializer
     */
    public async deserializeWithRecursionProtection(
        serialized: JsonValue,
        errors: Array<DeserializationError>,
    ): Promise<ICollboardSerializable> {
        if (errors.length >= MAX_RECURSION_LEVEL) {
            throw new ArtDeserializationError(`Too much attempts to install missing support modules`, {
                serialized,
                errors,
            });
        }

        try {
            return this.serializer.deserialize(serialized);
        } catch (error) {
            if (!(error instanceof DeserializationError)) {
                throw error;
            }

            errors = [...errors, error];

            // Note: Trying to Install the support and serialize once again
            let isAttemptToInstallMissingSupport = false;

            if (isPureObject(error.details.serialized)) {
                // Note: Installing for new functional IArts
                if (typeof error.details.serialized.moduleName === 'string') {
                    // TODO: Maybe also test that moduleName is string
                    isAttemptToInstallMissingSupport = true;
                    await (await this.systems.artSupportSyncer).installSupport(error.details.serialized.moduleName);
                }

                // Note: Installing for old classical AbstractArts
                if (typeof error.details.serialized.__class === 'string') {
                    isAttemptToInstallMissingSupport = true;
                    await (await this.systems.artSupportSyncer).installSupportForArt(error.details.serialized.__class);
                }
            }

            if (!isAttemptToInstallMissingSupport) {
                throw new ArtDeserializationError(
                    spaceTrim(`
                            Can not find way how to install missing support module

                            In details:
                            - **value** is the top value ArtSerializer trying to serialize (usually a Commit)
                            - in **errors** you can find the actual value which cannot be installed support for
                        `),
                    {
                        serialized,
                        errors,
                    },
                );
            }

            if (
                errors.length >= 2 &&
                errors[errors.length - 2].details.serialized === errors[errors.length - 1].details.serialized
            ) {
                throw new ArtDeserializationError(
                    spaceTrim(`
                            Same error occured after an attempt to install missing support module

                            It means that Collboard can not find way how to serialize the value
                            In details:
                            - **value** is the top value ArtSerializer trying to serialize (usually a Commit)
                            - in **errors** you can find the actual value which cannot be installed support for
                        `),
                    {
                        serialized,
                        errors,
                    },
                );
            }

            return await this.deserializeWithRecursionProtection(serialized, errors);
        }
    }
}

/**
 * TODO: DRY serialize and deserialize
 * TODO: ArtSerializer should warn if you try to serialize unnessesary HUGE art - test on clone vs fixed freehand
 * TODO: In ICollboardSerializable maybe just Commit (not Commit | ICommitData )
 * TODO: Uninstall support modules with destroying of ArtSerializer
 * TODO: [🧠] In future here can be just ArtSerializer; Serializer with basic rules move into the independent library
 */
