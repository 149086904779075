import { renderToStaticMarkup } from 'react-dom/server';
import { string_html, string_xml } from '../typeAliases';

/**
 * Renders jsx to html / xml
 *
 * Note: There are functions:
 *    - **jsxToHtml** uses react-dom to render the jsx to html
 *    - **jsxToHtmlSimple** does not use react-dom to render the jsx to html and it tryes to extract essential information from the js
 *    @see ./jsxToHtml.test.tsx vs. ./jsxToHtmlSimple.test.tsx
 *
 * @param element JSX element
 * @returns html string
 *
 * @collboard-modules-sdk
 */
export function jsxToHtml(jsx: JSX.Element): string_xml | string_html {
    return renderToStaticMarkup(jsx);
}

/**
 * TODO: Formatting is not working on large (exported) SVGs - It is not fatal but it returns one lined string
 * TODO: [🎞️] Probbably work with XMLs in some object-based method
 * TODO: [🐾] Optimize HTML native export
 */
