import { IKeywords, parseKeywords } from '@promptbook/utils';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';

/**
 * Create list of keywords from module manifest
 *
 * @collboard-modules-sdk
 */
export function parseKeywordsFromManifest(manifest: IModuleManifest): IKeywords {
    const { name, title, description, author, contributors, keywords, categories } = manifest;
    return parseKeywords({ name, title, description, author, contributors, keywords, categories });
}

/**
 *  TODO: Unit test
 */
