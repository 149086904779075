import { ISystemsExtended } from '../50-systems/00-SystemsContainer/ISystems';
import { UserInterfaceElementPlace } from '../50-systems/UserInterfaceSystem/UserInterfaceElementPlace';
import { AbstractArt } from './20-AbstractArt';

/*
 * Note: This is not in the internal module because some of the Arts are so
 * tightly connected with the core that they need to be there, not in
 * optionally deactivateable module.
 */

/**
 * AbstractUIArt is an abstract class providing functionality regarding
 * adding or removing components from UI
 *
 * @summary AbstractUIArt is not implemented within the system yet
 *
 * @collboard-modules-sdk
 */
export abstract class AbstractUserInterfaceArt extends AbstractArt {
    public constructor(public userInterfaceElementPlace: UserInterfaceElementPlace) {
        super();
    }

    abstract render(systems: ISystemsExtended /* TODO: Create render options interface */): JSX.Element;
}

/**
 * TODO: AbstractLogoArt  - Change or remove Collboard logo (it can be payed feature)
 * TODO: ToolbarArt  - Creates a new toolbar
 * TODO: [🎚️] Maybe get rit of AbstractArts and make makers for arts which returns IArts
 */
