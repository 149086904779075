import { normalizeTo_SCREAMING_CASE } from '@promptbook/utils';
import { splitArray } from './splitArray';

/**
 * Converts human readable time to seconds
 *
 * @deprecated use some better name or complete library instead
 * @collboard-modules-sdk
 */
export function calculate(formula: string): number {
    /* tslint:disable:prefer-const */

    let keywords: Array<string | number> = normalizeTo_SCREAMING_CASE(formula)
        .toLowerCase()
        .split(/[^a-z0-9]+/gs)
        .filter(Boolean);

    keywords = keywords
        .map((word) => (!Number.isNaN(Number.parseFloat(word as string)) ? Number.parseFloat(word as string) : word))
        .map((word) => NUMBERS_SMALL[word] || word);

    let [from, to] = splitArray(keywords, 'in', 2);

    if (to.join(' ') !== 'seconds') {
        throw new Error(
            `Conversion to other things than seconds is not implemented, tried to convert to "${to.join(' ')}"`,
        );
    }

    if (from.length === 1) {
        from = [1, from[0]!];
    }

    let [count, unit] = from;

    if (typeof count !== 'number' || Number.isNaN(count)) {
        throw new Error(`First part of formula should be some count, got "${count}" instead.`);
    }

    if (typeof unit !== 'string') {
        throw new Error(`Unit should be keyword, got "${unit}" instead.`);
    }

    unit = unit.replace(/s$/, '');

    if (!TIME_UNITS_TO_SECONDS[unit]) {
        throw new Error(`Unknown unit ${unit}. You can use ${Object.keys(TIME_UNITS_TO_SECONDS).join(', ')}.`);
    }

    return count * TIME_UNITS_TO_SECONDS[unit];
}

const TIME_UNITS_TO_SECONDS: Record<string, number> = {
    milisecond: 1 / 1000,
    second: 1,
    minute: 60,
    hour: 3600,
    day: 24 * 3600,
    week: 7 * 24 * 3600,
    month: 2629743.83,
    year: 31556926,
};

const NUMBERS_SMALL: Record<string, number> = {
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9,
    ten: 10,
    eleven: 11,
    twelve: 12,
    thirteen: 13,
    fourteen: 14,
    fifteen: 15,
    sixteen: 16,
    seventeen: 17,
    eighteen: 18,
    nineteen: 19,
    twenty: 20,
    thirty: 30,
    forty: 40,
    fifty: 50,
    sixty: 60,
    seventy: 70,
    eighty: 80,
    ninety: 90,
    // TIP: In future maybe use https://www.npmjs.com/package/words-to-numbers
};

/**
 * TODO: Make this more universal OR rename to convert / convertUnits (OR Use VM2 instead of this)
 */
