import uuidV4 from 'uuid/v4';
import { uuid } from './typeAliases';

/**
 * Generates random UUID v4
 *
 * @returns uuid branded type
 */
export function randomUuid(): uuid {
    return uuidV4() as uuid;
}
