import { makeClassicalArtModule } from './30-makeClassicalArtModule';

/**
 * @@x
 *
 * @collboard-modules-sdk
 */
export const makeArtModule = makeClassicalArtModule;

/**
 * TODO: In next major version switch to makeFunctionalArtModule
 * Note: Not uing:
 *     > export { makeClassicalArtModule as makeArtModule } from './30-makeClassicalArtModule';
 *     Because we need to be able to export it as @collboard-modules-sdk
 */
