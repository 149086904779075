import { randomUuid } from '../../../40-utils/randomUuid';
import { IControl } from '../interfaces/IControl';
import { IControls } from '../interfaces/IControls';

export type IRegisterControlOptions<TValue> = IControl | IControls<TValue>;
export function controlToControls<TValue>(options: IRegisterControlOptions<TValue>): IControls<TValue> {
    const { defaultShortcut, defaultShortcuts, executor } = options as any;

    if (defaultShortcuts) {
        return options as IControls<TValue>;
    } else if (defaultShortcut) {
        const optionsSingle = options as IControl;
        const shortcutValue = randomUuid();
        const createdShortcuts = new Map();
        createdShortcuts.set(optionsSingle.defaultShortcut, shortcutValue);
        return {
            priority: optionsSingle.priority,
            isContinuing: optionsSingle.isContinuing,
            defaultShortcuts: createdShortcuts,
            executor: ({ value }) => {
                if (shortcutValue !== (value as unknown as string)) {
                    throw new Error(
                        `Unexpected behaviour of ControlSystem - should recieve value "${defaultShortcut}" but got "${value}".`,
                    );
                }

                return executor();
            },
        };
    } else {
        throw new Error(`IRegisterControlOptions should have shortcuts or shortcut.`);
    }
}
