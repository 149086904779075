import { IVectorData, Vector } from 'xyzt';
import { number_positive, string_color } from '../40-utils/typeAliases';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeArtModule } from '../50-systems/ModuleStore/makers/art/20-makeArtModule';
import { ShapeArt, ShapeName } from './50-ShapeArt';

internalModules.declareModule(() => makeArtModule(LineArt));

/**
 *
 * @collboard-modules-sdk
 */
export class LineArt extends ShapeArt {
    public static serializeName = 'Line';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/line-art',
        deprecatedNames: '@collboard/line-art',
    };

    public constructor(
        public color: string_color,
        public weight: 0 | number_positive,
        point1: IVectorData,
        public point2: IVectorData,
    ) {
        super(
            ShapeName.Line,
            color,
            weight,
            point1,
            /* TODO: Better size*/ point1 && point2
                ? new Vector(Math.abs(point2.x || 0), Math.abs(point2.y || 0))
                : Vector.zero(),
        );
        this.shift = point1;
    }

    /*
    TODO: Smarter than pure boundingbox
    public isNear(pointToTest: IVectorData) {
        // TODO: Smarter!
        return (
            (pointToTest.x || 0) > this.topLeft.x &&
            (pointToTest.x || 0) < this.bottomRight.x &&
            (pointToTest.y || 0) > this.topLeft.y &&
            (pointToTest.y || 0) < this.bottomRight.y
        );
    }
    */
}

/**
 * TODO: [🚉] There should be some rounding optimization for svg numbers (for example path)
 *       From: d="M60.00000000000006 70.4999999999999 L60.00000000000006 70.4999999999999...
 *       To:   d="M60 70.5 L60 70.5...
 *
 * TODO: [🍎]  Use IMaterial instead of color
 * TODO: [🍎]  Use IShape instead of weight, points,...
 * TODO: [🕺] Rename weight => spotSize, edgeSize (as it is in PolygonArt and FreehandArt)
 * TODO: [🎚️] Implement IArt
 * Note: [🌡️]
 */
