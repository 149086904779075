import { IStorage, ObjectStorage, PrefixStorage } from 'everstorage';
import { consolex } from '../../consolex';
import { AbstractSystem } from '../10-AbstractSystem/AbstractSystem';

/**
 * StorageSystem provides storages for other systems / modules
 *
 * @private
 * @collboard-system
 */
export class StorageSystem extends AbstractSystem {
    protected async init() {
        await this.runMigrations();
    }

    private async runMigrations() {
        // TODO: Better and more structured migrations
        //-------------- My boards
        const storage = this.getStorage(`ApiClientCache`);
        const originalItem = await storage.getItem(`getMyBoards`);
        const item = await storage.getItem(`getMyBoards`);
        if (item) {
            let changed = false;
            for (const [from, to] of [
                ['name', 'boardname'],
                ['lastOpen', 'lastEdit'],
            ]) {
                for (const boardInfo of item.data) {
                    if (typeof boardInfo[from] !== 'undefined') {
                        boardInfo[to] = boardInfo[to] || boardInfo[from];
                        delete boardInfo[from];
                        changed = true;
                    }
                }
            }

            if (changed) {
                await this.getStorage(`MigrationsBackup_ApiClientCache`).setItem(`getMyBoards`, originalItem);
                await storage.setItem(`getMyBoards`, item);

                consolex
                    .groupCollapsed(
                        `%c⬆️ Migration of my boards`,
                        `background: #55ff55; color: white; font-size: 1.1em; font-weight: bold; padding: 10px; border-radius: 3px;`,
                    )
                    .info('Original data:', originalItem)
                    .info('Migrated data:', item)
                    .end();
            }
        }
        //--------------
    }

    private persistentStorage: IStorage<string> = new PrefixStorage(localStorage, 'Collboard');
    private sessionStorage: IStorage<string> = new PrefixStorage(sessionStorage, 'Collboard');

    public getStorage<TValue extends any /* TODO: !! IJson */>(
        storageName: string,
        persistent: boolean = true,
    ): IStorage<TValue> {
        // TODO: Normalize names in localstorage
        // Note: String storage values will be JSONed
        if (!storageName) {
            throw new Error(`You should provide storage name.`);
        }
        return new PrefixStorage(
            new ObjectStorage<any /* TODO: !! T */>(persistent ? this.persistentStorage : this.sessionStorage),
            storageName,
        );
    }

    // TODO: This system maybe should have access to API because of remote storages
}
