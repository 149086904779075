import { IDependenciesRecord, IDependenciesSet, IDependency } from '../interfaces/IDependencies';
import { IModuleManifest } from '../interfaces/IModuleManifest/IModuleManifest';
import { dependencyToDependencyDetail } from './dependencyToDependencyDetail';

export function dependenciesSetToDependenciesRecord(
    dependenciesSetOrArray: IDependenciesSet | Array<IDependency | IModuleManifest>,
): IDependenciesRecord {
    const dependeciesRecord: IDependenciesRecord = {};

    for (const dependency of Array.from(dependenciesSetOrArray)) {
        dependeciesRecord[dependency.name] = dependencyToDependencyDetail(dependency);
    }

    return dependeciesRecord;
}
